/* eslint-disable */
import React, {Component} from "react";
import {connect} from "react-redux";
import {userActions} from "../_actions";

class NewCustomer extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.app = JSON.parse(localStorage.getItem("app"));
        this.storeId = this.app.vanityId;
        this.customerRef = React.createRef();
        this.state = {
            user: {
                firstName: "",
                lastName: "",
                nickName: "",
                email: "",
                mobileNumber: "",
                password: "",
                gender: "Female",
                birthDate: "",
                referralToken: this.app.referralToken,
                appointmentSchedulingAllowed: true
            },
            submitted: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            this.setState({
                user: {
                    ...this.state.user,
                    firstName: this.props.firstName,
                },
            });
        }
        window.maskInputs();
    }

    handleChange(event) {
        const {name, value} = event.target;
        const {user} = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value,
            },
        });
    }

    isBirthdateValid() {
        var birthDate = this.state.user.birthDate;

        if (birthDate.length != 5) return false;
        if (birthDate[2] != "-") return false;

        var month = birthDate.split("-")[0];
        var date = birthDate.split("-")[1];
        return (
            month &&
            date &&
            month.length == 2 &&
            parseInt(month) > 0 &&
            parseInt(month) <= 12 &&
            date.length == 2 &&
            parseInt(date) > 0 &&
            parseInt(date) <= 31
        );
    }

    handleSubmit(e) {
        e.preventDefault();
        const {user} = this.state;
        this.setState({
            submitted: true,
        });
        var fname = user.firstName;
        var email = user.email;
        var mobileNumber = user.mobileNumber;
        if (user.firstName == "") {
            fname = this.props.firstName;
        }
        if (user.email == "") {
            email = this.props.email;
        }
        if (user.mobileNumber == "") {
            mobileNumber = this.props.mobile;
        }
        if (
            fname &&
            user.lastName &&
            email &&
            user.birthDate &&
            this.isBirthdateValid() &&
            mobileNumber &&
            user.gender &&
            user.password
        ) {
            user.mobileNumber = user.mobileNumber
                .replace(" ", "")
                .replace(")", "")
                .replace("(", "")
                .replace("-", "");
            const newUser = {
                business: {
                    id: this.app.id,
                },
                names: {
                    first: fname,
                    last: user.lastName,
                    nick: fname,
                },
                emailAddress: email,
                mobileNumber: mobileNumber,
                password: user.password,
                birthDate: "1900-" + user.birthDate,
                gender: user.gender,
                appointmentSchedulingAllowed: user.appointmentSchedulingAllowed,
                referredByUser: {
                    referralToken: user.referralToken,
                },
            };

            this.props.register(newUser);
            this.setState({
                user: {
                    firstName: "",
                    lastName: "",
                    nickName: "",
                    email: "",
                    mobileNumber: "",
                    password: "",
                    gender: "Female",
                    birthDate: "",
                    referralToken: this.app.referralToken,
                },
                submitted: false,
            });
        }
    }

    generateEmail = () => {
        const email = this.app.email.split("@");

        this.setState({
            user: {
                ...this.state.user,
                email:
                    this.state.user.firstName != ""
                        ? this.state.user.firstName.toLowerCase() +
                        this.state.user.lastName.toLowerCase() +
                        "@" +
                        email[1]
                        : this.props.firstName.toLowerCase() +
                        this.state.user.lastName.toLowerCase() +
                        "@" +
                        email[1],
            },
        });
    };
    generateBirthday = () => {
        const monthDate = moment().format("MM-DD");
        this.setState({
            user: {
                ...this.state.user,
                birthDate: monthDate,
            },
        });
    };
    generatePassword = () => {
        this.setState({
            user: {
                ...this.state.user,
                password: this.app.referralToken,
            },
        });
    };

    render() {
        const {registrationError, registering, registerSuccess} = this.props;
        const {submitted, user} = this.state;

        return (
            <React.Fragment>
                <h2 className="text-danger">
                    {this.props.registrationError && this.props.registrationError}
                </h2>
                <form name="form" onSubmit={this.handleSubmit} ref={this.customerRef}>
                    <div className="row">
                        <div className="col-md-6">
                            <div
                                className={
                                    "form-group" +
                                    (submitted && !user.firstName ? " has-error" : "")
                                }
                            >
                                <label htmlFor="firstName">
                                    First Name <b className="text-danger">*</b>
                                </label>
                                <input
                                    maxLength="40"
                                    type="text"
                                    className="form-control"
                                    name="firstName"
                                    value={
                                        user.firstName != ""
                                            ? user.firstName
                                            : this.props.firstName != ""
                                                ? this.props.firstName
                                                : ""
                                    }
                                    onChange={this.handleChange}
                                />
                                {submitted && !user.firstName && (
                                    <div className="help-block">First Name is required</div>
                                )}
                                {submitted && /^\s/.test(user.firstName) && (
                                    <div className="help-block" style={{color: "#a94442"}}>
                                        First Name is required
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div
                                className={
                                    "form-group" +
                                    (submitted && !user.lastName ? " has-error" : "")
                                }
                            >
                                <label htmlFor="lastName">
                                    Last Name <b className="text-danger">*</b>
                                </label>
                                <input
                                    maxLength="40"
                                    type="text"
                                    className="form-control"
                                    name="lastName"
                                    value={user.lastName}
                                    onChange={this.handleChange}
                                />
                                {submitted && !user.lastName && (
                                    <div className="help-block">Last Name is required</div>
                                )}
                                {submitted && /^\s/.test(user.lastName) && (
                                    <div className="help-block" style={{color: "#a94442"}}>
                                        Last Name is required
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <div
                                className={
                                    "form-group" +
                                    ((submitted && !user.email) ||
                                    (registrationError && registrationError.includes("email"))
                                        ? " has-error"
                                        : "")
                                }
                            >
                                <label className="email-new-customer" htmlFor="email">
                                    {" "}
                                    <span>
                    Email <b className="text-danger">*</b>
                  </span>
                                    {user.firstName != "" && user.lastName != "" && (
                                        <span onClick={this.generateEmail} className="help">
                      <i className="fa fa-arrow-down"></i>
                    </span>
                                    )}
                                    {user.firstName == "" &&
                                        user.lastName != "" &&
                                        this.props.firstName != "" && (
                                            <span onClick={this.generateEmail} className="help">
                        <i className="fa fa-arrow-down"></i>
                      </span>
                                        )}
                                </label>
                                <input
                                    maxLength="100"
                                    type="email"
                                    className="form-control"
                                    name="email"
                                    value={
                                        user.email != ""
                                            ? user.email
                                            : this.props.email != ""
                                                ? this.props.email
                                                : ""
                                    }
                                    onChange={this.handleChange}
                                />
                                {submitted && !user.email && (
                                    <div className="help-block">Email is required</div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div
                                className={
                                    "form-group" +
                                    ((submitted && !user.mobileNumber) ||
                                    (registrationError && registrationError.includes("phone"))
                                        ? " has-error"
                                        : "")
                                }
                            >
                                <label htmlFor="mobileNumber">
                                    Mobile Number <b className="text-danger">*</b>
                                </label>
                                <input
                                    maxLength="20"
                                    type="text"
                                    className="form-control"
                                    name="mobileNumber"
                                    value={
                                        user.mobileNumber != ""
                                            ? user.mobileNumber
                                            : this.props.mobile != ""
                                                ? this.props.mobile
                                                : ""
                                    }
                                    onChange={this.handleChange}
                                />
                                {submitted && !user.mobileNumber && (
                                    <div className="help-block">Phone number is required</div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="gender">
                                    Gender <b className="text-danger">*</b>
                                </label>
                                <select
                                    name="gender"
                                    onChange={this.handleChange}
                                    defaultValue={user.gender}
                                >
                                    <option value="Male">MALE</option>
                                    <option value="Female">FEMALE</option>
                                    <option value="Non Binary">NON BINARY</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div
                                className={
                                    "form-group" +
                                    (submitted && (!user.birthDate || !this.isBirthdateValid())
                                        ? " has-error"
                                        : "")
                                }
                            >
                                <label className="email-new-customer" htmlFor="birthDate">
                  <span>
                    Next Birthday <b className="text-danger">*</b>
                  </span>{" "}
                                    <span onClick={this.generateBirthday} className="help">
                    <i className="fa fa-arrow-down"></i>
                  </span>
                                </label>
                                <input
                                    type="text"
                                    className="dob"
                                    name="birthDate"
                                    value={user.birthDate}
                                    placeholder="mm-dd"
                                    onChange={this.handleChange}
                                />
                                {submitted && !user.birthDate && (
                                    <div className="help-block">Birth Day is required</div>
                                )}
                                {submitted && !this.isBirthdateValid() && (
                                    <div className="help-block">
                                        Birthdate should be in mm-dd format.{" "}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div
                                className={
                                    "form-group" +
                                    (submitted && !user.password ? " has-error" : "")
                                }
                            >
                                <label className="email-new-customer" htmlFor="birthDate">
                  <span>
                    Password <b className="text-danger">*</b>
                  </span>{" "}
                                    <span onClick={this.generatePassword} className="help">
                    <i className="fa fa-arrow-down"></i>
                  </span>
                                </label>
                                <input
                                    type="password"
                                    className="form-control"
                                    name="password"
                                    value={user.password}
                                    onChange={this.handleChange}
                                />
                                {submitted && !user.password && (
                                    <div className="help-block">Password is required</div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div
                                className={
                                    "form-group" +
                                    (submitted &&
                                    registrationError &&
                                    registrationError.includes("referrer")
                                        ? " has-error"
                                        : "")
                                }
                            >
                                <label htmlFor="referralToken">Referral Code</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="referralToken"
                                    maxLength="4"
                                    value={user.referralToken}
                                    onChange={this.handleChange}
                                />
                                {registrationError &&
                                    (registrationError.includes("email") ||
                                        registrationError.includes("referrer")) && (
                                        <div className="help-block error">{registrationError}</div>
                                    )}
                            </div>
                        </div>
                    </div>

                    <div className="switcher-spacer"></div>
                    <div className="switcher-spacer"></div>

                    <div className="form-group center-xs text-right">
                        <button type="submit" className="btn btn-custom-info">
                            Register
                        </button>
                    </div>
                    {
                        // eslint-disable-next-line
                        registering && (
                            <div className="loaderDiv">
                                <div className="loader"></div>
                            </div>
                        )
                    }
                </form>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const {registering, registerSuccess, registrationError} =
        state.registration;
    return {
        registering,
        registerSuccess,
        registrationError,
    };
}

export default connect(mapStateToProps)(NewCustomer);
