/* eslint-disable */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {appActions, outgoingAction, userActions} from '../../_actions'
import {reservationActions} from '../../_actions'
import {appService, reservationService} from '../../_services'
import {Link} from 'react-router-dom';
import Footer from '../footer/footer'
import {helperFunctions} from '../../_helpers'
import './customers.css';
import Swal from 'sweetalert2'
import {userService} from '../../_services'
import noteImg from '../../note.jpg'
import NewCustomer from "../../_components/newCustomer";
import InlineMessage from "../../_components/inlineMessage"
import 'rc-datetime-picker/dist/picker.min.css';
import moment from 'moment';
import 'moment-timezone';
import jstz from 'jstz'
import CustomerSearchBar from '../../_components/customerSearchBar'
import AddTask from '../../_components/addTask'
import {DatePicker, DatePickerInput} from 'rc-datepicker';
import 'rc-time-picker/assets/index.css';
import 'rc-datepicker/lib/style.css';

const now = moment().hour(0).minute(0);
import Select2 from 'react-select2-wrapper';
import {userConstants} from "../../_constants";
import CustomTimePicker from "../../_components/custom-timer-picker";

class CustomersPage extends Component {
    constructor(props) {
        super(props);
        const {dispatch} = this.props;
        this.app = JSON.parse(localStorage.getItem('app'));
        this.loggedUser = JSON.parse(localStorage.getItem('user'));
        this.client = JSON.parse(localStorage.getItem('client'))
        this.storeId = this.app.vanityId
        var userRole = JSON.parse(localStorage.getItem("user")).role;
        this.accessRole = userRole == "MANAGER" || "DIRECTOR" || "ADMIN" || "CASHIER" || "DIRECTOR" ? true : false;
        dispatch(userActions.getAllCustomers("all", 0, '', this.refresh))

        this.state = {
            pageOffset: 0,
            customerType: 'all',
            searchQuery: '',
            actionText: 'Book',
            customerNotes: '',
            customerId: '',
            errors: {},
            mergeCustomer: [],
            selectedOption: null,
            oldCustomer: {},
            newCustomer: {},
            taskSelected: {
                taskSelectedCustomer: '',
                taskSelectedItemId: ''
            },
            cls: false,
            cls2: false,
            mode: '',


            category: '',
            service: 0,
            technician: '',
            services: [],
            technicians: [],
            selectedServices: [],
            step: 1,
            date: 'DATE_RANGE',
            dateStart: '',
            dateEnd: '',
            time: 'ANYTIME',
            timeStart: '',
            timeEnd: '',
            timeSelected: true,
            notes: '',
            reservationExists: false,
            showSlots: false,
            nmodal: '',
            selectedAppointment: '',
            actionText2: 'Delete',
            reservationPlaced: '',
            daysOfWeek: '',
            daysBetween: '',
            email: '',
            firstName: '',
            mobile: '',
            place: '',
            selectedCustomer: '',
            to: null,
            from: null,
            caller: false,
            calling_proccess: false,
            categories: [],


        };
        this.handleChange = this.handleChange.bind(this);
        this.saveNote = this.saveNote.bind(this)

        this.handleStartTime = this.handleStartTime.bind(this);
        this.handleEndTime = this.handleEndTime.bind(this);
        this.handleDateRangeStart = this.handleDateRangeStart.bind(this)
        this.handleDateRangeEnd = this.handleDateRangeEnd.bind(this)
        this.getCategories();

    }

    getCategories() {
        appService.getCategories(0, 200).then(categories => {
            this.setState({categories})
            this.initializeServices()
        }).catch(e => {
            console.log(e)
        })
    }

    refresh = (response) => {
        //console.log('sdf', response)
    }


    initializeServices() {
        const {categories} = this.state
        this.services = []
        var allServices = []
        categories && categories.filter(osc => osc.services.length > 0).map((osc, k) => {
            var categoryServices = []
            osc.services.filter(s => s.publiclyVisible == true).map((s, k) => {
                allServices.push(s)
                categoryServices.push({
                    text: s.name,
                    id: s.id
                })
            })
            this.services.push({
                text: osc.name.toUpperCase(),
                children: categoryServices
            })
        })

        this.setState({
            services: allServices
        })
    }

    handleDateRangeStart(jsDate, dateString) {

        this.setState({
            dateStart: dateString.split('T')[0]
        })
    }

    handleDateRangeEnd(jsDate, dateString) {
        this.setState({
            dateEnd: dateString.split('T')[0]
        })
    }

    handleChange(e) {
        const {name, value} = e.target;
        this.setState({[name]: value});
        if (this.state.reservationExists) {
            this.setState({reservationExists: false});
        }
        if (name == "service") {
            this.selectService(value)
            window.selectService()
        }
        if (name == "searchQuery") {
            this.getNewValue(value);
            this.setState({[name]: value});

        }
        if (name == "date" && value == "DATE_RANGE") {
            setTimeout(function () {
            }, 500)
        }
        if (name == "date" && value != "DATE_RANGE") {
            this.setState({
                dateStart: '',
                dateEnd: ''
            })
        }
        if (name == "time" && value != "TIME_RANGE") {
            this.setState({
                timeStart: '',
                timeEnd: ''

            })
        }
    }

    closeThisModal = () => {
        this.setState({
            nmodal: ''
        })
    }


    getService(id) {
        var service = null
        const {categories} = this.state
        categories.some(function (osc) {
            service = osc.services.find(s => s.id == id)
            return service != undefined
        })

        return service;
    }

    selectService(value) {
        if (value != "") {
            let technicians = [];
            let technicianIds = [];
            var service = this.getService(value);

            //window.addEachService(service.name)

            service.technicians.forEach(technician => {
                technicianIds.push(technician.id);
            });

            this.app.locations[0].technicians.filter(tech => tech.bookable == true)
                .forEach(technician => {

                    if (technicianIds.includes(technician.id)) {
                        technicians.push({
                            id: technician.id,
                            name: technician.user.names.nick,
                            expertise: technician.expertise
                        })
                    }

                });

            this.setState({technician: 'any'});
            this.setState({technicians: technicians});
            var techJson = [];
            var i = 0;
            techJson.push({
                text: "Any Staff",
                value: "any",
                selected: false,
                description: "Expertise: Any",
                imageSrc: "https://s3.amazonaws.com/zoracorp/bianca/default/user-no-image.png"
            })
            technicians.forEach(tech => {
                techJson.push({
                    text: tech.name,
                    value: tech.id,
                    selected: false,
                    description: "Expertise: " + tech.expertise,
                    imageSrc: this.findTechnicianImage(tech.id)
                })
                i++;
            })

            setTimeout(function () {
                window.initiateTechniciansSelect(techJson, '#technicians-select-div-stand', '#technicians-select-stand')
            }, 100);
        }
    }


    componentDidMount = async () => {
        const metaInfo = await appService.checkVersion();
        await helperFunctions.clearCache(metaInfo)
        window.sidebarInitialize();
        window.sidebarClose();
        window.initializeDatepicker();
        window.initializeddSelect();

        window.makeActiveCaller()

    }


    openNoteModal(id) {
        this.setState({customerId: id});

        const oldNote = this.props.customers.filter(c => c.id == id);
        this.setState({customerNotes: oldNote[0].staffNotes || 'NONE'});
        $('#addNote').modal('toggle');
    }

    saveNote() {
        //alert(this.state.customerId)
        const {dispatch} = this.props;
        if (this.state.customerNotes != '') {
            //dispatch(userActions.saveNote(this.state.customerId, {staffNotes: this.state.customerNotes}))
            userService.saveNote(this.state.customerId, {staffNotes: this.state.customerNotes}).then(() => {
                dispatch(userActions.getAllCustomers("all", this.state.pageOffset));
                this.setState({
                    ...this.state,
                    customerNotes: ''
                })
            })

            $('#addNote').modal('toggle');
        } else {
            const errors = {}

            errors.saveNote = 'Please enter a valid note!'

            this.setState({errors})
        }

    }

    handleSelect = selectedOption => {
        this.setState(
            {selectedOption},
            () => console.log(`Option selected:`, this.state.selectedOption)
        );

        this.selectedNewCustomer(selectedOption.value);

    };

    processMerge = () => {

        const {dispatch} = this.props;
        //alert(this.state.newCustomer.id)
        userService.processMerge(this.state.oldCustomer.id, this.state.newCustomer.id).then((res) => {
            window.toast('success', `Customer ${this.state.newCustomer.fullName} has been merged`);
            dispatch(userActions.getAllCustomers('all', this.state.pageOffset))
        });


        this.setState({
            newCustomer: {},
            oldCustomer: {},
            mergeCustomer: [],
            searchQuery: '',
            selectedOption: ''
        });

        $('#mergeModal').modal('toggle');

    }


    saveTask = (taskJSON) => {

        userService.saveTask(taskJSON)
            .then(() => {
                $('#addingTask').modal('toggle');
                window.DataUpdateSuccess()
            })

    }

    caretClass = () => {
        this.setState({
            cls: !this.state.cls,
            cls2: !this.state.cls2
        })
    }

    onCustomerSelect = (customer) => {
        const newCustomer = this.state.newCustomer
        newCustomer.id = customer.id
        newCustomer.name = customer.fullName
        newCustomer.emailAddress = customer.emailAddress
        newCustomer.mobileNumber = customer.mobileNumber
        newCustomer.rewardBalance = customer.rewardBalance
        newCustomer.imageUrl = customer.imageUrl
        newCustomer.birthdayToday = customer.birthdayToday
        newCustomer.lastVisit = customer.lastVisit && customer.lastVisit
        newCustomer.nextVisit = customer.nextVisit && customer.nextVisit
        neResstomer.staffNotes = customer.staffNotes && customer.staffNotes

        this.setState({
            newCustomer: newCustomer
        })
    }

    clearelace = () => {
        this.setState({
            place: ''
        })
    }
    setPlace = (value) => {
        this.setState({
            ...this.state
        }, () => {
            this.setState({
                place: 'Type a name'
            })
        })
    }

    closeMergeModal = () => {
        this.setPlace()
        this.setState({oldCustomer: {}, newCustomer: {}, mergeCustomer: [], searchQuery: '', selectedOption: ''})
    }
    getNewValue = (value) => {
        var emailChecker = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        var numberChecker = /^\d+$/;
        var nameChecker = /^[a-zA-Z]/;
        if (emailChecker.test(value)) {
            this.setState({
                email: value,
                firstName: '',
                mobile: ''
            })
        } else if (numberChecker.test(value)) {
            this.setState({
                email: '',
                firstName: '',
                mobile: value
            })
        } else if (nameChecker.test(value)) {
            this.setState({
                email: '',
                firstName: value,
                mobile: ''
            })
        } else {
            this.setState({
                email: '',
                firstName: '',
                mobile: ''
            })
        }
    }

    clearState = () => {
        this.setState({
            ...this.state,
            email: '',
            mobile: '',
            firstName: '',
            searchQuery: ''
        })
    }


    makeInActive = () => {
        this.setState({})
    }

    openCaller = (user) => {
        if (this.accessRole === true) {
            const {dispatch} = this.props;
            dispatch(outgoingAction.proccessCall(user))
        }

    }

    //mark as contacted after call
    markedAsContacted = async (userId, name) => {
        let response = await userService.markAsContacted(userId);
        let data = await response.json();
        if (response.status === 200) {
            const {dispatch, customers} = this.props;
            window.toast("success", `${name} is marked as contacted`);
            const winBackList = this.props.customers.map(customer => {
                if (customer.id == userId) {
                    return {
                        ...customer,
                        lastFollowUpCallTime: data.lastFollowUpCallTime
                    }
                }
                return customer;
            });

            dispatch({type: userConstants.GET_ALL_USERS_SUCCESS, customers: winBackList})

        }
    }

    render() {
        console.log(this.loggedUser)
        const {
            customers,
            customersLoaded,
            findingAvailability,
            slots,
            placingReservation
        } = this.props;
        const {
            searchQuery,
            customerType,
            errors,
            oldCustomer,
            newCustomer,
            actionText,
            reservationPlaced
        } = this.state;
        const {
            showSlots,
            service,
            technician,
            technicians,
            selectedServices,
            time,
            timeStart,
            timeEnd,
        } = this.state;
        return (
            <React.Fragment>

                <div id="page-wrapper">

                    <div className="search-sidebar">
                        <div className="closebtn">
                            <button><i className="fa fa-close"></i></button>
                        </div>
                        <div>
                            <div className="form-group">
                                <input type="text" className="form-control input-sm" value={searchQuery}
                                       name="searchQuery"
                                       onChange={this.handleChange} placeholder="Name, Email or Phone"/>
                                <button className="btn btn-block btn-custom-info btn-sm" onClick={() => {
                                    this.searchCustomer()
                                }}><i className="fa fa-search"></i></button>
                            </div>
                        </div>
                        <div className="or">OR</div>
                        <div>
                            <div className="form-group">
                                <select
                                    className="form-control input-sm"
                                    value={customerType}
                                    name="customerType"
                                    onChange={this.handleChange}>
                                    <option value="">Type of Customers</option>
                                    <option value="all">All</option>
                                    <option value="uncfrmd_appts">Unconfirmed Appointments</option>
                                    <option value="exhstd_stndngs">Exhausted Standings</option>
                                    <option value="new">New This Week</option>
                                    <option value="bday_this_mth">Birthdays This Month</option>
                                </select>
                                <button className="btn btn-block btn-custom-info btn-sm" onClick={() => {
                                    this.searchCustomer()
                                }}><i className="fa fa-search"></i></button>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid page-title">
                        <div className="row bg-title search-row-mobile">

                            <div className="mobile-search-option">
                                <div className="paged">
                                    <ul className="pager">
                                        <li><a onClick={() => {
                                            this.getPreviousPage()
                                        }}><i className="fa fa-angle-left"></i></a>
                                        </li>
                                        <li><a onClick={() => {
                                            this.getNextPage()
                                        }}><i className="fa fa-angle-right"></i></a>
                                        </li>
                                    </ul>
                                </div>

                                <div className="search-option">
                                    <button className="sidebar-trigger"><i className="fa fa-filter"></i></button>
                                </div>
                                <div className="clear-filter">
                                    <button className="btn btn-warning" onClick={() => this.resetFilters()}>Clear
                                        Filter
                                    </button>
                                </div>
                                <div className="add-customer">
                                    <a href="#addCustomer" className="btn btn-custom-info" data-toggle="modal">+
                                        Add </a>
                                </div>

                            </div>

                        </div>
                        <div className="row bg-title desktop-search custom-header">
                            <div className="col-lg-1 col-md-4 col-sm-2 col-xs-12 text-center">
                                <h4 className="page-title">Customers</h4>
                            </div>
                            <div className="col-md-9 col-sm-10">
                                <div className="row">
                                    <div className="col-md-3 col-sm-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control input-sm" value={searchQuery}
                                                   name="searchQuery" onChange={this.handleChange}
                                                   placeholder="Name, Email or Phone"/>
                                        </div>
                                    </div>
                                    <div className="col-md-2 col-sm-3">
                                        <div className="form-group">
                                            <select className="form-control input-sm" value={customerType}
                                                    name="customerType" onChange={this.handleChange}>

                                                <option value="">Type of Customers</option>
                                                <option value="all">All</option>
                                                <option value="uncfrmd_appts">Unconfirmed Appointments</option>
                                                <option value="exhstd_stndngs">Exhausted Standings</option>
                                                <option value="new">New This Week</option>
                                                <option value="bday_this_mth">Birthdays This Month</option>
                                                <option value="winbck_trckng">Win Back</option>
                                            </select>

                                        </div>
                                    </div>
                                    <div className="col-md-2 col-sm-2 col-lg-2 col-xs-6">
                                        <button className="btn btn-info btn-sm" onClick={() => {
                                            this.searchCustomer()
                                        }}>Search
                                        </button>
                                    </div>

                                    <div className="col-md-5 col-sm-4 col-lg-5 col-xs-6 text-right">
                                        <button onClick={() => this.resetFilters()}
                                                className="btn btn-warning btn-sm">Clear Filters
                                        </button>
                                        &nbsp;
                                        <a href="#addCustomer" className="btn btn-custom-info btn-sm"
                                           data-toggle="modal">Add New</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2 col-sm-12">
                                <ul className="pager pull-right">
                                    <li><a onClick={() => {
                                        this.getPreviousPage()
                                    }}><i className="fa fa-angle-left"></i></a>
                                    </li>
                                    <li><a onClick={() => {
                                        this.getNextPage()
                                    }}><i className="fa fa-angle-right"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="row flex-row">
                            {
                                customers && customersLoaded == false &&
                                <React.Fragment>
                                    <div className="col-md-4 col-sm-6 col-xs-12 single-customer">
                                        <div className="loading"></div>
                                    </div>
                                    <div className="col-md-4 col-sm-6 col-xs-12 single-customer">
                                        <div className="loading"></div>
                                    </div>
                                    <div className="col-md-4 col-sm-6 col-xs-12 single-customer">
                                        <div className="loading"></div>
                                    </div>
                                    <div className="col-md-4 col-sm-6 col-xs-12 single-customer">
                                        <div className="loading"></div>
                                    </div>
                                    <div className="col-md-4 col-sm-6 col-xs-12 single-customer">
                                        <div className="loading"></div>
                                    </div>
                                    <div className="col-md-4 col-sm-6 col-xs-12 single-customer">
                                        <div className="loading"></div>
                                    </div>
                                    <div className="col-md-4 col-sm-6 col-xs-12 single-customer">
                                        <div className="loading"></div>
                                    </div>
                                    <div className="col-md-4 col-sm-6 col-xs-12 single-customer">
                                        <div className="loading"></div>
                                    </div>
                                    <div className="col-md-4 col-sm-6 col-xs-12 single-customer">
                                        <div className="loading"></div>
                                    </div>
                                </React.Fragment>
                            }
                            {
                                customers && customersLoaded == true && customers.length == 0 &&
                                <h2 style={{minWidth: "100%", textAlign: "center"}} className="center">No Customers
                                    Found</h2>
                            }
                            {customers && customersLoaded == true && customers.map((a, key) => {
                                return <div key={key} className="col-md-4 col-sm-6 col-xs-12 single-customer">
                                    <div
                                        className={a.birthdayToday == true ? "white-box card-shadow birthday" : a.sweepstakeWinnerToday == true ? "white-box card-shadow sweepstakes" : a.notSeenRecently == true ? "white-box card-shadow welcome-back bg-info" : "white-box card-shadow"}>
                                        <div
                                            className={a.birthdayToday == true || a.sweepstakeWinnerToday == true || a.wonBack == true ? "event-overlay" : ""}>
                                            <div className="col-md-3 col-sm-3 col-xs-4 text-center">
                                                <Link to={`/${this.storeId}` + "/update-customer?custId=" + a.id}>
                                                    <img src={a.imageUrl} alt="user"
                                                         className="img-circle img-responsive"/>
                                                    {this.loggedUser.role !== "TECHNICIAN" &&
                                                        <span><i className="fa fa-usd"
                                                                 aria-hidden="true"></i>{(a.rewardBalance * 1).toFixed(2)}</span>}<br
                                                    className="br"/>
                                                </Link>
                                            </div>
                                            <div className="col-md-9 col-sm-9 col-xs-8 card-content">
                                                <h3 className="box-title m-b-0 text-left">
                                                    <strong>{a.fullName}</strong>
                                                </h3>
                                                {a.notSeenRecently !== undefined && a.notSeenRecently === true &&
                                                    <div>
                                                        {a.lastFollowUpCallTime && <p
                                                            className="form-check-label"
                                                            htmlFor="contacted">Contacted
                                                            on: {helperFunctions.formatDateTime(a.lastFollowUpCallTime)}</p>}

                                                        <p>Contact Now? : <button
                                                            onClick={() => this.markedAsContacted(a.id, a.fullName)}>Yes</button>
                                                        </p>

                                                    </div>
                                                }
                                                <address>
                          <span><i className="fa fa-phone" aria-hidden="true"></i>
                            <a onClick={() => this.openCaller(a)}>{helperFunctions.formatMobilePhone(a.mobileNumber)}</a>
                          </span>
                                                    <br/>
                                                    {/*<span><i className="fa fa-phone" aria-hidden="true"></i><a>{helperFunctions.formatMobilePhone(a.mobileNumber)}</a></span><br/>*/}
                                                    <span>
                            <i className="fa fa-birthday-cake"></i>{helperFunctions.getTimelineDate(a.nextBirthdayRewardDateTime)}</span><br/>


                                                    <span><i
                                                        className="fa fa-calendar"></i> {a.lastVisit ? a.lastVisit.readableBookedTime : 'NONE'}</span><br/>

                                                    <span><i className="fa fa-random"
                                                             aria-hidden="true"></i>{a.nextVisit ? a.nextVisit.readableBookedTime : "NONE"}</span><br/>


                                                </address>
                                            </div>
                                            <div className="col-md-12 col-sm-12 col-xs-12">
                                                <div className="dropup  pull-right">
                                                    <Link className="btn btn-info"
                                                          to={`/${this.storeId}` + "/calendar-reservation?userId=" + a.id}>{this.state.actionText}</Link>
                                                    <button onClick={this.caretClass}
                                                            className="dropdown-toggle btn btn-info"
                                                            data-toggle="dropdown"
                                                            id="dropdownMenu2"><span
                                                        className={!this.state.cls ? "caret" : "caret up"}></span>
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                                        {actionText == 'Book'
                                                            ? <li><a href="#standingAppt" data-toggle="modal"
                                                                     onClick={() => this.setState({
                                                                         actionText: 'Book Standing',
                                                                         customerId: a.id
                                                                     })}>Book Standing</a></li>
                                                            : <li><a
                                                                onClick={() => this.setState({actionText: 'Book'})}>Book</a>
                                                            </li>
                                                        }

                                                    </ul>
                                                </div>

                                                {a.deleteable == true ?
                                                    (<div className="dropup  pull-left">
                                                        <a onClick={() => this.state.actionText2 !== 'Delete' ? this.mergeCustomer(a.id) : this.deleteCustomer(a.id, a.fullName)}
                                                           className="btn btn-danger"
                                                           href="javascript:void(0)">{this.state.actionText2}</a>
                                                        <button onClick={this.caretClass}
                                                                className="dropdown-toggle btn btn-danger"
                                                                data-toggle="dropdown"
                                                                id="dropdownMenu2"><span
                                                            className={!this.state.cls2 ? "caret" : "caret up"}></span>
                                                        </button>
                                                        <ul className="dropdown-menu"
                                                            aria-labelledby="dropdownMenu2">
                                                            {this.state.actionText2 == 'Delete'
                                                                ? <li><a onClick={() => this.mergeCustomer(a.id)}
                                                                         href="javascript:void(0)">Merge</a></li>
                                                                : <li><a
                                                                    onClick={() => this.deleteCustomer(a.id, a.fullName)}>Delete</a>
                                                                </li>
                                                            }

                                                        </ul>
                                                    </div>) :
                                                    (<button
                                                        onClick={() => this.mergeCustomer(a.id)}
                                                        title="Merge"
                                                        className="btn btn-danger">Merge</button>)

                                                }
                                                <button
                                                    onClick={() => this.taskSelectedCustomer(a.id)}
                                                    title="Add Task"
                                                    data-toggle="modal"
                                                    data-target="#addingTask"
                                                    className="btn btn-sm  btn-task">
                                                    <i className={"fa fa-list"}></i></button>


                                            </div>
                                        </div>

                                        <div className="add-note">

                                            <button
                                                className={`${a.staffNotes != undefined ? 'animate__animated animate__tada animate__infinite	infinite animate__slow' : ''}`}
                                                onClick={() => this.openNoteModal(a.id)} data-toggle="modal"><img
                                                style={{width: '30px'}}
                                                src={noteImg}/></button>
                                        </div>

                                        {a.staffNotes &&
                                            <div className="customerNote">
                                                <button className="on-off"><span
                                                    className="fa fa-angle-left"></span>
                                                </button>
                                                <p>{a.staffNotes != '' ? a.staffNotes : 'NONE'}</p>
                                            </div>
                                        }

                                    </div>


                                </div>
                            })
                            }
                        </div>
                    </div>

                    <Footer/>


                    <div className="modal" id="deleteModal" tabIndex="-1" role="dialog"
                         aria-labelledby="exampleModalLabel1">
                        <div className="modal-dialog  alert-modal" role="document">
                            <div className="modal-content">

                                <div className="modal-body">
                                    <div className="delete-wrapper text-center">
                                        <div className="warning-icon">!</div>
                                        <div className="block">
                                            <span>Delete Customer</span>
                                            <p>Are you sure you want to delete customer Obidul Hague ?</p>
                                        </div>
                                        <div className="delete-actions">
                                            <button className="btn btn-danger">Yes, Delete It</button>
                                            <button className="btn btn-success">No, Keep It</button>
                                            <button onClick={() => window.mergeSuggestions()}
                                                    data-toggle="modal"
                                                    data-target="#mergeModal" data-dismiss="modal"
                                                    className="btn btn-info">Merge Account
                                            </button>
                                        </div>
                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="modal" id="addCustomer" tabIndex="-1" role="dialog"
                         aria-labelledby="exampleModalLabel1">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button
                                        onClick={this.clearState}
                                        className="btn close"
                                        data-dismiss="modal">&times;</button>
                                    <h4>Add New Customer</h4>
                                </div>
                                <div className="modal-body">
                                    <NewCustomer
                                        firstName={this.state.firstName}
                                        mobile={this.state.mobile}
                                        email={this.state.email}
                                        register={this.register}/>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="modal" id="addNote" tabIndex="-1" role="dialog"
                         aria-labelledby="exampleModalLabel1">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button className="btn close" data-dismiss="modal">&times;</button>
                                    <h4>Edit Note</h4>
                                </div>
                                <div className="modal-body">

                                    <div className="form-group">
                                        <label>Customer Note: &nbsp; <span className="text-danger">*</span></label>
                                        <textarea value={this.state.customerNotes}
                                                  onChange={this.handleChange} required
                                                  maxLength="1000"
                                                  className="form-control height-200"
                                                  name="customerNotes"></textarea>

                                        <p className="text-danger">{errors &&
                                            <InlineMessage text={errors.saveNote}/>}</p>

                                    </div>


                                </div>

                                <div className="modal-footer">
                                    <button
                                        name="customerId"
                                        onClick={this.saveNote}
                                        type="submit"
                                        className="btn btn-custom-info">Update Note
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>


                    <div className="modal" id="mergeModal" role="dialog"
                         aria-labelledby="exampleModalLabel1">
                        <div className="modal-dialog  merge-modal" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <strong>Merge Account</strong>
                                    <button onClick={this.closeMergeModal} className="close"
                                            data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                    {oldCustomer && oldCustomer != null &&
                                        <div className="row">
                                            <div
                                                className="col-md-12 col-sm-12 col-xs-12 single-customer">
                                                <div
                                                    className={oldCustomer.birthdayToday == true ? "white-box card-shadow birthday" : oldCustomer.sweepstakeWinnerToday == true ? "white-box card-shadow sweepstakes" : oldCustomer.wonBack == true ? "white-box card-shadow welcome-back" : "white-box card-shadow"}>
                                                    <div
                                                        className={oldCustomer.birthdayToday == true || oldCustomer.sweepstakeWinnerToday == true || oldCustomer.wonBack == true ? "event-overlay" : ""}>
                                                        <div
                                                            className="col-md-3 col-sm-3 col-xs-4 text-center">
                                                            <Link
                                                                to={`/${this.storeId}` + "/update-customer?custId=" + oldCustomer.id}>
                                                                <img src={oldCustomer.imageUrl}
                                                                     alt="user"
                                                                     className="img-circle img-responsive"/>
                                                                <span><i className="fa fa-usd"
                                                                         aria-hidden="true"></i>{(oldCustomer.rewardBalance * 1).toFixed(2)}</span><br
                                                                className="br"/>
                                                            </Link>
                                                        </div>
                                                        <div
                                                            className="col-md-9 col-sm-9 col-xs-8 card-content">
                                                            <h3 className="box-title m-b-0 text-left">
                                                                <strong>{oldCustomer.fullName}</strong>
                                                            </h3>
                                                            <address>
                                <span><i className="fa fa-phone" aria-hidden="true"></i><a
                                    href={"tel:" + helperFunctions.formatMobilePhone(oldCustomer.mobileNumber)}>{helperFunctions.formatMobilePhone(oldCustomer.mobileNumber)}</a></span><br/>
                                                                <span><i className="fa fa-birthday-cake"
                                                                         aria-hidden="true"></i>{helperFunctions.getTimelineDate(oldCustomer.nextBirthdayRewardDateTime)}</span><br/>
                                                                <span><i className="fa fa-calendar"
                                                                         aria-hidden="true"></i> {oldCustomer.lastVisit ? oldCustomer.lastVisit.readableBookedTime : 'NONE'}</span><br/>
                                                                <span><i className="fa fa-random"
                                                                         aria-hidden="true"></i> {oldCustomer.nextVisit ? oldCustomer.nextVisit.readableBookedTime : 'NONE'}</span><br/>


                                                            </address>
                                                        </div>

                                                    </div>

                                                    {oldCustomer.staffNotes &&
                                                        <div className="customerNote">
                                                            <button className="on-off"><span
                                                                className="ti ti-angle-left"></span>
                                                            </button>
                                                            <p>{oldCustomer.staffNotes != '' ? oldCustomer.staffNotes : 'NONE'}</p>
                                                        </div>
                                                    }

                                                </div>


                                            </div>
                                        </div>
                                    }

                                    <div className="row">
                                        <div className="col-md-12 col-sm-12">
                                            <div className="arrowIcon">
                                                <i className="fa fa-arrow-down"></i>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">

                                            <CustomerSearchBar
                                                place={this.state.place}
                                                clearPlace={this.clearPlace}
                                                onCustomerSelect={this.onCustomerSelect}
                                            />
                                            <div className="switcher-spacer"></div>
                                        </div>

                                    </div>


                                    {Object.keys(newCustomer).length > 0 &&

                                        <div className="single-customer">
                                            <div
                                                className={newCustomer.birthdayToday == true ? "white-box card-shadow birthday" : newCustomer.sweepstakeWinnerToday == true ? "white-box card-shadow sweepstakes" : newCustomer.wonBack == true ? "white-box card-shadow welcome-back" : "white-box card-shadow"}>
                                                <div
                                                    className={newCustomer.birthdayToday == true || newCustomer.sweepstakeWinnerToday == true || newCustomer.wonBack == true ? "event-overlay" : ""}>
                                                    <div
                                                        className="col-md-3 col-sm-3 col-xs-4 text-center">
                                                        <Link
                                                            to={`/${this.storeId}` + "/update-customer?custId=" + newCustomer.id}>
                                                            <img src={newCustomer.imageUrl} alt="user"
                                                                 className="img-circle img-responsive"/>
                                                            <span><i className="fa fa-usd"
                                                                     aria-hidden="true"></i>{(newCustomer.rewardBalance * 1).toFixed(2)}</span><br
                                                            className="br"/>
                                                        </Link>
                                                    </div>
                                                    <div
                                                        className="col-md-9 col-sm-9 col-xs-8 card-content">
                                                        <h3 className="box-title m-b-0 text-left">
                                                            <strong>{newCustomer.name}</strong></h3>
                                                        <address>
                              <span><i className="fa fa-phone" aria-hidden="true"></i><a
                                  href={"tel:" + helperFunctions.formatMobilePhone(newCustomer.mobileNumber)}>{helperFunctions.formatMobilePhone(newCustomer.mobileNumber)}</a></span><br/>
                                                            <span><i className="fa fa-birthday-cake"
                                                                     aria-hidden="true"></i>{helperFunctions.getTimelineDate(newCustomer.nextBirthdayRewardDateTime)}</span><br/>
                                                            <span><i className="fa fa-calendar"
                                                                     aria-hidden="true"></i> {newCustomer.lastVisit !== undefined ? newCustomer.lastVisit.readableBookedTime : 'NONE'}</span><br/>
                                                            <span><i className="fa fa-random"
                                                                     aria-hidden="true"></i> {newCustomer.nextVisit !== undefined ? newCustomer.nextVisit.readableBookedTime : 'NONE'}</span><br/>


                                                        </address>
                                                    </div>

                                                </div>


                                                {newCustomer.staffNotes &&
                                                    <div className="customerNote">
                                                        <button className="on-off"><span
                                                            className="ti ti-angle-left"></span>
                                                        </button>
                                                        <p>{newCustomer.staffNotes != '' ? newCustomer.staffNotes : 'NONE'}</p>
                                                    </div>
                                                }

                                            </div>


                                        </div>
                                    }


                                </div>

                                <div className="modal-footer">
                                    <button onClick={this.processMerge} type="button"
                                            className="btn btn-custom-info waves-effect">Merge
                                    </button>

                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="modal" id="standingAppt" role="dialog"
                         aria-labelledby="exampleModalLabel1">
                        <div className="modal-dialog custom-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button className="close" data-dismiss="modal">&times;</button>
                                    <strong>Standing Appointment</strong>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="panel panel-group" id="parent">
                                                <div className="panel panel-default parent-panel">
                                                    <div className="panel-heading main-panel"
                                                         data-target="#service-staff"
                                                         data-toggle="collapse">
                                                        <p>
                                                            <a href="#service-staff">
                                                                <span>Step 1: </span>
                                                                <span>Choose Service & Staff</span>
                                                                <b className="caret"></b>
                                                            </a>
                                                        </p>
                                                    </div>

                                                    <div className="guide-first guide-common">
                                                        <div className="message-inner">
                                                            <p>
                                                                To get started, please click on "Choose
                                                                Service & Staff"
                                                                to
                                                                select a service.
                                                                <button className="close-msg one">Got It
                                                                    !</button>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="panel-body panel-collapse collapse"
                                                         id="service-staff">
                                                        <div className="form-group select-custom">
                                                            <Select2
                                                                data={this.services}
                                                                options={{
                                                                    placeholder: 'Select a service',
                                                                    width: "100%",
                                                                    height: "40px",
                                                                }}
                                                                onChange={this.handleChange}
                                                                name="service"
                                                                value={service}
                                                            />
                                                        </div>
                                                        {
                                                            // eslint-disable-next-line
                                                            technicians.length == 0 && service != "" &&
                                                            <div><p className="pt10 warning">No technician
                                                                found. Please
                                                                select another service.</p></div>
                                                        }
                                                        {technicians.length !== 0 &&
                                                            <div className="form-group">
                                                                <label style={{marginLeft: 38 + "px"}}>Select
                                                                    Staff</label>
                                                                <select className="hidden" name="technician"
                                                                        id="technicians-select-stand"
                                                                        value={technician}
                                                                        onChange={this.handleChange}>
                                                                    <option key={0} value="any">Any Staff
                                                                    </option>
                                                                    {
                                                                        technicians.map((e, key) => {
                                                                            return <option key={e.id}
                                                                                           value={e.id}>{e.name}</option>;
                                                                        })
                                                                    }
                                                                </select>
                                                                <div
                                                                    id="technicians-select-div-stand"></div>
                                                                <div className="guide-second">
                                                                    <p>Good. You have picked your service.
                                                                        Next, select
                                                                        your
                                                                        preferred staff.</p>
                                                                    <button className="close-msg two">Got It
                                                                        !
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className="service-res pull-left">

                                                        </div>

                                                        <div className="cart-btn text-right">
                                                            <button className="btn btn-info"
                                                                    onClick={() => this.addReservation()}>Add
                                                                To Cart
                                                            </button>
                                                            <div className="guide-third">
                                                                <p>Very nice! Please click "Add To Cart"
                                                                    button to add
                                                                    them
                                                                    to the cart.</p>
                                                                <button className="close-msg third">Got
                                                                    It!
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {
                                                selectedServices.length != 0 &&
                                                <div className="panel panel-group">
                                                    <div
                                                        className="panel panel-default parent-panel date-panel">
                                                        <div className="panel-heading main-panel">
                                                            <p>
                                                                <a href="#date-time" data-toggle="collapse">
                                                                    <span>Step 2: </span>
                                                                    <span>Choose Date & Time</span>
                                                                    <b className="caret"></b>
                                                                </a>
                                                            </p>

                                                            <div className="guide-common guide-fourth">
                                                                <p>
                                                                    Now It is time to select expected date
                                                                    and time.
                                                                    Please
                                                                    click on "Choose Date & Time" to select.
                                                                    <button className="close-msg fourth">Got
                                                                        It
                                                                        !</button>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="panel-body panel-collapse collapse"
                                                             id="date-time">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <DatePickerInput
                                                                            showOnInputClick={true}
                                                                            placeholder="MM/DD/YYYY"
                                                                            minDate={new Date()}
                                                                            readOnly
                                                                            onChange={this.handleDateRangeStart}
                                                                            className={"date"}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <DatePickerInput
                                                                            showOnInputClick={true}
                                                                            placeholder="MM/DD/YYYY"
                                                                            minDate={new Date()}
                                                                            readOnly
                                                                            onChange={this.handleDateRangeEnd}
                                                                            className="date"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <label>Choose Time</label>
                                                                        <select name="time"
                                                                                onChange={this.handleChange}
                                                                                className="form-control">
                                                                            <option value="ANYTIME">Any
                                                                                Time
                                                                            </option>
                                                                            <option value="MORNING">Morning
                                                                                (Before
                                                                                12PM)
                                                                            </option>
                                                                            <option
                                                                                value="AFTERNOON">Afternoon
                                                                                (12PM to
                                                                                5PM)
                                                                            </option>
                                                                            <option value="EVENING">Evening
                                                                                (After 5PM)
                                                                            </option>
                                                                            <option value="TIME_RANGE">Pick
                                                                                a Time range
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {
                                                                time != '' && time === "TIME_RANGE" &&
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div className="form-group">
                                                                            <label className="m-t-20">Start
                                                                                Time</label>

                                                                            <CustomTimePicker name={"startTime"}
                                                                                              onChange={this.handleStartTime}
                                                                                              format={'hh:mm a'}
                                                                                              value={this.state.timeStart}/>

                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group">
                                                                            <label className="m-t-20">End
                                                                                Time</label>
                                                                            <CustomTimePicker name={"endTime"}
                                                                                              onChange={this.handleEndTime}
                                                                                              format={'hh:mm a'}
                                                                                              value={this.state.timeEnd}/>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>

                                                    </div>
                                                    <p className="text-danger">{errors.datePreference &&
                                                        <InlineMessage text={errors.datePreference}/>}</p>
                                                </div>}

                                            {
                                                selectedServices.length != 0 &&
                                                <div className="panel panel-group">
                                                    <div
                                                        className="panel panel-default parent-panel date-panel">
                                                        <div className="panel-heading main-panel">
                                                            <p>
                                                                <a href="#interval" data-toggle="collapse">
                                                                    <span>Step 3: </span>
                                                                    <span>Interval Control</span>
                                                                    <b className="caret"></b>
                                                                </a>
                                                            </p>


                                                        </div>


                                                        <div className="panel-body panel-collapse collapse"
                                                             id="interval">


                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label>Day Of Week</label>
                                                                        <select onChange={this.handleChange}
                                                                                name="daysOfWeek"
                                                                                className="form-control">
                                                                            <option value="any">Any Day
                                                                            </option>
                                                                            <option value="SUNDAY">Sunday
                                                                            </option>
                                                                            <option value="MONDAY">Monday
                                                                            </option>
                                                                            <option
                                                                                value="TUESDAY">Tuesday
                                                                            </option>
                                                                            <option
                                                                                value="WEDNESDAY">Wednesday
                                                                            </option>
                                                                            <option
                                                                                value="THURSDAY">Thursday
                                                                            </option>
                                                                            <option value="FRIDAY">Friday
                                                                            </option>
                                                                            <option
                                                                                value="SATURDAY">Saturday
                                                                            </option>
                                                                        </select>
                                                                    </div>

                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label>Day Between</label>
                                                                        <select
                                                                            name="daysBetween"
                                                                            value={this.state.daysBetween}
                                                                            onChange={this.handleChange}
                                                                            className="form-control">
                                                                            <option value="none">Select
                                                                                Between
                                                                            </option>
                                                                            {Array.from({length: 60}).map((_, index) => {
                                                                                return <option
                                                                                    value={index + 1}>{index + 1}</option>
                                                                            })
                                                                            }

                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>

                                                    </div>
                                                </div>}


                                            <div className="selected-result">
                                                <div className="reservation-rows">
                                                    {
                                                        selectedServices.length != 0 &&
                                                        selectedServices.map((e, key) => {
                                                            return <p key={key}>
                                                                <b>{e.serviceName}</b> with <b>{e.technicianName}</b>
                                                                <a href="#"
                                                                   onClick={() => this.deleteReservation({e})}><span
                                                                    className="fa fa-trash-o text-danger pull-right"></span></a>
                                                            </p>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            {
                                                selectedServices.length != 0 && time !== '' && (time !== "TIME_RANGE" || (time === "TIME_RANGE" && timeStart != '' && timeEnd != '')) &&
                                                <div className="check">
                                                    <button onClick={() => this.checkAvailability()}
                                                            className="btn btn-block btn-info">Check
                                                        Availability
                                                    </button>
                                                </div>
                                            }

                                        </div>

                                        <div className="col-md-6">
                                            {showSlots > 0 && slots && slots.length != 0 && !placingReservation && !reservationPlaced &&
                                                <div className="book-all">
                                                    <button
                                                        onClick={() => this.setAppointmentTime(slots, 'All')}
                                                        className="btn btn-danger">Book All
                                                    </button>
                                                </div>
                                            }
                                            {
                                                // eslint-disable-next-line
                                                findingAvailability && !placingReservation && !reservationPlaced &&
                                                <div className="loaderDiv">
                                                    <h3>Finding available slots that work for you...</h3>
                                                    <div className="loader"></div>
                                                </div>
                                            }
                                            {
                                                // eslint-disable-next-line
                                                slots && slots.length == 0 &&
                                                <div className="alert-message">
                                                    <div className="message-inner text-center">
                                                        <div className="message-icon">
                                                            <img width="100" src="/assets/images/undone.png"
                                                                 alt=""/>
                                                        </div>
                                                        <div className="message-text text-center">
                                                            <p>Unfortunately there were no appointment slots
                                                                for your
                                                                current preferences. Please change your
                                                                preferences!</p>
                                                        </div>

                                                    </div>
                                                </div>
                                            }
                                            {
                                                showSlots > 0 && slots && slots.length != 0 && !placingReservation && !reservationPlaced &&

                                                slots.map((e, key) => {
                                                    return <div className="slection" key={key}>
                                                        {
                                                            <div className="card">
                                                                {
                                                                    e.visitTechnicians.map((s, k) => {
                                                                        return <div key={k}>
                                                                            <div className="card-body">
                                                                                <div className="card-left">
                                                                                    <h4>
                                                                                        <b>{this.findServiceName(s.offeredService.id, s.offeredService.category.id)}</b> with <b>{this.findTechnicianName(s.technician.id)}</b>
                                                                                    </h4>
                                                                                    <p>Date: <b> {this.formatDateTime(s.expectedStartTime, 'date')}</b>
                                                                                    </p>
                                                                                    <p>Duration: <b>{this.findServiceDuration(s.offeredService.id, s.technician.id, s.offeredService.category.id)} min</b>
                                                                                    </p>
                                                                                    <p>Time: <b>{this.formatDateTime(s.expectedStartTime, 'time')}</b>
                                                                                    </p>
                                                                                </div>
                                                                                <div className="tech-thumb">
                                                                                    <img
                                                                                        src={this.findTechnicianImage(s.technician.id)}
                                                                                        alt="tech"/>
                                                                                    <p className="text-center">
                                                                                        <b>{this.findServicePrice(s.offeredService.id, s.technician.id, s.offeredService.category.id)}</b>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                k != e.visitTechnicians.length - 1 &&
                                                                                <div
                                                                                    className="card-diver"></div>
                                                                            }
                                                                        </div>
                                                                    })
                                                                }
                                                                <div className="card-footer">
                                                                    <button
                                                                        data-toggle="modal"
                                                                        data-target="#reservationNote"
                                                                        data-dismiss="modal"
                                                                        className="btn add-btn"
                                                                        onClick={() => this.setAppointmentTime(e.visitTechnicians)}>Book
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                })
                                            }
                                            {
                                                // eslint-disable-next-line
                                                placingReservation &&
                                                <div className="loaderDiv">
                                                    <h3>Placing reservation ...</h3>
                                                    <div className="loader"></div>
                                                </div>
                                            }

                                        </div>


                                        <div className="height-spacer"></div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="reservationNote">
                        <div className="modal-dialog">
                            <div className="modal-content">

                                <div className="modal-header">
                                    <button
                                        data-toggle="modal"
                                        data-target="#standingAppt"
                                        data-dismiss="modal"
                                        onClick={this.closeThisModal} className="close"><i
                                        className="fa fa-close"></i>
                                    </button>
                                    <h4>Add Reservation Note.</h4>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group">
                    <textarea rows="10" className="form-control" value={this.state.notes} name="notes"
                              onChange={this.handleChange}
                              placeholder="Enter any notes for technician..."></textarea>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button onClick={() => {
                                        this.placeReservation()
                                    }} className="btn btn-custom-info">Confirm
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="modal fade" id="addingTask" tabIndex="-1" role="dialog"
                         aria-hidden="true">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal"
                                            aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    <h4 className="modal-title">Add Task</h4>
                                </div>
                                <div className="modal-body">

                                    <AddTask
                                        statas="disabled"
                                        predefinedRelatedItem={this.state.taskSelected.taskSelectedCustomer}
                                        predefinedRelatedId={this.state.taskSelected.taskSelectedItemId}
                                        predefinedRelatedTo="Customer"
                                        mode={this.state.mode}
                                        saveTask={this.saveTask}/>
                                </div>


                            </div>

                        </div>

                    </div>

                </div>

            </React.Fragment>
        )
            ;
    }


    mergeCustomer = (id) => {
        this.setState({
            actionText2: 'Merge'
        })
        $('#mergeModal').modal('toggle');

        this.searchOldCustomer(id)
    }


    deleteCustomer = (id, name) => {
        this.setState({
            actionText2: 'Delete'
        })
        Swal.fire({
            title: 'Delete Customer',
            text: `Are you sure you want to delete customer ${name}`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result) => {
            if (result.value) {
                const {dispatch} = this.props;
                userService.deleteCustomer(id).then(c => {

                    dispatch(userActions.getAllCustomers("all", 0))
                    window.toast('error', `Customer ${name} has been deleted from the system`)
                }).catch(e => {
                    e.json().then(err => {

                        Swal.fire({
                            title: 'Merge',
                            text: `${err.message}. You can merge it.`,
                            type: 'warning',
                            showCancelButton: true,
                            confirmButtonText: 'Yes, Merge!',
                            cancelButtonText: 'No, Not now!'
                        }).then((result) => {
                            if (result.value) {
                                $('#mergeModal').modal('toggle');

                                this.searchOldCustomer(id)
                            }
                        })

                    })

                })
            }
        })
    }

    searchOldCustomer = (id) => {
        let oldCustomer = this.props.customers.find(c => c.id == id)
        this.setState({
            oldCustomer
        })
    }
    selectedNewCustomer = (id) => {

        let newCustomer = this.state.mergeCustomer.find(c => c.id == id);

        this.setState({
            newCustomer
        });
    }

    taskSelectedCustomer = (id) => {

        let taskSelectedCustomer = this.props.customers.find(c => c.id == id);

        this.setState({
            taskSelected: {
                ...this.state.taskSelected,
                taskSelectedCustomer: taskSelectedCustomer.fullName,
                taskSelectedItemId: taskSelectedCustomer.id
            }
        });
    }


    getNextPage() {
        var offset = this.state.pageOffset + 1;
        const {customers, dispatch} = this.props;
        if (customers && customers.length != 0) {
            dispatch(userActions.getAllCustomers(this.state.customerType, offset, this.state.searchQuery));
            this.setState({
                pageOffset: offset,
                newCustomer: '',
                oldCustomer: '',
                selectedOption: '',
                mergeCustomer: '',
                searchQuery: ''
            });
        }

    }

    getPreviousPage() {
        var offset = this.state.pageOffset - 1;
        if (offset >= 0) {
            const {dispatch} = this.props;
            dispatch(userActions.getAllCustomers(this.state.customerType, offset, this.state.searchQuery));
            this.setState({
                pageOffset: offset,
                newCustomer: '',
                oldCustomer: '',
                selectedOption: '',
                mergeCustomer: '',
                searchQuery: ''
            });
        }
    }

    searchCustomer() {
        const {dispatch} = this.props;
        dispatch(userActions.getAllCustomers(this.state.customerType, 0, this.state.searchQuery))
    }

    searchMergeCustomer() {
        const {dispatch} = this.props;
        this.setState({
            mergeSearch: true
        });
        if (this.state.searchQuery != '') {
            userService.getSpecificCustomer('all', 0, this.state.searchQuery).then(res => {
                this.setState({
                    mergeCustomer: res
                });
            })
        }


    }

    register = (newUser) => {
        const {dispatch} = this.props;
        userService.register(newUser).then(res => {
            $('#addCustomer').modal('toggle');
            window.toast('success', 'A new customer is successfully added!')
            dispatch(userActions.getAllCustomers('all', this.state.pageOffset))
        }).catch(err => {
            err.json().then(e => {
                Swal.fire({
                    title: 'Error',
                    text: `${e.message}`,
                    type: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Ok!'
                })
            })
        })


    }


    resetFilters() {
        this.setState({pageOffset: 0});
        this.setState({customerType: 'all'});
        this.setState({searchQuery: ''});
        const {dispatch} = this.props;
        dispatch(userActions.getAllCustomers('all', 0))
    }


    //reservation
    handleStartTime(value) {
        if (value != null) {
            this.setState({
                timeStart: value.format('HH:mm a')
            })
        }
    }

    handleEndTime(value) {
        if (value != null) {
            this.setState({
                timeEnd: value.format('HH:mm a')
            })
        }
    }

    setDate(date) {
        this.setState({
            date: date,
            time: '',
            dateStart: '',
            dateEnd: ''
        });
    }

    setTime(time) {
        this.setState({
            time: time,
            timeStart: '',
            timeEnd: ''
        });
    }

    findTechnicianName(technicianId) {

        // eslint-disable-next-line
        let technician = this.app.locations[0].technicians.find(technician => technician.id == technicianId)
        return technician.user.names.nick;
    }

    findServiceName(serviceId) {
        // eslint-disable-next-line
        let service = this.state.services.find(service => service.id == serviceId)
        return service.name;
    }

    findTechnicianImage(technicianId) {
        // eslint-disable-next-line
        let technician = this.app.locations[0].technicians.find(technician => technician.id == technicianId)
        return technician.user.imageUrl;
    }

    findServicePrice(serviceId, techId, catId) {
        const {categories} = this.state

        let servicePrice = ""
        if (this.state.technician == "any") {
            let s = categories.find(c => c.id == catId)
                .services.find(service => service.id == serviceId)

            servicePrice = s.minimumFee == -1 && s.maximumFee == -1 ? "Per Consult" :
                s.minimumFee > -1 && s.maximumFee == -1 ? "From $" + s.minimumFee :
                    (s.minimumFee > -1 && s.maximumFee > -1) && (s.minimumFee == s.maximumFee) ? "$" + s.minimumFee :
                        (s.minimumFee > -1 && s.maximumFee > -1) && (s.minimumFee != s.maximumFee) ? "$" + s.minimumFee + " - $" + s.maximumFee : "";

        } else {
            let s = this.app.locations[0].technicians.find(tech => tech.id == techId).offeredServices.find(service => service.id == serviceId)

            servicePrice = s.minimumFee == -1 && s.maximumFee == -1 ? "Per Consult" :
                s.minimumFee > -1 && s.maximumFee == -1 ? "From $" + s.minimumFee :
                    (s.minimumFee > -1 && s.maximumFee > -1) && (s.minimumFee == s.maximumFee) ? "$" + s.minimumFee :
                        (s.minimumFee > -1 && s.maximumFee > -1) && (s.minimumFee != s.maximumFee) ? "$" + s.minimumFee + " - $" + s.maximumFee : "";
        }

        return servicePrice;
    }

    findServiceDuration(serviceId, techId, catId) {
        const {categories} = this.state
        let serviceDuration = ""
        if (this.state.technician == "any") {
            serviceDuration = categories.find(cat => cat.id == catId)
                .services.find(service => service.id == serviceId).durationInMinutes
        } else {
            serviceDuration = this.app.locations[0].technicians.find(tech => tech.id == techId)
                .offeredServices.find(service => service.id == serviceId).durationInMinutes
        }

        return serviceDuration;
    }

    checkAllApointmentsSelected() {
        let timeSelected = true;
        let selectedServices = this.state.selectedServices;
        selectedServices.forEach(service => {
            if (!service.dateTime)
                timeSelected = false;
        });
        if (!timeSelected)
            this.setState({timeSelected: false})
        else {
            this.setState({timeSelected: true});
        }

        return timeSelected;
    }

    placeReservation() {
        const selectedServices = this.state.selectedServices;
        selectedServices.forEach(service => {

            const apptJSON = {
                location: {
                    id: this.app.locations[0].id
                },
                client: {
                    id: this.state.customerId
                },
                requestSource: 'Walk In',
                requestType: 'Appointment',
                standingAppointment: true,
                customerNotes: this.state.notes,
                visitTechnicians: [{
                    technician: {
                        id: service.technician
                    },
                    offeredService: {
                        id: service.offeredService
                    },
                    expectedStartTime: service.expectedStartTime,
                    period: {
                        to: service.expectedEndtTime

                    }
                }],
                purchaseItems: []
            }

            reservationService.saveAppointment(apptJSON).then(res => {
                this.setState({
                    loading: false,
                    notes: '',
                    nmodal: '',
                    reservationPlaced: true
                })
            })
        })

        $('#reservationNote').modal('hide');
        $('#standingAppt').modal('hide');
        window.toast('success', `Standing Appointment has been added to the client`);
        this.deleteAllReservations()
    }

    setAppointmentTime = (appointments, all) => {

        if (all != undefined) {
            Swal.fire({
                title: 'Warning',
                text: `Are you sure to book all?`,
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            }).then(result => {
                if (result.value) {
                    let selectedServices = [];
                    if (appointments.length > 0) {

                        appointments.map(a => a.visitTechnicians.map(appointment => {
                            selectedServices.push({
                                technician: appointment.technician.id,
                                offeredService: appointment.offeredService.id,
                                expectedStartTime: appointment.expectedStartTime,
                                expectedEndtTime: appointment.period.to,
                                serviceName: this.findServiceName(appointment.offeredService.id),
                                technicianName: this.findTechnicianName(appointment.technician.id)
                            })
                        }))

                    }
                    this.setState({selectedServices: selectedServices});
                    $('#reservationNote').modal('show');
                    $('#standingAppt').modal('hide');
                    this.checkAllApointmentsSelected();
                }
            })


        } else {
            let selectedServices = this.state.selectedServices;
            let thisObj = this;

            selectedServices.forEach(service => {
                // eslint-disable-next-line
                let appointment = appointments.find(appointment => appointment.offeredService.id == service.serviceId);
                if (appointment) {
                    service.technician = appointment.technician.id;
                    service.offeredService = appointment.offeredService.id;
                    service.expectedStartTime = appointment.expectedStartTime;
                    service.expectedEndtTime = appointment.period.to;

                } else {
                    service.technicianId = '';
                    service.technicianName = 'No one'
                    service.date = '';
                    service.time = '';
                    service.dateTime = null;
                }

                this.setState({selectedServices: selectedServices});
                $('#reservationNote').modal('show');
                $('#standingAppt').modal('hide');
                this.checkAllApointmentsSelected();


            });
        }


    }


    checkAvailability() {
        const {dispatch} = this.props;

        let serviceTechnicians = []
        this.state.selectedServices.forEach(service => {
            // eslint-disable-next-line
            if (service.technicianId == 'any') {
                serviceTechnicians.push({
                    offeredService: {
                        id: service.serviceId
                    }
                })
            } else {
                serviceTechnicians.push({
                    technician: {
                        id: service.technicianId
                    },
                    offeredService: {
                        id: service.serviceId
                    }
                })
            }

        });
        var timeStart = this.state.timeStart
        var timeEnd = this.state.timeEnd
        var daysOfWeek = this.state.daysOfWeek
        var daysBetween = this.state.daysBetween

        if (timeStart.split(":").length == 2) {
            let trimedTime = timeStart.replace(/[^a-zA-Z ]/g, "");
            timeStart = timeStart.split(trimedTime)[0] + ":00";

        }

        if (timeEnd.split(":").length == 2) {
            let trimedTime = timeEnd.replace(/[^a-zA-Z ]/g, "");
            timeEnd = timeEnd.split(trimedTime)[0] + ":00";
        }
        if (this.state.time != "TIME_RANGE") {
            timeStart = timeEnd = ""
        }

        if (this.state.daysOfWeek == 'any') {
            daysOfWeek = daysBetween == ''
        } else {
            daysOfWeek = this.state.daysOfWeek
            daysBetween = this.state.daysBetween
        }

        if (this.state.dateStart != '' && this.state.dateEnd != '') {
            dispatch(reservationActions.checkAvailability(this.state.date, this.state.dateStart, this.state.dateEnd, this.state.time, timeStart, timeEnd, serviceTechnicians, daysBetween, daysOfWeek))
            this.setState({
                showSlots: true,
                errors: {}
            })
        } else {
            this.setState({
                errors: {
                    datePreference: 'Please select start and end date'
                }
            })
        }


    }

    addReservation() {
        // eslint-disable-next-line
        if (this.state.technician != '' && this.state.service != '') {
            let selectedServices = this.state.selectedServices;
            // eslint-disable-next-line
            let exists = selectedServices.findIndex(reservation => reservation.serviceId == this.state.service) != -1 ? true : false
            if (!exists) {
                this.setState({reservationExists: false});
                let service = {
                    reservationId: this.state.service + this.state.technician,
                    serviceId: this.state.service,
                    // eslint-disable-next-line
                    serviceName: this.state.services.find(service => service.id == this.state.service).name,
                    technicianId: this.state.technician,
                    // eslint-disable-next-line
                    technicianName: this.state.technician === "any" ? "Any Staff" : this.state.technicians.find(technician => technician.id == this.state.technician).name,
                }

                selectedServices.push(service);
                this.setState({selectedServices: selectedServices});
            } else {
                this.setState({reservationExists: true});
            }
        }
    }

    deleteReservation(item) {
        let selectedServices = this.state.selectedServices;
        // eslint-disable-next-line
        let index = selectedServices.findIndex(service => service.reservationId == item.e.reservationId);
        selectedServices.splice(index, 1);
        this.setState({
            selectedServices: selectedServices
        });
        // eslint-disable-next-line
        if (selectedServices.length == 0) {
            this.initializeReservations();
        } else {
            this.checkAvailability()
        }
    }

    deleteAllReservations() {
        this.setState({selectedServices: []});
        this.initializeReservations();
    }


    formatDateTime(dateTime, returnType) {
        if (!sessionStorage.getItem('timezone')) {
            var tz = jstz.determine() || 'UTC';
            sessionStorage.setItem('timezone', tz.name());
        }

        var currTz = sessionStorage.getItem('timezone');
        var momentTime = moment(dateTime)
        var tzTime = momentTime.tz(currTz);


        if (returnType == "date")
            return tzTime.format('ddd, MMMM Do, YYYY');
        else if (returnType == "time")
            return tzTime.format('hh:mm a');
        else
            return tzTime.format('hh:mm a, MMMM Do, YYYY');
    }

    initializeReservations() {
        this.setState({
            technician: '',
            technicians: [],
            service: '',
            reservationExists: false,
            showSlots: false,
            notes: ''
        })
    }

}


function mapStateToProps(state) {
    const {customers, customersLoaded} = state.customers;
    const {
        findingAvailability,
        foundAvailability,
        slots,
        placingReservation,
        reservationPlaced,
        reservationError
    } = state.reservation;
    const {registering, registerSuccess, registrationError} = state.registration;

    return {
        customers,
        customersLoaded,
        findingAvailability,
        foundAvailability,
        slots,
        placingReservation,
        reservationError,
        registering,
        registerSuccess,
        registrationError
    };
}


const connectedCustomersPage = connect(mapStateToProps)(CustomersPage);
export {connectedCustomersPage as CustomersPage};
