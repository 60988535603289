/* eslint-disable */
import React from "react";
import {connect} from "react-redux";
import {userActions} from "../../_actions";
import {userService} from "../../_services";
import {appService} from "../../_services";
import {reservationService} from "../../_services";
import {helperFunctions} from "../../_helpers";
import CustomerSearchBar from "../../_components/customerSearchBar";
import ServiceSearchBar from "../../_components/serviceSearchBar";
import InlineMessage from "../../_components/inlineMessage";
import {Link} from "react-router-dom";
import {isSupported, getMessaging, getToken} from 'firebase/messaging'
import "./reservationDetail.css";
import "rc-datetime-picker/dist/picker.min.css";
//import $ from "jquery";
import moment from "moment";
import {DatetimePickerTrigger} from "rc-datetime-picker";

const now = helperFunctions.getTodayDateTime();
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import GroupCart from "../../_components/groupCart";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import qs from "qs";
import "rc-time-picker/assets/index.css";

const format = "hh:mm a";
const timeNow = moment().hour(0).minute(0);

import CheckOut from "../../_components/checkOut";
import CheckIn from "../../_components/checkIn";
import EditCart from "../../_components/editCart";
import AddTask from "../../_components/addTask";
import NewCustomer from "../../_components/newCustomer";

import AddNewAppointmentModal from "../../_components/addAppointmentModal";
import AddNewWaitlist from "../../_components/addNewWaitlist";

import ViewDetails from "../../_components/viewDetails";
import Odometer from "react-odometerjs";
import "odometer/themes/odometer-theme-default.css";
import Persona from "../../_components/persona";
import {communicationAction} from "../../_actions/communication.actions";
import {outgoingAction} from "../../_actions";
import ReactDOM from "react-dom";
import {appActions} from "../../_actions/app.actions";
import CustomTimePicker from "../../_components/custom-timer-picker";
import _ from "lodash";

import DatePicker, {Calendar} from 'react-multi-date-picker'
import firebase from "firebase/app";


window.odometerOptions = {
    formatFunction: function (value) {
        return value.toFixed(2);
    },
};

class ReservationCalendarPage extends React.Component {
    _isMount = false;

    constructor(props) {
        super(props);
        this.app = JSON.parse(localStorage.getItem("app"));
        this.storeId = this.app.vanityId;
        this.calendarDate = new Date();
        this.startApp = true;
        this.parentCategoriesExist =
            this.app.offeredServiceCategories.filter((osc) => osc.parent != null)
                .length > 0;
        var userRole = JSON.parse(localStorage.getItem("user")).role;

        this.accessRole = userRole == "MANAGER" || "DIRECTOR" ? true : false;
        this.loggedUser = JSON.parse(localStorage.getItem("user"));
        this.categories = [];
        this.parentCategories = [];
        // if (this.parentCategoriesExist) {
        //     this.app.offeredServiceCategories.filter(osc => osc.parent == null).forEach(category => {
        //         this.parentCategories.push(<option key={category.id} value={category.id}>{category.name}</option>);
        //     })
        // } else {
        //     this.app.offeredServiceCategories.forEach(category => {
        //         this.categories.push(<option key={category.id} value={category.id}>{category.name}</option>);
        //     })
        // }
        this.events = [];
        this.userId = localStorage.getItem("ids")
            ? JSON.parse(localStorage.getItem("ids"))
            : qs.parse(this.props.location.search.replace("?", "")).userId;
        this.state = {
            calendarEvents: [
                // initial event data
                {title: "Event Now", start: new Date()},
            ],
            parentCategory: "",
            showParentCategory: this.parentCategoriesExist,
            category: "",
            visitId: "",
            categories: [],
            technicians: [],
            resources: [],
            teams: [],
            selectedTechnician: "all",
            selectedTeam: "all",
            getAllTechnicians: true,
            selectedTechnicianName: "",
            timeStart: "",
            timeEnd: "",
            dayOfWeek: "",
            daysBetween: "",
            reservationType: "",
            requestSource: "Phone Call",
            notes: "",
            service: "",
            services: [],
            techServiceError: false,
            booking: false,
            useSelectedDate: false,
            currentStartDate: "",
            currentEndDate: "",
            timeOffRequestType: "time-off",
            open: false,
            menuState: false,
            dateClickMenu: false,
            editApptDetails: {},
            taskSelected: {
                taskSelectedClient: "",
                taskSelectedItemId: "",
            },
            loading: false,
            viewDetails: {},
            mode: "",
            selectNewCustomer: true,
            weeks: "",
            key: 1,
            cartCollections: [],
            totalCharge: 0,

            cashPaidAmount: 0,
            checkPaidAmount: 0,
            creditPaidAmount: 0,
            discountDeductionAmount: 0,
            giftCardPaidAmount: 0,
            productPurchaseAmount: 0,
            rewardDeductionAmount: 0,
            serviceChargeAmount: 0,
            taxAmount: 0,
            tipAmount: 0,
            calendarDate: new Date(),
            offStart: "",
            offEnd: "",
            cartAdded: "",
            customerDetails: "",
            waitlist: "",
            techValue: "",
            singleWaitList: "",
            customerId: "",

            selectedServices: [],
            technician: [],
            techServices: [],
            startTime: moment(),
            endTime: moment(),
            staffNotes: "",
            smodal: false,
            place: "",
            editMode: "save",
            servicename: "",
            customer: "",
            pastAppts: [],
            openAppts: [],
            miniModal: false,
            wtid: "",
            ends: "",
            pageOffset: 0,
            errors: {},
            editOption: "save",
            customerFromCustomer: "",
            lastAppts: [],
            checkOut: false,
            selectedWait: [],
            selected: [],
            waitCustomer: "",
            customerFromWaitCustomer: "",
            tribes: [],
            calendarShow: false,
            prevQuestions: [],
            singleUser: "",
            processing: false,
            replace: false,
            presentDate: ''
        };
        if (this.userId) {
            userService.getCustomerInfo(this.userId).then((user) => {
                this.setState({
                    customerDetails: user,
                    customerFromCustomer: user,
                    selectNewCustomer: false,
                });
            });
        }

        this.calendarView = "dayGridMonth";
        this.handleChange = this.handleChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.changeCalendarView = this.changeCalendarView.bind(this);
        this.selectTimeSlot = this.selectTimeSlot.bind(this);
        this.eventClickHandler = this.eventClickHandler.bind(this);
        this.resetDayClosedBook = this.resetDayClosedBook.bind(this);
        this.calendarRef = React.createRef();
        //fetch category
        this.getCategories();


        this.onchangeTextDelayed = _.debounce(this.changeDateToRender, 800)
    }

    static getDerivedStateFromProps = (props, state) => {
        return null;
    };

    getCategories() {
        appService
            .getCategories(0, 200)
            .then((categories) => {
                this.setState({categories});
            })
            .catch((e) => {
                console.log(e);
            });
    }

    componentDidMount = async () => {
        const metaInfo = await appService.checkVersion();
        await helperFunctions.clearCache(metaInfo)
        let {dispatch} = this.props;

        this._isMount = true;
        var thisObj = this;
        if (this._isMount) {
            this.getAllTechnicians();
        }
        window.initiateScrollCalendar();
        this.initializeWaitList();

        dispatch(appActions.generateReloader(this.reloadCalendar));
    }


    reloadCalendar = (data) => {
        console.log("===FIREBASE====", data)
        if (data != undefined && data.bookedTime != undefined) {
            if (Object.keys(data).length > 0) {
                if (this.props.reloadData && this.props.reloadData.bookedTime) {
                    this.reloadTodayScreen(this.props.reloadData.bookedTime);
                }
            }
        }
    };

    initializeWaitList = () => {
        let startDate = moment(this.state.calendarDate).format("YYYY-MM-DD");
        let endDate = moment(startDate).add(1, "days").format("YYYY-MM-DD");
        reservationService
            .getAllWaitList(0, "open-lctn", "", startDate, endDate)
            .then((res) => {
                this.setState({
                    waitlist: res,
                });
            });
    };

    getAllTechnicians(team) {
        this.setState({
            useSelectedDate: true,
        });
        userService
            .getAllTechniciansForCalendarReservation("all", 0)
            .then((techs) => {
                var bookableTechs = this.technicianSorting(
                    techs.filter((tech) => tech.bookable)
                );

                var resources = [];
                var teams = [];
                bookableTechs.forEach((tech) => {
                    tech.title = tech.user.names.nick;
                    tech.imgageUrl = tech.user.imageUrl;
                    if ((team != null && tech.team == team) || team == null) {
                        resources.push(tech);
                    }
                    if (!teams.includes(tech.team)) {
                        teams.push(tech.team);
                    }
                });

                window.initializeCheckResource(resources.length);

                this.setState({
                    technicians: resources,
                    resources: resources,
                    teams: teams,
                });
            });
    }

    technicianSorting = (techs) => {
        return techs.sort((a, b) => b.displayOrder - a.displayOrder);
    };

    changeRequestSource(source) {
        this.setState({requestSource: source});
    }

    clicked = (event) => {
        this.setState({calendarShow: !this.state.calendarShow})
        let element = document.querySelector('.custom-calendar-picker-component')
        if (element !== null) {

            element.style.top = (event.pageY + 20) + "px";
            if (window.innerWidth >= 760) {

                element.style.left = event.pageX + "px"
            } else {

                element.style.left = '50%';
                element.style.marginLeft = '-45%'

            }

        }


    }

    handleDateChange = (info, successCallback, failureCallback) => {
        var start = info.start;
        start = moment(start);
        var end = info.end;
        var techSchedule =
            this.state.getAllTechnicians == true
                ? "all"
                : this.state.selectedTechnician;
        start = moment(start).format("YYYY-MM-DD");
        end = moment(end).format("YYYY-MM-DD");
        if (moment(end).diff(start, "days") <= 1) end = start;
        this.setState({presentDate: start})

        //waiting for delay searchkey to fire the request
        this.onchangeTextDelayed(techSchedule, start, end, successCallback)

    }

    changeDateToRender = (techSchedule, start, end, successCallback) => {
        userService.getTechniciansSchedule(techSchedule, start, end).then(
            (slots) => {
                this.events = slots;
                this.state.calendarDate = start;
                this.setState({calendarDate: start, ends: end});

                this.startApp = false;

                successCallback(slots);

            },
            (error) => {
                this.setState({loading: false});
            }
        );
        if (this.state.techValue != "") {
            reservationService
                .getAllWaitList(0, "open-tech", "", start, end, this.state.techValue)
                .then((res) => {
                    this.setState({
                        waitlist: res,
                    });
                });
        } else {
            reservationService
                .getAllWaitList(0, "open-lctn", "", start, end)
                .then((res) => {
                    this.setState({
                        waitlist: res,
                    });
                });
        }
    };

    initializeServiceSelect() {
        this.getAllTechnicians();
        this.setState({
            service: !this.state.service,
            buttonText: !this.state.buttonText,
            smodal: !this.state.smodal,
            editOption: "save",
        });
    }

    addSelectedService = () => {
        var service = {};
        service.key = this.state.selectedServices.length + 1;
        service.servicename = this.state.serviceName;
        service.technicians = this.state.technician;
        service.service = this.state.serviceId;
        (service.from = this.state.startTime), (service.to = this.state.endTime);
        if (this.state.endTime.diff(this.state.startTime) < 1) {
            const errors = {};
            errors.difference = "Please pick valid start and end period!";
            this.setState({
                errors,
            });
        } else {
            $("#editWaitList").modal("show");
            $("#addServicess").modal("hide");
            var selectedServices = this.state.selectedServices;
            selectedServices.push(service);

            this.setState({
                ...this.state,
                selectedServices: selectedServices,
                startTime: moment(),
                endTime: moment(),
                errors: {},
            });
        }
    };

    renderResources = (renderInfo) => {
        renderInfo.el.style.backgroundColor =
            renderInfo.resource.extendedProps.representationColor;
        renderInfo.el.classList.add("rubel");
        renderInfo.el.style.color = "white";
        renderInfo.el.style.position = "relative";

        let title = (
            <React.Fragment>
                <div
                    className="reset-btn"
                    style={{
                        backgroundColor:
                        renderInfo.resource.extendedProps.representationColor,
                    }}
                >
                    <button
                        onClick={() =>
                            this.resetDayClosedBook(
                                renderInfo.resource.id,
                                this.state.calendarDate
                            )
                        }
                    >
                        <i className="fa fa-undo"></i>
                    </button>
                </div>
                <img
                    className="resource-img"
                    src={renderInfo.resource.extendedProps.imgageUrl}
                    alt={renderInfo.resource.title}
                />
                {renderInfo.resource.title}
            </React.Fragment>
        );
        ReactDOM.render(title, renderInfo.el);
    };

    openFullScreenCalendar() {
        let calendarApi = this.calendarRef.current.getApi();
        calendarApi.gotoDate(this.calendarDate);

        setTimeout(function () {
            calendarApi.prev();
        }, 200);
    }

    changeRequestTimeOffTimeStart = (start) => {
        this.setState({
            offStart: start,
        });
    };

    changeRequestTimeOffTimeEnd = (end) => {
        this.setState({
            offEnd: end,
        });
    };

    changeRequestTimeOffEndTime = (end) => {
        this.setState({
            timeEnd: moment(end).format(format),
        });
    };

    handleChange = (e) => {
        const {name, value} = e.target;
        this.setState({
            ...this.state,
            [name]: value,
        });
    };

    changeHandler = (e) => {
        const {name, value} = e.target;

        if (name === "service") {
            var techIndex = this.state.technicians.findIndex(
                (tech) => tech.id == this.state.selectedTechnician
            );
            var serviceIndex = this.state.technicians[
                techIndex
                ].offeredServices.findIndex((service) => service.id == value);
            this.setState({
                selectedTechnicianName:
                this.state.technicians[techIndex].user.names.nick,
            });
            if (serviceIndex == -1) {
                this.setState({techServiceError: true});
            }
        }

        this.setState({[name]: value});

        if (name == "selectedTechnician" || name == "selectedTeam") {
            if (name == "selectedTeam") {
                this.setState({
                    selectedTechnician: "all",
                });
            }

            this.setState({
                useSelectedDate: true,
            });
            var today = new Date();
            if (helperFunctions.compareDateStrings(today, this.calendarDate)) {
                today.setDate(today.getDate() + 1);
                this.calendarDate = today;
            }
            let calendarApi = this.calendarRef.current.getApi();
            if (value != "all" && name != "selectedTeam") {
                this.setState({getAllTechnicians: false});
                var resource = this.state.technicians.find((r) => r.id == value);
                resource.title = resource.user.names.nick;
                var resources = [];
                resources.push(resource);

                this.setState({
                    resources: resources,
                    techValue: value,
                });

                calendarApi.changeView("timeGridDay");
            } else {
                this.setState({
                    getAllTechnicians: true,
                });
                if (name == "selectedTeam" && value != "all") {
                    this.getAllTechnicians(value);
                } else this.getAllTechnicians();
                calendarApi.changeView("resourceTimeGridDay");
            }

            setTimeout(function () {
                calendarApi.refetchEvents();
            }, 300);

            this.startApp = true;
            this.setState({service: ""});
            this.setState({category: ""});
            this.setState({services: []});
            this.setState({techServiceError: false});
        }

        if (name == "parentCategory") {
            var categoryArr = [];
            const {categories} = this.state;
            categories
                .filter((osc) => osc.parent != null && osc.parent.id == value)
                .forEach((category) => {
                    categoryArr.push(
                        <option key={category.id} value={category.id}>
                            {category.name}
                        </option>
                    );
                });
            this.setState({categories: categoryArr});
        }

        if (name === "category") {
            this.setState({service: []});
            let newServices = [];
            let offeredServices = [];
            let category = categoryArr.find((sc) => sc.id == value);
            if (category) {
                category.services.forEach((service) => {
                    if (service.active)
                        newServices.push({id: service.id, name: service.name});
                });
                newServices.forEach((s) => {
                    if (this.app.locations[0].offeredServices.find((os) => os.id == s.id))
                        offeredServices.push(s);
                });

                this.setState({
                    services: offeredServices,
                    techServiceError: false,
                });
            }
        }
    };

    //check out info
    togglePanel(e) {
        this.setState({open: !this.state.open});
    }

    openModal = () => {
        var collection = "";
        collection = this.state.viewDetails;

        if (collection) {
            const collectionCheck = this.state.cartCollections.find(
                (v, i) => v.id == collection.id
            );
            if (collectionCheck) {
                this.setState({
                    cartAdded: "exist",
                });
            } else {
                const cartCollections = this.state.cartCollections;
                cartCollections.push(collection);

                this.setState({
                    cartCollections: cartCollections,
                    totalCharge:
                        this.state.totalCharge * 1 + collection.totalChargeAmount * 1,
                    serviceChargeAmount:
                        this.state.serviceChargeAmount * 1 +
                        collection.serviceChargeAmount * 1,
                    cashPaidAmount:
                        this.state.cashPaidAmount * 1 + collection.cashPaidAmount * 1,
                    checkPaidAmount:
                        this.state.checkPaidAmount * 1 + collection.checkPaidAmount * 1,
                    creditPaidAmount:
                        this.state.creditPaidAmount * 1 + collection.creditPaidAmount * 1,
                    discountDeductionAmount:
                        this.state.discountDeductionAmount * 1 +
                        collection.discountDeductionAmount * 1,
                    giftCardPaidAmount:
                        this.state.giftCardPaidAmount * 1 +
                        collection.giftCardPaidAmount * 1,
                    productPurchaseAmount:
                        this.state.productPurchaseAmount * 1 +
                        collection.productPurchaseAmount * 1,
                    rewardDeductionAmount:
                        this.state.rewardDeductionAmount * 1 +
                        collection.rewardDeductionAmount * 1,
                    taxAmount: this.state.taxAmount * 1 + collection.taxAmount * 1,
                    tipAmount: this.state.tipAmount * 1 + collection.tipAmount * 1,
                    cartAdded: "added",
                });
            }
        } else {
            this.setState({
                cartCollections: this.state.cartCollections,
            });
        }

        window.initiateCartTable();
        window.initiateCollapse();
        window.initiateSummaryCheckout();
    };

    openEditModal = () => {
        this.setState({
            ...this.state,
            loading: true,
            menuState: false,
            checkOut: false,
        });
        appService.getSingleVtService(this.state.visitId).then((res) => {
            this.setState({
                ...this.state,
                editApptDetails: res,
                loading: false,
            });
        });

        window.initiateSummaryCheckout();
    };

    apptViewDetails = () => {
        this.setState({
            ...this.state,
            loading: true,
            menuState: false,
        });
        appService.getSingleVtService(this.state.visitId).then((res) => {
            this.setState(
                {
                    ...this.state,
                    editApptDetails: res,
                    loading: false,
                },
                () => {
                    this.setState({
                        showDetailsProcess: true,
                    });
                }
            );
        });
    };

    openCheckinModal = () => {
        this.setState({
            ...this.state,
            loading: true,
            menuState: false,
        });

        appService.getSingleVtService(this.state.visitId).then((res) => {
            this.setState({
                ...this.state,
                editApptDetails: res,
                viewDetails: res,
                loading: false,
            });
        });
    };

    openCheckOutModal = () => {
        this.setState({
            ...this.state,
            loading: true,
            menuState: false,
            checkOut: true,
        });
        appService.getSingleVtService(this.state.visitId).then((res) => {
            this.setState(
                {
                    ...this.state,
                    editApptDetails: res,
                    loading: false,
                },
                () => {
                    this.setState({
                        processing: !this.state.processing,
                    });
                }
            );
        });
    };

    resetStateWithModalClose = () => {
        this.setState({
            ...this.state,
            editApptDetails: "",
        });
    };

    closeCheckOutModal = () => {
        this.setState({
            ...this.state,
            editApptDetails: "",
        });
    };

    closeAddTaskModal = () => {
        this.setState({
            ...this.state,
            editApptDetails: "",
        });
    };

    clearEditApptModal = () => {
        if (this.state.cartCollections.length > 0) {
            this.openModal(this.state.editApptDetails);
        }
        this.setState({
            ...this.state,
            editApptDetails: "",
        });
    };

    taskSelectedItem = () => {
        appService.getSingleVtService(this.state.visitId).then((res) => {
            this.setState({
                taskSelected: {
                    ...this.state.taskSelected,
                    taskSelectedClient:
                        res.client.names.first +
                        " " +
                        (res.client.names.last && res.client.names.last.charAt(0)),
                    taskSelectedItemId: res.id,
                },
                menuState: false,
            });
        });
    };

    appointmentCheckIn = (id, note) => {
        var thisObj = this;
        appService
            .appointmentCheckIn(id, note)
            .then((res) => {
                appService.getSingleVtService(this.state.visitId).then((res) => {
                    this.setState({
                        ...this.state,
                        editApptDetails: res,
                        loading: false,
                    });
                });
                window.DataUpdateSuccess();
                let calendarApi = thisObj.calendarRef.current.getApi();
                calendarApi.refetchEvents();
            })
            .catch((e) => {
                Swal.fire({
                    title: "Error",
                    text: `Check-in is only allowed for today's appointments`,
                    type: "warning",
                    showCancelButton: false,
                    confirmButtonText: "Ok!",
                });
                this.setState({
                    ...this.state,
                    loading: false,
                });
            });
    };

    apptAddUpdateService = (id, apttService) => {
        var thisObj = this;
        this.setState({
            ...this.state,
            loading: true,
        });
        if (id !== "Nill") {
            appService
                .UpdateService(id, apttService)
                .then((res) => {
                    appService.getSingleVtService(this.state.visitId).then((res) => {
                        this.setState({
                            ...this.state,
                            viewDetails: res,
                            editApptDetails: res,
                            loading: false,
                        });
                    });
                    window.DataUpdateSuccess();
                    let calendarApi = thisObj.calendarRef.current.getApi();
                    calendarApi.refetchEvents();
                })
                .catch((e) => {
                    this.setState({
                        ...this.state,
                        loading: false,
                    });
                });
        } else {
            appService
                .apptAddUpdateService(apttService)
                .then((res) => {
                    appService.getSingleVtService(this.state.visitId).then((res) => {
                        this.setState({
                            ...this.state,
                            editApptDetails: res,
                            loading: false,
                        });
                    });

                    let calendarApi = thisObj.calendarRef.current.getApi();
                    calendarApi.refetchEvents();
                    window.DataUpdateSuccess();
                })
                .catch((e) => {
                    this.setState({
                        ...this.state,
                        loading: false,
                    });
                    e.json().then((err) => {
                        Swal.fire({
                            title: "Error",
                            text: `${err.message}`,
                            type: "warning",
                            showCancelButton: false,
                            confirmButtonText: "Ok",
                        });
                    });
                });
        }
    };

    updateBookingTime = (id, timeJSON) => {
        var thisObj = this;
        appService
            .updateBookingTime(id, timeJSON)
            .then((res) => {
                $("#editModal").modal("toggle");
                appService.getSingleVtService(this.state.visitId).then((res) => {
                    this.setState({
                        ...this.state,
                        viewDetails: res,
                        editApptDetails: res,
                        loading: false,
                    });
                });
                window.DataUpdateSuccess();
                let calendarApi = thisObj.calendarRef.current.getApi();
                calendarApi.refetchEvents();
            })
            .catch((e) => {
                Swal.fire({
                    title: "Error",
                    text: `Booking Time Update is failed`,
                    type: "warning",
                    showCancelButton: false,
                    confirmButtonText: "Ok!",
                });
                this.setState({
                    ...this.state,
                    loading: false,
                });
            });
    };

    //appointment check out
    apptCheckout = (id, payments) => {
        var thisObj = this;
        appService
            .appointmentCheckOut(id, payments)
            .then((res) => {
                $("#editModal").modal("toggle");
                appService.getSingleVtService(this.state.visitId).then((res) => {
                    this.setState({
                        ...this.state,
                        editApptDetails: res,
                        loading: false,
                    });
                });
                let calendarApi = thisObj.calendarRef.current.getApi();
                calendarApi.refetchEvents();
                window.toast("success", `The Appointment Check Out is successful!`);
            })
            .catch((e) => {
                e.json().then((err) => {
                    Swal.fire({
                        title: "Error",
                        text: `${err.message}`,
                        type: "warning",
                        showCancelButton: false,
                        confirmButtonText: "Ok",
                    });
                });
            });
    };

    openCancelModal = () => {
        this.setState({
            ...this.state,
            loading: true,
            menuState: false,
        });
        const startTime = moment(this.state.start).format(format);
        const endTime = moment(this.state.end).format(format);

        if (this.state.selectedWait.length > 0) {
            $("#miniWait").modal("show");
            appService.getSingleVtService(this.state.visitId).then((res) => {
                this.setState({
                    ...this.state,
                    viewDetails: res,
                    editApptDetails: res,
                    loading: false,
                });
            });
        } else {
            $("#cancelAppt").modal("show");
            appService.getSingleVtService(this.state.visitId).then((res) => {
                this.setState({
                    ...this.state,
                    viewDetails: res,
                    editApptDetails: res,
                    loading: false,
                });
            });
        }
    };

    cancelAppt = () => {
        var thisObj = this;
        const reason = {
            cancellationReason: this.state.cancelReason,
        };
        Swal.fire({
            title: "Cancel Appointment",
            text: `Are you sure you want to cancel this appointment`,
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, Cancel it!",
            cancelButtonText: "No, keep it",
        }).then((result) => {
            if (result.value) {
                this.setState({
                    ...this.state,
                    loading: true,
                });
                if (this.state.editApptDetails.status == "canceled") {
                    Swal.fire({
                        title: "Error!",
                        text: `This appointment is already canceled!`,
                        type: "warning",
                        showCancelButton: true,
                        cancelButtonText: "Ok",
                    });
                } else {
                    if (this.state.cancelReason != "") {
                        appService
                            .cancelAppt(this.state.editApptDetails.id, reason)
                            .then((res) => {
                                window.toast(
                                    "error",
                                    `The appointment has been canceled from the appointment`
                                );
                                /* eslint-disable */
                                appService
                                    .getSingleVtService(this.state.visitId)
                                    .then((res) => {
                                        this.setState({
                                            ...this.state,
                                            editApptDetails: res,
                                            cancelReason: "",
                                            menuState: false,
                                            loading: false,
                                            selectedTechnician: "",
                                            start: "",
                                            end: "",
                                            selectedWait: [],
                                        });
                                        let calendarApi = thisObj.calendarRef.current.getApi();
                                        calendarApi.refetchEvents();
                                        $("#cancelAppt").modal("toggle");
                                    });
                            })
                            .catch((e) => {
                                e.json().then((error) => {
                                    Swal.fire({
                                        title: "Error",
                                        text: `${error.message}`,
                                        type: "error",
                                        showCancelButton: false,
                                        confirmButtonText: "Ok",
                                    });
                                });
                                this.setState({
                                    ...this.state,
                                    loading: false,
                                });
                            });
                    } else {
                        this.setState({
                            loading: false,
                        });
                        Swal.fire({
                            title: "Warning!",
                            text: `Please enter cancellation reason!`,
                            type: "warning",
                            showCancelButton: false,
                            cancelButtonText: "Ok",
                        });
                    }
                }
            } else {
                this.setState({
                    ...this.state,
                    loading: false,
                });
            }
        });
    };

    //check in service
    checkServiceIn = (vt) => {
        var thisObj = this;
        this.setState({
            ...this.state,
            loading: true,
        });
        appService
            .checkServiceIn(vt)
            .then((res) => {
                appService.getSingleVtService(this.state.visitId).then((v) => {
                    this.setState({
                        ...this.state,
                        editApptDetails: v,
                        loading: false,
                    });
                });
                let calendarApi = thisObj.calendarRef.current.getApi();
                calendarApi.refetchEvents();
                window.toast("success", "Appointment checked in successfully");
            })
            .catch((e) => {
                Swal.fire({
                    title: "Error",
                    text: `Check-in is only allowed for today's appointments`,
                    type: "warning",
                    showCancelButton: false,
                    confirmButtonText: "Ok!",
                });
                this.setState({
                    ...this.state,
                    loading: false,
                });
            });
    };

    //check out service
    checkServiceOut = (vt, amount) => {
        var thisObj = this;
        this.setState({
            ...this.state,
            loading: true,
        });

        appService
            .checkServiceOut(vt, amount)
            .then((res) => {
                window.toast("success", "Service checked out successfully");

                appService.getSingleVtService(this.state.visitId).then((res) => {
                    this.setState({
                        ...this.state,
                        editApptDetails: res,
                        loading: false,
                    });
                });
                let calendarApi = thisObj.calendarRef.current.getApi();
                calendarApi.refetchEvents();
            })
            .catch((e) => {
                this.setState({
                    ...this.state,
                    loading: false,
                });
            });
    };

    UndocheckServiceIn = (vt) => {
        let thisObj = this;
        this.setState({
            ...this.state,
            loading: true,
            menuState: false,
        });
        appService
            .UndocheckServiceIn(vt)
            .then((res) => {
                window.toast("error", "Appointment undo  checked in is successfull");
                appService.getSingleVtService(this.state.viewDetails.id).then((res) => {
                    this.setState({
                        ...this.state,
                        viewDetails: res,
                        editApptDetails: res,
                        loading: false,
                    });
                });
                let calendarApi = thisObj.calendarRef.current.getApi();
                calendarApi.refetchEvents();
            })
            .catch((e) => {
                this.setState({
                    ...this.state,
                    loading: false,
                });
            });
    };

    saveTask = (taskJSON) => {
        const {dispatch} = this.props;
        userService.saveTask(taskJSON).then(() => {
            dispatch(userActions.getAllTask(0));
            window.DataUpdateSuccess();
        });
    };

    saveNote = () => {
        const notes = {
            staffNotes: this.state.staffNotes,
        };
        this.updateStaffNotes(this.state.viewDetails.id, notes);
        $("#addNote").modal("toggle");
    };

    updateStaffNotes = (id, notes) => {
        var thisObj = this;
        userService.saveApptStaffNotes(id, notes).then((res) => {
            appService.getSingleVtService(this.state.visitId).then((res) => {
                this.setState({
                    ...this.state,
                    editApptDetails: res,
                    loading: false,
                });
            });
            window.DataUpdateSuccess();
            let calendarApi = thisObj.calendarRef.current.getApi();
            calendarApi.refetchEvents();
        });
    };

    //save appointment
    saveAppointment = async (apptJSON) => {
        this.setState({
            loading: true,
        });
        var thisObj = this;

        let calendarApi = thisObj.calendarRef.current.getApi();
        reservationService
            .saveAppointment(apptJSON)
            .then(async (res) => {
                // if (this.state.selectedWait.length > 0 && this.state.replace == true) {
                //     appService.cancelAppt(this.state.editApptDetails.id, {cancellationReason: 'Appointment replaced from wait list'})
                // }
                this.setState({
                    loading: false,
                    menuState: false,
                    dateClickMenu: false,
                    //selectedWait: [],
                    selectedTechnician: "",
                    start: "",
                    end: "",
                    selected: [],
                    customerDetails: "",
                    customerFromCustomer: "",
                    selectNewCustomer: true,
                });
                window.DataUpdateSuccess();
                if (await isSupported() === false) {
                    calendarApi.refetchEvents();
                }
                //calendarApi.refetchEvents();
                window.closeTechTimeOffDetailsModal();
            })
            .catch(async (e) => {
                let error = await e.json();

                if (
                    Object.keys(error).length > 0 &&
                    error.code &&
                    error.code == "visit_override_confirmation_required"
                ) {
                    Swal.fire({
                        title: "Warning",
                        text: "Time slot, you selected is booked, time off or day off. Are you sure to override?",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Yes",
                    }).then((confirm) => {
                        if (confirm.value != undefined) {
                            reservationService
                                .saveAppointment(apptJSON, true)
                                .then((response) => {
                                    window.DataUpdateSuccess();
                                    calendarApi.refetchEvents();
                                });
                        }
                    });
                } else {
                    Swal.fire({
                        title: "Error",
                        text: error.message,
                        type: "error",
                        showCancelButton: false,
                        confirmButtonText: "Ok!",
                    });
                }
                window.closeTechTimeOffDetailsModal();
            });
        this.setPlace();
    };

    saveProduct = (prJson, id) => {
        var thisObj = this;
        if (id == "nill") {
            appService.saveProduct(prJson).then((res) => {
                window.DataUpdateSuccess();
                appService.getSingleVtService(this.state.viewDetails.id).then((res) => {
                    this.setState({
                        ...this.state,
                        viewDetails: res,
                        editApptDetails: res,
                        loading: false,
                    });
                });
                let calendarApi = thisObj.calendarRef.current.getApi();
                calendarApi.refetchEvents();
            });
        } else {
            appService.updateProduct(prJson, id).then((res) => {
                appService.getSingleVtService(this.state.viewDetails.id).then((res) => {
                    this.setState({
                        ...this.state,
                        viewDetails: res,
                        editApptDetails: res,
                        loading: false,
                    });
                });
                window.DataUpdateSuccess();
                let calendarApi = thisObj.calendarRef.current.getApi();
                calendarApi.refetchEvents();
            });
        }
    };

    deleteProduct = (id) => {
        var thisObj = this;
        Swal.fire({
            title: "Delete Product",
            text: `Are you sure you want to delete this Product`,
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, keep it",
        }).then((result) => {
            if (result.value) {
                this.setState({
                    ...this.state,
                    loading: true,
                });
                appService.deleteProduct(id).then((res) => {
                    appService
                        .getSingleVtService(this.state.viewDetails.id)
                        .then((res) => {
                            this.setState({
                                ...this.state,
                                viewDetails: res,
                                editApptDetails: res,
                                loading: false,
                            });
                        });
                    window.toast(
                        "error",
                        `The product has been deleted from the appointment`
                    );
                    /* eslint-disable */

                    let calendarApi = thisObj.calendarRef.current.getApi();
                    calendarApi.refetchEvents();
                });
            } else {
                this.setState({
                    ...this.state,
                    loading: false,
                });
            }
        });
    };

    removeFromCart = (id) => {
        let cartCollections = this.state.cartCollections;
        const newCollection = cartCollections.filter((c) => c.id != id);
        const old = cartCollections.filter((c) => c.id == id);
        if (cartCollections.length < 2) {
            $("#cartModal").modal("toggle");
            this.setState({
                cartAdded: "",
            });
        }
        this.setState({
            cartCollections: newCollection,
            totalCharge: this.state.totalCharge * 1 - old[0].totalChargeAmount,
            serviceChargeAmount:
                this.state.serviceChargeAmount * 1 - old[0].serviceChargeAmount,
            cashPaidAmount: this.state.cashPaidAmount * 1 - old[0].cashPaidAmount,
            checkPaidAmount: this.state.checkPaidAmount * 1 - old[0].checkPaidAmount,
            creditPaidAmount:
                this.state.creditPaidAmount * 1 - old[0].creditPaidAmount,
            discountDeductionAmount:
                this.state.discountDeductionAmount * 1 - old[0].discountDeductionAmount,
            giftCardPaidAmount:
                this.state.giftCardPaidAmount * 1 - old[0].giftCardPaidAmount,
            productPurchaseAmount:
                this.state.productPurchaseAmount * 1 - old[0].productPurchaseAmount,
            rewardDeductionAmount:
                this.state.rewardDeductionAmount * 1 - old[0].rewardDeductionAmount,
            taxAmount: this.state.taxAmount * 1 - old[0].taxAmount,
            tipAmount: this.state.tipAmount * 1 - old[0].tipAmount,
        });
    };

    editCartFromCollection = (id) => {
        let cartCollections = this.state.cartCollections;
        const newCollection = cartCollections.filter((c) => c.id != id);

        this.setState({
            cartCollections: newCollection,
            loading: true,
            cartAdded: "",
        });

        appService.getSingleVtService(id).then((res) => {
            this.setState({
                viewDetails: res,
                editApptDetails: res,
                loading: false,
            });
        });
        $("#editModal").modal("toggle");
    };

    cancelServieFromAppt = (id, canelReason, count) => {
        var thisObj = this;
        this.setState({
            loading: true,
        });
        appService
            .cancelServieFromAppt(id, canelReason)
            .then((res) => {
                window.toast(
                    "error",
                    `The service has been deleted from the appointment`
                );
                /* eslint-disable */
                appService.getSingleVtService(this.state.viewDetails.id).then((res) => {
                    this.setState(
                        {
                            ...this.state,
                            viewDetails: res,
                            editApptDetails: res,
                            loading: false,
                        },
                        () => {
                            if (this.state.viewDetails.status != "Cancelled") {
                                $("#editModal").modal("show");
                            }
                        }
                    );
                });

                let calendarApi = thisObj.calendarRef.current.getApi();
                calendarApi.refetchEvents();
            })
            .catch((e) => {
                this.setState({
                    loading: false,
                });
                e.json().then((err) => {
                    Swal.fire({
                        title: "Error!",
                        text: `${err.message}`,
                        type: "warning",
                        showCancelButton: false,
                    });
                });
            });
    };

    placeGroupCheckOut = (id, group) => {
        var thisObj = this;
        appService
            .placeCheckOut(id, group)
            .then((res) => {
                appService.getSingleVtService(this.state.viewDetails.id).then((res) => {
                    this.setState({
                        ...this.state,
                        viewDetails: res,
                        editApptDetails: res,
                        loading: false,
                    });

                    $("#cartModal").modal("toggle");

                    window.toast(
                        "success",
                        `The appointment is successfully checked out!`
                    );
                    /* eslint-disable */

                    let calendarApi = thisObj.calendarRef.current.getApi();
                    calendarApi.refetchEvents();
                });
            })
            .catch((e) => {
                e.json().then((error) => {
                    Swal.fire({
                        title: "Error!",
                        text: `${error.message}`,
                        type: "warning",
                        showCancelButton: false,
                    });
                });
            });
    };

    changeCustomCalendar = (date) => {
        let start = moment(date.toDate()).format('YYYY-MM-DD');

        var techSchedule =
            this.state.getAllTechnicians == true
                ? "all"
                : this.state.selectedTechnician;

        userService.getTechniciansSchedule(techSchedule, start, start).then(
            (slots) => {
                this.events = slots;
                this.setState({calendarDate: start, weekChanged: new Date()});
                this.startApp = false;
                this.setState({loading: false, calendarShow: false});
            },
            (error) => {
                this.setState({loading: false, calendarShow: false});
            }
        );

    }

    handleWeekChange = (e) => {
        this.setState({loading: true});

        const {value: week} = e.target;
        let startDate = moment(this.state.calendarDate)
            .add(week * 7, "days")
            .format("YYYY-MM-DD");

        let endDate = moment(startDate).add(1, "days").format("YYYY-MM-DD");
        //// validation logic here

        var techSchedule =
            this.state.getAllTechnicians == true
                ? "all"
                : this.state.selectedTechnician;
        let start = moment(startDate).format("YYYY-MM-DD");
        let end = moment(endDate).format("YYYY-MM-DD");
        if (moment(end).diff(start, "days") <= 1) end = start;
        userService.getTechniciansSchedule(techSchedule, start, end).then(
            (slots) => {
                this.events = slots;
                this.setState({calendarDate: start, weekChanged: new Date()});
                this.startApp = false;
                setTimeout(() => {
                    this.setState({loading: false});
                }, 1000);
            },
            (error) => {
                this.setState({loading: false});
            }
        );
    };

    closeModal = () => {
        this.setState({
            ...this.state,
            timeStart: "",
            timeEnd: "",
            technician: "",
            offEnd: "",
            offStart: "",
            staffNotes: "",
        });
    };

    openCreateWaitList = () => {
        $("#waitlist").modal("hide");
    };

    saveWaitList = (waitJSON) => {
        var thisObj = this;
        reservationService.saveWaitList(waitJSON).then((res) => {
            window.DataUpdateSuccess();
            this.setPlace();
            let calendarApi = thisObj.calendarRef.current.getApi();
            calendarApi.refetchEvents();

            $("#waitlist").modal("show");
        });
    };

    deleteWaitList = (id) => {
        var thisObj = this;
        Swal.fire({
            title: "Delete",
            text: `Are you sure you want to delete it?`,
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, keep it",
        }).then((result) => {
            if (result.value) {
                reservationService.deleteWaitList(id).then(() => {
                    let calendarApi = thisObj.calendarRef.current.getApi();
                    calendarApi.refetchEvents();
                });
                window.toast("error", `Data has been deleted from the system`);
            }
        });
    };

    editWaitList = (a, id) => {
        $("#waitlist").modal("hide");
        userService.getCustomerInfo(id).then((user) => {
            this.setState({
                customerDetails: user,
            });
        });

        const selectedServices = this.state.selectedServices;

        a.periods.forEach((m, key) => {
            const techs = [];
            a.services[key].technicians.forEach((t) => {
                techs.push({
                    id: t.id,
                });
            });
            selectedServices.push({
                from: m.from,
                to: m.to,
                technicians: techs,
                service: a.services[key].service.id,
                servicename: a.services[key].service.name,
                key: key,
            });
        });
        this.setState({
            selectedServices: selectedServices,
            staffNotes: a.staffNotes,
            customer: id,
            wtid: a.id,
        });

        reservationService.getPastAppointments(id).then((a) => {
            this.setState({
                pastAppts: a,
            });
        });
        reservationService.getOpenAppointments(id).then((a) => {
            this.setState({
                openAppts: a,
            });
        });
    };
    openMiniModal = (id) => {
        this.setState({
            lastAppts: this.state.pastAppts,
        });
    };
    openMiniModalForOpen = (id) => {
        this.setState({
            lastAppts: this.state.openAppts,
        });
    };
    removeService = (k, single) => {
        const newSelected = this.state.selectedServices.filter((s) => s.key != k);
        this.setState({
            selectedServices: newSelected,
        });
    };

    editWait = (k, single) => {
        var techs = this.state.technician;

        if (single.technicians.length == 0) {
            techs = [];
        } else {
            single.technicians.forEach((t) => {
                techs.push({
                    id: t.id,
                });
            });
        }
        this.setState({
            startTime: moment(single.from),
            endTime: moment(single.to),
            smodal: true,
            place: single.servicename,
            editMode: "update",
            key: single.key,
            service: single.service,
            servicename: single.servicename,
            staffNote: single.staffNotes,
            editOption: "update",
        });

        appService
            .getSingleServiceData(single.service, (this.state.searchQuery = ""))
            .then((res) => {
                if (res != "") {
                    let techService = [];
                    res.technicianServices.forEach((technician) => {
                        techService.push({
                            id: technician.technician.id,
                            name: technician.technician.user.names.nick,
                            price: technician.displayedPrice,
                            duration: helperFunctions.timeConvert(technician.duration),
                        });
                    });

                    this.setState({
                        ...this.state,
                        techServices: techService,
                    });
                    var techJson = [];
                    var i = 0;
                    techJson.push({
                        text: "Select Stylist",
                        value: "up",
                        selected: false,
                    });
                    techJson.push({
                        text: "Any Stylist",
                        value: "any",
                        selected: false,
                        description: "Expertise: Any",
                        imageSrc:
                            "https://s3.amazonaws.com/zoracorp/bianca/default/user-no-image.png",
                    });

                    techService.forEach((tech) => {
                        techJson.push({
                            text: tech.name,
                            value: tech.id,
                            selected: false,
                            description: "[" + tech.duration + ", " + tech.price + "]",
                            imageSrc: this.findTechnician(tech.id, "image"),
                        });
                        i++;
                    });

                    setTimeout(function () {
                        window.initiateTechniciansSelect(
                            techJson,
                            "#technicians-select-div-up",
                            "#technicians-select-up"
                        );
                    }, 100);
                }
            });
    };

    removeTech = (id) => {
        const techs = this.state.technician.filter((t) => t != id);

        if (techs) {
            this.setState({
                technician: techs,
            });
        }
    };

    changeTech = (e) => {
        const techs = this.state.technician.find((t) => t == e.target.value);

        if (techs) {
            this.setState({
                message: "Already added!",
            });
        } else {
            if (e.target.value == "up") {
                var techCollection = this.state.technician;
                this.setState({
                    technician: techCollection,
                });
            } else if (e.target.value != "any") {
                var techCollection = this.state.technician;
                techCollection.push({
                    id: e.target.value,
                });

                this.setState({
                    technician: techCollection,
                });
            } else {
                this.setState({
                    technician: [],
                });
            }
        }
    };

    upSelectedService = () => {
        const newSelected = this.state.selectedServices.map((s) => {
            if (s.key == this.state.key) {
                return {
                    ...s,
                    from: this.state.startTime,
                    to: this.state.endTime,
                    technicians: this.state.technician,
                    service: this.state.service,
                    servicename: this.state.servicename,
                };
            }
            return s;
        });

        this.setState({
            selectedServices: newSelected,
            techServices: "",
            editMode: "save",
            smodal: false,
            technician: [],
            startTime: moment(),
            endTime: moment(),
            place: "",
        });
        $("#editWaitList").modal("show");
        $("#addServicess").modal("hide");
    };

    onServiceSelect = (service) => {
        this.setState({
            ...this.state,
            serviceId: service.service.id,
            serviceName: service.service.name,
        });

        if (service != "") {
            let techServices = [];
            let technicianIds = [];

            service.technicianServices.forEach((technician, key) => {
                techServices.push({
                    key: key,
                    id: technician.technician.id,
                    name: technician.technician.user.names.nick,
                    price: technician.displayedPrice,
                    duration: helperFunctions.timeConvert(technician.duration),
                    durationInMinute: technician.durationInMinutes,
                    actualPrice: this.checkPriceType(
                        technician.minimumFee,
                        technician.maximumFee
                    ),
                });
            });
            this.setState({techServices: techServices});
            var techJson = [];
            var i = 0;
            techJson.push({
                text: "Any Stylist",
                value: "any",
                selected: false,
                description: "Expertise: Any",
                imageSrc:
                    "https://s3.amazonaws.com/silverbird/default/user-no-image.png",
            });

            techServices.forEach((tech) => {
                techJson.push({
                    text: tech.name,
                    value: tech.id,
                    selected: true,
                    description: "[" + tech.duration + ", " + tech.price + "]",
                    imageSrc: this.findTechnician(tech.id, "image"),
                });
                i++;
            });

            setTimeout(function () {
                window.initiateTechniciansSelect(
                    techJson,
                    "#technicians-select-div-up",
                    "#technicians-select-up"
                );
            }, 100);
        }
    };

    changeStartTime = (start) => {
        this.setState({
            startTime: start,
        });
    };
    changeEndTime = (end, rest) => {
        this.setState({
            endTime: end,
            showService: true,
        });
    };

    closeThisModal = () => {
        this.setState({
            editMode: "save",
            smodal: false,
            technician: [],
            techServices: "",
            key: "",
            startTime: moment(),
            endTime: moment(),
            place: "",
            miniModal: false,
            errors: {}
        });
    };

    onCustomerSelect = (c) => {
        this.setState({
            customerDetails: c,
            customer: c.id,
        });
    };

    updateWaitList = () => {
        const selectedServices = this.state.selectedServices;

        const services = [];
        const period = [];
        for (var i = 0; i < selectedServices.length; i++) {
            var singleService = "";
            if (selectedServices[i].technicians.length == 0) {
                singleService = {
                    service: {
                        id: selectedServices[i].service,
                    },
                    technicians: [],
                };
            } else {
                singleService = {
                    service: {
                        id: selectedServices[i].service,
                    },
                    technicians: selectedServices[i].technicians,
                };
            }
            var time = {
                from: moment(selectedServices[i].from).format("YYYY-MM-DDTHH:mm:ss"),
                to: moment(selectedServices[i].to).format("YYYY-MM-DDTHH:mm:ss"),
            };

            period.push(time);

            services.push(singleService);
        }
        const waitJSON = {
            client: {
                id: this.state.customer,
            },
            location: {
                id: this.app.locations[0].id,
            },
            staffNotes: this.state.staffNotes,
            periods: period,
            services: services,
        };

        reservationService.updateWaitList(this.state.wtid, waitJSON).then((res) => {
            var thisObj = this;
            window.DataUpdateSuccess();
            $("#editWaitList").modal("hide");
            $("#waitlist").modal("toggle");
            this.setState({
                techServices: "",
                selectedServices: [],
                customer: "",
                customerDetails: "",
                wtid: "",
            });
            let calendarApi = thisObj.calendarRef.current.getApi();
            calendarApi.refetchEvents();
        });
    };
    refreshCreateModal = () => {
        this.setState({
            selectedServices: [],
            techServices: [],
            technician: "",
            selectedCustomer: "",
        });
    };

    clearPlace = () => {
        this.setState({
            place: "",
        });
    };

    setPlace = (value) => {
        if (value != "something") {
            if (value == "waitList") {
                this.setState({
                    place: "Type a name",
                    replace: false,
                });
            } else {
                this.setState(
                    {
                        ...this.state,
                        //selectedWait: [],
                        start: "",
                        end: "",
                        selected: [],
                        waitCustomer: "",
                        customerFromWaitCustomer: "",
                        selectNewCustomer: true,
                        replace: false,
                    },
                    () => {
                        this.setState({
                            place: "Type a name",
                            replace: false,
                        });
                    }
                );
            }
        } else {
            this.setState(
                {
                    ...this.state,
                    place: "Type a name",
                    replace: false,
                },
                () => {
                    this.setState({
                        place: "Type a name",
                        replace: false,
                    });
                }
            );
        }
    };

    getTheDifference = (timeSm, timeBg) => {
        let diff = moment.duration(moment(timeBg).diff(moment(timeSm))).asMinutes();
        return diff;
    };

    addServiceToAppt = (a) => {
        const selectedWait = [];
        a.services.map((s, k) => {
            let tech = s.technicians.find(
                (st) => st.id == this.state.selectedTechnician
            );

            if (tech != undefined) {
                selectedWait.push({
                    key: k,
                    timeStart: moment(this.state.start),
                    timeEnd: moment(this.state.start).add(
                        tech.technicianServices[0].durationInMinutes,
                        "minutes"
                    ),
                    servicename: s.service.name,
                    service: s.service.id,
                    technician: this.state.selectedTechnician,
                    technicianname: this.findTechnician(
                        this.state.selectedTechnician,
                        "nick"
                    ),
                    price: tech.technicianServices[0].displayedPrice,
                });
            } else {
                // selectedWait.push({
                //     key: selectedWait.length + 1,
                //     timeStart: moment(this.state.start),
                //     timeEnd: moment(this.state.start).add(s.technicians[0].technicianServices[0].durationInMinutes, 'minutes'),
                //     servicename: s.service.name,
                //     service: s.service.id,
                //     technician: s.technicians[0].id,
                //     technicianname: this.findTechnician(s.technicians[0].id, 'nick'),
                //     price: s.technicians[0].technicianServices[0].displayedPrice
                // })
            }
        });

        userService.getCustomerInfo(a.client.id).then((user) => {
            this.setState(
                {
                    waitCustomer: user,
                    customerFromWaitCustomer: user,
                    loading: false,
                    timeStart: this.state.start,
                    selected: selectedWait,
                    replace: true,
                },
                () => {
                    $("#createEvent").modal("show");
                }
            );
        });
    };

    persona = (id) => {
        this.setState({
            menuState: false,
        });
        $("#persona").modal("toggle");
        window.initSlider();
    };
    renderSuccessMessage = () => {
        window.toast("success", "A new customer is added!");
    };
    reloadTodayScreen = (bookedTime) => {
        const {dispatch} = this.props;
        const thisObj = this;
        if (bookedTime) {
            let calendarApi = thisObj.calendarRef.current.getApi();
            calendarApi.refetchEvents();
        }

        dispatch(communicationAction.procceedToReload({}));
    };

    openCaller = () => {
        if (
            this.loggedUser.role == "DIRECTOR" ||
            this.loggedUser.role == "MANAGER" ||
            this.loggedUser.role == "RECEPTIONIST" ||
            this.loggedUser.role == "CASHIER"
        ) {
            const {dispatch} = this.props;
            if (this.state.singleUser != "") {
                dispatch(outgoingAction.proccessCall(this.state.singleUser));
            }
        }
    };

    viewRender = (info) => {
        const event = this.events.find(
            (event) =>
                event.id == info.event.id &&
                moment(event.start).format("hh:mm") ==
                moment(info.event.start).format("hh:mm")
        );
        const technician = this.state.resources.find((resource) => resource.id === event.resourceId);
        const content =
            (event && event.staffNotes && event && event.staffNotes != "") ||
            (event && event.customerNotes && event.customerNotes != "") ? (
                <React.Fragment>
                    <div className="envelop">
                        <i className="fa fa-envelope-o"></i>
                    </div>
                    <div className="scroll" style={{background: event.backgroundColor}}>
                        <div className="scroll-wrap">
                            <div className="staff-notess">
                                {event.staffNotes != "" ? (
                                    <div className="notes-staff">
                                        <h6>Staff Notes:</h6>
                                        {event.staffNotes}
                                    </div>
                                ) : (
                                    ""
                                )}{" "}
                                {event.customerNotes && event.customerNotes != "" ? (
                                    <div className="notes-customer">
                                        <h6>Customer Notes:</h6>
                                        {event.customerNotes}
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </div>


                    <div className="fc-content">
                        <div
                            className="fc-time"
                            data-start={moment(event.start).format("hh:mm")}
                            data-full={
                                moment(event.start).format("hh:mm") +
                                "-" +
                                moment(event.end).format("hh:mm")
                            }
                        >
              <span>
                {moment(event.start).format("hh:mm")} -{" "}
                  {moment(event.end).format("hh:mm")}
              </span>
                        </div>
                        <div className="fc-title">{event.title}</div>
                    </div>
                    <div className="active-on-hover">
                        <h4>
                            <b>{helperFunctions.formatDateTimeWithDay(this.state.presentDate, 'date')}</b>
                        </h4>
                        <div className="text-center">
                            {technician && technician.imgageUrl &&
                                <div className="chat-image text-center" style={{width: '100%'}}>
                                    <img style={{width: "20px", height: "20px", margin: 'auto'}}
                                         src={technician.imgageUrl}/>
                                </div>
                            }
                            {technician && technician.title && <big style={{color: '#000'}}>{technician.title}</big>}
                        </div>
                        <h5>{event.title}
                            <br/>
                            <b>{moment(event.start).format("hh:mm")} -{" "} {moment(event.end).format("hh:mm")}</b></h5>
                    </div>
                </React.Fragment>
            ) : (
                <>
                    <div className="fc-content">
                        <div
                            className="fc-time"
                            data-start={moment(event.start).format("hh:mm")}
                            data-full={
                                moment(event.start).format("hh:mm") +
                                "-" +
                                moment(event.end).format("hh:mm")
                            }
                        >
            <span>
              {moment(event.start).format("hh:mm")} -{" "}
                {moment(event.end).format("hh:mm")}
            </span>
                        </div>
                        <div className="fc-title">{event.title}</div>
                    </div>
                    <div className="active-on-hover">
                        <h4>
                            <b>{helperFunctions.formatDateTimeWithDay(this.state.presentDate, 'date')}</b>
                        </h4>
                        <div className="text-center">
                            {technician && technician.imgageUrl &&
                                <div className="chat-image text-center" style={{width: '100%'}}>
                                    <img style={{width: "20px", height: "20px", margin: 'auto'}}
                                         src={technician.imgageUrl}/>
                                </div>
                            }
                            {technician && technician.title && <big style={{color: '#000'}}>{technician.title}</big>}
                        </div>
                        <h5>{event.title}
                            <br/>
                            <b>{moment(event.start).format("hh:mm")} -{" "} {moment(event.end).format("hh:mm")}</b></h5>
                    </div>
                </>
            );
        ReactDOM.render(content, info.el);
        return info.el;
    };

    //reset time off
    resetDayClosedBook = (techId, date) => {
        reservationService.resetTimeOff(techId, date).then((res) => {
            let calendarApi = this.calendarRef.current.getApi();
            calendarApi.refetchEvents();
        });
    };
    mouseEnter = (event) => {
    }


    render() {
        const {
            teams,
            resources,
            parentCategory,
            technicians,
            category,
            requestSource,
            service,
            services,
            techServiceError,
            booking,
            waitlist,
            customerDetails,
            selectedServices,
            errors,
        } = this.state;

        return (
            <div>

                {this.state.calendarShow === true && <div className="custom-calendar-picker-component">
                    <div className="close-btn">
                        <button onClick={() => this.setState({calendarShow: !this.state.calendarShow})}
                                className="btn btn-lg btn-circle btn-danger btn-outline"><i className="fa fa-close"></i>
                        </button>
                    </div>
                    <Calendar
                        value={this.state.calendarDate}
                        onChange={this.changeCustomCalendar}
                        numberOfMonths={2}
                        disableMonthPicker
                        disableYearPicker
                    />
                </div>}

                <div id="page-wrapper" className="reservation-page">
                    <div className="container-fluid">
                        <div className="row bg-title custom-header">
                            <div className=" col-md-12 col-sm-12 col-xs-12">
                                <h4 className="page-title">
                                    Stylists TimeTable{" "}
                                    <b className="text-danger">
                                        {Object.keys(this.state.customerFromCustomer).length != 0 &&
                                            `for ${this.state.customerFromCustomer.fullName}`}
                                    </b>
                                </h4>
                            </div>
                            <div className="col-md-8">
                                <div className="row">
                                    <div className="col-md-6 col-xs-6">
                                        <div className="form-group">
                                            <select
                                                className="form-control input-sm"
                                                name="selectedTechnician"
                                                onChange={this.changeHandler}
                                            >
                                                <option value="all">All Stylists</option>
                                                {technicians &&
                                                    technicians.map((e, key) => {
                                                        return (
                                                            <option key={e.id} value={e.id}>
                                                                {e.user.names.nick}
                                                            </option>
                                                        );
                                                    })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-xs-6">
                                        <div className="form-group">
                                            <select
                                                className="form-control input-sm"
                                                name="selectedTeam"
                                                onChange={this.changeHandler}
                                            >
                                                <option value="all">All Teams</option>
                                                {teams &&
                                                    teams.map((t, key) => {
                                                        return (
                                                            <option key={key} value={t}>
                                                                {t}
                                                            </option>
                                                        );
                                                    })}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <button className="btn right">
                                    <i className="fa fa-print"></i>
                                </button>
                                {this.state.cartCollections.length > 0 && (
                                    <a
                                        data-target="#cartModal"
                                        data-toggle="modal"
                                        className="btn right"
                                    >
                                        <i className="icon-basket"></i>
                                        <label className="item-count">
                                            {this.state.cartCollections.length}
                                        </label>
                                    </a>
                                )}
                                <button className="btn right" id="toggle_fullscreen">
                                    <i className="fa fa-search-plus"></i>
                                </button>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div id="tech-calendar" className="demo-app">
                                    <div className="demo-app-calendar">
                                        <div className="jump-to">
                                            <select
                                                onChange={this.handleWeekChange}
                                                className="jump-option"
                                            >
                                                <option>Jump To</option>
                                                <option value="1">1 Week</option>
                                                <option value="2">2 Weeks</option>
                                                <option value="3">3 Weeks</option>
                                                <option value="4">4 Weeks</option>
                                                <option value="5">5 Weeks</option>
                                                <option value="6">6 Weeks</option>
                                                <option value="7">7 Weeks</option>
                                                <option value="8">8 Weeks</option>
                                                <option value="9">9 Weeks</option>
                                                <option value="10">10 Weeks</option>
                                                <option value="11">11 Weeks</option>
                                                <option value="12">12 Weeks</option>
                                            </select>
                                        </div>


                                        <FullCalendar
                                            key={this.state.weekChanged}
                                            id="my-full-calendar"
                                            defaultView="resourceTimeGridDay"

                                            header={{
                                                left: "prev,calendarButton,next,today",
                                                center: "title",
                                                right: this.state.getAllTechnicians == true ? "resourceTimeGridDay" : "dayGridMonth,timeGridWeek,resourceTimeGridDay",
                                            }}
                                            prev="prev"
                                            views={{
                                                resourceTimeGridDay: {
                                                    titleFormat: {
                                                        year: "numeric",
                                                        month: "short",
                                                        day: "numeric",
                                                        weekday: "short",
                                                    },
                                                },
                                            }}
                                            plugins={[
                                                dayGridPlugin,
                                                resourceTimeGridPlugin,
                                                timeGridPlugin,
                                                interactionPlugin,
                                            ]}
                                            slotDuration={
                                                this.app.visualAttributes.silverbirdCalInterval
                                            }
                                            minTime={this.app.visualAttributes.silverbirdCalStart}
                                            maxTime={this.app.visualAttributes.silverbirdCalEnd}
                                            handleWindowResize={true}
                                            allDaySlot={false}
                                            defaultDate={this.state.calendarDate}
                                            slotEventOverlap={true}
                                            navLinks={true}
                                            editable={false}
                                            selectable={true}
                                            eventLimit={true}
                                            changeView={this.changeCalendarView}
                                            ref={this.calendarRef}
                                            events={this.handleDateChange}
                                            select={this.selectTimeSlot}
                                            eventClick={this.eventClickHandler}
                                            eventMouseEnter={this.mouseEnter}
                                            longPressDelay={500}
                                            schedulerLicenseKey={"0255236413-fcs-1560176287"}
                                            resourceOrder={"displayOrder"}
                                            resources={resources && resources}
                                            resourceRender={this.renderResources}
                                            eventRender={this.viewRender}
                                            lazyFetching={false}
                                            customButtons={{
                                                calendarButton: {
                                                    icon: 'fa fa-calendar',
                                                    click: this.clicked
                                                },
                                            }}
                                        />


                                        <div
                                            data-toggle="modal"
                                            data-target="#waitlist"
                                            className="wait-lists"
                                        >
                                            <h4 title="waitlist">
                                                <i className="fa fa-tags"></i> - (
                                                {this.state.waitlist && this.state.waitlist.length})
                                            </h4>
                                        </div>
                                    </div>

                                    <div
                                        data-backdrop="static"
                                        data-keyboard="false"
                                        className="modal fade none-border"
                                        id="showTechDayOffModal"
                                    >
                                        <div className="modal-dialog modal-small">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <button
                                                        onClick={this.closeModal}
                                                        type="button"
                                                        className="close"
                                                        data-dismiss="modal"
                                                        aria-hidden="true"
                                                    >
                                                        &times;
                                                    </button>
                                                    <h4 className="modal-title">
                                                        <strong>Open Book</strong>
                                                    </h4>
                                                </div>
                                                <div className="modal-body">
                                                    <div className="pt10 form-group">
                                                        <label>Time Start:&nbsp; </label>
                                                        {helperFunctions.formatDateTimeWithDay(
                                                            moment(this.state.offStart),
                                                            "time"
                                                        )}{" "}
                                                        {helperFunctions.formatDateTimeWithDay(
                                                            moment(this.state.timeStart),
                                                            "date"
                                                        )}
                                                        <br/>
                                                        <label>Time End:&nbsp; </label>
                                                        {helperFunctions.formatDateTimeWithDay(
                                                            moment(this.state.offEnd),
                                                            "time"
                                                        )}{" "}
                                                        {helperFunctions.formatDateTimeWithDay(
                                                            moment(this.state.timeEnd),
                                                            "date"
                                                        )}
                                                        <br/>
                                                        {/*<label>Technician:&nbsp; </label>{this.state.selectedTechnician != 'all' && this.state.selectedTechnician != '' ? this.state.technicians.find(t => t.id == this.state.selectedTechnician) && this.state.technicians.find(t => t.id == this.state.selectedTechnician).title : ''}<br />*/}
                                                    </div>

                                                    <div className="fields">
                                                        <div className="form-group group-input">
                                                            <label htmlFor="service">
                                                                Start Times{" "}
                                                                <span className="text-danger">*</span>
                                                            </label>

                                                            <CustomTimePicker
                                                                value={moment(this.state.offStart).format(
                                                                    format
                                                                )}
                                                                name="timeStart"
                                                                format={format}
                                                                onChange={this.changeRequestTimeOffTimeStart}
                                                            />
                                                        </div>
                                                        <div className="form-group  group-input">
                                                            <label htmlFor="service">
                                                                End Times &nbsp;{" "}
                                                                <span className="text-danger">*</span>
                                                            </label>

                                                            <CustomTimePicker
                                                                onChange={this.changeRequestTimeOffTimeEnd}
                                                                name="timeEnd"
                                                                value={moment(this.state.offEnd).format(format)}
                                                            />
                                                        </div>
                                                    </div>
                                                    {booking && (
                                                        <div className="loaderDiv">
                                                            <div className="loader"></div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="modal-footer">
                                                    <button
                                                        onClick={this.requestTimeOff}
                                                        type="button"
                                                        className="btn btn-custom-info save-event waves-effect waves-light"
                                                    >
                                                        Confirm
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <AddNewAppointmentModal
                    customer={this.state.customerDetails}
                    waitCustomer={this.state.waitCustomer}
                    selectNewCustomer={this.state.selectNewCustomer}
                    loading={this.state.loading}
                    saveAppointment={this.saveAppointment}
                    place={this.state.place}
                    selectedTech={this.state.selectedTechnician}
                    timeStart={this.state.timeStart}
                    timeEnd={this.state.timeEnd}
                    clearPlace={this.clearPlace}
                    setPlace={this.setPlace}
                    selected={this.state.selected}
                />

                <AddNewWaitlist
                    selectNewCustomer={this.state.selectNewCustomer}
                    customer={this.state.customerDetails}
                    clearPlace={this.clearPlace}
                    setPlace={this.setPlace}
                    loading={this.state.loading}
                    saveWaitList={this.saveWaitList}
                />

                <div
                    data-backdrop="static"
                    data-keyboard="false"
                    className="modal fade"
                    id="editWaitList"
                >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <React.Fragment>
                                <div className="modal-header">
                                    <button
                                        onClick={this.refreshCreateModal}
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">×</span>
                                    </button>
                                    <h4 className="modal-title" id="cancelLabelModel">
                                        Appointment
                                    </h4>
                                </div>
                                <div className="modal-body">
                                    <div className="client-info addApt">
                                        <div className="info-header">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <CustomerSearchBar
                                                            place={this.state.place}
                                                            clearPlace={this.clearPlace}
                                                            id="newId"
                                                            mode="update"
                                                            onCustomerSelect={this.onCustomerSelect}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="client">
                                                {customerDetails != "" && (
                                                    <React.Fragment>
                                                        <div className="chat-image">
                                                            <img src={customerDetails.imageUrl} alt=""/>
                                                        </div>
                                                        <div className="chat-body">
                                                            <div className="chat-text">
                                                                <div>
                                                                    <h3>
                                                                        <strong>{customerDetails.fullName}</strong>
                                                                    </h3>
                                                                    <p>
                                                                        <i className="fa fa-phone"></i>{" "}
                                                                        {customerDetails.mobileNumber}
                                                                    </p>
                                                                    <p>
                                                                        <i className="fa fa-dollar"></i>{" "}
                                                                        {customerDetails.rewardBalance}
                                                                    </p>
                                                                    <div className="appt-time">
                                                                        <p>
                                                                            <i className="fa fa-calendar"></i>{" "}
                                                                            {helperFunctions.formatDateTimeWithDay(
                                                                                customerDetails.bookedTime,
                                                                                "date"
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                    <div className="appt-time">
                                                                        <p>
                                                                            <i className="fa fa-calendar-check-o"></i>

                                                                            {customerDetails.lastVisit && (
                                                                                <a
                                                                                    data-toggle="modal"
                                                                                    data-target="#openPast"
                                                                                    data-dismiss="modal"
                                                                                    onClick={() =>
                                                                                        this.openMiniModal(
                                                                                            customerDetails.id
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    {" "}
                                                                                    {
                                                                                        customerDetails.lastVisit
                                                                                            .readableBookedTime
                                                                                    }{" "}
                                                                                </a>
                                                                            )}
                                                                            {!customerDetails.lastVisit && "NONE"}
                                                                        </p>
                                                                    </div>
                                                                    <div className="appt-time">
                                                                        <p>
                                                                            <i className="fa fa-random"></i>
                                                                            {customerDetails.nextVisit && (
                                                                                <a
                                                                                    data-toggle="modal"
                                                                                    data-target="#openPast"
                                                                                    data-dismiss="modal"
                                                                                    onClick={() =>
                                                                                        this.openMiniModalForOpen(
                                                                                            customerDetails.id
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    {" "}
                                                                                    {
                                                                                        customerDetails.nextVisit
                                                                                            .readableBookedTime
                                                                                    }{" "}
                                                                                </a>
                                                                            )}
                                                                            {!customerDetails.nextVisit && "NONE"}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <hr/>

                                                            <div className="confirm-btn-group">
                                                                <div className="confirm-btn">
                                                                    <button
                                                                        data-toggle="modal"
                                                                        data-target="#addServicess"
                                                                        data-dismiss="modal"
                                                                        onClick={() =>
                                                                            this.initializeServiceSelect()
                                                                        }
                                                                        className={
                                                                            !this.state.buttonText
                                                                                ? "button-custom"
                                                                                : "actve"
                                                                        }
                                                                    >
                                                                        Add Service
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                )}
                                            </div>
                                        </div>
                                        <div className="past-appointments waitlist">
                                            {selectedServices.length > 0 && (
                                                <React.Fragment>
                                                    <div className="steamline">
                                                        {selectedServices.map((s, k) => {
                                                            return (
                                                                <div key={k} className="sl-item">
                                                                    <div className="sl-left">
                                                                        <img
                                                                            src={
                                                                                s.technicians.length > 0
                                                                                    ? this.findTechnician(
                                                                                        s.technicians[0].id,
                                                                                        "image"
                                                                                    )
                                                                                    : "https://s3.amazonaws.com/zoracorp/bianca/default/user-no-image.png"
                                                                            }
                                                                            alt="user"
                                                                            className="img-circle"
                                                                        />{" "}
                                                                        <span className="more-tech">
                                      {s.technicians.length > 1 &&
                                          ` + ${s.technicians.length}`}
                                    </span>
                                                                    </div>
                                                                    <div className="sl-right">
                                                                        <div className="m-l-40">
                                                                            <p>
                                                                                <b>{s.servicename}</b> with{" "}
                                                                                <b>
                                                                                    {s.technicians.length > 0
                                                                                        ? this.findTechnician(
                                                                                            s.technicians[0].id,
                                                                                            "name"
                                                                                        )
                                                                                        : "Any Stylist"}
                                                                                    <sub className="text-capitalize">
                                                                                        {s.technicians.length > 1 &&
                                                                                            `and  ${s.technicians.length} others`}
                                                                                    </sub>
                                                                                </b>
                                                                                <br/>
                                                                                <b>Time: </b>
                                                                                <b>
                                                                                    {helperFunctions.formatDateTimeWithDay(
                                                                                        moment(s.from).format(
                                                                                            "YYYY-MM-DDTHH:mm:ss"
                                                                                        ),
                                                                                        "date"
                                                                                    )}{" "}
                                                                                    {helperFunctions.formatDateTimeWithDay(
                                                                                        moment(s.from).format(
                                                                                            "YYYY-MM-DDTHH:mm:ss"
                                                                                        ),
                                                                                        "time"
                                                                                    )}{" "}
                                                                                    -{" "}
                                                                                    {helperFunctions.formatDateTimeWithDay(
                                                                                        moment(s.to).format(
                                                                                            "YYYY-MM-DDTHH:mm:ss"
                                                                                        ),
                                                                                        "date"
                                                                                    )}{" "}
                                                                                    {helperFunctions.formatDateTimeWithDay(
                                                                                        moment(s.to).format(
                                                                                            "YYYY-MM-DDTHH:mm:ss"
                                                                                        ),
                                                                                        "time"
                                                                                    )}
                                                                                </b>
                                                                                <br/>
                                                                            </p>
                                                                            <p className="pb35">
                                        <span className="pull-right">
                                          <button
                                              onClick={() =>
                                                  this.editWait(s.key, s)
                                              }
                                              data-toggle="modal"
                                              data-target="#addServicess"
                                              data-dismiss="modal"
                                              className="btn m-t-10 m-r-5 btn-custom-info"
                                          >
                                            Edit
                                          </button>
                                          <button
                                              onClick={() =>
                                                  this.removeService(s.key)
                                              }
                                              className="btn m-t-10 m-r-5 btn-danger"
                                          >
                                            Cancel
                                          </button>
                                        </span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Staff Notes</label>
                                                        <textarea
                                                            name="staffNotes"
                                                            value={this.state.staffNotes}
                                                            onChange={this.handleChange}
                                                            className="form-control staff-note"
                                                        ></textarea>
                                                    </div>
                                                </React.Fragment>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {this.state.selectedServices.length > 0 && (
                                    <div className="modal-footer">
                                        <ul>
                                            <li>
                                                <button
                                                    onClick={this.updateWaitList}
                                                    className="btn btn-custom-info"
                                                >
                                                    Update
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                )}
                                {this.props.loading == true && (
                                    <div className="custom-loading">
                                        <div className="loading">
                                            <i className="fa fa-spinner fa-spin"></i>
                                        </div>
                                    </div>
                                )}
                            </React.Fragment>
                        </div>
                    </div>
                </div>
                <div
                    data-backdrop="static"
                    data-keyboard="false"
                    className="modal fade"
                    id="openPast"
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                {
                                    <React.Fragment>
                                        <button
                                            data-dismiss="modal"
                                            data-toggle="modal"
                                            data-target="#editWaitList"
                                            className="close"
                                            onClick={() => this.closeThisModal()}
                                        >
                                            &times;
                                        </button>
                                        <h4>
                                            Appointment on{" "}
                                            {this.state.lastAppts.length > 0 &&
                                                helperFunctions.formatDateTimeWithDay(
                                                    moment(this.state.lastAppts[0].bookedTime),
                                                    "date"
                                                )}{" "}
                                            {this.state.lastAppts.length > 0 &&
                                                helperFunctions.formatDateTimeWithDay(
                                                    this.state.lastAppts[0].bookedTime,
                                                    "time"
                                                )}
                                        </h4>

                                        <ul>
                                            {this.state.lastAppts.length > 0 &&
                                                this.state.lastAppts[0].visitTechnicians.map(
                                                    (s, key) => {
                                                        return (
                                                            <li key={key}>
                                                                <strong>
                                                                    {this.findServiceName(
                                                                        s.offeredService.id,
                                                                        s.offeredService.category.id
                                                                    )}
                                                                </strong>{" "}
                                                                with{" "}
                                                                <strong>
                                                                    {this.findTechnician(s.technician.id, "name")}
                                                                </strong>
                                                            </li>
                                                        );
                                                    }
                                                )}
                                        </ul>
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    data-backdrop="static"
                    data-keyboard="false"
                    className="modal fade"
                    id="addServicess"
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <button
                                    data-toggle="modal"
                                    data-target="#editWaitList"
                                    data-dismiss="modal"
                                    className="close"
                                    onClick={() => this.closeThisModal()}
                                >
                                    &times;
                                </button>

                                <div className="col-md-12">
                                    <ul>
                                        {this.state.technician.length > 0 &&
                                            this.state.technician.map((t, k) => {
                                                return (
                                                    <label className="label label-info" key={k}>
                                                        {this.findTechnician(t.id, "nick")}
                                                        <button
                                                            className="btn btn-label-remover"
                                                            onClick={() => this.removeTech(t)}
                                                        >
                                                            X
                                                        </button>
                                                    </label>
                                                );
                                            })}
                                    </ul>
                                </div>

                                <div className="col-md-12 switcher-spacer"></div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group select-arrow">
                                            <label htmlFor="stylish">
                                                Service &nbsp; <span className="text-danger">*</span>
                                            </label>
                                            <ServiceSearchBar
                                                place={this.state.place}
                                                id="serviceId"
                                                clearPlace={this.clearPlace}
                                                onServiceSelect={this.onServiceSelect}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        {this.state.techServices.length !== 0 && (
                                            <div className="form-group add-services">
                                                <label>Select Stylist</label>
                                                <select
                                                    multiple={true}
                                                    className="hidden"
                                                    id="technicians-select-up"
                                                    value={this.state.technician}
                                                    onChange={this.changeTech}
                                                >
                                                    {
                                                        (this.state.editMode = "update" && (
                                                            <option value="up">Select Stylist</option>
                                                        ))
                                                    }
                                                    <option value="any">Any Stylist</option>
                                                    {this.state.techServices.map((e, key) => {
                                                        return (
                                                            <option key={e.id} value={e.id}>
                                                                {e.name}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                                <div
                                                    id="technicians-select-div-up"
                                                    className="add-services"
                                                ></div>
                                            </div>
                                        )}
                                        {this.state.techServices.length == 0 && (
                                            <div className="form-group add-services">
                                                <label>Select Stylist</label>

                                                <input type="text" className="form-control" readOnly/>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Start Time</label>
                                            <DatetimePickerTrigger
                                                moment={this.state.startTime}
                                                onChange={this.changeStartTime}
                                            >
                                                <input
                                                    type="text"
                                                    value={
                                                        this.state.startTime.format("YYYY-MM-DD") +
                                                        " " +
                                                        helperFunctions.formatDateTimeWithDay(
                                                            moment(
                                                                this.state.startTime.format(
                                                                    "YYYY-MM-DDTHH:mm:ss"
                                                                )
                                                            ),
                                                            "time"
                                                        )
                                                    }
                                                    readOnly
                                                />
                                            </DatetimePickerTrigger>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>End Time</label>
                                            <DatetimePickerTrigger
                                                moment={this.state.endTime}
                                                onChange={this.changeEndTime}
                                            >
                                                <input
                                                    type="text"
                                                    value={
                                                        this.state.endTime.format("YYYY-MM-DD") +
                                                        " " +
                                                        helperFunctions.formatDateTimeWithDay(
                                                            moment(
                                                                this.state.endTime.format("YYYY-MM-DDTHH:mm:ss")
                                                            ),
                                                            "time"
                                                        )
                                                    }
                                                    readOnly
                                                />
                                            </DatetimePickerTrigger>
                                        </div>
                                        {errors.difference && (
                                            <p className="text-danger">
                                                <InlineMessage text={errors.difference}/>
                                            </p>
                                        )}
                                    </div>
                                </div>

                                <div className="form-group text-right">
                                    {this.state.editOption == "update" && (
                                        <button
                                            onClick={this.upSelectedService}
                                            className="btn btn-custom-info"
                                        >
                                            Update
                                        </button>
                                    )}

                                    {this.state.editOption == "save" && (
                                        <button
                                            onClick={this.addSelectedService}
                                            className="btn btn-custom-info"
                                        >
                                            Add Service
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade none-border" id="techTimeOffDetails">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    onClick={this.closeModal}
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-hidden="true"
                                >
                                    &times;
                                </button>
                                <h4 className="modal-title">
                                    <strong>Close Book Request</strong>
                                </h4>
                            </div>
                            <div className="modal-body">
                                <div className="pt10 form-group">
                                    <label>Time Start:&nbsp; </label>
                                    {helperFunctions.formatDateTime(this.state.timeStart)}
                                    <br/>
                                    <label>Time End:&nbsp; </label>
                                    {helperFunctions.formatDateTime(this.state.timeEnd)}
                                    <br/>
                                    <label>Technician:&nbsp; </label>
                                    {/*{*/}
                                    {/*this.state.selectedTechnician != 'all' && this.state.selectedTechnician != '' ? this.state.technicians.find(t => t.id == this.state.selectedTechnician).title : ''}<br />*/}
                                </div>

                                <div className="pt10 form-group">
                                    <b>Reason: </b>
                                    <p>{this.state.title}</p>
                                </div>

                                <div className="creator">
                                    <p className="text-danger">
                                        Closed By{" "}
                                        <img
                                            width="20"
                                            className="img-circle"
                                            src={this.state.creatorImage}
                                            alt="close by image"
                                        />{" "}
                                        <span className="text-primary">{this.state.bookedBy} </span>{" "}
                                        on{" "}
                                        {helperFunctions.formatDateTimeWithDay(
                                            moment(this.state.bookedOn),
                                            "latest"
                                        )}
                                    </p>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    onClick={() => this.cancelTimeOffRequest()}
                                    className="btn btn-danger waves-effect"
                                >
                                    Cancel Time Off
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade none-border" id="waitlist">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    onClick={this.closeModal}
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-hidden="true"
                                >
                                    &times;
                                </button>
                                <h4 className="modal-title">
                                    <strong>Customer waitlist</strong>
                                </h4>
                            </div>
                            <div className="modal-body">
                                <div className="see-all pull-left">
                                    <ul className="pager">
                                        <li>
                                            <a
                                                onClick={() => {
                                                    this.getPreviousPage();
                                                }}
                                            >
                                                <i className="fa fa-angle-left"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                onClick={() => {
                                                    this.getNextPage();
                                                }}
                                            >
                                                <i className="fa fa-angle-right"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="text-right add-button">
                                    <button
                                        onClick={this.openCreateWaitList}
                                        data-toggle="modal"
                                        data-target="#createWait"
                                        className="btn btn-custom-info"
                                    >
                                        Add new
                                    </button>
                                    <div className="switcher-spacer"></div>
                                </div>
                                <div className="steamline">
                                    {waitlist && waitlist.length == 0 && (
                                        <h3 className="text-center">Records not found </h3>
                                    )}

                                    {waitlist &&
                                        waitlist.length > 0 &&
                                        waitlist.map((a, key) => {
                                            return (
                                                <div key={key} className="sl-item">
                                                    <div className="sl-left">
                                                        <img
                                                            width="36"
                                                            src={a.client.imageUrl}
                                                            alt="user"
                                                            className="img-circle"
                                                        />
                                                    </div>
                                                    <div className="sl-right">
                                                        <div className="m-l-40">
                                                            <p>
                                                                <strong>{a.client.fullName}</strong> &nbsp;
                                                                <span>{a.readableCreateTime}</span>
                                                            </p>
                                                            <b>
                                                                <em>
                                                                    <i className="fa fa-phone"></i>{" "}
                                                                    {helperFunctions.formatMobilePhone(
                                                                        a.client.mobileNumber
                                                                    )}
                                                                    <br/>
                                                                    <i className="fa fa-envelope-o"></i>
                                                                    {a.client.emailAddress}
                                                                    <br/>
                                                                </em>
                                                            </b>

                                                            {a.services.length > 0 &&
                                                                a.services.map((s, k) => {
                                                                    return (
                                                                        <React.Fragment key={k}>
                                                                            <b>{s.service.name}</b> with{" "}
                                                                            <b>
                                                                                {s.technicians.length > 0
                                                                                    ? this.findTechnician(
                                                                                        s.technicians[0].id,
                                                                                        "nick"
                                                                                    )
                                                                                    : "Any Stylist"}
                                                                                {s.technicians.length > 1 && (
                                                                                    <React.Fragment>
                                                                                        <a
                                                                                            style={{cursor: "pointer"}}
                                                                                            onClick={window.initiatePopover()}
                                                                                            tabIndex="0"
                                                                                            data-toggle="popover"
                                                                                            data-popover-content={`#a${k}`}
                                                                                            data-placement="right"
                                                                                        >
                                                                                            {" "}
                                                                                            {s.technicians.length} Others
                                                                                        </a>

                                                                                        <div
                                                                                            id={`a${k}`}
                                                                                            className="hidden"
                                                                                        >
                                                                                            <div
                                                                                                className="popover-heading">
                                                                                                Stylist List
                                                                                            </div>

                                                                                            <div
                                                                                                className="popover-body">
                                                                                                <ul>
                                                                                                    {s.technicians.map((t, k) => {
                                                                                                        return (
                                                                                                            <li key={k}>
                                                                                                                <img
                                                                                                                    className="img-circle"
                                                                                                                    width="30"
                                                                                                                    src={t.user.imageUrl}
                                                                                                                />{" "}
                                                                                                                {t.user.names.nick}
                                                                                                                <span>
                                                          {" "}
                                                                                                                    [{" "}
                                                                                                                    {
                                                                                                                        t
                                                                                                                            .technicianServices[0]
                                                                                                                            .durationInMinutes
                                                                                                                    }{" "}
                                                                                                                    min ]{" "}
                                                        </span>
                                                                                                                <span>
                                                          {" "}
                                                                                                                    [{" "}
                                                                                                                    {
                                                                                                                        t
                                                                                                                            .technicianServices[0]
                                                                                                                            .displayedPrice
                                                                                                                    }{" "}
                                                                                                                    ]
                                                        </span>
                                                                                                            </li>
                                                                                                        );
                                                                                                    })}
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </React.Fragment>
                                                                                )}
                                                                            </b>
                                                                            <br/>
                                                                            <p className="text-italic">
                                                                                {helperFunctions.formatDateTimeWithDay(
                                                                                    a.periods[k].from,
                                                                                    "latest"
                                                                                )}{" "}
                                                                                -{" "}
                                                                                {helperFunctions.formatDateTimeWithDay(
                                                                                    a.periods[k].to,
                                                                                    "latest"
                                                                                )}
                                                                            </p>
                                                                        </React.Fragment>
                                                                    );
                                                                })}
                                                        </div>
                                                        <p className="pb35">
                              <span className="pull-right">
                                <button
                                    data-dismiss="modal"
                                    data-target="#editWaitList"
                                    data-toggle="modal"
                                    onClick={() =>
                                        this.editWaitList(a, a.client.id)
                                    }
                                    className="btn m-t-10 m-r-5 btn-custom-info"
                                >
                                  Edit
                                </button>

                                <button
                                    onClick={() => this.deleteWaitList(a.id)}
                                    data-toggle="modal"
                                    className="btn m-t-10 m-r-5 btn-danger"
                                >
                                  Delete
                                </button>
                              </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade none-border" id="closeBook">
                    <div className="modal-dialog modal-small">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    onClick={this.closeModal}
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-hidden="true"
                                >
                                    &times;
                                </button>
                                <h4 className="modal-title">
                                    <strong>Close Book</strong>
                                </h4>
                            </div>
                            <div className="modal-body">
                                <div className="pt10 form-group">
                                    <label>Time Start:&nbsp; </label>
                                    {helperFunctions.formatDateTimeWithDay(
                                        moment(this.state.offStart),
                                        "time"
                                    )}{" "}
                                    {helperFunctions.formatDateTimeWithDay(
                                        moment(this.state.timeStart),
                                        "date"
                                    )}
                                    <br/>
                                    <label>Time End:&nbsp; </label>
                                    {helperFunctions.formatDateTimeWithDay(
                                        moment(this.state.offEnd),
                                        "time"
                                    )}{" "}
                                    {helperFunctions.formatDateTimeWithDay(
                                        moment(this.state.timeEnd),
                                        "date"
                                    )}
                                    <br/>
                                    <label>Technician:&nbsp; </label>
                                    {/*{this.state.selectedTechnician != 'all' && this.state.selectedTechnician != '' ? this.state.technicians.find(t => t.id == this.state.selectedTechnician).title : ''}<br />*/}
                                </div>
                                <div className="fields">
                                    <div className="form-group group-input">
                                        <label htmlFor="service">
                                            Start Time <span className="text-danger">*</span>
                                        </label>

                                        <CustomTimePicker
                                            name="offStart"
                                            format={format}
                                            value={moment(this.state.offStart).format(format)}
                                            onChange={this.changeRequestTimeOffTimeStart}
                                        />
                                    </div>
                                    <div className="form-group  group-input">
                                        <label htmlFor="service">
                                            End Time &nbsp; <span className="text-danger">*</span>
                                        </label>

                                        <CustomTimePicker
                                            format={format}
                                            name="offEnd"
                                            onChange={this.changeRequestTimeOffTimeEnd}
                                            value={moment(this.state.offEnd).format(format)}
                                        />
                                    </div>
                                    <div className="form-group  group-input">
                                        <label htmlFor="service">
                                            Note<span className="text-danger">*</span>
                                        </label>
                                        <textarea
                                            value={this.state.staffNotes}
                                            onChange={this.changeHandler}
                                            name="staffNotes"
                                            className="form-control"
                                        ></textarea>
                                    </div>
                                </div>
                                {booking && (
                                    <div className="loaderDiv">
                                        <div className="loader"></div>
                                    </div>
                                )}
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    onClick={() => this.requestDayOff()}
                                    className="btn btn-custom-info save-event waves-effect waves-light"
                                >
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className="modal fade"
                    id="checkoutCustomer"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="checkoutcustomerlabel"
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <h4 className="modal-title" id="checkoutcustomerlabel">
                                    Check out customer
                                </h4>
                            </div>
                            <div className="modal-body">
                                <div className="form-group col-sm-12">
                                    <CheckOut/>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-info"
                                    onClick={() => this.checkOutCustomer()}
                                >
                                    Check Out
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    data-backdrop="static"
                    data-keyboard="false"
                    className="modal fade"
                    id="checkin"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel1"
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    onClick={this.resetStateWithModalClose}
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <h4 className="modal-title" id="exampleModalLabel1">
                                    Check In
                                </h4>
                            </div>

                            <div className="modal-body">
                                <CheckIn
                                    singleAppt={this.state.editApptDetails}
                                    appointmentCheckIn={this.appointmentCheckIn}
                                />
                            </div>
                            {this.state.loading == true && (
                                <div className="custom-loading">
                                    <div className="loading">
                                        <i className="fa fa-spinner fa-spin"></i>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <EditCart
                    singleAppt={this.state.editApptDetails}
                    updateStaffNotes={this.updateStaffNotes}
                    confirmAppt={this.confirmAppt}
                    apptAddUpdateService={this.apptAddUpdateService}
                    cancelServieFromAppt={this.cancelServieFromAppt}
                    checkServiceIn={this.checkServiceIn}
                    UndocheckServiceIn={this.UndocheckServiceIn}
                    checkServiceOut={this.checkServiceOut}
                    updateBookingTime={this.updateBookingTime}
                    saveProduct={this.saveProduct}
                    deleteProduct={this.deleteProduct}
                    loading={this.state.loading}
                    checkOut={this.state.checkOut}
                    apptCheckout={this.apptCheckout}
                />
                <div
                    data-backdrop="static"
                    data-keyboard="false"
                    className="modal"
                    id="viewDetails"
                >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    onClick={this.resetStateWithModalClose}
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                                <h2 className="modal-title" id="cancelLabelModel">
                                    View Details
                                </h2>
                            </div>
                            <div className="modal-body">
                                <ViewDetails
                                    singleAppt={this.state.editApptDetails}
                                    updateStaffNotes={this.updateStaffNotes}
                                    confirmAppt={this.confirmAppt}
                                    apptAddUpdateService={this.apptAddUpdateService}
                                    cancelServieFromAppt={this.cancelServieFromAppt}
                                    checkServiceIn={this.checkServiceIn}
                                    UndocheckServiceIn={this.UndocheckServiceIn}
                                    checkServiceOut={this.checkServiceOut}
                                    updateBookingTime={this.updateBookingTime}
                                />
                            </div>

                            {this.state.loading == true && (
                                <div className="custom-loading">
                                    <div className="loading">
                                        <i className="fa fa-spinner fa-spin"></i>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {/* Checkout Modal */}
                <div className="modal" id="cartModal">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                                <h4 className="modal-title" id="cancelLabelModel">
                                    Check Out Appointment
                                </h4>
                            </div>
                            {this.state.cartCollections.length > 0 && (
                                <GroupCart
                                    taxAmount={this.state.taxAmount}
                                    tipAmount={this.state.tipAmount}
                                    productPurchaseAmount={this.state.productPurchaseAmount}
                                    serviceChargeAmount={this.state.serviceChargeAmount}
                                    total={this.state.totalCharge}
                                    editCartFromCollection={this.editCartFromCollection}
                                    removeFromCart={this.removeFromCart}
                                    cartCollections={this.state.cartCollections}
                                    discountDeductionAmount={this.state.discountDeductionAmount}
                                    rewardDeductionAmount={this.state.rewardDeductionAmount}
                                    creditPaidAmount={this.state.creditPaidAmount}
                                    cashPaidAmount={this.state.cashPaidAmount}
                                    giftCardPaidAmount={this.state.giftCardPaidAmount}
                                    placeGroupCheckOut={this.placeGroupCheckOut}
                                />
                            )}
                        </div>
                    </div>
                </div>

                <div
                    data-backdrop="static"
                    data-keyboard="false"
                    className="modal"
                    id="checkOutModal"
                >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    onClick={this.resetStateWithModalClose}
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                                <h4 className="modal-title" id="cancelLabelModel">
                                    Check Out
                                </h4>
                            </div>

                            <div className="modal-body">
                                <div className="row">
                                    <div>
                                        <CheckOut
                                            checkoutDetails={this.state.editApptDetails}
                                            proccessCheckOut={this.proccessCheckOut}
                                            apptCheckout={this.apptCheckout}
                                        />
                                    </div>
                                </div>
                            </div>

                            {this.state.loading == true && (
                                <div className="custom-loading">
                                    <div className="loading">
                                        <i className="fa fa-spinner fa-spin"></i>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div
                    data-backdrop="static"
                    data-keyboard="false"
                    className="modal fade"
                    id="addingTask"
                    tabIndex="-1"
                    role="dialog"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    onClick={this.closeAddTaskModal}
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <h4 className="modal-title">Add Task</h4>
                            </div>
                            <div className="modal-body">
                                <AddTask
                                    predefinedRelatedItem={
                                        this.state.taskSelected.taskSelectedClient
                                    }
                                    predefinedRelatedId={
                                        this.state.taskSelected.taskSelectedItemId
                                    }
                                    predefinedRelatedTo="Appointment"
                                    mode={this.state.mode}
                                    saveTask={this.saveTask}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    data-backdrop="static"
                    data-keyboard="false"
                    className="modal"
                    id="addNote"
                    role="dialog"
                    aria-labelledby="exampleModalLabel1"
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button className="btn close" data-dismiss="modal">
                                    &times;
                                </button>
                                <h3>Edit Note</h3>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label>
                                        Write a note here: &nbsp;{" "}
                                        <span className="text-danger">*</span>
                                    </label>
                                    <textarea
                                        onChange={this.handleChange}
                                        required
                                        maxLength="1000"
                                        className="form-control height-200"
                                        value={
                                            this.state.staffNotes != ""
                                                ? this.state.staffNotes
                                                : this.state.viewDetails.staffNotes
                                        }
                                        name="staffNotes"
                                    ></textarea>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button onClick={this.saveNote} className="btn btn-custom-info">
                                    Update Note
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    data-backdrop="static"
                    data-keyboard="false"
                    className="modal fade"
                    id="cancelAppt"
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    onClick={this.resetStateWithModalClose}
                                    className="btn close"
                                    data-dismiss="modal"
                                >
                                    &times;
                                </button>
                                <h3>Cancellation Reason</h3>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label>
                                        Reason: &nbsp; <span className="text-danger">*</span>
                                    </label>
                                    <textarea
                                        onChange={this.handleChange}
                                        required
                                        maxLength="1000"
                                        className="form-control height-200"
                                        value={this.state.cancelReason}
                                        name="cancelReason"
                                    ></textarea>
                                </div>

                                <div className="switcher-spacer"></div>
                                <div className="switcher-spacer"></div>

                                <div className="form-group text-right">
                                    <button
                                        onClick={() => this.cancelAppt()}
                                        type="submit"
                                        className="btn btn-custom-info"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                            {this.state.loading == true && (
                                <div className="custom-loading">
                                    <div className="loading">
                                        <i className="fa fa-spinner fa-spin"></i>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div
                    data-backdrop="static"
                    data-keyboard="false"
                    className="modal fade"
                    id="persona"
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <Persona
                                    tribes={this.state.tribes}
                                    prevQuestions={this.state.prevQuestions}
                                />
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-warning" data-dismiss="modal">
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade none-border" id="newCustomerModal">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    data-toggle="modal"
                                    data-target="#createEvent"
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-hidden="true"
                                >
                                    &times;
                                </button>
                                <h4 className="modal-title">
                                    <strong>New Customer</strong>
                                </h4>
                            </div>
                            <div className="modal-body">
                                <NewCustomer renderSuccessMessage={this.renderSuccessMessage}/>
                            </div>
                            <div className="modal-footer">
                                <button
                                    data-toggle="modal"
                                    data-target="#createEvent"
                                    data-dismiss="modal"
                                    type="submit"
                                    className="btn btn-success text-uppercase"
                                >
                                    Register
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    data-backdrop="static"
                    data-keyboard="false"
                    className="modal fade"
                    id="miniWait"
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <strong>Replace from wait list ?</strong>
                                <button
                                    data-toggle="modal"
                                    data-target="#createEvent"
                                    data-dismiss="modal"
                                    className="close"
                                    onClick={() => this.closeThisModal()}
                                >
                                    &times;
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="steamline">
                                    {this.state.selectedWait &&
                                        this.state.selectedWait.length > 0 &&
                                        this.state.selectedWait.map((a, key) => {
                                            return (
                                                <div
                                                    key={key}
                                                    className={
                                                        this.checkTheActive(a) == "active"
                                                            ? "sl-item active"
                                                            : "sl-item"
                                                    }
                                                >
                                                    <div className="sl-left">
                                                        <img
                                                            width="36"
                                                            src={a.client.imageUrl}
                                                            alt="user"
                                                            className="img-circle"
                                                        />
                                                    </div>
                                                    <div className="sl-right">
                                                        <div className="m-l-40">
                                                            <p>
                                                                <strong>{a.client.fullName}</strong> &nbsp;
                                                                <span>{a.readableCreateTime}</span>
                                                            </p>
                                                            <b>
                                                                <em>
                                                                    <i className="fa fa-phone"></i>{" "}
                                                                    {helperFunctions.formatMobilePhone(
                                                                        a.client.mobileNumber
                                                                    )}
                                                                    <br/>
                                                                    <i className="fa fa-envelope-o"></i>
                                                                    {a.client.emailAddress}
                                                                    <br/>
                                                                </em>
                                                            </b>

                                                            {a.services.length > 0 &&
                                                                a.services.map((s, k) => {
                                                                    return (
                                                                        <React.Fragment key={k}>
                                                                            <b>{s.service.name}</b> with{" "}
                                                                            <b>
                                                                                {s.technicians.length > 0
                                                                                    ? this.findTechnician(
                                                                                        this.state.selectedTechnician,
                                                                                        "nick"
                                                                                    )
                                                                                    : "Any Stylist"}
                                                                                {s.technicians.length > 1 && (
                                                                                    <React.Fragment>
                                                                                        <a
                                                                                            style={{cursor: "pointer"}}
                                                                                            onClick={window.initiatePopover()}
                                                                                            tabIndex="0"
                                                                                            data-toggle="popover"
                                                                                            data-popover-content={`#a${k}`}
                                                                                            data-placement="right"
                                                                                        >
                                                                                            {" "}
                                                                                            {s.technicians.length} Others
                                                                                        </a>

                                                                                        <div
                                                                                            id={`a${k}`}
                                                                                            className="hidden"
                                                                                        >
                                                                                            <div
                                                                                                className="popover-heading">
                                                                                                Technician Lists
                                                                                            </div>

                                                                                            <div
                                                                                                className="popover-body">
                                                                                                <ul>
                                                                                                    {s.technicians
                                                                                                        .filter(
                                                                                                            (t) =>
                                                                                                                t.id !=
                                                                                                                this.state
                                                                                                                    .selectedTechnician
                                                                                                        )
                                                                                                        .map((t, k) => {
                                                                                                            return (
                                                                                                                <li key={k}>
                                                                                                                    <img
                                                                                                                        className="img-circle"
                                                                                                                        width="30"
                                                                                                                        src={
                                                                                                                            t.user.imageUrl
                                                                                                                        }
                                                                                                                    />{" "}
                                                                                                                    {t.user.names.nick}
                                                                                                                </li>
                                                                                                            );
                                                                                                        })}
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </React.Fragment>
                                                                                )}
                                                                            </b>
                                                                            <br/>
                                                                            <p className="text-italic">
                                                                                {helperFunctions.formatDateTimeWithDay(
                                                                                    a.periods[k].from,
                                                                                    "latest"
                                                                                )}{" "}
                                                                                -{" "}
                                                                                {helperFunctions.formatDateTimeWithDay(
                                                                                    a.periods[k].to,
                                                                                    "latest"
                                                                                )}
                                                                            </p>
                                                                        </React.Fragment>
                                                                    );
                                                                })}
                                                        </div>
                                                        <p className="pb35">
                              <span className="pull-right">
                                <button
                                    data-dismiss="modal"
                                    onClick={() =>
                                        this.addServiceToAppt(a, a.client.id)
                                    }
                                    className="btn m-t-10 m-r-5 btn-custom-info"
                                >
                                  Replace
                                </button>
                              </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>

                            <div className="modal-footer">
                                <button
                                    data-dismiss="modal"
                                    data-target="#cancelAppt"
                                    data-toggle="modal"
                                    className="btn btn-danger"
                                >
                                    Go with cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.menuState == true && (
                    <ul className="context-menu calendar" id="contextMenu">
                        {Object.keys(this.state.viewDetails).length > 0 && (
                            <li className="positioned_close">
                                <a
                                    href="javascript:void(0)"
                                    onClick={this.closeContextMenu.bind(this)}
                                >
                                    <i className="fa fa-close text-danger"></i>
                                </a>
                            </li>
                        )}
                        {Object.keys(this.state.viewDetails).length > 0 && (
                            <li
                                style={{cursor: "pointer"}}
                                className="more-info"
                                onClick={() => this.loggedUser.technicianResponsibilities.length > 0 && this.loggedUser.technicianResponsibilities[0].customerAccessAllowed === false ? '' : this.openCaller()}
                            >
                <span className="soruce">
                  <i
                      className={this.getRequestSource(
                          this.state.viewDetails.requestSource
                      )}
                  ></i>
                </span>
                                <span>
                  {this.state.viewDetails.client.fullName} -{" "}
                                    {this.loggedUser.technicianResponsibilities.length > 0 && this.loggedUser.technicianResponsibilities[0].customerAccessAllowed === false ? null : helperFunctions.formatMobilePhone(
                                        this.state.viewDetails.client.mobileNumber
                                    )}
                </span>
                                <span>
                  {this.state.viewDetails.visitTechnicians.length > 0 &&
                      this.findServiceName(
                          this.state.viewDetails.visitTechnicians[0].offeredService
                              .id,
                          this.state.viewDetails.visitTechnicians[0].offeredService
                              .category.id
                      ) +
                      " with " +
                      this.findTechnician(
                          this.state.viewDetails.visitTechnicians[0].technician
                              .id,
                          "nick"
                      )}
                                    {this.state.viewDetails.visitTechnicians.length > 1 &&
                                        `(${this.state.viewDetails.visitTechnicians.length})`}
                </span>
                            </li>
                        )}

                        {this.state.viewDetails.status == "Checked Out" && (
                            <li>
                                <a
                                    data-toggle="modal"
                                    onClick={() => this.apptViewDetails()}
                                    href="#viewDetails"
                                >
                                    <i className="fa fa-eye"></i>View Details
                                </a>
                            </li>
                        )}

                        {Object.keys(this.state.viewDetails).length > 0 && this.loggedUser.technicianResponsibilities.length > 0 && this.loggedUser.technicianResponsibilities[0].customerAccessAllowed == false ? null : Object.keys(this.state.viewDetails).length > 0 && (
                            <li>
                                <Link
                                    to={
                                        `/${this.storeId}` +
                                        "/update-customer?custId=" +
                                        this.state.viewDetails.client.id
                                    }
                                >
                                    <i className="fa fa-user"></i>View Client
                                </Link>
                            </li>
                        )}

                        {this.state.tribes && this.state.tribes.length > 0 && (
                            <li>
                                <a
                                    onClick={() => this.persona(this.state.viewDetails.client.id)}
                                >
                                    <i className="fa fa-user"></i>View Persona
                                </a>
                            </li>
                        )}

                        {this.state.viewDetails.checkInOutAllowed == true &&
                            this.state.viewDetails.status == "Booked" && (
                                <li>
                                    <a
                                        onClick={() => this.openCheckinModal()}
                                        href="#checkin"
                                        data-toggle="modal"
                                    >
                                        <i className="fa fa-check"></i>Check In
                                    </a>
                                </li>
                            )}
                        {this.state.viewDetails.checkInOutAllowed == true &&
                            this.state.viewDetails.status == "Booked" && (
                                <React.Fragment>
                                    <li>
                                        <a
                                            onClick={this.openCheckOutModal}
                                            href="#editModal"
                                            data-toggle="modal"
                                        >
                                            <i className="mdi mdi-run"></i>Check Out
                                        </a>
                                    </li>
                                </React.Fragment>
                            )}

                        {this.state.viewDetails.checkInOutAllowed == true &&
                            this.state.viewDetails.status == "Checked In" && (
                                <React.Fragment>
                                    <li>
                                        <a
                                            onClick={this.openCheckOutModal}
                                            href="#editModal"
                                            data-toggle="modal"
                                        >
                                            <i className="mdi mdi-run"></i>Check Out
                                        </a>
                                    </li>
                                </React.Fragment>
                            )}

                        {this.state.viewDetails.status == "Checked In" && (
                            <li>
                                <a
                                    onClick={() =>
                                        this.UndocheckServiceIn(this.state.viewDetails.id)
                                    }
                                >
                                    <i className="fa fa-undo"></i>Undo Checked In
                                </a>
                            </li>
                        )}

                        {this.state.viewDetails.status == "Checked In" && (
                            <li>
                                <a href="javascript:void(0)" onClick={this.openModal}>
                                    <i className="fa fa-plus"></i>Add To Cart
                                </a>
                            </li>
                        )}
                        {this.state.viewDetails.status == "all-checked-out" && (
                            <li>
                                <a href="javascript:void(0)" onClick={this.openModal}>
                                    <i className="fa fa-plus"></i>Add To Cart
                                </a>
                            </li>
                        )}
                        {this.state.viewDetails.status == "Checked In" && (
                            <li>
                                <a
                                    href="#editModal"
                                    data-toggle="modal"
                                    onClick={() => this.openEditModal()}
                                >
                                    <i className="fa fa-pencil"></i>Edit Appt
                                </a>
                            </li>
                        )}
                        {this.state.viewDetails.status == "Booked" && (
                            <li>
                                <a
                                    href="#editModal"
                                    data-toggle="modal"
                                    onClick={() => this.openEditModal()}
                                >
                                    <i className="fa fa-pencil"></i>Edit Appt
                                </a>
                            </li>
                        )}
                        {/*this.state.viewDetails.status == 'Checked Out' && this.accessRole == true &&
                        <li><a href='#editModal' data-toggle="modal" onClick={() => this.openEditModal()}><i
                            className='fa fa-pencil'></i>Edit Appt</a></li>
                        */}
                        {this.state.viewDetails.status == "Booked" && (
                            <React.Fragment>
                                <li>
                                    <a
                                        onClick={() =>
                                            this.taskSelectedItem(this.state.viewDetails)
                                        }
                                        href="#addingTask"
                                        data-toggle="modal"
                                    >
                                        <i className="fa fa-check-square"></i>Add Task
                                    </a>
                                </li>
                                <li>
                                    <a
                                        onClick={() => this.setState({menuState: false})}
                                        href="#addNote"
                                        data-toggle="modal"
                                    >
                                        <i className="fa fa-sticky-note-o"></i>Edit Note
                                    </a>
                                </li>
                            </React.Fragment>
                        )}
                        {this.state.viewDetails.status == "Checked In" && (
                            <React.Fragment>
                                <li>
                                    <a
                                        onClick={() =>
                                            this.taskSelectedItem(this.state.viewDetails)
                                        }
                                        href="#addingTask"
                                        data-toggle="modal"
                                    >
                                        <i className="fa fa-check-square"></i>Add Task
                                    </a>
                                </li>
                                <li>
                                    <a
                                        onClick={() => this.setState({menuState: false})}
                                        href="#addNote"
                                        data-toggle="modal"
                                    >
                                        <i className="fa fa-sticky-note-o"></i>Edit Note
                                    </a>
                                </li>
                            </React.Fragment>
                        )}

                        {this.state.viewDetails.status == "Booked" && (
                            <li>
                                <a onClick={() => this.openCancelModal()}>
                                    <i className="fa fa-trash-o"></i>Cancel
                                </a>
                            </li>
                        )}
                        {this.state.viewDetails.status == "Checked In" && (
                            <li>
                                <a
                                    onClick={() => this.openCancelModal()}
                                    href="#cancelAppt"
                                    data-toggle="modal"
                                >
                                    <i className="fa fa-trash-o"></i>Cancel
                                </a>
                            </li>
                        )}
                        {this.state.viewDetails.bookedTime && (
                            <li className="bookedBy">
                                Booked By{" "}
                                <span className="text-primary">
                  {this.state.viewDetails.createUser.names.nick}
                </span>
                                <br/>{" "}
                                {helperFunctions.formatDateTimeWithDay(
                                    moment(this.state.viewDetails.bookedTime),
                                    "latest"
                                )}
                            </li>
                        )}
                    </ul>
                )}
                {this.state.dateClickMenu == true && (
                    <ul className="context-menu calendar" id="dateClickContextMenu">
                        <li className="d-flex justify-between align-items-center" style={{paddingLeft: '15px'}}>
                            <span>{helperFunctions.formatDateTime(this.state.offStart, 'time')} -{" "}
                                {helperFunctions.formatDateTime(this.state.offEnd, 'time')}</span>
                            <a
                                style={{paddingRight: '5px'}}
                                href="javascript:void(0)"
                                onClick={this.closeDateClickContextMenu.bind(this)}
                            >
                                <i className="fa fa-close" style={{color: 'red'}}></i>
                            </a>
                        </li>
                        <li>
                            <a onClick={this.openAddAppointment}
                               href="javascript:void(0)">
                                <i className="fa fa-eye"></i>Add Appointment
                            </a>
                        </li>
                        <li>
                            <a onClick={this.openCloseBookModal} href="javascript:void(0)">
                                <i className="fa fa-check"></i>Close Book
                            </a>
                        </li>
                    </ul>
                )}
                {this.state.cartAdded != "" && (
                    <div
                        data-target="#cartModal"
                        data-toggle="modal"
                        className="cart-label calendar"
                    >
                        <div className="alert alert-warning alert-dismissible" role="alert">
                            <div className="cart-techs">
                                {this.state.cartCollections &&
                                    this.state.cartCollections[
                                    this.state.cartCollections.length - 1
                                        ].visitTechnicians.map((c, k) => {
                                        return (
                                            <React.Fragment key={k}>
                                                <img
                                                    width="70"
                                                    src={this.findTechnicianImage(c.technician.id)}
                                                />
                                                <span>
                          {" "}
                                                    {this.state.cartCollections[
                                                        this.state.cartCollections.length - 1
                                                            ].visitTechnicians.length > 2 &&
                                                        `+ ${this.state.cartCollections[
                                                        this.state.cartCollections.length - 1
                                                            ].visitTechnicians.length - 1
                                                        }`}{" "}
                        </span>
                                            </React.Fragment>
                                        );
                                    })}
                            </div>

                            {this.state.cartAdded == "added" && (
                                <strong>
                                    Added{" "}
                                    <span className="counts text-danger">
                    {this.state.cartCollections.length}
                  </span>{" "}
                                    item{this.state.cartCollections.length < 2 ? "" : "s"} to the
                                    cart
                                </strong>
                            )}
                            {this.state.cartAdded == "exist" && (
                                <strong>
                                    Already exist in the cart{" "}
                                    <span className="counts text-danger">
                    ( {this.state.cartCollections.length} )
                  </span>
                                </strong>
                            )}
                            <div className="odometer">
                                <strong>Cart Total : </strong>
                                <span className="text-danger">
                  <Odometer
                      duration={1000}
                      value={this.state.totalCharge}
                      format="(,ddd).ddd"
                  />
                </span>
                            </div>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="alert"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    openAddAppointment = () => {
        this.setState({
            customer: this.userId,
            dateClickMenu: false,
        });
        $("#closeBook").modal("hide");
        $("#createEvent").modal("toggle");
    };
    openCloseBookModal = () => {
        this.setState({
            dateClickMenu: false,
        });
        $("#createEvent").modal("hide");
        window.showCloseBookModal();
    };

    setServiceDefaultEndTime() {
        const {categories} = this.state;
        var serviceId = this.state.service;
        var categoryId = this.state.category;
        var service = categories
            .find((cat) => cat.id == categoryId)
            .services.find((s) => s.id == serviceId);
        var newTime = moment(this.state.timeStart).add(
            service.durationInMinutes,
            "minutes"
        );
        this.setState({timeEnd: newTime.format("YYYY-MM-DDTHH:mm:ss")});
    }

    changeCalendarView(view, element) {
        this.calendarView = view.type;
    }

    eventClickHandler(eventClickInfo) {

        // var event = eventClickInfo.event
        //var calEvent = this.events.find((e) => e.resourceId == eventClickInfo.event.id); //API ALSO NEEDS TO UPDATE AND NEED TO ADD EXTRA PROPERTY
        var calEvent = this.events.find((e) => e.id == eventClickInfo.event.id);

        if (calEvent != null && calEvent.outOfOffice != true) {
            reservationService
                .getAllWaitList(
                    0,
                    "open-tech",
                    "",
                    this.state.calendarDate,
                    this.state.calendarDate,
                    calEvent.resourceId
                )
                .then((res) => {
                    this.setState({
                        selectedWait: res,
                        replace: false,
                    });
                });

            this.setState(
                {
                    menuState: true,
                    visitId: eventClickInfo.event.id,
                    selectedTechnician: calEvent.resourceId,
                    start: calEvent.start,
                    end: calEvent.end,
                },
                () => {
                    appService.getSingleVtService(eventClickInfo.event.id).then((res) => {
                        this.setState(
                            {
                                ...this.state,
                                viewDetails: res,
                            },
                            () => {
                                userService
                                    .getCustomerInfo(this.state.viewDetails.client.id)
                                    .then((user) => {
                                        this.setState({
                                            singleUser: user,
                                            tribes: user.personaTribes,
                                        });
                                    });
                                userService
                                    .getAllAnswers(this.state.viewDetails.client.id)
                                    .then((questions) => {
                                        this.setState({
                                            prevQuestions: questions,
                                        });
                                    });
                            }
                        );
                    });

                    let element = document.getElementById("contextMenu");
                    element.style.left =
                        event.pageX >= window.innerWidth - 200
                            ? event.pageX - 210 + "px"
                            : event.pageX + "px";
                    element.style.top =
                        event.pageY >= window.innerHeight - 200
                            ? event.pageY - 200 + "px"
                            : event.pageY + "px";
                }
            );
        } else if (calEvent.type == "DAY_OFF" || calEvent.type == "NOT_WORKING") {
            this.setState({
                timeStart: calEvent.start,
                timeEnd: calEvent.end,
                offStart:
                    moment(calEvent.start).format(format) ==
                    moment(
                        this.app.visualAttributes.silverbirdCalStart,
                        "HH:mm a"
                    ).format("HH:mm a")
                        ? moment(calEvent.start).add(1, "minutes")
                        : moment(calEvent.start),
                offEnd:
                    moment(calEvent.end).format(format) ==
                    moment(this.app.visualAttributes.silverbirdCalEnd, "HH:mm a").format(
                        "hh:mm a"
                    )
                        ? moment(calEvent.end).subtract(1, "minutes")
                        : moment(calEvent.end),
                selectedTechnician: calEvent.resourceId,
                visitId: calEvent.id,
                customerId: this.state.resources.find(
                    (resource) => resource.id === calEvent.resourceId
                ).user.id,
            });
            window.showTechDayOffDetailsModal();
        } else if (calEvent.type == "TIME_OFF") {
            appService.getSingleVtService(calEvent.id).then((res) => {
                this.setState({
                    timeStart: moment(calEvent.start),
                    timeEnd: moment(calEvent.end),
                    selectedTechnician: calEvent.resourceId,
                    visitId: calEvent.id,
                    title: calEvent.title,
                    bookedBy: res.createUser.names.nick,
                    bookedOn: res.createdTime,
                    creatorImage: res.createUser.imageUrl,
                });
            });

            $("#techTimeOffDetails").modal("toggle");
        }
    }

    closeContextMenu() {
        this.setState({
            menuState: false,
        });
    }

    closeDateClickContextMenu() {
        this.setState({
            dateClickMenu: false,
        });
    }

    cancelTimeOffRequest() {
        var thisObj = this;
        reservationService.cancelVisit("", this.state.visitId).then(
            (r) => {
                let calendarApi = thisObj.calendarRef.current.getApi();
                calendarApi.refetchEvents();
                window.closeTechTimeOffDetailsModal();
            },
            (error) => {
                window.closeTechTimeOffDetailsModal();
            }
        );
    }

    isTechOutOfOffice(start, end, resourceId) {
        var timeOffAppt = this.events.find(
            (event) => event.outOfOffice == true && event.resourceId == resourceId
        );
        start = moment(start);
        end = moment(end);
        if (timeOffAppt == null) {
            return false;
        }
        if (
            start.isSameOrBefore(timeOffAppt.start) &&
            start.isAfter(timeOffAppt.end)
        )
            return true;
        else if (
            end.isSameOrBefore(timeOffAppt.end) &&
            end.isAfter(timeOffAppt.start)
        )
            return true;
        return false;
    }

    resetFilters = () => {
        //console.log('Needs to apply')
    };

    searchTechs = () => {
        //console.log('Needs to apply')
    };

    selectTimeSlot(selectionInfo) {
        var start = selectionInfo.start;

        var end = selectionInfo.end;
        if (selectionInfo.resource) {
            var resource = selectionInfo.resource.id;
            this.setState({
                selectedTechnician: resource,
                customerId: selectionInfo && selectionInfo.resource && selectionInfo.resource.extendedProps && selectionInfo.resource.extendedProps.user ? selectionInfo.resource.extendedProps.user.id : '',
            });
        }


        if (this.isTechOutOfOffice(start, end, resource)) {
            window.showTechOffModal(true);
            return false;
        } else {
            this.setState({
                dateClickMenu: true,
            });
            let element = document.getElementById("dateClickContextMenu");

            element.style.left =
                event.pageX >= window.innerWidth - 200
                    ? event.pageX - 210 + "px"
                    : event.pageX + "px";
            element.style.top =
                event.pageY >= window.innerHeight - 200
                    ? event.pageY - 200 + "px"
                    : event.pageY + "px";

            this.setState({timeStart: start});
            this.setState({offStart: start});
            this.setState({offEnd: end});
            this.setState({timeEnd: end});
            this.setState({category: ""});
            this.setState({service: ""});
            this.setState({services: []});
            this.setState({techServiceError: false});
            this.setState({booking: false});
        }
    }

    findServiceName(serviceId, categoryId) {
        const {categories} = this.state;
        // eslint-disable-next-line
        let category = categories.find((sc) => sc.id == categoryId);
        if (category == null) return "Service not found";
        let service = category.services.find((s) => s.id == serviceId);
        if (service == null) return "Service not found";
        return service.name;
    }

    requestTimeOff = () => {
        if (this.state.booking == true) return;
        this.setState({booking: true});
        var technician = this.state.selectedTechnician;
        var ends = "";
        if (this.state.offEnd != "") {
            if (this.state.offEnd.format(format) != "07:30 pm") {
                ends = this.state.offEnd;
            } else {
                ends = this.state.offEnd.subtract(1, "minutes");
            }
        }

        var starts = "";

        if (this.state.offStart != "") {
            if (this.state.offStart.format(format) != "08:30 am") {
                starts = this.state.offStart;
            } else {
                starts = this.state.offStart.add(1, "minutes");
            }
        }


        //eslint-disable-next-line
        var timeOffJson = {
            location: {id: JSON.parse(localStorage.getItem("app")).locations[0].id},
            client: {id: this.state.customerId},
            requestType: "Opt-In Work",
            customerNotes: this.state.staffNotes,
            visitTechnicians: [
                {
                    technician: {
                        id: technician,
                    },
                    period: {
                        to:
                            ends != ""
                                ? moment(this.state.timeEnd).format("YYYY-MM-DDT") +
                                moment(ends).format("HH:mm:ss")
                                : moment(this.state.timeEnd).format("YYYY-MM-DDTHH:mm:ss"),
                    },
                    expectedStartTime:
                        this.state.offStart != ""
                            ? moment(this.state.timeStart).format("YYYY-MM-DDT") +
                            moment(starts).format("HH:mm:ss")
                            : moment(this.state.timeStart)
                                .subtract(1, "minutes")
                                .format("YYYY-MM-DDTHH:mm:ss"),
                },
            ],
            requestSource: "Phone Call",
        };
        var thisObj = this;
        reservationService
            .placeDayOff(timeOffJson)
            .then((response) => {
                $("#showTechDayOffModal").modal("toggle");
                let calendarApi = thisObj.calendarRef.current.getApi();
                calendarApi.refetchEvents();
                this.setState({
                    category: "",
                    service: "",
                    services: [],
                    techServiceError: false,
                    booking: false,
                    useSelectedDate: true,
                    staffNotes: "",
                    timeEnd: "",
                    timeStart: "",
                    offStart: "",
                    offEnd: "",
                });
            })
            .catch(async (e) => {
                this.setState({
                    booking: false,
                });
                let error = await e.json();

                if (
                    Object.keys(error).length > 0 &&
                    error.code &&
                    error.code == "visit_override_confirmation_required"
                ) {
                    Swal.fire({
                        title: "Warning",
                        text: "Time slot, you selected is booked, time off or day off. Are you sure to override?",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Yes",
                    }).then((confirm) => {
                        if (confirm.value != undefined) {
                            reservationService
                                .saveAppointment(timeOffJson, true)
                                .then((response) => {
                                    window.DataUpdateSuccess();
                                });
                        }
                    });
                } else {
                    Swal.fire({
                        title: "Error",
                        text: error.message,
                        type: "error",
                        showCancelButton: false,
                        confirmButtonText: "Ok!",
                    });
                    this.setState({loading: false, booking: false});
                }
            });
    };

    requestDayOff = () => {
        if (this.state.booking == true) return;
        this.setState({booking: true});
        var technician = this.state.selectedTechnician;

        var timeOffJson = {
            location: {id: JSON.parse(localStorage.getItem("app")).locations[0].id},
            client: {id: this.state.customerId},
            requestType: "Time-Off",
            customerNotes: this.state.staffNotes,
            visitTechnicians: [
                {
                    technician: {
                        id: technician,
                    },
                    period: {
                        to:
                            this.state.offEnd != ""
                                ? moment(this.state.timeEnd).format("YYYY-MM-DDT") +
                                moment(this.state.offEnd).format("HH:mm:ss")
                                : moment(this.state.timeEnd).format("YYYY-MM-DDTHH:mm:ss"),
                    },
                    expectedStartTime:
                        this.state.offStart != ""
                            ? moment(this.state.timeStart).format("YYYY-MM-DDT") +
                            moment(this.state.offStart).format("HH:mm:ss")
                            : moment(this.state.timeStart).format("YYYY-MM-DDTHH:mm:ss"),
                },
            ],
            requestSource: "Phone Call",
        };
        var thisObj = this;
        reservationService
            .placeDayOff(timeOffJson)
            .then((response) => {
                $("#closeBook").modal("hide");
                let calendarApi = thisObj.calendarRef.current.getApi();
                calendarApi.refetchEvents();
                this.setState({
                    category: "",
                    service: "",
                    services: [],
                    techServiceError: false,
                    booking: false,
                    useSelectedDate: true,
                    staffNotes: "",
                    timeEnd: "",
                    timeStart: "",
                    offStart: "",
                    offEnd: "",
                });
            })
            .catch(async (e) => {
                let error = await e.json();

                if (
                    Object.keys(error).length > 0 &&
                    error.code &&
                    error.code == "visit_override_confirmation_required"
                ) {
                    Swal.fire({
                        title: "Warning",
                        text: "Time slot, you selected is booked, time off or day off. Are you sure to override?",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Yes",
                    }).then((confirm) => {
                        $("#closeBook").modal("hide");
                        if (confirm.value != undefined) {
                            reservationService
                                .saveAppointment(timeOffJson, true)
                                .then((response) => {
                                    window.DataUpdateSuccess();
                                });
                        }
                    });
                } else {
                    Swal.fire({
                        title: "Error",
                        text: error.message,
                        type: "error",
                        showCancelButton: false,
                        confirmButtonText: "Ok!",
                    });
                }
                this.setState({loading: false});
            });
    };

    findTechnician(technicianId, query) {
        // eslint-disable-next-line
        let technician = this.app.locations[0].technicians.find(
            (technician) => technician.id == technicianId
        );

        if (technician) {
            if (query == "image") {
                return technician.user.imageUrl;
            } else if (query == "active") {
                if (technician.active == "true") {
                    return "active";
                }
            } else if (query == "nick") {
                return technician.user.names.nick;
            } else if (query == "clientId") {
                return technician.user.id;
            } else {
                return technician.user.fullName;
            }
        } else {
            return "";
        }
    }

    bookAppointment() {
        if (this.state.booking == true) return;
        this.setState({booking: true});
        if (this.state.techServiceError || this.state.service == "") return false;
        var technician = this.state.selectedTechnician;
        if (technician == "all") {
            technician = "any";
        }
        var selectedServices = [
            {
                technician: {id: technician},
                offeredService: {id: this.state.service},
                expectedStartTime: this.state.timeStart,
                period: {to: this.state.timeEnd},
            },
        ];

        var reservationJson = {
            location: {id: JSON.parse(localStorage.getItem("app")).locations[0].id},
            client: {id: this.userId},
            requestSource: this.state.requestSource,
            customerNotes: this.state.notes,
            visitTechnicians: selectedServices,
        };

        var today = new Date();
        if (helperFunctions.compareDateStrings(today, this.calendarDate)) {
            today.setDate(today.getDate() + 1);
            this.calendarDate = today;
        }
        var thisObj = this;
        reservationService.placeCalendarReservation(reservationJson).then(
            (response) => {
                window.closeCreateEventModal();
                let calendarApi = thisObj.calendarRef.current.getApi();
                calendarApi.refetchEvents();
                window.toast("success", "Appointment created successfully");
                this.setState({
                    category: "",
                    service: "",
                    services: [],
                    techServiceError: false,
                    booking: false,
                    useSelectedDate: true,
                });
            },
            (error) => {
                window.closeCreateEventModal();
                window.showReservationConflictAlert();
            }
        );
    }

    findTechnicianImage(technicianId) {
        let technician = this.app.locations[0].technicians.find(
            (technician) => technician.id == technicianId
        );
        return technician.user.imageUrl;
    }

    checkPriceType = (min, max) => {
        if (min == "-1" && max == "-1") {
            return "Per Consult";
        } else if (min > 0 && max > 0 && min < max) {
            return min * 1;
        } else if (min > 0 && max == -1) {
            return min * 1;
        } else {
            return min * 1;
        }
    };

    getNextPage() {
        var thisObj = this;
        if (this.state.techValue == "") {
            var offset = this.state.pageOffset + 1;
            const {waitlist} = this.state;
            if (waitlist && waitlist.length != 0) {
                reservationService
                    .getAllWaitList(
                        offset,
                        "open-lctn",
                        "",
                        this.state.calendarDate,
                        this.state.ends
                    )
                    .then((res) => {
                        this.setState({
                            ...this.state,
                            waitlist: res,
                            pageOffset: offset,
                        });
                    });
            }
        } else {
            var offset = this.state.pageOffset + 1;
            const {waitlist} = this.state;
            if (waitlist && waitlist.length != 0) {
                reservationService
                    .getAllWaitList(
                        offset,
                        "open-lctn",
                        "",
                        this.state.calendarDate,
                        this.state.ends,
                        this.state.techValue
                    )
                    .then((res) => {
                        this.setState({
                            ...this.state,
                            waitlist: res,
                            pageOffset: offset,
                        });
                    });
            }
        }
    }

    getPreviousPage() {
        var thisObj = this;
        if (this.state.techValue == "") {
            var offset = this.state.pageOffset - 1;
            const {waitlist} = this.state;
            if (offset >= 0) {
                reservationService
                    .getAllWaitList(
                        offset,
                        "open-lctn",
                        "",
                        this.state.calendarDate,
                        this.state.ends
                    )
                    .then((res) => {
                        this.setState({
                            waitlist: res,
                            pageOffset: offset,
                        });
                    });
            }
        } else {
            var offset = this.state.pageOffset - 1;
            const {waitlist} = this.state;
            if (offset >= 0) {
                reservationService
                    .getAllWaitList(
                        offset,
                        "open-lctn",
                        "",
                        this.state.calendarDate,
                        this.state.ends,
                        this.state.techValue
                    )
                    .then((res) => {
                        this.setState({
                            ...this.state,
                            waitlist: res,
                            pageOffset: offset,
                        });
                    });
            }
        }
    }

    getRequestSource(reqSrc) {
        let src = "";
        src =
            reqSrc == "Phone Call"
                ? "fa fa-phone text-warning custom mb0"
                : reqSrc == "Walk In"
                    ? "mdi mdi-run custom mb0"
                    : reqSrc == "Android App"
                        ? "fa fa-android text-purple custom"
                        : reqSrc == "iOS App"
                            ? "fa fa-mobile text-purple custom"
                            : reqSrc == "Website"
                                ? "fa fa-desktop custom"
                                : reqSrc == "iPad App"
                                    ? "fas fa-tablet custom"
                                    : reqSrc == "Tablet App"
                                        ? "mdi mdi-tablet-android custom"
                                        : "";

        return src;
    }

    checkTheActive = (waitlist) => {
        waitlist.periods.map((p) => {
            const wStart = moment(p.from).format(format);

            const wend = moment(p.to).format(format);

            if (
                wStart == moment(this.state.start).format(format) &&
                wend == moment(this.state.end).format(format)
            ) {
                return "active";
            } else {
                //console.log('false')
            }
        });
    };
}

function mapStateToProps(state) {
    const {} = state.technicians;
    const {user} = state.authentication;
    const {reloadData} = state.Communication;

    return {
        user,
        reloadData,
    };
}

const connectedReservationCalendarPage = connect(mapStateToProps)(
    ReservationCalendarPage
);
export {connectedReservationCalendarPage as ReservationCalendarPage};
