import React from 'react';
import { connect } from 'react-redux'
import ProductRow from './product-row'

const InventoryTable = props => {
  return (
    <div className="table">

      <table className="table table-bordered table-hover">
        <thead>
          <tr>
            <th>name</th>
            <th>variants</th>
            <th>code</th>
            <th>qty</th>
            <th>msrp</th>
            <th>sale price</th>
            <th className="text-center">visibility</th>
            <th className="text-center">actions</th>
          </tr>
        </thead>
        <tbody>
          {props.products && props.productsLoaded === true && Object.keys(props.products).length > 0 && Object.keys(props.products).map((product, key) => {
            return <ProductRow detailsView={props.detailsView} productUpdateHandler={props.productUpdateHandler} key={key} name={product} product={props.products[product]} />
          })}
        </tbody>
      </table>
      {props.products && props.productsLoaded === false && <div className="custom-loading">
        <div className="loading">
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      </div>}
    </div>
  )
}


function mapStateToProps(state) {
  const { products, productsLoaded } = state.inventory;

  return {
    products,
    productsLoaded,
  };
}
export default connect(mapStateToProps)(InventoryTable);
