/* eslint-disable */
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import './header.css';
import {connect} from 'react-redux';
import {history} from '../../_helpers'
import {appService, userService} from '../../_services'
import {helperFunctions} from '../../_helpers'
import {reservationService} from '../../_services';
import Call from "../../_components/icons/call";
import moment from "moment";
import Mic from "../../_components/icons/mic";
import Message from "../../_components/icons/message";
import Grid from "../../_components/icons/grid";
import Mute from "../../_components/icons/mute";
import Book from "../../_components/icons/book";
import userImage from './sm-user.png'
import InlineMessage from "../../_components/inlineMessage";
import Draggable from 'react-draggable';
import {Device} from 'twilio-client'
import {communicationAction} from "../../_actions/communication.actions";
import {outgoingAction} from "../../_actions";
import Com from '../../_components/icons/com'
import MailShow from "../../_components/mailShow";
import {isSupported, onMessage} from 'firebase/messaging'
import {messaging} from "../../Services/firebase";
import MainSidebar from "./main-sidebar";
import ActivitiesComponent from "./activities";
import WaitLists from "./waitLists";
import HeaderCommunication from "./header-communication";

var techHeight = 110

const format = 'hh:mm a';

let connections = ''

class Header extends Component {
    constructor(props) {
        super(props);
        var currentUrl = `${location.pathname}${location.search}${location.hash}`
        this.app = JSON.parse(localStorage.getItem('app'));
        this.loggedUser = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : ''
        this.storeId = this.app !== null && this.app.vanityId
        this.state = {
            location: history.location.pathname,
            showHeader: currentUrl == "/" || currentUrl.includes("login") ? false : true,
            cls: '',
            pageOffset: 0,
            activities: '',
            waitList: '',
            waitlsts: true,
            actvts: false,
            userAccess: '',
            type: '',
            keyboard: false,
            numbers: '',
            calling_proccess: false,
            disconnect: false,
            mute: false,
            textMessage: '',
            User: '',
            details: '',
            errors: {},
            connection: '',
            callDirection: '',
            callEnded: false,
            callStarted: false,
            timer: {
                hours: 0,
                minutes: 0,
                seconds: 0
            },
            totalDuration: '',
            types: ["SMS"],
            directions: ["INBOUND", "OUTBOUND"],
            status: ["COMPLETED"],
            messages: '',
            com_offset: 0,
            activeMobileNumber: '',
            SMS: '',
            communication: [],
            loading: false,
            storage: localStorage.getItem('sms') ? JSON.parse(localStorage.getItem('sms')).filter(sms => sms.salon == this.app.id) : [],
            notificationOpen: false,
            notify: '',
            callStatus: '',
            users: {},
            caller: [],
            accepted: '',
            phones: [],
            com: [],
            typ: ["SMS", "EMAIL", "VOICE"],
            direction: ["INBOUND", "OUTBOUND"],
            stat: ["IN_PROGRESS", "COMPLETED", "FAILED", "NO_ANSWER"],
            com_page: 0,
            com_open: false,
            to: 0,
            from: '',
            filteredName: '',
            htmlElement: '',
            activeMobile: '',
            con_errors: {},
            categories: [],
        }

        this.app = JSON.parse(localStorage.getItem('app'));
        if (this.app != null && this.app != {}) {
            this.storeId = this.app.vanityId
        }
        //this.getCategories();
        this.myRef = React.createRef();
    }

    getCategories() {
        appService.getCategories(0, 200).then(categories => {
            this.setState({categories})
        }).catch(e => {
            console.log(e)
        })
    }


    setLoader = (loader) => this.setState({loading: loader});


    componentDidMount = async () => {
        window.destroyWarning();
        //const confirmation = window.isConfirmed();
        const res = await isSupported();
        if (res) {
            onMessage(messaging, (payload) => {
                if (payload.data.sender && payload.data.direction == 'INBOUND') {
                    this.receiveNotificationForMessage(payload.data)
                }
                if (payload.data.status) {
                    this.retriveFromMessage(payload.data.status)
                }
                if (payload.data.bookedTime) {
                    this.procceedToReload(payload.data)
                    if (this.props.fcmReload) {
                        this.props.fcmReload(payload.data)
                    }
                }
                if (payload.data.notificationType && payload.data.notificationType === 'AppSettingsUpdate') {
                    if (localStorage.getItem('appVersion') !== payload.data.appVersion) {
                        window.generateWarning("System Upgrade", `A new version v${payload.data.appVersion} is available. Update is recommended. To update please press "confirm" button or reload manually.`);
                        Array(...this.myRef.current.children[0].children[2].children).forEach(item => {
                            item.addEventListener('click', async function () {
                                if (this.dataset.content == 'confirm') {
                                    window.destroyWarning()
                                    const registrations = await navigator.serviceWorker.getRegistrations()
                                    // log
                                    for (const reg of registrations) {
                                        if (!reg.scope.includes("firebase-cloud-messaging-push-scope")) {
                                            await reg.unregister();
                                        }
                                    }
                                    const cacheKeys = await caches.keys()
                                    for (const ck of cacheKeys) {
                                        await caches.delete(ck)
                                    }
                                    setTimeout(() => {
                                        window.location.reload()
                                    }, 500)

                                } else {
                                    window.destroyWarning()
                                }
                            });
                        })
                    }
                }
            })
        }

        window.filterWindow();
        const {dispatch} = this.props
        history.listen((location, action) => {
            this.setState({location: location.pathname});
            var currentUrl = `${location.pathname}${location.search}${location.hash}`
            if (currentUrl == "/" || currentUrl.includes("login")) {
                this.setState({showHeader: false})
            } else {
                this.app = JSON.parse(localStorage.getItem('app'));
                if (this.app != null && this.app != {})
                    this.storeId = this.app.vanityId
                this.setState({showHeader: true})
            }
        });
        //once call is coming
        Device.on('incoming', connection => {
            //console.log(connection)
            this.fetchUser(connection)
        })
        //reject the call
        Device.on('reject', connection => {
            this.callDisconnected(connection)
        })
        //cancel the call
        Device.on('cancel', connection => {
            //console.log('cancelled by caller')
            this.callDisconnected(connection)
        })
        //disconnect the call
        Device.on('disconnect', (connection) => {
            this.callDisconnected(connection)
        })
        window.initMainSidebar();
    }
    activitiesShown = () => {
        userService.getAllActivities(0).then(res => {
            this.setState({
                ...this.state,
                activities: res
            })
        })
    }
    waitListShown = () => {
        reservationService.getAllWaitList(0, 'upcmng-lctn').then(res => {
            this.setState({
                waitList: res
            })
        })
    }

    enableActivities = () => {
        this.activitiesShown()
        this.setState({
            ...this.state,
            cls: 'shown',
            actvts: true,
            waitlsts: false
        })
    }
    waitEnable = () => {
        this.waitListShown()
        this.setState({
            waitlsts: true,
            cls: 'shown',
            actvts: false
        })
    }

    removeActivities = () => {
        this.setState({
            ...this.state,
            cls: ''
        })
    }

    pressedFooterButton = (types, number) => {
        //alert(number)
        const keyboard = `keyboard_${number}`
        const numbers = `numbers_${number}`
        const type = `type_${number}`
        const cls = `cls_${number}`

        if (types == 'keypad') {
            if (this.state.type == 'keypad') {
                this.setState({
                    type: '',
                    [type]: '',
                    keyboard: !this.state.keyboard,
                    [keyboard]: !this.state[`keyboard_${number}`],
                    [numbers]: number,
                    cls: '',
                    [cls]: '',

                })
            } else {
                this.setState({
                    type: types,
                    keyboard: !this.state.keyboard,
                    [keyboard]: !this.state[`keyboard_${number}`],
                    numbers: number,
                    [numbers]: number,
                    smsNumber: number,
                    cls: '',
                    [cls]: ''
                })

            }
        } else if (types == 'cancel') {
            clearInterval(this.state[`inter_${number}`])
            let in_calling_proccess = `in_calling_proccess_${number}`
            let callDirection = `callDirection_${number}`
            let numbers = `numbers_${number}`
            let callEnded = `callEnded_${number}`
            let activeMobileNumber = `activeMobileNumber_${number}`
            let callStarted = `callStarted_${number}`
            let type = `type_${number}`
            let calling_proccess = `calling_proccess_${number}`
            let connections = this.state[`connections_${number}`]
            let cls = this.state[`connections_${cls}`]
            if (this.state[`callStarted_${number}`] == true) {
                Device.disconnectAll()
                this.setState({
                    calling_proccess: false,
                    [calling_proccess]: false,
                    callStarted: false,
                    [callStarted]: false,
                    callEnded: true,
                    [callEnded]: true,
                    callDirection: '',
                    in_calling_proccess: false,
                    [in_calling_proccess]: false,
                    cls: '',
                    [cls]: ''
                })
                connections = ''
            } else {
                if (this.state.callDirection == 'outgoing') {
                    Device.disconnectAll()
                } else {
                    this.state[`connections_${number}`].reject()
                    this.setState({
                        callEnded: true,
                        [callEnded]: true,
                        callDirection: '',
                        [callDirection]: '',
                        in_calling_proccess: false,
                        [in_calling_proccess]: false,
                    })
                }


                this.setState({
                    calling_proccess: false,
                    [calling_proccess]: false,
                    callStarted: false,
                    [callStarted]: false,
                    callDirection: '',
                    [callDirection]: '',
                    in_calling_proccess: false,
                    [in_calling_proccess]: false
                })
            }
        } else if (types == 'message') {
            let activeMob = '';
            if (this.state[`numbers_${number}`] && this.state[`numbers_${number}`] != '') {
                activeMob = this.state[`numbers_${number}`]
            } else {
                activeMob = number
            }
            if (this.state[`cls_${number}`] == 'active') {
                this.setState({
                    cls: '',
                    [cls]: '',
                    [type]: '',
                    activeMobileNumber: activeMob
                })
            } else {
                this.setState({
                    [cls]: 'active',
                    cls: 'active',
                    [type]: types,
                    activeMobileNumber: activeMob
                }, () => {
                    this.loadCommunication(this.state.activeMobileNumber)
                })
            }
        } else {
            this.setState({
                type: types,
                keyboard: false,
                cls: '',
                [cls]: ''
            })
        }
    }

    loadCommunication = (phone) => {
        const loading = `loading_${phone}`
        this.setState({
            loading: true,
            [loading]: true
        })

        const {types, directions, status, activeMobileNumber} = this.state;
        let communication = `communication_${phone}`
        userService.loadCommunication(types, directions, status, activeMobileNumber, this.app.smsSenderNumber, this.state.com_offset, '').then(res => {
            const com = res.reverse().filter(sms => sms.notificationType == 'SMS');
            //console.log(communication)
            this.setState({
                [communication]: [...com],
                communication: com,
                [loading]: false,
                loading: false
            })
        }).catch(error => {
            this.setState({
                loading: false,
                [loading]: false,
            })
        })
    }
    keypadPressed = (op) => {
        let numbers = `numbers_${this.state.smsNumber}`
        if (op == 'C') {
            let num = this.state[`numbers_${this.state.smsNumber}`].split('');
            num.pop();
            let n = num.join('')
            this.setState({
                [numbers]: n
            })
        } else {
            this.setState({[numbers]: this.state[`numbers_${this.state.smsNumber}`] + op})
        }
    }
    fadeOutPop = () => {
        window.fadeOutPop()
    }
    muteUnMute = () => {
        this.setState({
            mute: !this.state.mute
        }, () => {
            Device.activeConnection().mute(this.state.mute)
        })
    }

    calling = () => {
        window.callCustomer(this.state.to, this.state.from)
    }
    showHideMessage = () => {
        window.showHideMessage()
    }
    textMessageHandler = (phone, e) => {
        const SMS = `SMS_${phone}`
        this.setState({
            [SMS]: e.target.value
        })
    }

    sendMessage = (phone) => {
        let TO = phone;
        let FROM = this.app.smsSenderNumber;
        let SMS = `SMS_${phone}`
        let bdy = this.state[`SMS_${phone}`]
        const errors = this.validateMessage(bdy);
        this.setState({errors})
        if (Object.keys(errors).length === 0) {
            userService.sendTextMessage(TO, FROM, bdy).then((response) => {
                const newSms = {
                    body: bdy,
                    createdTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
                    direction: 'OUTBOUND',
                    notificationType: 'SMS',
                    imageUrl: this.props.user.imageUrl
                }
                const existMessages = this.state[`communication_${TO}`];
                const communication = `communication_${TO}`;
                if (existMessages.length == 5) {
                    existMessages.shift();
                    existMessages.push(newSms);
                    this.setState({
                        communication: existMessages,
                        [communication]: [...existMessages],
                        [SMS]: '',
                        errors: {}
                    })
                } else {
                    existMessages.push(newSms);
                    this.setState({
                        communication: existMessages,
                        [communication]: [...existMessages],
                        [SMS]: '',
                        errors: {}
                    })
                }
            }).catch(error => {
                alert(error.message)
            })
        }
        window.scrolling()
    }
    validateMessage = (message) => {
        const errors = {}
        if (!message) errors.message = 'Please enter a message'
        return errors;
    }

    checkNotification = (data) => {
        //console.log('src', data)
    }
    fetchUser = (connection, sms = false, phoneNumber = '', id) => {
        var phone = ''
        if (sms == true && !id) {
            var nu = phoneNumber
            var mbnum = nu.split('');
            let ln = mbnum.length - 10
            for (var i = ln; i < mbnum.length; i++) {
                phone += mbnum[i]
            }
        } else {
            if (!id) {
                var nu = connection.parameters.From
                var mbnum = nu.split('');
                let ln = mbnum.length - 10
                for (var i = ln; i < mbnum.length; i++) {
                    phone += mbnum[i]
                }
            }
        }
        if (id) {
            userService.getCustomerInfoForCall(id).then(user => {
                let phonn = ''
                let nuu = user.mobileNumber
                let mbnumm = nuu.split('');
                let lnn = mbnumm.length - 10
                for (var i = lnn; i < mbnumm.length; i++) {
                    phonn += mbnumm[i]
                }
                let in_calling_proccess = `in_calling_proccess_${phonn}`
                let callDirection = `callDirection_${phonn}`
                let numbers = `numbers_${phonn}`
                let callEnded = `callEnded_${phonn}`
                let activeMobileNumber = `activeMobileNumber_${phonn}`
                let callStarted = `callStarted_${phonn}`
                let type = `type_${phonn}`
                let connections = `connections_${phonn}`
                let keyboard = `keyboard_${phonn}`
                let cls = `cls_${phonn}`
                const User = {
                    id: user.id,
                    name: user.fullName,
                    rewardBalance: user.rewardBalance,
                    mobileNumber: phonn,
                    emailAddress: user.emailAddress,
                    nextBirthdayRewardDate: user.nextBirthdayRewardDate,
                    nextVisit: user.nextVisit ? user.nextVisit : 'NONE',
                    lastVisit: user.lastVisit ? user.lastVisit : 'NONE',
                    imageUrl: user.imageUrl
                }
                const caller = this.state.caller;
                caller.push(User);
                if (sms == true) {
                    this.setState({
                        caller,
                        numbers: '',
                        [numbers]: '',
                        activeMobileNumber: phonn,
                        [activeMobileNumber]: phonn,
                        cls: 'active',
                        [cls]: 'active',
                        callStarted: false,
                        [callStarted]: false,
                        callEnded: false,
                        [callEnded]: false
                    }, () => {
                        this.loadCommunication(phonn)
                    })
                } else {
                    const {dispatch} = this.props
                    if (this.state.callDirection != 'outgoing') {
                        const duplicateCheck = this.state.caller.filter(dpl => dpl.mobileNumber == phonn);
                        if (duplicateCheck.length == 0) {
                            this.setState({
                                caller: caller,
                                [in_calling_proccess]: true,
                                callDirection: 'inComing',
                                [callDirection]: 'inComing',
                                numbers: '',
                                [numbers]: '',
                                callEnded: false,
                                [callEnded]: false,
                                activeMobileNumber: '',
                                [activeMobileNumber]: '',
                                callStarted: false,
                                [callStarted]: false,
                                [type]: '',
                                totalDuration: 0,
                                timer: {
                                    hours: 0,
                                    minutes: 0,
                                    seconds: 0
                                },
                                [connections]: connection,
                                [keyboard]: false
                            }, () => {
                                dispatch(outgoingAction.proccessCall({}))
                            })
                        } else {
                            const toKeep = this.state.caller.filter(dpl => dpl.mobileNumber != phonn);
                            toKeep.push(User)
                            this.setState({
                                caller: toKeep,
                                in_calling_proccess: true,
                                [in_calling_proccess]: true,
                                callDirection: 'inComing',
                                [callDirection]: 'inComing',
                                numbers: '',
                                [numbers]: '',
                                callEnded: false,
                                [callEnded]: false,
                                activeMobileNumber: '',
                                [activeMobileNumber]: '',
                                callStarted: false,
                                [callStarted]: false,
                                [type]: '',
                                totalDuration: 0,
                                timer: {
                                    hours: 0,
                                    minutes: 0,
                                    seconds: 0
                                },
                                [connections]: connection,
                                [keyboard]: false
                            }, () => {
                                dispatch(outgoingAction.proccessCall({}))
                            })
                        }
                    }
                }
            })
        } else {
            let in_calling_proccess = `in_calling_proccess_${phone}`
            let callDirection = `callDirection_${phone}`
            let numbers = `numbers_${phone}`
            let callEnded = `callEnded_${phone}`
            let activeMobileNumber = `activeMobileNumber_${phone}`
            let callStarted = `callStarted_${phone}`
            let type = `type_${phone}`
            let connections = `connections_${phone}`
            let keyboard = `keyboard_${phone}`
            let cls = `cls_${phone}`
            userService.searchCustomers('all', 0, phone).then(user => {
                let users = ''
                if (user.length > 0) {
                    const User = {
                        id: user[0].id,
                        name: user[0].fullName,
                        rewardBalance: user[0].rewardBalance,
                        mobileNumber: phone,
                        emailAddress: user[0].emailAddress,
                        nextBirthdayRewardDate: user[0].nextBirthdayRewardDate,
                        nextVisit: user[0].nextVisit ? user[0].nextVisit : 'NONE',
                        lastVisit: user[0].lastVisit ? user[0].lastVisit : 'NONE',
                        imageUrl: user[0].imageUrl
                    }
                    users = User
                } else {
                    const User = {
                        id: '',
                        name: "Guest",
                        mobileNumber: phone,
                        emailAddress: '',
                        nextBirthdayRewardDate: '',
                        nextVisit: 'NONE',
                        lastVisit: 'NONE',
                        imageUrl: userImage
                    }
                    users = User
                }
                if (sms == true) {
                    const caller = this.state.caller;
                    caller.push(users)
                    this.setState({
                        caller,
                        numbers: '',
                        [numbers]: '',
                        activeMobileNumber: phone,
                        [activeMobileNumber]: phone,
                        cls: 'active',
                        [cls]: 'active',
                        callStarted: false,
                        [callStarted]: false,
                        callEnded: false,
                        [callEnded]: false
                    }, () => {
                        this.loadCommunication(phone)
                    })
                } else {
                    const {dispatch} = this.props
                    if (this.state.callDirection != 'outgoing') {
                        const duplicateCheck = this.state.caller.filter(dpl => dpl.mobileNumber == phone);
                        if (duplicateCheck.length == 0) {
                            const caller = this.state.caller;
                            caller.push(users)
                            this.setState({
                                caller: caller,
                                [in_calling_proccess]: true,
                                callDirection: 'inComing',
                                [callDirection]: 'inComing',
                                numbers: '',
                                [numbers]: '',
                                callEnded: false,
                                [callEnded]: false,
                                activeMobileNumber: '',
                                [activeMobileNumber]: '',
                                callStarted: false,
                                [callStarted]: false,
                                [type]: '',
                                totalDuration: 0,
                                timer: {
                                    hours: 0,
                                    minutes: 0,
                                    seconds: 0
                                },
                                [connections]: connection,
                                [keyboard]: false
                            }, () => {
                                dispatch(outgoingAction.proccessCall({}))
                            })
                        } else {
                            const toKeep = this.state.caller.filter(dpl => dpl.mobileNumber != phone);
                            toKeep.push(users)
                            this.setState({
                                caller: toKeep,
                                in_calling_proccess: true,
                                [in_calling_proccess]: true,
                                callDirection: 'inComing',
                                [callDirection]: 'inComing',
                                numbers: '',
                                [numbers]: '',
                                callEnded: false,
                                [callEnded]: false,
                                activeMobileNumber: '',
                                [activeMobileNumber]: '',
                                callStarted: false,
                                [callStarted]: false,
                                [type]: '',
                                totalDuration: 0,
                                timer: {
                                    hours: 0,
                                    minutes: 0,
                                    seconds: 0
                                },
                                [connections]: connection,
                                [keyboard]: false
                            }, () => {
                                dispatch(outgoingAction.proccessCall({}))
                            })
                        }
                    }
                }
            })
        }
    }
    showDetails = (details) => {
        if (this.state.details == details) {
            this.setState({
                details: ''
            })
        } else {
            this.setState({
                details: details
            })
        }
    }
    close_caller = (phone, direct) => {
        const {dispatch} = this.props
        const cls = `cls_${phone}`;
        const totalDuration = `totalDuration_${phone}`;
        const timer = `timer_${phone}`
        const callEnded = this.state[`callEnded_${phone}`]
        const loading = `loading_${phone}`
        const activeMobileNumber = `activeMobileNumber_${phone}`
        if (direct == 'outgoing') {
            this.setState({
                [cls]: '',
                [totalDuration]: '',
                [loading]: false,
                [activeMobileNumber]: '',
                [timer]: {
                    seconds: 0,
                    hours: 0,
                    minutes: 0
                }
            })
            dispatch(outgoingAction.proccessCall({}))
        } else {
            const keep = this.state.caller.filter(k => k.mobileNumber != phone)
            this.setState({
                [cls]: '',
                [totalDuration]: '',
                [loading]: false,
                [activeMobileNumber]: '',
                [timer]: {
                    seconds: 0,
                    hours: 0,
                    minutes: 0
                },
                caller: keep
            })
        }
        this.setState({
            [activeMobileNumber]: ''
        })
    }
    disableButton = () => {
    }
    proccessToCall = (phone) => {
        let in_calling_proccess = phone && `in_calling_proccess_${phone}`
        let callDirection = phone && `callDirection_${phone}`
        let callEnded = phone && `callEnded_${phone}`
        let activeMobileNumber = phone && `activeMobileNumber_${phone}`
        let callStarted = phone && `callStarted_${phone}`
        let callStatus = phone && `callStatus_${phone}`
        let mobileNumber = this.state[`numbers_${phone}`] && this.state[`numbers_${phone}`] != '' ? this.state[`numbers_${phone}`] : phone
        const errors = this.validateMobileNumber(mobileNumber);
        this.setState({
            errors
        })
        if (Object.keys(errors).length == 0) {
            this.setState({
                calling_proccess: true,
                [in_calling_proccess]: true,
                [callStarted]: false,
                callStarted: false,
                callEnded: false,
                [callEnded]: false,
                totalDuration: '',
                callDirection: 'outgoing',
                timer: {
                    hours: 0,
                    minutes: 0,
                    seconds: 0
                },
                callStatus: '',
                [callStatus]: '',
                outgoingNumber: phone
            })
            setTimeout(() => {
                Device.connect({
                    To: mobileNumber,
                    From: this.app.smsSenderNumber,
                    Outbound: true,
                    CallerUserId: this.props.user.id
                })
            }, 1000)
        }
    }
    startTimer = (phn) => {
        let phone = phn;
        let inter = `inter_${phone}`;
        this.run(phone);
        //let intrvl = setInterval(()=>this.run(phone), 1000);
        let intrvl = setInterval(this.run, 1000)
        this.setState({
            [inter]: intrvl
        })
    }
    run = (phone) => {
        //console.log(phone)
        const timer = this.state[`timer_${phone}`]
        //console.log(timer)
        let updatedSecond = this.state.timer.seconds, updatedMinutes = this.state.timer.minutes,
            updatedHours = this.state.timer.hours
        if (updatedSecond === 60) {
            updatedMinutes++;
            updatedSecond = 0
        }
        if (updatedMinutes === 60) {
            updatedHours++;
            updatedMinutes = 0;
        }
        updatedSecond++
        let newTimer = {
            seconds: updatedSecond,
            minutes: updatedMinutes,
            hours: updatedHours
        }
        return this.setState({
            ...this.state,
            timer: newTimer
        }, () => {
            console.log(this.state.timer)
        })
    }

    validateMobileNumber(mobileNumber) {
        const errors = {}
        if (mobileNumber.length < 10) errors.mobLenght = 'Please enter a valida number!'
        return errors;
    }

    callDisconnected = (connection) => {
        let phone = ''
        let nu = Object.keys(connection.parameters).length > 0 && connection.parameters.From ? connection.parameters.From : connection.message.To
        let mbnum = nu.split('');
        let ln = mbnum.length - 10
        for (let i = ln; i < mbnum.length; i++) {
            phone += mbnum[i]
        }
        clearInterval(this.state[`inter_${phone}`])
        let in_calling_proccess = `in_calling_proccess_${phone}`
        let callDirection = `callDirection_${phone}`
        let numbers = `numbers_${phone}`
        let callEnded = `callEnded_${phone}`
        let activeMobileNumber = `activeMobileNumber_${phone}`
        let callStarted = `callStarted_${phone}`
        if (this.state.timer.minutes > 0 || this.state.timer.hours > 0 || this.state.timer.seconds > 0) {
            this.setState({
                callDirection: '',
                [callDirection]: '',
                calling_proccess: false,
                in_calling_proccess: false,
                [in_calling_proccess]: false,
                [callStarted]: false,
                callStarted: false,
                callEnded: true,
                [callEnded]: true
            }, () => {
                this.totalDuration(phone);
            })
        } else {
            this.setState({
                callDirection: '',
                [callDirection]: '',
                calling_proccess: false,
                in_calling_proccess: false,
                [in_calling_proccess]: false,
                callStarted: false,
                [callStarted]: false,
            }, () => {
                this.totalDuration()
            })
        }
    }

    cancelledCall = (connection) => {
        let phone = ''
        let nu = Object.keys(connection.parameters).length > 0 && connection.parameters.From ? connection.parameters.From : connection.message.To
        let mbnum = nu.split('');
        let ln = mbnum.length - 10
        for (let i = ln; i < mbnum.length; i++) {
            phone += mbnum[i]
        }
        clearInterval(this.state[`inter_${phone}`])
        this.setState({
            calling_proccess: false,
            callDirection: '',
            in_calling_proccess: false,
            callEnded: false,
            callStarted: false
        })
    }


    acceptTheCall = (phone) => {
        let in_calling_proccess = `in_calling_proccess_${phone}`
        let callDirection = `callDirection_${phone}`
        let numbers = `numbers_${phone}`
        let callEnded = `callEnded_${phone}`
        let activeMobileNumber = `activeMobileNumber_${phone}`
        let callStarted = `callStarted_${phone}`
        let timer = `timer_${phone}`
        let totalDuration = `totalDuration_${phone}`
        this.setState({
            callStarted: true,
            [callStarted]: true,
            [timer]: {
                hours: 0,
                minutes: 0,
                seconds: 0
            },
            totalDuration: '',
            [totalDuration]: '',
            in_calling_proccess: false,
            [in_calling_proccess]: false,
            accepted: phone
        }, () => {
            this.state[`connections_${phone}`].accept();
            this.startTimer(phone)
        })
    }
    totalDuration = (phone) => {
        const timer = this.state.timer;
        const totalDuration = `totalDuration_${phone}`;
        let calculatedDuration = ''
        if (timer.hours != 0) calculatedDuration += timer.hours + ' hr '
        if (timer.minutes != 0) calculatedDuration += timer.minutes + ' min '
        if (timer.seconds != 0) calculatedDuration += timer.seconds + ' s'
        this.setState({
            totalDuration: calculatedDuration,
            [totalDuration]: calculatedDuration
        })
    }

    changeNumber = (e, number) => {
        let numbers = `numbers_${number}`
        this.setState({
            [numbers]: e.target.value
        })
    }
    //for sms block
    receiveNotificationForMessage = (smsData) => {
        const {typ, direction, stat, to, from, com_page} = this.state;
        if (this.state.to != '') {
            this.setLoader(true)
            userService.loadCommunication(typ, direction, stat, to, from, com_page, '').then(response => {
                this.setState({
                    com: response,
                    loading: false
                })
            }).catch(err => {
                this.setLoader(false)
            })
        } else {
            this.setLoader(true)
            userService.loadCommunication(this.state.typ, this.state.direction, this.state.stat, this.state.to, this.state.from, this.state.com_page, this.app.id,).then(response => {
                this.setState({
                    com: response,
                    loading: false,
                    filteredName: '',
                    to: ''
                })
            }).catch(error => this.setLoader(false))
        }
        if (this.props.user.role == 'DIRECTOR' || this.props.user.role == 'MANAGER' || this.props.user.role == 'RECEPTIONIST') {
            let users = smsData.user && JSON.parse(smsData.user);
            const data = {
                sender: smsData.sender,
                body: smsData.body,
                createdTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
                name: smsData.user && users.fullName ? users.fullName : 'No Name',
                imageUrl: smsData.user && users.imageUrl ? users.imageUrl : userImage,
                id: smsData.user && users.id ? users.id : 0,
                notificationType: 'SMS',
                direction: 'INBOUND',
                qty: 1,
                salon: this.props.user.business.id
            }
            if (data.sender) {
                window.playTheMusic();
                const smsData = {...data}
                const storage = this.state.storage;
                if (data.sender != this.state[`activeMobileNumber_${smsData.sender}`]) {
                    if (storage.length > 0) {
                        let exist = storage.find(sms => sms.sender == smsData.sender)
                        if (exist) {
                            let newStorage = storage.map(sms => {
                                if (sms.sender == smsData.sender) {
                                    return {
                                        ...sms,
                                        qty: sms.qty + 1
                                    }
                                }
                                return sms
                            });
                            this.setState({
                                storage: newStorage
                            }, () => {
                                this.setState({
                                    notificationOpen: false
                                }, () => {
                                    this.setState({
                                        notificationOpen: true
                                    })
                                })
                            })
                            localStorage.removeItem('sms');
                            localStorage.setItem('sms', JSON.stringify(newStorage))
                        } else {
                            storage.push({
                                body: smsData.body,
                                sender: smsData.sender,
                                qty: 1,
                                time: helperFunctions.formatDateTimeWithDay(moment(), 'time')
                            })
                            this.setState({
                                storage: storage
                            }, () => {
                                this.setState({
                                    notificationOpen: true
                                })
                            })
                            localStorage.removeItem('sms');
                            localStorage.setItem('sms', JSON.stringify(storage))
                        }
                    } else {
                        storage.push({
                            ...smsData
                        })
                        this.setState({
                            storage: storage
                        }, () => {
                            this.setState({
                                notificationOpen: true
                            })
                        })
                        localStorage.removeItem('sms');
                        localStorage.setItem('sms', JSON.stringify(storage))
                    }
                } else {
                    const SMS = `SMS_${smsData.sender}`
                    const existMessages = this.state[`communication_${smsData.sender}`];
                    const communication = `communication_${smsData.sender}`;
                    if (existMessages.length == 5) {
                        existMessages.shift();
                        existMessages.push(smsData);
                        this.setState({
                            communication: existMessages,
                            [communication]: [...existMessages],
                            [SMS]: ''
                        })
                    } else {
                        existMessages.push(smsData);
                        this.setState({
                            communication: existMessages,
                            [communication]: [...existMessages],
                            [SMS]: ''
                        })
                    }
                }
                window.scrolling()
            }
        }
    }
    openChat = (phone, id) => {
        let sms = true
        if (this.state.activeMobileNumber != phone) {
            this.fetchUser('', sms, phone, id)
        }
        const storage = this.state.storage;
        const newStorage = storage.filter(sms => sms.sender != phone);
        this.setState({
            storage: newStorage
        }, () => {
            localStorage.removeItem('sms');
            localStorage.setItem('sms', JSON.stringify(newStorage))
        })
    }
    openCallers = (phone, id) => {
    }
    retriveFromMessage = (status) => {
        const callStatus = `status_${this.state.outgoing}`
        if (status == 'RINGING') {
            this.setState({
                callStatus: status
            })
        }
        if (status == 'IN_PROGRESS') {
            this.setState({
                timer: {
                    hours: 0,
                    seconds: 0,
                    minutes: 0
                },
                callStatus: status,
                [callStatus]: status,
                totalDuration: 0,
                callStarted: true
            }, () => {
                if (this.state.outgoingNumber) {
                    this.startTimer(this.state.outgoingNumber)
                }
            })
        }
        if (status == 'FAILED') {
            this.setState({
                [callStatus]: status,
                callStatus: status
            })
        }
        if (status == 'NO_ANSWER') {
            this.setState({
                [callStatus]: status,
                callStatus: status
            })
        }
    }
    procceedToReload = (data) => {
        const {dispatch} = this.props;
        dispatch(communicationAction.procceedToReload(data))
    }

    componentWillReceiveProps(nextProps) {
    }


    openCaller = (id, phone, sms) => {
        const {dispatch} = this.props;
        if (this.props.user.role == 'DIRECTOR' || this.props.user.role == 'MANAGER' || this.props.user.role == 'RECEPTIONIST') {
            if (sms == 'sms') {
                this.setState({
                    cls: 'active'
                })
            } else {
                this.setState({
                    cls: ''
                })
            }
            if (id) {
                userService.getCustomerInfoForCall(id).then(customer => {
                    const communication = `communication_${customer.mobileNumber}`
                    const loading = `loading_${customer.mobileNumber}`
                    if (sms == 'sms') {
                        userService.loadCommunication(this.state.types, this.state.directions, this.state.status, customer.mobileNumber, this.app.smsSenderNumber, this.state.com_offset, '').then(res => {
                            const com = res.reverse().filter(sms => sms.notificationType == 'SMS');
                            this.setState({
                                [communication]: [...com],
                                communication: com,
                                [loading]: false,
                                loading: false
                            })
                        }).catch(error => {
                            this.setState({
                                loading: false,
                                [loading]: false,
                            })
                        })
                    }
                    if (Object.keys(customer).length > 0) {
                        dispatch(outgoingAction.proccessCall(customer))
                    }
                })
            } else {
                const user = {
                    fullName: 'No Name',
                    imageUrl: userImage,
                    nextBirthdayRewardDateTime: '',
                    rewardBalance: 0,
                    emailAddress: '',
                    mobileNumber: phone
                }
                const communication = `communication_${phone}`
                const loading = `loading_${phone}`
                if (sms == 'sms') {
                    userService.loadCommunication(this.state.types, this.state.directions, this.state.status, phone, this.app.smsSenderNumber, this.state.com_offset, '').then(res => {
                        const com = res.reverse().filter(sms => sms.notificationType == 'SMS');
                        this.setState({
                            [communication]: [...com],
                            communication: com,
                            [loading]: false,
                            loading: false
                        })
                    }).catch(error => {
                        this.setState({
                            loading: false,
                            [loading]: false,
                        })
                    })
                }
                const {dispatch} = this.props;
                dispatch(outgoingAction.proccessCall(user))
            }
        }
    }

    close_callerss = () => {
        const {dispatch} = this.props;
        dispatch(outgoingAction.proccessCall({}))
    }

    filterByMobile = (filter, name) => {
        this.setState({
            from: this.app.smsSenderNumber,
            to: filter,
            com_page: 0,
            loading: true,
            filteredName: name
        }, () => {
            userService.loadCommunication(this.state.typ, this.state.direction, this.state.stat, this.state.to, this.state.from, this.state.com_page, '').then(response => {
                this.setState({
                    com: response,
                    loading: false
                })
            }).catch(err => {
                this.setState({
                    loading: false
                })
            })
        })
    }
    openEmail = (body) => {
        this.setState({
            htmlElement: body
        })
    }


    render() {
        const {user, selectedUser, info} = this.props;
        const {location, showHeader, activities, waitlsts, actvts, waitList} = this.state;

        let rows = [];
        let nums = [[1, 2, 3], [4, 5, 6], [7, 8, 9], ['+', 0, 'C']]
        for (let i = 0; i < 4; i++) {
            let row = []
            for (let j = 0; j < 3; j++) {
                row.push(<div key={nums[i][j]} className="col-md-4 col-sm-4 col-xs-4">
                    <button onClick={() => this.keypadPressed(nums[i][j])}>{nums[i][j]}</button>
                </div>)
            }
            rows.push(<div key={i} className="row">{row}</div>)
        }
        const MAN_DIREC = user != undefined && user.role == "MANAGER" || user != undefined && user.role == "DIRECTOR" || user != undefined && user.role == 'ADMIN' || user != undefined && user.role == 'CASHIER' ? true : false;

        return (
            <div id="wrapper">
                <div ref={this.myRef} id={"generate-warning"}></div>
                {selectedUser && Object.keys(selectedUser).length > 0 &&
                    <Draggable disabled={window.innerWidth <= 800}>
                        <div className="caller-popup" style={{
                            position: 'fixed',
                            width: '400px',
                            height: '600px',
                            background: '#1a76a4,',
                            zIndex: '9999999999 !important',
                            left: '50%',
                            marginLeft: '-200px',
                            top: '50%',
                            marginTop: '-300px',
                            overflow: 'hidden',
                            boxShadow: '0 0 2px #999'
                        }}>
                            {this.state.callEnded == true &&
                                <div className="call-duration">
                                    <p style={{color: 'green'}}>Last Call : {this.state.totalDuration}</p>
                                </div>
                            }
                            {this.state.callStatus == 'RINGING' &&
                                <div className="call-duration">
                                    <p style={{color: 'green'}}>Ringing ....</p>
                                </div>
                            }
                            {this.state.callStatus == 'NO_ANSWER' &&
                                <div className="call-duration">
                                    <p style={{color: 'red'}}>No Answer ....</p>
                                </div>
                            }
                            {this.state.callStatus == 'FAILED' &&
                                <div className="call-duration">
                                    <p style={{color: 'red'}}>Call failed...</p>
                                </div>
                            }
                            {this.state.callStarted == true &&
                                <div className="call-duration">
                                    <p><span style={{color: 'red'}}>{this.state.timer.hours} : </span><span
                                        style={{color: 'green'}}>{this.state.timer.minutes} : </span><span
                                        style={{color: 'goldenrod'}}>{this.state.timer.seconds} </span></p>
                                </div>
                            }
                            <div className="close-btn"
                                 onClick={() => this.close_caller(selectedUser.mobileNumber, 'outgoing')}><i
                                className="fa fa-close"></i></div>
                            <div className="popup-inner">
                                <div className="intro">
                                    <div className="profile-pick" style={{
                                        width: '50px',
                                        height: '50px',
                                        backgroundImage: `url(${selectedUser.imageUrl})`
                                    }}></div>
                                    <span style={{fontWeight: 600, color: '#0e587d'}}>{selectedUser.fullName}</span>
                                    <span style={{
                                        color: "goldenrod",
                                        fontWeight: 600
                                    }}>${(selectedUser.rewardBalance * 1).toFixed(2)}</span>
                                </div>

                                <div className="numbers">
                                    <input name="numbers" type="text"
                                           onChange={(e) => this.changeNumber(e, selectedUser.mobileNumber)}
                                           value={this.state.keyboard != false ? this.state[`numbers_${selectedUser.mobileNumber}`] : selectedUser.mobileNumber}/>
                                </div>
                                <div
                                    onClick={this.state.calling_proccess == true && this.state.callStatus != 'IN_PROGRESS' ? this.disableButton : () => this.proccessToCall(selectedUser.mobileNumber)}
                                    className={this.state.calling_proccess == true && this.state.callStatus != 'IN_PROGRESS' ? "calling active" : "calling"}>
                                    <Call proccess={this.state.calling_proccess}/>
                                </div>

                            </div>
                            <div className="error-handling">
                                {this.state.errors.mobLenght && <InlineMessage text={this.state.errors.mobLenght}/>}
                            </div>
                            <div className="content"
                                 style={this.state.keyboard == true ? {transform: 'translateY(-900px)'} : {transform: 'translateY(0px)'}}>
                                <div className="inn">
                                    <div className="content-single">
                                        <div className="icon-part">
                                            <span>Mob : </span>
                                        </div>
                                        <div className="content-part">
                                            <span>{selectedUser.mobileNumber && selectedUser.mobileNumber}</span>
                                        </div>
                                    </div>
                                    <div className="content-single">
                                        <div className="icon-part">
                                            <span>Email :</span>
                                        </div>
                                        <div className="content-part">
                                            <span>{selectedUser.emailAddress != '' && selectedUser.emailAddress}</span>
                                        </div>
                                    </div>
                                    <div className="content-single">
                                        <div className="icon-part">
                                            <span>Birthday : </span>
                                        </div>
                                        <div className="content-part">
                                            <span>{selectedUser.nextBirthdayRewardDateTime != '' && helperFunctions.getTimelineDate(selectedUser.nextBirthdayRewardDateTime)}</span>
                                        </div>

                                    </div>
                                    <div className="content-single">
                                        <div className="icon-part">
                                            <span>Last visit : </span>
                                            {selectedUser.lastVisit && selectedUser.lastVisit != 'NONE' &&
                                                <span onClick={() => this.showDetails('last')} className="toggler"><i
                                                    className={this.state.details == 'last' ? 'fa fa-angle-up' : 'fa fa-angle-down'}></i></span>
                                            }
                                        </div>
                                        <div className="content-part">
                                            <span>{selectedUser.lastVisit && selectedUser.lastVisit != 'NONE' ? selectedUser.lastVisit.readableBookedTime : 'NONE'}</span>
                                        </div>

                                        <div className="hiden-part"
                                             style={this.state.details == 'last' ? {height: `${techHeight * selectedUser.lastVisit.visitTechnicians.length}px`} : {height: 0}}>
                                            <h5>Appt
                                                Date: {selectedUser && selectedUser.lastVisit && selectedUser.lastVisit != 'NONE' && selectedUser.lastVisit.bookedTime && helperFunctions.formatDateTimeWithDay(moment(selectedUser.lastVisit.bookedTime), 'date')} {selectedUser.lastVisit && helperFunctions.formatDateTimeWithDay(selectedUser.lastVisit.bookedTime, 'time')}</h5>
                                            {selectedUser.lastVisit && selectedUser.lastVisit != 'NONE' && selectedUser.lastVisit.visitTechnicians.map((vt, key) => {
                                                return <div key={key} className="tech" style={{marginBottom: "10px"}}>
                                                    <div className="tech-image" style={{marginRight: "20px"}}>
                                                        <div className="profile_" style={{
                                                            width: '30px',
                                                            height: '30px',
                                                            backgroundImage: `url(${this.findTechnician(vt.technician.id, 'image')})`
                                                        }}></div>
                                                    </div>
                                                    <div className="appt-content">
                                                        <p>{this.findServiceName(vt.offeredService.id, vt.offeredService.category.id)}<span>With</span> {this.findTechnician(vt.technician.id, 'nick')}
                                                        </p>
                                                        <p>
                                                            <span>Time : </span> {moment(vt.expectedStartTime).format(format)} - {moment(vt.expectedEndTime).format(format)}
                                                        </p>
                                                        <p><span>Price : </span> ${(vt.chargeAmount * 1).toFixed(2)}</p>

                                                    </div>
                                                </div>
                                            })}
                                        </div>

                                    </div>
                                    <div className="content-single">
                                        <div className="icon-part">
                                            <span>Next visit : </span>
                                            {selectedUser.nextVisit && selectedUser.nextVisit != 'NONE' &&
                                                <span onClick={() => this.showDetails('next')} className="toggler"><i
                                                    className={this.state.details == 'next' ? 'fa fa-angle-up' : 'fa fa-angle-down'}></i></span>
                                            }
                                        </div>
                                        <div className="content-part">
                                            <span>{selectedUser.nextVisit && selectedUser.nextVisit != 'NONE' ? selectedUser.nextVisit.readableBookedTime : 'NONE'}</span>
                                        </div>
                                        <div className="hiden-part"
                                             style={this.state.details == 'next' ? {height: `${techHeight * selectedUser.nextVisit.visitTechnicians.length}px`} : {height: 0}}>
                                            <h5>Appt
                                                Date: {selectedUser.nextVisit && helperFunctions.formatDateTimeWithDay(moment(selectedUser.nextVisit.bookedTime), 'date')} {selectedUser.nextVisit && helperFunctions.formatDateTimeWithDay(selectedUser.nextVisit.bookedTime, 'time')}</h5>
                                            {selectedUser.nextVisit && selectedUser.nextVisit != 'NONE' && selectedUser.nextVisit.visitTechnicians.map((vt, key) => {
                                                return <div key={key} className="tech" style={{marginBottom: "10px"}}>
                                                    <div className="tech-image" style={{marginRight: "20px"}}>
                                                        <div className="profile_" style={{
                                                            width: '30px',
                                                            height: '30px',
                                                            backgroundImage: `url(${this.findTechnician(vt.technician.id, 'image')})`
                                                        }}></div>
                                                    </div>
                                                    <div className="appt-content">
                                                        <p>{this.findServiceName(vt.offeredService.id, vt.offeredService.category.id)}<span>With</span> {this.findTechnician(vt.technician.id, 'nick')}
                                                        </p>
                                                        <p>
                                                            <span>Time : </span> {moment(vt.expectedStartTime).format(format)} - {moment(vt.expectedEndTime).format(format)}
                                                        </p>
                                                        <p><span>Price : </span> ${(vt.chargeAmount * 1).toFixed(2)}</p>

                                                    </div>
                                                </div>
                                            })}

                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="key-pad"
                                 style={this.state.keyboard == true ? {transform: 'translateY(0px)'} : {transform: 'translateY(300px)'}}>
                                <div className="keypad-inner">
                                    {rows}
                                </div>
                            </div>
                            <div id="chat" className={this.state.cls == 'active' ? 'chat-app active' : 'chat-app'}>
                                <div className="intro custom-info-bg">

                                    <div className="profile-pick" style={{width: '40px', height: '40px'}}>
                                        <img src={selectedUser.imageUrl}/>
                                    </div>
                                    <span id="ms-name" style={{fontWeight: 600, color: '#fff'}}
                                    >{selectedUser && selectedUser.fullName} ({selectedUser.mobileNumber})</span>
                                    <span onClick={() => this.close_caller(selectedUser.mobileNumber, 'outgoing')}
                                          id="ms-balance"
                                          style={{color: "goldenrod", fontWeight: 600}}>
                    <div className="close-btn"><i className="fa fa-close"></i></div>
                  </span>
                                </div>
                                <div className="sms-container sms">
                                    <div className="scroller">

                                        {this.state[`loading_${selectedUser.mobileNumber}`] &&
                                            <div className="custom-loading">
                                                <div className="loading"><i className="fa fa-spinner fa-spin"></i></div>
                                            </div>
                                        }

                                        {this.state[`communication_${selectedUser.mobileNumber}`] && this.state[`communication_${selectedUser.mobileNumber}`].length > 0 && this.state[`communication_${selectedUser.mobileNumber}`].map((sms, key) => {
                                            return sms.direction == 'INBOUND' ? (<div key={key} className="client">
                                                <div className="client-image">
                                                    <img
                                                        src={sms.user ? sms.user.imageUrl : sms.imageUrl ? sms.imageUrl : userImage}
                                                        alt="user"/>
                                                </div>
                                                <div className="client-content">
                                                    <p style={{
                                                        color: '#444',
                                                        fontWeight: 600,
                                                        fontSize: '11px',
                                                        marginBottom: 0
                                                    }}>{helperFunctions.formatDateTimeWithDay(moment(sms.createdTime), 'latest')}</p>
                                                    <div className="client-content-inner">

                                                        <p style={{color: 'green'}}>{sms.body}</p>
                                                    </div>
                                                </div>
                                            </div>) : (<div key={key} className="client support">
                                                <div className="client-content text-right">
                                                    <p style={{
                                                        color: '#444',
                                                        fontWeight: 600,
                                                        fontSize: '11px',
                                                        marginBottom: 0
                                                    }}>{helperFunctions.formatDateTimeWithDay(moment(sms.createdTime), 'time')}</p>
                                                    <div className="client-content-inner">
                                                        <p style={{color: '#1a76a4'}}>{sms.body}</p>
                                                    </div>
                                                </div>
                                                <div className="client-image">
                                                    <img
                                                        src={sms.createUser ? sms.createUser.imageUrl : sms.imageUrl ? sms.imageUrl : userImage}
                                                        alt="user"/>
                                                </div>

                                            </div>)
                                        })}


                                    </div>
                                </div>
                                <div id="sms-sender" className={this.state.errors.message && "errors"}>
                  <textarea
                      value={this.state[`SMS_${selectedUser.mobileNumber}`] && this.state[`SMS_${selectedUser.mobileNumber}`]}
                      onChange={(e) => this.textMessageHandler(selectedUser.mobileNumber, e)}
                      placeholder="Type here.."></textarea>
                                    <div onClick={() => this.sendMessage(selectedUser.mobileNumber)}
                                         className="sender-icon"><i
                                        className="fa fa-paper-plane"></i></div>
                                </div>
                            </div>
                            <div className="caller-footer">
                                <div className="action">
                                    <div onClick={() => this.pressedFooterButton('book', '')} className="message book">
                                        <Link
                                            to={selectedUser.id != '' ? `/${this.app.vanityId}` + "/calendar-reservation?userId=" + selectedUser.id : `/${this.app.locations[0].id}` + "/calendar-reservation"}
                                            className="icons">
                                            <Book type={this.state.type}/>
                                        </Link>
                                    </div>

                                    {this.state.calling_proccess == true &&
                                        <div onClick={this.muteUnMute} className="mute book">
                                            <div className="icons">
                                                {this.state.mute == true ? <Mute/> : <Mic/>}

                                            </div>
                                        </div>
                                    }
                                    {this.state.calling_proccess == true &&
                                        <div
                                            onClick={() => this.pressedFooterButton('cancel', selectedUser.mobileNumber)}
                                            className="book">
                                            <div className="icons">
                                                <Call type={this.state.type} cancel={true}/>
                                            </div>
                                        </div>}

                                    <div onClick={() => this.pressedFooterButton('message', selectedUser.mobileNumber)}
                                         className="message book">
                                        <div className="icons">
                                            <Message type={this.state.type}/>
                                        </div>
                                    </div>
                                    <div onClick={() => this.pressedFooterButton('keypad', selectedUser.mobileNumber)}
                                         className="grid book">
                                        <div className="icons">
                                            <Grid type={this.state.type}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                }

                {this.state.caller.length > 0 && this.state.caller.map((users, key) => {
                    return <Draggable key={key} disabled={window.innerWidth <= 800}>
                        <div className="caller-popup" style={{
                            position: 'fixed',
                            width: '400px',
                            height: '600px',
                            background: '#1a76a4,',
                            zIndex: '9999999999 !important',
                            left: '50%',
                            marginLeft: '-200px',
                            top: '50%',
                            marginTop: '-300px',
                            overflow: 'hidden',
                            boxShadow: '0 0 2px #999'
                        }}>


                            {this.state[`callStatus_${users.mobileNumber}`] == 'RINGING' &&
                                <div className="call-duration">
                                    <p style={{color: 'green'}}>Ringing ....</p>
                                </div>
                            }
                            {this.state[`callStatus_${users.mobileNumber}`] == 'NO_ANSWER' &&
                                <div className="call-duration">
                                    <p style={{color: 'red'}}>No Answer ....</p>
                                </div>
                            }
                            {this.state[`callStatus_${users.mobileNumber}`] == 'FAILED' &&
                                <div className="call-duration">
                                    <p style={{color: 'red'}}>Call failed...</p>
                                </div>
                            }


                            {this.state[`callEnded_${users.mobileNumber}`] == true && this.state[`totalDuration_${users.mobileNumber}`] != "" && this.state.accepted == users.mobileNumber &&
                                <div className="call-duration">
                                    <p style={{fontWeight: 600, color: 'green'}}>Last call
                                        : {this.state[`totalDuration_${users.mobileNumber}`]}</p>
                                </div>
                            }
                            {this.state[`callStarted_${users.mobileNumber}`] == true &&
                                <div className="call-duration">
                                    <p><span style={{color: 'red'}}>{this.state.timer.hours} : </span><span
                                        style={{color: 'green'}}>{this.state.timer.minutes} : </span><span
                                        style={{color: 'goldenrod'}}>{this.state.timer.seconds} </span></p>
                                </div>
                            }
                            <div className="close-btn" onClick={() => this.close_caller(users.mobileNumber)}><i
                                className="fa fa-close"></i></div>
                            <div className="popup-inner">
                                <div className="intro">
                                    <div className="profile-pick" style={{
                                        width: '50px',
                                        height: '50px',
                                        backgroundImage: `url(${users.imageUrl})`
                                    }}></div>
                                    <span style={{fontWeight: 600, color: '#0e587d'}}>{users.fullName}</span>
                                    <span style={{color: "goldenrod", fontWeight: 600}}>$50.00</span>
                                </div>

                                <div className="numbers">
                                    <input name="numbers" type="text"
                                           onChange={(e) => this.changeNumber(e, users.mobileNumber)}
                                           value={this.state[`keyboard_${users.mobileNumber}`] != false ? this.state[`numbers_${users.mobileNumber}`] : users.mobileNumber}/>
                                </div>
                                {this.state[`callDirection_${users.mobileNumber}`] == 'inComing' ? (<div
                                    onClick={this.state[`callStarted_${users.mobileNumber}`] != true ? () => this.acceptTheCall(users.mobileNumber) : this.disableButton}
                                    className={this.state[`in_calling_proccess_${users.mobileNumber}`] == true ? "calling active" : "calling"}>
                                    <Call proccess={this.state[`in_calling_proccess_${users.mobileNumber}`]}/>
                                </div>) : (<div
                                    onClick={this.state[`in_calling_proccess_${users.mobileNumber}`] == true ? this.disableButton : () => this.proccessToCall(users.mobileNumber)}
                                    className={this.state[`in_calling_proccess_${users.mobileNumber}`] == true ? "calling active" : "calling"}>
                                    <Call proccess={this.state[`in_calling_proccess_${users.mobileNumber}`]}/>
                                </div>)}


                            </div>
                            <div className="error-handling">
                                {this.state.errors.mobLenght && <InlineMessage text={this.state.errors.mobLenght}/>}
                            </div>
                            <div className="content"
                                 style={this.state[`keyboard_${users.mobileNumber}`] == true ? {transform: 'translateY(-900px)'} : {transform: 'translateY(0px)'}}>
                                <div className="inn">
                                    <div className="content-single">
                                        <div className="icon-part">
                                            <span>Mob : </span>
                                        </div>
                                        <div className="content-part">
                                            <span>{users.mobileNumber && users.mobileNumber}</span>
                                        </div>
                                    </div>
                                    <div className="content-single">
                                        <div className="icon-part">
                                            <span>Email :</span>
                                        </div>
                                        <div className="content-part">
                                            <span>{users.emailAddress && users.emailAddress}</span>
                                        </div>
                                    </div>
                                    <div className="content-single">
                                        <div className="icon-part">
                                            <span>Birthday : </span>
                                        </div>
                                        <div className="content-part">
                                            <span>{helperFunctions.getTimelineDate(users.nextBirthdayRewardDateTime)}</span>
                                        </div>

                                    </div>
                                    <div className="content-single">
                                        <div className="icon-part">
                                            <span>Last visit : </span>
                                            {users.lastVisit != 'NONE' &&
                                                <span onClick={() => this.showDetails('last')} className="toggler"><i
                                                    className={this.state.details == 'last' ? 'fa fa-angle-up' : 'fa fa-angle-down'}></i></span>
                                            }
                                        </div>
                                        <div className="content-part">
                                            <span>{users.lastVisit && users.lastVisit != 'NONE' ? users.lastVisit.readableBookedTime : 'NONE'}</span>
                                        </div>

                                        <div className="hiden-part"
                                             style={this.state.details == 'last' ? {height: `${techHeight * users.lastVisit.visitTechnicians.length}px`} : {height: 0}}>
                                            <h5>Appt
                                                Date: {users && users.lastVisit && users.lastVisit != 'NONE' && users.lastVisit.bookedTime && helperFunctions.formatDateTimeWithDay(moment(users.lastVisit.bookedTime), 'date')} {users.lastVisit && helperFunctions.formatDateTimeWithDay(users.lastVisit.bookedTime, 'time')}</h5>
                                            {users.lastVisit && users.lastVisit != 'NONE' && users.lastVisit.visitTechnicians.map((vt, key) => {
                                                return <div key={key} className="tech" style={{marginBottom: "10px"}}>
                                                    <div className="tech-image" style={{marginRight: "20px"}}>
                                                        <div className="profile_" style={{
                                                            width: '30px',
                                                            height: '30px',
                                                            backgroundImage: `url(${this.findTechnician(vt.technician.id, 'image')})`
                                                        }}></div>
                                                    </div>
                                                    <div className="appt-content">
                                                        <p>{this.findServiceName(vt.offeredService.id, vt.offeredService.category.id)}<span>With</span> {this.findTechnician(vt.technician.id, 'nick')}
                                                        </p>
                                                        <p>
                                                            <span>Time : </span> {moment(vt.expectedStartTime).format(format)} - {moment(vt.expectedEndTime).format(format)}
                                                        </p>
                                                        <p><span>Price : </span> ${(vt.chargeAmount * 1).toFixed(2)}</p>

                                                    </div>
                                                </div>
                                            })}
                                        </div>

                                    </div>
                                    <div className="content-single">
                                        <div className="icon-part">
                                            <span>Next visit : </span>
                                            {users.nextVisit && users.nextVisit != 'NONE' &&
                                                <span onClick={() => this.showDetails('next')} className="toggler"><i
                                                    className={this.state.details == 'next' ? 'fa fa-angle-up' : 'fa fa-angle-down'}></i></span>
                                            }
                                        </div>
                                        <div className="content-part">
                                            <span>{users.nextVisit && users.nextVisit != 'NONE' ? users.nextVisit.readableBookedTime : 'NONE'}</span>
                                        </div>
                                        <div className="hiden-part"
                                             style={this.state.details == 'next' ? {height: `${techHeight * users.nextVisit.visitTechnicians.length}px`} : {height: 0}}>
                                            <h5>Appt
                                                Date: {users.nextVisit && helperFunctions.formatDateTimeWithDay(moment(users.nextVisit.bookedTime), 'date')} {users.nextVisit && helperFunctions.formatDateTimeWithDay(users.nextVisit.bookedTime, 'time')}</h5>
                                            {users.nextVisit && users.nextVisit != 'NONE' && users.nextVisit.visitTechnicians.map((vt, key) => {
                                                return <div key={key} className="tech" style={{marginBottom: "10px"}}>
                                                    <div className="tech-image" style={{marginRight: "20px"}}>
                                                        <div className="profile_" style={{
                                                            width: '30px',
                                                            height: '30px',
                                                            backgroundImage: `url(${this.findTechnician(vt.technician.id, 'image')})`
                                                        }}></div>
                                                    </div>
                                                    <div className="appt-content">
                                                        <p>{this.findServiceName(vt.offeredService.id, vt.offeredService.category.id)}<span>With</span> {this.findTechnician(vt.technician.id, 'nick')}
                                                        </p>
                                                        <p>
                                                            <span>Time : </span> {moment(vt.expectedStartTime).format(format)} - {moment(vt.expectedEndTime).format(format)}
                                                        </p>
                                                        <p><span>Price : </span> ${(vt.chargeAmount * 1).toFixed(2)}</p>

                                                    </div>
                                                </div>
                                            })}

                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="key-pad"
                                 style={this.state[`keyboard_${users.mobileNumber}`] == true ? {transform: 'translateY(0px)'} : {transform: 'translateY(300px)'}}>
                                <div className="keypad-inner">
                                    {rows}
                                </div>
                            </div>
                            <div id="chat"
                                 className={this.state[`cls_${users.mobileNumber}`] == 'active' ? 'chat-app active' : 'chat-app'}>
                                <div className="intro custom-info-bg">

                                    <div className="profile-pick" style={{width: '40px', height: '40px'}}>
                                        <img src={users.imageUrl}/>
                                    </div>
                                    <span id="ms-name" style={{fontWeight: 600, color: '#fff'}}
                                    >{users && users.name} ({users.mobileNumber})</span>
                                    <span onClick={() => this.close_caller(users.mobileNumber)} id="ms-balance"
                                          style={{color: "goldenrod", fontWeight: 600}}>
                    <div className="close-btn"><i className="fa fa-close"></i></div>
                  </span>

                                </div>
                                <div className="sms-container sms">
                                    <div className="scroller">

                                        {this.state[`loading_${users.mobileNumber}`] == true &&
                                            <div className="custom-loading">
                                                <div className="loading"><i className="fa fa-spinner fa-spin"></i></div>
                                            </div>
                                        }

                                        {this.state[`communication_${users.mobileNumber}`] && this.state[`communication_${users.mobileNumber}`].length > 0 && this.state[`communication_${users.mobileNumber}`].map((sms, key) => {
                                            return sms.direction == 'INBOUND' ? (<div key={key} className="client">
                                                <div className="client-image">
                                                    <img
                                                        src={sms.user ? sms.user.imageUrl : sms.imageUrl ? sms.imageUrl : userImage}
                                                        alt="user"/>
                                                </div>
                                                <div className="client-content">
                                                    <p style={{
                                                        color: '#444',
                                                        fontWeight: 600,
                                                        fontSize: '11px',
                                                        marginBottom: 0
                                                    }}>{helperFunctions.formatDateTimeWithDay(moment(sms.createdTime), 'time')}</p>
                                                    <div className="client-content-inner">

                                                        <p style={{color: 'green'}}>{sms.body}</p>
                                                    </div>
                                                </div>
                                            </div>) : (<div key={key} className="client support">
                                                <div className="client-content text-right">
                                                    <p style={{
                                                        color: '#444',
                                                        fontWeight: 600,
                                                        fontSize: '11px',
                                                        marginBottom: 0
                                                    }}>{helperFunctions.formatDateTimeWithDay(moment(sms.createdTime), 'time')}</p>
                                                    <div className="client-content-inner">
                                                        <p style={{color: '#1a76a4'}}>{sms.body}</p>
                                                    </div>
                                                </div>
                                                <div className="client-image">
                                                    <img src={sms.user ? sms.user.imageUrl : sms.imageUrl}
                                                         alt="user"/>
                                                </div>

                                            </div>)
                                        })}


                                    </div>
                                </div>
                                <div id="sms-sender">
                  <textarea value={this.state[`SMS_${users.mobileNumber}`] && this.state[`SMS_${users.mobileNumber}`]}
                            onChange={(e) => this.textMessageHandler(users.mobileNumber, e)}
                            placeholder="Type here.."></textarea>
                                    <div onClick={() => this.sendMessage(users.mobileNumber)} className="sender-icon"><i
                                        className="fa fa-paper-plane"></i></div>
                                </div>
                            </div>
                            <div className="caller-footer">
                                <div className="action">
                                    <div onClick={() => this.pressedFooterButton('book', '')} className="message book">
                                        <Link
                                            to={users && users.id != '' ? `/${this.app.vanityId}` + "/calendar-reservation?userId=" + users.id : `/${this.app.locations[0].id}` + "/calendar-reservation"}
                                            className="icons">
                                            <Book type={this.state.type}/>
                                        </Link>
                                    </div>

                                    {this.state[`callDirection_${users.mobileNumber}`] == 'inComing' &&
                                        <div onClick={() => this.muteUnMute()} className="mute book">
                                            <div className="icons">
                                                {this.state.mute == true ? <Mute/> : <Mic/>}

                                            </div>
                                        </div>
                                    }
                                    {this.state[`callDirection_${users.mobileNumber}`] == 'inComing' &&
                                        <div onClick={() => this.pressedFooterButton('cancel', users.mobileNumber)}
                                             className="book">
                                            <div className="icons">
                                                <Call type={this.state[`type_${users.mobileNumber}`]} cancel={true}/>
                                            </div>
                                        </div>}

                                    <div onClick={() => this.pressedFooterButton('message', users.mobileNumber)}
                                         className="message book">
                                        <div className="icons">
                                            <Message type={this.state[`type_${users.mobileNumber}`]}/>
                                        </div>
                                    </div>
                                    <div onClick={() => this.pressedFooterButton('keypad', users.mobileNumber)}
                                         className="grid book">
                                        <div className="icons">
                                            <Grid type={this.state[`type_${users.mobileNumber}`]}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                })}

                <HeaderCommunication
                    toggle={() => this.setState({open_com: false, com: []})}
                    callerHandler={this.openCaller}
                    app={this.app}
                    elementId={this.state.open_com == true ? 'activate' : ''}/>


                {user && showHeader &&
                    <div>
                        <nav className="navbar navbar-default navbar-static-top m-b-0">
                            <div className="navbar-header">
                                <div className="top-left-part">
                                    <Link className="logo" to={`/${this.storeId}`}>
                    <span>
                      <img src={this.app && this.app.logoUrls && this.app.logoUrls[1]} alt="home" className="dark-logo"
                           height="65"
                           width="180"/>
                    </span>
                                    </Link>
                                </div>

                                {actvts == true && this.state.location == `/${this.app.vanityId}/` &&

                                    <ActivitiesComponent
                                        activities={activities} cls={this.state.cls}
                                        remove={this.removeActivities}
                                        prev={this.getPreviousPage}
                                        next={this.getNextPage}
                                        actvts={actvts}
                                        waitlsts={waitlsts}
                                    />
                                }
                                {actvts == true && this.app !== null && this.app.vanityId && this.state.location == `/${this.app.vanityId}` &&
                                    <ActivitiesComponent
                                        activities={activities} cls={this.state.cls}
                                        remove={this.removeActivities}
                                        prev={this.getPreviousPage}
                                        next={this.getNextPage}
                                        actvts={actvts}
                                        waitlsts={waitlsts}
                                    />
                                }


                                {waitlsts == true && this.app !== null && this.app.vanityId && this.state.location == `/${this.app.vanityId}/` &&
                                    <WaitLists
                                        waitlsts={waitlsts}
                                        remove={this.removeActivities}
                                        cls={this.state.cls}
                                        next={this.getNextPage}
                                        prev={this.getPreviousPage}
                                        waitList={waitList}
                                        deletes={this.deleteWaitList}
                                        edit={this.editWaitList}
                                        findTechnician={this.findTechnician}

                                    />
                                }

                                {waitlsts == true && this.app !== null && this.app.vanityId && this.state.location == `/${this.app.vanityId}` &&

                                    <WaitLists
                                        waitlsts={waitlsts}
                                        remove={this.removeActivities}
                                        cls={this.state.cls}
                                        next={this.getNextPage}
                                        prev={this.getPreviousPage}
                                        waitList={waitList}
                                        deletes={this.deleteWaitList}
                                        edit={this.editWaitList}
                                        findTechnician={this.findTechnician}

                                    />}

                                <ul className="nav navbar-top-links navbar-right pull-right">
                                    {this.app !== null && this.app.vanityId && this.state.location == `/${this.app.vanityId}` &&
                                        <li className="dropdown">
                                            <a onClick={this.waitEnable}
                                               className="dropdown-toggle waves-effect waves-light"
                                               data-toggle="dropdown" href="#"> <i className="fa fa-hourglass"></i>
                                                <div className="notify"><span className="heartbit"></span> <span
                                                    className="point"></span></div>
                                            </a>

                                        </li>
                                    }
                                    {this.app !== null && this.app.vanityId && this.state.location == `/${this.app.vanityId}/` &&
                                        <li className="dropdown">
                                            <a onClick={this.waitEnable}
                                               className="dropdown-toggle waves-effect waves-light"
                                               data-toggle="dropdown" href="#"> <i className="fa fa-hourglass"></i>
                                                <div className="notify"><span className="heartbit"></span> <span
                                                    className="point"></span></div>
                                            </a>

                                        </li>
                                    }

                                    <li className={this.state.notificationOpen && this.state.notificationOpen == true ? 'dropdown open' : 'dropdown'}>
                                        <a
                                            style={{cursor: 'pointer'}}
                                            className="nav-link dropdown-toggle waves-effect waves-dark"
                                            data-toggle="dropdown">
                                            <i className="font-18 mdi mdi-gmail"></i>
                                            <div className="notify"><span className="heartbit"></span><span
                                                className="point"></span></div>

                                        </a>
                                        <div
                                            className="dropdown-menu dropdown-menu-right mailbox animated bounceInDown"
                                            aria-labelledby="2">
                                            <ul className="list-style-none">
                                                <li>
                                                    <div className="drop-title">You
                                                        have {this.state.storage ? this.state.storage.length : '0'} new
                                                        messages
                                                    </div>
                                                </li>
                                                {this.state.storage && this.state.storage.length > 0 && this.state.storage.map((sms, key) => {
                                                    return <li key={key}
                                                               onClick={() => this.openChat(sms.sender, sms.id != 0 && sms.id)}>
                                                        <div className="message-center message-body">

                              <span style={{cursor: 'pointer'}} className="message-item">
                                <span className="user-img"> <img
                                    src={sms.imageUrl}
                                    alt="user"
                                    className="rounded-circle"/> <span
                                    className="profile-status online pull-right">{sms.qty}</span> </span>
                                <span className="mail-contnet">
                                  <div>
                                    <span className="mail-desc">{sms.name}</span>
                                  </div>
                                  <div>

                                    <span className="mail-desc">{sms.sender}</span>
                                    <span
                                        className="time">{sms.time ? sms.time : moment().format('hh:mm a')}</span>
                                  </div>
                                  <span
                                      className="mail-desc">{sms.body.length > 20 ? sms.body.substr(0, 20) + '...' : sms.body}</span>  </span>
                              </span>


                                                        </div>
                                                    </li>
                                                })}


                                            </ul>
                                        </div>
                                    </li>

                                    {this.app !== null && this.app.vanityId && this.state.location == `/${this.app.vanityId}` &&
                                        <li className="dropdown">
                                            <a onClick={this.enableActivities}
                                               className="dropdown-toggle waves-effect waves-light"
                                               data-toggle="dropdown"
                                               href="javascript.void(0)"> <i className="mdi mdi-check-circle"></i>
                                                <div className="notify"><span className="heartbit"></span><span
                                                    className="point"></span></div>
                                            </a>
                                        </li>
                                    }
                                    {this.app !== null && this.app.vanityId && this.state.location == `/${this.app.vanityId}/` &&
                                        <li className="dropdown">
                                            <a onClick={this.enableActivities}
                                               className="dropdown-toggle waves-effect waves-light"
                                               data-toggle="dropdown"
                                               href="javascript.void(0)"> <i className="mdi mdi-check-circle"></i>
                                                <div className="notify"><span className="heartbit"></span><span
                                                    className="point"></span></div>
                                            </a>
                                        </li>
                                    }

                                    <li className="dropdown">
                                        <a onClick={() => this.setState({open_com: true})}
                                           className="waves-effect waves-light com">
                                            <Com/>
                                            <div className="notify"><span className="heartbit"></span> <span
                                                className="point"></span></div>
                                        </a>

                                    </li>

                                    <li><a className="open-close waves-effect waves-light visible-xs"><i
                                        className="fa fa-close fa fa-bars"></i></a></li>
                                    <li className="dropdown">
                                        <a className="dropdown-toggle profile-pic" data-toggle="dropdown"> <img
                                            src={user.imageUrl} alt="user-img" width="36" className="img-circle"/><b
                                            className="hidden-xs">{user.fullName}</b><span className="caret"></span>
                                        </a>
                                        <ul className="dropdown-menu dropdown-user animated flipInY">


                                            <li>
                                                <div className="dw-user-box">
                                                    <div className="u-img"><img src={user.imageUrl} alt="user"/>
                                                        <div className="btn btn-info"><Link
                                                            to={`/${this.storeId}/profile`}>Edit
                                                            Image</Link></div>
                                                    </div>
                                                    <div className="u-text">
                                                        <h4>{user.fullName}</h4>
                                                        <p className="text-muted">{user.role}</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li role="separator" className="divider"></li>
                                            <li><Link to={`/${this.storeId}/profile`}><i className="fa fa-user"></i> My
                                                Profile</Link></li>
                                            <li><Link to={`/${this.storeId}/update-password`}><i
                                                className="fa fa-lock"></i>Change
                                                Password</Link></li>
                                            <li role="separator" className="divider"></li>
                                            <li><Link to='/'><i className="fa fa-home"></i> Change Salon</Link></li>
                                            <li role="separator" className="divider"></li>
                                            <li><Link to={`/${this.storeId}/login`}><i
                                                className="fa fa-power-off"></i> Logout</Link></li>
                                        </ul>

                                    </li>

                                </ul>
                            </div>

                        </nav>

                        <MainSidebar
                            MAN_DIREC={MAN_DIREC}
                            storeId={this.storeId}
                            vanityId={this.app.vanityId}
                            location={location}
                            app={this.app}
                            user={user}/>
                    </div>
                }
                <Draggable>

                    <MailShow htmlElement={this.state.htmlElement}/>

                </Draggable>
            </div>
        );
    }

    getNextPage = () => {
        if (this.state.actvts == false) {
            var offset = this.state.pageOffset + 1;
            const {waitList} = this.state
            if (waitList && waitList.length != 0) {
                reservationService.getAllWaitList(offset, 'upcmng-lctn').then(res => {
                    this.setState({
                        ...this.state,
                        waitList: res,
                        pageOffset: offset
                    })
                })

            }

        } else {
            var offset = this.state.pageOffset + 1;
            const {activities} = this.state
            if (activities && activities.length != 0) {
                userService.getAllActivities(offset).then(res => {
                    this.setState({
                        ...this.state,
                        activities: res,
                        pageOffset: offset
                    })
                })

            }
        }

    }

    getPreviousPage = () => {
        if (this.state.actvts == false) {
            var offset = this.state.pageOffset - 1;
            const {waitList} = this.state
            if (offset >= 0) {
                reservationService.getAllWaitList(offset, 'upcmng-lctn').then(res => {
                    this.setState({
                        ...this.state,
                        waitList: res,
                        pageOffset: offset
                    })
                })
            }
        } else {
            var offset = this.state.pageOffset - 1;
            const {activities} = this.state
            if (offset >= 0) {
                userService.getAllActivities(offset).then(res => {
                    this.setState({
                        ...this.state,
                        activities: res,
                        pageOffset: offset
                    })
                })
            }
        }


    }


    findServicePrice(serviceId, techId, catId) {
        //console.log(serviceId)
        let servicePrice = ""
        let s = this.app.locations[0].technicians.find(tech => tech.id == techId)
            .offeredServices.find(service => service.id == serviceId);
        //console.log('SSSS',s)
        if (s == null) {
            s = this.state.categories.find(c => c.id == catId)
            //console.log('s',s)
        }
        servicePrice = s.minimumFee == -1 && s.maximumFee == -1 ? "Per Consult" :
            s.minimumFee > -1 && s.maximumFee == -1 ? "From $" + s.minimumFee :
                (s.minimumFee > -1 && s.maximumFee > -1) && (s.minimumFee == s.maximumFee) ? "$" + s.minimumFee :
                    (s.minimumFee > -1 && s.maximumFee > -1) && (s.minimumFee != s.maximumFee) ? "$" + s.minimumFee + " - $" + s.maximumFee : "";
        return servicePrice;
    }

    findServiceName(serviceId, categoryId) {
        // eslint-disable-next-line
        let category = this.state.categories.find(sc => sc.id == categoryId)
        if (category == null)
            return "Service not found"
        let service = category.services.find(s => s.id == serviceId)
        if (service == null)
            return "Service not found"
        return service.name;
    }

    findTechnician(technicianId, query) {
        // eslint-disable-next-line
        let technician = this.app.locations[0].technicians.find(technician => technician.id == technicianId)

        if (technician) {
            if (query == 'image') {
                return technician.user.imageUrl;
            } else if (query == 'active') {
                if (technician.active == 'true') {
                    return 'active'
                }
            } else if (query == 'nick') {
                return technician.user.names.nick;

            } else {
                return technician.user.fullName;
            }
        } else {

            return "";
        }
    }

}

function mapStateToProps(state) {
    const {user} = state.authentication;
    const {info} = state.Communication
    const {selectedUser} = state.outgoing
    const {fcmReload} = state.app;
    return {
        user,
        selectedUser,
        info,
        fcmReload
    };
}


const connectedHeader = connect(mapStateToProps)(Header);
export {connectedHeader as Header};
