/* eslint-disable */
import React, {PureComponent} from 'react';
import AddToCart from './addToCart';

class AddNewAppointmentModal extends PureComponent {

    constructor(props){
        super(props)
    }

    render() {
        return (

              <AddToCart waitCustomer={this.props.waitCustomer} selected={this.props.selected} setPlace={this.props.setPlace} clearPlace={this.props.clearPlace} place={this.props.place} selectedTech={this.props.selectedTech} apptDate={this.props.apptDate} customer={this.props.customer} loading={this.props.loading} saveAppointment={this.props.saveAppointment} selectNewCustomer={this.props.selectNewCustomer} startTime={this.props.timeStart} endTime={this.props.timeEnd} apptDate={this.props.timeStart}/>
              );
    }
}

export default AddNewAppointmentModal;
