import { userConstants } from '../_constants';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { loggedIn: true, user } : {};
 
export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: null
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggingIn:false,
        loggedIn: true,
        user: action.user
      };
    case userConstants.LOGIN_FAILURE:
      return {
        loginFailure: true,
        loggingIn:false,
        failureMessage:action.error,
        user: null
      };
    case userConstants.LOGOUT:
      return {};
    default:
      return state
  }
}