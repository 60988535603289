import { userConstants } from '../_constants';
 
export function technicians(state = {}, action) {
  switch (action.type) {

    case userConstants.GET_ALL_TECHNICIANS_REQUEST:
      return {
        technicians: [],
        techLoading:  false
      }

    case userConstants.GET_ALL_TECHNICIANS_SUCCESS:
      return { 
        technicians: action.technicians,
        techLoading:  true
       };
    case userConstants.GET_ALL_TECHNICIANS_FAILURE:
      return {
        technicians: [],
        techLoading:  false
      };
    case userConstants.GET_TECHNICIANS_SCHEDULE_SUCCESS:
      return { schedule: action.schedule };
    case userConstants.GET_TECHNICIANS_SCHEDULE_FAILURE:
      return { schedule: [] };
    default:
      return state
  }
}