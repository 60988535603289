import React, {useEffect} from "react";

const Result = ({label}) => {
    const resultRef = React.useRef(null)
    useEffect(() => {
        if (resultRef.current) {
            resultRef.current.value = label ? label : ''
        }
    }, [label])

    const clickHandler = (e) => {
        const element = document.querySelector('.search-result');
        element.classList.contains('visible') ? element.classList.remove('visible') : element.classList.add('visible');


    }
    return (
        <div className="dropdown-result" onClick={clickHandler}>
            <div className="input-group mb-3">
                <input
                    ref={resultRef}
                    readOnly
                    type="text"
                    className="form-control"
                    placeholder="select brand*"
                    aria-label="Brand"
                    aria-describedby="basic-addon1"

                />
                <div className="input-group-addon">
          <span className="input-group-text" id="basic-brand">
            <i className="fa fa-angle-down fa-2x"></i>
          </span>
                </div>
            </div>
        </div>
    );
};

export default Result;
