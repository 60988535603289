import React from 'react';
import {connect} from 'react-redux';
import './profile.css';
import {userActions} from '../../_actions';
import Footer from '../footer/footer'
import {appService} from "../../_services";
import {helperFunctions} from "../../_helpers";

class UpdatePasswordPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            cPassword: '',
            submitted: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentDidMount = async () => {
        const metaInfo = await appService.checkVersion();
        await helperFunctions.clearCache(metaInfo)
    }


    handleChange(event) {
        const {name, value} = event.target;
        this.setState({[name]: value});

    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({submitted: true});
        const {dispatch} = this.props;
        // eslint-disable-next-line
        if (this.state.password == this.state.cPassword) {
            const newPassword = {
                password: this.state.password
            }

            dispatch(userActions.updatePassword(newPassword));
        }
    }

    render() {
        const {passwordUpdated} = this.props;
        const {password, cPassword, submitted} = this.state;
        return (
            <div>
                <div id="page-wrapper">
                    <div className="container-fluid">
                        <div className="center col-sm-12">
                            <h1>CHANGE PASSWORD</h1>
                        </div>
                        <hr/>
                        <div className="row contact-wrap">
                            <div className="col-md-6 col-md-offset-3 col-xs-9 col-xs-offset-1">
                                <form name="form" onSubmit={this.handleSubmit}>
                                    <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                                        <label htmlFor="password">NEW PASSWORD*</label>
                                        <input type="password" className="form-control" name="password" value={password}
                                               onChange={this.handleChange}/>
                                        {submitted && !password &&
                                            <div className="help-block">Password is required</div>
                                        }
                                    </div>
                                    <div
                                        className={'form-group' + (submitted && !(password === cPassword) ? 'is-invalid' : '')}>
                                        <label htmlFor="cPassword">CONFIRM PASSWORD*</label>
                                        <input type="password" className="form-control" name="cPassword"
                                               value={cPassword} onChange={this.handleChange}/>
                                        {submitted && !(password === cPassword) &&
                                            <div className="help-block">Password and confirm password fields donot
                                                match.</div>
                                        }
                                    </div>

                                    <div className="form-group center-xs">
                                        <input type="submit" className="btn btn-block btn-primary btn-lg btn-rounded"
                                               value="Update Password"/>
                                    </div>
                                    {
                                        passwordUpdated &&
                                        <div>
                                            <h4 className="center">Your password has been updated successfully.</h4>
                                            <div className="checkmark">
                                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                     viewBox="0 0 130.2 130.2">
                                                    <circle className="path circle" fill="none" stroke="#73AF55"
                                                            strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1"
                                                            r="62.1"/>
                                                    <polyline className="path check" fill="none" stroke="#73AF55"
                                                              strokeWidth="6" strokeLinecap="round"
                                                              strokeMiterlimit="10"
                                                              points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
                                                </svg>
                                            </div>
                                        </div>
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }

}

function mapStateToProps(state) {
    const {passwordUpdated} = state.updateProfile
    return {
        passwordUpdated
    };
}

const connectedUpdatePasswordPage = connect(mapStateToProps)(UpdatePasswordPage);
export {connectedUpdatePasswordPage as UpdatePasswordPage};
