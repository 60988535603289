import React from 'react';

const  CompletedForm =({imgData})=> {

        return (

                <div className="modal-dialog modal-custom-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button className="close" data-dismiss="modal">
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-image">
                                <img src={'data:image/gif;base64,'+imgData} alt="form"/>
                            </div>
                        </div>
                    </div>
                </div>

        );
 }
export default React.memo(CompletedForm)
