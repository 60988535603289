/* eslint-disable */
import {Config} from '../config';
import {authHeader} from '../_helpers';

var config = Config()
const apiPath = config.serverUrl;

export const payrollService = {
    getAllPayroll,
    getSinglePayRoll,
    finalizeRecords,
    updateRecords

};

function getAllPayroll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include'
    };

    return fetch(apiPath + 'pay-runs?locId=' + JSON.parse(localStorage.getItem('app')).locations[0].id, requestOptions).then(handleResponse);

}

function getSinglePayRoll(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include'
    };

    return fetch(apiPath + 'pay-runs/' + id, requestOptions).then(handleResponse);

}

function updateRecords(id, data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(data)
    };

    return fetch(apiPath + 'pay-records/' + id + '/adjustments', requestOptions).then(handleResponse);

}

function finalizeRecords(id, type) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify({})
    };
    let params = '';
    if (type == 'process') {
        params = 'pay-runs/'
    } else {
        params = 'pay-records/'
    }


    return fetch(apiPath + params + id + '/status', requestOptions).then(handleResponse);

}


function handleResponse(response) {
    if (!response.ok) {
        if (response.status == 401 || response.status == 403) {
            //window.location = `/${window.location.href.split('/')[3]}/login`;
        } else {
            return Promise.reject(response);
        }
    }

    return response.json();
}


function handleDeleteResponse(response) {

    if (!response.ok) {

        if (response.status == 401 || response.status == 403) {
            window.location = `/${window.location.href.split('/')[3]}/login`;
        }

        return Promise.reject(response);

    }
}
