/* eslint-disable */
import React, {PureComponent} from "react";
import "rc-datetime-picker/dist/picker.min.css";
import moment from "moment";
import {DatetimePickerTrigger} from "rc-datetime-picker";
import tasksImg from "./../task.png";
import notesImg from "./../note.png";
import {helperFunctions} from "../_helpers";
import {appService} from "../_services";
import InlineMessage from "./inlineMessage";
import ServiceSearchBar from "./serviceSearchBar";
import "rc-time-picker/assets/index.css";
import ProductSearchBar from "./productSearchBar";
import ProductScanner from "./productScanner";
import CustomTimePicker from "./custom-timer-picker";
import NotePopUp from "./note-popup/note-popup";

const format = "hh:mm a";
const now = moment().hour(0).minute(0);

class EditCart extends PureComponent {
    state = {
        staffNote: false,
        customerNote: false,
        editDate: false,
        addService: false,
        products: false,
        dueTime: moment(),
        searchQuery: "",

        staffNotes: this.props.singleAppt && this.props.singleAppt.staffNotes,
        ctgryId: "",
        services: "",
        sid: "",
        sprice: "",
        selectedOption: null,
        techServices: "",
        startTime: "",
        endTime: moment(),
        errors: {},
        method: "POST",
        singleSVtId: "",
        cancelReason: "",
        buttonText: false,
        updateText: false,
        apptBookedTime: "",
        expectedStartTime: "",
        expectedEndtTime: "",
        periodTo: "",
        value: "",
        serviceId: "",
        serviceName: "",
        servicePrice: "",
        hidden: false,
        technician: "",
        durat: "",
        singleProduct: "",
        productPrice: "",
        productId: "",
        qty: 1,
        productName: "",
        place: "",
        mode: "",
        productCollection: [],
        purchaseId: "",
        singleService: "",
        modalClass: false,
        pmodalClass: false,
        vt: "",
        chargeAmount: 0,
        cmodal: false,
        validAmount: "",
        serviceChargeAmount: null,
        productPurchaseAmount: null,
        creditPaidAmount: 0,
        cashPaidAmount: 0,
        checkPaidAmount: 0,
        giftCardPaidAmount: 0,
        rewardDeductionAmount: 0,
        discountDeductionAmount: 0,
        tipAmount: 0,
        taxAmount: 0,
        giftCardSaleAmount: 0,
        totalChargeAmount: 0,
        singleData: {},
        singleAppt: {},
        editPmodal: false,
        popUp: false,
    };

    constructor(props) {
        super(props);
        this.loggedUser = JSON.parse(localStorage.getItem("user"));
        this.app = JSON.parse(localStorage.getItem("app"));
        this.changeStaffNotes = this.changeStaffNotes.bind(this);
    }

    calculateTotal = () => {
        let calculation = 0;
        if (this.props.singleAppt.totalChargeAmount != "-1") {
            calculation = this.state.serviceChargeAmount + this.state.pr;
        }
    };

    changeHandler = (e) => {
        const {name, value} = e.target;
        //console.log(value)
        this.setState({
            ...this.state,
            [name]: value,
        });
    };

    serviceChange = (e) => {
        //console.log(e.target.value)
        this.setState({
            singleAppt: {
                ...this.state.singleAppt,
                serviceChargeAmount: e.target.value,
            },
        });
    };

    changeStaffNotes = (e) => {
        this.setState({
            staffNotes: e.target.value,
        });
    };

    updateStaffNotes = () => {
        const notes = {
            staffNotes: this.state.staffNotes,
        };
        this.props.updateStaffNotes(this.props.singleAppt.id, notes);
        this.setState({
            staffNote: !this.state.staffNote,
        });
    };

    confirmAppt = () => {
        const confirmedTime = {
            confirmationTime: this.props.singleAppt.bookedTime,
        };
        this.props.confirmAppt(this.props.singleAppt.id, confirmedTime);
    };

    changeCategory = (e) => {
        this.setState({
            ...this.state,
            ctgryId: e.target.value,
            sid: "",
            selectedOption: null,
            sprice: "",
        });

        setTimeout(() => {
            appService.getAllServices(0, this.state.ctgryId).then((res) => {
                this.setState({
                    ...this.state,
                    services: res,
                });
            });
        }, 400);
    };
    changeUpdateCategory = (e) => {
        const {name, value} = e.target;
        const sid = `sid-${this.state.singleSVtId}`;
        const sprice = `sprice-${this.state.singleSVtId}`;
        const services = `services-${this.state.singleSVtId}`;
        const techServices = `techServices-${this.state.singleSVtId}`;
        const selectedOption = `selectedOption-${this.state.singleSVtId}`;
        this.setState(
            {
                ...this.state,
                [name]: value,
                [sid]: "",
                [selectedOption]: null,
                [sprice]: "",
                [techServices]: "",
            },
            () => {
                setTimeout(() => {
                    appService
                        .getAllServices(0, this.state[`ctgryId-${this.state.singleSVtId}`])
                        .then((res) => {
                            this.setState({
                                ...this.state,
                                [services]: res,
                            });
                        });
                }, 400);
            }
        );
    };

    changeService = (e) => {
        this.setState(
            {
                ...this.state,
                sid: e.target.value,
            },
            () => {
                let technicians = this.checkTechAttachedToService(this.state.sid);

                setTimeout(() => {
                    const price = this.checkPrice(this.state.sid);
                    this.setState({
                        ...this.state,
                        sprice: price,
                        techServices: technicians,
                        selectedOption: null,
                    });
                }, 400);
            }
        );
    };

    changeUpdateService = (e) => {
        const {name, value} = e.target;
        const sprice = `sprice-${this.state.singleSVtId}`;
        const techServices = `techServices-${this.state.singleSVtId}`;
        const selectedOption = `selectedOption-${this.state.singleSVtId}`;
        this.setState(
            {
                ...this.state,
                [name]: value,
            },
            () => {
                setTimeout(() => {
                    let technicians = this.checkTechUpdateAttachedToService(
                        this.state[`sid-${this.state.singleSVtId}`]
                    );
                    const price = this.checkUpdatePrice(
                        this.state[`sid-${this.state.singleSVtId}`]
                    );
                    this.setState({
                        ...this.state,
                        [sprice]: price,
                        [techServices]: technicians,
                        [selectedOption]: null,
                    });
                }, 400);
            }
        );
    };

    handleSelect = (selectedOption) => {
        this.setState({selectedOption});
    };
    handleUpdateSelect = (selectedOptions) => {
        const selectedOption = `selectedOption-${this.state.singleSVtId}`;
        this.setState({...this.state, [selectedOption]: selectedOptions});
    };

    changeStartTime = (startTime) => {
        var durat = "";
        var duration = "";
        if (this.state.techServices.length > 0) {
            durat = this.state.techServices.find(
                (t) => t.id == this.state.technician
            );

            if (durat) {
                duration = durat.durationInMinute;
            }
        }

        this.setState({
            startTime: startTime,
            durat: moment(startTime).add(duration, "minutes"),
        });
    };

    changeUpdateStartTime = (startTimes) => {
        const startTime = `startTime-${this.state.singleSVtId}`;
        const durat = `durat-${this.state.singleSVtId}`;
        var durats = "";
        var duration = "";

        if (this.state[`techServices-${this.state.singleSVtId}`].length > 0) {
            durats = this.state[`techServices-${this.state.singleSVtId}`].find(
                (t) => t.id == this.state[`technician-${this.state.singleSVtId}`]
            );

            if (durats) {
                duration = durats.durationInMinute;
            }
        }

        this.setState({
            [startTime]: startTimes,
            [durat]: moment(startTimes).add(duration, "minutes"),
        });
    };

    changeEndTime = (endTime) => {
        this.setState({
            ...this.state,
            endTime: endTime,
            durat: endTime,
        });
    };

    changeUpdateEndTime = (endTimes) => {
        const durat = `durat-${this.state.singleSVtId}`;
        this.setState({
            ...this.state,
            [durat]: endTimes,
        });
    };

    handleChangeRequested = () => {
        const technicianRequested = `technicianRequested-${this.state.singleSVtId}`;
        this.setState({
            ...this.state,
            [technicianRequested]:
                !this.state[`technicianRequested-${this.state.singleSVtId}`],
        });
    };

    initializeAddServiceForm = () => {
        $("#editCart").modal("hide");
        $("#addServices").modal("show");
        this.setState({
            ...this.state,
            addService: !this.state.addService,
            ctgryId: "",
            services: "",
            serviceId: "",
            sprice: "",
            selectedOption: null,
            searchQuery: "",
            techServices: "",
            startTime: moment(this.props.singleAppt.bookedTime),
            errors: {},
            method: "POST",
            buttonText: !this.state.buttonText,
            modalClass: !this.state.modalClass,
        });
    };

    changeAddPrTech = (e) => {
        const {name, value} = e.target;
        this.setState({
            ...this.state,
            [name]: value,
        });
    };
    updateSaveService = (e) => {
        e.preventDefault();
        this.setState({
            place: "Search a name",
        });
        if (this.state.method == "PUT") {
            const datePart = moment(this.props.singleAppt.bookedTime).format(
                "YYYY-MM-DDT"
            );
            const serviceJSON = {
                visit: {
                    id: this.props.singleAppt.id,
                },
                technician: {
                    id: this.state[`technician-${this.state.singleSVtId}`],
                },
                offeredService: {
                    id: this.state[`serviceId-${this.state.singleSVtId}`],
                },
                expectedStartTime:
                    datePart +
                    moment(this.state[`startTime-${this.state.singleSVtId}`]).format(
                        "HH:mm:ss"
                    ),
                period: {
                    to:
                        datePart +
                        moment(this.state[`durat-${this.state.singleSVtId}`]).format(
                            "HH:mm:ss"
                        ),
                },
                technicianRequested:
                    this.state[`technicianRequested-${this.state.singleSVtId}`],
            };
            const errors = this.validateTask(serviceJSON);
            this.setState({errors});
            if (Object.keys(errors).length == 0) {
                this.props.apptAddUpdateService(this.state.singleSVtId, serviceJSON);
                this.setState({
                    errors: {},
                });
            }
        } else {
            //alert('POST');
            const {startTime, durat} = this.state;
            const datePart = moment(this.props.singleAppt.bookedTime).format(
                "YYYY-MM-DDT"
            );
            const serviceJSON = {
                visit: {
                    id: this.props.singleAppt.id,
                },
                technician: {
                    id: this.state.technician,
                },
                offeredService: {
                    id: this.state.serviceId,
                },
                expectedStartTime:
                    this.state.startTime != ""
                        ? datePart + moment(this.state.startTime).format("HH:mm:ss")
                        : datePart +
                        moment(this.props.singleAppt.bookedTime).format("HH:mm:ss"),
                period: {
                    to: datePart + moment(this.state.durat).format("HH:mm:ss"),
                },
            };
            //console.log('SERVICE',serviceJSON)
            const errors = this.validateTask(serviceJSON);
            this.setState({errors});
            if (Object.keys(errors).length == 0) {
                this.props.apptAddUpdateService("Nill", serviceJSON);
                this.setState({
                    errors: {},
                });
                $("#addServices").modal("hide");
                $("#editModal").modal("show");
            }
        }
    };

    //open edit service form and set the state

    openUpdateServiceForm = (singleService) => {
        console.log("sinfg", singleService);

        const service = `service-${singleService.id}`;
        const startTime = `startTime-${singleService.id}`;

        const durat = `durat-${singleService.id}`;
        const singleSVtId = `singleSVtId-${singleService.id}`;
        const servicePrice = `servicePrice-${singleService.id}`;
        const serviceName = `services-${singleService.id}`;
        const techServices = `techServices-${singleService.id}`;
        const category = `category-${singleService.id}`;
        const technician = `technician-${singleService.id}`;
        const serviceId = `serviceId-${singleService.id}`;
        const value = `value-${singleService.id}`;
        const technicianRequested = `technicianRequested-${singleService.id}`;

        this.setState({
            ...this.state,
            [service]: true,
            [startTime]: moment(singleService.expectedStartTime),
            [durat]: moment(singleService.period.to),
            method: "PUT",
            [singleSVtId]: singleService.id,
            singleSVtId: singleService.id,
            [serviceId]: singleService.offeredService.id,
            [techServices]: [],
            [technician]: singleService.technician.id,
            [technicianRequested]: singleService.technicianRequested,
            [value]: this.findServiceName(
                singleService.offeredService.id,
                singleService.offeredService.category.id
            ),
        });

        // const techJSON = {
        //     id: singleService.technician.id,
        //     name: this.findTechnician(singleService.technician.id, 'nick'),
        //     price: singleService.chargeAmount,
        //
        // }

        appService
            .getSingleOfferedService(
                singleService.offeredService.id,
                (this.state.searchQuery = "")
            )
            .then((res) => {
                //console.log('rereeer',res)
                if (res != "") {
                    let techService = [];

                    appService
                        .getTechnicianServiceByLocationId(
                            this.app.locations[0].id,
                            singleService.offeredService.id
                        )
                        .then((technicianServices) => {
                            technicianServices.forEach((technician) => {
                                const valid = this.checkValid(technician.technician.id);
                                if (valid == "valid") {
                                    techService.push({
                                        id: technician.technician.id,
                                        name: technician.technician.user.names.nick,
                                        price: technician.displayedPrice,
                                        duration: helperFunctions.timeConvert(technician.duration),
                                        durationInMinute: technician.durationInMinutes,
                                        actualPrice: this.checkPriceType(
                                            technician.minimumFee,
                                            technician.maximumFee
                                        ),
                                    });
                                }
                            });

                            this.setState({[techServices]: techService});
                            var techJson = [];
                            var i = 0;

                            techJson.push({
                                text: this.findTechnician(singleService.technician.id, "nick"),
                                value: singleService.technician.id,
                                selected: false,
                                description: "[" + singleService.chargeAmount + "]",
                                imageSrc: this.findTechnician(
                                    singleService.technician.id,
                                    "image"
                                ),
                            });
                            techService.forEach((tech) => {
                                techJson.push({
                                    text: tech.name,
                                    value: tech.id,
                                    selected: false,
                                    description: "[" + tech.duration + ", " + tech.price + "]",
                                    imageSrc: this.findTechnician(tech.id, "image"),
                                });
                                i++;
                            });
                            setTimeout(function () {
                                window.initiateTechniciansSelect(
                                    techJson,
                                    `#technicians-select-div-update-${singleService.id}`,
                                    `.technicians-select-update-${singleService.id}`
                                );
                            }, 100);
                        });
                }
            });
    };
    checkValid = (id) => {
        const t = this.app.locations[0].technicians.find((t) => t.id == id);
        if (t) {
            if (t.bookable == true) {
                return "valid";
            }
        } else {
            return "Not valid";
        }
    };

    closeUpdateForm = (id) => {
        const service = `service-${id}`;
        this.setState({
            ...this.state,
            [service]: false,
        });
    };

    validateTask = (data) => {
        let sum = moment(data.period.to).diff(moment(data.expectedStartTime));
        let minutes = parseInt(moment.duration(sum).asMinutes());

        const errors = {};
        if (!data.technician.id) errors.tech = "Please select a stylist";
        if (!data.offeredService.id) errors.service = "Please enter a subject";
        if (minutes < 1) errors.timeDifference = "Please enter a valid end time";

        return errors;
    };

    //delete service
    openCancelModal = (singleService) => {
        const active = this.props.singleAppt.visitTechnicians.filter(
            (vt) => vt.status != "Cancelled"
        );

        Swal.fire({
            title: "Delete Service",
            text: `${
                active.length > 1
                    ? " Are you sure you want to delete this service?"
                    : active.length == 1
                        ? "This will also cancel the appointment. Are you sure you want to delete ?"
                        : " Are you sure you want to delete this service?"
            }`,
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, keep it",
        }).then((result) => {
            if (result.value) {
                $("#cancelReason").modal("toggle");
                this.setState({
                    ...this.state,
                    singleSVtId: singleService.id,
                });
            } else {
                $("#editModal").modal("show");
            }
        });
    };
    cancelReason = () => {
        const cancelReason = {
            cancellaTionReason: this.state.cancelReason,
        };

        this.props.cancelServieFromAppt(
            this.state.singleSVtId,
            cancelReason,
            this.props.singleAppt.serviceCount
        );

        this.setState({
            cancelReason: "",
        });
        $("#cancelReason").modal("hide");
    };

    checkServiceIn = (vt) => {
        this.props.checkServiceIn(vt);
    };
    UndocheckServiceIn = () => {
        this.props.UndocheckServiceIn(this.props.singleAppt.id);
    };

    //checkout
    checkServiceOut = (vt, amount) => {
        this.setState({
            vt: vt,
            chargeAmount: amount,
            validAmount: amount,
            cmodal: true,
        });
    };

    //open editable time for booking
    editedBookingTime = (bookedTime) => {
        this.setState({
            editDate: !this.state.editDate,
            apptBookedTime: moment(bookedTime),
        });
    };

    //change booked time
    changeBookedTime = (bookedTime) => {
        this.setState({
            ...this.start,
            apptBookedTime: bookedTime,
        });
    };

    getDuration = () => {
        const sum = moment(
            this.props.singleAppt.visitTechnicians[0].period.to
        ).diff(moment(this.props.singleAppt.visitTechnicians[0].expectedStartTime));
        const minutes = parseInt(moment.duration(sum).asMinutes());

        return minutes;
    };

    //updating booking time
    updateAppointmentTime = () => {
        let datePart = "";
        if (this.state.apptBookedTime != "") {
            datePart = this.state.apptBookedTime.format("YYYY-MM-DDT");
        } else {
            datePart = moment(this.props.singleAppt.bookedTime).format("YYYY-MM-DDT");
        }
        let tid = [];
        this.props.singleAppt.visitTechnicians.map((t) => {
            tid.push(t.id);
        });

        var tCollection = [];
        for (var i = 0; i < tid.length; i++) {
            if (i == 0) {
                var obj = {
                    id: tid[i],
                    technician: {
                        id: this.props.singleAppt.visitTechnicians[i].technician.id,
                    },
                    offeredService: {
                        id: this.props.singleAppt.visitTechnicians[i].offeredService.id,
                    },
                    expectedStartTime:
                        this.state.apptBookedTime != ""
                            ? this.state.apptBookedTime.format("YYYY-MM-DDTHH:mm:ss")
                            : moment(this.props.singleAppt.bookedTime).format(
                                "YYYY-MM-DDTHH:mm:ss"
                            ),
                    period: {
                        to:
                            this.state.apptBookedTime != ""
                                ? this.state.apptBookedTime
                                    .add(this.getDuration(), "minutes")
                                    .format("YYYY-MM-DDTHH:mm:ss")
                                : datePart +
                                moment(
                                    this.props.singleAppt.visitTechnicians[i].period.to
                                ).format("HH:mm:ss"),
                    },
                    technicianRequested:
                    this.props.singleAppt.visitTechnicians[i].technicianRequested,
                };
            } else {
                var obj = {
                    id: tid[i],
                    technician: {
                        id: this.props.singleAppt.visitTechnicians[i].technician.id,
                    },
                    offeredService: {
                        id: this.props.singleAppt.visitTechnicians[i].offeredService.id,
                    },
                    expectedStartTime:
                        datePart +
                        moment(
                            this.props.singleAppt.visitTechnicians[i].expectedStartTime
                        ).format("HH:mm:ss"),
                    period: {
                        to:
                            datePart +
                            moment(
                                this.props.singleAppt.visitTechnicians[i].period.to
                            ).format("HH:mm:ss"),
                    },
                    technicianRequested:
                    this.props.singleAppt.visitTechnicians[i].technicianRequested,
                };
            }

            tCollection.push(obj);
        }

        var items = [];

        if (this.props.singleAppt.purchaseItems.length > 0) {
            for (var j = 0; j < this.props.singleAppt.purchaseItems.length; j++) {
                var iObj = {
                    id: this.props.singleAppt.purchaseItems[j].id,
                    visit: {
                        id: this.props.singleAppt.id,
                    },
                    quantity: this.props.singleAppt.purchaseItems[j].quantity,
                    chargeAmount: this.props.singleAppt.purchaseItems[j].chargeAmount,
                    technician: {
                        id: this.props.singleAppt.purchaseItems[j].technician.id,
                    },
                    locatedProductVariant: {
                        id: this.props.singleAppt.purchaseItems[j].locatedProductVariant.id,
                    },
                };
                items.push(iObj);
            }
        } else {
            items = [];
        }

        const timeJSON = {
            location: {
                id: this.app.locations[0].id,
            },
            requestSource: this.props.singleAppt.requestSource,
            requestType: this.props.singleAppt.requestType,
            standingAppointment: this.props.singleAppt.standingAppointment,
            customerNotes: this.props.singleAppt.customerNotes,
            visitTechnicians: tCollection,
            purchaseItems: items,
        };

        this.props.updateBookingTime(this.props.singleAppt.id, timeJSON);

        setTimeout(() => {
            this.setState({
                editDate: false,
            });
        }, 400);
    };

    onServiceSelect = (service) => {
        this.setState({
            ...this.state,
            serviceId: service.service.id,
            serviceName: service.service.name,
            servicePrice: this.checkPriceType(service.minimumFee, service.maximumFee),
            category: this.checkCategory(service.service.category.id),
        });

        if (service != "") {
            let techServices = [];
            let technicianIds = [];

            //alert(this.props.singleAppt.visitTechnicians[this.props.singleAppt.visitTechnicians.length-1].technician.id)

            const selectedTech = service.technicianServices.filter(
                (t) =>
                    t.technician.id ==
                    this.props.singleAppt.visitTechnicians[
                    this.props.singleAppt.visitTechnicians.length - 1
                        ].technician.id
            );
            if (selectedTech.length > 0) {
                const valid = this.checkValid(selectedTech[0].technician.id);
                if (valid == "valid") {
                    techServices.push({
                        key: 0,
                        id: selectedTech[0].technician.id,
                        name: selectedTech[0].technician.user.names.nick,
                        price: selectedTech[0].displayedPrice,
                        duration: helperFunctions.timeConvert(selectedTech[0].duration),
                        durationInMinute: selectedTech[0].durationInMinutes,
                        actualPrice: this.checkPriceType(
                            selectedTech[0].minimumFee,
                            selectedTech[0].maximumFee
                        ),
                    });
                }

                service.technicianServices
                    .filter(
                        (tech) =>
                            tech.technician.id !=
                            this.props.singleAppt.visitTechnicians[
                            this.props.singleAppt.visitTechnicians.length - 1
                                ].technician.id
                    )
                    .forEach((technician, key) => {
                        const valid = this.checkValid(technician.technician.id);
                        if (valid == "valid") {
                            techServices.push({
                                key: key + 1,
                                id: technician.technician.id,
                                name: technician.technician.user.names.nick,
                                price: technician.displayedPrice,
                                duration: helperFunctions.timeConvert(technician.duration),
                                durationInMinute: technician.durationInMinutes,
                                actualPrice: this.checkPriceType(
                                    technician.minimumFee,
                                    technician.maximumFee
                                ),
                            });
                        }
                    });
            } else {
                service.technicianServices.forEach((technician, key) => {
                    const valid = this.checkValid(technician.technician.id);
                    if (valid == "valid") {
                        techServices.push({
                            key: key,
                            id: technician.technician.id,
                            name: technician.technician.user.names.nick,
                            price: technician.displayedPrice,
                            duration: helperFunctions.timeConvert(technician.duration),
                            durationInMinute: technician.durationInMinutes,
                            actualPrice: this.checkPriceType(
                                technician.minimumFee,
                                technician.maximumFee
                            ),
                        });
                    }
                });
            }

            this.setState({techServices: techServices});
            var techJson = [];
            var i = 0;

            techServices.forEach((tech) => {
                techJson.push({
                    text: tech.name,
                    value: tech.id,
                    selected: false,
                    description: "[" + tech.duration + ", " + tech.price + "]",
                    imageSrc: this.findTechnician(tech.id, "image"),
                });
                i++;
            });

            setTimeout(function () {
                window.initiateTechniciansSelect(
                    techJson,
                    "#technicians-select-div",
                    ".technicians-select"
                );
            }, 100);
        }
    };

    onServiceUpdateSelect = (services) => {
        const servicePrice = `servicePrice-${this.state.singleSVtId}`;
        const serviceName = `serviceName-${this.state.singleSVtId}`;
        const techServices = `techServices-${this.state.singleSVtId}`;
        const serviceId = `serviceId-${this.state.singleSVtId}`;
        const value = `value-${this.state.singleSVtId}`;
        this.setState({
            ...this.state,
            method: "PUT",
            [serviceId]: services.service.id,
        });
        if (services != "") {
            let techServics = [];

            const selectedTech = services.technicianServices.filter(
                (t) =>
                    t.technician.id == this.state[`technician-${this.state.singleSVtId}`]
            );

            if (selectedTech.length > 0) {
                const valid = this.checkValid(selectedTech[0].technician.id);
                if (valid == "valid") {
                    techServics.push({
                        key: 0,
                        id: selectedTech[0].technician.id,
                        name: selectedTech[0].technician.user.names.nick,
                        price: selectedTech[0].displayedPrice,
                        duration: helperFunctions.timeConvert(selectedTech[0].duration),
                        durationInMinute: selectedTech[0].durationInMinutes,
                        actualPrice: this.checkPriceType(
                            selectedTech[0].minimumFee,
                            selectedTech[0].maximumFee
                        ),
                    });
                }

                services.technicianServices
                    .filter(
                        (tech) =>
                            tech.technician.id !=
                            this.state[`technician-${this.state.singleSVtId}`]
                    )
                    .forEach((technician, key) => {
                        const valid = this.checkValid(technician.technician.id);
                        if (valid == "valid") {
                            techServics.push({
                                key: key + 1,
                                id: technician.technician.id,
                                name: technician.technician.user.names.nick,
                                price: technician.displayedPrice,
                                duration: helperFunctions.timeConvert(technician.duration),
                                durationInMinute: technician.durationInMinutes,
                                actualPrice: this.checkPriceType(
                                    technician.minimumFee,
                                    technician.maximumFee
                                ),
                            });
                        }
                    });
            } else {
                services.technicianServices.forEach((technician, key) => {
                    const valid = this.checkValid(technician.technician.id);
                    if (valid == "valid") {
                        techServics.push({
                            key: key,
                            id: technician.technician.id,
                            name: technician.technician.user.names.nick,
                            price: technician.displayedPrice,
                            duration: helperFunctions.timeConvert(technician.duration),
                            durationInMinute: technician.durationInMinutes,
                            actualPrice: this.checkPriceType(
                                technician.minimumFee,
                                technician.maximumFee
                            ),
                        });
                    }
                });
            }

            this.setState({[techServices]: techServics});
            var techJson = [];
            var i = 0;

            techServics.forEach((tech) => {
                techJson.push({
                    text: tech.name,
                    value: tech.id,
                    selected: false,
                    description: "[" + tech.duration + ", " + tech.price + "]",
                    imageSrc: this.findTechnician(tech.id, "image"),
                });
                i++;
            });

            setTimeout(() => {
                window.initiateTechniciansSelect(
                    techJson,
                    `#technicians-select-div-update-${this.state.singleSVtId}`,
                    `.technicians-select-update-${this.state.singleSVtId}`
                );
            }, 100);
        }
    };

    closeThisModal = (id = "", val) => {
        this.setState({
            buttonText: !this.state.buttonText,
            productName: "",
            technician: "",
            productPrice: "",
            qty: 1,
            mode: "",
            modalClass: false,
            pmodalClass: false,
            cmodal: false,
            place: val,
            errors: {},
            editPmodal: false,
        });
    };

    changeTech = (e) => {
        const {name, value} = e.target;
        const durat = this.state.techServices.find((t) => t.id == value);
        var duration = "";
        var price = "";
        if (durat) {
            duration = durat.durationInMinute;
            price = durat.actualPrice;
        }
        this.setState({
            [name]: value,
            durat:
                this.state.startTime != ""
                    ? moment(this.state.startTime).add(duration, "minutes")
                    : moment(this.props.startTime).add(duration, "minutes"),
            servicePrice: price,
        });
    };
    changeUpdateTech = (e) => {
        //console.log('sdsdf',`durat-${this.state.singleSVtId}`)

        const {name, value} = e.target;
        const durat = `durat-${this.state.singleSVtId}`;
        const servicePrice = `servicePrice-${this.state.singleSVtId}`;
        const durats = this.state[`techServices-${this.state.singleSVtId}`].find(
            (t) => t.id == value
        );

        //console.log('sdcsdsdfdfsdfsffs',durats)
        var duration = "";
        var price = "";
        if (durats) {
            duration = durats.durationInMinute;
            price = durats.actualPrice;
        }
        this.setState(
            {
                [name]: value,
                [durat]:
                    this.state[`startTime-${this.state.singleSVtId}`] != ""
                        ? moment(this.state[`startTime-${this.state.singleSVtId}`]).add(
                            duration,
                            "minutes"
                        )
                        : moment(this.props.singleAppt.bookedTime).add(duration, "minutes"),
                [servicePrice]: price,
            },
            () => {
                //console.log(this.state)
            }
        );
    };

    onProductSelect = (product) => {
        if (product.quantity == 0) {
            this.setState({
                ...this.state,
                productPrice: "",
                productId: "",
                productName: "",
            });
        } else {
            this.setState({
                ...this.state,
                productPrice: product.salePrice,
                productId: product.id,
                productName: product.variant.product.name,
                attribute: product.variant.visualAttributeValues[0].value,
                validQty: product.quantity,
                errors: {},
            });
        }
    };

    incrementQty = () => {
        var qty = parseInt(this.state.qty, 10);
        qty++;
        if (qty >= this.state.validQty) {
            this.setState({
                qty: this.state.validQty,
            });
        } else {
            this.setState({
                qty: qty,
            });
        }
    };
    decrementQty = () => {
        var qty = parseInt(this.state.qty, 10);
        if (isNaN(qty)) {
            this.setState({
                qty: 1,
            });
        } else {
            if (qty > 1) {
                this.setState({
                    qty: qty - 1,
                });
            }
        }
    };

    saveProduct = () => {
        var singleProduct = {
            visit: {
                id: this.props.singleAppt.id,
            },
            technician: {
                id: this.state.technician,
            },
            quantity: this.state.qty,
            chargeAmount: this.state.productPrice,
            locatedProductVariant: {
                id: this.state.productId,
            },
        };

        const errors = this.prvalidate(singleProduct);
        this.setState({
            errors,
        });

        if (Object.keys(errors).length == 0) {
            $("#editModal").modal("show");
            $("#addProducts").modal("hide");
            if (this.state.mode == "POST") {
                this.props.saveProduct(singleProduct, "nill");
                this.setState({
                    ...this.state,
                    technician: "",
                    place: "Search a name",
                    qty: 1,
                    productPrice: "",
                    mode: "",
                    purchaseId: "",
                    productId: "",
                    pmodalClass: false,
                    modalClass: false,
                    errors: {},
                    editPmodal: false,
                });
            } else {
                this.props.saveProduct(singleProduct, this.state.purchaseId);
                this.setState({
                    ...this.state,
                    technician: "",
                    place: "",
                    qty: 1,
                    productPrice: "",
                    mode: "",
                    purchaseId: "",
                    productId: "",
                    pmodalClass: false,
                    modalClass: false,
                    errors: {},
                    editPmodal: false,
                });
            }
        }
    };

    prvalidate = (data) => {
        const errors = {};
        if (!data.quantity || data.quantity < 1)
            errors.quantity = "Please enter valid quantity ";
        if (!data.technician.id) errors.technician = "Please select technician!";
        if (!data.chargeAmount) errors.chargeAmount = "Please enter a valid price";
        if (!data.locatedProductVariant.id)
            errors.product = "Please select a product";

        return errors;
    };

    //edit product
    editProduct = (s) => {
        console.log(s)
        this.setState({
            ...this.state,
            technician: s.technician.id,
            place: s.locatedProductVariant.variant.product.name,
            qty: s.quantity,
            productPrice: s.chargeAmount,
            mode: "PUT",
            purchaseId: s.id,
            productId: s.locatedProductVariant.id,
            pmodalClass: true,
            attribute: s.locatedProductVariant.variant.visualAttributeValues[0].value
        });
    };

    removeProduct = (id) => this.props.deleteProduct(id);
    //
    // processServieCheckout = () => {
    //     const checkoutJson = {chargeAmount: this.state.chargeAmount}
    //     if (checkoutJson.chargeAmount < 0 || checkoutJson.chargeAmount > double) {
    //         let errors = this.state.errors;
    //         errors.checkout = 'Please enter valid amount!'
    //         this.setState({
    //             errors: errors
    //         })
    //     } else {
    //         this.props.checkServiceOut(this.state.vt, checkoutJson);
    //         $('#serviceCheckout').modal('toggle')
    //         $('#editModal').modal('toggle')
    //     }
    // }
    processServieCheckout = () => {
        const checkoutJson = {chargeAmount: this.state.chargeAmount};
        this.props.checkServiceOut(this.state.vt, checkoutJson);
        $("#serviceCheckout").modal("toggle");
        $("#editModal").modal("toggle");
    };

    initiaizedProduct = () => {
        // alert("okay")
        $("#editModal").modal("hide");
        this.setState({
            products: !this.state.products,
            mode: "POST",
            pmodalClass: !this.state.pmodalClass,
            place: "",
            editPmodal: true,
        });
    };

    removeEditModal = () => {
        $("body").removeClass("modal-open");
        $(".modal-backdrop.in").remove();
        this.setState({
            editDate: false,
            serviceChargeAmount: 0,
            productPurchaseAmount: 0,
            taxAmount: 0,
            tipAmount: 0,
            giftCardSaleAmount: 0,
            creditPaidAmount: 0,
            cashPaidAmount: 0,
            checkPaidAmount: 0,
            giftCardPaidAmount: 0,
            rewardDeductionAmount: 0,
            discountDeductionAmount: 0,
            popUp: false,
        });
    };

    clearPlace = () => {
        this.setState({
            place: "",
        });
    };

    apptCheckout = (total) => {
        const {singleAppt} = this.props;
        const paymentJSON = {
            serviceChargeAmount: this.state.serviceChargeAmount,
            productPurchaseAmount: this.state.productPurchaseAmount,
            taxAmount: this.state.taxAmount,
            tipAmount: this.state.tipAmount,
            giftCardSaleAmount: this.state.giftCardSaleAmount,
            totalChargeAmount: total,
            creditPaidAmount: this.state.creditPaidAmount,
            cashPaidAmount: this.state.cashPaidAmount,
            checkPaidAmount: this.state.checkPaidAmount,
            giftCardPaidAmount: this.state.giftCardPaidAmount,
            rewardDeductionAmount: this.state.rewardDeductionAmount,
            discountDeductionAmount: this.state.discountDeductionAmount,
        };
        this.props.apptCheckout(singleAppt.id, paymentJSON);
        this.setState({
            serviceChargeAmount: 0,
            productPurchaseAmount: 0,
            taxAmount: 0,
            tipAmount: 0,
            giftCardSaleAmount: 0,
            creditPaidAmount: 0,
            cashPaidAmount: 0,
            checkPaidAmount: 0,
            giftCardPaidAmount: 0,
            rewardDeductionAmount: 0,
            discountDeductionAmount: 0,
        });
    };

    formatAmount = () => {
        const tip = this.state.tipAmount;
        this.setState({
            tipAmount: (tip * 1).toFixed(2),
        });
    };
    formatAmountService = () => {
        const serviceChargeAmount = this.state.serviceChargeAmount;
        this.setState({
            serviceChargeAmount: (serviceChargeAmount * 1).toFixed(2),
        });
    };
    formatgiftCardSaleAmount = () => {
        const giftCardSaleAmount = this.state.giftCardSaleAmount;
        this.setState({
            giftCardSaleAmount: (giftCardSaleAmount * 1).toFixed(2),
        });
    };
    formatproductPurchaseAmount = () => {
        const productPurchaseAmount = this.state.productPurchaseAmount;
        this.setState({
            productPurchaseAmount: (productPurchaseAmount * 1).toFixed(2),
        });
    };
    formatTax = () => {
        const tax = this.state.taxAmount;
        this.setState({
            taxAmount: (tax * 1).toFixed(2),
        });
    };
    formatcreditPaidAmount = () => {
        const creditPaidAmount = this.state.creditPaidAmount;
        this.setState({
            creditPaidAmount: (creditPaidAmount * 1).toFixed(2),
        });
    };
    formatcashPaidAmount = () => {
        const cashPaidAmount = this.state.cashPaidAmount;
        this.setState({
            cashPaidAmount: (cashPaidAmount * 1).toFixed(2),
        });
    };
    formatcheckPaidAmount = () => {
        const checkPaidAmount = this.state.checkPaidAmount;
        this.setState({
            checkPaidAmount: (checkPaidAmount * 1).toFixed(2),
        });
    };
    formatgiftCardPaidAmount = () => {
        const giftCardPaidAmount = this.state.giftCardPaidAmount;
        this.setState({
            giftCardPaidAmount: (giftCardPaidAmount * 1).toFixed(2),
        });
    };
    formatrewardDeductionAmount = () => {
        const rewardDeductionAmount = this.state.rewardDeductionAmount;
        this.setState({
            rewardDeductionAmount: (rewardDeductionAmount * 1).toFixed(2),
        });
    };
    formatdiscountDeductionAmount = () => {
        const discountDeductionAmount = this.state.discountDeductionAmount;
        this.setState({
            discountDeductionAmount: (discountDeductionAmount * 1).toFixed(2),
        });
    };

    formatChargeAmount = () => {
        const chargeAmount = this.state.chargeAmount;
        this.setState({
            chargeAmount: (chargeAmount * 1).toFixed(2),
        });
    };

    focusValue = (e) => {
        e.target.setSelectionRange(0, e.target.value.length);
    };

    // componentDidUpdate(prevProps) {
    //
    //     console.log(prevProps.singleAppt.staffNotes)
    //     console.log(this.props.singleAppt.staffNotes)
    //
    //     if (prevProps.singleAppt.staffNotes !== this.props.singleAppt.staffNotes) {
    //         this.setState({ staffNotes: this.props.singleAppt.staffNotes });
    //     }
    // }

    onProductScan = (product) => {
        if (product.length != 0) {
            this.setState({
                selectedProduct: {
                    name: product[0].variant.product.name,
                    id: product[0].id,
                },
                productPrice: product[0].salePrice,
                validQty: product[0].quantity,
                productName: product[0].variant.product.name,
                productId: product[0].id,
                place: product[0].variant.product.name,
                attribute: product[0].variant.visualAttributeValues[0].value,
                errors: {},
            });
        } else {
            this.setState({
                errors: {
                    ...this.state.errors,
                    product: "Product Not Found!",
                },
            });
        }
    };

    clearScanner = () => {
        this.setState({
            productName: "",
        });
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.singleAppt) {
            this.setState({
                singleAppt: nextProps.singleAppt,
                serviceChargeAmount: nextProps.singleAppt.serviceChargeAmount,
                productPurchaseAmount: nextProps.singleAppt.productPurchaseAmount,
                creditPaidAmount: nextProps.singleAppt.creditPaidAmount,
                cashPaidAmount: nextProps.singleAppt.cashPaidAmount,
                checkPaidAmount: nextProps.singleAppt.checkPaidAmount,
                giftCardPaidAmount: nextProps.singleAppt.giftCardPaidAmount,
                rewardDeductionAmount: nextProps.singleAppt.rewardDeductionAmount,
                discountDeductionAmount: nextProps.singleAppt.discountDeductionAmount,
                tipAmount: nextProps.singleAppt.tipAmount,
                taxAmount: nextProps.singleAppt.taxAmount,
                giftCardSaleAmount: nextProps.singleAppt.giftCardSaleAmount,
                totalChargeAmount: nextProps.singleAppt.totalChargeAmount,
            });
        }
    }

    // static getDerivedStateFromProps(nextProps, prevState) {
    //
    //     if(nextProps.singleAppt) {
    //         return {
    //
    //             singleAppt: nextProps.singleAppt
    //         }
    //     }
    // }
    render() {
        const {singleAppt} = this.props;
        const {services, searchQuery, selectedOption, techServices, errors} =
            this.state;

        return (
            <React.Fragment>
                <div
                    data-backdrop="static"
                    data-keyboard="false"
                    className="modal fade"
                    id="editModal"
                >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    data-backdrop="false"
                                    onClick={this.removeEditModal}
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                                <h2 className="modal-title" id="cancelLabelModel">
                                    Edit Appointment
                                </h2>
                            </div>

                            <React.Fragment>
                                <div className="modal-body" style={{height: "600px"}}>
                                    <NotePopUp
                                        isVisible={this.state.popUp}
                                        close={() =>
                                            this.setState({popUp: false, staffNotes: ""})
                                        }
                                        handler={this.changeStaffNotes}
                                        staffNotes={
                                            this.state.staffNotes
                                                ? this.state.staffNotes
                                                : singleAppt.staffNotes
                                        }
                                        customerNotes={
                                            singleAppt &&
                                            singleAppt.client &&
                                            singleAppt.client.staffNotes
                                                ? singleAppt.client.staffNotes
                                                : "NONE"
                                        }
                                        updatable={true}
                                        onUpdate={this.updateStaffNotes}
                                        btnClick={() =>
                                            this.setState({
                                                popUp: !this.state.popUp,
                                                staffNotes: "",
                                            })
                                        }
                                    />
                                    <div className="client-info">
                                        <div className="info-header">
                                            <div className="client">
                                                <div className="chat-image">
                                                    <img
                                                        src={
                                                            singleAppt &&
                                                            singleAppt != "" &&
                                                            Object.keys(singleAppt).length > 0 &&
                                                            singleAppt.client
                                                                ? singleAppt.client.imageUrl
                                                                : null
                                                        }
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="chat-body">
                                                    <div className="chat-text">
                                                        <h3>
                                                            <strong>
                                                                {singleAppt &&
                                                                    singleAppt != "" &&
                                                                    Object.keys(singleAppt).length > 0 &&
                                                                    singleAppt.client.fullName}
                                                            </strong>
                                                        </h3>
                                                        {this.loggedUser.technicianResponsibilities.length > 0 && this.loggedUser.technicianResponsibilities[0].customerAccessAllowed === false ? null :
                                                            <p>
                                                                <i className="fa fa-phone"></i>{" "}
                                                                {singleAppt &&
                                                                    singleAppt != "" &&
                                                                    Object.keys(singleAppt).length > 0 &&
                                                                    helperFunctions.formatMobilePhone(
                                                                        singleAppt.client.mobileNumber
                                                                    )}
                                                            </p>}
                                                        <p>
                                                            <i className="fa fa-dollar"></i>{" "}
                                                            {singleAppt &&
                                                            singleAppt != "" &&
                                                            Object.keys(singleAppt).length > 0 &&
                                                            singleAppt.client.rewardBalance
                                                                ? (singleAppt.client.rewardBalance * 1).toFixed(
                                                                    2
                                                                )
                                                                : "0.00"}
                                                        </p>
                                                        <div className="appt-time">
                                                            <p>
                                                                <i className="fa fa-calendar"></i>

                                                                {singleAppt &&
                                                                    singleAppt != "" &&
                                                                    Object.keys(singleAppt).length > 0 &&
                                                                    helperFunctions.formatDateTimeWithDay(
                                                                        moment(singleAppt.bookedTime),
                                                                        "latest"
                                                                    )}

                                                                <button
                                                                    onClick={() =>
                                                                        this.editedBookingTime(
                                                                            singleAppt.bookedTime
                                                                        )
                                                                    }
                                                                    className="edittime"
                                                                >
                                                                    {this.state.editDate == false ? (
                                                                        <i className="fa fa-pencil text-info"></i>
                                                                    ) : (
                                                                        <i className="fa fa-remove text-danger"></i>
                                                                    )}
                                                                </button>
                                                            </p>

                                                            {this.state.editDate && (
                                                                <div className="editDate-datePicker">
                                                                    <DatetimePickerTrigger
                                                                        moment={this.state.apptBookedTime}
                                                                        onChange={this.changeBookedTime}
                                                                    >
                                                                        <input
                                                                            type="text"
                                                                            value={
                                                                                this.state.apptBookedTime.format(
                                                                                    "YYYY-MM-DD"
                                                                                ) +
                                                                                " " +
                                                                                helperFunctions.formatDateTimeWithDay(
                                                                                    this.state.apptBookedTime,
                                                                                    "time"
                                                                                )
                                                                            }
                                                                            readOnly
                                                                        />
                                                                    </DatetimePickerTrigger>
                                                                    <button
                                                                        onClick={this.updateAppointmentTime}
                                                                        className="btn btn-custom-info pull-right"
                                                                    >
                                                                        Save
                                                                    </button>
                                                                </div>
                                                            )}

                                                            {singleAppt &&
                                                            singleAppt != "" &&
                                                            Object.keys(singleAppt).length > 0 &&
                                                            singleAppt.confirmationTime ? (
                                                                <label className="label label-info">
                                                                    Confirmed
                                                                </label>
                                                            ) : (
                                                                <button
                                                                    onClick={this.confirmAppt}
                                                                    className="btn btn-info confirm"
                                                                >
                                                                    Confirm
                                                                </button>
                                                            )}
                                                        </div>
                                                        <sub className="sub-time">
                                                            Booked on:{" "}
                                                            {singleAppt &&
                                                                singleAppt != "" &&
                                                                Object.keys(singleAppt).length > 0 &&
                                                                helperFunctions.formatDateTimeWithDay(
                                                                    moment(singleAppt.createdTime),
                                                                    "latest"
                                                                )}{" "}
                                                        </sub>

                                                        <p>
                                                            <i className="fa fa-mobile"></i> Source:{" "}
                                                            <i
                                                                className={
                                                                    singleAppt &&
                                                                    singleAppt != "" &&
                                                                    Object.keys(singleAppt).length > 0
                                                                        ? this.getRequestSource(
                                                                            singleAppt.requestSource
                                                                        )
                                                                        : null
                                                                }
                                                            ></i>
                                                        </p>
                                                    </div>
                                                    <div className="confirm-btn-group">
                                                        {singleAppt.status == "Booked" && (
                                                            <div className="confirm-btn">
                                                                <button
                                                                    data-dismiss="modal"
                                                                    data-toggle="modal"
                                                                    data-target="#addServices"
                                                                    onClick={() =>
                                                                        this.initializeAddServiceForm()
                                                                    }
                                                                    className={
                                                                        !this.state.buttonText
                                                                            ? "button-custom"
                                                                            : "actve"
                                                                    }
                                                                >
                                                                    Add Service
                                                                </button>
                                                                {this.app != null &&
                                                                    this.app.locations[0].brands.length > 0 && (
                                                                        <button
                                                                            data-toggle="modal"
                                                                            data-target="#addProducts"
                                                                            data-dismiss="modal"
                                                                            onClick={this.initiaizedProduct}
                                                                            className={
                                                                                this.state.products
                                                                                    ? "actve"
                                                                                    : "button-custom"
                                                                            }
                                                                        >
                                                                            Add Product
                                                                        </button>
                                                                    )}
                                                            </div>
                                                        )}
                                                        {singleAppt.status == "Checked In" && (
                                                            <div className="confirm-btn">
                                                                <button
                                                                    data-dismiss="modal"
                                                                    data-toggle="modal"
                                                                    data-target="#addServices"
                                                                    onClick={() =>
                                                                        this.initializeAddServiceForm()
                                                                    }
                                                                    className={
                                                                        !this.state.buttonText
                                                                            ? "button-custom"
                                                                            : "actve"
                                                                    }
                                                                >
                                                                    Add Service
                                                                </button>
                                                                {this.app != null &&
                                                                    this.app.locations[0].brands.length > 0 && (
                                                                        <button
                                                                            data-toggle="modal"
                                                                            data-target="#addProducts"
                                                                            data-dismiss="modal"
                                                                            onClick={() =>
                                                                                this.setState({
                                                                                    products: !this.state.products,
                                                                                    mode: "POST",
                                                                                    pmodalClass: !this.state.pmodalClass,
                                                                                })
                                                                            }
                                                                            className={
                                                                                this.state.products
                                                                                    ? "actve"
                                                                                    : "button-custom"
                                                                            }
                                                                        >
                                                                            Add Product
                                                                        </button>
                                                                    )}
                                                            </div>
                                                        )}

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="past-appointments">
                                            <div className="steamline">
                                                {singleAppt &&
                                                    singleAppt != "" &&
                                                    Object.keys(singleAppt).length > 0 &&
                                                    singleAppt.visitTechnicians
                                                        .filter((t) => t.status != "Cancelled")
                                                        .map((s, key) => {
                                                            return (
                                                                <div key={key} className="sl-item">
                                                                    <div className="sl-left">
                                                                        <img
                                                                            src={this.findTechnician(
                                                                                s.technician.id,
                                                                                "image"
                                                                            )}
                                                                            alt="user"
                                                                            className="img-circle"
                                                                        />
                                                                    </div>
                                                                    <div className="sl-right">
                                                                        <div className="m-l-40">
                                                                            <p>
                                                                                <b>
                                                                                    {this.findServiceName(
                                                                                        s.offeredService.id,
                                                                                        s.offeredService.category.id
                                                                                    )}
                                                                                </b>{" "}
                                                                                with{" "}
                                                                                <b>
                                                                                    {this.findTechnician(
                                                                                        s.technician.id,
                                                                                        "nick"
                                                                                    )}
                                                                                </b>
                                                                                <br/>
                                                                                <b>Time: </b>
                                                                                {helperFunctions.formatDateTime(
                                                                                    s.expectedStartTime,
                                                                                    "time"
                                                                                )}{" "}
                                                                                -{" "}
                                                                                {helperFunctions.formatDateTime(
                                                                                    s.period.to,
                                                                                    "time"
                                                                                )}
                                                                                <br/>
                                                                                <b>Price: </b>
                                                                                {s.chargeAmount != "-1"
                                                                                    ? "$" +
                                                                                    (s.chargeAmount * 1).toFixed(2)
                                                                                    : "Per Consult"}
                                                                                <br/>
                                                                                {s.technicianRequested != undefined && (
                                                                                    <b>
                                                                                        Stylist Preference:{" "}
                                                                                        {s.technicianRequested == true ? (
                                                                                            <strong
                                                                                                className="text-primary">
                                                                                                YES
                                                                                            </strong>
                                                                                        ) : (
                                                                                            <strong
                                                                                                className="text-warning">
                                                                                                NO
                                                                                            </strong>
                                                                                        )}
                                                                                    </b>
                                                                                )}
                                                                            </p>
                                                                            <p className="pb35">
                                        <span className="pull-right">
                                          {s.status == "Booked" && (
                                              <button
                                                  onClick={() =>
                                                      this.openUpdateServiceForm(s)
                                                  }
                                                  className="btn m-t-10 m-r-5 btn-custom-info"
                                                  href="javascript:void(0)"
                                              >
                                                  Edit
                                              </button>
                                          )}
                                            {s.status == "Booked" &&
                                                this.props.singleAppt
                                                    .checkInOutAllowed == true && (
                                                    <button
                                                        onClick={() =>
                                                            this.checkServiceIn(s.id)
                                                        }
                                                        data-toggle="modal"
                                                        className="btn m-t-10 m-r-5 btn-info"
                                                    >
                                                        Check In
                                                    </button>
                                                )}
                                            {s.status == "Checked In" && (
                                                <button
                                                    onClick={() =>
                                                        this.openUpdateServiceForm(s)
                                                    }
                                                    data-target="#updateAppointment"
                                                    data-toggle="modal"
                                                    className="btn m-t-10 m-r-5 btn-custom-info"
                                                    href="javascript:void(0)"
                                                >
                                                    Edit
                                                </button>
                                            )}
                                            {s.status == "Checked In" && (
                                                <button className="btn m-t-10 m-r-5 btn-warning">
                                                    <i className="fa fa-check-square-o"></i>
                                                    {s.period.from &&
                                                        helperFunctions.formatDateTime(
                                                            s.period.from,
                                                            "time"
                                                        )}
                                                </button>
                                            )}

                                            {s.status == "Booked" &&
                                                this.props.singleAppt
                                                    .checkInOutAllowed == true && (
                                                    <button
                                                        data-toggle="modal"
                                                        data-target="#serviceCheckout"
                                                        data-dismiss="modal"
                                                        onClick={() =>
                                                            this.checkServiceOut(
                                                                s.id,
                                                                s.chargeAmount
                                                            )
                                                        }
                                                        className="btn m-t-10 m-r-5 btn-success"
                                                    >
                                                        Check Out
                                                    </button>
                                                )}
                                            {s.status == "Checked In" &&
                                                this.props.singleAppt
                                                    .checkInOutAllowed == true && (
                                                    <button
                                                        data-toggle="modal"
                                                        data-target="#serviceCheckout"
                                                        data-dismiss="modal"
                                                        onClick={() =>
                                                            this.checkServiceOut(
                                                                s.id,
                                                                s.chargeAmount
                                                            )
                                                        }
                                                        className="btn m-t-10 m-r-5 btn-success"
                                                    >
                                                        Check Out
                                                    </button>
                                                )}

                                            {s.status == "Booked" && (
                                                <button
                                                    onClick={() =>
                                                        this.openCancelModal(
                                                            singleAppt.visitTechnicians[
                                                                key
                                                                ]
                                                        )
                                                    }
                                                    data-dismiss="modal"
                                                    className="btn m-t-10 m-r-5 btn-danger"
                                                >
                                                    Cancel
                                                </button>
                                            )}

                                            {s.status == "Checked In" && (
                                                <button
                                                    onClick={() =>
                                                        this.openCancelModal(
                                                            singleAppt.visitTechnicians[
                                                                key
                                                                ]
                                                        )
                                                    }
                                                    data-target="#cancelReason"
                                                    data-toggle="modal"
                                                    data-dismiss="modal"
                                                    className="btn m-t-10 m-r-5 btn-danger"
                                                >
                                                    Cancel
                                                </button>
                                            )}

                                            {s.status == "Checked Out" && (
                                                <button className="btn m-t-10 m-r-5 btn-warning">
                                                    <i className="fa fa-check-square-o"></i>
                                                    {s.period.from &&
                                                        helperFunctions.formatDateTime(
                                                            s.period.from,
                                                            "time"
                                                        )}
                                                </button>
                                            )}

                                            {s.status == "Checked Out" && (
                                                <button className="btn m-t-10 m-r-5 btn-info">
                                                    <i className="fa fa-check-square-o"></i>
                                                    {s.period.to
                                                        ? helperFunctions.formatDateTime(
                                                            s.period.to,
                                                            "time"
                                                        )
                                                        : this.getCurrentTime()}
                                                </button>
                                            )}
                                            {s.status == "Cancelled" && (
                                                <button className="btn m-t-10 m-r-5 btn-danger">
                                                    &times; Canceled
                                                </button>
                                            )}
                                            {s.status == "No Show" && (
                                                <button className="btn m-t-10 m-r-5 btn-danger">
                                                    <i className="fa fa-eye"></i>
                                                    No Show
                                                </button>
                                            )}
                                        </span>
                                                                            </p>
                                                                        </div>
                                                                    </div>

                                                                    {this.state[`service-${s.id}`] == true && (
                                                                        <div className="row">
                                                                            <div className="update-form">
                                                                                <button
                                                                                    onClick={() =>
                                                                                        this.closeUpdateForm(s.id)
                                                                                    }
                                                                                    className="closBtn"
                                                                                >
                                                                                    &times;
                                                                                </button>
                                                                                <div className="col-md-12">
                                                                                    <form
                                                                                        onSubmit={this.updateSaveService}
                                                                                    >
                                                                                        <div className="row">
                                                                                            <div className="col-md-6">
                                                                                                <div
                                                                                                    className="form-group select-arrow">
                                                                                                    <label
                                                                                                        htmlFor="stylish">
                                                                                                        Service &nbsp;
                                                                                                        <span
                                                                                                            className="text-danger">
                                                      *
                                                    </span>
                                                                                                    </label>
                                                                                                    <ServiceSearchBar
                                                                                                        id="service"
                                                                                                        mode="update"
                                                                                                        onServiceSelect={
                                                                                                            this.onServiceUpdateSelect
                                                                                                        }
                                                                                                        clearPlace={this.clearPlace}
                                                                                                        place={
                                                                                                            this.state[
                                                                                                                `value-${s.id}`
                                                                                                                ] &&
                                                                                                            this.state[
                                                                                                                `value-${s.id}`
                                                                                                                ] != "" &&
                                                                                                            this.state[
                                                                                                                `value-${s.id}`
                                                                                                                ]
                                                                                                        }
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-md-6">
                                                                                                {this.state[
                                                                                                    `techServices-${s.id}`
                                                                                                    ].length !== 0 && (
                                                                                                    <div
                                                                                                        className="form-group add-services">
                                                                                                        <label>Select
                                                                                                            Staff</label>
                                                                                                        <select
                                                                                                            className={`hidden technicians-select-update-${s.id}`}
                                                                                                            name={`technician-${s.id}`}
                                                                                                            value={
                                                                                                                this.state[
                                                                                                                    `technician-${s.id}`
                                                                                                                    ]
                                                                                                            }
                                                                                                            onChange={
                                                                                                                this.changeUpdateTech
                                                                                                            }
                                                                                                        >
                                                                                                            {this.state[
                                                                                                                `techServices-${s.id}`
                                                                                                                ].map((e, key) => {
                                                                                                                return (
                                                                                                                    <option
                                                                                                                        key={e.id}
                                                                                                                        value={e.id}
                                                                                                                    >
                                                                                                                        {e.name}
                                                                                                                    </option>
                                                                                                                );
                                                                                                            })}
                                                                                                        </select>
                                                                                                        <div
                                                                                                            id={`technicians-select-div-update-${s.id}`}
                                                                                                            className="add-services"
                                                                                                        ></div>
                                                                                                    </div>
                                                                                                )}
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="row">
                                                                                            <div className="col-md-6">
                                                                                                <div
                                                                                                    className="form-group">
                                                                                                    <label
                                                                                                        htmlFor="endTime">
                                                                                                        Start
                                                                                                        Time &nbsp;{" "}
                                                                                                        <span
                                                                                                            className="text-danger">
                                                      *
                                                    </span>
                                                                                                    </label>
                                                                                                    <div
                                                                                                        className="time-clock">
                                                                                                        <CustomTimePicker
                                                                                                            value={
                                                                                                                this.state[
                                                                                                                    `startTime-${s.id}`
                                                                                                                    ] != ""
                                                                                                                    ? moment(
                                                                                                                        this.state[
                                                                                                                            `startTime-${s.id}`
                                                                                                                            ]
                                                                                                                    ).format(format)
                                                                                                                    : moment(
                                                                                                                        singleAppt.bookedTime
                                                                                                                    ).format(format)
                                                                                                            }
                                                                                                            onChange={
                                                                                                                this
                                                                                                                    .changeUpdateStartTime
                                                                                                            }
                                                                                                            format={format}
                                                                                                            name={`startTime-${s.id}`}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="col-md-6">
                                                                                                <div
                                                                                                    className="form-group">
                                                                                                    <label
                                                                                                        htmlFor="endTime">
                                                                                                        End
                                                                                                        Time &nbsp;{" "}
                                                                                                        <span
                                                                                                            className="text-danger">
                                                      *
                                                    </span>
                                                                                                    </label>
                                                                                                    <div
                                                                                                        className="time-clock">
                                                                                                        <CustomTimePicker
                                                                                                            value={
                                                                                                                this.state[
                                                                                                                    `durat-${s.id}`
                                                                                                                    ] &&
                                                                                                                this.state[
                                                                                                                    `durat-${s.id}`
                                                                                                                    ] != ""
                                                                                                                    ? this.state[
                                                                                                                        `durat-${s.id}`
                                                                                                                        ].format(format)
                                                                                                                    : moment(
                                                                                                                        s.period.to
                                                                                                                    ).format(format)
                                                                                                            }
                                                                                                            name={`durat-${s.id}`}
                                                                                                            format={format}
                                                                                                            onChange={
                                                                                                                this.changeUpdateEndTime
                                                                                                            }
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                                {errors.timeDifference && (
                                                                                                    <p className="error">
                                                                                                        <InlineMessage
                                                                                                            text={
                                                                                                                errors.timeDifference
                                                                                                            }
                                                                                                        />
                                                                                                    </p>
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                            <div className="col-md-6">
                                                                                                <label>
                                                                                                    Selected Stylist
                                                                                                    Preferred?
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                className="col-md-6 text-right">
                                                                                                <div
                                                                                                    className="flex align-middle">
                                                                                                    <label
                                                                                                        htmlFor="technicianRequested">
                                                                                                        Yes &nbsp;
                                                                                                    </label>
                                                                                                    <label
                                                                                                        className="switch rotate">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            checked={
                                                                                                                this.state[
                                                                                                                    `technicianRequested-${s.id}`
                                                                                                                    ] &&
                                                                                                                this.state[
                                                                                                                    `technicianRequested-${s.id}`
                                                                                                                    ]
                                                                                                            }
                                                                                                            name="technicianRequested"
                                                                                                            readOnly
                                                                                                            onClick={
                                                                                                                this
                                                                                                                    .handleChangeRequested
                                                                                                            }
                                                                                                        />
                                                                                                        <span
                                                                                                            className="switchSlider"></span>
                                                                                                    </label>
                                                                                                    <label
                                                                                                        htmlFor="technicianRequested">
                                                                                                        {" "}
                                                                                                        &nbsp; No
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div
                                                                                            className="form-group text-right">
                                                                                            <button
                                                                                                className="btn btn-custom-info">
                                                                                                Update service
                                                                                            </button>
                                                                                        </div>
                                                                                    </form>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            );
                                                        })}
                                            </div>
                                            {singleAppt &&
                                                singleAppt != "" &&
                                                Object.keys(singleAppt).length > 0 &&
                                                singleAppt.purchaseItems.length > 0 && (
                                                    <div className="table-responsive table-product">
                                                        <table className="table table-striped">
                                                            <thead>
                                                            <tr>
                                                                <th>Tech</th>
                                                                <th>Name</th>
                                                                <th>Qty</th>
                                                                <th>Price</th>
                                                                <th className="text-center">Action</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {singleAppt.purchaseItems.map((s, k) => {
                                                                return (
                                                                    <tr key={k}>
                                                                        <td>
                                                                            <img
                                                                                width="30"
                                                                                className="img-circle"
                                                                                src={this.findTechnician(
                                                                                    s.technician.id,
                                                                                    "image"
                                                                                )}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                s.locatedProductVariant.variant
                                                                                    .product.name
                                                                            }
                                                                        </td>
                                                                        <td>{s.quantity}</td>
                                                                        <td>{s.chargeAmount}</td>
                                                                        <td align="center">
                                                                            <button
                                                                                data-target="#addProducts"
                                                                                data-toggle="modal"
                                                                                data-dismiss="modal"
                                                                                onClick={() => this.editProduct(s)}
                                                                                className="cross-btn"
                                                                            >
                                                                                <i className="fa fa-pencil text-info"></i>
                                                                            </button>
                                                                            <button
                                                                                onClick={() =>
                                                                                    this.removeProduct(s.id)
                                                                                }
                                                                                className="cross-btn"
                                                                            >
                                                                                <i className="fa fa-trash text-danger"></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )}
                                        </div>

                                        <div>
                                            <p className="text-primary">
                                                <b>Created By</b>:{" "}
                                                {singleAppt.createUser &&
                                                    singleAppt.createUser.fullName}{" "}
                                                on{" "}
                                                <span style={{color: "#666"}}>
                          {singleAppt.createdTime &&
                              helperFunctions.formatDateTimeWithDay(
                                  moment(singleAppt.createdTime),
                                  "latest"
                              )}
                        </span>
                                            </p>
                                            {singleAppt.lastUpdateUser && (
                                                <p className="text-primary">
                                                    <b>Updated By</b>:{" "}
                                                    {singleAppt.lastUpdateUser &&
                                                        singleAppt.lastUpdateUser.fullName}{" "}
                                                    on{" "}
                                                    <span style={{color: "#666"}}>
                            {singleAppt.updatedTime &&
                                helperFunctions.formatDateTimeWithDay(
                                    moment(singleAppt.updatedTime),
                                    "latest"
                                )}
                          </span>
                                                </p>
                                            )}
                                        </div>

                                        <div className="botom-info">
                                            <div>
                                                <button className="checkout-summary note btn btn-default">
                                                    Summary &nbsp;&nbsp;{" "}
                                                    <i className="fa fa-angle-down"></i>
                                                </button>
                                                {singleAppt && (
                                                    <div
                                                        className={
                                                            this.props.checkOut == true
                                                                ? "hidden-checkout visible"
                                                                : "hidden-checkout"
                                                        }
                                                    >
                                                        <div className="row">
                                                            <div>
                                                                <div className="check-out-info">
                                                                    <div className="expense col-md-5">
                                                                        <ul className="list-group">
                                                                            <li className="list-group-item">
                                                                                <b>
                                                                                    Costs:{" "}
                                                                                    <span className="text-warning cost">
                                            {this.state.productPurchaseAmount ==
                                            0 &&
                                            this.state.serviceChargeAmount ==
                                            0 &&
                                            this.state.tipAmount == 0 &&
                                            this.state.taxAmount == 0 &&
                                            this.state.giftCardSaleAmount ==
                                            0 &&
                                            singleAppt.serviceChargeAmount ==
                                            "-1"
                                                ? "Per Consult"
                                                : singleAppt &&
                                                "$" +
                                                (singleAppt.totalChargeAmount !=
                                                "-1"
                                                    ? (
                                                        this.state
                                                            .productPurchaseAmount *
                                                        1 +
                                                        this.state
                                                            .serviceChargeAmount *
                                                        1 +
                                                        this.state.tipAmount *
                                                        1 +
                                                        this.state.taxAmount *
                                                        1 +
                                                        this.state
                                                            .giftCardSaleAmount *
                                                        1
                                                    ).toFixed(2)
                                                    : 0)}{" "}
                                          </span>
                                                                                </b>
                                                                            </li>
                                                                            <li className="list-group-item">
                                                                                <p className="title-fade">
                                                                                    Service Charge Amount &nbsp;{" "}
                                                                                    <span
                                                                                        className="text-danger">*</span>
                                                                                </p>
                                                                                <p className="title-amount">
                                                                                    <input
                                                                                        type="text"
                                                                                        maxLength="9"
                                                                                        onChange={this.changeHandler}
                                                                                        name="serviceChargeAmount"
                                                                                        onClick={this.focusValue}
                                                                                        onBlur={this.formatAmountService}
                                                                                        value={
                                                                                            this.state.serviceChargeAmount
                                                                                        }
                                                                                    />
                                                                                </p>
                                                                            </li>
                                                                            <li className="list-group-item">
                                                                                <p className="title-fade">
                                                                                    Products Purchase Amount &nbsp;{" "}
                                                                                    <span
                                                                                        className="text-danger">*</span>
                                                                                </p>
                                                                                <p className="title-amount">
                                                                                    <input
                                                                                        type="text"
                                                                                        maxLength="9"
                                                                                        onChange={this.changeHandler}
                                                                                        name="productPurchaseAmount"
                                                                                        onBlur={
                                                                                            this.formatproductPurchaseAmount
                                                                                        }
                                                                                        value={
                                                                                            this.state.productPurchaseAmount
                                                                                        }
                                                                                    />
                                                                                </p>
                                                                            </li>
                                                                            <li className="list-group-item">
                                                                                <p className="title-fade">
                                                                                    Gift Card Sale Amount &nbsp;{" "}
                                                                                    <span
                                                                                        className="text-danger">*</span>
                                                                                </p>
                                                                                <p className="title-amount">
                                                                                    <input
                                                                                        type="text"
                                                                                        maxLength="9"
                                                                                        onChange={this.changeHandler}
                                                                                        name="giftCardSaleAmount"
                                                                                        onBlur={
                                                                                            this.formatgiftCardSaleAmount
                                                                                        }
                                                                                        value={
                                                                                            this.state.giftCardSaleAmount
                                                                                        }
                                                                                    />
                                                                                </p>
                                                                            </li>

                                                                            <li className="list-group-item">
                                                                                <p className="title-fade">
                                                                                    Tip Amount &nbsp;
                                                                                    <span
                                                                                        className="text-danger">*</span>
                                                                                </p>
                                                                                <p className="title-amount">
                                                                                    <input
                                                                                        type="text"
                                                                                        name="tipAmount"
                                                                                        maxLength="9"
                                                                                        onChange={this.changeHandler}
                                                                                        onBlur={this.formatAmount}
                                                                                        value={this.state.tipAmount}
                                                                                    />
                                                                                </p>
                                                                            </li>
                                                                            <li className="list-group-item">
                                                                                <p className="title-fade">
                                                                                    Tax Amount &nbsp;
                                                                                    <span
                                                                                        className="text-danger">*</span>
                                                                                </p>
                                                                                <p className="title-amount">
                                                                                    <input
                                                                                        type="text"
                                                                                        maxLength="9"
                                                                                        name="taxAmount"
                                                                                        onChange={this.changeHandler}
                                                                                        onBlur={this.formatTax}
                                                                                        value={this.state.taxAmount}
                                                                                    />
                                                                                </p>
                                                                            </li>
                                                                            <li className="list-group-item">
                                                                                <p className="title-fade">
                                                                                    <b>Total Amount</b>
                                                                                </p>
                                                                                <p className="title-amount">
                                                                                    <input
                                                                                        readOnly
                                                                                        type="text"
                                                                                        maxLength="9"
                                                                                        onChange={this.changeHandler}
                                                                                        name="totalChargeAmount"
                                                                                        value={
                                                                                            this.state
                                                                                                .productPurchaseAmount == 0 &&
                                                                                            this.state.serviceChargeAmount ==
                                                                                            0 &&
                                                                                            this.state.tipAmount == 0 &&
                                                                                            this.state.taxAmount == 0 &&
                                                                                            this.state.giftCardSaleAmount ==
                                                                                            0 &&
                                                                                            singleAppt.serviceChargeAmount ==
                                                                                            "-1"
                                                                                                ? "Per Consult"
                                                                                                : singleAppt &&
                                                                                                "$" +
                                                                                                (singleAppt.totalChargeAmount !=
                                                                                                "-1"
                                                                                                    ? (
                                                                                                        this.state
                                                                                                            .productPurchaseAmount *
                                                                                                        1 +
                                                                                                        this.state
                                                                                                            .serviceChargeAmount *
                                                                                                        1 +
                                                                                                        this.state.tipAmount *
                                                                                                        1 +
                                                                                                        this.state.taxAmount *
                                                                                                        1 +
                                                                                                        this.state
                                                                                                            .giftCardSaleAmount *
                                                                                                        1
                                                                                                    ).toFixed(2)
                                                                                                    : 0)
                                                                                        }
                                                                                    />
                                                                                </p>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="expense col-md-5 col-md-offset-1">
                                                                        <ul className="list-group">
                                                                            <li className="list-group-item">
                                                                                <b>
                                                                                    Payments:{" "}
                                                                                    <span className="text-custom-info">
                                            (${" "}
                                                                                        {(
                                                                                            this.state.creditPaidAmount * 1 +
                                                                                            this.state.cashPaidAmount * 1 +
                                                                                            this.state.checkPaidAmount * 1 +
                                                                                            this.state.rewardDeductionAmount *
                                                                                            1 +
                                                                                            this.state.giftCardPaidAmount *
                                                                                            1 +
                                                                                            this.state
                                                                                                .discountDeductionAmount *
                                                                                            1
                                                                                        ).toFixed(2)}
                                                                                        )
                                          </span>
                                                                                </b>
                                                                            </li>
                                                                            <li className="list-group-item">
                                                                                <p className="title-fade">
                                                                                    Credit Paid Amount &nbsp;{" "}
                                                                                    <span
                                                                                        className="text-danger">*</span>
                                                                                </p>
                                                                                <p className="title-amount">
                                                                                    <input
                                                                                        type="text"
                                                                                        maxLength="9"
                                                                                        name="creditPaidAmount"
                                                                                        onChange={this.changeHandler}
                                                                                        onBlur={this.formatcreditPaidAmount}
                                                                                        value={this.state.creditPaidAmount}
                                                                                    />
                                                                                </p>
                                                                            </li>
                                                                            <li className="list-group-item">
                                                                                <p className="title-fade">
                                                                                    Cash Paid Amount &nbsp;{" "}
                                                                                    <span
                                                                                        className="text-danger">*</span>
                                                                                </p>
                                                                                <p className="title-amount">
                                                                                    <input
                                                                                        type="text"
                                                                                        maxLength="9"
                                                                                        name="cashPaidAmount"
                                                                                        onChange={this.changeHandler}
                                                                                        onBlur={this.formatcashPaidAmount}
                                                                                        value={this.state.cashPaidAmount}
                                                                                    />
                                                                                </p>
                                                                            </li>

                                                                            <li className="list-group-item">
                                                                                <p className="title-fade">
                                                                                    Check Paid Amount &nbsp;{" "}
                                                                                    <span
                                                                                        className="text-danger">*</span>
                                                                                </p>
                                                                                <p className="title-amount">
                                                                                    <input
                                                                                        name="checkPaidAmount"
                                                                                        type="text"
                                                                                        maxLength="9"
                                                                                        onChange={this.changeHandler}
                                                                                        onBlur={this.formatcheckPaidAmount}
                                                                                        value={this.state.checkPaidAmount}
                                                                                    />
                                                                                </p>
                                                                            </li>
                                                                            <li className="list-group-item">
                                                                                <p className="title-fade">
                                                                                    Gift Card Paid Amount &nbsp;{" "}
                                                                                    <span
                                                                                        className="text-danger">*</span>
                                                                                </p>
                                                                                <p className="title-amount">
                                                                                    <input
                                                                                        name="giftCardPaidAmount"
                                                                                        type="text"
                                                                                        maxLength="9"
                                                                                        onChange={this.changeHandler}
                                                                                        onBlur={
                                                                                            this.formatgiftCardPaidAmount
                                                                                        }
                                                                                        value={
                                                                                            this.state.giftCardPaidAmount
                                                                                        }
                                                                                    />
                                                                                </p>
                                                                            </li>

                                                                            <li className="list-group-item">
                                                                                <p className="title-fade">
                                                                                    Reward Points Used &nbsp;{" "}
                                                                                    <span
                                                                                        className="text-danger">*</span>
                                                                                </p>
                                                                                <p className="title-amount">
                                                                                    <input
                                                                                        type="text"
                                                                                        name="rewardDeductionAmount"
                                                                                        onBlur={
                                                                                            this.formatrewardDeductionAmount
                                                                                        }
                                                                                        onChange={this.changeHandler}
                                                                                        value={
                                                                                            this.state.rewardDeductionAmount
                                                                                        }
                                                                                    />
                                                                                </p>
                                                                            </li>

                                                                            <li className="list-group-item">
                                                                                <p className="title-fade">
                                                                                    Discount Amount &nbsp;{" "}
                                                                                    <span
                                                                                        className="text-danger">*</span>
                                                                                </p>
                                                                                <p className="title-amount">
                                                                                    <input
                                                                                        type="text"
                                                                                        name="discountDeductionAmount"
                                                                                        onChange={this.changeHandler}
                                                                                        onBlur={
                                                                                            this.formatdiscountDeductionAmount
                                                                                        }
                                                                                        value={
                                                                                            this.state.discountDeductionAmount
                                                                                        }
                                                                                    />
                                                                                </p>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.props.checkOut == true && (
                                    <div className="modal-footer text-right">
                                        <button
                                            onClick={() =>
                                                this.apptCheckout(
                                                    this.state.productPurchaseAmount == 0 &&
                                                    this.state.serviceChargeAmount == 0 &&
                                                    this.state.tipAmount == 0 &&
                                                    this.state.taxAmount == 0 &&
                                                    this.state.giftCardSaleAmount == 0 &&
                                                    singleAppt.serviceChargeAmount == "-1"
                                                        ? "-1"
                                                        : singleAppt &&
                                                        (singleAppt.totalChargeAmount != "-1"
                                                            ? this.state.productPurchaseAmount * 1 +
                                                            this.state.serviceChargeAmount * 1 +
                                                            this.state.tipAmount * 1 +
                                                            this.state.taxAmount * 1 +
                                                            this.state.giftCardSaleAmount * 1
                                                            : 0)
                                                )
                                            }
                                            className="btn btn-info"
                                        >
                                            Check Out
                                        </button>
                                    </div>
                                )}
                            </React.Fragment>

                            {this.props.loading == true && (
                                <div className="custom-loading">
                                    <div className="loading">
                                        <i className="fa fa-spinner fa-spin"></i>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div
                    data-backdrop="static"
                    data-keyboard="false"
                    className="modal fade"
                    id="addServices"
                    role="dialog"
                    aria-hidden="true"
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <button
                                    data-dismiss="modal"
                                    data-toggle="modal"
                                    data-target="#editModal"
                                    className="close"
                                    onClick={() =>
                                        this.closeThisModal("#addServices", "Search a name")
                                    }
                                >
                                    &times;
                                </button>
                                <form>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group select-arrow">
                                                <label htmlFor="stylish">
                                                    Service &nbsp; <span className="text-danger">*</span>
                                                </label>
                                                <ServiceSearchBar
                                                    id="serviceId"
                                                    place={this.state.place}
                                                    clearPlace={this.clearPlace}
                                                    onServiceSelect={this.onServiceSelect}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            {this.state.techServices.length != 0 && (
                                                <div className="form-group add-services">
                                                    <label>Select Stylist</label>
                                                    <select
                                                        className="hidden technicians-select"
                                                        name="technician"
                                                        id=""
                                                        value={this.state.technician}
                                                        onChange={this.changeTech}
                                                    >
                                                        {this.state.techServices.map((e, key) => {
                                                            return (
                                                                <option key={e.id} value={e.id}>
                                                                    {e.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                    <div
                                                        id="technicians-select-div"
                                                        className="add-services"
                                                    ></div>
                                                </div>
                                            )}
                                            {this.state.techServices.length == 0 && (
                                                <div className="form-group add-services">
                                                    <label>Select Stylist</label>

                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        readOnly
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="endTime">
                                                    Start Time &nbsp;{" "}
                                                    <span className="text-danger">*</span>
                                                </label>
                                                <div className="time-clock">
                                                    <CustomTimePicker
                                                        value={
                                                            this.state.startTime != ""
                                                                ? moment(this.state.startTime).format(format)
                                                                : moment(
                                                                    singleAppt && singleAppt.bookedTime
                                                                ).format(format)
                                                        }
                                                        name="startTime"
                                                        onChange={this.changeStartTime}
                                                        format={format}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="endTime">
                                                    End Time &nbsp; <span className="text-danger">*</span>
                                                </label>

                                                <div className="time-clock">
                                                    <CustomTimePicker
                                                        value={
                                                            this.state.durat && this.state.durat != ""
                                                                ? this.state.durat.format(format)
                                                                : moment(
                                                                    singleAppt &&
                                                                    singleAppt.visitTechnicians &&
                                                                    singleAppt.visitTechnicians[0].period.to
                                                                ).format(format)
                                                        }
                                                        name="endTime"
                                                        onChange={this.changeEndTime}
                                                        format={format}
                                                    />
                                                </div>
                                            </div>
                                            {errors.timeDifference && (
                                                <p className="error">
                                                    <InlineMessage text={errors.timeDifference}/>
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <div className="form-group text-right">
                                    <button
                                        onClick={this.updateSaveService}
                                        className="btn btn-custom-info"
                                    >
                                        Save service
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.app != null && this.app.locations[0].brands.length > 0 && (
                    <div
                        data-backdrop="static"
                        data-keyboard="false"
                        className="modal fade"
                        id="addProducts"
                        tabIndex="-1"
                        role="dialog"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    {this.state.editPmodal === true && (
                                        <ProductScanner onProductScan={this.onProductScan}/>
                                    )}
                                    <button
                                        data-toggle="modal"
                                        data-target="#editModal"
                                        data-dismiss="modal"
                                        className="close"
                                        onClick={() => this.closeThisModal("", "Search a name")}
                                    >
                                        &times;
                                    </button>
                                    <div className="row">
                                        <div className="col-md-8">
                                            <div className="form-group select-arrow">
                                                <label htmlFor="stylish">
                                                    Product &nbsp; <span className="text-danger">*</span>
                                                </label>
                                                <ProductSearchBar
                                                    place={
                                                        this.state.place != ""
                                                            ? this.state.place
                                                            : this.props.place
                                                    }
                                                    clearPlace={this.clearPlace}
                                                    setPlace={this.setPlace}
                                                    clearScanner={this.clearScanner}
                                                    id="productId"
                                                    onProductSelect={this.onProductSelect}
                                                />
                                                <p className="text-danger error">
                                                    {errors.product && (
                                                        <InlineMessage text={errors.product}/>
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                        <div className={"col-md-4"}>
                                            <input className="form-control" value={this.state.attribute}/>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group add-services">
                                                <label>Staff</label>
                                                <select
                                                    name="technician"
                                                    value={this.state.technician}
                                                    onChange={this.changeAddPrTech}
                                                >
                                                    <optgroup label="">
                                                        <option>Select Staff</option>
                                                    </optgroup>
                                                    {this.app.lookupGroup.teams.map((team, key) => {
                                                        return this.teamWiseTechDisplay(team, key);
                                                    })}
                                                </select>
                                                <p className="text-danger error">
                                                    {errors.technician && (
                                                        <InlineMessage text={errors.technician}/>
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="endTime">
                                                    Quantity &nbsp; <span className="text-danger">*</span>
                                                </label>

                                                <div className="quantity-counter">
                                                    <div className="count-grabber">
                                                        <input
                                                            readOnly
                                                            type="text"
                                                            className="form-control"
                                                            value={this.state.qty}
                                                        />
                                                        <div className="btns">
                                                            <div
                                                                onClick={this.incrementQty}
                                                                className="incrmntbtn"
                                                            >
                                                                <i className="fa fa-caret-up"></i>
                                                            </div>
                                                            <div
                                                                onClick={this.decrementQty}
                                                                className="drmntbtn"
                                                            >
                                                                <i className="fa fa-caret-down"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <p className="text-danger error">
                                                    {errors.qty && (
                                                        <InlineMessage text={errors.quantity}/>
                                                    )}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="endTime">
                                                    {" "}
                                                    Price &nbsp; <span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    value={this.state.productPrice}
                                                    className="form-control"
                                                    type="text"
                                                    name="productPrice"
                                                    onChange={this.changeHandler}
                                                />
                                                <p className="text-danger error">
                                                    {errors.chargeAmount && (
                                                        <InlineMessage text={errors.chargeAmount}/>
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        onClick={this.saveProduct}
                                        className="btn btn-custom-info"
                                    >
                                        {this.state.mode == "PUT"
                                            ? "Update Product"
                                            : "Save Product"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className="modal" id="cancelReason">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button className="btn close" data-dismiss="modal">
                                    &times;
                                </button>
                                <h3>Cancellation Reason</h3>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label>
                                        Reason: &nbsp; <span className="text-danger">*</span>
                                    </label>
                                    <textarea
                                        onChange={this.changeHandler}
                                        required
                                        maxLength="1000"
                                        className="form-control height-200"
                                        value={this.state.cancelReason}
                                        name="cancelReason"
                                    ></textarea>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="form-group text-right">
                                    <button
                                        onClick={() => this.cancelReason()}
                                        type="submit"
                                        className="btn btn-custom-info"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    data-backdrop="static"
                    data-keyboard="false"
                    className="modal fade"
                    id="serviceCheckout"
                >
                    <div className="modal-dialog modal-sm" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <strong>Service Check out</strong>
                                <button
                                    onClick={() => this.closeThisModal("", "")}
                                    className="btn close"
                                    data-dismiss="modal"
                                >
                                    &times;
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label>
                                        Charge Amount: &nbsp; <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        className="form-control"
                                        name="chargeAmount"
                                        value={this.state.chargeAmount}
                                        onChange={this.changeHandler}
                                        onBlur={this.formatChargeAmount}
                                    />
                                    <p className="text-danger">
                                        {errors.checkout && (
                                            <InlineMessage text={errors.checkout}/>
                                        )}
                                    </p>
                                </div>
                            </div>

                            <div className="modal-footer">
                                <button
                                    onClick={() => this.processServieCheckout()}
                                    type="submit"
                                    className="btn btn-custom-info"
                                >
                                    Check Out
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    getRequestSource(reqSrc) {
        //console.log(reqSrc)
        let src = "";
        src =
            reqSrc == "Phone Call"
                ? "fa fa-phone text-warning custom mb0"
                : reqSrc == "Walk In"
                    ? "mdi mdi-run custom mb0"
                    : reqSrc == "Android App"
                        ? "fa fa-android text-purple custom"
                        : reqSrc == "iOS App"
                            ? "fa fa-mobile text-purple custom"
                            : reqSrc == "Website"
                                ? "fa fa-desktop custom"
                                : reqSrc == "iPad App"
                                    ? "fas fa-tablet custom"
                                    : reqSrc == "Tablet App"
                                        ? "mdi mdi-tablet-android custom"
                                        : "";

        return src;
    }

    findTechnician(technicianId, query) {
        // eslint-disable-next-line
        let technician = this.app.locations[0].technicians.find(
            (technician) => technician.id == technicianId
        );

        if (technician)
            if (query == "image") {
                return technician.user.imageUrl;
            }
        if (query == "nick") {
            return technician.user.names.nick;
        } else {
            return technician.user.fullName;
        }

        return "";
    }

    findServicePrice(serviceId, techId, catId) {
        //console.log(serviceId)
        let servicePrice = "";

        let s = this.app.locations[0].technicians
            .find((tech) => tech.id == techId)
            .offeredServices.find((service) => service.id == serviceId);
        //console.log('SSSS', s)

        if (s == null) {
            s = this.app.offeredServiceCategories.find((c) => c.id == catId);
            //console.log('s',s)
        }

        servicePrice =
            s.minimumFee == -1 && s.maximumFee == -1
                ? "Per Consult"
                : s.minimumFee > -1 && s.maximumFee == -1
                    ? "From $" + s.minimumFee
                    : s.minimumFee > -1 && s.maximumFee > -1 && s.minimumFee == s.maximumFee
                        ? "$" + s.minimumFee
                        : s.minimumFee > -1 && s.maximumFee > -1 && s.minimumFee != s.maximumFee
                            ? "$" + s.minimumFee + " - $" + s.maximumFee
                            : "";

        return servicePrice;
    }

    findServiceName(serviceId, categoryId) {
        // eslint-disable-next-line
        let category = this.app.offeredServiceCategories.find(
            (sc) => sc.id == categoryId
        );
        if (category == null) return "Service not found";
        let service = category.services.find((s) => s.id == serviceId);
        if (service == null) return "Service not found";
        return service.name;
    }

    checkPrice = (id) => {
        const service = this.state.services.find((s) => s.id == id);
        if (service != null) {
            const price = this.checkPriceType(service.minimumFee, service.maximumFee);
            return price;
        }
    };
    checkUpdatePrice = (id) => {
        const service = this.state[`services-${this.state.singleSVtId}`].find(
            (s) => s.id == id
        );
        if (service != null) {
            const price = this.checkPriceType(service.minimumFee, service.maximumFee);
            return price;
        }
    };

    checkPriceType = (min, max) => {
        if (min == "-1" && max == "-1") {
            return "";
        } else if (min > 0 && max > 0 && min < max) {
            return min * 1;
        } else if (min > 0 && max == -1) {
            return min * 1;
        } else {
            return min * 1;
        }
    };

    checkTechAttachedToService = (id) => {
        const service = this.state.services.find((s) => s.id == id);
        if (service != null) return service.technicianServices;
    };
    checkTechUpdateAttachedToService = (id) => {
        const service = this.state[`services-${this.state.singleSVtId}`].find(
            (s) => s.id == id
        );
        if (service != null) return service.technicianServices;
    };

    checkCategory = (id) => {
        const category = this.app.offeredServiceCategories.find((c) => c.id == id);
        if (category != null) return category.name;
    };

    teamWiseTechDisplay = (team, k) => (
        <optgroup key={k} label={team}>
            {this.checkChild(team)}
        </optgroup>
    );
    checkChild = (team) => {
        const grouped = [];
        this.app.locations[0].technicians
            .filter((t) => t.active == true)
            .map((t, k) => {
                if (t.team == team)
                    grouped.push(
                        <option key={k} value={t.id}>
                            {t.user.names.nick}
                        </option>
                    );
            });
        return grouped;
    };
}

export default EditCart;
