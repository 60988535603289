import React from 'react';

class Footer extends React.Component {

  render() {
    return (
        <footer  className="footer text-center"> {new Date().getFullYear()} &copy; Powered by ZorA </footer>     
    );
  }

}

export default Footer;