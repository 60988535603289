/* eslint-disable */
import React, {Component} from 'react';
import NewWaitList from './newWaitlist';

class AddNewWaitlist extends Component {

    constructor(props){
        super(props)
    }

    render() {
        return (
            <NewWaitList clearPlace={this.props.clearPlace} customer={this.props.customer} selectNewCustomer={this.props.selectNewCustomer}  loading={this.props.loading} saveWaitList={this.props.saveWaitList} />


        );
    }
}

export default AddNewWaitlist;