import { reservationConstants } from '../_constants';
 
export function reservation(state = {}, action) {
  switch (action.type) {
    case reservationConstants.APPOINTMENTS_REQUEST:
      return { 
        appointments: [], 
        appointmentsLoaded: false,
      };
    case reservationConstants.APPOINTMENTS_SUCCESS:
      return { 
          appointments: action.appointments, 
          appointmentsLoaded: true
      };
    case reservationConstants.APPOINTMENTS_FAILURE:
      return {
        appointments: [],
        appointmentsLoaded: true
      }
    
    case reservationConstants.CHECK_IN_CUSTOMER_SUCCESS:
      return {
          customerCheckedIn:true 
      };
    
    case reservationConstants.CHECK_OUT_CUSTOMER_SUCCESS:
      return { 
          customerCheckedOut:true 
      };

    case reservationConstants.CHECK_IN_APPOINTMENT_SUCCESS:
      return { 
          appointmentCheckedIn:true 
      };

    case reservationConstants.CHECK_OUT_APPOINTMENT_SUCCESS:
      return { 
          appointmentCheckedOut:true 
      };
      case reservationConstants.APPOINTMENT_SLOTS_REQUEST:
      return { 
          findingAvailability: true 
      };
    case reservationConstants.APPOINTMENT_SLOTS_SUCCESS:
      return {
        findingAvailability:false,
        foundAvailability: true,
        slots: action.slots
      };
    case reservationConstants.APPOINTMENT_SLOTS_FAILURE:
      return {};

    case reservationConstants.PLACE_RESERVATION_REQUEST:
      return {
          placingReservation:true
      };
    case reservationConstants.PLACE_RESERVATION_SUCCESS:
      return {
          reservationPlaced:true
      };
    case reservationConstants.PLACE_RESERVATION_FAILURE:
      return {};
    default:
      return state
  }
}