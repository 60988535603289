
import {communicationConstants} from "../_constants/communication.constants";

export function Communication(state = {}, action) {

    switch (action.type) {
        case communicationConstants.PROCESS_RELOAD:
            return {
                reloadData: action.data
            }
        case communicationConstants.CAPTURE_INFO:
            return {
                info: action.info
            }
        default:
            return state
    }

}
