import * as React from "react"

function Com(props) {
    return (
        <svg viewBox="0 0 512 512" {...props}>
            <circle cx={207.644} cy={333.305} r={14.216} />
            <circle cx={167.818} cy={333.305} r={14.216} />
            <circle cx={128.003} cy={333.305} r={14.216} />
            <path d="M304.366 416.009h-.011zM31.289 416.009h-.046zM480.711 40.446H207.644c-17.265.034-31.254 14.018-31.289 31.278v105.21H31.289C14.023 176.968.034 190.951 0 208.211v176.515c.034 17.26 14.023 31.243 31.283 31.278l42.547-.153v47.178a8.535 8.535 0 0014.143 6.423l60.365-53.635 156.018.193c17.265-.034 31.254-14.018 31.289-31.278V279.424l27.3.023 61.076 53.276a8.533 8.533 0 0014.143-6.424v-46.82l42.582-.199h-.034c17.265-.034 31.254-14.012 31.289-31.272V71.723c-.036-17.259-14.025-31.243-31.29-31.277zM318.577 384.731c0 3.908-1.576 7.424-4.181 10.041-2.617 2.599-6.132 4.176-10.041 4.176h-.011l-159.215-.188a8.626 8.626 0 00-5.598 2.105l-48.628 43.4v-36.898c0-4.682-3.806-8.511-8.488-8.533l-51.08.12h-.046c-3.908 0-7.424-1.576-10.041-4.176-2.606-2.628-4.181-6.144-4.181-10.046v-176.52c0-3.908 1.576-7.424 4.181-10.035 2.617-2.606 6.132-4.181 10.041-4.187h273.066c3.908.006 7.424 1.581 10.041 4.187 2.606 2.617 4.181 6.132 4.181 10.035v176.519zM490.752 258.04c-2.617 2.606-6.132 4.176-10.041 4.181h-.04l-51.08.239c-4.688.017-8.494 3.846-8.494 8.527v36.545l-49.34-43.042a8.613 8.613 0 00-5.598-2.1l-30.515-.034v-54.147c-.034-17.26-14.023-31.243-31.289-31.278H193.422V71.723c0-3.908 1.576-7.424 4.181-10.035 2.617-2.606 6.132-4.181 10.041-4.187H480.71c3.908.006 7.424 1.581 10.041 4.187 2.606 2.611 4.181 6.132 4.181 10.035v176.282h.001c0 3.908-1.576 7.418-4.181 10.035z" />
        </svg>
    )
}

export default Com
