import React, { useEffect } from "react";
import Feather from "../../feather.png";

const Features = () => {
  return (
    <div className="features">
      <h3 className="mobile-hidden">
        Welcome to Silverbird <br />
        The ultimate salon app
      </h3>
      <h4>Features include:</h4>
      <ul>
        <li>
          <span className="icon-box">
            <img src={Feather} alt="feather" />
          </span>
          <span className="feature-text">Appointments</span>
        </li>
        <li>
          <span className="icon-box">
            <img src={Feather} alt="feather" />
          </span>
          <span className="feature-text">Inventory</span>
        </li>
        <li>
          <span className="icon-box">
            <img src={Feather} alt="feather" />
          </span>
          <span className="feature-text">Payment</span>
        </li>
        <li>
          <span className="icon-box">
            <img src={Feather} alt="feather" />
          </span>
          <span className="feature-text">Accounting</span>
        </li>
        <li>
          <span className="icon-box">
            <img src={Feather} alt="feather" />
          </span>
          <span className="feature-text">Reports</span>
        </li>
        <li>
          <span className="icon-box">
            <img src={Feather} alt="feather" />
          </span>
          <span className="feature-text">More</span>
        </li>
      </ul>
    </div>
  );
};
export default Features;
