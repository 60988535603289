/* eslint-disable */
import React, {Component} from "react";
import ProductScanner from "./productScanner";
import ProductActivityRow from "./productActivityRow";
import InventorySearch from "./inventorySearch";
import {inventoryService} from "../_services";
import NewProductSearchBar from "./newProductSearchBar";
import CustomerSearchBar from "./customerSearchBar";
import {reservationService} from "../_services";
import {helperFunctions} from "../_helpers";
import {inventoryActions} from "../_actions";

class InventoryActivity extends Component {
    constructor(props) {
        super(props);
        this.app = JSON.parse(localStorage.getItem("app"));
        this.state = {
            inventoryActivityType: "Intake",
            activityItems: 0,
            activityRowData: [],
            collection: [],
            invoiceNumber: "",
            pageOffset: 0,
            type: "Intake",
            activitiesList: "",
            loading: false,
            brndId: "all",
            tableList: [],
            variantQuantity: "",
            salePrice: "",
            adjustReason: "",
            productName: "",
            brandName: "",
            variantId: "",
            inventId: "",
            returnCollection: [],
            visitPurchaseItem: "",
            validQty: "",
            returnQty: [],
            item: [],
        };

        this.addNewRow = this.addNewRow.bind(this);
    }

    componentDidMount() {
        this.getAllOpenIntake();
    }

    getAllOpenIntake = () => {
        inventoryService
            .getAllOpenIntake(this.state.pageOffset, this.state.inventoryActivityType)
            .then((res) => {
                this.setState({
                    activitiesList: res,
                    tableList: res,
                    loading: false,
                });
            })
            .catch((e) => {
                this.setState({
                    loading: false,
                });
            });
    };

    onRowDelete(index) {
        //console.log("delete ",index)
    }

    addNewRow() {
        this.setState({activityItems: this.state.activityItems + 1});
    }

    onProductScan = (product) => {
        if (product && product.length > 0) {
            this.setState({
                variantQuantity: product[0].quantity,
                salePrice: product[0].salePrice,
                brandName: product[0].variant.product.brand.name,
                variantId: product[0].id,
            });
        }
    };

    onProductSelect = (product) => {
        this.setState({
            variantQuantity: product.quantity,
            salePrice: product.salePrice,
            brandName: product.variant.product.brand.name,
            variantId: product.id,
        });
    };

    changeActivityType(e) {
        const {name, value} = e.target;
        var returnCollection = this.state.returnCollection;

        this.setState(
            {
                loading: true,
                [name]: value,
                activityItems: 0,
                pageOffset: 0,
            },
            () => {
                if (this.state.inventoryActivityType != "return") {
                    this.setState({
                        returnCollection: [],
                    });
                }
                this.getAllOpenIntake();
                this.getStateChanges("", "all");
            }
        );
    }

    getCollection = (d) => {
        const {collection} = this.state;
        // eslint-disable-next-line
        const pin = collection.find((f) => f.index == d.index);

        if (pin) {
            const newCollection = collection.map((c) => {
                // eslint-disable-next-line
                if (c.index == d.index) {
                    return {
                        ...c,
                        ...d,
                    };
                } else {
                    return {};
                }
                return c;
            });
            this.setState({
                collection: newCollection,
            });
        } else {
            const collection = this.state.collection;
            collection.push(d);
            this.setState({
                collection: collection,
            });
        }
    };
    getStateChanges = (i, changes) => {
        // eslint-disable-next-line
        if (changes == "all") {
            this.setState({
                collection: [],
            });
        } else {
            const {collection} = this.state;
            if (collection.length > 0) {
                const newCollection = collection.map((c) => {
                    // eslint-disable-next-line
                    if (c.index == i) {
                        return {
                            ...c,
                            ...changes,
                        };
                    }
                    return c;
                });

                this.setState({
                    collection: newCollection,
                });
            }
        }
    };

    saveNewActivity = (i) => {
        const {collection} = this.state;
        var activity = "";
        if (collection.length > 0) {
            // eslint-disable-next-line
            const pin = collection.filter((c) => c.index == i);
            if (pin.length !== 0) {
                activity = {
                    locatedVariant: {
                        id: pin[0].locatedVariant,
                    },
                    type: this.state.inventoryActivityType,
                    quantity: pin[0].variantQuantity,
                    invoiceNumber: this.state.invoiceNumber,
                    adjustmentReason: pin[0].adjustReason ? pin[0].adjustReason : "",
                };
            } else {
                activity = {
                    locatedVariant: {
                        id: collection[0].locatedVariant,
                    },
                    type: this.state.inventoryActivityType,
                    quantity: collection[0].variantQuantity,
                    invoiceNumber: this.state.invoiceNumber,
                    adjustmentReason: collection[0].adjustReason
                        ? collection[0].adjustReason
                        : "",
                };
            }

            this.props.addProductIntake(activity);
            this.onRowDelete(i);
            setTimeout(() => {
                this.getAllOpenIntake();
            }, 500);
        }
    };

    changeHandler = (e) => {
        const {name, value} = e.target;
        this.setState({
            [name]: value,
        });
    };

    finalizeActivity = (id = "", counts) => {
        const {dispatch} = this.props;

        this.setState({
            loading: true,
        });
        // eslint-disable-next-line
        if (counts == "single") {
            inventoryService
                .finalizeActivity(id)
                .then((res) => {
                    this.getAllOpenIntake();
                })
                .catch((e) => {
                    this.setState({
                        loading: false,
                    });
                });
        } else {
            var breakPoint =
                this.state.item.length > 0 ? this.state.item : this.state.tableList;

            for (var i = 0; i < breakPoint.length; i++) {
                inventoryService
                    .finalizeActivity(this.state.tableList[i].id)
                    .then((res) => {
                        this.getAllOpenIntake();
                        this.setState({
                            item: [],
                        });
                        window.uncheckEveryone("#waiting-table input");
                    })
                    .catch((e) => {
                        this.setState({
                            loading: false,
                        });
                    });
            }
        }
        this.props.getAllProducts();
    };

    changeBrand = (e) => {
        this.setState(
            {
                brndId: e.target.value,
            },
            () => {
                this.collectTableList();
            }
        );
    };

    collectTableList = () => {
        if (this.state.activitiesList.length > 0) {
            // eslint-disable-next-line
            if (this.state.brndId == "all") {
                this.setState({
                    tableList: this.state.activitiesList,
                });
            } else {
                const newActivitiesList = this.state.activitiesList.filter(
                    (a) => a.locatedVariant.variant.product.brand.id == this.state.brndId
                );
                if (newActivitiesList) {
                    this.setState({
                        tableList: newActivitiesList,
                    });
                } else {
                    tableList: [];
                }
            }
        }
    };

    globalIntakeSave = () => {
        const {collection} = this.state;
        if (collection.length > 0) {
            var activity = "";
            for (var i = 0; i < collection.length; i++) {
                const activity = {
                    locatedVariant: {
                        id: collection[i].locatedVariant,
                    },
                    type: this.state.inventoryActivityType,
                    quantity: collection[i].variantQuantity,
                    invoiceNumber: this.state.invoiceNumber,
                    adjustmentReason: collection[i].adjustReason
                        ? collection[i].adjustReason
                        : "",
                };
                this.props.addProductIntake(activity);
                setTimeout(() => {
                    this.getAllOpenIntake();
                }, 500);
            }
        }
    };

    onRowDelete = (index) => {
        const newCollection = this.state.collection.filter((c) => c.index != index);
        this.setState({
            collection: newCollection,
            activityItems: this.state.activityItems - 1,
        });
    };

    deleteActivity = (id) => {
        this.setState({
            loading: true,
        });
        inventoryService
            .deleteActivity(id)
            .then(() => {
                window.toast("error", `This item has been deleted from the system`);
                this.getAllOpenIntake();
            })
            .catch((e) => {
                this.setState({
                    loading: false,
                });
            });
    };

    editActivity = (a) => {
        $("#activity").modal("toggle");
        this.setState({
            invoiceNumber: a.invoiceNumber,
            variantQuantity: a.quantity,
            brandName: a.locatedVariant.variant.product.brand.name,
            salePrice: a.locatedVariant.salePrice,
            adjustReason: a.adjustmentReason ? a.adjustmentReason : "",
            productName: a.locatedVariant.variant.product.name,
            inventId: a.id,
            variantId: a.locatedVariant.id,
        });
    };

    updateActivity = () => {
        this.setState({
            loading: true,
        });
        const data = {
            locatedVariant: {
                id: this.state.variantId,
            },
            type: this.state.inventoryActivityType,
            quantity: this.state.variantQuantity,
            // eslint-disable-next-line
            adjustmentReason:
                this.state.adjustReason != "" ? this.state.adjustReason : "",
            invoiceNumber: "#123456",
        };
        inventoryService
            .updateActivity(this.state.inventId, data)
            .then((res) => {
                this.getAllOpenIntake();
                this.setState({
                    brandName: "",
                    variantQuantity: "",
                    invoiceNumber: "",
                    inventId: "",
                    variantId: "",
                    salePrice: "",
                    adjustReason: "",
                    loading: false,
                });

                $("#updateActivity").modal("toggle");
                $("#activity").modal("toggle");
            })
            .catch((e) => {
                this.setState({
                    loading: false,
                });
            });
    };

    onCustomerSelect = (user) => {
        reservationService
            .getPastAppointmentsWithInventory(user.id)
            .then((appt) => {
                this.setState({
                    returnCollection: appt,
                });
            });
    };

    deleteReturnRow = (id) => {
        const newCollection = this.state.returnCollection.filter((r) => r.id != id);
        this.setState({
            returnCollection: newCollection,
        });
    };

    selectSingleReturn = (product) => {
        this.setState({
            variantId: product.locatedProductVariant.id,
            variantQuantity: product.quantity,
            visitPurchaseItem: product.id,
            validQty: product.quantity,
        });
    };
    incrementQty = () => {
        var qty = parseInt(this.state.variantQuantity, 10);
        qty++;
        if (qty >= this.state.validQty) {
            this.setState({
                variantQuantity: this.state.validQty,
            });
        } else {
            this.setState({
                variantQuantity: qty,
            });
        }
    };
    decrementQty = () => {
        var qty = parseInt(this.state.variantQuantity, 10);
        if (isNaN(qty)) {
            this.setState({
                prod: 1,
            });
        } else {
            if (qty > 1) {
                this.setState({
                    variantQuantity: qty - 1,
                });
            }
        }
    };

    saveReturnActivity = () => {
        const returnJSON = {
            locatedVariant: {
                id: this.state.variantId,
            },
            type: this.state.inventoryActivityType,
            quantity: this.state.variantQuantity,
            adjustmentReason: this.state.adjustReason,
            visitPurchaseItem: {
                id: this.state.visitPurchaseItem,
            },
        };
        this.props.addProductIntake(returnJSON);

        const returnQty = this.state.returnQty;
        returnQty.push({
            id: this.state.visitPurchaseItem,
            qty: this.state.variantQuantity,
        });
        $("#saveReturnActivity").modal("toggle");
        this.setState({
            returnQty: returnQty,
            variantQuantity: "",
            adjustReason: "",
        });

        setTimeout(() => {
            this.getAllOpenIntake();
        }, 500);
    };

    handleCheckbox = (e) => {
        if (e.target.checked) {
            this.setState({
                item: this.state.item.concat(e.target.value),
            });
        } else {
            this.setState({
                item: this.state.item.filter((i) => i !== e.target.value),
            });
        }
    };

    deleteMultiple = () => {
        const {item, tableList} = this.state;
        this.setState({
            ...this.state,
            loading: true,
        });
        if (item.length > 0) {
            for (var i = 0; i < item.length; i++) {
                inventoryService.deleteActivity(item[i]).then((res) => {
                    this.getAllOpenIntake();
                });
            }

            window.uncheckEveryone("#waiting-table input");

            this.setState({
                item: [],
            });
        } else {
            for (var i = 0; i < tableList.length; i++) {
                inventoryService.deleteActivity(tableList[i].id).then((res) => {
                    this.getAllOpenIntake();
                });
            }
        }
        this.setState({
            loading: false,
        });
    };

    render() {
        const {activitiesList, tableList, returnCollection} = this.state;

        var productActivityRows = [];
        for (var i = 0; i < this.state.activityItems; i++) {
            // eslint-disable-next-line
            if (this.state.inventoryActivityType == "Intake")
                productActivityRows.push(
                    <ProductActivityRow
                        onRowDelete={this.onRowDelete}
                        finalizeActivity={this.finalizeActivity}
                        saveNewActivity={this.saveNewActivity}
                        key={i}
                        getStateChanges={this.getStateChanges}
                        getCollection={this.getCollection}
                        index={i}
                    />
                );
            // eslint-disable-next-line
            else if (this.state.inventoryActivityType == "Adjustment")
                productActivityRows.push(
                    <ProductActivityRow
                        showReasonCol={true}
                        key={i}
                        index={i}
                        onRowDelete={this.onRowDelete}
                        finalizeActivity={this.finalizeActivity}
                        saveNewActivity={this.saveNewActivity}
                        getStateChanges={this.getStateChanges}
                        getCollection={this.getCollection}
                    />
                );
            // eslint-disable-next-line
            else if (this.state.inventoryActivityType == "Return")
                productActivityRows.push(
                    <ProductActivityRow
                        showReasonCol={true}
                        showApptSearchCol={true}
                        key={i}
                        index={i}
                        onRowDelete={this.onRowDelete}
                    />
                );
        }

        return (
            <React.Fragment>
                <div
                    className="modal fade"
                    id="activity"
                    role="dialog"
                    aria-labelledby="exampleModalLabel1"
                >
                    <div className="modal-dialog  modal-inventory" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                                <h4 className="modal-title" id="exampleModalLabel1">
                                    <strong>New Activity</strong>
                                </h4>
                            </div>

                            <div className="modal-body">
                                <ProductScanner onProductScan={this.onProductScan}/>

                                <div className="top-fields container-fluid">
                                    <div className="row">
                                        <div className="col-md-9 col-md-offset-3">
                                            <div className="row">
                                                <div className="col-md-2 col-md-offset-6">
                                                    <div className="form-group activity-radio">
                                                        <label className="d-flex">
                                                            <input
                                                                defaultChecked
                                                                onChange={(e) => this.changeActivityType(e)}
                                                                type="radio"
                                                                name="inventoryActivityType"
                                                                value="Intake"
                                                            />{" "}
                                                            <span>Intake</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group activity-radio">
                                                        <label className="d-flex">
                                                            <input
                                                                onChange={(e) => this.changeActivityType(e)}
                                                                type="radio"
                                                                name="inventoryActivityType"
                                                                value="Adjustment"
                                                            />
                                                            <span>Adjustment</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group activity-radio">
                                                        <label className="d-flex">
                                                            <input
                                                                type="radio"
                                                                onChange={(e) => this.changeActivityType(e)}
                                                                name="inventoryActivityType"
                                                                value="Return"
                                                            />
                                                            <span>Return</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {this.state.inventoryActivityType === "Intake" && (
                                    <div className="container-fluid">
                                        <div className="switcher-spacer"></div>

                                        <div className="row">
                                            <div className="col-md-2">
                                                <div className="form-group text-uppercase">
                                                    <input
                                                        className="invoiceNumber"
                                                        name="invoiceNumber"
                                                        placeholder="Invoice"
                                                        onChange={this.changeHandler}
                                                        type="text"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-10 text-right top">
                                                <div className="actions-btn">
                                                    <a
                                                        onClick={this.globalIntakeSave}
                                                        style={{cursor: 'pointer'}}
                                                    >
                                                        <i className="ti ti-save"></i> Save All
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <h4 className="text-uppercase">Product</h4>
                                            </div>
                                            <div className="col-md-2">
                                                <h4 className="text-uppercase">Cost</h4>
                                            </div>
                                            <div className="col-md-2">
                                                <h4 className="text-uppercase">Qty</h4>
                                            </div>
                                            <div className="col-md-2 text-right">
                                                <h4 className="text-uppercase">Action</h4>
                                            </div>
                                        </div>

                                        {productActivityRows}

                                        <div className="row">
                                            <div>
                                                <div className="add-row-btn">
                                                    <div className="btn-line"></div>
                                                    <button onClick={this.addNewRow} className="new-btn">
                                                        + Add New
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="switcher-spacer"></div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="switcher-spacer"></div>
                                            </div>
                                        </div>
                                        {tableList.length > 0 && (
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="table-responsive">
                                                        <div className="take-action">
                                                            <div className="brand-filter">
                                                                <select
                                                                    name="brndId"
                                                                    onChange={this.changeBrand}
                                                                    className="brands"
                                                                >
                                                                    <option value="all">All</option>
                                                                    {this.app &&
                                                                        this.app.brands.map((b, key) => {
                                                                            return (
                                                                                <option key={key} value={b.id}>
                                                                                    {b.name}
                                                                                </option>
                                                                            );
                                                                        })}
                                                                </select>
                                                                <div className="pagination-wrapper">
                                                                    <ul className="pager">
                                                                        <li>
                                                                            <a
                                                                                onClick={this.getPreviousTableList}
                                                                                style={{cursor: 'pointer'}}
                                                                            >
                                                                                <i className="fa fa-angle-left"></i>
                                                                            </a>
                                                                        </li>
                                                                        <li>
                                                                            <a
                                                                                onClick={this.getNextTableList}
                                                                                style={{cursor: 'pointer'}}
                                                                            >
                                                                                <i className="fa fa-angle-right"></i>
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="global-action">
                                                                <button
                                                                    onClick={() =>
                                                                        this.finalizeActivity("", "all")
                                                                    }
                                                                    className="no-border"
                                                                >
                                                                    <i className="ti ti-check text-info fa-2x"></i>
                                                                </button>
                                                                <button
                                                                    onClick={this.deleteMultiple}
                                                                    className="no-border"
                                                                >
                                                                    <i className="ti ti-trash text-danger fa-2x"></i>
                                                                </button>
                                                            </div>
                                                        </div>

                                                        <table className="table" id="waiting-table">
                                                            <thead>
                                                            <tr>
                                                                <th>Name</th>
                                                                <th>Brand</th>
                                                                <th>Sale Price</th>
                                                                <th>Quantity</th>
                                                                <th>Status</th>
                                                                <th className="text-right">Action</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {tableList.length == 0 && (
                                                                <tr>
                                                                    <td colSpan="6" align="center">
                                                                        <h3>Records Not Found!</h3>
                                                                    </td>
                                                                </tr>
                                                            )}

                                                            {tableList.length > 0 &&
                                                                tableList.map((a, k) => {
                                                                    return (
                                                                        <tr key={k}>
                                                                            <td>
                                                                                <div className="form-check">
                                                                                    <input
                                                                                        onChange={this.handleCheckbox}
                                                                                        value={a.id}
                                                                                        type="checkbox"
                                                                                        id={`return-${a.id}`}
                                                                                        className="form-check-input"
                                                                                    />
                                                                                    <label
                                                                                        className="form-check-label"
                                                                                        htmlFor={`return-${a.id}`}
                                                                                    >
                                                                                        {
                                                                                            a.locatedVariant.variant.product
                                                                                                .name
                                                                                        }
                                                                                    </label>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    a.locatedVariant.variant.product
                                                                                        .brand.name
                                                                                }
                                                                            </td>
                                                                            <td>${a.locatedVariant.salePrice}</td>
                                                                            <td>{a.quantity}</td>
                                                                            <td>
                                                                                <label className="label label-info">
                                                                                    {a.status}
                                                                                </label>
                                                                            </td>
                                                                            <td align="right">
                                                                                <button
                                                                                    onClick={() =>
                                                                                        this.finalizeActivity(
                                                                                            a.id,
                                                                                            "single"
                                                                                        )
                                                                                    }
                                                                                    className="no-border"
                                                                                >
                                                                                    <i className="ti ti-check text-info"></i>
                                                                                </button>
                                                                                <button
                                                                                    onClick={() => this.editActivity(a)}
                                                                                    data-target="#updateActivity"
                                                                                    data-toggle="modal"
                                                                                    className="no-border"
                                                                                >
                                                                                    <i className="ti ti-pencil custom-info"></i>
                                                                                </button>
                                                                                <button
                                                                                    onClick={() =>
                                                                                        this.deleteActivity(a.id)
                                                                                    }
                                                                                    className="no-border"
                                                                                >
                                                                                    <i className="ti ti-trash text-danger"></i>
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                                {this.state.inventoryActivityType === "Adjustment" && (
                                    <div className="container-fluid">
                                        <div className="switcher-spacer"></div>
                                        <div className="row">
                                            <div className="col-md-12 text-right top">
                                                <div className="actions-btn">
                                                    <a
                                                        onClick={this.globalIntakeSave}
                                                        style={{cursor: 'pointer'}}
                                                    >
                                                        <i className="ti ti-save"></i> Save All
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <h4 className="text-uppercase">Product</h4>
                                            </div>
                                            <div className="col-md-2">
                                                <h4 className="text-uppercase">Cost</h4>
                                            </div>
                                            <div className="col-md-2">
                                                <h4 className="text-uppercase">Qty</h4>
                                            </div>

                                            <div className="col-md-3">
                                                <h4 className="text-uppercase">Reason</h4>
                                            </div>
                                            <div className="col-md-2 text-right">
                                                <h4 className="text-uppercase">Action</h4>
                                            </div>
                                        </div>

                                        {productActivityRows}

                                        <div className="row">
                                            <div>
                                                <div className="add-row-btn">
                                                    <div className="btn-line"></div>
                                                    <button onClick={this.addNewRow} className="new-btn">
                                                        + Add New
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="switcher-spacer"></div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="switcher-spacer"></div>
                                            </div>
                                        </div>
                                        {tableList.length > 0 && (
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="table-responsive">
                                                        <div className="take-action">
                                                            <div className="brand-filter">
                                                                <select
                                                                    name="brndId"
                                                                    onChange={this.changeBrand}
                                                                    className="brands"
                                                                >
                                                                    <option value="all">All</option>
                                                                    {this.app &&
                                                                        this.app.brands.map((b, key) => {
                                                                            return (
                                                                                <option key={key} value={b.id}>
                                                                                    {b.name}
                                                                                </option>
                                                                            );
                                                                        })}
                                                                </select>
                                                                <div className="pagination-wrapper">
                                                                    <ul className="pager">
                                                                        <li>
                                                                            <a
                                                                                onClick={this.getPreviousTableList}
                                                                                style={{cursor: 'pointer'}}
                                                                            >
                                                                                <i className="fa fa-angle-left"></i>
                                                                            </a>
                                                                        </li>
                                                                        <li>
                                                                            <a
                                                                                onClick={this.getNextTableList}
                                                                                style={{cursor: 'pointer'}}
                                                                            >
                                                                                <i className="fa fa-angle-right"></i>
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="global-action">
                                                                <button
                                                                    onClick={() =>
                                                                        this.finalizeActivity("", "all")
                                                                    }
                                                                    className="no-border"
                                                                >
                                                                    <i className="ti ti-check text-info fa-2x"></i>
                                                                </button>
                                                                <button
                                                                    onClick={this.deleteMultiple}
                                                                    className="no-border"
                                                                >
                                                                    <i className="ti ti-trash text-danger fa-2x"></i>
                                                                </button>
                                                            </div>
                                                        </div>

                                                        <table className="table" id="waiting-table">
                                                            <thead>
                                                            <tr>
                                                                <th>Name</th>
                                                                <th>Brand</th>
                                                                <th>Sale Price</th>
                                                                <th>Quantity</th>
                                                                <th>Status</th>
                                                                <th className="text-right">Action</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {tableList.length == 0 && (
                                                                <tr>
                                                                    <td align="center" colSpan="6">
                                                                        <h3>Records Not Found!</h3>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                            {tableList.length > 0 &&
                                                                tableList.map((a, k) => {
                                                                    return (
                                                                        <tr key={k}>
                                                                            <td>
                                                                                <div className="form-check">
                                                                                    <input
                                                                                        onChange={this.handleCheckbox}
                                                                                        value={a.id}
                                                                                        type="checkbox"
                                                                                        id={`return-${a.id}`}
                                                                                        className="form-check-input"
                                                                                    />
                                                                                    <label
                                                                                        className="form-check-label"
                                                                                        htmlFor={`return-${a.id}`}
                                                                                    >
                                                                                        {
                                                                                            a.locatedVariant.variant.product
                                                                                                .name
                                                                                        }
                                                                                    </label>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    a.locatedVariant.variant.product
                                                                                        .brand.name
                                                                                }
                                                                            </td>
                                                                            <td>${a.locatedVariant.salePrice}</td>
                                                                            <td>{a.quantity}</td>
                                                                            <td>
                                                                                <label className="label label-info">
                                                                                    {a.status}
                                                                                </label>
                                                                            </td>
                                                                            <td align="right">
                                                                                <button
                                                                                    onClick={() =>
                                                                                        this.finalizeActivity(
                                                                                            a.id,
                                                                                            "single"
                                                                                        )
                                                                                    }
                                                                                    className="no-border"
                                                                                >
                                                                                    <i className="ti ti-check text-info"></i>
                                                                                </button>
                                                                                <button
                                                                                    onClick={() => this.editActivity(a)}
                                                                                    data-target="#updateActivity"
                                                                                    data-toggle="modal"
                                                                                    className="no-border"
                                                                                >
                                                                                    <i className="ti ti-pencil custom-info"></i>
                                                                                </button>
                                                                                <button
                                                                                    onClick={() =>
                                                                                        this.deleteActivity(a.id)
                                                                                    }
                                                                                    className="no-border"
                                                                                >
                                                                                    <i className="ti ti-trash text-danger"></i>
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                                {this.state.inventoryActivityType === "Return" && (
                                    <div className="container-fluid">
                                        <div className="switcher-spacer"></div>

                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="switcher-spacer"></div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="customer-search">
                                                    <CustomerSearchBar
                                                        onCustomerSelect={this.onCustomerSelect}
                                                    />
                                                </div>
                                            </div>
                                            {this.state.returnCollection.length > 0 && (
                                                <div className="return-row col-md-12">
                                                    <hr/>

                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <h4>Appt Date/Time</h4>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <h4>Services Total</h4>
                                                        </div>

                                                        <div className="col-md-3">
                                                            <h4>Product Total</h4>
                                                        </div>
                                                        <div className="col-md-3 text-right">
                                                            <h4>Action</h4>
                                                        </div>
                                                    </div>

                                                    {this.state.returnCollection.length > 0 &&
                                                        this.state.returnCollection.map((r, key) => {
                                                            return (
                                                                <div key={key} className="collapsible">
                                                                    <div className="row  parent-row">
                                                                        <div className="col-md-3">
                                                                            <p>
                                                                                {helperFunctions.formatDateTimeWithDay(
                                                                                    moment(r.bookedTime),
                                                                                    "latest"
                                                                                )}
                                                                            </p>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <p>
                                                                                $ {(r.totalChargeAmount * 1).toFixed(2)}
                                                                            </p>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <p>
                                                                                ${" "}
                                                                                {(r.productPurchaseAmount * 1).toFixed(
                                                                                    2
                                                                                )}
                                                                            </p>
                                                                        </div>

                                                                        <div className="col-md-3 text-right">
                                                                            <button className="arrow no-border">
                                                                                <i className="fa fa-angle-down"></i>
                                                                            </button>
                                                                            <button
                                                                                onClick={() =>
                                                                                    this.deleteReturnRow(r.id)
                                                                                }
                                                                                className="btn no-border remove"
                                                                            >
                                                                                <i className="ti ti-close text-danger"></i>
                                                                            </button>
                                                                        </div>

                                                                        <div className="col-md-8 col-md-offset-4">
                                                                            <div className="row row-inactive">
                                                                                <div>
                                                                                    {r.purchaseItems.map((p, k) => {
                                                                                        return (
                                                                                            <div
                                                                                                key={k}
                                                                                                className={`listing ${this.checkAlreadyAdded(
                                                                                                    p.id
                                                                                                )}`}
                                                                                            >
                                                                                                <div className="body">
                                                                                                    <div
                                                                                                        className="body-top">
                                                                                                        <h4>
                                                                                                            {
                                                                                                                p.locatedProductVariant
                                                                                                                    .variant.product.name
                                                                                                            }{" "}
                                                                                                            {"(" +
                                                                                                                p.locatedProductVariant
                                                                                                                    .variant.product.brand
                                                                                                                    .name +
                                                                                                                ")"}
                                                                                                        </h4>
                                                                                                        <div
                                                                                                            className="staff">
                                                                                                            <img
                                                                                                                data-toggle="modal"
                                                                                                                data-target="#saveReturnActivity"
                                                                                                                className="img-circle"
                                                                                                                width="20"
                                                                                                                src={this.findTechnician(
                                                                                                                    p.technician.id,
                                                                                                                    "image"
                                                                                                                )}
                                                                                                                alt=""
                                                                                                            />{" "}
                                                                                                            {this.findTechnician(
                                                                                                                p.technician.id,
                                                                                                                "nick"
                                                                                                            )}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="body-bottom">
                                                                                                        <h4>Qty: {p.quantity}</h4>
                                                                                                        <h4>
                                                                                                            Returned:{" "}
                                                                                                            {this.state.returnQty
                                                                                                                .length > 0
                                                                                                                ? this.returnQty(p.id)
                                                                                                                : 0}
                                                                                                        </h4>
                                                                                                        <h4>
                                                                                                            Price:
                                                                                                            ${" "}
                                                                                                            {
                                                                                                                p.locatedProductVariant
                                                                                                                    .salePrice
                                                                                                            }
                                                                                                        </h4>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="action">
                                                                                                    <button
                                                                                                        onClick={() =>
                                                                                                            this.selectSingleReturn(p)
                                                                                                        }
                                                                                                        data-toggle="modal"
                                                                                                        data-target="#saveReturnActivity"
                                                                                                        className="btn no-border"
                                                                                                    >
                                                                                                        <i className="ti ti-save text-info"></i>
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                </div>
                                            )}

                                            <div className="col-md-12">
                                                <div className="switcher-spacer"></div>
                                            </div>
                                        </div>
                                        {tableList.length > 0 && (
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="table-responsive">
                                                        <div className="take-action">
                                                            <div className="brand-filter">
                                                                <select
                                                                    name="brndId"
                                                                    onChange={this.changeBrand}
                                                                    className="brands"
                                                                >
                                                                    <option value="all">All</option>
                                                                    {this.app &&
                                                                        this.app.brands.map((b, key) => {
                                                                            return (
                                                                                <option key={key} value={b.id}>
                                                                                    {b.name}
                                                                                </option>
                                                                            );
                                                                        })}
                                                                </select>
                                                                <div className="pagination-wrapper">
                                                                    <ul className="pager">
                                                                        <li>
                                                                            <a
                                                                                onClick={this.getPreviousTableList}
                                                                                style={{cursor: 'pointer'}}
                                                                            >
                                                                                <i className="fa fa-angle-left"></i>
                                                                            </a>
                                                                        </li>
                                                                        <li>
                                                                            <a
                                                                                onClick={this.getNextTableList}
                                                                                style={{cursor: 'pointer'}}
                                                                            >
                                                                                <i className="fa fa-angle-right"></i>
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="global-action">
                                                                <button
                                                                    onClick={() =>
                                                                        this.finalizeActivity("", "all")
                                                                    }
                                                                    className="no-border"
                                                                >
                                                                    <i className="fa fa-check text-info fa-2x"></i>
                                                                </button>
                                                                <button
                                                                    onClick={this.deleteMultiple}
                                                                    className="no-border"
                                                                >
                                                                    <i className="fa fa-trash text-danger fa-2x"></i>
                                                                </button>
                                                            </div>
                                                        </div>

                                                        <table className="table" id="waiting-table">
                                                            <thead>
                                                            <tr>
                                                                <th>Name</th>
                                                                <th>Brand</th>
                                                                <th>Sale Price</th>
                                                                <th>Quantity</th>
                                                                <th>Status</th>
                                                                <th className="text-right">Action</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {tableList.length == 0 && (
                                                                <tr>
                                                                    <td align="center" colSpan="6">
                                                                        <h3>Records Not Found!</h3>
                                                                    </td>
                                                                </tr>
                                                            )}

                                                            {tableList.length > 0 &&
                                                                tableList.map((a, k) => {
                                                                    return (
                                                                        <tr key={k}>
                                                                            <td>
                                                                                <div className="form-check">
                                                                                    <input
                                                                                        onChange={this.handleCheckbox}
                                                                                        value={a.id}
                                                                                        type="checkbox"
                                                                                        id={`return-${a.id}`}
                                                                                        className="form-check-input"
                                                                                    />
                                                                                    <label
                                                                                        className="form-check-label"
                                                                                        htmlFor={`return-${a.id}`}
                                                                                    >
                                                                                        {
                                                                                            a.locatedVariant.variant.product
                                                                                                .name
                                                                                        }
                                                                                    </label>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    a.locatedVariant.variant.product
                                                                                        .brand.name
                                                                                }
                                                                            </td>
                                                                            <td>${a.locatedVariant.salePrice}</td>
                                                                            <td>{a.quantity}</td>
                                                                            <td>
                                                                                <label className="label label-info">
                                                                                    {a.status}
                                                                                </label>
                                                                            </td>
                                                                            <td align="right">
                                                                                <button
                                                                                    onClick={() =>
                                                                                        this.finalizeActivity(
                                                                                            a.id,
                                                                                            "single"
                                                                                        )
                                                                                    }
                                                                                    className="no-border"
                                                                                >
                                                                                    <i className="fa fa-check text-info"></i>
                                                                                </button>
                                                                                <button
                                                                                    data-target="#updateActivity"
                                                                                    data-toggle="modal"
                                                                                    onClick={() => this.editActivity(a)}
                                                                                    className="no-border"
                                                                                >
                                                                                    <i className="fa fa-pencil custom-info"></i>
                                                                                </button>
                                                                                <button
                                                                                    onClick={() =>
                                                                                        this.deleteActivity(a.id)
                                                                                    }
                                                                                    className="no-border"
                                                                                >
                                                                                    <i className="fa fa-trash text-danger"></i>
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>

                            {this.state.loading == true && (
                                <div className="custom-loading">
                                    <div className="loading">
                                        <i className="fa fa-spinner fa-spin"></i>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div
                    data-backdrop="static"
                    data-keyboard="false"
                    className="modal fade"
                    id="updateActivity"
                    role="dialog"
                    aria-labelledby="exampleModalLabel1"
                >
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    onClick={this.closeThisModal}
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                                <h4 className="modal-title" id="exampleModalLabel1">
                                    Update Activity
                                </h4>
                            </div>

                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Product</label>
                                            <NewProductSearchBar
                                                place={this.state.productName}
                                                id="newId"
                                                onProductSelect={this.onProductSelect}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Brand</label>
                                            <input
                                                readOnly
                                                name="brandName"
                                                value={this.state.brandName}
                                                onChange={this.changeHandler}
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Quantity</label>
                                            <input
                                                name="variantQuantity"
                                                value={this.state.variantQuantity}
                                                onChange={this.changeHandler}
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Cost</label>
                                            <input
                                                readOnly
                                                name="salePrice"
                                                value={this.state.salePrice}
                                                onChange={this.changeHandler}
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    {this.state.adjustReason != "" && (
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="">Adjustment Reason</label>
                                                <textarea
                                                    onChange={this.changeHandler}
                                                    name="adjustReason"
                                                    rows="7"
                                                    className="form-control"
                                                    value={this.state.adjustReason}
                                                ></textarea>
                                            </div>
                                        </div>
                                    )}
                                    {this.state.invoiceNumber != "" && (
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="">Adjustment Reason</label>
                                                <input
                                                    type="text"
                                                    value={this.state.invoiceNumber}
                                                    name="invoiceNumber"
                                                    onChange={this.changeHandler}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <button
                                                onClick={this.updateActivity}
                                                className="btn btn-custom-info"
                                            >
                                                Update
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {this.state.loading == true && (
                                <div className="custom-loading">
                                    <div className="loading">
                                        <i className="fa fa-spinner fa-spin"></i>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div
                    data-backdrop="static"
                    data-keyboard="false"
                    className="modal fade"
                    id="saveReturnActivity"
                    role="dialog"
                    aria-labelledby="exampleModalLabel1"
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    onClick={this.closeThisModal}
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                                <h4 className="modal-title" id="exampleModalLabel1">
                                    Add Return
                                </h4>
                            </div>

                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Return Qty:</label>

                                            <div className="quantity-counter">
                                                <div className="count-grabber">
                                                    <input
                                                        readOnly
                                                        type="text"
                                                        className="form-control"
                                                        value={this.state.variantQuantity}
                                                    />
                                                    <div className="btns">
                                                        <div
                                                            onClick={this.incrementQty}
                                                            className="incrmntbtn"
                                                        >
                                                            <i className="fa fa-caret-up"></i>
                                                        </div>
                                                        <div
                                                            onClick={this.decrementQty}
                                                            className="drmntbtn"
                                                        >
                                                            <i className="fa fa-caret-down"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label htmlFor="">Reason</label>
                                            <textarea
                                                rows="5"
                                                onChange={this.changeHandler}
                                                name="adjustReason"
                                                className="form-control"
                                                value={this.state.adjustReason}
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group text-right">
                                            <button
                                                onClick={this.saveReturnActivity}
                                                className="btn btn-custom-info"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {this.state.loading == true && (
                                <div className="custom-loading">
                                    <div className="loading">
                                        <i className="fa fa-spinner fa-spin"></i>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    findTechnician(technicianId, query) {
        // eslint-disable-next-line
        let technician = this.app.locations[0].technicians.find(
            (technician) => technician.id == technicianId
        );

        if (technician)
            if (query == "image") {
                return technician.user.imageUrl;
            }
        if (query == "nick") {
            return technician.user.names.nick;
        } else {
            return technician.user.fullName;
        }

        return 0;
    }

    checkAlreadyAdded = (id) => {
        const added = this.state.tableList.find(
            (a) => a.visitPurchaseItem.id == id
        );
        if (added) {
            return "added";
        } else {
            return "";
        }
    };
    returnQty = (id) => {
        const qty = this.state.returnQty.find((r) => r.id == id);
        if (qty) {
            return qty.qty;
        } else {
            return 0;
        }
    };

    getNextTableList = () => {
        var offset = this.state.pageOffset + 1;
        const {activitiesList, pId} = this.state;
        if (activitiesList && activitiesList.length > 5) {
            inventoryService
                .getAllOpenIntake(offset, this.state.inventoryActivityType)
                .then((activitiesList) => {
                    this.setState({
                        activitiesList,
                        tableList: activitiesList,
                        pageOffset: offset,
                    });
                });
        }
    };

    getPreviousTableList = () => {
        var offset = this.state.pageOffset - 1;
        if (offset >= 0) {
            inventoryService
                .getAllOpenIntake(offset, this.state.inventoryActivityType)
                .then((activitiesList) => {
                    this.setState({
                        activitiesList,
                        tableList: activitiesList,
                        pageOffset: offset,
                    });
                });
        }
    };
}

export default InventoryActivity;
