import React, { Component } from "react";
import NewProductSearchBar from "./newProductSearchBar";
import ProductSearchBar from './productSearchBar'
import InlineMessage from './inlineMessage';

class ProductActivityRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      productSuggestions: [],
      selectedProduct: {
        variantCost: "",
        variantQuantity: 0,
        invoiceNumber: "",
        variant: "",
      },
      products: [],
      invoiceNumber: "",
      appt: "",
      adjustReason: "",
      place: "",
      errors: {},
      productName: ''
    };

    this.onProductSelect = this.onProductSelect.bind(this);
    this.deleteRow = this.deleteRow.bind(this);
  }

  onProductSelect(p) {
    this.setState({
      selectedProduct: {
        variantQuantity: p.quantity,
        salePrice: p.salePrice,
        variant: p.variant,
        invoiceNumber: "",
      },
    });

    const products = {};
    products.id = p.id;
    products.name = p.variant.product.name;
    products.index = this.props.index;
    products.variantQuantity = p.quantity;
    products.locatedVariant = p.id;
    // eslint-disable-next-line
    products.invoiceNumber =
      this.state.selectedProduct.invoiceNumber != ""
        ? this.state.selectedProduct.invoiceNumber
        : this.state.invoiceNumber;
    this.props.getCollection(products);
  }
  changeHandler = (e) => {
    const { name, value } = e.target;
    this.setState({
      selectedProduct: {
        ...this.state.selectedProduct,
        [name]: value,
      },
    });

    this.props.getStateChanges(this.props.index, { [name]: value });
  };

  addNewActivity = (i) => {
    this.props.saveNewActivity(i);
  };
  finalizeActivity = () => {
    this.props.finalizeActivity();
  };

  clearPlace = () => {
    this.setState({
      place: "",
    });
  };
  clearScanner = () => {
    this.setState({
      productName: ''
    })
  }

  deleteRow() {
    this.props.onRowDelete(this.props.index);
  }

  render() {
    const { selectedProduct, errors } = this.state;

    return (
      <React.Fragment>
        <div className="row">
          <div className="single-intake-list">
            <div
              className={
                // eslint-disable-next-line
                this.props.showReasonCol == true ? "col-md-3" : "col-md-6"
              }
            >
              <div className="form-group">

                <ProductSearchBar
                  id="productId"
                  place={this.props.place}
                  scanningName={this.state.productName}
                  clearScanner={this.clearScanner}
                  clearPlace={this.props.clearPlace}
                  setPlace={this.props.setPlace}
                  onProductSelect={this.onProductSelect} />
                <p className="text-danger error">{errors.product &&
                  <InlineMessage text={errors.product} />}</p>


              </div>
            </div>
            <div className="col-md-2 text-center">
              <div className="form-group">
                <input
                  type="text"
                  readOnly
                  name="salePrice"
                  onChange={this.changeHandler}
                  value={
                    // eslint-disable-next-line
                    `$${selectedProduct.variant != ""
                      ? selectedProduct.salePrice
                      : 0
                    }`
                  }
                  className="form-control"
                />
              </div>
            </div>
            <div
              className={
                // eslint-disable-next-line
                this.props.showApptSearchCol == true
                  ? "col-md-1 text-center"
                  : "col-md-2 text-center"
              }
            >
              <div className="form-group">
                <input
                  className="form-control"
                  name="variantQuantity"
                  type="text"
                  onChange={this.changeHandler}
                  value={selectedProduct.variantQuantity}
                />
              </div>
            </div>
            {
              // eslint-disable-next-line
              this.props.showApptSearchCol == true && (
                <div className="col-md-2 text-center">
                  <div className="form-group">
                    <input
                      name="appt"
                      type="text"
                      value={this.state.appt}
                      className="form-control"
                      onChange={this.changeHandler}
                    />
                  </div>
                </div>
              )
            }
            {
              // eslint-disable-next-line
              this.props.showReasonCol == true && (
                <div className="col-md-3 text-center">
                  <div className="form-group">
                    <input
                      type="text"
                      name="adjustReason"
                      value={this.adjustReason}
                      onChange={this.changeHandler}
                      className="form-control"
                    />
                  </div>
                </div>
              )
            }
            <div
              className={
                // eslint-disable-next-line
                this.props.showApptSearchCol === true
                  ? "col-md-1 text-right"
                  : "col-md-2 text-right"
              }
            >
              <div className="actions-btn">
                <a
                  onClick={
                    // eslint-disable-next-line
                    () => this.addNewActivity(this.props.index)
                  }
                  href="javascript:void(0)"
                >
                  <i className="fa fa-save custom-info fa-2x"></i>
                </a>
                <a
                  onClick={
                    // eslint-disable-next-line
                    () => this.deleteRow(this.props.index)
                  }
                  href="javascript:void(0)"
                >
                  <i className="fa fa-close text-danger fa-2x"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ProductActivityRow;
