import {appConstants} from '../_constants';

export function app(state = {}, action) {
    switch (action.type) {
        case appConstants.GET_SERVICES_REQUEST:
            return {
                services: [],
                servicesLoaded: false,
            }
        case appConstants.GET_SERVICES_SUCCESS:

            return {
                services: action.services,
                servicesLoaded: true,
            }


        case appConstants.GET_SERVICES_FAILURE:
            return {
                services: []
            };
        case appConstants.RELOADER_SUCCESS:
            return {
                fcmReload: action.reloader
            };

        case appConstants.GET_CATEGORY_INIT:
            return {
                catLoader: true,
                categories: []
            };
        case appConstants.GET_CATEGORY_SUCCESS:
            return {
                catLoader: false,
                categories: action.categories
            };
        case appConstants.GET_CATEGORY_FAILURE:
            return {
                catLoader: false,
                categories: []
            };

        default:
            return state
    }
}
