/* eslint-disable */
import React, {PureComponent} from 'react';
import CheckOut from "./checkOut";
import noteImg from '../note.png'
import noteCstmr from '../note-icon.png'
import 'rc-datetime-picker/dist/picker.min.css';
import moment from 'moment';
import {DatetimePickerTrigger} from 'rc-datetime-picker';
import tasksImg from './../task.png'
import notesImg from './../note.png'
import {helperFunctions} from '../_helpers'
import {appService} from '../_services'
import InlineMessage from './inlineMessage';
import Select from 'react-select';
import 'rc-time-picker/assets/index.css';
import TimePicker from 'rc-time-picker';
import ServiceSearchBar from "./serviceSearchBar";
const format = 'hh:mm a';
const now = moment().hour(0).minute(0);


class ViewDetails extends PureComponent {
    constructor(props){
        super(props)
    this.app = JSON.parse(localStorage.getItem('app'));
    this.state = {
        customerNote: false,
        staffNote: false,
        chargeAmount: 0,
        cmodal: false,
        validAmount: '',
        serviceChargeAmount: null,
        productPurchaseAmount: null,
        creditPaidAmount: 0,
        cashPaidAmount: 0,
        checkPaidAmount: 0,
        giftCardPaidAmount: 0,
        rewardDeductionAmount: 0,
        discountDeductionAmount: 0,
        tipAmount: 0,
        taxAmount: 0,
        giftCardSaleAmount: 0,
        totalChargeAmount: 0,
        singleData: {},
        singleAppt: {},

    }

}


    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.singleAppt) {
            this.setState({
                singleAppt: nextProps.singleAppt,
                serviceChargeAmount: nextProps.singleAppt.serviceChargeAmount,
                productPurchaseAmount: nextProps.singleAppt.productPurchaseAmount,
                creditPaidAmount: nextProps.singleAppt.creditPaidAmount,
                cashPaidAmount: nextProps.singleAppt.cashPaidAmount,
                checkPaidAmount: nextProps.singleAppt.checkPaidAmount,
                giftCardPaidAmount: nextProps.singleAppt.giftCardPaidAmount,
                rewardDeductionAmount: nextProps.singleAppt.rewardDeductionAmount,
                discountDeductionAmount: nextProps.singleAppt.discountDeductionAmount,
                tipAmount: nextProps.singleAppt.tipAmount,
                taxAmount: nextProps.singleAppt.taxAmount,
                giftCardSaleAmount: nextProps.singleAppt.giftCardSaleAmount,
                totalChargeAmount: nextProps.singleAppt.totalChargeAmount,

            })
        }
    }


render() {

        const {singleAppt} = this.props
    console.log(singleAppt)


    return (

            <React.Fragment>

                {singleAppt && singleAppt != ''  && Object.keys(singleAppt).length > 0 &&
                <div className="client-info">
                    <div className="info-header">
                        <div className="client">
                            <div className="chat-image">
                                <img src={ singleAppt.client && singleAppt.client.imageUrl}
                                    alt=""/>
                            </div>
                            <div className="chat-body">
                                <div className="chat-text">
                                    <h3><strong>{ singleAppt.client.fullName}</strong></h3>
                                    <p><i className="fa fa-phone"></i> {helperFunctions.formatMobilePhone(singleAppt.client.mobileNumber)}</p>
                                    <p><i className="fa fa-dollar"></i> {singleAppt.client.rewardBalance && (singleAppt.client.rewardBalance*1).toFixed(2)}</p>
                                    <div className="appt-time">
                                    <p><i className="fa fa-calendar"></i>
                                        {singleAppt.bookedTime && helperFunctions.formatDateTimeWithDay(singleAppt.bookedTime,'latest')}</p>

                                         {singleAppt.confirmationTime &&<label className="label label-info">Confirmed</label>}
                                    </div>
                                    <p><i className="ti ti-mobile"></i> Source: <i className={this.getRequestSource(singleAppt.requestSource)}></i>
                                    </p>
                                </div>
                                <div className="confirm-btn-group">

                                     <div className="btn-rounded-with-image text-right">
                                            <div className="customer-notes">
                                                <button
                                                title="Customer Note"
                                                onClick={()=>this.setState({customerNote: !this.state.customerNote})}><img src={notesImg} alt=""/></button>
                                            </div>
                                            <div className="customer-task">
                                                <button
                                                title="Staff Note"
                                                onClick={()=>this.setState({staffNote: !this.state.staffNote})}
                                                ><img src={tasksImg} alt=""/></button>
                                            </div>
                                     </div>
                                 </div>
                                 {this.state.customerNote &&
                                 <div className="customer-notes note-wrapper">
                                          <div className="row">

                                            <div className="note-body">
                                                <div className="note-fields">
                                                    <div className="form-group">
                                                        <p className="form-control"><strong>Customer Notes</strong><button className="close-note customer-note-toggle" onClick={()=>this.setState({customerNote: !this.state.customerNote})}><i className="ti ti-close"></i></button></p>
                                                    </div>
                                                    <div className="form-group">
                                                        <textarea disabled className="form-control" defaultValue={singleAppt.customerNotes != '' ? singleAppt.customerNotes : 'NONE'}></textarea>
                                                    </div>


                                                </div>

                                            </div>

                                        </div>

                                     </div>
                                 }

                                 {this.state.staffNote &&
                                     <div className="staff-note note-wrapper">
                                          <div className="row">

                                            <div className="note-body">
                                                <div className="note-fields">
                                                    <div className="form-group">
                                                        <p className="form-control"><strong>Staff Notes</strong><button onClick={()=>this.setState({staffNote: !this.state.staffNote})} className="close-note staff-note-toggle"><i className="ti ti-close"></i></button></p>
                                                    </div>
                                                    <div className="form-group">
                                                        <textarea
                                                        name="staffNotes"
                                                        onChange={this.changeHandler}
                                                        className="form-control"
                                                        defaultValue={singleAppt.staffNotes != '' ? singleAppt.staffNotes : 'NONE'}>
                                                        </textarea>
                                                    </div>


                                                </div>


                                            </div>

                                        </div>

                                    </div>
                                }




                            </div>


                        </div>



                    </div>
                    <div className="past-appointments">
                        {
                            // eslint-disable-next-line
                            singleAppt.requestType != 'Shopping' &&
                            <div className="steamline">
                                {singleAppt && singleAppt != '' && Object.keys(singleAppt).length > 0 && singleAppt.visitTechnicians.filter(t => t.status != 'Cancelled').map((s, key) => {
                                    return <div key={key} className="sl-item">
                                        <div className="sl-left"><img
                                            src={this.findTechnician(s.technician.id, 'image')}
                                            alt="user" className="img-circle"/></div>
                                        <div className="sl-right">
                                            <div className="m-l-40">
                                                <p>
                                                    <b>{this.findServiceName(s.offeredService.id, s.offeredService.category.id)}</b> with <b>{this.findTechnician(s.technician.id, 'nick')}</b><br/>
                                                    <b>Time: </b>{helperFunctions.formatDateTime(s.expectedStartTime, 'time')} - {helperFunctions.formatDateTime(s.period.to, 'time')}<br/>

                                                    <b>Price: </b>
                                                    {s.chargeAmount != '-1' ? '$'+ (s.chargeAmount*1).toFixed(2) : 'Per Consult' }
                                                    <br/>
                                                    {s.technicianRequested != undefined &&
                                                    <b>Stylist
                                                        Preference: {s.technicianRequested == true ?
                                                            <strong
                                                                className="text-primary">YES</strong> :
                                                            <strong
                                                                className="text-warning">NO</strong>}</b>
                                                    }
                                                </p>
                                                <p className="pb35">
                                            <span className="pull-right">
                                                  {s.status == "Booked" &&
                                                  <button onClick={() => this.openUpdateServiceForm(s)}
                                                          className="btn m-t-10 m-r-5 btn-custom-info"
                                                          href="javascript:void(0)">Edit</button>
                                                  }
                                                {s.status == "Booked" && this.props.singleAppt.checkInOutAllowed == true &&
                                                <button
                                                    onClick={() => this.checkServiceIn(s.id)}
                                                    data-toggle="modal"
                                                    className="btn m-t-10 m-r-5 btn-info">Check In</button>
                                                }
                                                {s.status == "Checked In" &&
                                                <button onClick={() => this.openUpdateServiceForm(s)}
                                                        data-target="#updateAppointment"
                                                        data-toggle="modal"
                                                        className="btn m-t-10 m-r-5 btn-custom-info"
                                                        href="javascript:void(0)">Edit</button>
                                                }
                                                {s.status == "Checked In" &&
                                                <button
                                                    className="btn m-t-10 m-r-5 btn-warning">
                                                    <i className="fa fa-check-square-o"></i>
                                                    {s.period.from && helperFunctions.formatDateTime(s.period.from, 'time')}
                                                </button>
                                                }

                                                {s.status == "Booked" && this.props.singleAppt.checkInOutAllowed == true &&
                                                <button
                                                    data-toggle="modal"
                                                    data-target="#serviceCheckout"
                                                    data-dismiss="modal"
                                                    onClick={() => this.checkServiceOut(s.id, s.chargeAmount)}
                                                    className="btn m-t-10 m-r-5 btn-success"
                                                >Check Out</button>
                                                }
                                                {s.status == "Checked In" && this.props.singleAppt.checkInOutAllowed == true &&
                                                <button
                                                    data-toggle="modal"
                                                    data-target="#serviceCheckout"
                                                    data-dismiss="modal"
                                                    onClick={() => this.checkServiceOut(s.id, s.chargeAmount)}
                                                    className="btn m-t-10 m-r-5 btn-success"
                                                >Check Out</button>
                                                }

                                                {s.status == "Booked" &&
                                                <button
                                                    onClick={() => this.openCancelModal(singleAppt.visitTechnicians[key])}
                                                    data-target="#cancelReason"
                                                    data-toggle="modal"
                                                    data-dismiss="modal"
                                                    className="btn m-t-10 m-r-5 btn-danger">Cancel</button>
                                                }

                                                {s.status == "Checked In" &&
                                                <button
                                                    onClick={() => this.openCancelModal(singleAppt.visitTechnicians[key])}
                                                    data-target="#cancelReason"
                                                    data-toggle="modal"
                                                    data-dismiss="modal"
                                                    className="btn m-t-10 m-r-5 btn-danger">Cancel</button>
                                                }

                                                {s.status == "Checked Out" &&
                                                <button
                                                    className="btn m-t-10 m-r-5 btn-warning">
                                                    <i className="fa fa-check-square-o"></i>
                                                    {s.period.from && helperFunctions.formatDateTime(s.period.from, 'time')}
                                                </button>
                                                }

                                                {s.status == "Checked Out" &&
                                                <button
                                                    className="btn m-t-10 m-r-5 btn-info">
                                                    <i className="fa fa-check-square-o"></i>
                                                    {s.period.to ? helperFunctions.formatDateTime(s.period.to, 'time') : this.getCurrentTime()}
                                                </button>
                                                }
                                                {s.status == "Cancelled" &&
                                                <button
                                                    className="btn m-t-10 m-r-5 btn-danger">
                                                    &times; Canceled
                                                </button>
                                                }
                                                {s.status == "No Show" &&
                                                <button
                                                    className="btn m-t-10 m-r-5 btn-danger">
                                                    <i className="fa fa-eye"></i>
                                                    No Show
                                                </button>
                                                }


                                            </span>
                                                </p>
                                            </div>
                                        </div>

                                        {this.state[`service-${s.id}`] == true &&

                                        <div className="row">
                                            <div className="update-form">
                                                <button onClick={() => this.closeUpdateForm(s.id)}
                                                        className="closBtn">&times;</button>
                                                <div className="col-md-12">
                                                    <form onSubmit={this.updateSaveService}>

                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div
                                                                    className="form-group select-arrow">
                                                                    <label
                                                                        htmlFor="stylish">Service &nbsp;
                                                                        <span
                                                                            className="text-danger">*</span></label>
                                                                    <ServiceSearchBar
                                                                        id="service"
                                                                        mode="update"
                                                                        onServiceSelect={this.onServiceUpdateSelect}
                                                                        clearPlace={this.clearPlace}
                                                                        place={this.state[`value-${s.id}`] && this.state[`value-${s.id}`] != '' && this.state[`value-${s.id}`]}
                                                                    />

                                                                </div>

                                                            </div>
                                                            <div className="col-md-6">
                                                                {this.state[`techServices-${s.id}`].length !== 0 &&
                                                                <div
                                                                    className="form-group add-services">
                                                                    <label>Select Staff</label>
                                                                    <select
                                                                        className={`hidden technicians-select-update-${s.id}`}
                                                                        name={`technician-${s.id}`}
                                                                        value={this.state[`technician-${s.id}`]}
                                                                        onChange={this.changeUpdateTech}>

                                                                        {
                                                                            this.state[`techServices-${s.id}`].map((e, key) => {
                                                                                return <option
                                                                                    key={e.id}
                                                                                    value={e.id}>{e.name}</option>;
                                                                            })
                                                                        }
                                                                    </select>
                                                                    <div
                                                                        id={`technicians-select-div-update-${s.id}`}
                                                                        className="add-services"></div>

                                                                </div>
                                                                }
                                                            </div>


                                                        </div>

                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="endTime">Start
                                                                        Time &nbsp; <span
                                                                            className="text-danger">*</span></label>
                                                                    <div className="time-clock">
                                                                        <TimePicker
                                                                            showSecond={false}
                                                                            name={`startTime-${s.id}`}
                                                                            className="xxx"
                                                                            onChange={this.changeUpdateStartTime}
                                                                            format={format}
                                                                            placeholder={this.state[`startTime-${s.id}`] != '' ? moment(this.state[`startTime-${s.id}`]).format(format) : moment(singleAppt.bookedTime).format(format)}
                                                                            use12Hours
                                                                            inputReadOnly
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="endTime">End
                                                                        Time &nbsp; <span
                                                                            className="text-danger">*</span></label>
                                                                    <div className="time-clock">
                                                                        <TimePicker
                                                                            showSecond={false}
                                                                            name={`durat-${s.id}`}
                                                                            className="xxx"
                                                                            onChange={this.changeUpdateEndTime}
                                                                            format={format}
                                                                            placeholder={this.state[`durat-${s.id}`] && this.state[`durat-${s.id}`] != '' ? this.state[`durat-${s.id}`].format(format) : moment(s.period.to).format(format)}
                                                                            use12Hours
                                                                            inputReadOnly
                                                                        />

                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>


                                                        <div className="form-group text-right">
                                                            <button
                                                                className="btn btn-custom-info">Update
                                                                service
                                                            </button>

                                                        </div>

                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        }

                                    </div>
                                })}


                            </div>
                        }
                        {singleAppt && singleAppt != '' && Object.keys(singleAppt).length > 0 && singleAppt.purchaseItems && singleAppt.purchaseItems.length > 0 &&
                        <div className="table-responsive table-product">
                            <table className="table table-bordered">
                                <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Tech</th>
                                    <th>Qty</th>
                                    <th>Price</th>
                                </tr>
                                </thead>
                                <tbody>




                                {
                                    singleAppt.purchaseItems.map((s, k) => {
                                        return <tr key={k}>
                                            <td>{s.locatedProductVariant.variant.product.name}</td>
                                            <td><img width="30" className="img-circle"
                                                     src={this.findTechnician(s.technician.id, 'image')}/>
                                            </td>

                                            <td>{s.quantity}</td>
                                            <td>${(s.chargeAmount*1).toFixed(2)}</td>

                                        </tr>
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                        }

                    </div>


                    <div className="botom-info">
                        <p><b>Created By: </b>{singleAppt.createUser && singleAppt.createUser.names.nick && singleAppt.createUser.names.nick} ({helperFunctions.formatDateTimeWithDay(singleAppt.createdTime,'latest')})
                        </p>
                        <p><b>Check Out: </b>{singleAppt.lastUpdateUser && singleAppt.lastUpdateUser.names.nick && singleAppt.lastUpdateUser.names.nick} ({helperFunctions.formatDateTimeWithDay(singleAppt.updatedTime,'latest')})</p>
                        <div>
                            <button className="checkout-summary note btn btn-default">
                                Summary &nbsp;&nbsp; <i className="fa fa-angle-down"></i>
                            </button>

                            <div className="hidden-checkout">
                                <div className="row">
                                    <div>
                                        <div className="check-out-info">
                                            <div className="expense col-md-5">
                                                <ul className="list-group">
                                                    <li className="list-group-item">
                                                        <b>Costs: <span
                                                            className="text-warning cost">{this.state.productPurchaseAmount == 0 && this.state.serviceChargeAmount == 0 && this.state.tipAmount == 0 && this.state.taxAmount == 0 && this.state.giftCardSaleAmount == 0 && singleAppt.serviceChargeAmount == '-1' ? 'Per Consult' : singleAppt && '$' + (((singleAppt.totalChargeAmount != '-1' ? ((this.state.productPurchaseAmount * 1) + (this.state.serviceChargeAmount * 1) + (this.state.tipAmount * 1) + (this.state.taxAmount * 1) + (this.state.giftCardSaleAmount * 1)).toFixed(2) : 0)))} </span></b>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <p className="title-fade">Service Charge
                                                            Amount &nbsp; <span
                                                                className="text-danger">*</span></p>
                                                        <p className="title-amount">
                                                            <input
                                                                readOnly
                                                                type="text"
                                                                maxLength="9"
                                                                name="serviceChargeAmount"
                                                                value={(this.state.serviceChargeAmount*1).toFixed(2)}
                                                            />
                                                        </p>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <p className="title-fade">Products Purchase
                                                            Amount &nbsp; <span
                                                                className="text-danger">*</span></p>
                                                        <p className="title-amount">
                                                            <input
                                                                readOnly
                                                                type="text"
                                                                maxLength="9"
                                                                name="productPurchaseAmount"
                                                                value={(this.state.productPurchaseAmount*1).toFixed(2)}
                                                            />
                                                        </p>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <p className="title-fade">Gift Card Sale
                                                            Amount &nbsp; <span
                                                                className="text-danger">*</span></p>
                                                        <p className="title-amount">
                                                            <input
                                                                readOnly
                                                                type="text"
                                                                maxLength="9"
                                                                name="giftCardSaleAmount"
                                                                value={(this.state.giftCardSaleAmount*1).toFixed(2)}
                                                            />
                                                        </p>
                                                    </li>

                                                    <li className="list-group-item">
                                                        <p className="title-fade">Tip Amount &nbsp;
                                                            <span className="text-danger">*</span>
                                                        </p>
                                                        <p className="title-amount">
                                                            <input
                                                                readOnly
                                                                type="text"
                                                                name="tipAmount"
                                                                maxLength="9"
                                                                value={(this.state.tipAmount*1).toFixed(2)}
                                                            />
                                                        </p>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <p className="title-fade">Tax Amount &nbsp;
                                                            <span className="text-danger">*</span>
                                                        </p>
                                                        <p className="title-amount">
                                                            <input
                                                                readOnly
                                                                type="text"
                                                                maxLength="9"
                                                                name="taxAmount"
                                                                value={(this.state.taxAmount*1).toFixed(2)}
                                                            />
                                                        </p>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <p className="title-fade"><b>Total
                                                            Amount</b></p>
                                                        <p className="title-amount">
                                                            <input
                                                                readOnly
                                                                type="text"
                                                                maxLength="9"
                                                                name="totalChargeAmount"
                                                                value={this.state.productPurchaseAmount == 0 && this.state.serviceChargeAmount == 0 && this.state.tipAmount == 0 && this.state.taxAmount == 0 && this.state.giftCardSaleAmount == 0 && singleAppt.serviceChargeAmount == '-1' ? 'Per Consult' : singleAppt && '$' + (((singleAppt.totalChargeAmount != '-1' ? ((this.state.productPurchaseAmount * 1) + (this.state.serviceChargeAmount * 1) + (this.state.tipAmount * 1) + (this.state.taxAmount * 1) + (this.state.giftCardSaleAmount * 1)).toFixed(2) : 0)))}
                                                            />
                                                        </p>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="expense col-md-5 col-md-offset-1">
                                                <ul className="list-group">
                                                    <li className="list-group-item">
                                                        <b>Payments: <span
                                                            className="text-custom-info">($ {((this.state.creditPaidAmount * 1) + (this.state.cashPaidAmount * 1) + (this.state.checkPaidAmount * 1) + (this.state.rewardDeductionAmount * 1) + (this.state.giftCardPaidAmount * 1) + (this.state.discountDeductionAmount * 1)).toFixed(2)})</span></b>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <p className="title-fade">Credit Paid
                                                            Amount &nbsp; <span
                                                                className="text-danger">*</span></p>
                                                        <p className="title-amount">
                                                            <input
                                                                readOnly
                                                                type="text"
                                                                maxLength="9"
                                                                name="creditPaidAmount"
                                                                value={(this.state.creditPaidAmount*1).toFixed(2)}
                                                            />
                                                        </p>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <p className="title-fade">Cash Paid
                                                            Amount &nbsp; <span
                                                                className="text-danger">*</span></p>
                                                        <p className="title-amount">
                                                            <input
                                                                readOnly
                                                                type="text"
                                                                maxLength="9"
                                                                name="cashPaidAmount"
                                                                value={(this.state.cashPaidAmount*1).toFixed(2)}
                                                            />
                                                        </p>
                                                    </li>

                                                    <li className="list-group-item">
                                                        <p className="title-fade">Check Paid
                                                            Amount &nbsp; <span
                                                                className="text-danger">*</span></p>
                                                        <p className="title-amount">
                                                            <input
                                                                readOnly
                                                                name="checkPaidAmount"
                                                                type="text"
                                                                maxLength="9"
                                                                value={(this.state.checkPaidAmount*1).toFixed(2)}
                                                            />
                                                        </p>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <p className="title-fade">Gift Card Paid
                                                            Amount &nbsp; <span
                                                                className="text-danger">*</span></p>
                                                        <p className="title-amount">
                                                            <input
                                                                readOnly
                                                                name="giftCardPaidAmount"
                                                                type="text"
                                                                maxLength="9"
                                                                value={(this.state.giftCardPaidAmount*1).toFixed(2)}
                                                            />
                                                        </p>
                                                    </li>


                                                    <li className="list-group-item">
                                                        <p className="title-fade">Reward Points
                                                            Used &nbsp; <span
                                                                className="text-danger">*</span></p>
                                                        <p className="title-amount">
                                                            <input
                                                                readOnly
                                                                type="text"
                                                                name="rewardDeductionAmount"
                                                                value={(this.state.rewardDeductionAmount*1).toFixed(2)}
                                                            />
                                                        </p>
                                                    </li>

                                                    <li className="list-group-item">
                                                        <p className="title-fade">Discount
                                                            Amount &nbsp; <span
                                                                className="text-danger">*</span></p>
                                                        <p className="title-amount">
                                                            <input
                                                                readOnly
                                                                type="text"
                                                                name="discountDeductionAmount"
                                                                value={(this.state.discountDeductionAmount*1).toFixed()}
                                                            />
                                                        </p>
                                                    </li>


                                                </ul>

                                            </div>


                                        </div>
                                    </div>

                                </div>
                            </div>


                        </div>

                    </div>

                    </div>
                 }



            </React.Fragment>
        );
    }

        getRequestSource(reqSrc) {
        let src = '';
        src = reqSrc == "Phone Call" ? 'fa fa-phone text-warning custom mb0'
            : reqSrc == "Walk In" ? 'mdi mdi-run custom mb0'
                : reqSrc == "Android App" ? 'fa fa-android text-purple custom'
                    : reqSrc == "iOS App" ? 'fa fa-mobile text-purple custom'
                        : reqSrc == "Website" ? 'fa fa-desktop custom'
                            : reqSrc == "iPad App" ? 'fas fa-tablet custom'
                                : reqSrc == "Tablet App" ? 'mdi mdi-tablet-android custom'
                                    : ''

        return src;
    }
    findTechnician(technicianId,query){

        // eslint-disable-next-line
        let technician = this.app.locations[0].technicians.find(technician => technician.id == technicianId)
        if(technician)
            if(query == 'image'){
                return technician.user.imageUrl;
            }else{
                return technician.user.fullName;
            }

        return "";
    }

    findServicePrice(serviceId,techId, catId){

        let servicePrice = ""

        let s = this.app.locations[0].technicians.find(tech => tech.id == techId)
        .offeredServices.find(service => service.id == serviceId)

        if(s == null){
            s = this.app.offeredServiceCategories.find(c => c.id == catId)
                    .services.find(service => service.id == serviceId)
        }

        servicePrice = s.minimumFee == -1 && s.maximumFee == -1 ? "Per Consult" :
                s.minimumFee > -1 && s.maximumFee == -1 ? "From $" + s.minimumFee :
                (s.minimumFee > -1 && s.maximumFee > -1) && (s.minimumFee == s.maximumFee) ? "$" + s.minimumFee :
                (s.minimumFee > -1 && s.maximumFee > -1) && (s.minimumFee != s.maximumFee) ? "$" + s.minimumFee + " - $" + s.maximumFee : "";


        return servicePrice;
    }
    findServiceName(serviceId, categoryId){
        // eslint-disable-next-line
        let category = this.app.offeredServiceCategories.find(sc => sc.id == categoryId)
        if(category == null)
            return "Service not found"
        let service = category.services.find(s => s.id == serviceId)
        if(service == null)
            return "Service not found"
        return service.name;
    }



    checkPrice=(id)=>{
        const service = this.state.services.find(s=>s.id==id)
        if(service != null){
            const price = this.checkPriceType(service.minimumFee,service.maximumFee);
            return price
        }

    }
    checkUpdatePrice=(id)=>{
        const service = this.state[`services-${this.state.singleSVtId}`].find(s=>s.id==id)
        if(service != null){
            const price = this.checkPriceType(service.minimumFee,service.maximumFee);
            return price
        }

    }




    checkPriceType = (min, max) => {

        if (min == '-1' && max == '-1') {
            return ''
        } else if (min > 0 && max > 0 && min < max) {
            return min*1
        } else if (min > 0 && max == -1) {
            return min*1
        } else {
            return min*1;
        }
    }

    checkTechAttachedToService=(id)=>{
        const service = this.state.services.find(s=>s.id==id)
        if(service != null){
            return service.visitTechnicians
        }
    }
    checkTechUpdateAttachedToService=(id)=>{
        const service = this.state[`services-${this.state.singleSVtId}`].find(s=>s.id==id)
        if(service != null){
            return service.visitTechnicians
        }
    }



}

export default ViewDetails;
