/* eslint-disable */
import {Config} from '../config';
import {authHeader} from '../_helpers';

var config = Config()
const apiPath = config.serverUrl;


export const closeOutService = {
    getAllCloseOut,
    getNewCloseOut,
    changeStatus,
    deleteCloseOut
}

function getAllCloseOut(page, start, end) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include'
    };
    const locId = JSON.parse(localStorage.getItem('app')).locations[0].id;
    const size = config.recordsPageSize;
    const params = 'locId=' + locId + '&startDate=' + start + '&endDate=' + end + '&page=' + page + '&pageCount=' + size;
    return fetch(apiPath + 'close-outs?' + params, requestOptions).then(handleResponse);
}

function getNewCloseOut(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(data)
    };
    return fetch(apiPath + 'close-outs', requestOptions).then(handleResponse);
}

function changeStatus(id, data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(data)
    };
    return fetch(apiPath + 'close-outs/' + id, requestOptions).then(handleResponse);
}

function deleteCloseOut(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include'
    };
    return fetch(apiPath + 'close-outs/' + id, requestOptions).then(handleDeleteResponse);
}

function handleResponse(response) {
    if (!response.ok) {
        if (response.status == 401 || response.status == 403) {
            window.location = `/${window.location.href.split('/')[3]}/login`;
        }
        if (response.status == 400 || response.status == 409 || response.status == 404 || response.status == 422 || response.status == 401 || response.status == 500) {
            throw response;
        } else {
            return Promise.reject(response.statusText);
        }
    }
    if (response.length == 0)
        return {};
    return response.json();
}

function handleDeleteResponse(response) {

    if (!response.ok) {

        if (response.status == 401 || response.status == 403) {
            window.location = `/${window.location.href.split('/')[3]}/login`;
        }
        if (response.status == 400 || response.status == 409 || response.status == 404 || response.status == 422 || response.status == 401 || response.status == 500) {
            throw response;
        }

        return Promise.reject(response);

    }
}
