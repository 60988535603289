import React from "react";

const Footer = ({ openAddNew, addType, manageType }) => {
  const openAction = (type) => {
    document.querySelector(".search-result").classList.remove('visible')
    openAddNew(type)
  }
  return (
    <div className="dropdown-footer d-flex justify-center items-center">
      <button type={"button"} onClick={() => openAction(addType)}>Add New</button>
      <button onClick={() => openAction(manageType)} type="button">Manage</button>
    </div>
  );
};

export default Footer;
