import React from 'react';
import DragSortableList from 'react-drag-sortable'
function Sorting({list,changeOrder}) {

    const onSort = (sortedList) => {
        changeOrder(sortedList)
    }

    return (
        <React.Fragment>
            <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span
                    aria-hidden="true">×</span></button>
                <h4 className="modal-title" id="cancelLabelModel">Change technician display order</h4>

            </div>
            <div className="modal-body">
            <DragSortableList items={list} moveTransitionDuration={0.3} onSort={onSort} type="grid"/>
            </div>
        </React.Fragment>
    );
}

export default Sorting;
