export function Config() {
    switch (process.env.REACT_APP_ENV) {
        case "dev":
            return {
                serverUrl: "https://silverbird-api-dev0.herokuapp.com/",
                recordsPageSize: 9,
                STORE_ID: "(eterna-day-spa|salon-h2o|cloud-9-salon|eterna-lash-brow)",
                appVersion: "2.0.1",
            };
        case "prod":
            return {
                serverUrl: "https://silverbird-api-dev0.herokuapp.com/",
                recordsPageSize: 9,
                STORE_ID: "(eterna-day-spa|cloud-9-salon|salon-h2o|eterna-lash-brow)",
                appVersion: "2.0.1",
            };
        case "qa":
            return {
                serverUrl: "https://silverbird-api-dev0.herokuapp.com/",
                recordsPageSize: 9,
                STORE_ID: "(eterna-day-spa|cloud-9-salon|salon-h2o|eterna-lash-brow)",
                appVersion: "2.0.1",
            };
        default:
            return {
                //serverUrl: "https://api-v2-dev.silverbird.io/",
                //serverUrl: "http://localhost:8080/",
                serverUrl: "https://api-v2.silverbird.io/",
                recordsPageSize: 9,
                STORE_ID: "(eterna-day-spa|cloud-9-salon|salon-h2o|eterna-lash-brow)",
                firebaseAuthKey: 'AAAATI00lbI:APA91bEtpdTXNTbQ3gDgJxyS6ILxdWv3Jhkf4eu30sa4G3C2LoYMhrVrBpClTHAGP2E_gf6m6eozT77jtQJud9QOHBAHSwktZCu4_yAvY_qRlPK7-zSTWJ4HMbf-ipwIhdzeegDxdRCr'
            };
    }
}
