/* eslint-disable */
import React, { Component } from "react";
import ViewDetails from "./viewDetails";

class Details extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <ViewDetails singleAppt={this.props.details} />;
  }
}

export default Details;
