/* eslint-disable */
import React, {Component} from 'react';
import CheckOut from "./checkOut";
import 'rc-datetime-picker/dist/picker.min.css';
import moment from 'moment';
import {DatetimePickerTrigger} from 'rc-datetime-picker';
import tasksImg from './../task.png'
import notesImg from './../note.png'
import CustomerSearchBar from './customerSearchBar';
import {userService} from '../_services/user.service'
import ServiceSearchBar from './serviceSearchBar';
import InlineMessage from './inlineMessage';
import PastAppt from './pastAppt';
import {helperFunctions} from '../_helpers'
import 'rc-time-picker/assets/index.css';
import TimePicker from 'rc-time-picker';
import {reservationService} from '../_services'

const format = 'hh:mm a';
const now = moment().hour(0).minute(0);

class NewWaitList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            staffNote: '',
            editDate: false,
            service: false,
            selectedCustomer: '',

            selectedService: {},
            selectedServices: [],
            technicians: [],
            services: [],
            selectedTechnician: '',
            startTime: moment(),
            endTime: moment(),
            technician: [],
            techServices: '',
            serviceId: '',
            serviceName: '',
            servicePrice: '',
            buttonText: false,
            timeStart: '',
            timeEnd: '',
            singleService: '',
            requestSoruce: 'Phone Call',
            errors: {},
            key: 1,
            place: 'Type a name',
            smodal: false,
            notes: '',
            customerId: '',
            pastAppts: '',
            message: '',
            showService: false,
            periods: []


        }

        this.app = JSON.parse(localStorage.getItem('app'))
        this.onCustomerSelect = this.onCustomerSelect.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    getAllTechnicians() {
        userService.getAllTechniciansForCalendarReservation('all', 0).then(techs => {
            var bookableTechs = techs.filter(tech => tech.active && tech.bookable && tech.technicianServices.length > 0);
            this.setState({
                technicians: bookableTechs,
            });
        })
    }

    getAllServices() {
        const tech = this.state.selectedTechnician
        if (tech != '') {
            var techServices = this.state.technicians.find(t => t.id == this.state.selectedTechnician).technicianServices
            var techServicesDetail = []
            this.app.offeredServiceCategories.forsilEach(oc => {
                techServicesDetail = techServicesDetail.concat(oc.services.filter(s => techServices.find(ts => ts.service.id == s.id) != null))
            });

            this.setState({
                services: techServicesDetail
            })
        }
    }

    onCustomerSelect(c) {
        this.setState({
            selectedCustomer: c
        })
    }

    initializeServiceSelect() {
        this.getAllTechnicians()
        this.setState({
            service: !this.state.service,
            buttonText: !this.state.buttonText,
            smodal: !this.state.smodal
        })


    }

    handleChange(e) {
        const {name, value} = e.target;
        this.setState({
            [name]: value
        }, function () {
            if (name == "selectedTechnician") {
                this.getAllServices()
            }

            if (name == "selectedService") {
                this.setState({selectedService: this.state.services.find(s => s.id == value)})
            }
        })
    }

    changeTech = (e) => {
        const techs = this.state.technician.find(t => t.id == e.target.value)

        if (techs) {
            this.setState({
                message: 'Already added!'
            }, () => {
                setTimeout(() => {
                    this.setState({
                        message: ''
                    })
                }, 1000)
            })

        } else {
            if (e.target.value != 'any') {
                var techCollection = this.state.technician
                techCollection.push({
                    id: e.target.value
                })

                this.setState({
                    technician: techCollection
                })
            } else {
                this.setState({
                    technician: []
                })
            }

        }

    }
    removeTech = (id) => {
        const techs = this.state.technician.filter(t => t != id)

        if (techs) {
            this.setState({
                technician: techs
            })

        }

    }


    changeStartTime = (start) => {
        this.setState({
            startTime: start
        })
    }
    changeEndTime = (end) => {
        this.setState({
            endTime: end,
            showService: true
        })
    }


    onServiceSelect = (service) => {

        this.setState({
            ...this.state,
            serviceId: service.service.id,
            serviceName: service.service.name
        })

        if (service != "") {

            let techServices = [];
            let technicianIds = [];


            service.technicianServices.forEach((technician, key) => {
                const valid = this.checkValid(technician.technician.id);
                if (valid == 'valid') {
                    techServices.push({
                        key: key,
                        id: technician.technician.id,
                        name: technician.technician.user.names.nick,
                        price: technician.displayedPrice,
                        duration: helperFunctions.timeConvert(technician.duration),
                        durationInMinute: technician.durationInMinutes,
                        actualPrice: this.checkPriceType(technician.minimumFee, technician.maximumFee)

                    })
                }


            });
            this.setState({techServices: techServices});
            var techJson = [];
            var i = 0;
            techJson.push({
                text: "Any Staff",
                value: "any",
                selected: false,
                description: "Expertise: Any",
                imageSrc: "https://s3.amazonaws.com/zoracorp/bianca/default/user-no-image.png"
            })

            techServices.forEach(tech => {
                techJson.push({
                    text: tech.name,
                    value: tech.id,
                    selected: false,
                    description: '[' + tech.duration + ", " + tech.price + "]",
                    imageSrc: this.findTechnician(tech.id, 'image')
                })
                i++;
            })

            setTimeout(function () {
                window.initiateTechniciansSelect(techJson, '#technicians-select-div-multi', '#technicians-select-multi')
            }, 100);


        }

    }


    addSelectedService = () => {

        var service = {}
        service.key = this.state.key++
        service.servicename = this.state.serviceName
        service.technician = this.state.technician.length == 0 ? 'any' : this.state.technician
        service.service = this.state.serviceId
        service.from = this.state.startTime,
            service.to = this.state.endTime

        if (this.state.endTime.diff(this.state.startTime) < 1) {
            const errors = {};
            errors.difference = 'Please pick valid start and end period!'
            this.setState({
                errors
            })
        } else {
            $('#addServe').modal('toggle');
            $('#createWait').modal('toggle');
            var selectedServices = this.state.selectedServices
            selectedServices.push(service)

            this.setPlace()

            this.setState({
                ...this.state,
                selectedServices: selectedServices,
                startTime: moment(),
                endTime: moment(),
                errors: {},
                techServices: '',
                serviceName: '',
                technician: []

            })
        }

    }

    checkValid = (id) => {
        const t = this.app.locations[0].technicians.find(t => t.id == id)
        if (t) {
            if (t.bookable == true) {
                return 'valid'
            }
        } else {
            return 'Not valid'
        }
    }


    //remove product from the cart
    removeService = (key) => {
        const newSelectedServices = this.state.selectedServices.filter(s => s.key != key);
        this.setState({
            ...this.state,
            selectedServices: newSelectedServices
        })
    }

    validate = (data) => {

        const errors = {}
        if (!data.id) errors.product = 'Please select a product';
        if (!data.qty || data.qty < 1) errors.qty = 'Please enter valid quantity ';
        if (!data.price) errors.price = 'Please enter a valid price';
        if (!data.technicianId) errors.technicianId = 'Please select a stylist';


        return errors;
    }


    checkPriceType = (min, max) => {

        if (min == '-1' && max == '-1') {
            return 'Per Consult'
        } else if (min > 0 && max > 0 && min < max) {
            return min * 1
        } else if (min > 0 && max == -1) {
            return min * 1
        } else {
            return min * 1;
        }
    }

    saveWaitList = () => {

        const selectedServices = this.state.selectedServices
        const services = [];
        const period = [];
        for (var i = 0; i < selectedServices.length; i++) {
            var singleService = ''
            if (selectedServices[i].technician == 'any') {
                singleService = {
                    service: {
                        id: selectedServices[i].service
                    },
                    technicians: []

                }
            } else {
                singleService = {
                    service: {
                        id: selectedServices[i].service
                    },
                    technicians: selectedServices[i].technician,

                }
            }

            var time = {
                from: moment(selectedServices[i].from).format('YYYY-MM-DDTHH:mm:ss'),
                to: moment(selectedServices[i].to).format('YYYY-MM-DDTHH:mm:ss')
            }

            period.push(time)

            services.push(singleService)
        }
        const waitJSON = {
            client: {
                id: this.state.selectedCustomer.id ? this.state.selectedCustomer.id : this.props.customer.id
            },
            location: {
                id: this.app.locations[0].id
            },
            staffNotes: this.state.staffNote,
            periods: period,
            services: services
        }

        this.props.saveWaitList(waitJSON);
        $('#createWait').modal('toggle')
        this.setPlace()

        this.setState({
            techServices: '',
            selectedServices: [],
            selectedCustomer: false,
            serviceName: '',
            technician: []
        })

    }


    refreshCreateModal = () => {
        this.setState({
            selectedServices: [],
            techServices: [],
            technician: '',
            selectedCustomer: ''

        })
    }

    openMiniModal = (id) => {
        reservationService.getPastAppointments(id).then(a => {

            this.setState({
                pastAppts: a,
                miniModal: true
            })
        })
    }
    openMiniModalForOpen = (id) => {
        reservationService.getOpenAppointments(id).then(a => {

            this.setState({
                pastAppts: a,
                miniModal: true
            })
        })
    }


    setTime = () => {
        const periods = this.state.periods
        periods.push({
            from: this.state.startTime,
            to: this.state.endTime
        })

        this.setState({
            periods: periods
        })

    }

    componentDidMount = () => {
        if (this.props.customer) {
            userService.getCustomerInfo(this.props.customer).then(user => {
                this.setState({
                    selectedCustomer: user
                })
            })
        }
    }
    clearPlace = () => {
        this.setState({
            place: ''
        })
    }
    setPlace = () => {
        this.setState({
            place: 'Type a name'
        })
    }


    render() {

        const {customer} = this.props


        const {
            selectedCustomer,
            selectedProducts,
            selectedServices,
            technicians,
            selectedTechnician,
            services,
            selectedService,
            errors,
            startTime,
            endTime
        } = this.state
        return (
            <React.Fragment>
                <div data-backdrop="static" data-keyboard="false" className="modal fade" id="createWait">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button onClick={this.refreshCreateModal} type="button" className="close"
                                        data-dismiss="modal" aria-label="Close"><span
                                    aria-hidden="true">×</span></button>
                                <h4 className="modal-title" id="cancelLabelModel">Add a new waitlist</h4>
                            </div>
                            <div className="modal-body">
                                <div className="client-info addApt">
                                    <div className="info-header">
                                        <div className="row">
                                            <div className="col-md-6">

                                                {
                                                    this.props.selectNewCustomer == true &&
                                                    <div className="form-group">
                                                        <label>Client Name</label>
                                                        <CustomerSearchBar
                                                            id="newId"
                                                            place={this.state.place}
                                                            clearPlace={this.clearPlace}
                                                            setPlace={this.setPlace}
                                                            onCustomerSelect={this.onCustomerSelect}/>
                                                    </div>
                                                }
                                                {
                                                    this.props.selectNewCustomer == false && this.props.customer &&
                                                    <div className="form-group">
                                                        <label>Client Name</label>
                                                        <CustomerSearchBar
                                                            place={this.props.customer.fullName}
                                                            clearPlace={this.props.clearPlace}
                                                            mode="update"
                                                            id="newId"
                                                            onCustomerSelect={this.onCustomerSelect}/>
                                                    </div>
                                                }

                                            </div>

                                        </div>
                                        <div className="client">
                                            {
                                                selectedCustomer == '' && this.props.customer &&
                                                <React.Fragment>
                                                    <div className="chat-image">
                                                        <img src={customer.imageUrl} alt=""/>
                                                    </div>
                                                    <div className="chat-body">
                                                        <div className="chat-text">
                                                            <div>
                                                                <h3><strong>{customer.fullName}</strong></h3>
                                                                <p><i
                                                                    className="fa fa-phone"></i> {customer.mobileNumber}
                                                                </p>
                                                                <p><i
                                                                    className="fa fa-dollar"></i> {customer.rewardBalance ? (customer.rewardBalance * 1).toFixed(2) : '0.00'}
                                                                </p>

                                                                <div className="appt-time">
                                                                    <p><i className="fa fa-calendar-check-o"></i>
                                                                        {customer.lastVisit ?
                                                                            <a
                                                                                data-toggle="modal"
                                                                                data-target="#MiniModal"
                                                                                data-dismiss="modal"
                                                                                onClick={() => this.openMiniModal(customer.id)}
                                                                                href="javascript:void(0)">{customer.lastVisit.readableBookedTime} </a> : 'NONE'}

                                                                    </p>
                                                                </div>
                                                                <div className="appt-time">
                                                                    <p><i className="fa fa-random"></i>
                                                                        {customer.nextVisit ?
                                                                            <a
                                                                                onClick={() => this.openMiniModalForOpen(customer.id)}
                                                                                href="javascript:void(0)">{customer.nextVisit.readableBookedTime}  </a> : 'NONE'}

                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <hr/>


                                                        <div className="confirm-btn-group">
                                                            <div className="confirm-btn">
                                                                <button
                                                                    data-dismiss="modal" data-toggle="modal"
                                                                    data-target="#addServe"
                                                                    onClick={() => this.initializeServiceSelect()}
                                                                    className={!this.state.buttonText ? 'button-custom' : 'actve'}>Add
                                                                    Service
                                                                </button>

                                                            </div>

                                                        </div>


                                                    </div>
                                                </React.Fragment>

                                            }
                                            {
                                                selectedCustomer != '' &&
                                                <React.Fragment>
                                                    <div className="chat-image">
                                                        <img src={selectedCustomer.imageUrl} alt=""/>
                                                    </div>
                                                    <div className="chat-body">
                                                        <div className="chat-text">
                                                            <div>
                                                                <h3><strong>{selectedCustomer.fullName}</strong></h3>
                                                                <p><i
                                                                    className="fa fa-phone"></i> {selectedCustomer.mobileNumber}
                                                                </p>
                                                                <p><i
                                                                    className="fa fa-dollar"></i> {selectedCustomer.rewardBalance ? (selectedCustomer.rewardBalance * 1).toFixed(2) : '0.00'}
                                                                </p>

                                                                <div className="appt-time">
                                                                    <p><i className="fa fa-calendar-check-o"></i>
                                                                        {selectedCustomer.lastVisit ?
                                                                            <a
                                                                                data-toggle="modal"
                                                                                data-target="#MiniModal"
                                                                                data-dismiss="modal"
                                                                                onClick={() => this.openMiniModal(selectedCustomer.id)}
                                                                                href="javascript:void(0)">{selectedCustomer.lastVisit.readableBookedTime} </a> : 'NONE'}

                                                                    </p>
                                                                </div>
                                                                <div className="appt-time">
                                                                    <p><i className="fa fa-random"></i>
                                                                        {selectedCustomer.nextVisit ?
                                                                            <a
                                                                                onClick={() => this.openMiniModalForOpen(selectedCustomer.id)}
                                                                                href="javascript:void(0)">{selectedCustomer.nextVisit.readableBookedTime}  </a> : 'NONE'}

                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <hr/>


                                                        <div className="confirm-btn-group">
                                                            <div className="confirm-btn">
                                                                <button
                                                                    data-dismiss="modal" data-toggle="modal"
                                                                    data-target="#addServe"
                                                                    onClick={() => this.initializeServiceSelect()}
                                                                    className={!this.state.buttonText ? 'button-custom' : 'actve'}>Add
                                                                    Service
                                                                </button>

                                                            </div>

                                                        </div>


                                                    </div>
                                                </React.Fragment>

                                            }
                                        </div>


                                    </div>
                                    <div className="past-appointments waitlist">
                                        {selectedServices.length > 0 &&
                                            <React.Fragment>
                                                <div className="steamline">
                                                    {
                                                        selectedServices.map((s, k) => {
                                                            return <div key={k} className="sl-item">
                                                                <div className="sl-left">
                                                                    <img
                                                                        src={s.technician != 'any' ? this.findTechnician(s.technician[0].id, 'image') : 'https://s3.amazonaws.com/zoracorp/bianca/default/user-no-image.png'}
                                                                        alt="user" className="img-circle"/> <span
                                                                    className="more-tech">{s.technician != 'any' && s.technician.length > 1 && ` + ${s.technician.length}`}</span>
                                                                </div>
                                                                <div className="sl-right">
                                                                    <div className="m-l-40">
                                                                        <p>
                                                                            <b>{s.servicename}</b> with <b>{s.technician != 'any' ? this.findTechnician(s.technician[0].id, 'nick') : 'Any Technician'}
                                                                            <sub
                                                                                className="text-capitalize">{s.technician != 'any' && s.technician.length > 1 && `and  ${s.technician.length} others`}</sub></b>
                                                                            <br/>
                                                                            <b>Time: </b><b>{helperFunctions.formatDateTimeWithDay(moment(s.from).format('YYYY-MM-DDTHH:mm:ss'), 'latest')} - {helperFunctions.formatDateTimeWithDay(moment(s.to).format('YYYY-MM-DDTHH:mm:ss'), 'latest')} </b><br/>

                                                                        </p>
                                                                        <p className="pb35">
                                                                    <span className="pull-right">
                                                                        <button
                                                                            onClick={() => this.removeService(s.key)}
                                                                            className="btn m-t-10 m-r-5 btn-danger">Cancel</button>

                                                                    </span>

                                                                        </p>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        })
                                                    }

                                                </div>
                                                <div className="form-group">
                                                    <label>Staff Notes</label>
                                                    <textarea name="staffNote" value={this.state.staffNote}
                                                              onChange={this.handleChange}
                                                              className="form-control staff-note"></textarea>
                                                </div>
                                            </React.Fragment>
                                        }


                                    </div>

                                </div>


                            </div>
                            {this.state.selectedServices.length > 0 &&
                                <div className="modal-footer">
                                    <button onClick={this.saveWaitList} className="btn btn-custom-info">Save</button>

                                </div>
                            }
                            {this.props.loading == true &&
                                <div className="custom-loading">
                                    <div className="loading"><i className="fa fa-spinner fa-spin"></i></div>
                                </div>
                            }
                        </div>
                    </div>
                </div>


                <PastAppt pastAppts={this.state.pastAppts} closePastAppts={this.closePastAppts}/>


                <div data-backdrop="static" data-keyboard="false" className="modal fade" id="addServe">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <button data-toggle="modal" data-dismiss="modal" data-target="#createWait"
                                        className="close" onClick={() => this.closeThisModal()}>&times;</button>

                                <div className="col-md-12">
                                    {this.state.message != '' &&
                                        <h4 className="text-warning text-italic">{this.state.message}</h4>
                                    }
                                    <ul>
                                        {this.state.technician.length > 0 && this.state.technician.map((t, k) => {
                                            return <label className="label label-info"
                                                          key={k}>{this.findTechnician(t.id, 'nick')}
                                                <button className="btn btn-label-remover"
                                                        onClick={() => this.removeTech(t)}>X
                                                </button>
                                            </label>
                                        })}
                                    </ul>
                                </div>

                                <div className="col-md-12 switcher-spacer"></div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div
                                            className="form-group select-arrow">
                                            <label htmlFor="stylish">Service &nbsp;  <span
                                                className="text-danger">*</span></label>
                                            <ServiceSearchBar
                                                place={this.state.place}
                                                clearPlace={this.clearPlace}
                                                setPlace={this.setPlace}
                                                id="serviceId"
                                                onServiceSelect={this.onServiceSelect}/>

                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        {this.state.techServices.length !== 0 &&
                                            <div className="form-group add-services">
                                                <label>Select Staff</label>
                                                <select multiple={true} className="hidden"
                                                        id="technicians-select-multi" value={this.state.technician}
                                                        onChange={this.changeTech}>
                                                    <option value="any">Any Staff</option>
                                                    {
                                                        this.state.techServices.map((e, key) => {
                                                            return <option key={e.id}
                                                                           value={e.id}>{e.name}</option>;
                                                        })
                                                    }
                                                </select>
                                                <div id="technicians-select-div-multi"
                                                     className="add-services"></div>

                                            </div>
                                        }

                                        {this.state.techServices.length == 0 &&
                                            <div className="form-group add-services">
                                                <label>Select Staff</label>

                                                <input type="text" className="form-control" readOnly/>

                                            </div>
                                        }
                                        {errors.tech &&
                                            <p className="text-danger"><InlineMessage text={errors.tech}/></p>}


                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">

                                        <div className="form-group">
                                            <label>Start Time</label>
                                            <DatetimePickerTrigger
                                                moment={this.state.startTime}
                                                onChange={this.changeStartTime}>
                                                <input
                                                    type="text"
                                                    value={this.state.startTime.format('YYYY-MM-DD') + " " + helperFunctions.formatDateTimeWithDay(this.state.startTime, 'time')}
                                                    readOnly/>
                                            </DatetimePickerTrigger>
                                        </div>

                                    </div>

                                    <div className="col-md-6">

                                        <div className="form-group">
                                            <label>End Time</label>
                                            <DatetimePickerTrigger
                                                moment={this.state.endTime}
                                                onChange={this.changeEndTime}>
                                                <input
                                                    type="text"
                                                    value={this.state.endTime.format('YYYY-MM-DD') + " " + helperFunctions.formatDateTimeWithDay(this.state.endTime, 'time')}
                                                    readOnly/>
                                            </DatetimePickerTrigger>
                                        </div>

                                        {errors.difference &&
                                            <p className="text-danger"><InlineMessage text={errors.difference}/>
                                            </p>}

                                    </div>
                                </div>


                            </div>


                            <div className="modal-footer">
                                <button onClick={this.addSelectedService}
                                        className="btn btn-custom-info">Add Service
                                </button>
                            </div>
                        </div>
                    </div>
                </div>


            </React.Fragment>
        );
    }

    findServiceName = (serviceId, categoryId) => {
        // eslint-disable-next-line
        let category = this.app.offeredServiceCategories.find(sc => sc.id == categoryId)
        if (category == null)
            return "Service not found"
        let service = category.services.find(s => s.id == serviceId)
        if (service == null)
            return "Service not found"
        return service.name;
    }

    findTechnician(technicianId, query) {

        // eslint-disable-next-line
        let technician = this.app.locations[0].technicians.find(technician => technician.id == technicianId)
        if (technician)
            if (query == 'image') {
                return technician.user.imageUrl;
            } else if (query == 'nick') {
                return technician.user.names.nick;
            } else {
                return technician.user.fullName;
            }

        return "";
    }


    closeThisModal = () => {
        this.setState({
            buttonText: !this.state.buttonText,
            smodal: false,
            miniModal: false,
            customerId: '',
            pastAppts: '',
            technician: [],
            techServices: '',
            place: 'Search a name'
        })
    }
    closePastAppts = () => {
        $('#editWaitList').modal('toggle');
        $('#MiniModal').modal('toggle');
    }


    closePmodal() {

        this.setState({
            buttonText: !this.state.buttonText,
            pmodal: false
        })
    }

}

export default NewWaitList;