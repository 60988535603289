/* eslint-disable */
import React from 'react';

const InlineMessage = (props) => {
  return (
    <span style={{color: 'red'}}>{props.text}</span>
  )
}

export default InlineMessage;
