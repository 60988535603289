import {communicationConstants} from "../_constants/communication.constants";
import {reservationConstants} from "../_constants";

export const communicationAction = {
    procceedToReload,
    captureInfo
}


function procceedToReload(data){
    return dispatch =>{
        dispatch(success(data));
    }

    function success(data){return { type: communicationConstants.PROCESS_RELOAD, data }}
}
function captureInfo(info){
    return dispatch =>{
        dispatch(success(info));
    }

    function success(info){return { type: communicationConstants.CAPTURE_INFO, info }}
}
