import React, { useState } from 'react';
import './index.css'
const BrandForm = ({ cls, openAddNew, saveNew, errors }) => {
  const [brandName, setBrandName] = useState("");
  const inputRef = React.useRef(null);
  const changeHandler = (e) => {
    setBrandName(e.target.value)
  }

  const createNew = () => {
    saveNew(brandName, clearState)
  }

  const clearState = () => {
    setBrandName("")
  }

  const isValid = Object.keys(errors).length > 0 ? "is-invalid" : ""

  return (
    <React.Fragment>
      <div className="add-new-brand">
        <label>Enter new brand name*:</label>
        <input ref={inputRef} required className={`from-control ${isValid}`} type="text" name="brandName" value={brandName} onChange={changeHandler} />
        <div className="invalid-feedback">{errors && errors.brandName && errors.brandName}</div>
      </div>
      <div className="add-new-item-footer d-flex justify-between items-center">
        <button onClick={openAddNew} className="btn btn-danger">Cancel</button>
        <button onClick={createNew} className="btn btn-info">Save</button>
      </div>
    </React.Fragment>

  )
}

export default BrandForm;
