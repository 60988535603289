import { closeOutConstants } from '../_constants';
import { closeOutService } from '../_services';

export const closeoutAction = {
    getAllCloseOut
};

function getAllCloseOut(page,start,end) {
    return dispatch => {
        dispatch(loading())
        closeOutService.getAllCloseOut(page,start,end)
            .then(
                closeOut => {
                    dispatch(success(closeOut));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function success(closeOut) { return { type: closeOutConstants.GET_ALL_CLOSEOUT_SUCCESS,closeOut } }
    function failure(error) { return { type: closeOutConstants.GET_ALL_CLOSEOUT_FAILURE, error } }
    function loading() { return { type: closeOutConstants.GET_ALL_CLOSEOUT_REQUEST } }
}