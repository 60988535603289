import React, { Component } from "react";
import { inventoryService } from "../_services";
import Autosuggest from "react-autosuggest";

const getProductSuggestionValue = (suggestion) => `${suggestion.name}`;
const renderProductSuggestion = (suggestion) => (
  <div>
    <div className="product-list">
      <div className="product-left">
        <h4>{suggestion.name}</h4>
        <p>{suggestion.brand.name}</p>
      </div>
      <div className="product-right">
        <h4 className="custom-info">${suggestion.variants && suggestion.variants.length > 0 && suggestion.variants[0].salePrice}</h4>
        <h4>
          Qty: <b className="custom-info">{suggestion.variants && suggestion.variants.length > 0 && suggestion.variants[0].quantity}</b>
        </h4>
      </div>
    </div>
  </div>
);

//{`${suggestion.variant.product.name} ${suggestion.variant.mappedVisualAttributeValues != null ? suggestion.variant.mappedVisualAttributeValues[Object.keys(suggestion.variant.mappedVisualAttributeValues)[0]] : ''}`}

class NewProductSearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      productSuggestions: [],
    };
  }

  onProductNameChange = (e, { newValue }) => {
    if (this.props.getProductName) {
      this.props.getProductName(newValue)
    }
    this.props.clearPlace();
    this.setState({
      value: newValue,
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (inputLength === 0) return [];

    inventoryService
      .searchNewProduct(this.props.brands, inputValue, 5)
      .then((products) => {
        this.setState({
          productSuggestions: products,
        });
      });
  };

  onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    this.props.onProductSelect(suggestion);
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      productSuggestions: [],
    });
  };
  focusValue = (e) => {
    e.target.setSelectionRange(0, e.target.value.length);
  };

  render() {
    const { value, productSuggestions } = this.state;
    const inputProps = {
      // eslint-disable-next-line
      placeholder:
        this.props.place && this.props.place != ""
          ? this.props.place
          : "Type a product name",
      // eslint-disable-next-line
      value:
        !this.props.mode && this.props.place && this.props.place != ""
          ? this.props.place
          : value,
      type: "search",
      onChange: this.onProductNameChange,
      className: "form-control",
      id: this.props.id,
      onClick: this.focusValue,
    };

    return (
      <Autosuggest
        suggestions={productSuggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getProductSuggestionValue}
        renderSuggestion={renderProductSuggestion}
        onSuggestionSelected={this.onSuggestionSelected}
        inputProps={inputProps}
      />
    );
  }
}

export default NewProductSearchBar;
