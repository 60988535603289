/* eslint-disable */
import React from "react";
import {connect} from "react-redux";
import {userActions} from "../../_actions";
import {userService} from "../../_services";
import {appService} from "../../_services";
import {reservationActions} from "../../_actions";
import {reservationService} from "../../_services";

import qs from "qs";
import Footer from "../footer/footer";
import {helperFunctions} from "../../_helpers";
import {Link} from "react-router-dom";
import "rc-datetime-picker/dist/picker.min.css";
import moment from "moment";
import {DatetimePickerTrigger} from "rc-datetime-picker";
import EditCart from "../../_components/editCart";
import ServiceSearchBar from "../../_components/serviceSearchBar";
import "rc-time-picker/assets/index.css";
import TimePicker from "rc-time-picker";
import ProductSearchBar from "../../_components/productSearchBar";
import tasksImg from "../../task.png";
import notesImg from "../../note.png";

import "moment-timezone";
import jstz from "jstz";
import {DatePickerInput} from "rc-datepicker";
import "rc-datepicker/lib/style.css";
import Select2 from "react-select2-wrapper";
import CustomerSearchBar from "../../_components/customerSearchBar";
import AddNewWaitlist from "../../_components/addNewWaitlist";
import InlineMessage from "../../_components/inlineMessage";
import Plush from "../../_components/icons/plush";
import CheckOut from "../../_components/checkOut";
import CheckIn from "../../_components/checkIn";
import Details from "../../_components/details";
import AddNewShopping from "../../_components/addNewShopping";
import Persona from "../../_components/persona";
import {communicationAction} from "../../_actions/communication.actions";
import {outgoingAction} from "../../_actions";
import CompletedForm from "../../_components/completedForm";
import "./customerDetail.css";
import CustomTimePicker from "../../_components/custom-timer-picker";

const format = "hh:mm a";
const now = moment().hour(0).minute(0);
const b64toBlob = (b64Data, contentType, sliceSize) => {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, {type: contentType});
    return blob;
};

class CustomerDetailPage extends React.Component {
    _isMount = false;

    constructor(props) {
        super(props);
        const {dispatch} = this.props;
        this.app = JSON.parse(localStorage.getItem("app"));
        this.storeId = this.app.vanityId;
        let thisObj = this;
        var userRole = JSON.parse(localStorage.getItem("user")).role;
        this.canUpdate = false;
        this.myRef = React.createRef();
        this.loggedUser = JSON.parse(localStorage.getItem("user"));
        this.accessRole = userRole == "MANAGER" || "DIRECTOR" || "ADMIN" || "CASHIER" || "DIRECTOR" ? true : false;
        if (
            userRole === "CASHIER" ||
            userRole === "MANAGER" ||
            userRole === "DIRECTOR" ||
            userRole === "ADMIN" ||
            userRole === "CASHIER"
        )
            this.canUpdate = true;
        const userId = qs.parse(this.props.location.search.replace("?", "")).custId;

        userService.getAllRewards(userId, "all-earned", 0).then((res) => {
            this.setState({
                rewardLists: res,
            });
        });

        if (userId) {
            this.getCustomerInfo(userId);
            reservationService.getAllWaitList(0, "user-lctn", userId).then((res) => {
                this.setState({
                    waitlist: res,
                });
            });
        }
        if (userId) {
            this.getAllAnswer(userId);
        }

        this.state = {
            actionText: "Book",
            pageOffset: 0,
            user: {
                id: "",
                firstName: "",
                lastName: "",
                nickName: "",
                email: "",
                mobileNumber: "",
                gender: "",
                birthDate: "",
                emailNotification: false,
                smsNotification: false,
                doNotCallEnabled: false,
                appointmentSchedulingAllowed: true,
                profilePic: "",
                password: "",
                cPassword: "",
                addReward: 0,
                referralToken: "",
                pendingConsentForms: "",
                transientAccount: false,
            },
            userDetails: {},
            userId: "",
            submitted: false,
            passwordSubmitted: false,
            rewardSubmitted: false,
            rewardNotes: "",
            rewardType: "add",
            rewardError: false,
            open: false,
            apt: "open",
            loadMore: false,
            active: false,

            formula: [],
            singleFormula: {
                id: "",
            },

            formulas: {
                staffId: "",
                type: "",
                notes: "",
            },

            lastUseTime: moment(),
            submitFormula: false,
            updateFormSubmitted: true,
            earnType: "all-earned",
            searchQuery: "",

            consentPendingFormId: "",
            consentUrl: "",
            screenCapture: "",
            rewardLists: "",
            openAppts: [],
            pastAppts: [],
            selectedAppt: {
                serviceChargeAmount: 0,
                productPurchaseAmount: 0,
                tipAmount: 0,
                totalChargeAmount: 0,
                discountDeductionAmount: 0,
                rewardDeductionAmount: 0,
                creditPaidAmount: 0,
                cashPaidAmount: 0,
                checkPaidAmount: 0,
                giftCardPaidAmount: 0,
            },
            rescheduledAppt: {
                id: 0,
                category: 0,
                service: 0,
                technician: 0,
                reason: "",
            },

            editApptDetails: "",
            loading: false,
            selectNewCustomer: false,
            apptDate: "",
            selectedServices: "",
            selectedProduct: {},
            selectedProducts: [],
            techServices: "",
            errors: {},
            smodal: false,
            durat: "",
            key: 1,
            technician: "",
            qty: 1,
            productPrice: "",
            requestSoruce: "Phone Call",
            editDate: false,
            place: "",
            technicianName: "",
            mode: "",
            category: "",
            service: 0,
            services: [],
            technicianss: [],
            selectedServicess: [],
            step: 1,
            date: "DATE_RANGE",
            dateStart: "",
            dateEnd: "",
            time: "ANYTIME",
            timeStart: "",
            timeEnd: "",
            timeSelected: true,
            reservationExists: false,
            showSlots: false,
            nmodal: "",
            selectedAppointment: "",
            waitlist: "",

            selectedServicesss: [],
            techniciansss: [],
            techServicesss: [],
            startTime: moment(),
            endTime: moment(),
            staffNotes: "",
            wmodal: false,
            editMode: "save",
            servicesss: "",
            servicename: "",
            miniModal: false,
            wtid: "",
            customerDetails: "",
            cancelReason: "",
            notes: "",
            remainingStandingCount: "",
            getHTMLString: "",
            htmlContentString: "",
            openId: "",
            daysOfWeek: "",
            daysBetween: "",
            menuState: false,
            apptId: "",
            lastAppts: [],
            past: "past",
            checkOut: false,
            details: "",
            shoppingInfo: "",
            label: "",
            prevQuestions: [],
            con: "",
            types: ["SMS", "EMAIL", "VOICE", "PUSH"],
            directions: ["INBOUND", "OUTBOUND"],
            status: ["IN_PROGRESS", "COMPLETED", "FAILED", "NO_ANSWER"],
            com_offset: 0,
            con_errors: {},
            communication: [],
            consentForm: [],
            completedConsent: "",
            signedContent: "",
            categories: [],
        };

        if (userId) {
            dispatch(userActions.getCustomerFormulas(userId));
        }

        dispatch(userActions.getAllTechnicians(0));
        this.handleChange = this.handleChange.bind(this);
        this.onchangeStanding = this.onchangeStanding.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.submitPassword = this.submitPassword.bind(this);
        this.submitReward = this.submitReward.bind(this);
        this.updateCustomerSuccess = this.updateCustomerSuccess.bind(this);
        this.handleFormula = this.handleFormula.bind(this);
        this.submitHandlers = this.submitHandlers.bind(this);
        this.changeLastUseTime = this.changeLastUseTime.bind(this);
        this.updateHandler = this.updateHandler.bind(this);

        //fetch category
        this.getCategories();
    }

    getCategories() {
        appService
            .getCategories(0, 200)
            .then((categories) => {
                this.setState({categories});
                this.initializeServices();
            })
            .catch((e) => {
                console.log(e);
            });
    }

    getAllAnswer = (id) => {
        userService.getAllAnswers(id).then((questions) => {
            this.setState({
                prevQuestions: questions,
            });
        });
    };

    getCustomerInfo = (id) => {
        userService.getCustomerInfo(id).then((user) => {
            //console.log(user)
            this.setState({
                user: {
                    firstName: user.names.first,
                    lastName: user.names.last,
                    nickName: user.names.nick,
                    email: user.emailAddress,
                    mobileNumber: user.mobileNumber,
                    gender: user.gender,
                    birthDate: user.birthDate,
                    emailNotification: user.emailNotificationEnabled,
                    smsNotification: user.smsNotificationEnabled,
                    doNotCallEnabled: user.doNotCallEnabled,
                    appointmentSchedulingAllowed: user.appointmentSchedulingAllowed,
                    profilePic: user.imageUrl,
                    password: "",
                    cPassword: "",
                    addReward: 0,
                    referralToken: user.referralToken,
                    bookedTime: user.lastVisit ? user.lastVisit.bookedTime : "NONE",
                    pendingConsentForms: user.pendingConsentForms,
                    transientAccount: user.transientAccount,
                },
                userDetails: user,
                userId: id,
                submitted: false,
                passwordSubmitted: false,
                rewardSubmitted: false,
                rewardNotes: "",
                rewardType: "add",
                rewardError: false,
                cls: false,

                customer: "",
                pastSingleAppt: "",
                loading: false,
                remainingStandingCount: user.remainingStandingCount,
                getHTMLString: [201, 202, 3],
            });
        });
    };

    closeThisModal() {
        this.setState({
            buttonText: !this.state.buttonText,
            smodal: false,
        });
    }

    changePrice = (e) => {
        this.setState({
            productPrice: e.target.value,
        });
    };

    handleFormula(e) {
        this.setState({
            formulas: {...this.state.formulas, [e.target.name]: e.target.value},
        });
    }

    openNewFormula = () => {
        this.setState({
            formulas: {
                staffId: "",
                type: "",
                notes: "",
            },
        });
    };

    changeLastUseTime(lastUseTime) {
        this.setState({
            lastUseTime,
        });
    }

    changeStartTime = (start) => {
        const durat =
            this.state.techServices.length > 0 &&
            this.state.techServices.find((t) => t.id == this.state.technician);
        var duration = "";
        if (durat) {
            duration = durat.durationInMinute;
            this.setState({
                timeStart: start,
                durat: moment(start).add(duration, "minutes"),
            });
        } else {
            this.setState({
                timeStart: start,
            });
        }
    };

    componentDidMount = async () => {
        const metaInfo = await appService.checkVersion();
        await helperFunctions.clearCache(metaInfo)
        this._isMount = true;
        if (this._isMount) {
            this.initializeOpenAppt();
        }

        $(".mobileNumber").mask("(000) 000-0000");
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.props.reloadData != undefined &&
            this.props.reloadData.bookedTime != undefined
        ) {
            if (Object.keys(this.props.reloadData).length > 0) {
                this.reloadTodayScreen(this.props.reloadData);
            }
        }
    }

    initializeOpenAppt = () => {
        reservationService
            .getOpenAppointments(
                qs.parse(this.props.location.search.replace("?", "")).custId
            )
            .then((a) => {
                this.setState({
                    openAppts: a,
                    loading: false,
                });
            });
    };
    initializePastAppt = () => {
        reservationService
            .getPastAppointments(
                qs.parse(this.props.location.search.replace("?", "")).custId
            )
            .then((a) => {
                this.setState({
                    openAppts: a,
                    loading: false,
                });
            });
    };

    submitPassword(e) {
        e.preventDefault();
        this.setState({passwordSubmitted: true});
        if (this.state.user.password == this.state.user.cPassword) {
            const newPassword = {
                password: this.state.user.password,
            };
            userService
                .updateCustomerPassword(this.state.userId, newPassword)
                .then((response) => {
                    window.toast("success", `Password is updated!`);
                    this.setState({passwordSubmitted: false});
                })
                .catch((e) => {
                    console.log(e);
                    this.setState({passwordSubmitted: false});
                });
        }
    }

    submitReward(e) {
        e.preventDefault();
        this.setState({rewardError: false});

        const {dispatch} = this.props;
        var reward = this.state.user.addReward;
        if (this.state.rewardType == "deduct") reward *= -1;
        if (reward + this.state.userDetails.rewardBalance < 0)
            this.setState({rewardError: true});
        else {
            this.setState({rewardSubmitted: true});
            const rewardJson = {
                amount: reward,
                notes: this.state.rewardNotes,
                owner: {id: this.state.userId},
            };
            dispatch(userActions.updateCustomerRewardBalance(rewardJson));
            var thisObj = this;
            this.state.userDetails.rewardBalance += +reward * 1;

            setTimeout(() => {
                userService
                    .getAllRewards(this.state.userId, "all-earned", 0)
                    .then((res) => {
                        this.setState({
                            ...this.state.rewardLists,
                            rewardLists: res,
                        });
                    });
                thisObj.setState({rewardSubmitted: false});
            }, 2000);
        }
    }

    handleChange(event) {
        const {name, value} = event.target;

        // eslint-disable-next-line
        const {user} = this.state;
        // eslint-disable-next-line
        if (name === "emailNotification") {
            this.setState({
                user: {
                    ...user,
                    emailNotification: !this.state.user.emailNotification,
                },
            });
        }
        // eslint-disable-next-line
        else if (name === "smsNotification") {
            this.setState({
                user: {
                    ...user,
                    smsNotification: !this.state.user.smsNotification,
                },
            });
        } // eslint-disable-next-line
        else if (name === "transientAccount") {
            this.setState({
                user: {
                    ...user,
                    transientAccount: !this.state.user.transientAccount,
                },
            });
        } // eslint-disable-next-line
        else if (name === "doNotCallEnabled") {
            this.setState({
                user: {
                    ...user,
                    doNotCallEnabled: !this.state.user.doNotCallEnabled,
                },
            });
        } else if (name === "appointmentSchedulingAllowed") {
            this.setState({
                user: {
                    ...user,
                    appointmentSchedulingAllowed: !this.state.user.appointmentSchedulingAllowed,
                },
            });
        } else if (name === "doNotCallEnabled") {
            this.setState({
                user: {
                    ...user,
                    doNotCallEnabled: !this.state.user.doNotCallEnabled,
                },
            });
        } else if (name == "rewardType") {
            this.setState({
                rewardType: value,
            });
        } else if (name == "rewardNotes") {
            this.setState({
                rewardNotes: value,
            });
        } else if (name == "staffNotes") {
            this.setState({
                staffNotes: value,
            });
        } else if (name == "cancelReason") {
            this.setState({
                [name]: value,
            });
        } else if (name == "notes") {
            this.setState({
                [name]: value,
            });
        } else {
            this.setState({
                user: {
                    ...user,
                    [name]: value,
                },
            });
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({submitted: true});
        const {user} = this.state;
        const {dispatch} = this.props;
        if (
            user.firstName &&
            user.lastName &&
            user.birthDate &&
            user.mobileNumber &&
            user.gender
        ) {
            user.mobileNumber = user.mobileNumber
                .replace(" ", "")
                .replace(")", "")
                .replace("(", "")
                .replace("-", "");
            const updatedUser = {
                names: {
                    first: user.firstName,
                    last: user.lastName,
                    nick: user.nickName,
                },
                mobileNumber: user.mobileNumber,
                birthDate: user.birthDate,
                emailAddress: user.email,
                gender: user.gender,
                emailNotificationEnabled: user.emailNotification,
                smsNotificationEnabled: user.smsNotification,
                transientAccount: user.transientAccount,
                doNotCallEnabled: user.doNotCallEnabled,
                appointmentSchedulingAllowed: user.appointmentSchedulingAllowed,
                referralToken: user.referralToken,
            };
            const errors = this.validateBirthDate();
            this.setState({errors});
            if (Object.keys(errors).length == 0) {
                dispatch(
                    userActions.updateCustomerProfile(this.state.userId, updatedUser)
                );
                const userDetails = this.state.userDetails;
                this.setState({
                    userDetails: {
                        ...userDetails,
                        mobileNumber: user.mobileNumber,
                        errors: {},
                    },
                });
                var thisObj = this;
                setTimeout(function () {
                    thisObj.setState({submitted: false});
                }, 2000);
            }
        }
    }

    getDuration = (timeB, timeS) => {
        const sum = moment(moment(timeB)).diff(moment());
        const minutes = parseInt(moment.duration(sum).asMinutes());
        return minutes;
    };
    validateBirthDate = () => {
        const errors = {};
        let duration = this.getDuration(this.state.user.birthDate, "");
        if (duration > 0) errors.birthDate = "Please enter valid date of birth";
        return errors;
    };

    getUser(userId) {
        const {dispatch} = this.props;
        dispatch(userActions.getCustomerFormulas(userId));
        dispatch(userActions.getAllRewards(userId, "all-earned", 0));
        userService.getCustomerInfo(userId).then((user) => {
            this.setState({
                user: {
                    firstName: user.names.first,
                    lastName: user.names.last,
                    nickName: user.names.nick,
                    email: user.emailAddress,
                    mobileNumber: user.mobileNumber,
                    gender: user.gender,
                    birthDate: user.birthDate,
                    emailNotification: user.emailNotificationEnabled,
                    smsNotification: user.smsNotificationEnabled,
                    doNotCallEnabled: user.doNotCallEnabled,
                    appointmentSchedulingAllowed: user.appointmentSchedulingAllowed,
                    profilePic: user.imageUrl,
                    password: "",
                    cPassword: "",
                    addReward: 0,
                    referralToken: user.referralToken,
                    pendingConsentForms: user.pendingConsentForms,
                    transientAccount: user.transientAccount,
                },
                userDetails: user,
                userId: userId,
                submitted: false,
                passwordSubmitted: false,
                rewardSubmitted: false,
                rewardNotes: "",
                rewardType: "add",
                rewardError: false,
            });
        });
    }

    togglePanel() {
        this.setState({
            open: !this.state.open,
        });
    }

    submitHandlers = (e) => {
        e.preventDefault();
        this.setState({
            ...this.state,
            submitFormula: true,
            loading: true,
        });
        const {formulas} = this.state;
        const {dispatch} = this.props;
        const formula = {
            user: {
                id: this.state.userId,
            },
            technician: {
                id: formulas.staffId,
            },
            type: formulas.type,
            notes: formulas.notes,
            lastUseTime: moment(this.state.lastUseTime).format("YYYY-MM-DDTHH:mm:ss"),
        };

        const errors = this.validateFormulaData(formula);
        this.setState({errors, loading: false});

        if (Object.keys(errors).length == 0) {
            dispatch(userActions.saveFormulData(formula));
            if (this.state.submitFormula) {
                window.DataUpdateSuccess();
            }
            this.setState({
                formulas: {
                    staffId: "",
                    type: "",
                    notes: "",
                    loading: false,
                    errors: {},
                },
            });
            $("#addNew").modal("toggle");
            setTimeout(() => {
                this.setState({submitFormula: false});
            }, 4000);
        }
    };

    validateFormulaData = (data) => {
        const errors = {};
        if (!data.type) errors.type = "Please select a formula type";
        if (!data.notes) errors.notes = "Please enter a note";
        if (!data.technician.id) errors.staffId = "Please select a staff";
        return errors;
    };

    openEditFormula = (itemId, usrId) => {
        const singleFormula = this.props.formulass.filter((f) => f.id == itemId);

        this.setState({
            ...this.state,
            formulas: {
                staffId: singleFormula[0].technician.id,
                type: singleFormula[0].type,
                notes: singleFormula[0].notes,
            },
            singleFormula: {
                id: singleFormula[0].id,
            },

            lastUseTime: moment(singleFormula[0].lastUseTime),
        });

        $("#editFormula").modal("toggle");
    };

    updateHandler(e) {
        e.preventDefault();
        this.setState({updateFormSubmitted: true});
        const {formulas} = this.state;
        const {dispatch} = this.props;
        const ss = {
            user: {
                id: this.state.userId,
            },
            technician: {
                id: formulas.staffId,
            },
            type: formulas.type,
            notes: formulas.notes,
            lastUseTime: moment(this.state.lastUseTime).format("YYYY-MM-DDTHH:mm:ss"),
        };

        dispatch(userActions.updateFormulData(ss, this.state.singleFormula.id));
        $("#editFormula").modal("toggle");
        setTimeout(() => {
            this.setState({updateFormSubmitted: false});
        }, 4000);
    }

    printDocument = () => {
        const thisObj = this;

        var HTML_Width = $(".consent-wrapper").width();

        var HTML_Height = $(".consent-wrapper").height();

        var top_left_margin = 15;

        var PDF_Width = HTML_Width + top_left_margin * 2;

        var PDF_Height = PDF_Width * 1.5 + top_left_margin * 2;
        var canvas_image_width = HTML_Width;
        var canvas_image_height = HTML_Height;
        var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
        html2canvas($(".consent-wrapper")[0]).then(function (canvas) {
            var imgData = canvas.toDataURL("image/jpeg", 0.1);

            var block = imgData.split(";");

            var contentType = block[0].split(":")[1]; // In this case "image/gif"

            var realData = block[1].split(",")[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."

            var blob = b64toBlob(realData, contentType);

            userService
                .saveConsentForm(
                    thisObj.state.formId,
                    qs.parse(thisObj.props.location.search.replace("?", "")).custId,
                    blob
                )
                .then((res) => {
                    $("#consentForm").modal("hide");
                    thisObj.getCustomerInfo(
                        qs.parse(thisObj.props.location.search.replace("?", "")).custId
                    );
                })
                .catch((e) => {
                    e.json().then((err) => {
                        Swal.fire({
                            title: "Error",
                            text: `${err.message}`,
                            type: "warning",
                            showCancelButton: false,
                            confirmButtonText: "Ok",
                        });
                    });
                });
        });
    };

    // postPdf=(formData)=>{
    //     console.log(formData)
    // }

    openConsentModal = (id) => {
        var iframe = this.myRef.current;
        this.setState(
            {
                //consentUrl: this.findConsentformNameUrl(id)
                consentUrl:
                    "https://s3.console.aws.amazon.com/s3/object/zoracorp/bianca/472822496/docs/meta/consent/Eyelash-051219.html",
            },
            () => {
                iframe.setAttribute("src", this.state.consentUrl);
            }
        );
    };

    openConsentModal = (id) => {
        this.setState({
            consentFormHtml: pdfHtml,
        });
    };

    changeAppt(e) {
        this.setState(
            {
                apt: e.target.value,
            },
            () => {
                if (this.state.apt == "past") {
                    this.initializePastAppt();
                } else {
                    this.initializeOpenAppt();
                }
            }
        );
    }

    //related to appointment

    openEditModal(id) {
        this.setState({
            ...this.state,
            loading: true,
            checkOut: false,
        });
        appService.getSingleVtService(id).then((res) => {
            this.setState({
                ...this.state,
                editApptDetails: res,
                loading: false,
            });
        });

        window.initiateSummaryCheckout();
    }

    clearEditApptModal = () => {
        this.setState({
            ...this.state,
            editApptDetails: "",
        });
    };

    confirmAppt = (id, time) => {
        this.setState({
            ...this.state,
            loading: true,
        });
        appService
            .confirmAppt(id, time)
            .then((res) => {
                this.setState({
                    ...this.state,
                    editApptDetails: res,
                    loading: false,
                });

                window.DataUpdateSuccess();
                reservationService
                    .getOpenAppointments(
                        qs.parse(this.props.location.search.replace("?", "")).custId
                    )
                    .then((a) => {
                        this.setState({
                            openAppts: a,
                        });
                    });
            })
            .catch((e) => {
                this.setState({
                    ...this.state,
                    loading: false,
                });
            });
    };

    apptAddUpdateService = (id, apttService) => {
        this.setState({
            ...this.state,
            loading: true,
        });
        if (id !== "Nill") {
            appService
                .UpdateService(id, apttService)
                .then((res) => {
                    appService
                        .getSingleVtService(this.state.editApptDetails.id)
                        .then((res) => {
                            this.setState({
                                ...this.state,
                                editApptDetails: res,
                                loading: false,
                            });
                        });
                    window.DataUpdateSuccess();
                    reservationService
                        .getOpenAppointments(
                            qs.parse(this.props.location.search.replace("?", "")).custId
                        )
                        .then((a) => {
                            this.setState({
                                openAppts: a,
                            });
                        });
                })
                .catch((e) => {
                    this.setState({
                        ...this.state,
                        loading: false,
                    });
                });
        } else {
            appService
                .apptAddUpdateService(apttService)
                .then((res) => {
                    appService
                        .getSingleVtService(this.state.editApptDetails.id)
                        .then((res) => {
                            this.setState({
                                ...this.state,
                                editApptDetails: res,
                                loading: false,
                            });
                        });

                    window.DataUpdateSuccess();
                    reservationService
                        .getOpenAppointments(
                            qs.parse(this.props.location.search.replace("?", "")).custId
                        )
                        .then((a) => {
                            this.setState({
                                openAppts: a,
                            });
                        });
                })
                .catch((e) => {
                    this.setState({
                        ...this.state,
                        loading: false,
                    });
                    e.json().then((err) => {
                        Swal.fire({
                            title: "Error",
                            text: `${err.message}`,
                            type: "warning",
                            showCancelButton: false,
                            confirmButtonText: "Yes, Merge!",
                        });
                    });
                });
        }
    };

    cancelServieFromAppt = (id, canelReason, count) => {
        this.setState({
            loading: true,
        });
        appService
            .cancelServieFromAppt(id, canelReason)
            .then((res) => {
                window.toast(
                    "error",
                    `The service has been deleted from the appointment`
                );
                /* eslint-disable */
                appService.getSingleVtService(this.state.viewDetails.id).then((res) => {
                    this.setState(
                        {
                            ...this.state,
                            viewDetails: res,
                            editApptDetails: res,
                            loading: false,
                        },
                        () => {
                            if (this.state.viewDetails.status != "Cancelled") {
                                $("#editModal").modal("show");
                            }
                        }
                    );
                });

                reservationService
                    .getOpenAppointments(
                        qs.parse(this.props.location.search.replace("?", "")).custId
                    )
                    .then((a) => {
                        this.setState({
                            openAppts: a,
                        });
                    });
            })
            .catch((e) => {
                this.setState({
                    loading: false,
                });
                e.json().then((err) => {
                    Swal.fire({
                        title: "Error!",
                        text: `${err.message}`,
                        type: "warning",
                        showCancelButton: false,
                    });
                });
            });
    };

    //check in service
    checkServiceIn = (vt) => {
        this.setState({
            ...this.state,
            loading: true,
        });
        appService
            .checkServiceIn(vt)
            .then((res) => {
                appService
                    .getSingleVtService(this.state.editApptDetails.id)
                    .then((v) => {
                        this.setState({
                            ...this.state,
                            editApptDetails: v,
                            loading: false,
                        });
                    });
                window.toast("success", "Appointment checked in successfully");
                reservationService
                    .getOpenAppointments(
                        qs.parse(this.props.location.search.replace("?", "")).custId
                    )
                    .then((a) => {
                        this.setState({
                            openAppts: a,
                        });
                    });
            })
            .catch((e) => {
                Swal.fire({
                    title: "Error",
                    text: `Check-in is only allowed for today's appointments`,
                    type: "warning",
                    showCancelButton: false,
                    confirmButtonText: "Ok!",
                });
                this.setState({
                    ...this.state,
                    loading: false,
                });
            });
    };

    refreshEditWaitModal = () => {
        this.setState({
            selectedServicesss: [],
        });
    };

    //check out service
    checkServiceOut = (vt, amount) => {
        this.setState({
            ...this.state,
            loading: true,
        });

        appService
            .checkServiceOut(vt, amount)
            .then((res) => {
                window.toast("success", "Service checked out successfully");

                appService
                    .getSingleVtService(this.state.editApptDetails.id)
                    .then((res) => {
                        this.setState({
                            ...this.state,
                            editApptDetails: res,
                            loading: false,
                        });
                    });
                reservationService
                    .getOpenAppointments(
                        qs.parse(this.props.location.search.replace("?", "")).custId
                    )
                    .then((a) => {
                        this.setState({
                            openAppts: a,
                        });
                    });
            })
            .catch((e) => {
                this.setState({
                    ...this.state,
                    loading: false,
                });
            });
    };

    techangeForProduct = (e) => {
        this.setState({
            technician: e.target.value,
        });
    };

    updateBookingTime = (id, timeJSON) => {
        appService
            .updateBookingTime(id, timeJSON)
            .then((res) => {
                appService
                    .getSingleVtService(this.state.editApptDetails.id)
                    .then((res) => {
                        this.setState({
                            ...this.state,
                            editApptDetails: res,
                            loading: false,
                        });
                    });
                window.DataUpdateSuccess();
                reservationService
                    .getOpenAppointments(
                        qs.parse(this.props.location.search.replace("?", "")).custId
                    )
                    .then((a) => {
                        this.setState({
                            openAppts: a,
                        });
                    });
            })
            .catch((e) => {
                Swal.fire({
                    title: "Error",
                    text: `Booking Time Update is failed`,
                    type: "warning",
                    showCancelButton: false,
                    confirmButtonText: "Ok!",
                });
                this.setState({
                    ...this.state,
                    loading: false,
                });
            });
    };

    appointmentCheckIn = (id, note) => {
        appService
            .appointmentCheckIn(id, note)
            .then((res) => {
                appService
                    .getSingleVtService(this.state.editApptDetails.id)
                    .then((res) => {
                        this.setState({
                            ...this.state,
                            editApptDetails: res,
                            loading: false,
                        });
                    });
                window.DataUpdateSuccess();
                reservationService
                    .getOpenAppointments(
                        qs.parse(this.props.location.search.replace("?", "")).custId
                    )
                    .then((a) => {
                        this.setState({
                            openAppts: a,
                        });
                    });
            })
            .catch((e) => {
                Swal.fire({
                    title: "Error",
                    text: `Check-in is only allowed for today's appointments`,
                    type: "warning",
                    showCancelButton: false,
                    confirmButtonText: "Ok!",
                });
                this.setState({
                    ...this.state,
                    loading: false,
                });
            });
    };

    //appointment check out

    openCancelModal = (id) => {
        this.setState({
            ...this.state,
            loading: true,
        });
        appService.getSingleVtService(id).then((res) => {
            this.setState({
                ...this.state,
                editApptDetails: res,
                loading: false,
            });
        });
    };

    cancelAppt = () => {
        const reason = {
            cancelReason: this.state.cancelReason,
        };
        Swal.fire({
            title: "Cancel Appointment",
            text: `Are you sure you want to cancel this appointment`,
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, Cancel it!",
            cancelButtonText: "No, keep it",
        }).then((result) => {
            if (result.value) {
                appService
                    .cancelAppt(this.state.editApptDetails.id, reason)
                    .then((res) => {
                        window.toast(
                            "error",
                            `The appointment has been canceled from the appointment`
                        );
                        /* eslint-disable */
                        appService
                            .getSingleVtService(this.state.editApptDetails.id)
                            .then((res) => {
                                this.setState({
                                    ...this.state,
                                    editApptDetails: res,
                                    cancelReason: "",
                                    loading: false,
                                });

                                $("#cancelPastAppt").modal("toggle");
                            });
                        this.getCustomerInfo(this.userId);
                        reservationService
                            .getOpenAppointments(
                                qs.parse(this.props.location.search.replace("?", "")).custId
                            )
                            .then((a) => {
                                this.setState({
                                    openAppts: a,
                                });
                            });
                    })
                    .catch((e) => {
                        this.setState({
                            ...this.state,
                            loading: false,
                        });
                    });
            }
        });
    };

    makeFirstBtnActive = () => {
        appService.getSalesData().then((data) => {
            this.setState({
                ...this.state,
                firstBtn: "actve",
                secondBtn: "",
                counting: "bookings",
                salesData: data.bookingsToday,
            });
        });
    };
    makeSecondBtnActive = () => {
        appService.getSalesData().then((data) => {
            this.setState({
                ...this.state,
                firstBtn: "",
                secondBtn: "actve",
                counting: "checkout",
                salesData: data.today,
            });
        });
    };

    saveProduct = (prJson, id) => {
        if (id == "nill") {
            appService.saveProduct(prJson).then((res) => {
                window.DataUpdateSuccess();
                appService
                    .getSingleVtService(this.state.editApptDetails.id)
                    .then((res) => {
                        this.setState({
                            ...this.state,
                            editApptDetails: res,
                            loading: false,
                        });
                    });
                reservationService
                    .getOpenAppointments(
                        qs.parse(this.props.location.search.replace("?", "")).custId
                    )
                    .then((a) => {
                        this.setState({
                            openAppts: a,
                        });
                    });
            });
        } else {
            appService.updateProduct(prJson, id).then((res) => {
                appService
                    .getSingleVtService(this.state.editApptDetails.id)
                    .then((res) => {
                        this.setState({
                            ...this.state,
                            editApptDetails: res,
                            loading: false,
                        });
                    });
                window.DataUpdateSuccess();
                reservationService
                    .getOpenAppointments(
                        qs.parse(this.props.location.search.replace("?", "")).custId
                    )
                    .then((a) => {
                        this.setState({
                            openAppts: a,
                        });
                    });
            });
        }
    };

    deleteProduct = (id) => {
        Swal.fire({
            title: "Delete Product",
            text: `Are you sure you want to delete this Product`,
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, keep it",
        }).then((result) => {
            if (result.value) {
                this.setState({
                    ...this.state,
                    loading: true,
                });
                appService.deleteProduct(id).then((res) => {
                    appService
                        .getSingleVtService(this.state.editApptDetails.id)
                        .then((res) => {
                            this.setState({
                                ...this.state,
                                editApptDetails: res,
                                loading: false,
                            });
                        });
                    window.toast(
                        "error",
                        `The product has been deleted from the appointment`
                    );
                    /* eslint-disable */

                    reservationService
                        .getOpenAppointments(
                            qs.parse(this.props.location.search.replace("?", "")).custId
                        )
                        .then((a) => {
                            this.setState({
                                openAppts: a,
                            });
                        });
                });
            } else {
                this.setState({
                    ...this.state,
                    loading: false,
                });
            }
        });
    };

    openAddToCartModal = (a) => {
        // const customerObj = {
        //     id: a.client.id,
        //     fullName: a.client.fullName,
        //     imageUrl: a.client.imageUrl,
        //     rewardBalance: this.state.userDetails.rewardBalance,
        //     mobileNumber: this.state.user.mobileNumber
        // }
        //
        //
        // var selectedServices = []
        // var selectedProducts = []
        //
        //
        // a.visitTechnicians.forEach((v, key) => {
        //
        //     selectedServices.push({
        //         key: key,
        //         id: v.id,
        //         timeStart: moment(v.expectedStartTime),
        //         durat: moment(v.period.to),
        //         servicename: this.findServiceName(v.offeredService.id, v.offeredService.category.id),
        //         price: v.chargeAmount,
        //         technician: v.technician.id,
        //         service: v.offeredService.id,
        //         technicianname: this.findTechnician(v.technician.id, 'nick')
        //     })
        // })
        //
        // if (a.purchaseItems && a.purchaseItems.length > 0) {
        //     a.purchaseItems.forEach((p, key) => {
        //         selectedProducts.push({
        //             id: p.locatedProductVariant.id,
        //             key: key,
        //             qty: p.quantity,
        //             name: p.locatedProductVariant.product.name,
        //             price: p.chargeAmount,
        //             technicianId: p.technician.id,
        //             techImage: this.findTechnician(p.technician.id, 'image'),
        //             technicianname: this.findTechnician(p.technician.id, 'nick')
        //         })
        //     })
        // }

        this.setState({
            ...this.state,
            details: a,
        });
    };

    initializeServiceSelect() {
        this.setState({
            service: !this.state.service,
            buttonText: !this.state.buttonText,
            smodal: !this.state.smodal,
        });
    }

    onServiceSelect = (service) => {
        this.setState({
            ...this.state,
            serviceId: service.service.id,
            serviceName: service.service.name,
            place: "Type a name",
        });

        if (service != "") {
            let techServices = [];
            let technicianIds = [];

            service.technicianServices.forEach((technician) => {
                technicianIds.push(technician.technician.id);
            });

            service.technicianServices.forEach((technician, key) => {
                const valid = this.checkValid(technician.technician.id);
                if (valid == "valid") {
                    techServices.push({
                        key: key,
                        id: technician.technician.id,
                        name: technician.technician.user.names.nick,
                        price: technician.displayedPrice,
                        duration: helperFunctions.timeConvert(technician.duration),
                        durationInMinute: technician.durationInMinutes,
                        actualPrice: this.checkPriceType(
                            technician.minimumFee,
                            technician.maximumFee
                        ),
                    });
                }
            });
            this.setState({techServices: techServices});
            var techJson = [];
            var i = 0;

            techServices.forEach((tech) => {
                techJson.push({
                    text: tech.name,
                    value: tech.id,
                    selected: false,
                    description: "[" + tech.duration + ", " + tech.price + "]",
                    imageSrc: this.findTechnician(tech.id, "image"),
                });
                i++;
            });

            setTimeout(function () {
                window.initiateTechniciansSelect(
                    techJson,
                    "#technicians-select-div-customer",
                    "#technicians-select-customer"
                );
            }, 100);
        }
    };
    checkValid = (id) => {
        const t = this.app.locations[0].technicians.find((t) => t.user.id == id);
        if (t) {
            return "valid";
        } else {
            return "Not valid";
        }
    };
    handleSource = (e) => {
        this.setState({
            requestSource: e.target.value,
        });
    };
    changeTech = (e) => {
        const {name, value} = e.target;
        const durat = this.state.techServices.find((t) => t.id == value);
        var duration = "";
        var price = "";
        if (durat) {
            duration = durat.durationInMinute;
            price = durat.actualPrice;
        }

        setTimeout(() => {
            this.setState({
                [name]: value,
                durat:
                    this.state.timeStart != ""
                        ? moment(this.state.timeStart).add(duration, "minutes")
                        : moment(this.props.startTime).add(duration, "minutes"),
                servicePrice: price,
            });
        }, 200);
    };

    addSelectedService = (id1 = "", id2 = "") => {
        var service = {};
        service.key = this.state.key++;
        service.timeStart =
            this.state.timeStart != "" ? this.state.timeStart : this.props.startTime;
        service.timeEnd =
            this.state.timeEnd != "" ? this.state.timeEnd : this.props.endTime;
        service.servicename = this.state.serviceName;
        service.price = this.state.servicePrice;
        service.technician = this.state.technician;
        service.service = this.state.serviceId;
        service.technicianname = this.findTechnician(this.state.technician, "nick");
        var selectedServices = this.state.selectedServices;
        selectedServices.push(service);

        $(id1).modal("hide");
        $(id2).modal("show");

        this.setState({
            ...this.state,
            selectedServices: selectedServices,
        });
    };

    updateServices = (id1 = "", id2 = "") => {
        const newSelected = this.state.selectedServices.map((s) => {
            if (s.key == this.state.key) {
                return {
                    ...s,
                    durat: this.state.durat,
                    timeStart: this.state.timeStart,
                    servicename: this.state.serviceName,
                    service: this.state.serviceId,
                    technician: this.state.technician,
                    technicianname: this.findTechnician(this.state.technician, "name"),
                    price: this.state.price,
                };
            }
            return s;
        });
        $(id1).modal("hide");
        $(id2).modal("show");

        this.setState({
            selectedServices: newSelected,
        });
    };

    onProductSelect = (product) => {
        const products = this.state.selectedProduct;
        products.name = product.variant.product.name;
        products.id = product.id;

        this.setState({
            selectedProduct: products,
            productPrice: product.salePrice,
        });
    };

    incrementQty = () => {
        var qty = parseInt(this.state.qty, 10);
        qty++;
        this.setState({
            qty: qty,
        });
    };
    decrementQty = () => {
        var qty = parseInt(this.state.qty, 10);
        if (isNaN(qty)) {
            this.setState({
                qty: 1,
            });
        } else {
            if (qty > 1) {
                this.setState({
                    qty: qty - 1,
                });
            }
        }
    };

    addSelectedProduct = (id1 = "", id2 = "") => {
        const {selectedProduct, technician} = this.state;
        const selected = this.state.selectedProducts;

        const productJSON = {
            id: selectedProduct.id,
            key: this.state.key++,
            qty: this.state.qty,
            name: selectedProduct.name,
            price: this.state.productPrice,
            technicianId: this.state.technician,
            techImage: this.findTechnician(technician, "image"),
            technicianname: this.findTechnician(technician, "nick"),
        };

        const errors = this.validate(productJSON);
        this.setState({errors});
        if (Object.keys(errors).length == 0) {
            selected.push(productJSON);
            $(id1).modal("hide");
            $(id2).modal("show");
            this.setPlace();
            this.setState({
                selectedProducts: selected,
                errors: {},
            });
        }
    };

    validate = (data) => {
        const errors = {};
        if (!data.id) errors.product = "Please select a product";
        if (!data.qty || data.qty < 1) errors.qty = "Please enter valid quantity ";
        if (!data.price) errors.price = "Please enter a valid price";
        if (!data.technicianId) errors.technicianId = "Please select a staff";

        return errors;
    };

    closePmodal() {
        this.setState({
            buttonText: !this.state.buttonText,
            pmodal: false,
        });
        this.setPlace();
    }

    saveRebookAppointment = () => {
        const {selectedServices, selectedProducts} = this.state;
        var tech = [];
        var purchaseItems = [];
        if (selectedProducts.length > 0) {
            for (var i = 0; i < selectedProducts.length; i++) {
                var singleProduct = {
                    technician: {
                        id: selectedProducts[i].technicianId,
                    },
                    quantity: selectedProducts[i].qty,
                    chargeAmount: selectedProducts[i].price,
                    locatedProductVariant: {
                        id: selectedProducts[i].id,
                    },
                };

                purchaseItems.push(singleProduct);
            }
        } else {
            purchaseItems = [];
        }
        const datePart = moment(this.state.apptDate).format("YYYY-MM-DDT");

        for (var i = 0; i < selectedServices.length; i++) {
            var singleTech = {
                technician: {
                    id: selectedServices[i].technician,
                },
                offeredService: {
                    id: selectedServices[i].service,
                },
                expectedStartTime:
                    datePart + moment(selectedServices[i].timeStart).format("HH:mm:ss"),
                period: {
                    to: datePart + moment(selectedServices[i].timeEnd).format("HH:mm:ss"),
                },
            };

            tech.push(singleTech);
        }
        const apptJSON = {
            location: {
                id: this.app.locations[0].id,
            },
            requestSource: this.state.requestSoruce,
            requestType: "Appointment",
            standingAppointment: false,
            customerNotes: "",
            visitTechnicians: tech,
            purchaseItems: purchaseItems,
            client: {
                id: this.state.customer.id,
            },
        };

        this.setState({
            loading: true,
        });
        reservationService
            .saveAppointment(apptJSON)
            .then((res) => {
                this.setState({
                    loading: false,
                });
                window.DataUpdateSuccess();
                this.setState({
                    selectedServices: [],
                    techServices: [],
                    technician: "",
                    customer: "",
                    selectedProducts: [],
                });
                $("#createEvent").modal("toggle");
                reservationService
                    .getOpenAppointments(
                        qs.parse(this.props.location.search.replace("?", "")).custId
                    )
                    .then((a) => {
                        this.setState({
                            openAppts: a,
                        });
                    });
                reservationService
                    .getPastAppointments(
                        qs.parse(this.props.location.search.replace("?", "")).custId
                    )
                    .then((a) => {
                        this.setState({
                            pastAppts: a,
                        });
                    });
            })
            .catch((e) => {
                e.json().then((err) => {
                    Swal.fire({
                        title: "Error",
                        text: `${err.message}`,
                        type: "warning",
                        showCancelButton: false,
                        confirmButtonText: "Ok!",
                    });
                });
            });
    };

    saveShopping = (apptJSON) => {
        reservationService
            .saveAppointment(apptJSON)
            .then((res) => {
                this.setState({
                    shoppingInfo: res,
                });
                window.DataUpdateSuccess();
                $("#Shopping").modal("toggle");
                const {dispatch} = this.props;
                this.getCustomerInfo(this.state.userId);
            })
            .catch((e) => {
                e.json().then((err) => {
                    Swal.fire({
                        title: "Error",
                        text: `${err.message}`,
                        type: "warning",
                        showCancelButton: false,
                        confirmButtonText: "Ok!",
                    });
                });
            });
    };

    //remove product from the cart
    removeProduct = (key) => {
        const newSelectedProducts = this.state.selectedProducts.filter(
            (p) => p.key != key
        );
        this.setState({
            ...this.state,
            selectedProducts: newSelectedProducts,
        });
    };

    //remove product from the cart
    removeService = (key) => {
        const newSelectedServices = this.state.selectedServices.filter(
            (s) => s.key != key
        );
        this.setState({
            ...this.state,
            selectedServices: newSelectedServices,
        });
    };

    editDate = () => {
        this.setState({
            editDate: !this.state.editDate,
        });
    };

    //change booked time
    changeBookedTime = (bookedTime) => {
        this.setState({
            apptDate: bookedTime,
        });
    };

    editingService = (id) => {
        const service = this.state.selectedServices.find((s) => s.id == id);

        this.setState({
            place: service.servicename,
            durat: service.durat,
            timeStart: service.timeStart,
            serviceName: service.servicename,
            serviceId: service.service,
            technician: service.technician,
            technicianname: service.technicianname,
            smodal: true,
            mode: "edit",
            key: service.key,
            servicePrice: service.price,
            service: service.service,
            price: service.price,
        });
        const techJSON = {
            id: service.technician,
            name: service.technicianname,
            price: service.price,
        };

        appService
            .getSingleServiceData(service.service, (this.state.searchQuery = ""))
            .then((res) => {
                if (res != "") {
                    let techService = [];

                    res.technicianServices.forEach((technician) => {
                        techService.push({
                            id: technician.technician.id,
                            name: technician.technician.user.names.nick,
                            price: technician.displayedPrice,
                            duration: helperFunctions.timeConvert(technician.duration),
                            durationInMinute: technician.durationInMinutes,
                            actualPrice: this.checkPriceType(
                                technician.minimumFee,
                                technician.maximumFee
                            ),
                        });
                    });

                    this.setState({techServices: techService});
                    var techJson = [];
                    var i = 0;

                    techJson.push({
                        text: service.technicianname,
                        value: service.technician,
                        selected: false,
                        description: "[" + service.price + "]",
                        imageSrc: this.findTechnician(service.technician, "image"),
                    });

                    techService.forEach((tech) => {
                        techJson.push({
                            text: tech.name,
                            value: tech.id,
                            selected: false,
                            description: "[" + tech.duration + ", " + tech.price + "]",
                            imageSrc: this.findTechnician(tech.id, "image"),
                        });
                        i++;
                    });

                    setTimeout(function () {
                        window.initiateTechniciansSelect(
                            techJson,
                            "#technicians-select-div-customer",
                            "#technicians-select-customer"
                        );
                    }, 100);
                }
            });
    };

    //standing
    initializeServices() {
        const {categories} = this.state;
        this.services = [];
        var allServices = [];
        categories
            .filter((osc) => osc.services.length > 0)
            .map((osc, k) => {
                var categoryServices = [];
                osc.services
                    .filter((s) => s.publiclyVisible == true)
                    .map((s, k) => {
                        allServices.push(s);
                        categoryServices.push({
                            text: s.name,
                            id: s.id,
                        });
                    });
                this.services.push({
                    text: osc.name.toUpperCase(),
                    children: categoryServices,
                });
            });

        this.setState({
            services: allServices,
        });
    }

    handleDateRangeStart = (jsDate, dateString) => {
        this.setState({
            dateStart: dateString.split("T")[0],
        });
    };

    handleDateRangeEnd = (jsDate, dateString) => {
        this.setState({
            dateEnd: dateString.split("T")[0],
        });
    };

    onchangeStanding = (e) => {
        const {name, value} = e.target;
        this.setState({[name]: value});
        if (this.state.reservationExists) {
            this.setState({reservationExists: false});
        }
        if (name === "service") {
            this.selectService(value);
            window.selectService();
        }
        if (name == "date" && value == "DATE_RANGE") {
            setTimeout(function () {
            }, 500);
        }
        if (name == "date" && value != "DATE_RANGE") {
            this.setState({
                dateStart: "",
                dateEnd: "",
            });
        }
        if (name == "time" && value != "TIME_RANGE") {
            this.setState({
                timeStart: "",
                timeEnd: "",
            });
        }
        if (name == "time" && value == "TIME_RANGE") {
            setTimeout(function () {
            }, 500);
        }
    };

    getService(id) {
        var service = null;
        const {categories} = this.state;

        categories.some(function (osc) {
            if (osc.services.length > 0) {
                service = osc.services.find((s) => s.id == id);
                return service != undefined;
            }
        });

        return service;
    }

    selectService(value) {
        if (value != "") {
            let technicians = [];
            let technicianIds = [];
            var service = this.getService(value);
            //window.addEachService(service.name)
            //console.log('SERVICE',service)
            service.technicians.forEach((technician) => {
                technicianIds.push(technician.id);
            });

            this.app.locations[0].technicians
                .filter((tech) => tech.bookable == true)
                .forEach((technician) => {
                    if (technicianIds.includes(technician.id)) {
                        technicians.push({
                            id: technician.id,
                            name: technician.user.names.nick,
                            expertise: technician.expertise,
                        });
                    }
                });

            this.setState({technician: "any"});
            this.setState({technicianss: technicians});
            var techJson = [];
            var i = 0;
            techJson.push({
                text: "Any Staff",
                value: "any",
                selected: false,
                description: "Expertise: Any",
                imageSrc:
                    "https://s3.amazonaws.com/zoracorp/bianca/default/user-no-image.png",
            });
            technicians.forEach((tech) => {
                techJson.push({
                    text: tech.name,
                    value: tech.id,
                    selected: false,
                    description: "Expertise: " + tech.expertise,
                    imageSrc: this.findTechnicianImage(tech.id),
                });
                i++;
            });

            setTimeout(function () {
                window.initiateTechniciansSelect(
                    techJson,
                    "#technicians-select-div-stand",
                    "#technicians-select-stand"
                );
            }, 100);
        }
    }

    cancelStandings = () => {
        Swal.fire({
            title: "Cancel Standings!",
            text: `Are you sure you want to cancel standings?`,
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, keep it",
        }).then((result) => {
            if (result.value) {
                reservationService
                    .cancelStandings({
                        location: {id: this.app.locations[0].id},
                        client: {id: this.state.userId},
                        cancellationReason: this.state.cancelReason,
                        standingAppointment: true,
                    })
                    .then((res) => {
                        this.getCustomerInfo(
                            qs.parse(this.props.location.search.replace("?", "")).custId
                        );
                        this.initializeOpenAppt();
                        $("#standingCancel").modal("toggle");
                        window.toast(
                            "error",
                            `Standings Appointment has been deleted for this client`
                        );
                    });
            }
        });
    };

    deleteWaitList = (id) => {
        Swal.fire({
            title: "Delete Formula",
            text: `Are you sure you want to delete it?`,
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, keep it",
        }).then((result) => {
            if (result.value) {
                reservationService.deleteWaitList(id).then(() => {
                    reservationService
                        .getAllWaitList(0, "user-lctn", this.state.userId)
                        .then((res) => {
                            this.setState({
                                waitlist: res,
                            });
                        });
                });
                window.toast("error", `Data has been deleted from the system`);
            }
        });
    };

    //waitlist
    initializeWaitServiceSelect() {
        this.setState({
            buttonText: !this.state.buttonText,
            editMode: "save",
        });
    }

    addUpSelectedService = () => {
        var service = {};
        service.key = this.state.selectedServices.length + 1;
        service.servicename = this.state.serviceName;
        service.technicians = this.state.technician;
        service.service = this.state.serviceId;
        (service.from = this.state.startTime), (service.to = this.state.endTime);
        var selectedServices = this.state.selectedServices;
        selectedServices.push(service);

        this.setState({
            ...this.state,
            selectedServicesss: selectedServices,
            techniciansss: [],
            techServicesss: "",
            startTime: moment(),
            endTime: moment(),
        });
    };

    saveWaitList = (waitJSON) => {
        reservationService.saveWaitList(waitJSON).then((res) => {
            window.DataUpdateSuccess();
            reservationService
                .getAllWaitList(0, "user-lctn", this.state.userId)
                .then((res) => {
                    this.setState({
                        waitlist: res,
                    });
                });
        });
    };

    editWaitList = (a, id) => {
        $("#waitlist").modal("hide");
        userService.getCustomerInfo(id).then((user) => {
            this.setState({
                customerDetails: user,
                staffNotes: a.staffNotes,
            });
        });
        const selectedServices = this.state.selectedServicesss;

        a.periods.forEach((m, key) => {
            const techs = [];
            a.services[key].technicians.forEach((t) => {
                techs.push({
                    id: t.id,
                });
            });

            selectedServices.push({
                from: m.from,
                to: m.to,
                technicians: techs,
                service: a.services[key].service.id,
                servicename: a.services[key].service.name,
                key: key,
            });
        });
        this.setState({
            selectedServicesss: selectedServices,
            customer: id,
            wtid: a.id,
        });
    };
    openMiniModal = (type) => {
        this.setState({
            lastAppts: this.state.pastAppts,
        });
    };
    openMiniModalForOpen = (type) => {
        this.setState({
            lastAppts: this.state.openAppts,
        });
    };
    removeService = (k, single) => {
        const newSelected = this.state.selectedServices.filter((s) => s.key != k);
        this.setState({
            selectedServices: newSelected,
        });
    };

    editWait = (k, single) => {
        const techs = this.state.techniciansss;
        single.technicians.forEach((t) => {
            techs.push({
                id: t.id,
            });
        });

        this.setState({
            startTime: moment(single.from),
            endTime: moment(single.to),
            techniciansss: techs,
            wmodal: true,
            place: single.servicename,
            editMode: "update",
            key: single.key,
            servicess: single.service,
            servicename: single.servicename,
        });

        appService
            .getSingleServiceData(single.service, (this.state.searchQuery = ""))
            .then((res) => {
                if (res != "") {
                    let techService = [];

                    res.technicianServices.forEach((technician) => {
                        techService.push({
                            id: technician.technician.id,
                            name: technician.technician.user.names.nick,
                            price: technician.displayedPrice,
                            duration: helperFunctions.timeConvert(technician.duration),
                        });
                    });

                    this.setState({techServicesss: techService});
                    var techJson = [];
                    var i = 0;
                    techJson.push({
                        text: "Select Stylist",
                        value: "up",
                        selected: false,
                    });
                    techJson.push({
                        text: "Any Stylist",
                        value: "any",
                        selected: false,
                        description: "Expertise: Any",
                        imageSrc:
                            "https://s3.amazonaws.com/zoracorp/bianca/default/user-no-image.png",
                    });

                    techService.forEach((tech) => {
                        techJson.push({
                            text: tech.name,
                            value: tech.id,
                            selected: false,
                            description: "[" + tech.duration + ", " + tech.price + "]",
                            imageSrc: this.findTechnician(tech.id, "image"),
                        });
                        i++;
                    });

                    setTimeout(function () {
                        window.initiateTechniciansSelect(
                            techJson,
                            "#technicians-select-div-wait",
                            "#technicians-select-wait"
                        );
                    }, 100);
                }
            });
    };

    removeTech = (id) => {
        const techs = this.state.techniciansss.filter((t) => t.id != id);

        if (techs) {
            this.setState({
                techniciansss: techs,
            });
        }
    };

    changeWaitTech = (e) => {
        const technician = this.state.techniciansss.find(
            (t) => t.id == e.target.value
        );

        if (technician) {
            this.setState(
                {
                    message: "Already added!",
                },
                () => {
                    setTimeout(() => {
                        this.setState({
                            message: "",
                        });
                    }, 1000);
                }
            );
        } else {
            if (e.target.value == "up") {
                var techCollection = this.state.techniciansss;
                this.setState({
                    techniciansss: techCollection,
                });
            } else if (e.target.value != "any") {
                var techCollection = this.state.techniciansss;
                techCollection.push({
                    id: e.target.value,
                });

                this.setState({
                    techniciansss: techCollection,
                });
            } else {
                this.setState({
                    techniciansss: [],
                });
            }
        }
    };

    upSelectedService = () => {
        const newSelected = this.state.selectedServicesss.map((s) => {
            if (s.key == this.state.key) {
                return {
                    ...s,
                    from: this.state.startTime,
                    to: this.state.endTime,
                    technicians: this.state.techniciansss,
                    service: this.state.servicess,
                    servicename: this.state.servicename,
                };
            }
            return s;
        });

        $("#editWaitList").modal("show");
        $("#addServicesForWait").modal("hide");

        this.setState({
            selectedServicesss: newSelected,
            techServicesss: "",
            editMode: "save",
            wmodal: false,
            techniciansss: [],
            startTime: moment(),
            endTime: moment(),
            place: "",
        });
    };

    onWaitServiceSelect = (service) => {
        this.setState({
            ...this.state,
            servicesss: service.service.id,
            servicename: service.service.name,
        });

        if (service != "") {
            let techServices = [];

            service.technicianServices.forEach((technician, key) => {
                const valid = this.checkValid(technician.technician.id);
                if (valid == "valid") {
                    techServices.push({
                        key: key,
                        id: technician.technician.id,
                        name: technician.technician.user.names.nick,
                        price: technician.displayedPrice,
                        duration: helperFunctions.timeConvert(technician.duration),
                        durationInMinute: technician.durationInMinutes,
                        actualPrice: this.checkPriceType(
                            technician.minimumFee,
                            technician.maximumFee
                        ),
                    });
                }
            });
            this.setState({techServicesss: techServices});
            var techJson = [];
            var i = 0;
            techJson.push({
                text: "Any Staff",
                value: "any",
                selected: false,
                description: "Expertise: Any",
                imageSrc:
                    "https://s3.amazonaws.com/zoracorp/bianca/default/user-no-image.png",
            });

            techServices.forEach((tech) => {
                techJson.push({
                    text: tech.name,
                    value: tech.id,
                    selected: false,
                    description: "[" + tech.duration + ", " + tech.price + "]",
                    imageSrc: this.findTechnician(tech.id, "image"),
                });
                i++;
            });

            setTimeout(function () {
                window.initiateTechniciansSelect(
                    techJson,
                    "#technicians-select-div-wait",
                    "#technicians-select-wait"
                );
            }, 100);
        }
    };

    changeStartTime = (start) => {
        this.setState({
            startTime: start,
        });
    };
    changeEndTime = (end) => {
        this.setState({
            endTime: end,
            showService: true,
        });
    };

    closeThisModal = (id1 = "", id2 = "") => {
        this.setState({
            editMode: "save",
            smodal: false,
            technician: [],
            techServices: "",
            techServicesss: [],
            key: "",
            startTime: moment(),
            endTime: moment(),
            place: "Type a name",
            miniModal: false,
            wmodal: false,
            nmodal: "",
        });
        $(id1).modal("hide");
        $(id2).modal("show");
    };

    closePastAppts = () => {
        $("#editWaitList").modal("toggle");
        $("#MiniModal").modal("toggle");
    };

    onCustomerSelect = (user) => {
        this.setState(
            {
                customerDetails: user,
                customer: user.id,
            },
            () => {
                this.getCustomerInfo(this.state.customer);
            }
        );
    };

    updateWaitList = () => {
        const selectedServices = this.state.selectedServicesss;

        const services = [];
        const period = [];
        for (var i = 0; i < selectedServices.length; i++) {
            var singleService = "";
            if (selectedServices[i].technicians.length == 0) {
                singleService = {
                    service: {
                        id: selectedServices[i].service,
                    },
                    technicians: [],
                };
            } else {
                singleService = {
                    service: {
                        id: selectedServices[i].service,
                    },
                    technicians: selectedServices[i].technicians,
                };
            }
            var time = {
                from: moment(selectedServices[i].from).format("YYYY-MM-DDTHH:mm:ss"),
                to: moment(selectedServices[i].to).format("YYYY-MM-DDTHH:mm:ss"),
            };

            period.push(time);

            services.push(singleService);
        }
        const waitJSON = {
            client: {
                id: this.state.customer,
            },
            location: {
                id: this.app.locations[0].id,
            },
            staffNotes: this.state.staffNotes,
            periods: period,
            services: services,
        };

        reservationService.updateWaitList(this.state.wtid, waitJSON).then((res) => {
            var thisObj = this;
            window.DataUpdateSuccess();
            $("#editWaitList").modal("hide");
            $("#waitlist").modal("toggle");
            this.setState({
                techServicesss: "",
                selectedServicesss: [],
                customer: "",
                customerDetails: "",
                wtid: "",
                servicesss: "",
            });
            reservationService
                .getAllWaitList(0, "user-lctn", this.state.userId)
                .then((res) => {
                    this.setState({
                        waitlist: res,
                    });
                });
        });
    };

    addWaitSelectedService = () => {
        var service = {};
        service.key = this.state.selectedServicesss.length + 1;
        service.servicename = this.state.servicename;
        service.technicians = this.state.techniciansss;
        service.service = this.state.servicesss;
        (service.from = this.state.startTime), (service.to = this.state.endTime);

        if (this.state.endTime.diff(this.state.startTime) < 1) {
            const errors = {};
            errors.difference = "Please pick valid start and end period!";
            this.setState({
                errors,
            });
        } else {
            var selectedServices = this.state.selectedServicesss;
            selectedServices.push(service);
            this.setPlace();
            $("#editWaitList").modal("show");
            $("#addServicesForWait").modal("hide");

            this.setState({
                ...this.state,
                selectedServicesss: selectedServices,
                techniciansss: [],
                startTime: moment(),
                endTime: moment(),
                errors: {},
                techServicesss: [],
            });
        }
    };

    getHTMLString = (id) => {
        this.setState({
            ...this.state,
            loading: true,
        });
        userService
            .getHTMLString(id)
            .then((string) => {
                this.setState({
                    ...this.state,
                    htmlContentString: string.contentText,
                });

                setTimeout(() => {
                    window.initializeForms("#canvas1", "#clear1");
                }, 400);
                setTimeout(() => {
                    window.initializeForms("#canvas2", "#clear2");
                    this.setState({
                        ...this.state,
                        loading: false,
                        formId: id,
                    });
                }, 500);
            })
            .catch((e) => {
                this.setState({
                    ...this.state,
                    loading: false,
                });
            });
    };

    cancelPastApptModal = (id) => {
        this.setState({
            openId: id,
        });
    };

    cancelPastAppt = () => {
        const reason = {
            cancelReason: this.state.cancelReason,
        };
        const errors = this.validateReason(reason);
        this.setState({errors});

        if (Object.keys(errors).length == 0) {
            Swal.fire({
                title: "Cancel Appointment",
                text: `Are you sure you want to cancel this appointment`,
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, Cancel it!",
                cancelButtonText: "No, keep it",
            }).then((result) => {
                if (result.value) {
                    appService.cancelAppt(this.state.openId, reason).then(() => {
                        $("#cancelPastAppt").modal("toggle");
                        this.setState({
                            cancelReason: "",
                        });
                        window.toast(
                            "error",
                            `The appointment has been canceled from the appointment`
                        );
                        this.initializeOpenAppt();
                    });
                }
            });
        }
    };

    validateReason = (reason) => {
        let errors = {};
        if (reason.cancelReason == "")
            errors.reason = "Please enter a reason for cancel!";
        return errors;
    };

    contextMenus(e, cls, a) {
        e.preventDefault();
        this.setState(
            {
                menuState: true,
                viewDetails: a,
                apptId: a.id,
            },
            () => {
                let element = document.getElementById("contextMenu");
                element.classList.add(cls);
                element.style.left = e.pageX + "px";
                element.style.top = e.pageY + "px";
            }
        );
    }

    closeContextMenu = () => {
        this.setState({
            ...this.state,
            menuState: false,
            viewDetails: "",
        });
    };

    openCheckOutModal = (id) => {
        this.setState({
            ...this.state,
            loading: true,
            checkOut: true,
        });
        appService.getSingleVtService(id).then((res) => {
            this.setState({
                ...this.state,
                editApptDetails: res,
                loading: false,
            });
        });
    };
    openCheckinModal = (id) => {
        this.setState({
            ...this.state,
            loading: true,
        });
        appService.getSingleVtService(id).then((res) => {
            this.setState({
                ...this.state,
                viewDetails: res,
                editApptDetails: res,
                loading: false,
            });
        });
    };
    UndocheckServiceIn = (vt) => {
        this.setState({
            ...this.state,
            loading: true,
        });
        appService
            .UndocheckServiceIn(vt)
            .then((res) => {
                window.toast("error", "Appointment undo  checked in is successfull");
                appService.getSingleVtService(this.state.viewDetails.id).then((res) => {
                    this.setState({
                        ...this.state,
                        viewDetails: res,
                        editApptDetails: res,
                        loading: false,
                    });
                });
                this.initializeOpenAppt();
            })
            .catch((e) => {
                this.setState({
                    ...this.state,
                    loading: false,
                });
            });
    };

    appointmentCheckIn = (id, note) => {
        appService
            .appointmentCheckIn(id, note)
            .then((res) => {
                appService.getSingleVtService(this.state.apptId).then((res) => {
                    this.setState({
                        ...this.state,
                        viewDetails: res,
                        editApptDetails: res,
                        loading: false,
                    });
                });
                window.DataUpdateSuccess();
                this.initializeOpenAppt();
            })
            .catch((e) => {
                Swal.fire({
                    title: "Error",
                    text: `Check-in is only allowed for today's appointments`,
                    type: "warning",
                    showCancelButton: false,
                    confirmButtonText: "Ok!",
                });
                this.setState({
                    ...this.state,
                    loading: false,
                });
            });
    };

    //appointment check out
    apptCheckout = (id, payments) => {
        appService
            .appointmentCheckOut(id, payments)
            .then((res) => {
                $("#editModal").modal("toggle");
                window.toast("success", `The Appointment Check Out is successful!`);
                if (this.state.apt == "open") {
                    this.initializeOpenAppt();
                } else {
                    this.initializePastAppt();
                }
            })
            .catch((error) => {
                error.json().then((err) => {
                    Swal.fire({
                        title: "Error",
                        text: `${err.message}`,
                        type: "error",
                        showCancelButton: false,
                        confirmButtonText: "Ok",
                    });
                });
            });
    };

    //appointment check out
    productCheckout = (id, payments) => {
        appService
            .appointmentCheckOut(id, payments)
            .then((res) => {
                $("#Shopping").modal("hide");
                window.toast("success", `The Appointment Check Out is successful!`);

                this.initializePastAppt();
                this.setState({
                    apt: "past",
                    label: "none",
                });
            })
            .catch((error) => {
                error.json().then((err) => {
                    Swal.fire({
                        title: "Error",
                        text: `${err.message}`,
                        type: "error",
                        showCancelButton: false,
                        confirmButtonText: "Ok",
                    });
                });
            });
    };

    setDetails = (a) => {
        this.setState({
            viewDetails: a,
        });
    };
    changeAction = (e, id) => {
        if (e.target.value != "none") {
            this.setState({
                loading: true,
                openId: id,
            });
            appService.getSingleVtService(id).then((res) => {
                this.setState({
                    ...this.state,
                    viewDetails: res,
                    editApptDetails: res,
                    loading: false,
                });
            });
        }
        if (e.target.value == "edit") {
            this.setState({
                checkOut: false,
            });
            /* eslint-disable */
            $("#editModal").modal("toggle");
        }
        if (e.target.value == "checkedIn") {
            /* eslint-disable */
            $("#checkin").modal("toggle");
        }
        if (e.target.value == "CheckOut") {
            this.setState({
                checkOut: true,
            });
            /* eslint-disable */
            $("#editModal").modal("toggle");
        }
        if (e.target.value == "cancel") {
            /* eslint-disable */
            $("#cancelPastAppt").modal("toggle");
        }
        if (e.target.value == "undo") {
            this.UndocheckServiceIn(id);
        }
    };

    clearPlace = () => {
        this.setState({
            place: "",
        });
    };
    setPlace = (value) => {
        this.setState(
            {
                ...this.state,
            },
            () => {
                this.setState({
                    place: "Type a name",
                });
            }
        );
    };

    changeLebel = (e) => {
        this.setState(
            {
                label: e.target.value,
            },
            () => {
                if (this.state.label == "appt") {
                    this.props.history.push(
                        `/${this.storeId}` +
                        "/calendar-reservation?userId=" +
                        qs.parse(this.props.location.search.replace("?", "")).custId
                    );
                } else if (this.state.label == "standing") {
                    $("#standingAppt").modal("show");
                    $("#Shopping").modal("hide");
                } else if (this.state.label == "gift") {
                    $("#Shopping").modal("toggle");
                    $("#standingAppt").modal("hide");
                }
            }
        );
    };

    changeTypes = (event) => {
        if (event.target.checked) {
            this.setState({
                ...this.state.types,
                types: this.state.types.concat(event.target.value),
            });
        } else {
            this.setState({
                ...this.state.types,
                types: this.state.types.filter((type) => type != event.target.value),
            });
        }
    };
    changeDirections = (event) => {
        if (event.target.checked) {
            this.setState({
                ...this.state.directions,
                directions: this.state.directions.concat(event.target.value),
            });
        } else {
            this.setState({
                ...this.state.directions,
                directions: this.state.directions.filter(
                    (direction) => direction != event.target.value
                ),
            });
        }
    };
    changeStatus = (event) => {
        if (event.target.checked) {
            this.setState({
                ...this.state.status,
                status: this.state.status.concat(event.target.value),
            });
        } else {
            this.setState({
                ...this.state.status,
                status: this.state.status.filter(
                    (stats) => stats != event.target.value
                ),
            });
        }
    };

    loadCommunication = () => {
        const {types, directions, status} = this.state;
        const con_errors = this.comValidation(types, directions, status);
        this.setState({
            con_errors,
        });

        if (Object.keys(con_errors).length == 0) {
            userService
                .loadCommunication(
                    types,
                    directions,
                    status,
                    this.state.user.mobileNumber,
                    this.app.smsSenderNumber,
                    this.state.com_offset,
                    ""
                )
                .then((res) => {
                    this.setState({
                        communication: res,
                    });
                });
        }
        setTimeout(() => {
            window.removeVisibleClass();
        }, 1000);
    };

    loadNext = () => {
        let offset = this.state.com_offset + 1;

        const {types, directions, status} = this.state;
        const con_errors = this.comValidation(types, directions, status);
        this.setState({
            con_errors,
            loadMore: true,
        });
        if (Object.keys(con_errors).length == 0) {
            userService
                .loadCommunication(
                    types,
                    directions,
                    status,
                    this.state.user.mobileNumber,
                    this.app.smsSenderNumber,
                    offset,
                    ""
                )
                .then((res) => {
                    this.setState({
                        communication: res,
                        com_offset: offset,
                        loadMore: false,
                    });
                });
        }
    };

    comValidation = (types, directions, status) => {
        const con_errors = {};
        if (types.length == 0) con_errors.types = "Please check at least one!";
        if (directions.length == 0)
            con_errors.directions = "Please check at least one!";
        if (status.length == 0) con_errors.status = "Please check at least one!";

        return con_errors;
    };

    openCaller = () => {
        if (
            this.accessRole === true
        ) {
            const {dispatch} = this.props;
            dispatch(outgoingAction.proccessCall(this.state.userDetails));
        }
    };

    reloadTodayScreen = (data) => {
        const {dispatch} = this.props;
        if (
            data.bookedTime &&
            data.clientId &&
            data.clientId ==
            qs.parse(this.props.location.search.replace("?", "")).custId
        ) {
            this.initializeOpenAppt();
            this.initializePastAppt();
        }
        dispatch(communicationAction.procceedToReload({}));
    };

    checkAccess = () => {
        if (
            this.loggedUser.role == "DIRECTOR" ||
            this.loggedUser.role == "MANAGER" ||
            this.loggedUser.role == "RECEPTIONIST"
        ) {
            return true;
        } else {
            return false;
        }
    };

    getCompletedForms = () => {
        this.getFormDetails();
    };

    getFormDetails = () => {
        userService
            .getCompletedForms(
                qs.parse(this.props.location.search.replace("?", "")).custId
            )
            .then((cforms) => {
                //console.log(cforms)
                this.setState({
                    consentForm: cforms,
                });
            });
    };

    viewCopletedForm = (id) => {
        userService
            .viewCompletedFormById(id)
            .then((form) => {
                this.setState({signedContent: form.signedContent});
            })
            .catch((error) => {
                error.json().then((err) => {
                    Swal.fire({
                        title: "Error!",
                        text: `${err.message}`,
                        type: "warning",
                        showCancelButton: false,
                        confirmButtonText: "Ok",
                    });
                });
            });
    };
    deleteCompletedForm = (id) => {
        userService
            .deleteCompletedForm(id)
            .then((form) => {
                window.toast("success", `The consent form has been deleted!`);
                this.getCustomerInfo(
                    qs.parse(this.props.location.search.replace("?", "")).custId
                );
            })
            .catch((error) => {
                error.json().then((err) => {
                    Swal.fire({
                        title: "Error!",
                        text: `${err.message}`,
                        type: "warning",
                        showCancelButton: false,
                        confirmButtonText: "Ok",
                    });
                });
            });
    };

    render() {
        var totalAmount = 0;
        const {
            updating,
            profileUpdated,
            profileUpdateError,
            passwordUpdated,
            rewardUpdated,
            technicians,
            formulass,
            formulaLoaded,
            updateStatus,
            rerender,
            findingAvailability,
            foundAvailability,
            slots,
            reservationPlaced,
            placingReservation,
            reservationError,
        } = this.props;
        const {
            submitted,
            user,
            userDetails,
            passwordSubmitted,
            rewardNotes,
            rewardSubmitted,
            rewardType,
            rewardError,
            formula,
            singleFormula,
            rewardLists,
            selectedServices,
            selectedProducts,
            errors,
            customer,
        } = this.state;
        const {
            showSlots,
            category,
            service,
            technician,
            services,
            technicianss,
            selectedServicess,
            step,
            date,
            dateStart,
            dateEnd,
            time,
            timeStart,
            timeEnd,
            timeSelected,
            reservationExists,
            waitlist,
            customerDetails,
            selectedServicesss,
        } = this.state;

        return (
            <React.Fragment>
                <div id="page-wrapper">
                    {user && userDetails && (
                        <div className="container-fluid">
                            <div className="row bg-title">
                                <div className="col-lg-2 col-md-4 col-sm-4 col-xs-12">
                                    <h4 className="page-title">Customer Details</h4>
                                </div>
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <CustomerSearchBar
                                            place={this.props.place}
                                            id="newCustomer"
                                            clearPlace={this.props.clearPlace}
                                            getNewValue={this.getNewValue}
                                            onCustomerSelect={this.onCustomerSelect}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-7 col-sm-8 col-md-8 col-xs-12">
                                    <ol className="breadcrumb">
                                        <li>
                                            <Link to={`/${this.storeId}`}>Dashboard</Link>
                                        </li>
                                        <li>
                                            <Link to={`/${this.storeId}/customers`}>Customers</Link>
                                        </li>
                                        <li className="active">Customer Details</li>
                                    </ol>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-5 col-lg-4 col-lg-offset-0  col-xs-12">
                                    <div className="white-box">
                                        {userDetails.imageUrl && (
                                            <div className="user-bg">
                                                <img
                                                    width="100%"
                                                    alt="user"
                                                    src={userDetails.imageUrl}
                                                />
                                            </div>
                                        )}
                                        {!userDetails.imageUrl && (
                                            <div className="user-bg loading"></div>
                                        )}
                                        <div className="user-btm-box">
                                            <div
                                                className={
                                                    userDetails.mobileNumber
                                                        ? "row text-center m-t-10"
                                                        : "row text-center m-t-10  before-loading"
                                                }
                                            >
                                                <div className="col-md-6 col-sm-6 b-r">
                                                    <strong>Name</strong>
                                                    <p>
                                                        <i className="fa fa-user"></i>
                                                        {userDetails.fullName}
                                                    </p>
                                                </div>
                                                <div className="col-md-6 col-sm-6">
                                                    <strong>Phone</strong>
                                                    <p>
                                                        <i className="fa fa-phone"></i>
                                                        {userDetails.mobileNumber ? (
                                                            <a onClick={this.openCaller}>
                                                                {helperFunctions.formatMobilePhone(
                                                                    userDetails.mobileNumber
                                                                )}
                                                            </a>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </p>
                                                </div>
                                            </div>
                                            <hr/>
                                            <div
                                                className={
                                                    userDetails.referredByUser &&
                                                    userDetails.referredByUser.fullName
                                                        ? "row text-center m-t-10"
                                                        : "row text-center m-t-10  before-loading"
                                                }
                                            >
                                                <div className="col-md-6 col-sm-6 b-r">
                                                    <strong>Joining Date</strong>
                                                    <p>
                                                        <i className="fa fa-calendar"></i>{" "}
                                                        {helperFunctions.formatDateTimeWithDay(
                                                            userDetails.createdTime,
                                                            "latest"
                                                        )}
                                                    </p>
                                                </div>

                                                <div className="col-md-6 col-sm-6 b-r">
                                                    <strong>Referred By</strong>
                                                    <p>
                                                        <i className="fa fa-user-plus"></i>
                                                        {userDetails.referredByUser ? (
                                                            <a
                                                                onClick={() => {
                                                                    this.getUser(userDetails.referredByUser.id);
                                                                }}
                                                            >
                                                                {" "}
                                                                {userDetails.referredByUser.fullName}{" "}
                                                            </a>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </p>
                                                </div>
                                            </div>
                                            <hr/>
                                            <div
                                                className={
                                                    userDetails.nextBirthdayRewardDateTime
                                                        ? "row text-center m-t-10"
                                                        : "row text-center m-t-10  before-loading"
                                                }
                                            >
                                                <div className="col-md-6 col-sm-6 b-r">
                                                    <strong>Email Address</strong>
                                                    <br/>
                                                    <br/>
                                                    <p>
                                                        <i className="fa fa-envelope"></i>
                                                        {userDetails.emailAddress}
                                                    </p>
                                                </div>

                                                <div className="col-md-6 col-sm-6">
                                                    <strong>Next Birthday</strong>
                                                    <br/>
                                                    <br/>
                                                    <p>
                                                        <i className="fa fa-calender"></i>{" "}
                                                        {helperFunctions.formatDateTimeWithDay(
                                                            moment(userDetails.nextBirthdayRewardDateTime),
                                                            "date"
                                                        )}
                                                    </p>
                                                </div>
                                            </div>
                                            <hr/>

                                            <div
                                                className={
                                                    userDetails.emailAddress
                                                        ? "row text-center m-t-10"
                                                        : "row text-center m-t-10  before-loading"
                                                }
                                            >
                                                <div className="col-md-12">
                                                    <strong>Last Visit:</strong> &nbsp;{" "}
                                                    <i className="fa fa-clock"></i>
                                                    {userDetails.lastVisit &&
                                                    helperFunctions.get_time_diff_seperate(
                                                        userDetails.bookedTime
                                                    ) < 30
                                                        ? helperFunctions.get_time_diff_seperate(
                                                            userDetails.lastVisit.bookedTime
                                                        )
                                                        : userDetails.lastVisit &&
                                                        helperFunctions.formatDateTimeWithDay(
                                                            userDetails.bookedTime,
                                                            "latest"
                                                        )}
                                                    {!userDetails.lastVisit && "NONE"}
                                                    <br/>
                                                    <strong>Required Password Change:</strong> &nbsp;{" "}
                                                    <i className="fa fa-check text-success"></i>
                                                    <br/>
                                                    <br/>
                                                    <strong>Can Book Online:</strong> &nbsp;{" "}
                                                    <i className="fa fa-check text-success"></i>
                                                    <br/>
                                                    <br/>
                                                    <strong>Pending Consent Forms:</strong> &nbsp;{" "}
                                                    <i className="fa fa-check text-success"></i>
                                                </div>
                                            </div>
                                            <hr/>

                                            <div
                                                className={
                                                    userDetails.referralToken
                                                        ? "row m-t-10"
                                                        : "row m-t-10  before-loading"
                                                }
                                            >
                                                <div className="col-md-4 col-sm-4 b-r text-center">
                                                    <p className="text-blue">Reward Balance</p>
                                                    <h3 className="text-center">
                                                        {userDetails.rewardBalance &&
                                                        userDetails.rewardBalance > 0
                                                            ? (userDetails.rewardBalance * 1).toFixed(2)
                                                            : "0.00"}
                                                    </h3>
                                                </div>

                                                <div className="col-md-4 col-sm-4  b-r text-center">
                                                    <p className="text-danger text-center">
                                                        ReferralToken
                                                    </p>
                                                    <h3>{userDetails.referralToken}</h3>
                                                </div>
                                                <div className="col-md-4 col-sm-4 text-center">
                                                    <p className="text-purple text-center">
                                                        Referral Earnings
                                                    </p>
                                                    <h3>
                                                        {userDetails.totalEarningsAsReferrer > 0
                                                            ? (
                                                                userDetails.totalEarningsAsReferrer * 1
                                                            ).toFixed(2)
                                                            : "0.00"}
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-7 col-lg-8 col-xs-12">
                                    <div className="customer-details-tab">
                                        <ul
                                            className={
                                                user.referralToken
                                                    ? "nav nav-tabs tabs customtab"
                                                    : "nav nav-tabs tabs customtab before-loading"
                                            }
                                        >
                                            <li className="tab active">
                                                <a
                                                    href="#settings"
                                                    data-toggle="tab"
                                                    aria-expanded="true"
                                                >
                                                    {" "}
                                                    <span className="visible-xs">
                            <i className="fa fa-cog"></i>
                          </span>{" "}
                                                    <span className="hidden-xs">Info</span>{" "}
                                                </a>
                                            </li>

                                            <li className="tab">
                                                <a
                                                    onClick={() => window.addRow()}
                                                    href="#appointment"
                                                    data-toggle="tab"
                                                    aria-expanded="true"
                                                >
                                                    {" "}
                                                    <span className="visible-xs">
                            <i className="fa fa-list"></i>
                          </span>{" "}
                                                    <span className="hidden-xs">Visits</span>{" "}
                                                </a>
                                            </li>

                                            <li className="tab">
                                                <a
                                                    href="#rewards"
                                                    data-toggle="tab"
                                                    aria-expanded="true"
                                                >
                                                    {" "}
                                                    <span className="visible-xs">
                            <i className="fa fa-gift"></i>
                          </span>{" "}
                                                    <span className="hidden-xs">Rewards</span>{" "}
                                                </a>
                                            </li>

                                            <li className="tab">
                                                <a
                                                    href="#forms"
                                                    onClick={this.getCompletedForms}
                                                    data-toggle="tab"
                                                    aria-expanded="true"
                                                >
                                                    {" "}
                                                    <span className="visible-xs">
                            <i className="fa fa-table"></i>
                          </span>{" "}
                                                    <span className="hidden-xs">Forms</span>{" "}
                                                </a>
                                            </li>

                                            <li className="tab">
                                                <a
                                                    onClick={() => window.addRow()}
                                                    href="#formula"
                                                    data-toggle="tab"
                                                    aria-expanded="true"
                                                >
                                                    {" "}
                                                    <span className="visible-xs">
                            <i className="fa fa-wrench"></i>
                          </span>{" "}
                                                    <span className="hidden-xs">Formulas</span>{" "}
                                                </a>
                                            </li>

                                            <li className="tab">
                                                <a
                                                    href="#waitlists"
                                                    data-toggle="tab"
                                                    aria-expanded="true"
                                                >
                                                    {" "}
                                                    <span className="visible-xs">
                            <i className="fa fa-hourglass"></i>
                          </span>{" "}
                                                    <span className="hidden-xs">Wait Lists</span>{" "}
                                                </a>
                                            </li>
                                            {this.state.userDetails &&
                                                Object.keys(this.state.userDetails).length > 0 &&
                                                this.state.userDetails.personaTribes.length > 0 && (
                                                    <li className="tab">
                                                        <a
                                                            onClick={() => window.initSlider()}
                                                            href="#persona"
                                                            data-toggle="tab"
                                                            aria-expanded="true"
                                                        >
                                                            {" "}
                                                            <span className="visible-xs">
                                <i className="fa fa-hourglass"></i>
                              </span>{" "}
                                                            <span className="hidden-xs">Persona</span>{" "}
                                                        </a>
                                                    </li>
                                                )}

                                            <li className="tab">
                                                <a
                                                    onClick={this.loadCommunication}
                                                    href="#communication"
                                                    data-toggle="tab"
                                                    aria-expanded="true"
                                                >
                                                    {" "}
                                                    <span className="visible-xs">
                            <i className="fa fa-hourglass"></i>
                          </span>{" "}
                                                    <span className="hidden-xs">Communication</span>{" "}
                                                </a>
                                            </li>
                                        </ul>
                                        <div className="tab-content">
                                            <div className="tab-pane fade in active" id="settings">
                                                <div
                                                    className={
                                                        user.referralToken
                                                            ? "settings"
                                                            : "settings before-loading"
                                                    }
                                                >
                                                    <h4>
                                                        <strong>Update Profile</strong>
                                                    </h4>
                                                    <form
                                                        className="form-horizontal form-material"
                                                        onSubmit={this.handleSubmit}
                                                    >
                                                        <div className="col-md-6 col-lg-4">
                                                            <div
                                                                className={
                                                                    "form-group" +
                                                                    (submitted && !user.firstName
                                                                        ? " has-error"
                                                                        : "")
                                                                }
                                                            >
                                                                <label
                                                                    className="col-md-12"
                                                                    htmlFor="firstName"
                                                                >
                                                                    FIRST NAME
                                                                </label>
                                                                <div className="col-md-12">
                                                                    <input
                                                                        type="text"
                                                                        maxLength="40"
                                                                        name="firstName"
                                                                        value={user.firstName}
                                                                        onChange={this.handleChange}
                                                                    />
                                                                    {submitted && !user.firstName && (
                                                                        <div className="help-block">
                                                                            First Name is required
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6 col-lg-4">
                                                            <div
                                                                className={
                                                                    "form-group" +
                                                                    (submitted && !user.lastName
                                                                        ? " has-error"
                                                                        : "")
                                                                }
                                                            >
                                                                <label className="col-md-12" htmlFor="lastName">
                                                                    LAST NAME
                                                                </label>
                                                                <div className="col-md-12">
                                                                    <input
                                                                        type="text"
                                                                        maxLength="40"
                                                                        name="lastName"
                                                                        value={user.lastName}
                                                                        onChange={this.handleChange}
                                                                    />
                                                                    {submitted && !user.lastName && (
                                                                        <div className="help-block">
                                                                            Last Name is required
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6 col-lg-4">
                                                            <div className="form-group">
                                                                <label className="col-md-12" htmlFor="nickName">
                                                                    NICK NAME
                                                                </label>
                                                                <div className="col-md-12">
                                                                    <input
                                                                        type="text"
                                                                        maxLength="40"
                                                                        name="nickName"
                                                                        value={user.nickName}
                                                                        onChange={this.handleChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6 col-lg-4">
                                                            <div className="form-group">
                                                                <label className="col-md-12" htmlFor="email">
                                                                    EMAIL
                                                                </label>
                                                                <div className="col-md-12">
                                                                    <input
                                                                        type="email"
                                                                        maxLength="100"
                                                                        name="email"
                                                                        value={user.email}
                                                                        onChange={this.handleChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6 col-lg-4">
                                                            <div
                                                                className={
                                                                    "form-group" +
                                                                    (submitted && !user.mobileNumber
                                                                        ? " has-error"
                                                                        : "")
                                                                }
                                                            >
                                                                <label
                                                                    className="col-md-12"
                                                                    htmlFor="mobileNumber"
                                                                >
                                                                    MOBILE NUMBER
                                                                </label>
                                                                <div className="col-md-12">
                                                                    <input
                                                                        type="text"
                                                                        className="mobileNumber"
                                                                        name="mobileNumber"
                                                                        value={user.mobileNumber}
                                                                        onChange={this.handleChange}
                                                                    />
                                                                    {submitted && !user.mobileNumber && (
                                                                        <div className="help-block">
                                                                            Phone number is required
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6 col-lg-4">
                                                            <div className="form-group">
                                                                <label className="col-md-12" htmlFor="gender">
                                                                    GENDER
                                                                </label>
                                                                <div className="col-md-12">
                                                                    <select
                                                                        style={{height: "42px"}}
                                                                        name="gender"
                                                                        onChange={this.handleChange}
                                                                        value={user.gender}
                                                                    >
                                                                        <option value="Male">MALE</option>
                                                                        <option value="Female">FEMALE</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6 col-lg-4">
                                                            <div
                                                                className={
                                                                    "form-group" +
                                                                    (submitted && !user.birthDate
                                                                        ? " has-error"
                                                                        : "")
                                                                }
                                                            >
                                                                <label
                                                                    className="col-md-12"
                                                                    htmlFor="birthDate"
                                                                >
                                                                    BIRTH DATE
                                                                </label>
                                                                <div className="col-md-12">
                                                                    <input
                                                                        type="date"
                                                                        name="birthDate"
                                                                        value={user.birthDate}
                                                                        onChange={this.handleChange}
                                                                    />
                                                                    {submitted && !user.birthDate && (
                                                                        <div className="help-block">
                                                                            Birth Date is required
                                                                        </div>
                                                                    )}
                                                                    <p>
                                                                        {this.state.errors.birthDate && (
                                                                            <InlineMessage
                                                                                text={this.state.errors.birthDate}
                                                                            />
                                                                        )}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-lg-4">
                                                            <div
                                                                className={
                                                                    "form-group" +
                                                                    (submitted && !user.referralToken
                                                                        ? " has-error"
                                                                        : "")
                                                                }
                                                            >
                                                                <label
                                                                    className="col-md-12"
                                                                    htmlFor="birthDate"
                                                                >
                                                                    REFERRAL TOKEN
                                                                </label>
                                                                <div className="col-md-12">
                                                                    <input
                                                                        type="text"
                                                                        name="referralToken"
                                                                        value={user.referralToken}
                                                                        onChange={this.handleChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="switcher-spacer"></div>
                                                                <div className="flex align-middle">
                                                                    <label className="switch">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={user.transientAccount}
                                                                            name="transientAccount"
                                                                            readOnly
                                                                            onClick={this.handleChange}
                                                                        />
                                                                        <span className="switchSlider"></span>
                                                                    </label>
                                                                    <label htmlFor="transientAccount">
                                                                        {" "}
                                                                        &nbsp; &nbsp; &nbsp; Transient
                                                                    </label>
                                                                </div>
                                                                <div className="flex align-middle">
                                                                    <label className="switch">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={user.emailNotification}
                                                                            name="emailNotification"
                                                                            readOnly
                                                                            onClick={this.handleChange}
                                                                        />
                                                                        <span className="switchSlider"></span>
                                                                    </label>
                                                                    <label htmlFor="emailNotification">
                                                                        {" "}
                                                                        &nbsp; &nbsp; &nbsp; Email Notifications
                                                                    </label>
                                                                </div>
                                                                <div className="flex align-middle">
                                                                    <label className="switch">
                                                                        <input
                                                                            type="checkbox"
                                                                            name="smsNotification"
                                                                            checked={user.smsNotification}
                                                                            readOnly
                                                                            onClick={this.handleChange}
                                                                        />
                                                                        <span className="switchSlider"></span>
                                                                    </label>
                                                                    <label htmlFor="smsNotification">
                                                                        &nbsp; &nbsp; &nbsp; Text Notifications
                                                                    </label>
                                                                </div>
                                                                <div className="flex align-middle">
                                                                    <label className="switch">
                                                                        <input
                                                                            id="doNotCallEnabled"
                                                                            type="checkbox"
                                                                            name="doNotCallEnabled"
                                                                            checked={user.doNotCallEnabled}
                                                                            readOnly
                                                                            onClick={this.handleChange}
                                                                        />
                                                                        <span className="switchSlider"></span>
                                                                    </label>
                                                                    <label htmlFor="doNotCallEnabled">
                                                                        &nbsp; &nbsp; &nbsp; Callable Status
                                                                    </label>
                                                                </div>
                                                                {this.canUpdate = true &&
                                                                    <div className="flex align-middle">
                                                                        <label className="switch">
                                                                            <input
                                                                                id="appointmentSchedulingAllowed"
                                                                                type="checkbox"
                                                                                name="appointmentSchedulingAllowed"
                                                                                checked={user.appointmentSchedulingAllowed}
                                                                                readOnly
                                                                                onClick={this.handleChange}
                                                                            />
                                                                            <span className="switchSlider"></span>
                                                                        </label>
                                                                        <label htmlFor="appointmentSchedulingAllowed">
                                                                            &nbsp; &nbsp; &nbsp; Allow appointment
                                                                            scheduling
                                                                        </label>
                                                                    </div>}
                                                            </div>
                                                        </div>

                                                        <div className="form-group">
                                                            <div className="col-sm-12">
                                                                <div className="switcher-spacer"></div>
                                                                {this.canUpdate && (
                                                                    <button
                                                                        type="submit"
                                                                        className="btn btn-custom-info"
                                                                    >
                                                                        Update Profile
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                        {profileUpdateError && (
                                                            <div className="warning">
                                                                {profileUpdateError}
                                                            </div>
                                                        )}
                                                        {
                                                            // eslint-disable-next-line
                                                            updating && (
                                                                <div className="loaderDiv">
                                                                    <div className="loader"></div>
                                                                </div>
                                                            )
                                                        }
                                                        {submitted &&
                                                            profileUpdated &&
                                                            this.updateCustomerSuccess()}
                                                    </form>
                                                </div>

                                                <div className="switcher-spacer"></div>

                                                <div
                                                    className={
                                                        user.referralToken
                                                            ? "settings"
                                                            : "settings before-loading"
                                                    }
                                                >
                                                    <h4>Update Password</h4>
                                                    <form
                                                        name="form"
                                                        className="form-horizontal form-material"
                                                        onSubmit={this.submitPassword}
                                                    >
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div
                                                                    className={
                                                                        "form-group" +
                                                                        (passwordSubmitted && !user.password
                                                                            ? " has-error"
                                                                            : "")
                                                                    }
                                                                >
                                                                    <label
                                                                        className="col-md-12"
                                                                        htmlFor="password"
                                                                    >
                                                                        NEW PASSWORD*
                                                                    </label>
                                                                    <div className="col-md-12">
                                                                        <input
                                                                            placeholder="*******"
                                                                            type="password"
                                                                            name="password"
                                                                            value={user.password}
                                                                            onChange={this.handleChange}
                                                                        />
                                                                        {passwordSubmitted && !user.password && (
                                                                            <div className="help-block">
                                                                                Password is required
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div
                                                                    className={
                                                                        "form-group" +
                                                                        (passwordSubmitted &&
                                                                        !(user.password === user.cPassword)
                                                                            ? "is-invalid"
                                                                            : "")
                                                                    }
                                                                >
                                                                    <label
                                                                        className="col-md-12"
                                                                        htmlFor="cPassword"
                                                                    >
                                                                        CONFIRM PASSWORD*
                                                                    </label>
                                                                    <div className="col-md-12">
                                                                        <input
                                                                            placeholder="*******"
                                                                            type="password"
                                                                            name="cPassword"
                                                                            value={user.cPassword}
                                                                            onChange={this.handleChange}
                                                                        />
                                                                        {passwordSubmitted &&
                                                                            !(user.password === user.cPassword) && (
                                                                                <div className="help-block">
                                                                                    Password and confirm password fields
                                                                                    donot match.
                                                                                </div>
                                                                            )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="form-group">
                                                            <div className="col-sm-12">
                                                                {this.canUpdate && (
                                                                    <button
                                                                        className="btn btn-custom-info"
                                                                        type="submit"
                                                                    >
                                                                        Update Password
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                        {passwordSubmitted &&
                                                            passwordUpdated &&
                                                            window.customerUpdateSuccess()}
                                                    </form>
                                                </div>
                                            </div>

                                            <div className="tab-pane fade" id="rewards">
                                                <div className="settings">
                                                    <div className="info-table">
                                                        <div className="pagination-wrapper">
                                                            <ul className="pager">
                                                                <li>
                                                                    <a
                                                                        onClick={() => {
                                                                            this.getPreviousPage();
                                                                        }}
                                                                    >
                                                                        <i className="fa fa-angle-left"></i>
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a
                                                                        onClick={() => {
                                                                            this.getNextPage();
                                                                        }}
                                                                    >
                                                                        <i className="fa fa-angle-right"></i>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="table-responsive">
                                                            <table className="table table-striped">
                                                                <thead>
                                                                <tr>
                                                                    <th>Reward Date</th>
                                                                    <th>Amount</th>
                                                                    <th>Type</th>
                                                                    <th className="text-center">Notes</th>
                                                                </tr>
                                                                </thead>

                                                                <tbody>
                                                                {rewardLists.length == 0 ? (
                                                                    <tr>
                                                                        <td colSpan="4" align="center">
                                                                            <h3>Records not found.</h3>
                                                                        </td>
                                                                    </tr>
                                                                ) : (
                                                                    rewardLists.map((r, key) => {
                                                                        return (
                                                                            <tr key={key}>
                                                                                <td>
                                                                                    {helperFunctions.formatDateTimeWithDay(
                                                                                        r.createdTime,
                                                                                        "date"
                                                                                    )}
                                                                                </td>
                                                                                <td className="text-info">
                                                                                    <b>
                                                                                        <strong>
                                                                                            $ {(r.amount * 1).toFixed(2)}
                                                                                        </strong>
                                                                                    </b>
                                                                                </td>
                                                                                <td className="text-success">
                                                                                    {r.type}
                                                                                </td>
                                                                                <td align="center">
                                                                                    {helperFunctions.formatDateTimeWithDay(
                                                                                        r.statusTime
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    })
                                                                )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <h4>
                                                            <strong>
                                                                Balance: &nbsp; &nbsp; &nbsp; ${" "}
                                                                {userDetails.rewardBalance > 0
                                                                    ? (userDetails.rewardBalance * 1).toFixed(2)
                                                                    : "0.00"}
                                                            </strong>
                                                        </h4>
                                                    </div>
                                                </div>
                                                <div className="switcher-spacer"></div>
                                                {this.accessRole == true && (
                                                    <div className="settings">
                                                        <form
                                                            name="form"
                                                            className="form-horizontal form-material"
                                                            onSubmit={this.submitReward}
                                                        >
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label
                                                                            className="col-md-12"
                                                                            htmlFor="password"
                                                                        >
                                                                            ADJUST REWARD
                                                                        </label>
                                                                        <div className="col-md-12">
                                                                            <select
                                                                                name="rewardType"
                                                                                value={rewardType}
                                                                                onChange={this.handleChange}
                                                                            >
                                                                                <option value="add">Add</option>
                                                                                <option value="deduct">Deduct</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>

                                                                    <div className="form-group">
                                                                        <label
                                                                            className="col-md-12"
                                                                            htmlFor="reward"
                                                                        >
                                                                            ADJUSTMENT AMOUNT
                                                                        </label>
                                                                        <div className="col-md-12">
                                                                            <input
                                                                                required
                                                                                type="text"
                                                                                maxLength="9"
                                                                                step="0.01"
                                                                                name="addReward"
                                                                                value={user.addReward}
                                                                                onChange={this.handleChange}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label
                                                                            className="col-md-12"
                                                                            htmlFor="rewardNotes"
                                                                        >
                                                                            {" "}
                                                                            NOTES
                                                                        </label>
                                                                        <div className="col-md-12">
                                      <textarea
                                          maxLength="1000"
                                          placeholder="Enter any notes here"
                                          name="rewardNotes"
                                          value={rewardNotes}
                                          onChange={this.handleChange}
                                      />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <div className="col-sm-12">
                                                                            {this.accessRole == true && (
                                                                                <button
                                                                                    className="btn btn-custom-info"
                                                                                    type="submit"
                                                                                >
                                                                                    Update Reward Balance
                                                                                </button>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    {rewardError && (
                                                                        <div className="form-group">
                                                                            <div className="col-sm-12 error">
                                                                                After this deduction user's reward
                                                                                balance will go into negative. Please
                                                                                input another amount.
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                    {rewardSubmitted &&
                                                                        rewardUpdated &&
                                                                        window.customerUpdateSuccess()}
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                )}
                                            </div>

                                            <div className="tab-pane fade" id="forms">
                                                <div className="settings">
                                                    <h3>
                                                        <strong>Pending Forms</strong>
                                                    </h3>
                                                    <ul className="nav pending-form">
                                                        {userDetails.pendingConsentForms &&
                                                            userDetails.pendingConsentForms.length == 0 && (
                                                                <li>
                                                                    <h2
                                                                        className="no-records"
                                                                        className="text-center"
                                                                    >
                                                                        Records not found
                                                                    </h2>
                                                                </li>
                                                            )}

                                                        {userDetails.pendingConsentForms &&
                                                            userDetails.pendingConsentForms.length == 0 &&
                                                            userDetails.pendingConsentForms.map((f, k) => {
                                                                return (
                                                                    <li key={k}>
                                    <span>
                                      {" "}
                                        - {this.findConsentformName(f)}
                                    </span>
                                                                        <a
                                                                            onClick={() => this.getHTMLString(f)}
                                                                            href="#consentForm"
                                                                            data-toggle="modal"
                                                                        >
                                                                            <i className="fa fa-pencil"></i>
                                                                        </a>
                                                                    </li>
                                                                );
                                                            })}
                                                    </ul>
                                                </div>
                                                <div className="switcher-spacer"></div>
                                                <div className="settings">
                                                    <h3>
                                                        <strong>Completed Forms</strong>
                                                    </h3>
                                                    <ul className="nav pending-form completed">
                                                        {this.state.consentForm.length > 0 &&
                                                            this.state.consentForm.map((c, k) => {
                                                                return (
                                                                    <li key={k}>
                                    <span>
                                      {" "}
                                        <b>
                                        {this.findConsentformName(
                                            c.consentForm.id
                                        )}
                                      </b>{" "}
                                        <br/>{" "}
                                        {helperFunctions.formatDateTimeWithDay(
                                            moment(c.createdTime),
                                            "latest"
                                        )}
                                    </span>
                                                                        <p>
                                                                            <a
                                                                                onClick={() =>
                                                                                    this.viewCopletedForm(c.id)
                                                                                }
                                                                                data-toggle="modal"
                                                                                data-target="#viewForm"
                                                                            >
                                                                                <i className="icon-eye text-info"></i>
                                                                            </a>{" "}
                                                                            &nbsp;
                                                                            <a
                                                                                onClick={() =>
                                                                                    this.deleteCompletedForm(c.id)
                                                                                }
                                                                            >
                                                                                <i className="fa fa-trash text-danger"></i>
                                                                            </a>
                                                                        </p>
                                                                    </li>
                                                                );
                                                            })}
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="tab-pane fade" id="appointment">
                                                <div className="settings">
                                                    <div className="actions-group fix">
                                                        <div className="action-left pull-right">
                                                            <select name="label" onChange={this.changeLebel}>
                                                                <option value="none">Select Action</option>
                                                                <option value="appt">Book Appointment</option>
                                                                <option value="standing">Book Standing</option>
                                                                <option value="gift">
                                                                    Gift Card/Product Purchase
                                                                </option>
                                                            </select>
                                                            {this.state.remainingStandingCount != 0 && (
                                                                <button
                                                                    data-toggle="modal"
                                                                    data-target="#standingCancel"
                                                                    className="btn btn-danger"
                                                                >
                                                                    Cancel Standings
                                                                </button>
                                                            )}
                                                        </div>

                                                        <div className="action-right pull-left">
                                                            <label className="radio-inline">
                                                                <input
                                                                    checked={this.state.apt === "open"}
                                                                    onChange={(e) => this.changeAppt(e)}
                                                                    type="radio"
                                                                    value="open"
                                                                    name="apt"
                                                                />
                                                                Upcoming Appt.
                                                            </label>
                                                            <label className="radio-inline">
                                                                <input
                                                                    value="past"
                                                                    checked={this.state.apt === "past"}
                                                                    onChange={(e) => this.changeAppt(e)}
                                                                    type="radio"
                                                                    name="apt"
                                                                />
                                                                Past Appt
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div className="past-open-appts">
                                                        <div className="steamline">
                                                            {this.state.apt == "open" &&
                                                                this.state.openAppts.length == 0 && (
                                                                    <h3 className="text-center">
                                                                        Records not found{" "}
                                                                    </h3>
                                                                )}
                                                            {this.state.apt == "past" &&
                                                                this.state.openAppts.length == 0 && (
                                                                    <h3 className="text-center">
                                                                        Records not found{" "}
                                                                    </h3>
                                                                )}

                                                            {this.state.openAppts.length > 0 &&
                                                                this.state.openAppts.map((a, key) => {
                                                                    return (
                                                                        <div
                                                                            key={key}
                                                                            className={
                                                                                a.requestType == "Shopping"
                                                                                    ? "sl-item shopping"
                                                                                    : "sl-item"
                                                                            }
                                                                        >
                                                                            <p>
                                                                                <i className="fa fa-calendar"></i>
                                                                                <strong>
                                                                                    {helperFunctions.formatDateTimeWithDay(
                                                                                        moment(a.bookedTime),
                                                                                        "latest"
                                                                                    )}
                                                                                </strong>
                                                                            </p>
                                                                            <p>
                                        <span>
                                          <i
                                              className="fa fa-dot-circle-o"
                                              aria-hidden="true"
                                          ></i>{" "}
                                            Confirmed:
                                            {a.confirmationTime ? (
                                                <i className="fa fa-check text-info"></i>
                                            ) : (
                                                <i className="fa fa-close text-danger"></i>
                                            )}
                                        </span>
                                                                                {a.requestType != "Shopping" && (
                                                                                    <span>
                                            <i
                                                className="fa fa-dot-circle-o"
                                                aria-hidden="true"
                                            ></i>{" "}
                                                                                        Standing:
                                                                                        {a.stanndingAppointment == true ? (
                                                                                            <i className="fa fa-check text-info"></i>
                                                                                        ) : (
                                                                                            <i className="fa fa-close text-danger"></i>
                                                                                        )}
                                          </span>
                                                                                )}
                                                                                {a.requestType == "Shopping" && (
                                                                                    <span>
                                            <i
                                                className="fa fa-dot-circle-o"
                                                aria-hidden="true"
                                            ></i>{" "}
                                                                                        Gift Card: $
                                                                                        {(a.giftCardSaleAmount * 1).toFixed(
                                                                                            2
                                                                                        )}
                                          </span>
                                                                                )}

                                                                                <span className="source">
                                          &nbsp; &nbsp;
                                                                                    <i
                                                                                        className="fa fa-dot-circle-o"
                                                                                        aria-hidden="true"
                                                                                    ></i>{" "}
                                                                                    Source:
                                          <i
                                              className={this.getRequestSource(
                                                  a.requestSource
                                              )}
                                          ></i>
                                        </span>
                                                                                <span>
                                          <i
                                              className="fa fa-dot-circle-o"
                                              aria-hidden="true"
                                          ></i>{" "}
                                                                                    Total: $
                                                                                    {(a.totalChargeAmount * 1).toFixed(2)}
                                        </span>
                                                                            </p>
                                                                            {a.requestType != "Shopping" &&
                                                                                a.visitTechnicians
                                                                                    .filter(
                                                                                        (t) => t.status != "Cancelled"
                                                                                    )
                                                                                    .map((s, k) => {
                                                                                        return (
                                                                                            <p key={k}
                                                                                               className="listings">
                                                                                                <b>
                                                                                                    <img
                                                                                                        width="20"
                                                                                                        className="img-circle"
                                                                                                        src={this.findTechnician(
                                                                                                            s.technician.id,
                                                                                                            "image"
                                                                                                        )}
                                                                                                        alt="user"
                                                                                                        className="img-circle"
                                                                                                    />{" "}
                                                                                                    {this.findServiceName(
                                                                                                        s.offeredService.id,
                                                                                                        s.offeredService.category.id
                                                                                                    )}
                                                                                                </b>{" "}
                                                                                                with{" "}
                                                                                                <b>
                                                                                                    {this.findTechnician(
                                                                                                        s.technician.id,
                                                                                                        "nick"
                                                                                                    )}
                                                                                                </b>
                                                                                                <br/>
                                                                                                <b>
                                                                                                    <i
                                                                                                        className="fa fa-dot-circle-o"
                                                                                                        aria-hidden="true"
                                                                                                    ></i>{" "}
                                                                                                    Time:
                                                                                                </b>
                                                                                                {helperFunctions.formatDateTime(
                                                                                                    s.expectedStartTime,
                                                                                                    "time"
                                                                                                )}{" "}
                                                                                                -{" "}
                                                                                                {helperFunctions.formatDateTime(
                                                                                                    s.period.to,
                                                                                                    "time"
                                                                                                )}
                                                                                                <br/>
                                                                                                <b>
                                                                                                    <i
                                                                                                        className="fa fa-dot-circle-o"
                                                                                                        aria-hidden="true"
                                                                                                    ></i>{" "}
                                                                                                    Price:{" "}
                                                                                                </b>
                                                                                                {s.status == "Checked Out"
                                                                                                    ? "$" +
                                                                                                    (
                                                                                                        s.chargeAmount * 1
                                                                                                    ).toFixed(2)
                                                                                                    : this.findServicePrice(
                                                                                                        s.offeredService.id,
                                                                                                        s.technician.id,
                                                                                                        s.offeredService.category
                                                                                                            .id
                                                                                                    )}
                                                                                                <br/>
                                                                                            </p>
                                                                                        );
                                                                                    })}
                                                                            {a.requestType == "Shopping" &&
                                                                                a.purchaseItems.map((s, k) => {
                                                                                    return (
                                                                                        <p key={k} className="listings">
                                                                                            <b>
                                                                                                <strong>
                                                                                                    {
                                                                                                        s.locatedProductVariant
                                                                                                            .variant.product.name
                                                                                                    }
                                                                                                </strong>
                                                                                            </b>{" "}
                                                                                            By{" "}
                                                                                            <b>
                                                                                                <strong>
                                                                                                    {this.findTechnician(
                                                                                                        s.technician.id,
                                                                                                        "nick"
                                                                                                    )}
                                                                                                </strong>
                                                                                            </b>
                                                                                            <br/>
                                                                                            <b>
                                                                                                <i
                                                                                                    className="fa fa-dot-circle-o"
                                                                                                    aria-hidden="true"
                                                                                                ></i>{" "}
                                                                                                Time:
                                                                                            </b>{" "}
                                                                                            {helperFunctions.formatDateTime(
                                                                                                a.visitTechnicians[k]
                                                                                                    .expectedStartTime,
                                                                                                "time"
                                                                                            )}
                                                                                            <br/>
                                                                                            <b>
                                                                                                <i
                                                                                                    className="fa fa-dot-circle-o"
                                                                                                    aria-hidden="true"
                                                                                                ></i>{" "}
                                                                                                Qty:{" "}
                                                                                            </b>
                                                                                            {s.quantity}
                                                                                            <br/>
                                                                                            <b>
                                                                                                <i
                                                                                                    className="fa fa-dot-circle-o"
                                                                                                    aria-hidden="true"
                                                                                                ></i>{" "}
                                                                                                Price:{" "}
                                                                                            </b>
                                                                                            ${" "}
                                                                                            {(s.chargeAmount * 1).toFixed(2)}
                                                                                            <br/>
                                                                                        </p>
                                                                                    );
                                                                                })}
                                                                            <p className="text-primary">
                                                                                <b>Created By</b>:{" "}
                                                                                {a.createUser && a.createUser.fullName}{" "}
                                                                                on{" "}
                                                                                <span style={{color: "#666"}}>
                                          {a.createdTime &&
                                              helperFunctions.formatDateTimeWithDay(
                                                  moment(a.createdTime),
                                                  "latest"
                                              )}
                                        </span>
                                                                            </p>
                                                                            {a.lastUpdateUser && (
                                                                                <p className="text-primary">
                                                                                    <b>Updated By</b>:{" "}
                                                                                    {a.lastUpdateUser &&
                                                                                        a.lastUpdateUser.fullName}{" "}
                                                                                    on{" "}
                                                                                    <span style={{color: "#666"}}>
                                            {a.updatedTime &&
                                                helperFunctions.formatDateTimeWithDay(
                                                    moment(a.updatedTime),
                                                    "latest"
                                                )}
                                          </span>
                                                                                </p>
                                                                            )}
                                                                            {this.state.apt == "open" && (
                                                                                <p className="pb35">
                                          <span className="pull-right">
                                            {a.status == "Booked" && (
                                                <button
                                                    onClick={() =>
                                                        this.openEditModal(a.id)
                                                    }
                                                    data-toggle="modal"
                                                    data-target="#editModal"
                                                    className="btn m-t-10 m-r-5 btn-custom-info"
                                                >
                                                    Edit
                                                </button>
                                            )}
                                              {a.status == "Booked" &&
                                                  a.checkInOutAllowed == true && (
                                                      <button
                                                          onClick={() =>
                                                              this.openCheckinModal(a.id)
                                                          }
                                                          data-toggle="modal"
                                                          data-target="#checkin"
                                                          className="btn m-t-10 m-r-5 btn-info"
                                                      >
                                                          Check In
                                                      </button>
                                                  )}
                                              {a.status == "Checked In" && (
                                                  <button
                                                      onClick={() =>
                                                          this.openEditModal(a.id)
                                                      }
                                                      data-toggle="modal"
                                                      data-target="#editModal"
                                                      className="btn m-t-10 m-r-5 btn-custom-info"
                                                  >
                                                      Edit
                                                  </button>
                                              )}
                                              {a.status == "Checked In" && (
                                                  <button className="btn m-t-10 m-r-5 btn-warning">
                                                      <i className="fa fa-check-square-o"></i>
                                                      {a.period.from &&
                                                          helperFunctions.formatDateTime(
                                                              a.period.from,
                                                              "time"
                                                          )}
                                                  </button>
                                              )}

                                              {a.status == "Booked" &&
                                                  a.checkInOutAllowed == true && (
                                                      <button
                                                          data-toggle="modal"
                                                          data-target="#editModal"
                                                          onClick={() =>
                                                              this.openCheckOutModal(a.id)
                                                          }
                                                          className="btn m-t-10 m-r-5 btn-custom-info"
                                                      >
                                                          Check Out
                                                      </button>
                                                  )}
                                              {a.status == "Checked In" &&
                                                  a.checkInOutAllowed == true && (
                                                      <button
                                                          data-toggle="modal"
                                                          data-target="#editModal"
                                                          onClick={() =>
                                                              this.openCheckOutModal(a.id)
                                                          }
                                                          className="btn m-t-10 m-r-5 btn-custom-info"
                                                      >
                                                          Check Out
                                                      </button>
                                                  )}

                                              {a.status == "Booked" && (
                                                  <button
                                                      onClick={() =>
                                                          this.cancelPastApptModal(a.id)
                                                      }
                                                      data-target="#cancelPastAppt"
                                                      data-toggle="modal"
                                                      data-dismiss="modal"
                                                      className="btn m-t-10 m-r-5 btn-danger"
                                                  >
                                                      Cancel
                                                  </button>
                                              )}

                                              {a.status == "Checked In" && (
                                                  <button
                                                      onClick={() =>
                                                          this.cancelPastApptModal(a.id)
                                                      }
                                                      data-target="#cancelPastAppt"
                                                      data-toggle="modal"
                                                      className="btn m-t-10 m-r-5 btn-danger"
                                                  >
                                                      Cancel
                                                  </button>
                                              )}

                                              {a.status == "Checked Out" && (
                                                  <button className="btn m-t-10 m-r-5 btn-warning">
                                                      <i className="fa fa-check-square-o"></i>
                                                      {a.period.from &&
                                                          helperFunctions.formatDateTime(
                                                              a.period.from,
                                                              "time"
                                                          )}
                                                  </button>
                                              )}

                                              {a.status == "Checked Out" && (
                                                  <button className="btn m-t-10 m-r-5 btn-info">
                                                      <i className="fa fa-check-square-o"></i>
                                                      {a.period.to
                                                          ? helperFunctions.formatDateTime(
                                                              a.period.to,
                                                              "time"
                                                          )
                                                          : this.getCurrentTime()}
                                                  </button>
                                              )}
                                              {a.status == "Cancelled" && (
                                                  <button className="btn m-t-10 m-r-5 btn-danger">
                                                      &times; Canceled
                                                  </button>
                                              )}
                                              {a.status == "No Show" && (
                                                  <button className="btn m-t-10 m-r-5 btn-danger">
                                                      <i className="fa fa-eye"></i>
                                                      No Show
                                                  </button>
                                              )}
                                          </span>
                                                                                </p>
                                                                            )}
                                                                            {this.state.apt == "past" && (
                                                                                <p className="pb35">
                                          <span className="pull-right">
                                            <button
                                                onClick={() =>
                                                    this.openAddToCartModal(a)
                                                }
                                                data-toggle="modal"
                                                data-target="#viewDetails"
                                                className="btn m-t-10 m-r-5 btn-custom-info"
                                            >
                                              View Details
                                            </button>
                                          </span>
                                                                                </p>
                                                                            )}
                                                                        </div>
                                                                    );
                                                                })}
                                                        </div>
                                                    </div>

                                                    {this.state.loading == true && (
                                                        <div className="custom-loading">
                                                            <div className="loading">
                                                                <i className="fa fa-spinner fa-spin"></i>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="tab-pane fade" id="formula">
                                                <div className="text-right">
                                                    <button
                                                        onClick={this.openNewFormula}
                                                        data-toggle="modal"
                                                        data-target="#addNew"
                                                        type="button"
                                                        className="btn btn-custom-info"
                                                    >
                                                        <i className="fa fa-plus"></i> &nbsp; Add New
                                                    </button>
                                                </div>
                                                <div className="settings">
                                                    <div className="table-responsive">
                                                        <table
                                                            className="table table-striped m-t-30 table-hover contact-list"
                                                            data-page-size="5"
                                                        >
                                                            <thead>
                                                            <tr>
                                                                <th>Last Used</th>
                                                                <th>Formula Type</th>
                                                                <th>Notes</th>
                                                                <th>Staff</th>
                                                                <th>Action</th>
                                                            </tr>
                                                            </thead>

                                                            <tbody>
                                                            {formulass && formulass.length == 0 && (
                                                                <tr>
                                                                    <td colSpan="5" align="center">
                                                                        <h2 className="no-recordsno-re">
                                                                            Records not found.
                                                                        </h2>
                                                                    </td>
                                                                </tr>
                                                            )}

                                                            {formulass &&
                                                                formulass.map((f, key) => {
                                                                    return (
                                                                        <tr key={key}>
                                                                            <td>
                                                                                {helperFunctions.formatDateTimeWithDay(
                                                                                    f.lastUseTime,
                                                                                    "latest"
                                                                                )}
                                                                            </td>
                                                                            <td>
                                                                                <b>{f.type}</b>
                                                                            </td>
                                                                            <td>
                                                                                <div className="note note-reward">
                                            <span>
                                              {f.notes.length > 10
                                                  ? `${f.notes.substr(0, 10)}...`
                                                  : f.notes}
                                            </span>
                                                                                    <div className="customer-note">
                                                                                        <div className="chat-body">
                                                                                            <div className="chat-text">
                                                                                                <small>Customer
                                                                                                    Note:</small>
                                                                                                <p> {f.notes} </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <b>
                                                                                    {this.findTechnicianName(
                                                                                        f.technician.id
                                                                                    )}
                                                                                </b>
                                                                            </td>
                                                                            <td>
                                                                                <button
                                                                                    onClick={() =>
                                                                                        this.openEditFormula(f.id)
                                                                                    }
                                                                                    className="btn btn-outline btn-circle btn-lg btn-info"
                                                                                >
                                                                                    <i className="ti ti-pencil"></i>
                                                                                </button>

                                                                                <button
                                                                                    onClick={() =>
                                                                                        this.deleteFormula(f.id)
                                                                                    }
                                                                                    className="btn btn-outline btn-circle btn-lg btn-danger"
                                                                                >
                                                                                    <i className="ti ti-close"></i>
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    {this.state.loading == true && (
                                                        <div className="custom-loading">
                                                            <div className="loading">
                                                                <i className="fa fa-spinner fa-spin"></i>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="tab-pane fade" id="waitlists">
                                                <div className="settings">
                                                    <div className="text-right add-button">
                                                        <button
                                                            onClick={this.openCreateWaitList}
                                                            data-toggle="modal"
                                                            data-target="#createWait"
                                                            className="btn btn-custom-info"
                                                        >
                                                            Add new
                                                        </button>
                                                        <div className="switcher-spacer"></div>
                                                    </div>
                                                    <div className="steamline">
                                                        {waitlist && waitlist.length == 0 && (
                                                            <h3 className="text-center">
                                                                Records not found{" "}
                                                            </h3>
                                                        )}

                                                        {waitlist &&
                                                            waitlist.length > 0 &&
                                                            waitlist.map((a, key) => {
                                                                return (
                                                                    <div key={key} className="sl-item">
                                                                        <div className="sl-left">
                                                                            <img
                                                                                width="36"
                                                                                src={a.client.imageUrl}
                                                                                alt="user"
                                                                                className="img-circle"
                                                                            />
                                                                        </div>
                                                                        <div className="sl-right">
                                                                            <div className="m-l-40">
                                                                                <p>
                                                                                    <strong>{a.client.fullName}</strong>{" "}
                                                                                    &nbsp;
                                                                                    <span>{a.readableCreateTime}</span>
                                                                                </p>
                                                                                <b>
                                                                                    <em>
                                                                                        <i className="fa fa-phone"></i>{" "}
                                                                                        {helperFunctions.formatMobilePhone(
                                                                                            a.client.mobileNumber
                                                                                        )}
                                                                                        <br/>
                                                                                        <i className="fa fa-envelope-o"></i>
                                                                                        {a.client.emailAddress}
                                                                                        <br/>
                                                                                    </em>
                                                                                </b>

                                                                                {a.services.length > 0 &&
                                                                                    a.services.map((s, k) => {
                                                                                        return (
                                                                                            <React.Fragment key={k}>
                                                                                                <b>{s.service.name}</b> with{" "}
                                                                                                <b>
                                                                                                    {s.technicians.length > 0
                                                                                                        ? this.findTechnician(
                                                                                                            s.technicians[0].id,
                                                                                                            "nick"
                                                                                                        )
                                                                                                        : "Any Stylist"}
                                                                                                    {s.technicians.length > 1 && (
                                                                                                        <React.Fragment>
                                                                                                            <a
                                                                                                                style={{
                                                                                                                    cursor: "pointer",
                                                                                                                }}
                                                                                                                onClick={window.initiatePopover()}
                                                                                                                tabIndex="0"
                                                                                                                data-toggle="popover"
                                                                                                                data-popover-content="#a1"
                                                                                                                data-placement="right"
                                                                                                            >
                                                                                                                {" "}
                                                                                                                {
                                                                                                                    s.technicians.length
                                                                                                                }{" "}
                                                                                                                Others
                                                                                                            </a>
                                                                                                            <div
                                                                                                                id="a1"
                                                                                                                className="hidden"
                                                                                                            >
                                                                                                                <div
                                                                                                                    className="popover-heading">
                                                                                                                    Technician
                                                                                                                    Lists
                                                                                                                </div>

                                                                                                                <div
                                                                                                                    className="popover-body">
                                                                                                                    <ul>
                                                                                                                        {s.technicians.map(
                                                                                                                            (t, k) => {
                                                                                                                                return (
                                                                                                                                    <li key={k}>
                                                                                                                                        {this.findTechnician(
                                                                                                                                            t.id,
                                                                                                                                            "nick"
                                                                                                                                        )}
                                                                                                                                    </li>
                                                                                                                                );
                                                                                                                            }
                                                                                                                        )}
                                                                                                                    </ul>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </React.Fragment>
                                                                                                    )}
                                                                                                </b>
                                                                                                <br/>
                                                                                                <p className="text-italic">
                                                                                                    {helperFunctions.formatDateTimeWithDay(
                                                                                                        a.periods[k].from,
                                                                                                        "latest"
                                                                                                    )}{" "}
                                                                                                    -{" "}
                                                                                                    {helperFunctions.formatDateTimeWithDay(
                                                                                                        a.periods[k].to,
                                                                                                        "latest"
                                                                                                    )}
                                                                                                </p>
                                                                                            </React.Fragment>
                                                                                        );
                                                                                    })}
                                                                            </div>
                                                                            <p className="pb35">
                                        <span className="pull-right">
                                          <button
                                              data-dismiss="modal"
                                              data-target="#editWaitList"
                                              data-toggle="modal"
                                              onClick={() =>
                                                  this.editWaitList(a, a.client.id)
                                              }
                                              className="btn m-t-10 m-r-5 btn-custom-info"
                                          >
                                            Edit
                                          </button>

                                          <button
                                              onClick={() =>
                                                  this.deleteWaitList(a.id)
                                              }
                                              data-toggle="modal"
                                              className="btn m-t-10 m-r-5 btn-danger"
                                          >
                                            Delete
                                          </button>
                                        </span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="shopping">
                                                <div className="settings">
                                                    <div className="text-right add-button">
                                                        <button
                                                            onClick={this.openShopping}
                                                            data-toggle="modal"
                                                            data-target="#Shopping"
                                                            className="btn btn-custom-info"
                                                        >
                                                            Add new
                                                        </button>
                                                        <div className="switcher-spacer"></div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="tab-pane fade" id="communication">
                                                <div className="settings">
                                                    <div className="filter">
                                                        <div className="filter-wrapper">
                                                            <div className="filter-button"
                                                                 onClick={() => this.setState(prev => {
                                                                     return {...prev, active: !prev.active}
                                                                 })}>
                                                                <div className="icon-filter">
                                                                    <i className="fa fa-sliders"></i>
                                                                </div>
                                                                <div className="label-filter">Filter</div>
                                                            </div>
                                                            {this.checkAccess() == true && (
                                                                <div
                                                                    style={{cursor: "pointer"}}
                                                                    onClick={() => this.openCaller()}
                                                                    id="add-history"
                                                                    className="add-history"
                                                                >
                                                                    <div
                                                                        className="icon-filter"
                                                                        style={{background: "green"}}
                                                                    >
                                                                        <Plush/>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className={`filter-control ${this.state.active}`}>
                                                            <div className="types">
                                                                <h5>
                                                                    Types:{" "}
                                                                    {this.state.con_errors.types && (
                                                                        <InlineMessage
                                                                            text={this.state.con_errors.types}
                                                                        />
                                                                    )}
                                                                </h5>

                                                                <div className="control-item">
                                                                    <div className="form-check">
                                                                        <input
                                                                            defaultChecked={true}
                                                                            name="types"
                                                                            onClick={this.changeTypes}
                                                                            id="t_sms"
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            value="SMS"
                                                                        />
                                                                        <label htmlFor="t_sms">SMS</label>
                                                                    </div>
                                                                    <div className="form-check">
                                                                        <input
                                                                            defaultChecked={true}
                                                                            name="types"
                                                                            onClick={this.changeTypes}
                                                                            id="t_email"
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            value="EMAIL"
                                                                        />
                                                                        <label htmlFor="t_email">Email</label>
                                                                    </div>
                                                                    <div className="form-check">
                                                                        <input
                                                                            defaultChecked={true}
                                                                            name="types"
                                                                            onClick={this.changeTypes}
                                                                            id="t_voice"
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            value="VOICE"
                                                                        />
                                                                        <label htmlFor="t_voice">Voice</label>
                                                                    </div>

                                                                    <div className="form-check">
                                                                        <input
                                                                            defaultChecked={true}
                                                                            name="types"
                                                                            onClick={this.changeTypes}
                                                                            id="t_push"
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            value="PUSH"
                                                                        />
                                                                        <label htmlFor="t_push">Push</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="types">
                                                                <h5>
                                                                    Directions:{" "}
                                                                    {this.state.con_errors.directions && (
                                                                        <InlineMessage
                                                                            text={this.state.con_errors.directions}
                                                                        />
                                                                    )}
                                                                </h5>

                                                                <div className="control-item">
                                                                    <div className="form-check">
                                                                        <input
                                                                            defaultChecked={true}
                                                                            name="direction"
                                                                            onClick={this.changeDirections}
                                                                            id="t_inbound"
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            value="INBOUND"
                                                                        />
                                                                        <label htmlFor="t_inbound">Inbound</label>
                                                                    </div>
                                                                    <div className="form-check">
                                                                        <input
                                                                            defaultChecked={true}
                                                                            name="direction"
                                                                            onClick={this.changeDirections}
                                                                            id="t_outbound"
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            value="OUTBOUND"
                                                                        />
                                                                        <label htmlFor="t_outbound">Outbound</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="types">
                                                                <h5>
                                                                    Status:{" "}
                                                                    {this.state.con_errors.status && (
                                                                        <InlineMessage
                                                                            text={this.state.con_errors.status}
                                                                        />
                                                                    )}
                                                                </h5>

                                                                <div className="control-item">
                                                                    <div className="form-check">
                                                                        <input
                                                                            defaultChecked={true}
                                                                            name="status"
                                                                            onClick={this.changeStatus}
                                                                            id="t_inprogress"
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            value="IN_PROGRESS"
                                                                        />
                                                                        <label htmlFor="t_inprogress">
                                                                            In Progress
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check">
                                                                        <input
                                                                            defaultChecked={true}
                                                                            name="status"
                                                                            onClick={this.changeStatus}
                                                                            id="t_completed"
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            value="COMPLETED"
                                                                        />
                                                                        <label htmlFor="t_completed">
                                                                            Completed
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check">
                                                                        <input
                                                                            defaultChecked={true}
                                                                            name="status"
                                                                            onClick={this.changeStatus}
                                                                            id="t_failed"
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            value="FAILED"
                                                                        />
                                                                        <label htmlFor="t_failed">Failed</label>
                                                                    </div>
                                                                    <div className="form-check">
                                                                        <input
                                                                            defaultChecked={true}
                                                                            name="status"
                                                                            onClick={this.changeStatus}
                                                                            id="t_no_answer"
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            value="NO_ANSWER"
                                                                        />
                                                                        <label htmlFor="t_no_answer">
                                                                            No Answer
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div
                                                                className="go"
                                                                onClick={this.loadCommunication}
                                                            >
                                                                <label className="label label-warning">
                                                                    Filter
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="history">
                                                        <div className="label-part">
                                                            <div className="row lbls">
                                                                <div className="col-md-3">
                                                                    <h5>Type</h5>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <h5>Date/Time</h5>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <h5>Direction</h5>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <h5>Status</h5>
                                                                </div>
                                                            </div>
                                                            {this.state.communication.length > 0 &&
                                                                this.state.communication.map((c, k) => {
                                                                    return (
                                                                        <div key={k} className="row rw-content">
                                                                            <div className="col-md-3">
                                                                                {c.notificationType == "PUSH" ? (
                                                                                    <i className="fa fa-bell"></i>
                                                                                ) : c.notificationType == "EMAIL" ? (
                                                                                    <i className="fa fa-envelope-o"></i>
                                                                                ) : c.notificationType == "SMS" ? (
                                                                                    <i className="fa fa-comment-o"></i>
                                                                                ) : c.notificationType == "VOICE" ? (
                                                                                    <i className="fa fa-phone"></i>
                                                                                ) : (
                                                                                    ""
                                                                                )}{" "}
                                                                                {c.notificationType
                                                                                        .toString()[0]
                                                                                        .toUpperCase() +
                                                                                    c.notificationType
                                                                                        .toString()
                                                                                        .slice(1)
                                                                                        .toLowerCase()}
                                                                            </div>
                                                                            <div className="col-md-3">
                                                                                {helperFunctions.formatDateTimeWithDay(
                                                                                    moment(c.createdTime),
                                                                                    "latest"
                                                                                )}
                                                                            </div>
                                                                            <div className="col-md-3">
                                                                                {c.direction
                                                                                        .toString()[0]
                                                                                        .toUpperCase() +
                                                                                    c.direction
                                                                                        .toString()
                                                                                        .slice(1)
                                                                                        .toLowerCase()}{" "}
                                                                            </div>
                                                                            <div className="col-md-3">
                                                                                {c.deliveryStatus
                                                                                        .toString()[0]
                                                                                        .toUpperCase() +
                                                                                    c.deliveryStatus
                                                                                        .toString()
                                                                                        .slice(1)
                                                                                        .toLowerCase()}
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                        </div>
                                                    </div>
                                                    {this.state.communication.length == 10 && (
                                                        <div className="load-more">
                                                            <div
                                                                className={
                                                                    this.state.loadMore == true
                                                                        ? "load-more-button loadMore"
                                                                        : "load-more-button"
                                                                }
                                                            >
                                                                <button onClick={this.loadNext}>
                                                                    <i className="fa fa-refresh"></i>
                                                                </button>
                                                                <p>Load More</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            {Object.keys(this.state.userDetails).length > 0 &&
                                                this.state.userDetails.personaTribes.length > 0 && (
                                                    <div className="tab-pane fade" id="persona">
                                                        <div className="settings">
                                                            <div className="persona-info">
                                                                <Persona
                                                                    tribes={this.state.userDetails.personaTribes}
                                                                    prevQuestions={this.state.prevQuestions}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <Footer/>

                    <AddNewWaitlist
                        customer={
                            qs.parse(this.props.location.search.replace("?", "")).custId
                        }
                        place={this.state.place}
                        clearPlace={this.clearPlace}
                        setPlace={this.setPlace}
                        selectNewCustomer={this.state.selectNewCustomer}
                        loading={this.state.loading}
                        saveWaitList={this.saveWaitList}
                    />

                    <div
                        data-backdrop="static"
                        data-keyboard="false"
                        className="modal"
                        id="cancelPastAppt"
                    >
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button
                                        onClick={this.resetStateWithModalClose}
                                        className="btn close"
                                        data-dismiss="modal"
                                    >
                                        &times;
                                    </button>
                                    <h3>Cancellation Reason</h3>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label>
                                            Reason: &nbsp; <span className="text-danger">*</span>
                                        </label>
                                        <textarea
                                            onChange={this.handleChange}
                                            required
                                            maxLength="1000"
                                            className="form-control height-200"
                                            value={this.state.cancelReason}
                                            name="cancelReason"
                                        ></textarea>
                                        <p>
                                            {errors.reason && <InlineMessage text={errors.reason}/>}
                                        </p>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        onClick={() => this.cancelPastAppt()}
                                        type="submit"
                                        className="btn btn-custom-info"
                                    >
                                        Cancel
                                    </button>
                                </div>
                                {this.state.loading == true && (
                                    <div className="custom-loading">
                                        <div className="loading">
                                            <i className="fa fa-spinner fa-spin"></i>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="modal" id="standingCancel">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button className="close" data-dismiss="modal">
                                        &times;
                                    </button>
                                    <strong>Cancellation Reason</strong>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label>Write a reason for Cancellation</label>
                                        <textarea
                                            value={this.state.cancelReason}
                                            name="cancelReason"
                                            onChange={this.handleChange}
                                            className="form-control"
                                        ></textarea>
                                    </div>
                                </div>

                                <div className="modal-footer">
                                    <button
                                        onClick={this.cancelStandings}
                                        className="btn btn-danger"
                                    >
                                        Cancel Standings
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        className="modal"
                        id="standingAppt"
                        role="dialog"
                        aria-labelledby="exampleModalLabel1"
                    >
                        <div className="modal-dialog custom-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button className="close" data-dismiss="modal">
                                        &times;
                                    </button>
                                    <strong>Standing Appointment</strong>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="panel panel-group" id="parent">
                                                <div className="panel panel-default parent-panel">
                                                    <div
                                                        className="panel-heading main-panel"
                                                        data-target="#service-staff"
                                                        data-toggle="collapse"
                                                    >
                                                        <p>
                                                            <a href="#service-staff">
                                                                <span>Step 1: </span>
                                                                <span>Choose Service & Staff</span>
                                                                <b className="caret"></b>
                                                            </a>
                                                        </p>
                                                    </div>

                                                    <div className="guide-first guide-common">
                                                        <div className="message-inner">
                                                            <p>
                                                                To get started, please click on "Choose Service
                                                                & Staff" to select a service.
                                                                <button className="close-msg one">
                                                                    Got It !
                                                                </button>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="panel-body panel-collapse collapse"
                                                        id="service-staff"
                                                    >
                                                        <div className="form-group select-custom">
                                                            <Select2
                                                                data={this.services}
                                                                options={{
                                                                    placeholder: "Select a service",
                                                                    width: "100%",
                                                                    height: "40px",
                                                                }}
                                                                onChange={this.onchangeStanding}
                                                                name="service"
                                                                value={service && service}
                                                            />
                                                        </div>
                                                        {
                                                            // eslint-disable-next-line
                                                            technicianss.length == 0 && service != "" && (
                                                                <div>
                                                                    <p className="pt10 warning">
                                                                        No technician found. Please select another
                                                                        service.
                                                                    </p>
                                                                </div>
                                                            )
                                                        }
                                                        {technicianss.length !== 0 && (
                                                            <div className="form-group">
                                                                <label style={{marginLeft: 38 + "px"}}>
                                                                    Select Staff
                                                                </label>
                                                                <select
                                                                    className="hidden"
                                                                    name="technician"
                                                                    id="technicians-select-stand"
                                                                    value={technician}
                                                                    onChange={this.onchangeStanding}
                                                                >
                                                                    <option key={0} value="any">
                                                                        Any Staff
                                                                    </option>
                                                                    {technicianss.map((e, key) => {
                                                                        return (
                                                                            <option key={e.id} value={e.id}>
                                                                                {e.name}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                                <div id="technicians-select-div-stand"></div>
                                                                <div className="guide-second">
                                                                    <p>
                                                                        Good. You have picked your service. Next,
                                                                        select your preferred staff.
                                                                    </p>
                                                                    <button className="close-msg two">
                                                                        Got It !
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div className="service-res pull-left"></div>

                                                        <div className="cart-btn text-right">
                                                            <button
                                                                className="btn btn-info"
                                                                onClick={() => this.addReservation()}
                                                            >
                                                                Add To Cart
                                                            </button>
                                                            <div className="guide-third">
                                                                <p>
                                                                    Very nice! Please click "Add To Cart" button
                                                                    to add them to the cart.
                                                                </p>
                                                                <button className="close-msg third">
                                                                    Got It!
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {selectedServicess.length > 0 && (
                                                <div className="panel panel-group">
                                                    <div className="panel panel-default parent-panel date-panel">
                                                        <div className="panel-heading main-panel">
                                                            <p>
                                                                <a href="#date-time" data-toggle="collapse">
                                                                    <span>Step 2: </span>
                                                                    <span>Choose Date & Time</span>
                                                                    <b className="caret"></b>
                                                                </a>
                                                            </p>

                                                            <div className="guide-common guide-fourth">
                                                                <p>
                                                                    Now It is time to select expected date and
                                                                    time. Please click on "Choose Date & Time" to
                                                                    select.
                                                                    <button className="close-msg fourth">
                                                                        Got It !
                                                                    </button>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="panel-body panel-collapse collapse"
                                                            id="date-time"
                                                        >
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <DatePickerInput
                                                                            showOnInputClick={true}
                                                                            placeholder="MM/DD/YYYY"
                                                                            minDate={new Date()}
                                                                            readOnly
                                                                            onChange={this.handleDateRangeStart}
                                                                            className={"date"}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <DatePickerInput
                                                                            showOnInputClick={true}
                                                                            placeholder="MM/DD/YYYY"
                                                                            minDate={new Date()}
                                                                            readOnly
                                                                            onChange={this.handleDateRangeEnd}
                                                                            className="date"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <label>Choose Time</label>
                                                                        <select
                                                                            name="time"
                                                                            onChange={this.onchangeStanding}
                                                                            className="form-control"
                                                                        >
                                                                            <option value="ANYTIME">Any Time</option>
                                                                            <option value="MORNING">
                                                                                Morning (Before 12PM)
                                                                            </option>
                                                                            <option value="AFTERNOON">
                                                                                Afternoon (12PM to 5PM)
                                                                            </option>
                                                                            <option value="EVENING">
                                                                                Evening (After 5PM)
                                                                            </option>
                                                                            <option value="TIME_RANGE">
                                                                                Pick a Time range
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {time != "" && time === "TIME_RANGE" && (
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div className="form-group">
                                                                            <label className="m-t-20">
                                                                                Start Time
                                                                            </label>
                                                                            <CustomTimePicker
                                                                                format={format}
                                                                                name={"startTime"}
                                                                                onChange={this.handleStartTime}
                                                                                value={this.state.timeStart}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group">
                                                                            <label className="m-t-20">End Time</label>
                                                                            <CustomTimePicker
                                                                                format={format}
                                                                                name={"endTime"}
                                                                                onChange={this.handleEndTime}
                                                                                value={this.state.timeEnd}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <p className="text-danger">
                                                        {errors.datePreference && (
                                                            <InlineMessage text={errors.datePreference}/>
                                                        )}
                                                    </p>
                                                </div>
                                            )}

                                            {selectedServicess.length > 0 && (
                                                <div className="panel panel-group">
                                                    <div className="panel panel-default parent-panel date-panel">
                                                        <div className="panel-heading main-panel">
                                                            <p>
                                                                <a href="#interval" data-toggle="collapse">
                                                                    <span>Step 3: </span>
                                                                    <span>Interval Control</span>
                                                                    <b className="caret"></b>
                                                                </a>
                                                            </p>
                                                        </div>

                                                        <div
                                                            className="panel-body panel-collapse collapse"
                                                            id="interval"
                                                        >
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label>Day Of Week</label>
                                                                        <select
                                                                            onChange={this.onchangeStanding}
                                                                            name="daysOfWeek"
                                                                            className="form-control"
                                                                        >
                                                                            <option value="any">Any Day</option>
                                                                            <option value="SUNDAY">Sunday</option>
                                                                            <option value="MONDAY">Monday</option>
                                                                            <option value="TUESDAY">Tuesday</option>
                                                                            <option value="WEDNESDAY">
                                                                                Wednesday
                                                                            </option>
                                                                            <option value="THURSDAY">Thursday</option>
                                                                            <option value="FRIDAY">Friday</option>
                                                                            <option value="SATURDAY">Saturday</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label>Day Between</label>
                                                                        <select
                                                                            onChange={this.onchangeStanding}
                                                                            name="daysBetween"
                                                                            value={this.state.daysBetween}
                                                                            className="form-control"
                                                                        >
                                                                            <option value="none">
                                                                                Select Between
                                                                            </option>
                                                                            {Array.from({length: 60}).map(
                                                                                (_, index) => {
                                                                                    return (
                                                                                        <option value={index + 1}>
                                                                                            {index + 1}
                                                                                        </option>
                                                                                    );
                                                                                }
                                                                            )}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            <div className="selected-result">
                                                <div className="reservation-rows">
                                                    {selectedServicess.length != 0 &&
                                                        selectedServicess.map((e, key) => {
                                                            return (
                                                                <p key={key} googl="true">
                                                                    <b>{e.serviceName}</b> with{" "}
                                                                    <b>{e.technicianName}</b>
                                                                    <a
                                                                        href="#"
                                                                        onClick={() =>
                                                                            this.deleteReservation({e})
                                                                        }
                                                                    >
                                                                        <span
                                                                            className="fa fa-trash-o text-danger pull-right"></span>
                                                                    </a>
                                                                </p>
                                                            );
                                                        })}
                                                </div>
                                            </div>
                                            {selectedServicess.length != 0 &&
                                                time !== "" &&
                                                (time !== "TIME_RANGE" ||
                                                    (time === "TIME_RANGE" &&
                                                        timeStart != "" &&
                                                        timeEnd != "")) && (
                                                    <div className="check">
                                                        <button
                                                            onClick={() => this.checkAvailability()}
                                                            className="btn btn-block btn-info"
                                                        >
                                                            Check Availability
                                                        </button>
                                                    </div>
                                                )}
                                        </div>

                                        <div className="col-md-6">
                                            {showSlots > 0 &&
                                                slots &&
                                                slots.length != 0 &&
                                                !placingReservation &&
                                                !reservationPlaced && (
                                                    <div className="book-all">
                                                        <button
                                                            onClick={() =>
                                                                this.setAppointmentTime(slots, "All")
                                                            }
                                                            className="btn btn-danger"
                                                        >
                                                            Book All
                                                        </button>
                                                    </div>
                                                )}
                                            {
                                                // eslint-disable-next-line
                                                findingAvailability &&
                                                !placingReservation &&
                                                !reservationPlaced && (
                                                    <div className="loaderDiv">
                                                        <h3>
                                                            Finding available slots that work for you...
                                                        </h3>
                                                        <div className="loader"></div>
                                                    </div>
                                                )
                                            }
                                            {
                                                // eslint-disable-next-line
                                                slots && slots.length == 0 && (
                                                    <div className="alert-message">
                                                        <div className="message-inner text-center">
                                                            <div className="message-icon">
                                                                <img
                                                                    width="100"
                                                                    src="/assets/images/undone.png"
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className="message-text">
                                                                <p>
                                                                    Unfortunately there were no appointment slots
                                                                    for your current preferences. Please change
                                                                    your preferences!
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            {showSlots > 0 &&
                                                slots &&
                                                slots.length != 0 &&
                                                !placingReservation &&
                                                !reservationPlaced &&
                                                slots.map((e, key) => {
                                                    return (
                                                        <div className="slection" key={key}>
                                                            {
                                                                <div className="card">
                                                                    {e.visitTechnicians.map((s, k) => {
                                                                        return (
                                                                            <div key={k}>
                                                                                <div className="card-body">
                                                                                    <div className="card-left">
                                                                                        <h4>
                                                                                            <b>
                                                                                                {this.findServiceName(
                                                                                                    s.offeredService.id,
                                                                                                    s.offeredService.category.id
                                                                                                )}
                                                                                            </b>{" "}
                                                                                            with{" "}
                                                                                            <b>
                                                                                                {this.findTechnicianName(
                                                                                                    s.technician.id
                                                                                                )}
                                                                                            </b>
                                                                                        </h4>
                                                                                        <p>
                                                                                            Date:{" "}
                                                                                            <b>
                                                                                                {" "}
                                                                                                {this.formatDateTime(
                                                                                                    s.expectedStartTime,
                                                                                                    "date"
                                                                                                )}
                                                                                            </b>
                                                                                        </p>
                                                                                        <p>
                                                                                            Duration:{" "}
                                                                                            <b>
                                                                                                {this.findServiceDuration(
                                                                                                    s.offeredService.id,
                                                                                                    s.technician.id,
                                                                                                    s.offeredService.category.id
                                                                                                )}{" "}
                                                                                                min
                                                                                            </b>
                                                                                        </p>
                                                                                        <p>
                                                                                            Time:{" "}
                                                                                            <b>
                                                                                                {this.formatDateTime(
                                                                                                    s.expectedStartTime,
                                                                                                    "time"
                                                                                                )}
                                                                                            </b>
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className="tech-thumb">
                                                                                        <img
                                                                                            src={this.findTechnicianImage(
                                                                                                s.technician.id
                                                                                            )}
                                                                                            alt="tech"
                                                                                        />
                                                                                        <p className="text-center">
                                                                                            <b>
                                                                                                {this.findServicePrice(
                                                                                                    s.offeredService.id,
                                                                                                    s.technician.id,
                                                                                                    s.offeredService.category.id
                                                                                                )}
                                                                                            </b>
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                                {k != e.visitTechnicians.length - 1 && (
                                                                                    <div className="card-diver"></div>
                                                                                )}
                                                                            </div>
                                                                        );
                                                                    })}
                                                                    <div className="card-footer">
                                                                        <button
                                                                            className="btn add-btn"
                                                                            onClick={() =>
                                                                                this.setAppointmentTime(
                                                                                    e.visitTechnicians
                                                                                )
                                                                            }
                                                                        >
                                                                            Book
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    );
                                                })}
                                            {
                                                // eslint-disable-next-line
                                                placingReservation && (
                                                    <div className="loaderDiv">
                                                        <h3>Placing reservation ...</h3>
                                                        <div className="loader"></div>
                                                    </div>
                                                )
                                            }
                                            {
                                                // eslint-disable-next-line
                                                reservationPlaced == true && (
                                                    <div className="success-message">
                                                        <div className="message-inner">
                                                            <div className="message-icon">
                                                                <img src="/assets/img/checked.png" alt=""/>
                                                            </div>
                                                            <div className="message-text">
                                                                <span>All Done!</span>
                                                                <p>
                                                                    Your Reservation has been placed successfully!
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            {
                                                // eslint-disable-next-line
                                                reservationPlaced == false && (
                                                    <div className="alert-message">
                                                        <div className="message-inner">
                                                            <div className="message-icon">
                                                                <img src="/assets/img/undone.png" alt=""/>
                                                            </div>
                                                            <div className="message-text">
                                                                <p>{reservationError}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>

                                        <div className="height-spacer"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="reservationNote">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button
                                        data-toggle="modal"
                                        data-target="#standingAppt"
                                        data-dismiss="modal"
                                        className="close"
                                        onClick={this.closeThisModal}
                                    >
                                        &times;
                                    </button>
                                    <h4>Add Reservation Note.</h4>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group">
                    <textarea
                        value={this.state.notes}
                        rows="10"
                        className="form-control"
                        name="notes"
                        onChange={this.handleChange}
                        placeholder="Enter any notes for technician..."
                    ></textarea>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        onClick={() => {
                                            this.placeReservation();
                                        }}
                                        className="btn btn-custom-info"
                                    >
                                        Confirm
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <EditCart
                        singleAppt={this.state.editApptDetails}
                        updateStaffNotes={this.updateStaffNotes}
                        confirmAppt={this.confirmAppt}
                        apptAddUpdateService={this.apptAddUpdateService}
                        cancelServieFromAppt={this.cancelServieFromAppt}
                        checkServiceIn={this.checkServiceIn}
                        UndocheckServiceIn={this.UndocheckServiceIn}
                        checkServiceOut={this.checkServiceOut}
                        updateBookingTime={this.updateBookingTime}
                        saveProduct={this.saveProduct}
                        deleteProduct={this.deleteProduct}
                        loading={this.state.loading}
                        checkOut={this.state.checkOut}
                        apptCheckout={this.apptCheckout}
                    />

                    <div className="modal consent" id="consentForm">
                        <div className="modal-dialog ">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button className="close" data-dismiss="modal">
                                        &times;
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="wrapper consent-wrapper">
                                        <div
                                            size="A4"
                                            id="pdfForm"
                                            dangerouslySetInnerHTML={{
                                                __html: this.state.htmlContentString,
                                            }}
                                        ></div>
                                        <div className="save-image-btn">
                                            <button
                                                className="btn btn-primary"
                                                onClick={() => this.printDocument()}
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {this.props.loading == true && (
                                    <div className="custom-loading">
                                        <div className="loading">
                                            <i className="fa fa-spinner fa-spin"></i>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div
                        data-backdrop="static"
                        data-keyboard="false"
                        className="modal fade"
                        id="createEvent"
                    >
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <React.Fragment>
                                    <div className="modal-header">
                                        <button
                                            onClick={this.refreshCreateModal}
                                            type="button"
                                            className="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            <span aria-hidden="true">×</span>
                                        </button>
                                        <h4 className="modal-title" id="cancelLabelModel">
                                            Appointment
                                        </h4>
                                    </div>
                                    <div className="modal-body">
                                        <div className="client-info addApt">
                                            <div className="info-header">
                                                <div className="client">
                                                    {this.state.customer && (
                                                        <React.Fragment>
                                                            <div className="chat-image">
                                                                <img
                                                                    src={this.state.customer.imageUrl}
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className="chat-body">
                                                                <div className="chat-text">
                                                                    <div>
                                                                        <h3>
                                                                            <strong>
                                                                                {this.state.customer.fullName}
                                                                            </strong>
                                                                        </h3>
                                                                        <p className="mobileNumber">
                                                                            <i className="fa fa-phone"></i>
                                                                            {customer.mobileNumber}
                                                                        </p>
                                                                        <p>
                                                                            <i className="fa fa-dollar"></i>{" "}
                                                                            {this.state.customer.rewardBalance}
                                                                        </p>
                                                                        <div className="appt-time">
                                                                            <p>
                                                                                <i className="fa fa-calendar"></i>{" "}
                                                                                {helperFunctions.formatDateTimeWithDay(
                                                                                    this.state.apptDate,
                                                                                    "latest"
                                                                                )}
                                                                                {
                                                                                    <button
                                                                                        onClick={this.editDate}
                                                                                        className="edittime"
                                                                                    >
                                                                                        {this.state.editDate == false ? (
                                                                                            <i className="fa fa-pencil text-info"></i>
                                                                                        ) : (
                                                                                            <i className="fa fa-remove text-danger"></i>
                                                                                        )}
                                                                                    </button>
                                                                                }
                                                                            </p>

                                                                            {this.state.editDate && (
                                                                                <div className="editDate-datePicker">
                                                                                    <DatetimePickerTrigger
                                                                                        moment={this.state.apptDate}
                                                                                        onChange={this.changeBookedTime}
                                                                                    >
                                                                                        <input
                                                                                            type="text"
                                                                                            value={this.state.apptDate.format(
                                                                                                "YYYY-MM-DD HH:mm"
                                                                                            )}
                                                                                            readOnly
                                                                                        />
                                                                                    </DatetimePickerTrigger>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <hr/>

                                                                <div className="form-group">
                                                                    <strong>Source Of Request</strong>
                                                                    <div className="inline-radio-buttons">
                                                                        <label className="radio-inline">
                                                                            <input
                                                                                defaultChecked
                                                                                type="radio"
                                                                                value="Phone Call"
                                                                                onChange={this.handleSource}
                                                                                name="requestSoruce"
                                                                            />
                                                                            Phone Call
                                                                        </label>
                                                                        <label className="radio-inline">
                                                                            <input
                                                                                type="radio"
                                                                                value="Walk In"
                                                                                onChange={this.handleSource}
                                                                                name="requestSoruce"
                                                                            />
                                                                            Walk In
                                                                        </label>
                                                                    </div>
                                                                </div>

                                                                <div className="confirm-btn-group">
                                                                    <div className="confirm-btn">
                                                                        <button
                                                                            data-toggle="modal"
                                                                            data-target="#addRebookServices"
                                                                            data-dismiss="modal"
                                                                            onClick={() =>
                                                                                this.initializeServiceSelect()
                                                                            }
                                                                            className={
                                                                                !this.state.buttonText
                                                                                    ? "button-custom"
                                                                                    : "actve"
                                                                            }
                                                                        >
                                                                            Add Service
                                                                        </button>
                                                                        {this.app != null &&
                                                                            this.app.locations[0].brands.length >
                                                                            0 && (
                                                                                <button
                                                                                    data-toggle="modal"
                                                                                    data-target="#addRebookProduct"
                                                                                    data-dismiss="modal"
                                                                                    onClick={() =>
                                                                                        this.setState({
                                                                                            products: !this.state.products,
                                                                                        })
                                                                                    }
                                                                                    className={
                                                                                        this.state.products
                                                                                            ? "actve"
                                                                                            : "button-custom"
                                                                                    }
                                                                                >
                                                                                    Add Product
                                                                                </button>
                                                                            )}
                                                                    </div>
                                                                    <div className="btn-rounded-with-image">
                                                                        <div className="customer-notes">
                                                                            <button
                                                                                title="Customer Note"
                                                                                onClick={() =>
                                                                                    this.setState({
                                                                                        customerNote:
                                                                                            !this.state.customerNote,
                                                                                    })
                                                                                }
                                                                            >
                                                                                <img src={notesImg} alt=""/>
                                                                            </button>
                                                                        </div>
                                                                        <div className="customer-task">
                                                                            <button
                                                                                title="Staff Note"
                                                                                onClick={() =>
                                                                                    this.setState({
                                                                                        staffNote: !this.state.staffNote,
                                                                                    })
                                                                                }
                                                                            >
                                                                                <img src={tasksImg} alt=""/>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {this.state.customerNote && (
                                                                    <div className="customer-notes note-wrapper">
                                                                        <div className="row">
                                                                            <div className="note-body">
                                                                                <div className="note-fields">
                                                                                    <div className="form-group">
                                                                                        <p className="form-control">
                                                                                            <strong>Teresa N.</strong>
                                                                                            <button
                                                                                                className="close-note customer-note-toggle"
                                                                                                onClick={() =>
                                                                                                    this.setState({
                                                                                                        customerNote:
                                                                                                            !this.state.customerNote,
                                                                                                    })
                                                                                                }
                                                                                            >
                                                                                                <i className="ti ti-close"></i>
                                                                                            </button>
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className="form-group">
                                            <textarea
                                                disabled
                                                className="form-control"
                                            ></textarea>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}

                                                                {this.state.staffNote && (
                                                                    <div className="staff-note note-wrapper">
                                                                        <div className="row">
                                                                            <div className="note-body">
                                                                                <div className="note-fields">
                                                                                    <div className="form-group">
                                                                                        <p className="form-control">
                                                                                            <strong>Seyi Badmus</strong>
                                                                                            <button
                                                                                                onClick={() =>
                                                                                                    this.setState({
                                                                                                        staffNote:
                                                                                                            !this.state.staffNote,
                                                                                                    })
                                                                                                }
                                                                                                className="close-note staff-note-toggle"
                                                                                            >
                                                                                                <i className="ti ti-close"></i>
                                                                                            </button>
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className="form-group">
                                            <textarea
                                                name="order_note"
                                                className="form-control"
                                            ></textarea>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="stumped-body">
                                                                                    <div className="stumped-action">
                                                                                        <button
                                                                                            className="btn btn-default btn-custom-default">
                                                                                            Update
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </React.Fragment>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="past-appointments">
                                                {selectedServices.length > 0 && (
                                                    <div className="steamline">
                                                        {selectedServices.map((s, k) => {
                                                            return (
                                                                <div key={k} className="sl-item">
                                                                    <div className="sl-left">
                                                                        <img
                                                                            src={this.findTechnician(
                                                                                s.technician,
                                                                                "image"
                                                                            )}
                                                                            alt="user"
                                                                            className="img-circle"
                                                                        />
                                                                    </div>
                                                                    <div className="sl-right">
                                                                        <div className="m-l-40">
                                                                            <p>
                                                                                <b>{s.servicename}</b> with{" "}
                                                                                <b>{s.technicianname}</b>
                                                                                <br/>
                                                                                <b>Time: </b>
                                                                                {helperFunctions.formatDateTime(
                                                                                    moment(s.timeStart),
                                                                                    "time"
                                                                                )}{" "}
                                                                                -{" "}
                                                                                {helperFunctions.formatDateTime(
                                                                                    moment(s.durat),
                                                                                    "time"
                                                                                )}
                                                                                <br/>
                                                                                <b>Price:</b> $
                                                                                {(s.price * 1).toFixed(2)}
                                                                                <br/>
                                                                            </p>
                                                                            <p className="pb35">
                                        <span className="pull-right">
                                          <button
                                              data-target="#addRebookServices"
                                              data-toggle="modal"
                                              data-dismiss="modal"
                                              onClick={() =>
                                                  this.editingService(s.id)
                                              }
                                              className="btn m-t-10 m-r-5 btn-custom-info"
                                          >
                                            Edit
                                          </button>
                                          <button
                                              onClick={() =>
                                                  this.removeService(s.key)
                                              }
                                              className="btn m-t-10 m-r-5 btn-danger"
                                          >
                                            Cancel
                                          </button>
                                        </span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                )}
                                                {selectedProducts.length > 0 && (
                                                    <div className="table-responsive table-product">
                                                        <table className="table table-striped">
                                                            <thead>
                                                            <tr>
                                                                <th>Tech</th>
                                                                <th>Name</th>
                                                                <th>Qty</th>
                                                                <th>Price</th>
                                                                <th className="text-center">Action</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {selectedProducts.map((s, k) => {
                                                                return (
                                                                    <tr key={k}>
                                                                        <td>
                                                                            <img
                                                                                width="30"
                                                                                className="img-circle"
                                                                                src={s.techImage}
                                                                            />
                                                                        </td>
                                                                        <td>{s.technicianname}</td>
                                                                        <td>{s.qty}</td>
                                                                        <td>{s.price}</td>
                                                                        <td align="center">
                                                                            <button
                                                                                onClick={() =>
                                                                                    this.removeProduct(s.key)
                                                                                }
                                                                                className="cross-btn"
                                                                            >
                                                                                <i className="ti ti-close text-danger"></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.selectedServices.length > 0 && (
                                        <div className="modal-footer">
                                            <ul>
                                                <li>
                                                    <button
                                                        onClick={this.saveRebookAppointment}
                                                        className="btn btn-custom-info"
                                                    >
                                                        Rebook
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    )}
                                    {this.props.loading == true && (
                                        <div className="custom-loading">
                                            <div className="loading">
                                                <i className="fa fa-spinner fa-spin"></i>
                                            </div>
                                        </div>
                                    )}
                                </React.Fragment>
                            </div>
                        </div>
                    </div>

                    <AddNewShopping
                        productCheckout={this.productCheckout}
                        clearPlace={this.clearPlace}
                        customer={this.state.userDetails}
                        setPlace={this.setPlace}
                        place={this.state.place}
                    />

                    <div
                        data-backdrop="static"
                        data-keyboard="false"
                        className="modal fade"
                        id="addRebookServices"
                    >
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <button
                                        className="close"
                                        onClick={() =>
                                            this.closeThisModal("#addRebookServices", "#createEvent")
                                        }
                                    >
                                        &times;
                                    </button>

                                    <div className="col-md-12">
                                        <div className="form-group select-arrow">
                                            <label htmlFor="stylish">
                                                Service &nbsp; <span className="text-danger">*</span>
                                            </label>
                                            <ServiceSearchBar
                                                place={this.state.place}
                                                id="serviceId"
                                                setPlace={this.setPlace}
                                                clearPlace={this.clearPlace}
                                                onServiceSelect={this.onServiceSelect}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        {this.state.techServices.length !== 0 && (
                                            <div className="form-group add-services">
                                                <label>Select Stylist</label>
                                                <select
                                                    id="technicians-select-customer"
                                                    className="hidden"
                                                    name="technician"
                                                    value={this.state.technician}
                                                    onChange={this.changeTech}
                                                >
                                                    {
                                                        (this.state.editMode = "update" && (
                                                            <option value="up">Select Stylist</option>
                                                        ))
                                                    }
                                                    <option value="any">Any Stylist</option>
                                                    {this.state.techServices.map((e, key) => {
                                                        return (
                                                            <option key={e.id} value={e.id}>
                                                                {e.name}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                                <div
                                                    id="technicians-select-div-customer"
                                                    className="add-services"
                                                ></div>
                                            </div>
                                        )}
                                        {this.state.techServices.length == 0 && (
                                            <div className="form-group add-services">
                                                <label>Select Staff</label>

                                                <input
                                                    placeholder="Select Staff"
                                                    type="text"
                                                    className="form-control"
                                                    readOnly
                                                />
                                            </div>
                                        )}
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="endTime">
                                                Start Time &nbsp; <span className="text-danger">*</span>
                                            </label>
                                            <div className="time-clock">
                                                <TimePicker
                                                    showSecond={false}
                                                    name="timeStart"
                                                    className="xxx"
                                                    onChange={this.changeStartTime}
                                                    format={format}
                                                    placeholder={moment(this.state.timeStart).format(
                                                        format
                                                    )}
                                                    use12Hours
                                                    inputReadOnly
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="endTime">
                                                End Time &nbsp; <span className="text-danger">*</span>
                                            </label>

                                            <div className="time-clock">
                                                <TimePicker
                                                    showSecond={false}
                                                    name="endTime"
                                                    className="xxx"
                                                    onChange={this.changeEndTime}
                                                    format={format}
                                                    placeholder={moment(this.state.durat).format(format)}
                                                    use12Hours
                                                    inputReadOnly
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="modal-footer">
                                    <div className="form-group text-right">
                                        <button
                                            onClick={
                                                this.state.mode != ""
                                                    ? () =>
                                                        this.updateServices(
                                                            "#addRebookServices",
                                                            "#createEvent"
                                                        )
                                                    : () =>
                                                        this.addSelectedService(
                                                            "#addRebookServices",
                                                            "#createEvent"
                                                        )
                                            }
                                            className="btn btn-custom-info"
                                        >
                                            {this.state.mode != "" ? "Update Service" : "Add Service"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {this.app != null && this.app.locations[0].brands.length > 0 && (
                        <div
                            data-backdrop="static"
                            data-keyboard="false"
                            className="modal fade"
                            id="addRebookProduct"
                        >
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <button
                                            data-toggle="modal"
                                            data-target="#createEvent"
                                            data-dismiss="modal"
                                            className="close"
                                            onClick={() => this.closePmodal()}
                                        >
                                            &times;
                                        </button>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group select-arrow">
                                                    <label htmlFor="stylish">
                                                        Product &nbsp;{" "}
                                                        <span className="text-danger">*</span>
                                                    </label>
                                                    <ProductSearchBar
                                                        id="productId"
                                                        place={this.state.place}
                                                        clearPlace={this.clearPlace}
                                                        setPlace={this.setPlace}
                                                        onProductSelect={this.onProductSelect}
                                                    />
                                                    <p className="text-danger error">
                                                        {errors.product && (
                                                            <InlineMessage text={errors.product}/>
                                                        )}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group add-services">
                                                    <label>Select Staff</label>
                                                    <select
                                                        name="technician"
                                                        value={this.state.technician}
                                                        onChange={this.techangeForProduct}
                                                    >
                                                        <option>Select Stylist</option>
                                                        {this.app.locations[0].technicians
                                                            .filter((t) => t.active == true)
                                                            .map((t, key) => {
                                                                return (
                                                                    <option key={key} value={t.user.id}>
                                                                        {t.user.names.nick}
                                                                    </option>
                                                                );
                                                            })}
                                                    </select>
                                                    <p className="text-danger error">
                                                        {errors.technicianId && (
                                                            <InlineMessage text={errors.technicianId}/>
                                                        )}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="endTime">
                                                        Quantity &nbsp;{" "}
                                                        <span className="text-danger">*</span>
                                                    </label>

                                                    <div className="quantity-counter">
                                                        <div className="count-grabber">
                                                            <input
                                                                readOnly
                                                                type="text"
                                                                className="form-control"
                                                                value={this.state.qty}
                                                            />
                                                            <div className="btns">
                                                                <div
                                                                    onClick={this.incrementQty}
                                                                    className="incrmntbtn"
                                                                >
                                                                    <i className="fa fa-caret-up"></i>
                                                                </div>
                                                                <div
                                                                    onClick={this.decrementQty}
                                                                    className="drmntbtn"
                                                                >
                                                                    <i className="fa fa-caret-down"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <p className="text-danger error">
                                                        {errors.qty && <InlineMessage text={errors.qty}/>}
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="endTime">
                                                        {" "}
                                                        Price &nbsp; <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        value={this.state.productPrice}
                                                        className="form-control"
                                                        type="text"
                                                        name="productPrice"
                                                        onChange={this.changePrice}
                                                    />
                                                    <p className="text-danger error">
                                                        {errors.price && (
                                                            <InlineMessage text={errors.price}/>
                                                        )}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group text-right">
                                            <button
                                                onClick={() =>
                                                    this.addSelectedProduct(
                                                        "#addRebookProduct",
                                                        "#createEvent"
                                                    )
                                                }
                                                className="btn btn-custom-info"
                                            >
                                                Add Product
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="modal" id="addNew">
                        <div className="modal-dialog formula-modal">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <strong>Add New Formula</strong>
                                    <button className="close" data-dismiss="modal">
                                        &times;
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="formula-form">
                                        <form onSubmit={this.submitHandlers}>
                                            <div className="form-group">
                                                <label>Last Used</label>

                                                <DatetimePickerTrigger
                                                    moment={this.state.lastUseTime}
                                                    onChange={this.changeLastUseTime}
                                                >
                                                    <input
                                                        type="text"
                                                        value={this.state.lastUseTime.format(
                                                            "YYYY-MM-DD HH:mm"
                                                        )}
                                                        readOnly
                                                    />
                                                </DatetimePickerTrigger>
                                            </div>
                                            <div className="form-group">
                                                <label>Formula Type</label>
                                                <select
                                                    value={this.state.formulas.type}
                                                    onChange={this.handleFormula}
                                                    name="type"
                                                    className="form-group"
                                                    required
                                                >
                                                    <option>Select Type</option>
                                                    {this.app &&
                                                        this.app.lookupGroup.userFormulaTypes.map(
                                                            (a, key) => (
                                                                <option key={key} value={a}>
                                                                    {a}
                                                                </option>
                                                            )
                                                        )}
                                                </select>
                                                <p className="text-danger error">
                                                    {errors.type && <InlineMessage text={errors.type}/>}
                                                </p>
                                            </div>
                                            <div className="form-group">
                                                <label>Staff</label>
                                                <select
                                                    value={this.state.formulas.staffId}
                                                    onChange={this.handleFormula}
                                                    name="staffId"
                                                    className="form-group"
                                                    required
                                                >
                                                    <option>Select Staff</option>
                                                    {this.app.locations[0].technicians
                                                        .filter((t) => t.active == true)
                                                        .map((t, key) => {
                                                            return (
                                                                <option key={key} value={t.id}>
                                                                    {t.user.names.nick}
                                                                </option>
                                                            );
                                                        })}
                                                </select>
                                                <p className="text-danger error">
                                                    {errors.staffId && (
                                                        <InlineMessage text={errors.staffId}/>
                                                    )}
                                                </p>
                                            </div>

                                            <div className="form-group">
                                                <label>Notes</label>
                                                <textarea
                                                    onChange={this.handleFormula}
                                                    value={this.state.formulas.notes}
                                                    maxLength="1000"
                                                    className="form-control"
                                                    name="notes"
                                                ></textarea>
                                                <p className="text-danger error">
                                                    {errors.notes && (
                                                        <InlineMessage text={errors.notes}/>
                                                    )}
                                                </p>
                                            </div>

                                            <div className="form-group text-right">
                                                <button type="submit" className="btn btn-custom-info">
                                                    Add New
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        data-backdrop="static"
                        data-keyboard="false"
                        className="modal"
                        id="checkOutModal"
                    >
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button
                                        onClick={this.resetStateWithModalClose}
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">×</span>
                                    </button>
                                    <h4 className="modal-title" id="cancelLabelModel">
                                        Check Out
                                    </h4>
                                </div>

                                <div className="modal-body">
                                    <div className="row">
                                        <div>
                                            <CheckOut
                                                checkoutDetails={this.state.editApptDetails}
                                                proccessCheckOut={this.proccessCheckOut}
                                                apptCheckout={this.apptCheckout}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {this.state.loading == true && (
                                    <div className="custom-loading">
                                        <div className="loading">
                                            <i className="fa fa-spinner fa-spin"></i>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div
                        data-backdrop="static"
                        data-keyboard="false"
                        className="modal fade"
                        id="checkin"
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLabel1"
                    >
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button
                                        onClick={this.resetStateWithModalClose}
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    <h4 className="modal-title" id="exampleModalLabel1">
                                        Check In
                                    </h4>
                                </div>

                                <div className="modal-body">
                                    <CheckIn
                                        singleAppt={this.state.editApptDetails}
                                        appointmentCheckIn={this.appointmentCheckIn}
                                    />
                                </div>
                                {this.state.loading == true && (
                                    <div className="custom-loading">
                                        <div className="loading">
                                            <i className="fa fa-spinner fa-spin"></i>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="modal" id="editFormula">
                        <div className="modal-dialog formula-modal">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <strong>Add New Formula</strong>
                                    <button className="close" data-dismiss="modal">
                                        &times;
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="formula-form">
                                        <form onSubmit={this.updateHandler}>
                                            <div>
                                                <div className="form-group">
                                                    <label>Last Used</label>

                                                    <DatetimePickerTrigger
                                                        moment={this.state.lastUseTime}
                                                        onChange={this.changeLastUseTime}
                                                    >
                                                        <input
                                                            type="text"
                                                            value={this.state.lastUseTime.format(
                                                                "YYYY-MM-DD HH:mm"
                                                            )}
                                                            readOnly
                                                        />
                                                    </DatetimePickerTrigger>
                                                </div>
                                                <div className="form-group">
                                                    <label>Formula Type</label>
                                                    <select
                                                        value={this.state.formulas.type}
                                                        onChange={this.handleFormula}
                                                        name="type"
                                                        className="form-group"
                                                        required
                                                    >
                                                        {this.app &&
                                                            this.app.lookupGroup.userFormulaTypes.map(
                                                                (a, key) => (
                                                                    <option key={key} value={a}>
                                                                        {a}
                                                                    </option>
                                                                )
                                                            )}
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label>Staff</label>
                                                    <select
                                                        value={this.state.formulas.staffId}
                                                        onChange={this.handleFormula}
                                                        name="staffId"
                                                        className="form-group"
                                                        required
                                                    >
                                                        {this.app.locations[0].technicians
                                                            .filter((t) => t.active == true)
                                                            .map((t, key) => {
                                                                return (
                                                                    <option key={key} value={t.id}>
                                                                        {t.user.names.nick}
                                                                    </option>
                                                                );
                                                            })}
                                                    </select>
                                                </div>

                                                <div className="form-group">
                                                    <label>Notes</label>
                                                    <textarea
                                                        onChange={this.handleFormula}
                                                        value={this.state.formulas.notes}
                                                        maxLength="1000"
                                                        className="form-control"
                                                        name="notes"
                                                    ></textarea>
                                                </div>

                                                <div className="form-group">
                                                    <button type="submit" className="btn btn-custom-info">
                                                        Update Formula
                                                    </button>
                                                </div>
                                            </div>
                                        </form>

                                        {this.state.updateFormSubmitted &&
                                            updateStatus &&
                                            window.customerUpdateSuccess()}
                                    </div>
                                </div>
                                {this.props.loading == true && (
                                    <div className="custom-loading">
                                        <div className="loading">
                                            <i className="fa fa-spinner fa-spin"></i>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div
                        data-backdrop="static"
                        data-keyboard="false"
                        className="modal fade"
                        id="editWaitList"
                    >
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <React.Fragment>
                                    <div className="modal-header">
                                        <button
                                            onClick={this.refreshEditWaitModal}
                                            type="button"
                                            className="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            <span aria-hidden="true">×</span>
                                        </button>
                                        <h4 className="modal-title" id="cancelLabelModel">
                                            Update waitlist
                                        </h4>
                                    </div>
                                    <div className="modal-body">
                                        <div className="client-info addApt">
                                            <div className="info-header">
                                                <div className="client">
                                                    {customerDetails != "" && (
                                                        <React.Fragment>
                                                            <div className="chat-image">
                                                                <img src={customerDetails.imageUrl} alt=""/>
                                                            </div>
                                                            <div className="chat-body">
                                                                <div className="chat-text">
                                                                    <div>
                                                                        <h3>
                                                                            <strong>
                                                                                {customerDetails.fullName}
                                                                            </strong>
                                                                        </h3>
                                                                        <p>
                                                                            <i className="fa fa-phone"></i>{" "}
                                                                            {helperFunctions.formatMobilePhone(
                                                                                customerDetails.mobileNumber
                                                                            )}
                                                                        </p>
                                                                        <p>
                                                                            <i className="fa fa-dollar"></i>{" "}
                                                                            {(
                                                                                customerDetails.rewardBalance * 1
                                                                            ).toFixed(2)}
                                                                        </p>

                                                                        <div className="appt-time">
                                                                            <p>
                                                                                <i className="fa fa-calendar-check-o"></i>
                                                                                {customerDetails.lastVisit && (
                                                                                    <a
                                                                                        data-toggle="modal"
                                                                                        data-target="#lastModal"
                                                                                        data-dismiss="modal"
                                                                                        onClick={() => this.openMiniModal()}
                                                                                    >
                                                                                        {" "}
                                                                                        {
                                                                                            customerDetails.lastVisit
                                                                                                .readableBookedTime
                                                                                        }{" "}
                                                                                    </a>
                                                                                )}
                                                                                {!customerDetails.lastVisit && "NONE"}
                                                                            </p>
                                                                        </div>
                                                                        <div className="appt-time">
                                                                            <p>
                                                                                <i className="fa fa-random"></i>
                                                                                {customerDetails.nextVisit && (
                                                                                    <a
                                                                                        data-toggle="modal"
                                                                                        data-target="#lastModal"
                                                                                        data-dismiss="modal"
                                                                                        onClick={() =>
                                                                                            this.openMiniModalForOpen("open")
                                                                                        }
                                                                                    >
                                                                                        {" "}
                                                                                        {
                                                                                            customerDetails.nextVisit
                                                                                                .readableBookedTime
                                                                                        }{" "}
                                                                                    </a>
                                                                                )}
                                                                                {!customerDetails.nextVisit && "NONE"}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <hr/>

                                                                <div className="confirm-btn-group">
                                                                    <div className="confirm-btn">
                                                                        <button
                                                                            data-toggle="modal"
                                                                            data-target="#addServicesForWait"
                                                                            data-dismiss="modal"
                                                                            onClick={() =>
                                                                                this.initializeWaitServiceSelect()
                                                                            }
                                                                            className={
                                                                                !this.state.buttonText
                                                                                    ? "button-custom"
                                                                                    : "actve"
                                                                            }
                                                                        >
                                                                            Add Services
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="past-appointments waitlist">
                                                {selectedServicesss.length > 0 && (
                                                    <React.Fragment>
                                                        <div className="steamline">
                                                            {selectedServicesss.map((s, k) => {
                                                                return (
                                                                    <div key={k} className="sl-item">
                                                                        <div className="sl-left">
                                                                            <img
                                                                                src={
                                                                                    s.technicians.length > 0
                                                                                        ? this.findTechnician(
                                                                                            s.technicians[0].id,
                                                                                            "image"
                                                                                        )
                                                                                        : "https://s3.amazonaws.com/zoracorp/bianca/default/user-no-image.png"
                                                                                }
                                                                                alt="user"
                                                                                className="img-circle"
                                                                            />{" "}
                                                                            <span className="more-tech">
                                        {s.technicians.length > 1 &&
                                            ` + ${s.technicians.length}`}
                                      </span>
                                                                        </div>
                                                                        <div className="sl-right">
                                                                            <div className="m-l-40">
                                                                                <p>
                                                                                    <b>{s.servicename}</b> with{" "}
                                                                                    <b>
                                                                                        {s.technicians.length > 0
                                                                                            ? this.findTechnician(
                                                                                                s.technicians[0].id,
                                                                                                "nick"
                                                                                            )
                                                                                            : "Any Stylist"}
                                                                                        <sub
                                                                                            className="text-capitalize">
                                                                                            {s.technicians.length > 1 &&
                                                                                                `and  ${s.technicians.length} others`}
                                                                                        </sub>
                                                                                    </b>
                                                                                    <br/>
                                                                                    <b>Time: </b>
                                                                                    <b>
                                                                                        {helperFunctions.formatDateTimeWithDay(
                                                                                            moment(s.from).format(
                                                                                                "YYYY-MM-DDTHH:mm:ss"
                                                                                            ),
                                                                                            "latest"
                                                                                        )}{" "}
                                                                                        -{" "}
                                                                                        {helperFunctions.formatDateTimeWithDay(
                                                                                            moment(s.to).format(
                                                                                                "YYYY-MM-DDTHH:mm:ss"
                                                                                            ),
                                                                                            "latest"
                                                                                        )}
                                                                                    </b>
                                                                                    <br/>
                                                                                </p>
                                                                                <p className="pb35">
                                          <span className="pull-right">
                                            <button
                                                data-toggle="modal"
                                                data-target="#addServicesForWait"
                                                data-dismiss="modal"
                                                onClick={() =>
                                                    this.editWait(s.key, s)
                                                }
                                                className="btn m-t-10 m-r-5 btn-custom-info"
                                            >
                                              Edit
                                            </button>
                                            <button
                                                onClick={() =>
                                                    this.removeService(s.key)
                                                }
                                                className="btn m-t-10 m-r-5 btn-danger"
                                            >
                                              Cancel
                                            </button>
                                          </span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Staff Notes</label>
                                                            <textarea
                                                                name="staffNotes"
                                                                value={this.state.staffNotes}
                                                                onChange={this.handleChange}
                                                                className="form-control staff-note"
                                                            ></textarea>
                                                        </div>
                                                    </React.Fragment>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.selectedServicesss.length > 0 && (
                                        <div className="modal-footer">
                                            <ul>
                                                <li>
                                                    <button
                                                        onClick={this.updateWaitList}
                                                        className="btn btn-custom-info"
                                                    >
                                                        Update
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    )}
                                    {this.props.loading == true && (
                                        <div className="custom-loading">
                                            <div className="loading">
                                                <i className="fa fa-spinner fa-spin"></i>
                                            </div>
                                        </div>
                                    )}
                                </React.Fragment>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    data-backdrop="static"
                    data-keyboard="false"
                    className="modal fade"
                    id="lastModal"
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <button
                                    data-toggle="modal"
                                    data-dismiss="modal"
                                    data-target="#editWaitList"
                                    className="close"
                                >
                                    &times;
                                </button>
                                <h4>
                                    Appointment on{" "}
                                    {this.state.lastAppts.length > 0 &&
                                        helperFunctions.formatDateTimeWithDay(
                                            moment(this.state.lastAppts[0].bookedTime),
                                            "date"
                                        )}{" "}
                                    {this.state.lastAppts.length > 0 &&
                                        helperFunctions.formatDateTimeWithDay(
                                            this.state.lastAppts[0].bookedTime,
                                            "time"
                                        )}
                                </h4>

                                <ul>
                                    {this.state.lastAppts.length > 0 &&
                                        this.state.lastAppts[0].visitTechnicians.map((s, key) => {
                                            return (
                                                <li key={key}>
                                                    <strong>
                                                        {this.findServiceName(
                                                            s.offeredService.id,
                                                            s.offeredService.category.id
                                                        )}
                                                    </strong>{" "}
                                                    with{" "}
                                                    <strong>
                                                        {this.findTechnician(s.technician.id, "name")}
                                                    </strong>
                                                </li>
                                            );
                                        })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    data-backdrop="static"
                    data-keyboard="false"
                    className="modal"
                    id="viewDetails"
                >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    onClick={this.resetStateWithModalClose}
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                                <h2 className="modal-title" id="cancelLabelModel">
                                    View Details
                                </h2>
                            </div>
                            <div className="modal-body">
                                <Details details={this.state.details}/>
                            </div>

                            {this.state.loading == true && (
                                <div className="custom-loading">
                                    <div className="loading">
                                        <i className="fa fa-spinner fa-spin"></i>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div
                    data-backdrop="static"
                    data-keyboard="false"
                    className="modal fade"
                    id="addServicesForWait"
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <button
                                    data-toggle="modal"
                                    data-target="#editWaitList"
                                    data-dismiss="modal"
                                    className="close"
                                    onClick={() => this.closeThisModal()}
                                >
                                    &times;
                                </button>

                                <div className="col-md-12">
                                    {this.state.message != "" && (
                                        <h4 className="text-warning text-italic">
                                            {this.state.message}
                                        </h4>
                                    )}
                                    <ul>
                                        {this.state.techniciansss.length > 0 &&
                                            this.state.techniciansss.map((t, k) => {
                                                return (
                                                    <label className="label label-info" key={k}>
                                                        {this.findTechnician(t.id, "nick")}
                                                        <button
                                                            className="btn btn-label-remover"
                                                            onClick={() => this.removeTech(t.id)}
                                                        >
                                                            X
                                                        </button>
                                                    </label>
                                                );
                                            })}
                                    </ul>
                                </div>

                                <div className="col-md-12 switcher-spacer"></div>

                                <div className="col-md-12">
                                    <div className="form-group select-arrow">
                                        <label htmlFor="stylish">
                                            Service &nbsp; <span className="text-danger">*</span>
                                        </label>
                                        <ServiceSearchBar
                                            place={this.state.place}
                                            setPlace={this.setPlace}
                                            clearPlace={this.clearPlace}
                                            onServiceSelect={this.onWaitServiceSelect}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    {this.state.techServicesss.length !== 0 && (
                                        <div className="form-group add-services">
                                            <label>Select Stylist</label>
                                            <select
                                                multiple={true}
                                                className="hidden"
                                                id="technicians-select-wait"
                                                value={this.state.techniciansss}
                                                onChange={this.changeWaitTech}
                                            >
                                                {this.state.editMode == "update" && (
                                                    <option value="up">Select Stylist</option>
                                                )}
                                                <option value="any">Any Stylist</option>
                                                {this.state.techServicesss.map((e, key) => {
                                                    return (
                                                        <option key={e.id} value={e.id}>
                                                            {e.name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                            <div
                                                id="technicians-select-div-wait"
                                                className="add-services"
                                            ></div>
                                        </div>
                                    )}
                                    {this.state.techServicesss.length == 0 && (
                                        <div className="form-group add-services">
                                            <label>Select Staff</label>

                                            <input type="text" className="form-control" readOnly/>
                                        </div>
                                    )}
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Start Time</label>
                                        <DatetimePickerTrigger
                                            moment={this.state.startTime}
                                            onChange={this.changeStartTime}
                                        >
                                            <input
                                                type="text"
                                                value={
                                                    this.state.startTime.format("YYYY/MM/DD") +
                                                    " " +
                                                    helperFunctions.formatDateTimeWithDay(
                                                        moment(
                                                            this.state.startTime.format("YYYY-MM-DDTHH:mm:ss")
                                                        ),
                                                        "time"
                                                    )
                                                }
                                                readOnly
                                            />
                                        </DatetimePickerTrigger>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>End Time</label>
                                        <DatetimePickerTrigger
                                            moment={this.state.endTime}
                                            onChange={this.changeEndTime}
                                        >
                                            <input
                                                type="text"
                                                value={
                                                    this.state.endTime.format("YYYY/MM/DD") +
                                                    " " +
                                                    helperFunctions.formatDateTimeWithDay(
                                                        moment(
                                                            this.state.endTime.format("YYYY-MM-DDTHH:mm:ss")
                                                        ),
                                                        "time"
                                                    )
                                                }
                                                readOnly
                                            />
                                        </DatetimePickerTrigger>
                                    </div>
                                    <p className="text-danger error">
                                        {errors.difference && (
                                            <InlineMessage text={errors.difference}/>
                                        )}
                                    </p>
                                </div>
                            </div>

                            <div className="modal-footer">
                                <div className="form-group text-right">
                                    {this.state.editMode == "update" && (
                                        <button
                                            onClick={this.upSelectedService}
                                            className="btn btn-custom-info"
                                        >
                                            Update
                                        </button>
                                    )}

                                    {this.state.editMode == "save" && (
                                        <button
                                            onClick={this.addWaitSelectedService}
                                            className="btn btn-custom-info"
                                        >
                                            Add Service
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="" id="viewForm" className="modal fade">
                    <CompletedForm imgData={this.state.signedContent}/>
                </div>
            </React.Fragment>
        );
    }

    updateCustomerSuccess = () => {
        this.getUser(this.state.userId);
        window.customerUpdateSuccess();
    };

    getTechnicianNames = (technicians) => {
        var techNamesList = [];
        technicians.forEach((visitTech) => {
            var techName = this.findTechnicianName(visitTech.technician.id);
            if (techNamesList.indexOf(techName) == -1) {
                techNamesList.push(techName);
            }
        });
        return techNamesList.join(", ");
    };

    findTechnicianName = (technicianId) => {
        // eslint-disable-next-line
        let technician = this.app.locations[0].technicians.find(
            (technician) => technician.id == technicianId
        );
        return technician.user.names.nick;
    };

    findConsentformName = (consentFormId) => {
        // eslint-disable-next-line
        let consentformName = this.app.consentForms.find(
            (form) => form.id == consentFormId
        );
        if (consentformName) {
            return consentformName.name;
        }
    };

    findConsentformNameUrl = (consentFormId) => {
        // eslint-disable-next-line
        let consentformUrl = this.app.consentForms.find(
            (form) => form.id == consentFormId
        );
        return consentformUrl.contentUrl;
    };

    showLastVisitPopup = () => {
        var user = this.state.userDetails;
        var data = {
            fullName: user.fullName,
            mobileNumber: helperFunctions.formatMobilePhone(user.mobileNumber),
            apptTime: helperFunctions.formatDateTime(user.lastVisit.bookedTime),
            requestSource: user.lastVisit.requestSource,
            technicians: this.getTechnicianNames(user.lastVisit.visitTechnicians),
            visitId: user.lastVisit.id,
        };
        window.reservationDetailAlert(data, this.storeId);
    };

    deleteFormula = (id) => {
        Swal.fire({
            title: "Delete Formula",
            text: `Are you sure you want to delete it?`,
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, keep it",
        }).then((result) => {
            if (result.value) {
                const {dispatch} = this.props;
                userService.deleteFormula(id);
                window.toast("error", `Data has been deleted from the system`);
                setTimeout(() => {
                    dispatch(userActions.getCustomerFormulas(this.state.userId));
                }, 500);
            }
        });
    };

    getNextPage = () => {
        var offset = this.state.pageOffset + 1;
        if (this.state.rewardLists && this.state.rewardLists.length >= 9) {
            userService
                .getAllRewards(
                    this.state.userId,
                    this.state.earnType,
                    offset,
                    this.state.searchQuery
                )
                .then((res) => {
                    this.setState({
                        ...this.state.rewardLists,
                        rewardLists: res,
                        pageOffset: offset,
                    });
                });
        }
    };

    getPreviousPage = () => {
        var offset = this.state.pageOffset - 1;
        if (offset >= 0) {
            userService
                .getAllRewards(
                    this.state.userId,
                    this.state.earnType,
                    offset,
                    this.state.searchQuery
                )
                .then((res) => {
                    this.setState({
                        ...this.state.rewardLists,
                        rewardLists: res,
                        pageOffset: offset,
                    });
                });
        }
    };

    getRequestSource(reqSrc) {
        let src = "";
        src =
            reqSrc == "Phone Call"
                ? "fa fa-phone text-warning custom mb0"
                : reqSrc == "Walk In"
                    ? "mdi mdi-run custom mb0"
                    : reqSrc == "Android App"
                        ? "fa fa-android text-purple custom"
                        : reqSrc == "iOS App"
                            ? "fa fa-mobile text-purple custom"
                            : reqSrc == "Website"
                                ? "fa fa-desktop custom"
                                : reqSrc == "iPad App"
                                    ? "fas fa-tablet custom"
                                    : reqSrc == "Tablet App"
                                        ? "mdi mdi-tablet-android custom"
                                        : "";

        return src;
    }

    findServiceName(serviceId, categoryId) {
        const {categories} = this.state;
        // eslint-disable-next-line
        let category = categories.find((sc) => sc.id == categoryId);
        if (category == null) return "Service not found";
        let service = category.services.find((s) => s.id == serviceId);
        if (service == null) return "Service not found";
        return service.name;
    }

    findTechnician(technicianId, query) {
        // eslint-disable-next-line
        let technician = this.app.locations[0].technicians.find(
            (technician) => technician.id == technicianId
        );

        if (technician) {
            if (query == "image") {
                return technician.user.imageUrl;
            } else if (query == "active") {
                if (technician.active == "true") {
                    return "active";
                }
            } else if (query == "nick") {
                return technician.user.names.nick;
            } else {
                return technician.user.fullName;
            }
        } else {
            return "";
        }
    }

    checkPriceType = (min, max) => {
        if (min == "-1" && max == "-1") {
            return "Per Consult";
        } else if (min > 0 && max > 0 && min < max) {
            return min * 1;
        } else if (min > 0 && max == -1) {
            return min * 1;
        } else {
            return min * 1;
        }
    };

    //reservation
    handleStartTime = (value) => {
        if (value != null) {
            this.setState({
                timeStart: value.format("HH:mm a"),
            });
        }
    };

    handleEndTime = (value) => {
        if (value != null) {
            this.setState({
                timeEnd: value.format("HH:mm a"),
            });
        }
    };

    setDate = (date) => {
        this.setState({
            date: date,
            time: "",
            dateStart: "",
            dateEnd: "",
        });
    };

    setTime = (time) => {
        this.setState({
            time: time,
            timeStart: "",
            timeEnd: "",
        });
    };

    findTechnicianName(technicianId) {
        // eslint-disable-next-line
        let technician = this.app.locations[0].technicians.find(
            (technician) => technician.id == technicianId
        );
        return technician.user.names.nick;
    }

    findTechnicianImage(technicianId) {
        // eslint-disable-next-line
        let technician = this.app.locations[0].technicians.find(
            (technician) => technician.id == technicianId
        );
        return technician.user.imageUrl;
    }

    findServicePrice(serviceId, techId, catId) {
        const {categories} = this.state;
        let servicePrice = "";
        if (this.state.technician == "any") {
            let s = categories
                .find((c) => c.id == catId)
                .services.find((service) => service.id == serviceId);

            servicePrice =
                s.minimumFee == -1 && s.maximumFee == -1
                    ? "Per Consult"
                    : s.minimumFee > -1 && s.maximumFee == -1
                        ? "From $" + s.minimumFee
                        : s.minimumFee > -1 &&
                        s.maximumFee > -1 &&
                        s.minimumFee == s.maximumFee
                            ? "$" + s.minimumFee
                            : s.minimumFee > -1 &&
                            s.maximumFee > -1 &&
                            s.minimumFee != s.maximumFee
                                ? "$" + s.minimumFee + " - $" + s.maximumFee
                                : "";
        } else {
            let s = this.app.locations[0].technicians
                .find((tech) => tech.id == techId)
                .offeredServices.find((service) => service.id == serviceId);

            servicePrice =
                s.minimumFee == -1 && s.maximumFee == -1
                    ? "Per Consult"
                    : s.minimumFee > -1 && s.maximumFee == -1
                        ? "From $" + s.minimumFee
                        : s.minimumFee > -1 &&
                        s.maximumFee > -1 &&
                        s.minimumFee == s.maximumFee
                            ? "$" + s.minimumFee
                            : s.minimumFee > -1 &&
                            s.maximumFee > -1 &&
                            s.minimumFee != s.maximumFee
                                ? "$" + s.minimumFee + " - $" + s.maximumFee
                                : "";
        }

        return servicePrice;
    }

    findServiceDuration(serviceId, techId, catId) {
        const {categories} = this.state;
        let serviceDuration = "";
        if (this.state.technician == "any") {
            serviceDuration = categories
                .find((cat) => cat.id == catId)
                .services.find((service) => service.id == serviceId).durationInMinutes;
        } else {
            serviceDuration = this.app.locations[0].technicians
                .find((tech) => tech.id == techId)
                .offeredServices.find(
                    (service) => service.id == serviceId
                ).durationInMinutes;
        }

        return serviceDuration;
    }

    checkAllApointmentsSelected() {
        let timeSelected = true;
        let selectedServices = this.state.selectedServicess;
        selectedServices.forEach((service) => {
            if (!service.dateTime) timeSelected = false;
        });
        if (!timeSelected) this.setState({timeSelected: false});
        else {
            this.setState({timeSelected: true});
        }

        return timeSelected;
    }

    placeReservation() {
        const selectedServices = this.state.selectedServicess;
        selectedServices.forEach((service) => {
            const apptJSON = {
                location: {
                    id: this.app.locations[0].id,
                },
                client: {
                    id: qs.parse(this.props.location.search.replace("?", "")).custId,
                },
                requestSource: "Walk In",
                requestType: "Appointment",
                standingAppointment: true,
                customerNotes: this.state.notes,
                visitTechnicians: [
                    {
                        technician: {
                            id: service.technician,
                        },
                        offeredService: {
                            id: service.offeredService,
                        },
                        expectedStartTime: service.expectedStartTime,
                        period: {
                            to: service.expectedEndtTime,
                        },
                    },
                ],
                purchaseItems: [],
            };

            reservationService.saveAppointment(apptJSON).then((res) => {
                this.setState({
                    loading: false,
                    notes: "",
                    reservationPlaced: true,
                });
                reservationService
                    .getOpenAppointments(
                        qs.parse(this.props.location.search.replace("?", "")).custId
                    )
                    .then((a) => {
                        this.setState({
                            openAppts: a,
                        });
                    });
            });
        });
        this.getCustomerInfo(
            qs.parse(this.props.location.search.replace("?", "")).custId
        );

        this.deleteAllReservations();

        $("#reservationNote").modal("hide");
        $("#standingAppt").modal("hide");
    }

    setAppointmentTime = (appointments, all) => {
        if (all != undefined) {
            Swal.fire({
                title: "Warning",
                text: `Are you sure to book all?`,
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
            }).then((result) => {
                if (result.value) {
                    let selectedServices = [];
                    if (appointments.length > 0) {
                        appointments.map((a) =>
                            a.visitTechnicians.map((appointment) => {
                                //  console.log(appointment)
                                selectedServices.push({
                                    technician: appointment.technician.id,
                                    offeredService: appointment.offeredService.id,
                                    expectedStartTime: appointment.expectedStartTime,
                                    expectedEndtTime: appointment.period.to,
                                    serviceName: this.findServiceName(
                                        appointment.offeredService.id,
                                        appointment.offeredService.category.id
                                    ),
                                    technicianName: this.findTechnician(
                                        appointment.technician.id,
                                        "nick"
                                    ),
                                });
                            })
                        );
                    }
                    this.setState({selectedServicess: selectedServices});
                    $("#reservationNote").modal("show");
                    $("#standingAppt").modal("hide");
                    this.checkAllApointmentsSelected();
                }
            });
        } else {
            let selectedServices = this.state.selectedServicess;
            let thisObj = this;

            selectedServices.forEach(function (service) {
                // eslint-disable-next-line
                let appointment = appointments.find(
                    (appointment) => appointment.offeredService.id == service.serviceId
                );
                if (appointment) {
                    service.technician = appointment.technician.id;
                    service.offeredService = appointment.offeredService.id;
                    service.expectedStartTime = appointment.expectedStartTime;
                    service.expectedEndtTime = appointment.period.to;
                } else {
                    service.technicianId = "";
                    service.technicianName = "No one";
                    service.date = "";
                    service.time = "";
                    service.dateTime = null;
                }
            });

            this.setState({selectedServicess: selectedServices});
            $("#reservationNote").modal("show");
            $("#standingAppt").modal("hide");
            this.checkAllApointmentsSelected();
        }
    };

    checkAvailability() {
        const {dispatch} = this.props;

        let serviceTechnicians = [];
        this.state.selectedServicess.forEach((service) => {
            // eslint-disable-next-line
            if (service.technicianId == "any") {
                serviceTechnicians.push({
                    offeredService: {
                        id: service.serviceId,
                    },
                });
            } else {
                serviceTechnicians.push({
                    technician: {
                        id: service.technicianId,
                    },
                    offeredService: {
                        id: service.serviceId,
                    },
                });
            }
        });
        var timeStart = this.state.timeStart;
        var timeEnd = this.state.timeEnd;
        var daysOfWeek = this.state.daysOfWeek;
        var daysBetween = this.state.daysBetween;
        if (timeStart.split(":").length == 2) {
            let trimedTime = timeStart.replace(/[^a-zA-Z ]/g, "");
            timeStart = timeStart.split(trimedTime)[0] + ":00";
        }

        if (timeEnd.split(":").length == 2) {
            let trimedTime = timeEnd.replace(/[^a-zA-Z ]/g, "");
            timeEnd = timeEnd.split(trimedTime)[0] + ":00";
        }

        if (this.state.time != "TIME_RANGE") {
            timeStart = timeEnd = "";
        }
        if (this.state.daysOfWeek == "any") {
            daysOfWeek = daysBetween == "";
        } else {
            daysOfWeek = this.state.daysOfWeek;
            daysBetween = this.state.daysBetween;
        }

        if (this.state.dateStart != "" && this.state.dateEnd != "") {
            dispatch(
                reservationActions.checkAvailability(
                    this.state.date,
                    this.state.dateStart,
                    this.state.dateEnd,
                    this.state.time,
                    timeStart,
                    timeEnd,
                    serviceTechnicians,
                    daysBetween,
                    daysOfWeek
                )
            );
            this.setState({
                showSlots: true,
                errors: {},
            });
        } else {
            this.setState({
                errors: {
                    datePreference: "Please select start and end date",
                },
            });
        }
    }

    addReservation() {
        // eslint-disable-next-line
        if (this.state.technician != "" && this.state.service != "") {
            let selectedServices = this.state.selectedServicess;

            // eslint-disable-next-line
            let exists =
                selectedServices.findIndex(
                    (reservation) => reservation.serviceId == this.state.service
                ) != -1
                    ? true
                    : false;
            if (!exists) {
                this.setState({reservationExists: false});
                let service = {
                    reservationId: this.state.service + this.state.technician,
                    serviceId: this.state.service,
                    // eslint-disable-next-line
                    serviceName: this.state.services.find(
                        (service) => service.id == this.state.service
                    ).name,
                    technicianId: this.state.technician,
                    // eslint-disable-next-line
                    technicianName:
                        this.state.technician === "any"
                            ? "Any Staff"
                            : this.state.technicianss.find(
                                (technician) => technician.id == this.state.technician
                            ).name,
                };

                selectedServices.push(service);
                this.setState({selectedServicess: selectedServices});
            } else {
                this.setState({reservationExists: true});
            }
        }
    }

    deleteReservation(item) {
        let selectedServices = this.state.selectedServicess;
        // eslint-disable-next-line
        let index = selectedServices.findIndex(
            (service) => service.reservationId == item.e.reservationId
        );
        selectedServices.splice(index, 1);
        this.setState({selectedServicess: selectedServices});
        // eslint-disable-next-line
        if (selectedServices.length == 0) {
            this.initializeReservations();
        }
    }

    deleteAllReservations() {
        this.setState({selectedServices: []});
        this.initializeReservations();
    }

    formatDateTime(dateTime, returnType) {
        if (!sessionStorage.getItem("timezone")) {
            var tz = jstz.determine() || "UTC";
            sessionStorage.setItem("timezone", tz.name());
        }

        var currTz = sessionStorage.getItem("timezone");
        var momentTime = moment(dateTime);
        var tzTime = momentTime.tz(currTz);

        if (returnType == "date") return tzTime.format("ddd, MMMM Do, YYYY");
        else if (returnType == "time") return tzTime.format("hh:mm a");
        else return tzTime.format("hh:mm a, MMMM Do, YYYY");
    }

    initializeReservations() {
        this.setState({
            technician: "",
            technicians: [],
            service: "",
            reservationExists: false,
            showSlots: false,
            notes: "",
            selectedServicess: [],
        });
    }

    changeContent = (text) => {
        alert(text);
    };
}

function mapStateToProps(state) {
    const {reloadData} = state.Communication;
    const {
        updating,
        profileUpdated,
        profileUpdateError,
        passwordUpdated,
        rewardUpdated,
        rerender,
    } = state.updateProfile;
    const {technicians} = state.technicians;
    const {message} = state.alert;
    const {user} = state.authentication;
    const {formulass, saved, updateStatus} = state.customers;
    const {
        findingAvailability,
        foundAvailability,
        slots,
        placingReservation,
        reservationPlaced,
        reservationError,
    } = state.reservation;

    return {
        message,
        updating,
        profileUpdated,
        profileUpdateError,
        passwordUpdated,
        rewardUpdated,
        formulass,
        saved,
        updateStatus,
        rerender,
        technicians,
        findingAvailability,
        foundAvailability,
        slots,
        placingReservation,
        reservationPlaced,
        reservationError,
        reloadData,
        user,
    };
}


export default connect(mapStateToProps)(CustomerDetailPage)
