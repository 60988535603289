import React from 'react';
import InlineMessage from "../../_components/inlineMessage";
import userImage from "./sm-user.png";
import {helperFunctions} from "../../_helpers";
import Message from "../../_components/icons/message";
import Call from "../../_components/icons/call";
import {userService} from "../../_services";


class HeaderCommunication extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            com: [],
            typ: ["SMS", "EMAIL", "VOICE"],
            direction: ["INBOUND", "OUTBOUND"],
            stat: ["IN_PROGRESS", "COMPLETED", "FAILED", "NO_ANSWER"],
            com_page: 0,
            errors: {},
            loading: false,
            to: '',
            filteredName: '',
            from: ''
        }
    }


    setLoader = (loader) => this.setState({loading: loader})

    componentDidMount() {
        const {app} = this.props;
        if (app !== null && localStorage.getItem('user') !== null) {
            this.getCom();
        }
    }

//get all the communication
    getCom = (clear) => {
        const {app} = this.props;

        const errors = this.comValidation(this.state.typ, this.state.direction, this.state.stat);
        this.setState({errors})
        if (Object.keys(errors).length == 0) {
            if (clear = 'clear') {
                this.setState({
                    loading: true,
                    open_com: true,
                    filteredName: '',
                    to: ''
                })
            } else {
                this.setState({
                    loading: true,
                    open_com: true
                })
            }

            setTimeout(() => {
                let bsnId = this.state.filteredName == '' ? app.id : ''
                userService.loadCommunication(this.state.typ, this.state.direction, this.state.stat, this.state.to, this.state.from, this.state.com_page, bsnId).then(response => {
                    if (clear != undefined) {
                        this.setState({
                            com: response,
                            loading: false,
                            to: '',
                            filteredName: ''
                        })
                    } else {
                        this.setState({
                            com: response,
                            loading: false
                        })
                    }
                    window.removeVisibleClass()
                }).catch(error => this.setLoader(false))
            }, 400)
        }
    }

    comValidation = (typ, direction, stat) => {
        const con_errors = {}
        if (typ.length == 0) con_errors.typ = 'Please check at least one!'
        if (direction.length == 0) con_errors.direction = 'Please check at least one!'
        if (stat.length == 0) con_errors.stat = 'Please check at least one!'
        return con_errors
    }

    changeTypes = event => {
        if (event.target.checked) {
            this.setState({
                ...this.state.typ,
                typ: this.state.typ.concat(event.target.value)
            })
        } else {
            this.setState({
                ...this.state.typ,
                typ: this.state.typ.filter(type => type != event.target.value)
            })
        }
    }

    changeDirections = event => {
        if (event.target.checked) {
            this.setState({
                ...this.state.direction,
                direction: this.state.direction.concat(event.target.value)
            })
        } else {
            this.setState({
                ...this.state.direction,
                direction: this.state.direction.filter(direction => direction != event.target.value)
            })
        }
    }

    changeStatus = event => {
        if (event.target.value == 'all') {
            this.setState({
                stat: ["IN_PROGRESS", "COMPLETED", "FAILED", "NO_ANSWER"]
            })
        } else {
            this.setState({
                stat: ["NO_ANSWER"]
            })
        }
    }

    getNextComPage = () => {
        const com_offset = this.state.com_page + 1;
        if (this.state.com.length == 10) {
            this.setState({
                com_page: com_offset
            }, () => {
                if (this.state.to) {
                    userService.loadCommunication(this.state.typ, this.state.direction, this.state.stat, this.state.to, this.state.from, this.state.com_page, '').then(response => {
                        this.setState({
                            com: response,
                            loading: false
                        })
                    }).catch(err => {
                        this.setState({
                            loading: false
                        })
                    })
                } else {
                    this.getCom();
                }

            })
        }
    }
    getPrevComPage = () => {

        if (this.state.com_page > 0) {
            this.setState({
                com_page: this.state.com_page - 1
            }, () => {
                if (this.state.to) {
                    userService.loadCommunication(this.state.typ, this.state.direction, this.state.stat, this.state.to, this.state.from, this.state.com_page, '').then(response => {
                        this.setState({
                            com: response,
                            loading: false
                        })
                    }).catch(err => {
                        this.setState({
                            loading: false
                        })
                    })
                } else {
                    this.getCom();
                }
            })
        }
    }


    render() {
        const {com, loading, errors} = this.state;
        const {callerHandler, open_com, toggle, elementId} = this.props;
        return (
            <div className="perspective">
                <div className="communication" id={elementId}>
                    {loading == true &&
                        <div className="custom-loading">
                            <div className="loading"><i className="fa fa-spinner fa-spin"></i></div>
                        </div>
                    }

                    <div className="scroller">
                        <div className="scroll-inner">
                            <div className="section-title">
                                <div className="filter">
                                    <div className="filter-wrapper">
                                        <div className="filter-button">
                                            <div className="icon-filter"><i
                                                className="fa fa-sliders"></i></div>
                                            <div className="label-filter">Filter</div>
                                        </div>


                                    </div>
                                    <div className="filter-control">
                                        <div className="types">
                                            <h5>Types: {errors.typ &&
                                                <InlineMessage text={errors.typ}/>}</h5>

                                            <div className="control-item">
                                                <div className="form-check">
                                                    <input defaultChecked={true} name="types"
                                                           onClick={this.changeTypes} id="t_sms"
                                                           className="form-check-input" type="checkbox"
                                                           value="SMS"/>
                                                    <label htmlFor="t_sms">SMS</label>
                                                </div>
                                                <div className="form-check">
                                                    <input defaultChecked={true} name="types"
                                                           onClick={this.changeTypes} id="t_email"
                                                           className="form-check-input" type="checkbox"
                                                           value="EMAIL"/>
                                                    <label htmlFor="t_email">Email</label>
                                                </div>
                                                <div className="form-check">
                                                    <input defaultChecked={true} name="types"
                                                           onClick={this.changeTypes} id="t_voice"
                                                           className="form-check-input" type="checkbox"
                                                           value="VOICE"/>
                                                    <label htmlFor="t_voice">Voice</label>
                                                </div>

                                            </div>

                                        </div>
                                        <div className="types">
                                            <h5>Directions: {errors.direction &&
                                                <InlineMessage
                                                    text={errors.direction}/>}</h5>

                                            <div className="control-item">
                                                <div className="form-check">
                                                    <input defaultChecked={true} name="direction"
                                                           onClick={this.changeDirections}
                                                           id="t_inbound" className="form-check-input"
                                                           type="checkbox" value="INBOUND"/>
                                                    <label htmlFor="t_inbound">Inbound</label>
                                                </div>
                                                <div className="form-check">
                                                    <input defaultChecked={true} name="direction"
                                                           onClick={this.changeDirections}
                                                           id="t_outbound" className="form-check-input"
                                                           type="checkbox" value="OUTBOUND"/>
                                                    <label htmlFor="t_outbound">Outbound</label>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="types">
                                            <h5>Status: {errors.stat &&
                                                <InlineMessage text={errors.stat}/>}</h5>

                                            <div className="control-item">
                                                <div className="form-check">

                                                    <input defaultChecked={true} onClick={this.changeStatus}
                                                           type="radio" name="status" value="all"/> All &nbsp;
                                                    <input onClick={this.changeStatus} type="radio" name="status"
                                                           value="missed"/> Missed

                                                </div>

                                            </div>

                                        </div>

                                        <div className="go" onClick={this.getCom}>
                                            <label className="label label-warning">Filter</label>
                                        </div>
                                    </div>
                                </div>
                                <h5>Recent Communications</h5>

                                <button onClick={toggle}><i
                                    className="fa fa-close"></i></button>
                            </div>
                            {this.state.filteredName != '' &&
                                <div className="filteration">
                                    <h2>{this.state.filteredName}</h2>
                                    <h5>{this.state.to}</h5>
                                    <button onClick={() => this.getCom('clear')}>clear</button>
                                </div>
                            }
                            {this.state.com.length == 0 &&
                                <div className="no-records"><h3>Records Not Found!!</h3></div>
                            }
                            {this.state.com.length > 0 && this.state.com.filter(com => com.notificationType != 'PUSH').map((com, key) => {
                                return <div key={key} className="single-com">
                                    {this.state.filteredName == '' &&
                                        <div className="filter"
                                             onClick={() => this.filterByMobile(com.to, com.user.fullName ? com.user.fullName : 'No Name')}>
                                            <i className="fa fa-filter"></i>
                                        </div>
                                    }
                                    <div className="user-image">
                                        <img className="img-circle" style={{width: '30px', height: '30px'}}
                                             src={com.user && com.user.imageUrl ? com.user.imageUrl : userImage}/>
                                    </div>
                                    <div className="name-phone-message">
                                        <p>{com.user && com.user.fullName ? com.user.fullName : 'No Name'} - {com.readableCreateTime}</p>
                                        <p><span className="custom-info">{com.to}</span> - <span
                                            style={{color: 'goldenrod'}}>{com.notificationType}</span></p>
                                        <p>{helperFunctions.formatDateTimeWithDay(com.createdTime, 'latest')}</p>
                                    </div>
                                    <div className="com-action">
                                        {com.user && com.notificationType == 'EMAIL' &&
                                            <button data-toggle="modal" data-target="#mail"
                                                    onClick={() => this.openEmail(com.body)}>
                                                <i className="fa fa-envelope-o"></i>
                                            </button>
                                        }
                                        {com.user &&
                                            <button
                                                onClick={() => callerHandler(com.user && com.user.id, com.to, 'sms')}>
                                                <Message
                                                    type={this.state[`activeMobileNumber_${com.to}`] == com.to ? 'message' : ''}/>
                                            </button>
                                        }
                                        {com.user &&
                                            <button
                                                onClick={() => callerHandler(com.user && com.user.id, com.to, 'call')}>
                                                <Call
                                                    proccess={this.state[`activeMobileNumber_${com.to}`] == com.to ? true : false}/>
                                            </button>
                                        }
                                    </div>
                                </div>
                            })}
                        </div>
                        <div className="paged">
                            <div className="prev" onClick={() => {
                                this.getPrevComPage()
                            }}><i className="fa fa-angle-left fa-2x"></i></div>
                            <div className="prev" onClick={() => {
                                this.getNextComPage()
                            }}><i className="fa fa-angle-right fa-2x"></i></div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default HeaderCommunication;
