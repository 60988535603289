import React, { useState } from "react";
import Result from "./result";
import SearchResult from "./search.result";
import "./index.css";
const CustomDropDown = ({ items, changeSearchValue, onSelect, selectedItem, openAddNew, addType, manageType }) => {
  const [visible, isVisible] = useState(false)

  return (
    <div className="custom-dropdown">
      <Result label={selectedItem.name} />
      <SearchResult
        items={items}
        changeSearchValue={changeSearchValue}
        onSelect={onSelect}
        selectedId={selectedItem.id}
        openAddNew={openAddNew}
        addType={addType}
        manageType={manageType} />
    </div>
  );
};

export default CustomDropDown;
