
import {communicationConstants} from "../_constants/communication.constants";

export function outgoing(state = {}, action) {

    switch (action.type) {
        case communicationConstants.PROCESS_CALLING:
            return{
                selectedUser: action.user
            }

        default:
            return state
    }

}
