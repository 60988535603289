/* eslint-disable */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Config } from '../config'
import { appService } from '../_services'
var config = Config()

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => {
        var storeId = window.location.href.split('/')[3]
        if(config.STORE_ID.indexOf(storeId) == -1){
            return <Redirect to={{ pathname: `/`, state: { from: props.location } }} />
        }
        else{
            var app = JSON.parse(localStorage.getItem('app'))
            if(localStorage.getItem(`${storeId}_user`) && localStorage.getItem(`${storeId}_sessionId`) && localStorage.getItem('user')){
                if(app.vanityId != storeId){
                    appService.getAppDetails(storeId).then(app => {
                        localStorage.setItem("app",JSON.stringify(app))
                        window.location.reload()
                    })
                }
                return <Component {...props} />

            }
            return <Redirect to={{ pathname: `/${storeId}/login`, state: { from: props.location } }} />
        }
    }} />
)
