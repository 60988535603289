import React, { Component } from 'react';
import { userService } from '../_services';
import Autosuggest from 'react-autosuggest';

const getCustomerSuggestionValue = suggestion => `${suggestion.user.fullName}`
const renderCustomerSuggestion = suggestion => (
    <div>
      {`${suggestion.user.fullName} (${suggestion.user.mobileNumber})`}
    </div>
);

class StaffSearchBar extends Component{

    constructor(props){
        super(props)
        this.state = {
            value: '',
            customerSuggestions: []
        }

    }

    staffNameChange = (e, { newValue }) => {
        this.props.clearPlace && this.props.clearPlace();
        this.props.getNewValue && this.props.getNewValue(newValue)
        this.setState({
            value: newValue
        });
    };

    onSuggestionsFetchRequested = ({ value }) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        if(inputLength === 0)
            return []

        userService.getAllTechnicians(0,'', inputValue).then(c => {
            this.setState({
                customerSuggestions: c
            })

        })

    };

    onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
        this.props.onSelectTechnician(suggestion)
    }

    onSuggestionsClearRequested = () => {
        this.setState({
            customerSuggestions: []
        });
    };
    focusValue=(e)=>{
        e.target.setSelectionRange(0, e.target.value.length)
    }

    render(){
        const { value, customerSuggestions } = this.state;
        const inputProps = {
            // eslint-disable-next-line
            placeholder: this.props.place && this.props.place != '' ? this.props.place : 'Type a name',
            // eslint-disable-next-line
            value: !this.props.mode && this.props.place && this.props.place != '' ? this.props.place: value,
            type:'search',
            onChange: this.staffNameChange,
            onClick: this.focusValue,
            className:"form-control",
            id:this.props.id
        };

        return (
            <Autosuggest
                suggestions={customerSuggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={getCustomerSuggestionValue}
                renderSuggestion={renderCustomerSuggestion}
                onSuggestionSelected={this.onSuggestionSelected}
                inputProps={inputProps}
            />
        )
    }

}

export default StaffSearchBar
