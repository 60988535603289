import React from 'react';
import {helperFunctions} from "../../_helpers";


class ActivitiesComponent extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {activities, cls, remove, prev, next, waitlsts, actvts} = this.props;
        return (
            <div className={"activities " + cls}>
                <button onClick={remove} className="close-activities"><i
                    className="fa fa-angle-double-right text-danger"></i></button>
                <div className="fileters">
                    <div className="title">
                        <h3><strong>Activities</strong></h3>
                    </div>
                    <div className="see-all">
                        <ul className="pager">
                            <li><a onClick={prev}><i className="fa fa-angle-left"></i></a>
                            </li>
                            <li><a onClick={next}><i className="fa fa-angle-right"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="activities-body">
                    <div className="activities-scroll">
                        {actvts == true && waitlsts == false && activities &&
                            <div className="steamline">

                                {actvts == true && activities && activities.length > 0 && activities.map((a, key) => {
                                    return <div key={key} className="sl-item">
                                        <div className="sl-left"><img
                                            width="36"
                                            src={a.createUser.imageUrl}
                                            alt="user" className="img-circle"/></div>
                                        <div className="sl-right">
                                            <div className="m-l-40">
                                                <p>
                                                    <strong>{a.createUser.fullName}</strong> &nbsp;
                                                    <span>{a.readableCreateTime}</span></p>
                                                <p className="text-italic">{a.message}
                                                    <br/> {helperFunctions.formatDateTimeWithDay(a.createdTime, 'latest')}
                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                })}
                            </div>
                        }
                    </div>
                </div>

            </div>
        )
    }
}

export default ActivitiesComponent;

