import { userConstants } from '../_constants';
 
export function updateProfile(state = {}, action) {
  switch (action.type) {
    case userConstants.UPDATE_PROFILE_REQUEST:
      return { updating: true };
    case userConstants.UPDATE_PROFILE_SUCCESS:
      return {
        profileUpdated: true,
        updatedUser:action.user
      };
    case userConstants.UPDATE_PROFILE_FAILURE:
      return {
        profileUpdateError:action.error
      };
    case userConstants.UPDATE_PASSWORD_SUCCESS:
      return {
          passwordUpdated: true
      }
    case userConstants.UPDATE_PASSWORD_FAILURE:
      return {
          passwordUpdated: false
      }

      case userConstants.REQUEST_FOR_USER_REWARDS:
         return{
            rewardLists: []
         }

     case userConstants.GET_ALL_USER_REWARDS:
           return{
              rewardLists: action.rewards
           }

    case userConstants.REWARDS_FAILURE_IN_FETCHING:
        return{
          rewardLists: []
        }       

    case userConstants.UPDATE_REWARD_SUCCESS:
      return {
          rewardUpdated: true,
          rewardLists: state.rewardLists,
          rerender: true
      }
    case userConstants.UPDATE_REWARD_FAILURE:
      return {
          rewardUpdated: false
      }

      case userConstants.GET_ALL_UPDATED_REWARDS:
        return{
          rewardLists:[...state.rewardLists,action.rewardLists]
        }

    case userConstants.RESET_PASSWORD_SUCCESS:
      return{
        passwordReset:true
      }
    case userConstants.RESET_PASSWORD_FAILURE:
      return{
        passwordResetError:true
      }
    default:
      return state
  }
}