/* eslint-disable */
import React, {Component} from 'react';
import InlineMessage from './inlineMessage';
import 'rc-datetime-picker/dist/picker.min.css';
import moment from 'moment';
import {DatetimePickerTrigger} from 'rc-datetime-picker';
import {helperFunctions} from '../_helpers'
import UserSearchBar from './userSearchBar'


class AddTask extends Component {
    constructor(props) {
        super(props);
        const {dispatch} = this.props;
        this.app = JSON.parse(localStorage.getItem('app'));


        this.state = {
            type: 'all',
            pageOffset: 0,
            errors: {},
            task:{
                locationid: this.app.locations[0].id,
                technicianid: '',
                subject: '',
                priority: '',
                relatedItemType: '',
                comments:'',
                status: '',
                selectedItem: '',
                selectedId: '',
                searchItem: ''
            },
            dueTime: moment(),
            selectedOption:'',
            relatedToItem: '',
            val: 'exist'

        };

    }




    taskChangeDuetime = (dueTime)=>{
        this.setState({
            dueTime
        })
    }

    taskChangeHandler=(e)=>{
        this.setState({
            task:{
                ...this.state.task,
                [e.target.name]: e.target.value

            }

        });
    }


    saveTask = ()=>{
        const {task} = this.state;
        const {dispatch} = this.props;
        const taskJSON ={
            location:{
                id: task.locationid
            },
            technician:{
                id: task.technicianid
            },
            subject: task.subject,
            priority: task.priority,
            relatedItemType: this.props.predefinedRelatedTo ? this.props.predefinedRelatedTo : task.relatedItemType,
            comments: task.comments,
            status: task.status,
            dueTime: moment(this.state.dueTime).format("YYYY-MM-DDTHH:mm:ss"),
            relatedItem:{
                id: this.props.predefinedRelatedId ? this.props.predefinedRelatedId : task.selectedId
            }


        }



        const errors = this.validateTask(taskJSON);
        this.setState({ errors});

        if(Object.keys(errors).length == 0){

            this.props.saveTask(taskJSON);

            this.setState({
                errors: {},
                task:{
                    locationid: this.app.locations[0].id,
                    technicianid: '',
                    subject: '',
                    priority: '',
                    relatedItemType: '',
                    comments:'',
                    status: '',
                    selectedItem: '',
                    selectedId: '',
                    dueTime: moment().format("YYYY-MM-DDTHH:mm:ss"),
                    relatedItem:{
                        id: ''
                    }


                },
                dueTime: moment(),
                selectedOption:''
            })


        }
    }
    getDuration = (timeB,timeS) => {
        const sum = moment(timeB).diff(moment());
        const minutes = parseInt(moment.duration(sum).asMinutes());

        return minutes
    }

    validateTask =(data)=>{

        const errors = {}
            if(!data.technician.id) errors.tech = 'Please select a technician';
            if(!data.subject) errors.subject = 'Please enter a subject';
            if(data.dueTime) {
                let duration = this.getDuration(this.state.dueTime,moment().format('YYYY-MM-DD'))
                if(duration < 0) errors.dueTime = 'Please enter valid date';
            }

            if(/^\s/.test(data.subject)) errors.subject = 'Please enter a subject';
            if(!data.priority) errors.priority = 'Please select a priority';
            if(!data.relatedItemType) errors.relatedItemType = 'Please select related type';
            if(!data.status) errors.status = 'Please select status';

        return errors;
    }



    clearSearchVal=()=>{
        this.setState({
            ...this.state,
            selectedOption: '',
            optin: true,
            task: {
                ...this.state.task,
                searchItem: ''
            }
        })

    }

    onUserSelect=(user)=>{

        console.log(user)

        const {task} = this.state
        if(task.relatedItemType == 'Customer'){
            this.setState({
                ...this.state,
                task:{
                    ...this.state.task,
                    selectedItem: user.fullName,
                    selectedId: user.id
                }
            })
        }else if(task.relatedItemType == 'Staff'){
            this.setState({
                ...this.state,
                task:{
                    ...this.state.task,
                    selectedItem: user.user.fullName,
                    selectedId: user.user.id
                }
            })
        }else if(task.relatedItemType == 'Service'){
            this.setState({
                ...this.state,
                task:{
                    ...this.state.task,
                    selectedItem: user.service.name,
                    selectedId: user.service.id
                }
            })
        }
    }


    taskRelatedToChangeHandler=(e)=>{
        this.setState({
            task:{
                ...this.state.task,
                relatedItemType: e.target.value,

            },
            val: ''

        });
    }




    render() {
        const {errors,selectedOption,task} = this.state;
        const {customers,searchVals,searchType} = this.props;



        return (
            <React.Fragment>


                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Title &nbsp; <span className="text-danger">*</span></label>
                                <input
                                    maxLength="40"
                                    required
                                    className="form-control"
                                    type="text"
                                    name="subject"
                                    value={this.state.task.subject}
                                    onChange={this.taskChangeHandler}/>
                                    <p className="text-danger error">{errors && <InlineMessage text={errors.subject}/>}</p>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Due Date:</label>
                                <DatetimePickerTrigger
                                    moment={this.state.dueTime}
                                    onChange={this.taskChangeDuetime}>
                                    <input
                                    type="text"
                                    value={this.state.dueTime.format('YYYY-MM-DD') +' '+ helperFunctions.formatDateTimeWithDay(moment(this.state.dueTime),'time')  }
                                    readOnly/>
                                </DatetimePickerTrigger>
                                <p className="text-danger error">{errors.dueTime && <InlineMessage text={errors.dueTime}/>}</p>
                            </div>

                        </div>


                     </div>
                        <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Related To &nbsp; <span className="text-danger">*</span></label>
                                <div className="flex-input">

                                    {this.props.statas == 'disabled' ?
                                        <select
                                            value={this.props.predefinedRelatedTo ? this.props.predefinedRelatedTo : this.state.relatedItemType}
                                            className="form-control"
                                            name="relatedItemType"
                                            disabled
                                            required>
                                            {this.app && this.app.lookupGroup.taskItemTypes.map((a, key) => (
                                            <option key={key} value={a}>{a}</option>
                                            ))}
                                        </select>
                                        :<select
                                        value={this.state.task.relatedItemType}
                                        className="form-control"
                                        name="relatedItemType"
                                        onChange={this.taskRelatedToChangeHandler}
                                        required>
                                        <option>Select Type</option>

                                        {this.app && this.app.locations[0].brands.length > 0 && this.app.lookupGroup.taskItemTypes.filter(t=>t!='Appointment' && t != 'Other' && t != 'Payroll').map((a, key) => (
                                            <option key={key} value={a}>{a}</option>
                                        ))}
                                            {this.app && this.app.locations[0].brands.length == 0 && this.app.lookupGroup.taskItemTypes.filter(t=>t!='Appointment' && t != 'Other' && t != 'Product' && t !=  'Payroll').map((a, key) => (
                                                <option key={key} value={a}>{a}</option>
                                            ))}

                                    </select>
                                    }


                                </div>



                                </div>

                            </div>

                            <div className="col-md-6 mt-top">
                              {this.state.task.relatedItemType == 'Other' ?
                                <input className="form-control" type="text" value="N/A" readOnly/>
                                :<UserSearchBar
                                id="userId"
                                mode={this.props.mode}
                                onUserSelect={this.onUserSelect}
                                place={this.props.predefinedRelatedItem}
                                relatedToItem={this.props.predefinedRelatedItem ? this.props.predefinedRelatedItem : this.state.task.relatedItem}
                                predefinedRelatedTo={this.props.predefinedRelatedTo ? this.props.predefinedRelatedTo : this.state.task.relatedItemType}
                                 />
                                 }


                        </div>
                        </div>


                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Assigned To:</label>

                                <select
                                value={this.state.task.technicianid}
                                onChange={this.taskChangeHandler}
                                name="technicianid"
                                className="form-control">
                                    <optgroup label="">
                                        <option>Select Staff</option>
                                    </optgroup>
                                    {this.app.lookupGroup.teams.map((team, key) => {
                                        return this.teamWiseTechDisplay(team,key)
                                    })}
                                </select>
                                <p className="text-danger error">{errors && <InlineMessage text={errors.tech}/>}</p>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Priority &nbsp; <span className="text-danger">*</span></label>
                                <select
                                className="form-control"
                                value={this.state.task.priority}
                                name="priority" className="form-control"
                                onChange={this.taskChangeHandler}
                                required>
                                    <option>Select Priority</option>
                                    {this.app && this.app.lookupGroup.taskPriorities.map((a, key) => (
                                    <option key={key} value={a}>{a}</option>
                                ))}
                                </select>
                                <p className="text-danger error">{errors && <InlineMessage text={errors.priority}/>}</p>
                            </div>

                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Comments</label>
                                <textarea
                                 value={this.state.task.comments}
                                 maxLength="1000"
                                 className="form-control"
                                 name="comments"
                                 onChange={this.taskChangeHandler}

                                 >
                                 </textarea>

                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Status &nbsp; <span className="text-danger">*</span></label>
                                <select
                                name="status"
                                value={this.state.task.status}
                                className="form-control"
                                onChange={this.taskChangeHandler}
                                required>
                                    <option>Select Status</option>
                                    {this.app && this.app.lookupGroup.taskStatuses.map((a, key) => (
                                    <option key={key} value={a}>{a}</option>
                                    ))}
                                </select>
                                <p className="text-danger error">{errors && <InlineMessage text={errors.status}/>}</p>
                            </div>

                        </div>

                    </div>

                    <div className="switcher-spacer"></div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="buttons text-right">
                                <button onClick={this.saveTask} type="button" className="btn btn-custom-info">Save Task</button>
                            </div>
                        </div>
                    </div>
            </React.Fragment>
        );
    }

    teamWiseTechDisplay=(team,k)=><optgroup key={k} label={team}>{this.checkChild(team)}</optgroup>
    checkChild=team=>{
        const grouped = [];
        this.app.locations[0].technicians.filter(t=>t.active == true).map((t,k)=>{

            if(t.team == team) grouped.push(<option key={k} value={t.id}>{t.user.names.nick}</option>)
        });return grouped
    }
}
export default AddTask;
