import React, {Component} from 'react';
import {inventoryService} from '../_services';
import Autosuggest from 'react-autosuggest';

const getProductSuggestionValue = suggestion => `${suggestion.variant.product.name} ${suggestion.variant.mappedVisualAttributeValues != null ? suggestion.variant.mappedVisualAttributeValues[Object.keys(suggestion.variant.mappedVisualAttributeValues)[0]] : ''}`
const renderProductSuggestion = suggestion => (

    <div>
        {// eslint-disable-next-line
            suggestion.quantity > 0 &&
            <div className="product-list">

                <div className="product-left">
                    <h4>{suggestion.variant.product.name}</h4>
                    <p>{suggestion.variant.product.brand.name}</p>
                </div>
                <div className="product-right">
                    <h4 className="custom-info">${suggestion.salePrice}</h4>
                    <h4>Qty: <b className="custom-info">{suggestion.quantity}</b></h4>
                </div>
            </div>

        }

        {// eslint-disable-next-line
            suggestion.quantity < 1 &&
            <div className="product-list block">

                <div className="product-left">
                    <h4>{suggestion.variant.product.name}</h4>
                    <p>{suggestion.variant.product.brand.name}</p>
                </div>
                <div className="product-right">
                    <h4 className="text-danger">${suggestion.salePrice}</h4>
                    <h4 className="text-danger">Qty: <strong className="text-danger">{suggestion.quantity}</strong></h4>
                </div>
            </div>

        }

    </div>
);

//{`${suggestion.variant.product.name} ${suggestion.variant.mappedVisualAttributeValues != null ? suggestion.variant.mappedVisualAttributeValues[Object.keys(suggestion.variant.mappedVisualAttributeValues)[0]] : ''}`}

class ProductSearchBar extends Component {

    constructor(props) {
        super(props)

        this.state = {
            value: '',
            productSuggestions: []
        }
    }

    onProductNameChange = (e, {newValue}) => {
        this.props.clearPlace()
        this.props.clearScanner()
        this.setState({
            value: newValue
        });
    };

    onSuggestionsFetchRequested = ({value}) => {
        // window.customFocusOut()
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        if (inputLength === 0)
            return []

        inventoryService.searchProduct(inputValue, 5).then(products => {

            this.setState({
                productSuggestions: products
            });
        })

    };

    onSuggestionSelected = (event, {suggestion, suggestionValue, suggestionIndex, sectionIndex, method}) => {
        // window.customFocusOut();
        // eslint-disable-next-line
        if (suggestion.quantity != 0) {
            this.props.onProductSelect(suggestion)
        } else {
            this.setState({
                value: this.state.value
            }, () => {
                inventoryService.searchProduct(this.state.value, 5).then(products => {
                    this.setState({
                        productSuggestions: products
                    });
                })
            })
        }
    }

    onSuggestionsClearRequested = () => {
        this.setState({
            productSuggestions: []
        });
    };
    focusValue = (e) => {
        e.target.setSelectionRange(0, e.target.value.length)
    }

    render() {
        const {value, productSuggestions} = this.state;
        const inputProps = {
            // eslint-disable-next-line
            placeholder: this.props.scanningName && this.props.scanningName != '' ? this.props.scanningName : this.props.place && this.props.place != '' ? this.props.place : 'Type a product name',
            // eslint-disable-next-line
            value: this.props.scanningName && this.props.scanningName != '' ? this.props.scanningName : this.props.place && this.props.place != '' ? this.props.place : value,
            type: 'search',
            onChange: this.onProductNameChange,
            className: "form-control",
            id: this.props.id,
            onClick: this.focusValue
        };

        return (
            <Autosuggest
                suggestions={productSuggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={getProductSuggestionValue}
                renderSuggestion={renderProductSuggestion}
                onSuggestionSelected={this.onSuggestionSelected}
                inputProps={inputProps}
            />
        )
    }

}

export default ProductSearchBar
