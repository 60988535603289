/* eslint-disable */
import React, {Component} from 'react';
class CheckIn extends Component {
    constructor(props){
        super(props)
	    this.app = JSON.parse(localStorage.getItem('app'));
	    this.state = {
	        staffNotes: ''
	    }
    
	}

	changeHandler =(e)=>{
	    const {name, value} = e.target;
	    this.setState({
	        ...this.state,
	        [name]: value
	    })
	 }
     appointmentCheckIn=()=>{
        const note={
            staffNotes: this.state.staffNotes ? this.state.staffNotes : this.props.singleAppt.staffNotes
        }
        this.props.appointmentCheckIn(this.props.singleAppt.id,note)
     }

	 render(){
	 	const {singleAppt} = this.props
	 	return(
	 		
	 		<div>
	 		{singleAppt && singleAppt != ''  && Object.keys(singleAppt).length > 0 &&
	 			<React.Fragment>
	 			<div className="form-group">
                    <label htmlFor="notes">Customer Note &nbsp; <span className="text-danger">*</span></label>
                    <textarea
                        rows="5"
                        required
                        maxLength="1000"
                        readOnly
                        defaultValue={ singleAppt.customerNotes != '' ? singleAppt.customerNotes : 'NONE' }
                        className="form-control" id="Notes"></textarea>

                </div>
	 			<div className="form-group">
                    <label htmlFor="notes">Staff Note &nbsp; <span className="text-danger">*</span></label>
                    <textarea
                        rows="5"
                        required
                        maxLength="1000"
                        onChange={this.changeHandler}
                        name="staffNotes"
                        value={this.state.staffNotes != '' ? this.state.staffNotes : singleAppt.staffNotes}
                        className="form-control" id="Notes"></textarea>

                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-custom-info" data-dismiss="modal" onClick={() => {
                        this.appointmentCheckIn()
                    }}>Check In
                    </button>
                </div>
                </React.Fragment>
                }
	 		</div>
	 		
	 	)
	 }
}

export default CheckIn
