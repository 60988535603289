import React from "react";

const Item = ({ item, onSelect, id }) => {
  const selectItem = () => {
    onSelect(item)
    const element = document.querySelector('.search-result');
    element.classList.remove('visible')


  }
  return (
    <li onClick={selectItem} className={`search-item list-group-item ${item.id === id ? 'active' : ''}`}>
      <span>{item.name}</span>
    </li>
  );
};

export default Item;
