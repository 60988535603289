import { payrollConstants } from '../_constants';
import { payrollService } from '../_services';
 
export const payRollActions = {
    getAllPayroll
};

function getAllPayroll() {
    
    return dispatch => {
        dispatch(loading())
        payrollService.getAllPayroll()
            .then(
                payroll => {
                    dispatch(success(payroll));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function success(payroll) { return { type: payrollConstants.GET_ALL_PAYROLL_SUCCESS, payroll } }
    function failure(error) { return { type: payrollConstants.GET_ALL_PAYROLL_FAILURE, error } }
    function loading() { return { type: payrollConstants.GET_ALL_PAYROLL_REQUEST } }
}