import {FirebaseService} from "../_services/firebase.service";

export async function subscribeToTopic(TOPIC, TOKEN) {
    const subscriptionResponse = await FirebaseService.firebaseTopicSubscription(TOPIC, TOKEN)
}

export async function unsubscribeToTopic(TOPIC, TOKEN) {
    const unsubscriptionResponse = await FirebaseService.firebaseTopicUnsubscription(TOPIC, TOKEN);
}

