import React, { Component } from 'react';
import { inventoryService } from '../_services';
import Autosuggest from 'react-autosuggest';

const getProductSuggestionValue = suggestion => `${suggestion.variant.product.name} ${suggestion.variant.mappedVisualAttributeValues != null ? suggestion.variant.mappedVisualAttributeValues[Object.keys(suggestion.variant.mappedVisualAttributeValues)[0]] : ''}`
const renderProductSuggestion = suggestion => (
    
    <div>
  
      <div className="product-list">
        
        <div className="product-left">
            <h4>{suggestion.variant.product.name}</h4>
            <p>{suggestion.variant.product.brand.name}</p>
        </div>
        <div className="product-right">
            <h4 className="custom-info">${suggestion.salePrice}</h4>
            <p>Qty: <b className="custom-info">{suggestion.quantity}</b></p>
        </div>
      </div>
    
    
    </div>
);

class InventorySearch extends Component{

    constructor(props){
        super(props)
        this.state = {
            value: '',
            productSuggestions: []
        }
    }

    onProductNameChange = (e, { newValue }) => {
        this.setState({
            value: newValue
        });
    };

    onSuggestionsFetchRequested = ({ value }) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        if(inputLength === 0)
            return []

        inventoryService.searchProduct(inputValue,5).then(products => {
            
            this.setState({
                productSuggestions: products
            });
        })
        
    };

    onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
        this.props.onProductSelect(suggestion)
    }

    onSuggestionsClearRequested = () => {
        this.setState({
            productSuggestions: []
        });
    };

    render(){
        const { value, productSuggestions } = this.state;
        const inputProps = {
            placeholder: this.props.place  ? this.props.place : 'Type a product name to search',
            value,
            type:'search',
            onChange: this.onProductNameChange,
            className:"form-control",
            id:this.props.id
        };

        return (
            <Autosuggest
                suggestions={productSuggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={getProductSuggestionValue}
                renderSuggestion={renderProductSuggestion}
                onSuggestionSelected={this.onSuggestionSelected}
                inputProps={inputProps}
            />
        )
    }

}

export default InventorySearch