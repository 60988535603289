/* eslint-disable */
import {Config} from '../config';
import {authHeader} from '../_helpers';

var config = Config()
const apiPath = config.serverUrl;
export const reservationService = {
    getAppointments,
    checkInCustomer,
    checkOutCustomer,
    cancelVisit,
    cancelAppointment,
    checkInAppointment,
    cancelCheckInAppointment,
    checkOutAppointment,
    findOpenSlots,
    placeReservation,
    placeCalendarReservation,
    addAppointment,
    getAppointment,
    getVisitHistory,
    updateAppointment,
    saveAppointment,
    getOpenAppointments,
    getPastAppointments,
    cancelStandings,
    placeDayOff,
    resetTimeOff,
    getAllWaitList,
    saveWaitList,
    deleteWaitList,
    updateWaitList,
    getPastAppointmentsWithInventory
};


function cancelStandings(cancelJSON) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(cancelJSON)
    };
    return fetch(apiPath + 'visits', requestOptions).then(handleDeleteResponse);

}

function getOpenAppointments(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',

    };
    var locId = JSON.parse(localStorage.getItem('app')).locations[0].id;
    return fetch(apiPath + 'visits?locId=' + locId + '&userId=' + id + '&type=user-open', requestOptions).then(handleResponse);

}

function getPastAppointments(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',
    };
    var locId = JSON.parse(localStorage.getItem('app')).locations[0].id;

    return fetch(apiPath + 'visits?locId=' + locId + '&userId=' + id + '&type=user-past', requestOptions).then(handleResponse);

}

function getPastAppointmentsWithInventory(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',
    };
    var locId = JSON.parse(localStorage.getItem('app')).locations[0].id;

    return fetch(apiPath + 'visits?locId=' + locId + '&userId=' + id + '&type=user-past-prdcts', requestOptions).then(handleResponse);

}

function getAllWaitList(page, type, userId, start, end, techId) {

    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',
    };
    var queryParams = '';
    var locId = JSON.parse(localStorage.getItem('app')).locations[0].id;
    if (techId != undefined) {
        queryParams = 'locId=' + locId + '&techId=' + techId + '&type=' + type + '&startDate=' + start + '&endDate=' + end + '&page=' + page + '&pageCount=' + 5
    } else {

        queryParams += 'locId=' + locId + '&page=' + page + '&type=' + type + '&pageCount=' + 9
        if (userId != undefined && userId != '')
            queryParams += '&userId=' + userId
        if (start != undefined)
            queryParams += '&startDate=' + start
        if (end != undefined)
            queryParams += '&endDate=' + end
    }


    return fetch(apiPath + 'waitlists?' + queryParams, requestOptions).then(handleResponse);

}

function saveWaitList(waitJSON) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(waitJSON)
    };


    return fetch(apiPath + 'waitlists', requestOptions).then(handleResponse);

}

function deleteWaitList(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include'
    }
    return fetch(apiPath + 'waitlists/' + id, requestOptions).then(handleDeleteResponse);
}

function updateWaitList(id, waitJSON) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(waitJSON)
    }
    return fetch(apiPath + 'waitlists/' + id, requestOptions).then(handleResponse);
}


function findOpenSlots(userPreferences) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(userPreferences)
    };

    return fetch(apiPath + 'visits/suggest', requestOptions).then(handleResponse);
}

function placeReservation(reservationJson) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(reservationJson)
    };

    return fetch(apiPath + 'visits', requestOptions).then(handleResponse);
}


function placeCalendarReservation(reservationJson) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(reservationJson)
    };

    return fetch(apiPath + 'visits/calendar', requestOptions).then(handleResponse);
}


function placeDayOff(reservationJson) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(reservationJson)
    };

    return fetch(apiPath + 'visits', requestOptions).then(handleResponse);
}

function resetTimeOff(techId, date) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include'
    };

    return fetch(apiPath + 'visits/reset?techId=' + techId + '&bookedDate=' + date, requestOptions).then(response => {
        return response.text()
    });
}


function saveAppointment(apptJSON, visitOverrideConfirmed) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(apptJSON)
    };

    let url = apiPath + 'visits/'
    if (visitOverrideConfirmed != undefined) {
        url += '?visitOverrideConfirmed=' + visitOverrideConfirmed;
    }

    return fetch(url, requestOptions).then(handleResponse);
}

function getAppointments(type, offset, searchVal) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',
    };
    var queryParams = "type=" + type + "&locId=" + JSON.parse(localStorage.getItem('app')).locations[0].id + "&page=" + offset + "&pageCount=" + config.recordsPageSize;
    if (searchVal != undefined && searchVal != '')
        queryParams += "&searchVal=" + searchVal
    return fetch(apiPath + 'visits?' + queryParams, requestOptions).then(handleResponse);
}


function getAppointment(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',
    };
    return fetch(apiPath + 'visits/' + id, requestOptions).then(handleResponse);
}

function getVisitHistory(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',
    };
    return fetch(apiPath + 'visits?type=user-past&locId=' + JSON.parse(localStorage.getItem('app')).locations[0].id + '&visitId=' + id, requestOptions).then(handleResponse);

}

function cancelVisit(reason, visitId) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify({cancellationReason: reason})
    };
    return fetch(apiPath + 'visits/' + visitId, requestOptions).then(handleResponse);
}

function cancelAppointment(reason, visitTechId) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify({cancellationReason: reason})
    };
    return fetch(apiPath + 'visit-technicians/' + visitTechId, requestOptions).then(handleResponse);
}

function checkInCustomer(notes, visitId) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify({staffNotes: notes})
    };

    return fetch(apiPath + 'visits/' + visitId, requestOptions).then(handleResponse);
}

function cancelCheckInAppointment(visitId) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: "{}"
    };

    return fetch(apiPath + 'visits/' + visitId + '/undo', requestOptions).then(handleResponse);
}

function checkInAppointment(visitTechnicianId) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
    };
    return fetch(apiPath + 'visit-technicians/' + visitTechnicianId, requestOptions).then(handleResponse);
}


function checkOutAppointment(amount, visitTechnicianId) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify({chargeAmount: amount})
    };

    return fetch(apiPath + 'visit-technicians/' + visitTechnicianId, requestOptions).then(handleResponse);
}

function checkOutCustomer(serviceChargeAmount, productPurchaseAmount, tipAmount, totalCharge, rewardDeduction, credit, cash, check, notes, visitId) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify({
            serviceChargeAmount: serviceChargeAmount,
            productPurchaseAmount: productPurchaseAmount,
            tipAmount: tipAmount,
            totalChargeAmount: totalCharge,
            rewardDeductionAmount: rewardDeduction,
            creditPaidAmount: credit,
            cashPaidAmount: cash,
            checkPaidAmount: check,
            staffNotes: notes
        })
    };

    return fetch(apiPath + 'visits/' + visitId, requestOptions).then(handleResponse);
}

function addAppointment(visitId, technicianId, serviceId, startTime) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(
            {
                visit: {
                    id: visitId
                },
                technician: {
                    id: technicianId
                },
                offeredService: {
                    id: serviceId
                },
                expectedStartTime: startTime
            }
        )
    };

    return fetch(apiPath + 'visit-technicians/', requestOptions).then(handleResponse);
}

function updateAppointment(appointmentId, json) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(json)
    };

    return fetch(apiPath + 'visits/update/' + appointmentId, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    if (!response.ok) {
        if (response.status == 401 || response.status == 403) {
            localStorage.removeItem('user')
            window.location = `/${window.location.href.split('/')[3]}/login`;
        }
        return Promise.reject(response);
    }
    return response.json();
}

function handleDeleteResponse(response) {
    if (!response.ok) {
        if (response.status == 401 || response.status == 403) {
            localStorage.removeItem('user')
            window.location = `/${window.location.href.split('/')[3]}/login`;
        } else {
            return Promise.reject(response);
        }
    }
    return {"status": "ok"}

}
