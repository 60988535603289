/* eslint-disable */
import React, {Component} from 'react';
import Shopping from "./shopping";

class AddNewShopping extends Component {

    constructor(props){
        super(props)
    }

    render() {
        return (

            <Shopping save={this.props.save} place={this.props.place} clearPlace={this.props.clearPlace}  customer={this.props.customer} setPlace={this.props.setPlace} productCheckout={this.props.productCheckout}/>
        );
    }
}

export default AddNewShopping;