import React from 'react';
import ManageItem from './manage-item'
const Manage = ({ openAddNew, items, deletableItems, collectCheckbox, onDelete, deleteItem, onEdit, editableItem, editItemName, hide, update, errors }) => {
  return (
    <React.Fragment>
      <div className="manage-component">
        {items && items.length > 0 && items.map((item, key) => {
          return <ManageItem errors={errors} update={update} hide={hide} deletableItems={deletableItems} key={key} item={item} collectCheckbox={collectCheckbox} deleteItem={deleteItem} onEdit={onEdit} editableItem={editableItem} editItemName={editItemName} />
        })}
      </div>

      <div className="add-new-item-footer d-flex justify-between items-center">
        <button onClick={openAddNew} className="btn btn-danger btn-block" style={{ marginTop: '5px' }}>Cancel</button>
        {deletableItems.length > 0 &&
          <button onClick={onDelete} className="btn btn-warning btn-block">Delete Selected</button>
        }
      </div>

    </React.Fragment >
  )
}

export default Manage;
