import React from "react";

import 'rc-datepicker/lib/style.css';
import 'rc-time-picker/assets/index.css';
import TimePicker from 'rc-time-picker';
import moment from 'moment'

const CustomTimePicker = (props) => {
    const onChange = (v) => {
        props.onChange(v, props.name);
    }
    //console.log(props.value);
    const formattedValue = props.value !== '' ? moment(props.value, ['h:m a', 'H:m']) : moment().hour(0).minute(0);
    return (
        <div>
            <TimePicker
                value={formattedValue}
                showSecond={false}
                name={props.name}
                className="xxx"
                onChange={onChange}
                format={props.format}
                use12Hours
                inputReadOnly
            />


        </div>
    );
};

export default CustomTimePicker;

