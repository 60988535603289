import React, {Component} from 'react';

import {inventoryService} from '../_services';
import BarcodeScanner from "react-barcode-reader";

class ProductScanner extends Component {

    constructor(props) {
        super(props)
        this.handleScan = this.handleScan.bind(this)
        this.handleError = this.handleError.bind(this)
        //this.handleScan()
    }

    handleScan(data) {
        window.customFocusOut();
        if (data != undefined) {
            inventoryService.searchProduct(data, 1).then(product => {
                this.props.onProductScan(product)
            })
        }
    }

    handleError(err) {
        console.error(err)
    }

    render() {
        return (
            <BarcodeScanner
                onError={this.handleError}
                onScan={this.handleScan}/>

        )
    }
}

export default ProductScanner;
