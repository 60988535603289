/* eslint-disable */
import { reservationService } from '../_services';
import { reservationConstants } from '../_constants';
 
export const reservationActions = {
    getAppointments,
    checkInCustomer,
    checkInAppointment,
    checkAvailability,
    placeReservation
};
 
function checkAvailability(datePreference,startDate,endDate,timePreference,startTime,endTime,services, daysBetween, dayOfWeek) {
    return dispatch => {
        var preferences = {
            locationId:JSON.parse(localStorage.getItem('app')).locations[0].id,
            datePreference:datePreference,
            startDate:startDate,
            endDate:endDate,
            timePreference:timePreference,
            startTime:startTime,
            endTime:endTime,
            serviceTechnicians:services
        }
        // eslint-disable-next-line
        if(dayOfWeek != ''){
            preferences.dayOfWeek = dayOfWeek;
        }
        if(daysBetween != ''){
            preferences.daysBetween = daysBetween;
        }
        dispatch(request(preferences))
        reservationService.findOpenSlots(preferences)
            .then(
                slots => {
                    dispatch(success(slots));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
 
    function request(preferences) { return { type: reservationConstants.APPOINTMENT_SLOTS_REQUEST, preferences } }
    function success(slots) { return { type: reservationConstants.APPOINTMENT_SLOTS_SUCCESS, slots } }
    function failure(error) { return { type: reservationConstants.APPOINTMENT_SLOTS_FAILURE, error } }
}

function placeReservation(requestSource,userId,reservations,notes,reservationType) {
    return dispatch => {
        var visits = []
        var reservationJson = {
            location:{id:JSON.parse(localStorage.getItem('app')).locations[0].id},
            client:{id:userId},
            requestSource:requestSource,
            customerNotes:notes,
            visitTechnicians:[],
            standingAppointment: false
        }

        if(reservationType == "recurring"){
            var visitTechnicians = []
            var date = reservations[0].date
            reservations.forEach(reservation => {
                if(reservation.date == date){
                    visitTechnicians.push({
                        technician:{id:reservation.technicianId},
                        offeredService:{id:reservation.serviceId},
                        expectedStartTime:reservation.dateTime
                    });
                }
                else{
                    visits.push(createVisit(requestSource,userId,notes,visitTechnicians))
                    visitTechnicians = []
                    date = reservation.date
                    visitTechnicians.push({
                        technician:{id:reservation.technicianId},
                        offeredService:{id:reservation.serviceId},
                        expectedStartTime:reservation.dateTime
                    })
                }
            });
            visits.push(createVisit(requestSource,userId,notes,visitTechnicians))
            dispatch(request( visits ));
            visits.forEach(function(visit,index){
                reservationService.placeReservation(visit)
                .then(
                    response => {
                        if(index == visits.length-1)
                            dispatch(success());
                    },
                    error => {
                        dispatch(failure(error));
                    }
                );
            })
        }
        else{
            reservations.forEach(reservation => {
                reservationJson.visitTechnicians.push({
                    technician:{id:reservation.technicianId},
                    offeredService:{id:reservation.serviceId},
                    expectedStartTime:reservation.dateTime
                });
            });
            dispatch(request( reservationJson ));
            reservationService.placeReservation(reservationJson)
            .then(
                response => {
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error));
                }
            );
        }
        
        
        
    };
    
    function request(reservations) { return { type: reservationConstants.PLACE_RESERVATION_REQUEST, reservations } }
    function success() { return { type: reservationConstants.PLACE_RESERVATION_SUCCESS } }
    function failure(error) { return { type: reservationConstants.PLACE_RESERVATION_SUCCESS, error } }
}

function createVisit(requestSource,userId,notes,visitTechnicians){
    return {
        location:{id:JSON.parse(localStorage.getItem('app')).locations[0].id},
        client:{id:userId},
        requestSource:requestSource,
        customerNotes:notes,
        visitTechnicians:visitTechnicians,
        standingAppointment: true
    }
}

function getAppointments(type,offset) {
    return dispatch => {
        dispatch(request)
        reservationService.getAppointments(type,offset)
            .then(
                appointments => {
                    localStorage.setItem('appointments',JSON.stringify(appointments));
                    dispatch(success(appointments));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
 
    function request() { return { type: reservationConstants.APPOINTMENTS_REQUEST } }
    function success(appointments) { return { type: reservationConstants.APPOINTMENTS_SUCCESS, appointments } }
    function failure(error) { return { type: reservationConstants.APPOINTMENTS_FAILURE, error } }
}

function checkInCustomer(notes,visitId) {
    return dispatch => {
      
        reservationService.checkInCustomer(notes,visitId)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
 
    function success(response) { return { type: reservationConstants.CHECK_IN_CUSTOMER_SUCCESS, response } }
    function failure(error) { return { type: reservationConstants.CHECK_IN_CUSTOMER_FAILURE, error } }
}

function checkInAppointment(visitTechnicianId) {
    return dispatch => {
      
        reservationService.checkInAppointment(visitTechnicianId)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
 
    function success(response) { return { type: reservationConstants.CHECK_IN_APPOINTMENT_SUCCESS, response } }
    function failure(error) { return { type: reservationConstants.CHECK_IN_APPOINTMENT_FAILURE, error } }
}