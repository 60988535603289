import React from 'react';

const ManageItem = ({
                        item,
                        deletableItems,
                        collectCheckbox,
                        deleteItem,
                        onEdit,
                        editableItem,
                        editItemName,
                        hide,
                        update,
                        errors
                    }) => {
    return (
        <div className="manage-item">
            <div className="name-action d-flex justify-between items-center">
                <div className="items-center d-flex">
                    <input autoFocus onChange={() => collectCheckbox(item)} type="checkbox" id={`id-${item.id}`}
                           checked={deletableItems.includes(item.id)}/> <label
                    htmlFor={`id-${item.id}`}>{item.name}</label>
                </div>
                <div>
                    <button onClick={() => deleteItem(item)} className="btn btn-custom-circle"><i
                        className="fa fa-trash"></i>
                    </button>
                    <button onClick={() => onEdit(item)} className="btn btn-custom-circle"><i
                        className="fa fa-pencil"></i>
                    </button>
                </div>
            </div>
            <div className={`updatable-item ${item.id === editableItem.id ? 'active' : ''}`}>

                <div className="input-group mb-3">

                    <input onChange={editItemName} type="text"
                           className={`form-control ${errors && errors.editableItemName ? 'is-invalid' : ''}`}
                           value={editableItem.name}/>

                    <span onClick={hide} className="input-group-addon text-danger"><i
                        className="fa fa-times"></i></span>
                    <div className="input-group-addon bg-info">
                        <span onClick={update} className="input-group-text text-white">update</span>
                    </div>
                </div>
                <div
                    className="invalid-feedback text-danger">{errors && errors.editableItemName && errors.editableItemName}</div>

            </div>
        </div>
    )
}

export default ManageItem;
