import React, {Component} from 'react';
import {appService} from '../_services';
import {userService} from '../_services';
import {inventoryService} from '../_services';
import Autosuggest from 'react-autosuggest';


//Customer
const getUserSuggestionValue = suggestion => suggestion && Object.keys(suggestion).length > 0 && suggestion.fullName;
const renderUserSuggestionValue = suggestion => (
    <div>
        {suggestion && Object.keys(suggestion).length > 0 && suggestion.fullName}
    </div>
);


const getStaffSuggestionValue = suggestion => suggestion && Object.keys(suggestion).length > 0 && suggestion.user.fullName;
const renderStaffSuggestionValue = suggestion => {

   if(suggestion){
        if(suggestion.user){
            return (<div>{suggestion.user.fullName}</div>)
        }
   }


}





const getServiceSuggestionValue = suggestion => {
    if(suggestion){
        return suggestion.service.name
    }
}
const renderServiceSuggestionValue = suggestion => (
    <div>{suggestion && Object.keys(suggestion).length > 0 && suggestion.service.name} </div>
);






const getProductSuggestionValue = suggestion => suggestion && suggestion.variant.product.name;
const renderProductSuggestionValue = suggestion => (
    <div>
        {suggestion &&  suggestion.variant.product.name}
    </div>
);


const getPayrollSuggestionValue = suggestion => suggestion.payroll.name;
const renderPayrollSuggestionValue = suggestion => (
    <div>
        {suggestion && Object.keys(suggestion).length > 0 && suggestion.payroll.name}
    </div>
);

//other

const getOtherSuggestionValue = suggestion => suggestion.payroll.name;
const renderOtherSuggestionValue = suggestion => (
    <div>
        {suggestion && Object.keys(suggestion).length > 0 && suggestion.payroll.name}
    </div>
);


class UserSearchBar extends Component {

    constructor(props) {
        super(props)
        this.state = {
            value: '',
            userSuggestions: []
        }
    }

    onUserNameChange = (e, {newValue}) => {
        // eslint-disable-next-line
        if(this.props.predefinedRelatedTo != ''){
            this.setState({
                value: newValue
            });
        }else{
           this.setState({
            value: 'Please select related type first'
           })
        }
    };

    onSuggestionsFetchRequested = ({value}) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        if (inputLength === 0) {
            return []
        } else {
            // eslint-disable-next-line
            if (this.props.predefinedRelatedTo == 'Customer') {
                userService.searchCustomers('all',0,inputValue).then(user => {
                    this.setState({
                        userSuggestions: user
                    });
                })
            }
            // eslint-disable-next-line
            else if (this.props.predefinedRelatedTo == 'Staff') {
                userService.getAllTechnicians(0,'',inputValue).then(user => {
                    if(user && user.length > 0){
                        this.setState({
                            userSuggestions: user
                        })
                    }

                })

            }
            // eslint-disable-next-line
            else if (this.props.predefinedRelatedTo == 'Product') {
                inventoryService.searchProduct(inputValue,5).then(products => {
		            this.setState({
                        userSuggestions: products
		            });
		        })
            }
            // eslint-disable-next-line
            else if (this.props.predefinedRelatedTo == 'Payroll') {
                appService.getAllServices(0, '', inputValue).then(user => {
                    this.setState({
                        userSuggestions: user
                    });
                })
            }
            // eslint-disable-next-line
            else if (this.props.predefinedRelatedTo == 'Service') {
                appService.getAllServices(0, '', inputValue).then(services => {
                    this.setState({
                        userSuggestions: services
                    });
                })
            }
            // eslint-disable-next-line
            else if (this.props.predefinedRelatedTo == 'Other') {
                appService.getAllServices(0, '', inputValue).then(services => {
                    this.setState({
                        userSuggestions: services
                    });
                })
            }
        }
    }


    onSuggestionSelected = (event, {suggestion, suggestionValue, suggestionIndex, sectionIndex, method}) => {
        this.props.onUserSelect(suggestion)
    }

    onSuggestionsClearRequested = () => {
        this.setState({
            userSuggestions: []
        });
    };


    render() {

        // eslint-disable-next-line
        var disabled = this.props.mode == '' && this.props.place && this.props.place != '' ? 'disabled' : ''

        const {value, userSuggestions} = this.state;
        var placeholder = ''
        // eslint-disable-next-line
        if(this.props.place && this.props.place != ''){
            placeholder = this.props.place
        }else{
            // eslint-disable-next-line
            if(this.props.predefinedRelatedTo == 'Customer' || this.props.predefinedRelatedTo == 'Staff'){
                placeholder = 'Name, email or mobile no..'
            }else{
                placeholder = 'Please search here'
            }
        }
        const inputProps = {
            // eslint-disable-next-line
            placeholder: this.props.place && this.props.place != '' ? this.props.place : placeholder,
            value,
            type: 'search',
            onChange: this.onUserNameChange,
            className: "form-control",
            id: this.props.id,
            disabled

        };


        return (
            <Autosuggest
                suggestions={userSuggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                // eslint-disable-next-line
                getSuggestionValue={this.props.predefinedRelatedTo == 'Customer' ? getUserSuggestionValue : this.props.predefinedRelatedTo == 'Staff' ? getStaffSuggestionValue : this.props.predefinedRelatedTo == 'Service' ? getServiceSuggestionValue : this.props.predefinedRelatedTo == 'Payroll' ? getPayrollSuggestionValue : this.props.predefinedRelatedTo == 'Product' ? getProductSuggestionValue : getOtherSuggestionValue}
                // eslint-disable-next-line
                renderSuggestion={this.props.predefinedRelatedTo == 'Customer' ? renderUserSuggestionValue : this.props.predefinedRelatedTo == 'Staff' ? renderStaffSuggestionValue : this.props.predefinedRelatedTo == 'Service' ? renderServiceSuggestionValue : this.props.predefinedRelatedTo == 'Payroll' ? renderPayrollSuggestionValue : this.props.predefinedRelatedTo == 'Product' ? renderProductSuggestionValue : renderOtherSuggestionValue}
                onSuggestionSelected={this.onSuggestionSelected}
                inputProps={inputProps}
            />
        )
    }

}

export default UserSearchBar
