import {appConstants} from '../_constants';
import {appService} from '../_services';

export const appActions = {
    getAllServices,
    getCategories,
    generateReloader,

};

function getAllServices(page, ctgryId, searchVal) {

    return dispatch => {
        dispatch(loading())
        appService.getAllServices(page, ctgryId, searchVal)
            .then(
                services => {

                    dispatch(success(services));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };


    function success(services) {
        return {type: appConstants.GET_SERVICES_SUCCESS, services}
    }

    function failure(error) {
        return {type: appConstants.GET_SERVICES_FAILURE, error}
    }

    function loading() {
        return {type: appConstants.GET_SERVICES_REQUEST}
    }
}

function generateReloader(reloader) {

    return dispatch => {
        dispatch(success(reloader));
    }
    function success(reloader) {
        return {type: appConstants.RELOADER_SUCCESS, reloader}
    }
};


function getCategories(page, pageCount) {

    return dispatch => {
        dispatch(loading())
        appService.getCategories(page, pageCount)
            .then(
                categories => {

                    dispatch(success(categories));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };


    function success(categories) {
        return {type: appConstants.GET_CATEGORY_SUCCESS, categories}
    }

    function failure(error) {
        return {type: appConstants.GET_CATEGORY_FAILURE, error}
    }

    function loading() {
        return {type: appConstants.GET_CATEGORY_INIT}
    }
}

