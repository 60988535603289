export const inventoryConstants = {
  GET_ALL_PRODUCTS_REQUEST: 'GET_ALL_PRODUCTS_REQUEST',
  GET_ALL_PRODUCTS_SUCCESS: 'GET_ALL_PRODUCTS_SUCCESS',
  GET_ALL_PRODUCTS_FAILURE: 'GET_ALL_PRODUCTS_FAILURE',


  //BRANDS
  BRANDS_LOADING: 'BRANDS_LAODING',
  BRANDS_FETCHED: 'BRANDS_FETCHED',
  BRANDS_FAILURE: 'BRANDS_FAILURE'
};
