/* eslint-disable */
import React from 'react'
import Footer from '../footer/footer';
import {connect} from "react-redux";
import 'rc-datetime-picker/dist/picker.min.css';
import moment from 'moment';
import {DatetimePickerTrigger} from 'rc-datetime-picker';
import {closeoutAction} from "../../_actions";
import {appService, closeOutService} from "../../_services";
import {helperFunctions} from "../../_helpers";


class Closeout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: moment(),
            endDate: moment(),
            pageOffset: 0,
            newCloseOut: '',
            serviceChargeAmount: '',
            productPurchaseAmount: '',
            tipAmount: '',
            discountDeductionAmount: '',
            rewardDeductionAmount: '',
            creditPaidAmount: '',
            cashPaidAmount: '',
            checkPaidAmount: '',
            giftCardPaidAmount: '',
            registerCashMet: '',
            registerCashLeft: '',
            status: '',
            loading: false,
            cId: ''

        }
        const {dispatch} = this.props;
        dispatch(closeoutAction.getAllCloseOut(0, moment(this.state.startDate).format('YYYY-MM-DD'), moment(this.state.endDate).format('YYYY-MM-DD')))

    }

    componentDidMount = async () => {
        const metaInfo = await appService.checkVersion();
        await helperFunctions.clearCache(metaInfo)
    }

    changeHandle = (e) => {
        const {name, value} = e.target;
        this.setState({
            [name]: value
        })
    }

    getNewCloseOut = () => {
        this.setState({
            loading: true
        })
        const data = {
            location: {
                id: JSON.parse(localStorage.getItem('app')).locations[0].id
            }
        }
        closeOutService.getNewCloseOut(data).then(newCloseOut => {
            $('#createClose').modal('toggle')

            this.setState({
                startDate: moment(newCloseOut.period.from),
                endDate: moment(newCloseOut.period.to),
                serviceChargeAmount: newCloseOut.serviceChargeAmount,
                productPurchaseAmount: newCloseOut.productPurchaseAmount,
                tipAmount: newCloseOut.tipAmount,
                discountDeductionAmount: newCloseOut.discountDeductionAmount,
                rewardDeductionAmount: newCloseOut.rewardDeductionAmount,
                creditPaidAmount: newCloseOut.creditPaidAmount,
                cashPaidAmount: newCloseOut.cashPaidAmount,
                checkPaidAmount: newCloseOut.checkPaidAmount,
                giftCardPaidAmount: newCloseOut.giftCardPaidAmount,
                registerCashMet: (newCloseOut.registerCashMet * 1).toFixed(2),
                registerCashLeft: (newCloseOut.registerCashLeft * 1).toFixed(2),
                status: newCloseOut.status,
                loading: false,
                cId: newCloseOut.id,
                newCloseOut
            })
        }).catch(e => {
            e.json().then(err => {
                Swal.fire({
                    title: 'Error',
                    text: `${err.message}`,
                    type: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Ok!'
                })
            })
        })
    }

    formatMetNumber = () => {
        const met = this.state.registerCashMet;
        this.setState({
            registerCashMet: (met * 1).toFixed(2)
        })
    }
    formatLeftNumber = () => {
        const lefts = this.state.registerCashLeft;
        this.setState({
            registerCashLeft: (lefts * 1).toFixed(2)
        })
    }

    changeStatus = (status) => {
        const data = {
            registerCashMet: this.state.registerCashMet,
            registerCashLeft: this.state.registerCashLeft,
            status: status,
            period: {
                from: this.state.startDate.format('YYYY-MM-DDTHH:mm:ss'),
                to: this.state.endDate.format('YYYY-MM-DDTHH:mm:ss')
            }
        }

        closeOutService.changeStatus(this.state.cId, data).then(res => {
            $('#createClose').modal('toggle');
            this.setState({
                startDate: moment(),
                endDate: moment()
            }, () => {
                const {dispatch} = this.props;
                dispatch(closeoutAction.getAllCloseOut(this.state.pageOffset, moment(this.state.startDate).format('YYYY-MM-DD'), moment(this.state.endDate).format('YYYY-MM-DD')))

            })
        }).catch(e => {
            e.json().then(err => {
                Swal.fire({
                    title: 'Error',
                    text: `${err.message}`,
                    type: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Ok!'
                })
            })
        })
    }

    changeStartDate = (start) => {
        this.setState({
            startDate: start
        })
    }
    changeEndDate = (end) => {
        this.setState({
            endDate: end
        })
    }
    getMoreCloseOut = () => {
        const {dispatch} = this.props;
        dispatch(closeoutAction.getAllCloseOut(this.state.pageOffset, moment(this.state.startDate).format('YYYY-MM-DD'), moment(this.state.endDate).format('YYYY-MM-DD')))
    }

    initiateProcess = (newCloseOut) => {

        this.setState({
            startDate: moment(newCloseOut.period.from),
            endDate: moment(newCloseOut.period.to),
            serviceChargeAmount: newCloseOut.serviceChargeAmount,
            productPurchaseAmount: newCloseOut.productPurchaseAmount,
            tipAmount: newCloseOut.tipAmount,
            discountDeductionAmount: newCloseOut.discountDeductionAmount,
            rewardDeductionAmount: newCloseOut.rewardDeductionAmount,
            creditPaidAmount: newCloseOut.creditPaidAmount,
            cashPaidAmount: newCloseOut.cashPaidAmount,
            checkPaidAmount: newCloseOut.checkPaidAmount,
            giftCardPaidAmount: newCloseOut.giftCardPaidAmount,
            registerCashMet: (newCloseOut.registerCashMet * 1).toFixed(2),
            registerCashLeft: (newCloseOut.registerCashLeft * 1).toFixed(2),
            status: newCloseOut.status,
            cId: newCloseOut.id
        });
        $('#createClose').modal('toggle')
    }

    deleteCloseOut = () => {
        Swal.fire({
            title: 'Delete',
            text: `Are you sure you want to delete?`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes!',
            cancelButtonText: 'Nope!'
        }).then((result) => {
            if (result.value) {
                closeOutService.deleteCloseOut(this.state.cId).then(() => {
                    const {dispatch} = this.props;
                    $('#createClose').modal('toggle');
                    dispatch(closeoutAction.getAllCloseOut(this.state.pageOffset, moment(this.state.startDate).format('YYYY-MM-DD'), moment(this.state.endDate).format('YYYY-MM-DD')))

                    window.toast('warning', 'Deleted successfully from the system!')

                }).catch(e => {
                    e.json().then(err => {
                        Swal.fire({
                            title: 'Error',
                            text: `${err.message}`,
                            type: 'warning',
                            showCancelButton: false,
                            confirmButtonText: 'Ok!'
                        })
                    })
                })
            }
        })
    }


    render() {
        const {closeOut, closeoutLoaded} = this.props;


        return (
            <div id="page-wrapper">

                <div className="container-fluid">
                    <div className="row bg-title">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <h4 className="page-title">Close Out</h4>
                        </div>
                        <div className="col-lg-5 col-md-5 col-sm-4 col-xs-4 text-right close-out">
                            <ul className="pager">
                                <li><a onClick={() => {
                                    this.getPreviousPage()
                                }}><i className="fa fa-angle-left"></i></a>
                                </li>
                                <li><a onClick={() => {
                                    this.getNextPage()
                                }}><i className="fa fa-angle-right"></i></a>
                                </li>
                            </ul>

                        </div>
                        <div className="col-lg-1 col-md-1 col-sm-2 col-xs-2">
                            <button onClick={() => {
                                this.resetFilters()
                            }} className="btn btn-warning btn-sm">Clear Filters
                            </button>

                        </div>
                    </div>

                    <div className="row">

                        {
                            closeOut && closeoutLoaded == false &&
                            <React.Fragment>
                                <div className="col-md-4 col-sm-6 col-xs-12 single-customer">
                                    <div className="loading"></div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12 single-customer">
                                    <div className="loading"></div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12 single-customer">
                                    <div className="loading"></div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12 single-customer">
                                    <div className="loading"></div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12 single-customer">
                                    <div className="loading"></div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12 single-customer">
                                    <div className="loading"></div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12 single-customer">
                                    <div className="loading"></div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12 single-customer">
                                    <div className="loading"></div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12 single-customer">
                                    <div className="loading"></div>
                                </div>
                            </React.Fragment>
                        }
                    </div>
                    <div className="row">
                        {
                            closeOut && closeOut.length === 0 && closeoutLoaded == true &&
                            <div className="text-center">
                                <h2 className="center">Records not found!!</h2>
                            </div>
                        }
                    </div>

                    {closeOut && closeoutLoaded == true &&
                        <div className="row">
                            <div className="col-md-2">
                                <div className="start-time form-group">

                                    <label>From Date </label>
                                    <DatetimePickerTrigger
                                        onChange={this.changeStartDate}
                                        moment={this.state.startDate}>
                                        <input
                                            type="text"
                                            value={this.state.startDate.format('YYYY-MM-DD')}
                                            readOnly/>
                                    </DatetimePickerTrigger>

                                </div>

                            </div>
                            <div className="col-md-2">
                                <div className="start-time form-group">

                                    <label>To Date</label>
                                    <DatetimePickerTrigger
                                        onChange={this.changeEndDate}
                                        moment={this.state.endDate}>
                                        <input

                                            type="text"
                                            value={this.state.endDate.format('YYYY-MM-DD')}
                                            readOnly/>
                                    </DatetimePickerTrigger>

                                </div>

                            </div>
                            <div className="col-md-1">
                                <div className="action-right padding-right-0 text-left">
                                    <button onClick={this.getMoreCloseOut} className="btn btn-custom-info">Apply
                                    </button>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="action-right padding-right-0 text-right">
                                    <button onClick={this.getNewCloseOut} className="btn btn-custom-info"><i
                                        className="fa fa-plus"></i> &nbsp; New Close Out
                                    </button>
                                </div>
                            </div>
                        </div>

                    }

                    <div className="row">


                        {closeOut && closeOut.length > 0 && closeoutLoaded == true && closeOut.map((c, k) => {
                            return <div key={k} className="col-md-4">
                                <div
                                    className={c.status == 'New' ? 'panel panel-default' : c.status == 'Unresolved' ? 'panel panel-warning' : 'panel panel-success'}>
                                    <div className="panel-heading">
                                        <strong>Status: {c.status}</strong>
                                    </div>
                                    <div className="panel-body">
                                        <ul className="payroll">
                                            <li>
                                                <i className="ti ti-calendar"></i>
                                                <span className="payroll-tittle">Period</span>
                                                <span className="colon">:</span>
                                                <span
                                                    className="value">{helperFunctions.getTimelineDate(moment(c.period.from), 'fullDate')} - {helperFunctions.getTimelineDate(moment(c.period.to), 'fullDate')}</span>
                                            </li>
                                            <li>
                                                <i className="ti ti-alarm-clock"></i>
                                                <span className="payroll-tittle">Time</span>
                                                <span className="colon">:</span>
                                                <span
                                                    className="value">{helperFunctions.formatDateTimeWithDay(moment(c.period.from), 'time')} - {helperFunctions.formatDateTimeWithDay(moment(c.period.to), 'time')}</span>
                                            </li>

                                            <li>
                                                <i className="ti ti-list"></i>
                                                <span className="payroll-tittle">Income</span>
                                                <span className="colon">:</span>
                                                <span
                                                    className="value">${((c.serviceChargeAmount * 1) + (c.productPurchaseAmount * 1) + (c.tipAmount * 1)).toFixed(2)}</span>
                                            </li>
                                            <li>
                                                <i className="ti ti-credit-card"></i>
                                                <span className="payroll-tittle">Payment</span>
                                                <span className="colon">:</span>
                                                <span
                                                    className="value">${((c.cashPaidAmount * 1) + (c.creditPaidAmount * 1) + (c.checkPaidAmount * 1) + (c.giftCardPaidAmount * 1) + (c.rewardDeductionAmount * 1)).toFixed(2)}</span>
                                            </li>


                                            <li>
                                                <i className="ti ti-volume"></i>
                                                <span className="payroll-tittle">Cash Met.</span>
                                                <span className="colon">:</span>
                                                <span
                                                    className="value">${c.registerCashMet}</span>
                                            </li>
                                            <li>
                                                <i className="ti ti-check-box"></i>
                                                <span className="payroll-tittle">Cash Left</span>
                                                <span className="colon">:</span>
                                                <span className="value">${c.registerCashLeft}</span>
                                            </li>
                                            <li>
                                                <i className="ti ti-check-box"></i>
                                                <span className="payroll-tittle">In Register</span>
                                                <span className="colon">:</span>
                                                <span
                                                    className="value">${((c.registerCashMet * 1) + (c.cashPaidAmount * 1)).toFixed(2)}</span>
                                            </li>

                                        </ul>
                                    </div>
                                    <div className="panel-footer">

                                        <button onClick={() => this.initiateProcess(c)}
                                                className="btn  btn-custom-info text-uppercase process-btn pull-right">Update
                                        </button>
                                    </div>
                                </div>
                            </div>
                        })

                        }


                    </div>

                </div>

                <Footer/>
                <div className="modal fade" id="createClose">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button className="close pull-right" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <div
                                    className={this.state.loading == true ? 'add-new-header before-loading' : 'add-new-header'}>
                                    <div className="status">
                                        <p><strong>Status: </strong><label className="label label-info">NEW</label></p>
                                    </div>

                                </div>

                                <div className="add-new-body">

                                    <div className="row">

                                        <div className="col-md-6">

                                            <div
                                                className={this.state.loading == true ? 'start-time form-group before-loading' : 'start-time form-group'}>

                                                <label>Start Time </label>
                                                <DatetimePickerTrigger
                                                    moment={this.state.startDate}
                                                    onChange={this.changeStartDate}>
                                                    <input
                                                        type="text"
                                                        value={this.state.startDate.format('YYYY-MM-DD') + " " + helperFunctions.formatDateTimeWithDay(this.state.startDate, 'time')}
                                                        readOnly/>
                                                </DatetimePickerTrigger>

                                            </div>
                                            <div
                                                className={this.state.loading == true ? 'start-time form-group before-loading' : 'start-time form-group'}>

                                                <label>End Time </label>
                                                <DatetimePickerTrigger
                                                    moment={this.state.endDate}
                                                    onChange={this.changeEndDate}>
                                                    <input
                                                        type="text"
                                                        value={this.state.endDate.format('YYYY-MM-DD') + " " + helperFunctions.formatDateTimeWithDay(this.state.endDate, 'time')}
                                                        readOnly/>
                                                </DatetimePickerTrigger>

                                            </div>


                                        </div>

                                        <div className="col-md-6">
                                            <div
                                                className={this.state.loading == true ? 'form-group before-loading' : 'form-group'}>
                                                <label>Cash Met</label>
                                                <input
                                                    onChange={this.changeHandle}
                                                    className="form-control"
                                                    type="text"
                                                    value={this.state.registerCashMet}
                                                    onBlur={this.formatMetNumber}
                                                    name="registerCashMet"/>
                                            </div>
                                            <div
                                                className={this.state.loading == true ? 'form-group before-loading' : 'form-group'}>
                                                <label>Cash Left</label>
                                                <input
                                                    onChange={this.changeHandle}
                                                    className="form-control"
                                                    type="text"
                                                    value={this.state.registerCashLeft}
                                                    onBlur={this.formatLeftNumber}
                                                    name="registerCashLeft"/>
                                            </div>
                                            <div
                                                className={this.state.loading == true ? 'form-group before-loading' : 'form-group'}>
                                                <strong>Cash In Register:
                                                    ${((this.state.registerCashMet * 1) + (this.state.cashPaidAmount * 1)).toFixed(2)}</strong>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <hr/>

                                <div className="add-new-bottom">
                                    <div className="row">

                                        <div className='col-md-6'>
                                            <label className="label label-info">Income</label>
                                            <form>
                                                <div
                                                    className={this.state.loading == true ? 'form-group before-loading' : 'form-group'}>
                                                    <label>Services: </label>
                                                    <input
                                                        readOnly
                                                        onChange={this.changeHandle}
                                                        className="form-control"
                                                        type="text"
                                                        value={(this.state.serviceChargeAmount * 1).toFixed(2)}/>

                                                </div>
                                                <div
                                                    className={this.state.loading == true ? 'form-group before-loading' : 'form-group'}>
                                                    <label>Products: </label>

                                                    <input
                                                        readOnly
                                                        onChange={this.changeHandle}
                                                        className="form-control"
                                                        type="text"
                                                        value={(this.state.productPurchaseAmount * 1).toFixed(2)}/>

                                                </div>
                                                <div
                                                    className={this.state.loading == true ? 'form-group before-loading' : 'form-group'}>
                                                    <label>Tip: </label>

                                                    <input
                                                        readOnly
                                                        onChange={this.changeHandle}
                                                        className="form-control"
                                                        type="text"
                                                        value={(this.state.tipAmount * 1).toFixed(2)}/>

                                                </div>
                                                <div
                                                    className={this.state.loading == true ? 'form-group before-loading' : 'form-group'}>
                                                    <label><strong>Total: </strong>
                                                    </label>

                                                    <input
                                                        onChange={this.changeHandle}
                                                        readOnly
                                                        className="form-control fontweight" type="text"
                                                        value={((this.state.serviceChargeAmount != '' && this.state.serviceChargeAmount * 1) + (this.state.productPurchaseAmount != '' && this.state.productPurchaseAmount * 1) + (this.state.tipAmount != '' && this.state.tipAmount * 1)).toFixed(2)}/>

                                                </div>
                                            </form>
                                        </div>
                                        <div className='col-md-6'>
                                            <label className="label label-inverse">Payments</label>

                                            <form>
                                                <div
                                                    className={this.state.loading == true ? 'form-group before-loading' : 'form-group'}>
                                                    <label>Cash: </label>

                                                    <input
                                                        readOnly
                                                        className="form-control"
                                                        type="text"
                                                        value={(this.state.cashPaidAmount * 1).toFixed(2)}/>

                                                </div>
                                                <div
                                                    className={this.state.loading == true ? 'form-group before-loading' : 'form-group'}>
                                                    <label>Credit: </label>

                                                    <input
                                                        readOnly
                                                        className="form-control"
                                                        type="text"
                                                        onChange={this.changeHandle}
                                                        value={(this.state.creditPaidAmount * 1).toFixed(2)}/>

                                                </div>
                                                <div
                                                    className={this.state.loading == true ? 'form-group before-loading' : 'form-group'}>
                                                    <label>Check: </label>

                                                    <input
                                                        readOnly
                                                        className="form-control"
                                                        onChange={this.changeHandle}
                                                        type="text"
                                                        value={(this.state.checkPaidAmount * 1).toFixed(2)}/>

                                                </div>
                                                <div
                                                    className={this.state.loading == true ? 'form-group before-loading' : 'form-group'}>
                                                    <label>Gift Card: </label>

                                                    <input
                                                        readOnly
                                                        className="form-control"
                                                        type="text"
                                                        onChange={this.changeHandle}
                                                        value={(this.state.giftCardPaidAmount * 1).toFixed(2)}/>

                                                </div>
                                                <div
                                                    className={this.state.loading == true ? 'form-group before-loading' : 'form-group'}>
                                                    <label>Reward: </label>

                                                    <input
                                                        readOnly
                                                        className="form-control"
                                                        type="text"
                                                        value={(this.state.rewardDeductionAmount * 1).toFixed(2)}/>

                                                </div>
                                                <div
                                                    className={this.state.loading == true ? 'form-group before-loading' : 'form-group'}>
                                                    <label><strong>Total: </strong>
                                                    </label>

                                                    <input
                                                        onChange={this.changeHandle}
                                                        readOnly
                                                        className="form-control fontweight"
                                                        type="text"
                                                        value={((this.state.cashPaidAmount * 1) + (this.state.creditPaidAmount * 1) + (this.state.checkPaidAmount * 1) + (this.state.giftCardPaidAmount * 1) + (this.state.rewardDeductionAmount * 1)).toFixed(2)}/>

                                                </div>
                                            </form>
                                        </div>

                                    </div>

                                </div>
                            </div>

                            <div
                                className={this.state.loading == true ? 'modal-footer before-loading' : 'modal-footer'}>

                                {this.state.status == 'New' &&
                                    <button onClick={() => this.changeStatus('New')}
                                            className="btn btn-custom-info">Save
                                    </button>
                                }
                                {this.state.status == 'New' &&
                                    <button onClick={() => this.changeStatus('Unresolved')}
                                            className="btn btn-warning">Mark
                                        Unresolved
                                    </button>
                                }
                                {this.state.status == 'New' &&
                                    <button onClick={() => this.changeStatus('Completed')}
                                            className="btn btn-custom-info">Finalize
                                    </button>
                                }
                                {this.state.status == 'Unresolved' &&
                                    <button onClick={() => this.changeStatus('Completed')}
                                            className="btn btn-custom-info">Finalize
                                    </button>
                                }
                                {this.state.status == 'New' &&
                                    <button onClick={this.deleteCloseOut} className="btn btn-danger">Delete</button>
                                }

                            </div>


                        </div>
                    </div>
                </div>


            </div>

        )
    }

    getNextPage() {
        var offset = this.state.pageOffset + 1;
        const {closeOut, dispatch} = this.props;
        if (closeOut && closeOut.length == 9) {
            dispatch(closeoutAction.getAllCloseOut(offset, moment(this.state.startDate).format('YYYY-MM-DD'), moment(this.state.endDate).format('YYYY-MM-DD')));
            this.setState({pageOffset: offset});
        }

    }

    getPreviousPage() {
        var offset = this.state.pageOffset - 1;
        if (offset >= 0) {
            const {dispatch} = this.props;
            dispatch(closeoutAction.getAllCloseOut(offset, moment(this.state.startDate).format('YYYY-MM-DD'), moment(this.state.endDate).format('YYYY-MM-DD')));
            this.setState({pageOffset: offset});
        }
    }

    resetFilters = () => {
        const {dispatch} = this.props;
        this.setState({
            pageOffset: 0,
            startDate: moment(),
            endDate: moment()
        }, () => {
            dispatch(closeoutAction.getAllCloseOut(this.state.pageOffset, moment(this.state.startDate).format('YYYY-MM-DD'), moment(this.state.endDate).format('YYYY-MM-DD')));
        })
    }


}


function mapStateToProps(state) {
    const {closeOut, closeoutLoaded} = state.closeOut;

    return {
        closeOut,
        closeoutLoaded
    }
}

const connectedCloseOutPage = connect(mapStateToProps)(Closeout)
export {connectedCloseOutPage as Closeout};

