/* eslint-disable */
import moment from 'moment'
import 'moment-timezone';
import jstz from 'jstz'

export const helperFunctions = {
    formatMobilePhone,
    formatDateTime,
    compareDateStrings,
    getDay,
    isDateToday,
    getTodayDateTime,
    getCurrentTimezone,
    convertMomentToCurrentTz,
    getTimelineDate,
    formatDateTimeWithDay,
    timeConvert,
    dateFormat,
    get_time_diff,
    get_time_diff_seperate,
    firstDayOfWeek,
    clearCache
};

async function clearCache(info) {
    let localVersion = localStorage.getItem('appVersion');
    if (localVersion != null && localVersion != info.app_version) {
        localStorage.setItem('appVersion', info.app_version);
        const registrations = await navigator.serviceWorker.getRegistrations()
        // log
        for (const reg of registrations) {
            if (!reg.scope.includes("firebase-cloud-messaging-push-scope")) {
                await reg.unregister();
            }
        }
        const cacheKeys = await caches.keys()
        for (const ck of cacheKeys) {
            await caches.delete(ck)
        }
        setTimeout(() => {
            window.location.reload()
        }, 500)
    } else {
        localStorage.setItem('appVersion', info.app_version);
    }
}


function firstDayOfWeek(dateObject, firstDayOfWeekIndex) {

    const dayOfWeek = dateObject.getDay(),
        firstDayOfWeek = new Date(dateObject),
        diff = dayOfWeek >= firstDayOfWeekIndex ?
            dayOfWeek - firstDayOfWeekIndex :
            6 - dayOfWeek

    firstDayOfWeek.setDate(dateObject.getDate() - diff)
    firstDayOfWeek.setHours(0, 0, 0, 0)

    return firstDayOfWeek
}

// August 18th was a Saturday
let lastMonday = firstDayOfWeek(new Date('August 18, 2018 03:24:00'), 1)

// outputs something like "Mon Aug 13 2018 00:00:00 GMT+0200"
// (may vary according to your time zone)


function compareDateStrings(date, string) {
    var dd = String(date.getDate()).padStart(2, '0');
    var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = date.getFullYear();
    date = yyyy + '-' + mm + '-' + dd;
    return date == string
}

function formatMobilePhone(phone = '') {

    return "(" + phone.substr(0, 3) + ") " + phone.substr(3, 3) + "-" + phone.substr(6, 4);
}

function isDateToday(date) {
    var q = new Date();
    var m = q.getMonth();
    var d = q.getDate();
    var y = q.getFullYear();

    var checkDate = new Date(date);
    if (checkDate.getFullYear() == y && checkDate.getMonth() == m && checkDate.getDate() == d)
        return true;
    else
        return false;
}

function getDay(date, full) {
    var threeLetterDays = [
        "Sun",
        "Mon",
        "Tue",
        "Wed",
        "Thu",
        "Fri",
        "Sat"
    ];
    var fullDays = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
    ]
    var day = new Date(date).getDay()
    if (full)
        return fullDays[day]
    else
        return threeLetterDays[day];
}

function formatDateTime(dateTime, returnType) {
    if (!sessionStorage.getItem('timezone')) {
        var tz = jstz.determine() || 'America/New_York';
        sessionStorage.setItem('timezone', tz.name());
    }

    var currTz = sessionStorage.getItem('timezone');
    var momentTime = moment(dateTime)
    var tzTime = momentTime.tz(currTz);


    if (returnType == "date")
        //return tzTime.format('MMMM Do, YYYY');
        return tzTime.format('MM Do, YYYY');
    else if (returnType == "time")
        return tzTime.format('hh:mm a');
    else
        return tzTime.format('hh:mm a MMM D, YYYY');
}


function formatDateTimeWithDay(dateTime, returnType) {
    if (!sessionStorage.getItem('timezone')) {
        var tz = jstz.determine() || 'America/New_York';
        sessionStorage.setItem('timezone', tz.name());
    }

    var currTz = sessionStorage.getItem('timezone');
    var momentTime = moment(dateTime)
    var tzTime = momentTime.tz(currTz);


    if (returnType == "date")
        return tzTime.format('ddd MMM D, YYYY');
    else if (returnType == "time")
        return tzTime.format('hh:mm a');
    else if (returnType == "timeCard")
        return tzTime.format('ddd MMM  D');
    else if (returnType == "time_after")
        //Fri, Jan 31 2020 10:00am
        return tzTime.format('ddd, MMM Do YYYY hh:mm a');
    else if (returnType == 'latest')
        return tzTime.format('ddd, MMM D YYYY hh:mm a');
    else
        return tzTime.format('hh:mm a, ddd, MMMM Do, YYYY');
}


function getTimelineDate(dateTime, fullDate) {
    if (!sessionStorage.getItem('timezone')) {
        var tz = jstz.determine() || 'America/New_York';
        sessionStorage.setItem('timezone', tz.name());
    }

    var currTz = sessionStorage.getItem('timezone');
    var momentTime = moment(dateTime)
    var tzTime = momentTime.tz(currTz);

    if (fullDate)
        return tzTime.format('MM/DD/YYYY');
    else
        return tzTime.format('MMM D')
}

function getTodayDateTime() {
    if (!sessionStorage.getItem('timezone')) {
        var tz = jstz.determine() || 'America/New_York';
        sessionStorage.setItem('timezone', tz.name());
    }
    var currTz = sessionStorage.getItem('timezone');

    return moment(new Date()).tz(currTz)
}

function getCurrentTimezone() {
    if (!sessionStorage.getItem('timezone')) {
        var tz = jstz.determine() || 'America/New_York';
        sessionStorage.setItem('timezone', tz.name());
    }
    return sessionStorage.getItem('timezone');
}

function convertMomentToCurrentTz(time) {

    return time.tz(getCurrentTimezone())
}


function timeConvert(n, minute = '') {
    if (!sessionStorage.getItem('timezone')) {
        var tz = jstz.determine() || 'America/New_York';
        sessionStorage.setItem('timezone', tz.name());
    }


    if (minute == '') {
        var spl = n.split('PT');
        var s = spl[1].split('H');
        if (s.length == 2) {
            var min = s[1].split('M');


            if (min[0] == '') {
                return s[0] + ' :' + '00 mins';
            } else {
                return s[0] + ' :' + min[0] + 'mins';
            }
        } else {
            var spl = n.split('PT');
            var s = spl[1].split('H');

            var min = spl[1].split('M');

            return min[0] + " mins"

        }
    }


    if (minute != '') {
        var spl = n.split('PT');
        var s = spl[1].split('H');
        if (s.length == 2) {
            var min = s[1].split('M');

            return (Number(s[0]) * 60) + (Number(min[0]));
        } else {
            var spl = n.split('PT');
            var s = spl[1].split('H');

            var min = spl[1].split('M');

            return min[0]
        }
    }


}

function dateFormat(date) {
    if (!sessionStorage.getItem('timezone')) {
        var tz = jstz.determine() || 'America/New_York';
        sessionStorage.setItem('timezone', tz.name());
    }

    var month = '' + (date.getMonth() + 1);
    var day = '' + date.getDate();
    var year = date.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}


function get_time_diff(datetime) {
    if (!sessionStorage.getItem('timezone')) {
        var tz = jstz.determine() || 'America/New_York';
        sessionStorage.setItem('timezone', tz.name());
    }


    var datetime = typeof datetime !== 'undefined' ? datetime : "2014-01-01 01:02:03.123456";

    var datetime = new Date(datetime).getTime();
    var now = new Date().getTime();

    if (isNaN(datetime)) {
        return "";
    }

    //console.log( datetime + " " + now);

    if (datetime < now) {
        var milisec_diff = now - datetime;
    } else {
        var milisec_diff = datetime - now;
    }

    var days = Math.floor(milisec_diff / 1000 / 60 / (60 * 24));

    var date_diff = new Date(milisec_diff);
    var time = '';

    if (days != null && days != undefined && days != 0 && days != '') {

        time += days + " days "
    }
    if (date_diff.getHours() != null || date_diff.getHours() != undefined) {
        time += date_diff.getHours() + " hrs "
    }
    if (date_diff.getMinutes() != null || date_diff.getMinutes() != undefined) {
        time += date_diff.getMinutes() + " mins "
    }
    //return days + " Days "+ date_diff.getHours() + " Hours " + date_diff.getMinutes() + " Minutes " + date_diff.getSeconds() + " Seconds";
    //return days + " days "+ date_diff.getHours() + " hrs " + date_diff.getMinutes() + " minutes ";

    return time;

}

function get_time_diff_seperate(datetime) {
    if (!sessionStorage.getItem('timezone')) {
        var tz = jstz.determine() || 'America/New_York';
        sessionStorage.setItem('timezone', tz.name());
    }

    var datetime = typeof datetime !== 'undefined' ? datetime : "2014-01-01 01:02:03.123456";

    var datetime = new Date(datetime).getTime();
    var now = new Date().getTime();

    if (isNaN(datetime)) {
        return "";
    }

    //console.log( datetime + " " + now);

    if (datetime < now) {
        var milisec_diff = now - datetime;
    } else {
        var milisec_diff = datetime - now;
    }

    var days = Math.floor(milisec_diff / 1000 / 60 / (60 * 24));

    var date_diff = new Date(milisec_diff);

    //return days + " Days "+ date_diff.getHours() + " Hours " + date_diff.getMinutes() + " Minutes " + date_diff.getSeconds() + " Seconds";
    //return days + " days "+ date_diff.getHours() + " hrs " + date_diff.getMinutes() + " minutes ";


    if (days != null || day != undefined) {

        if (days > 30) {
            return days
        } else {
            return days
        }
    } else if (date_diff.getHours() != null || date_diff.getHours() != undefined) {
        return date_diff.getHours() + " hours "
    } else if (date_diff.getMinutes() != null || date_diff.getMinutes() != undefined) {
        return date_diff.getMinutes() + " mins "
    }


}
