import firebase from "firebase/compat/app";

import {isSupported, getMessaging, getToken} from 'firebase/messaging'
import {FirebaseService} from "../_services/firebase.service";

var config = {
    apiKey: "AIzaSyB6muHdSEG6Y5ZcTLxDv4HanG28hfhOIl0",
    authDomain: "silverbird-staff.firebaseapp.com",
    databaseURL: "https://silverbird-staff.firebaseio.com",
    projectId: "silverbird-staff",
    storageBucket: "silverbird-staff.appspot.com",
    messagingSenderId: "328786548146",
    appId: "1:328786548146:web:9651fff71796d91bce40ab",
    measurementId: "G-7S45NFGW3N"
};


// Initialize Firebase
export const app = firebase.initializeApp(config);
export let messaging = ''
isSupported().then(support => {
    if (support === true) {
        messaging = getMessaging(app)
    }
}).catch(e => console.log(e))

export const requestFirebaseNotification = () => {
    if (Notification != undefined) {
        Notification.requestPermission().then((permission) => {
            if (permission === "granted") {
                getToken(messaging, {
                    vapidKey: 'BKF7LC2R2eB5nraym04MswMkwEx6hgwt1k-_E43D0whvDKJ8iru4Uref3rd-n88cf6pkkedEV3QTCUFA74fSMqI'
                }).then(response => {
                    setTimeout(() => {
                        if (localStorage.getItem('app') !== null && localStorage.getItem('firebaseToken') === null) {
                            const appDetails = JSON.parse(localStorage.getItem('app'));
                            let apptTopics = 'lctn-appts-' + appDetails.locations[0].id
                            let twillioTopics = 'lctn-sms-' + appDetails.smsSenderNumber
                            let statusTopic = 'lctn-status-' + appDetails.smsSenderNumber
                            let updateStatus = "app-settings-update"
                            subscribeTokenToTopic(apptTopics, response)
                            subscribeTokenToTopic(twillioTopics, response)
                            subscribeTokenToTopic(statusTopic, response)
                            subscribeTokenToTopic(statusTopic, response)
                            localStorage.setItem('firebaseToken', updateStatus)
                        } else {
                            localStorage.removeItem('firebaseToken');
                            localStorage.setItem('firebaseToken', response)
                        }
                    }, 500)

                }).catch(e => console.log(e))

            } else {
                console.log("Do not have permission!");
            }
        });
    }
}


async function subscribeTokenToTopic(topic, firebaseToken) {
    const subscriptionResult = await FirebaseService.firebaseTopicSubscription(topic, firebaseToken);
}
