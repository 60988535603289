import React, { useState } from 'react';
import Dropzone, { useDropzone } from "react-dropzone";
import './dropzone.css'
const max_file_size = 10485760

const RcDropZone = (props) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/*'
  })


  const { selectedFiles, setSelectedFiles } = props;
  const [sizeError, setSizeError] = useState(false)

  const onDrop = (files) => {
    let totalSize = 0;
    files.forEach(f => {
      totalSize += f.size
    })
    if (totalSize > max_file_size) {
      setSizeError(true)
      setSelectedFiles(undefined)

    } else {
      setSizeError(false)
      setSelectedFiles(files)
    }
  }



  return (


    <Dropzone onDrop={onDrop}>
      {({ getRootProps, getInputProps }) => (
        <section>
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            {selectedFiles &&
              Array.isArray(selectedFiles) &&
              selectedFiles.length ? (
              <div className="selected-file">
                {selectedFiles.length > 3
                  ? `${selectedFiles.length} files`
                  : selectedFiles.map((file) => file.name).join(", ")}
              </div>
            ) : (
              sizeError ? <p className="invalid">The files size is exceeded, accepted size is max 10MB</p> : 'Drag and drop images here, or click to select images'
            )}

          </div>

        </section>
      )
      }
    </Dropzone >
  )
}

export default RcDropZone;
