import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { reservation } from './reservation.reducer';
import { updateProfile } from './updateProfile.reducer'
import { sendInquiry } from './contact.reducer';
import { customers } from './customers.reducer';
import { technicians } from './technicians.reducer';
import { app } from './app.reducer';
import { alert } from './alert.reducer';
import { inventory } from './inventory.reducer';
import { payroll } from './payroll.reducer';
import { closeOut } from "./closeout.reducer";
import { Communication } from "./communication.reducer";
import { outgoing } from "./outgoing.reducers";

const rootReducer = combineReducers({
  authentication,
  registration,
  reservation,
  updateProfile,
  sendInquiry,
  customers,
  technicians,
  app,
  alert,
  inventory,
  payroll,
  closeOut,
  Communication,
  outgoing
});

export default rootReducer;
