import {communicationConstants} from "../_constants/communication.constants";


export const outgoingAction = {
    proccessCall
}


function proccessCall(user){
    return dispatch =>{
        dispatch(success(user));
    }

    function success(user){return { type: communicationConstants.PROCESS_CALLING, user }}
}

