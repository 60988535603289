import React, {Component} from 'react';
import {appService} from '../_services';
import Autosuggest from 'react-autosuggest';

const getServiceSuggestionValue = suggestion => suggestion.service.name;
const renderServiceSuggestion = suggestion => {
    return suggestion.service.active === true ? <div>
        {suggestion.service.name}
    </div> : null
};

class ServiceSearchBar extends Component {

    constructor(props) {
        super(props)
        this.state = {
            value: '',
            serviceSuggestions: []
        }
    }

    onServiceNameChange = (e, {newValue}) => {
        this.props.clearState && this.props.clearState()
        this.props.clearPlace()
        this.setState({
            value: newValue
        });
    };

    onSuggestionsFetchRequested = ({value}) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        if (inputLength === 0)
            return []

        appService.getAllServices(0, '', inputValue).then(services => {
            this.setState({
                serviceSuggestions: services
            });
        })

    };

    onSuggestionSelected = (event, {suggestion, suggestionValue, suggestionIndex, sectionIndex, method}) => {
        this.props.onServiceSelect(suggestion)
    }

    onSuggestionsClearRequested = () => {
        this.setState({
            serviceSuggestions: []
        });
    };
    focusValue = (e) => {
        e.target.setSelectionRange(0, e.target.value.length);

    }

    render() {
        const {value, serviceSuggestions} = this.state;

        const inputProps = {
            // eslint-disable-next-line
            placeholder: this.props.place && this.props.place != '' ? this.props.place : 'Search a name',
            // eslint-disable-next-line
            value: !this.props.mode && this.props.place && this.props.place != '' ? this.props.place : value,
            type: 'search',
            onChange: this.onServiceNameChange,
            className: "form-control",
            id: this.props.id,
            onClick: this.focusValue

        };


        return (
            <Autosuggest
                suggestions={serviceSuggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={getServiceSuggestionValue}
                renderSuggestion={renderServiceSuggestion}
                onSuggestionSelected={this.onSuggestionSelected}
                inputProps={inputProps}
            />
        )
    }

}

export default ServiceSearchBar