import { userConstants } from '../_constants';

 
export function customers(state = {}, action) {
  switch (action.type) {
    case userConstants.GET_ALL_USERS_REQUEST:
      return {
        customers: [],
        customersLoaded: false, 
      }
    case userConstants.GET_ALL_USERS_SUCCESS:
      return { 
        customers: action.customers,
        customersLoaded: true, 
      };
    case userConstants.GET_ALL_USERS_FAILURE:
      return {
        customers: [],
        customersLoaded: true, 
      };

     case userConstants.MERGE_CUSTOMER_REQUEST:
        return {
        mergeCustomer: [] 
      };

    case userConstants.GET_MERGE_CUSTOMER:
        return {
          mergeCustomer: action.mergeCustomer
      };

    case userConstants.UPDATE_PASSWORD_SUCCESS:
      return {
          passwordUpdated: true
      };

    case userConstants.SAVE_CUSTOMER_NOTE:
      return state

     case userConstants.REQUEST_FORMULA_DATA:
        return {
          formulass: []

        }

      case userConstants.FETCH_FORMULA_DATA:

            return { 
              formulass: action.formula
              
            };
        

        case userConstants.FAIL_FORMULA_DATA:
         return { 
              formulass: [] 
            };
       case userConstants.REQUEST_SAVE_FORMULA:
          return {
            ...state,
            formulass: state.formulass == null ? [] : [...state.formulass],
             saved: false
          }
       case userConstants.SAVE_FORMULA:
          console.log(action.datas)
           return {
            ...state,
            formulass: [...state.formulass,action.datas],
            saved: true

           }

       case userConstants.GET_ALL_TASK_REQUEST:
          return {
            tasks: []
          }  
       case userConstants.GET_ALL_TASK_SUCCESS:
          return {
            tasks: action.task
          }    

      case userConstants.UPDATE_REQUEST_FOR_FORMULA:
           return {
             formulass: [...state.formulass],
             updateStatus: false, 
          }  

         

      case userConstants.UPDATE_CUSTOMER_FORMULA:
         const formulas =  state.formulass.map(formula=>{
                if(formula.id === action.id){
                  return {
                    ...formula,
                    user: action.data.user,
                    type: action.data.type,
                    technician: action.data.technician,
                    notes: action.data.notes,
                    lastUseTime: action.data.lastUseTime
                  }
                }

                return formula
         });



        return{
          formulass: formulas,
          updateStatus: true, 
        }
        

      default:
        return state

    
  }
}