/* eslint-disable */
import React from "react";
import {connect} from "react-redux";
import {inventoryActions} from "../../_actions";
import {appService, inventoryService} from "../../_services";
import Footer from "../footer/footer";
import taskImg from "../../task.png";
import AddTask from "../../_components/addTask";
import {userService} from "../../_services";
import InventoryActivity from "../../_components/inventoryActivity";
import {helperFunctions} from "../../_helpers";
import AddNewProduct from "../../_components/addNewProduct";
import SilckCarousel from "../../_components/slick-carousel/SilckCarousel";
import RcDropZone from "../../_components/rc-dropzone";
import ProductPreview from '../../_components/inventory/product-preview'


import './inventory.css'

import InventoryTable from '../../_components/inventory/inventory-table'


class Inventory extends React.Component {
    constructor(props) {
        super(props);
        this.app = JSON.parse(localStorage.getItem("app"));


        this.state = {
            pId: "",
            count: 0,
            brndId: "",
            pageSize: 30,
            searchQuery: "",
            pageOffset: 0,
            activityOffset: 0,
            customerType: "all",
            brndname: "",
            taskSelected: {
                taskSelectedProduct: "",
                taskSelectedItemId: "",
            },
            salePrice: "",
            updatedSPrice: "",
            productDetails: {},
            images: [],
            loading: false,
            activities: "",
            newProduct: "",
            attributes: "",
            variantId: "",
            upc: "",
            msrp: "",
            quantity: "",
            size: "",
            inventoryId: [],
            activitiesList: "",
            place: "",
            addProductModal: false,
            typeOfAction: 'productOnly',
            selectedFiles: undefined,
            addImage: false,
            productId: '',
            uploading: false
        };
        this.loadProduct();

    }

    loadProduct = () => {
        const {dispatch} = this.props;
        const {pageOffset, brndId, pageSize, searchQuery} = this.state;
        dispatch(inventoryActions.getAllProducts(pageOffset, brndId, pageSize, searchQuery));
    };

    componentDidMount = async () => {
        const metaInfo = await appService.checkVersion();
        await helperFunctions.clearCache(metaInfo)
        window.sidebarInitialize();
        window.sidebarClose();
    }

    clickHandlerToIncrease(e) {
        e.preventDefault();
        this.setState({
            count: this.state.count + 1,
        });
    }

    clickHandlerToDecrease(e) {
        e.preventDefault();
        this.setState({
            count: this.state.count - 1,
        });
    }

    //onchange handler
    changeHandler = (e) => {
        const {name, value} = e.target;
        if (name == "attributes") {
            const v = this.state.newProduct.find(
                (n) =>
                    n.mappedVisualAttributeValues.Size == e.target.value ||
                    n.mappedVisualAttributeValues.Option == e.target.value
            );
            this.setState({
                ...this.state,
                [name]: e.target.value == 0 ? "" : value,
                upc: e.target.value == 0 ? "" : v.upc,
                msrp: e.target.value == 0 ? "" : v.msrp,
                salePrice: e.target.value == 0 ? "" : v.salePrice,
                quantity: e.target.value == 0 ? "" : v.quantity,
                variantId: e.target.value == 0 ? "" : v.id,
            });
        } else {
            this.setState({
                ...this.state,
                [name]: value,
            });
        }
    };

    //save task
    saveTask = (taskJSON) => {
        userService
            .saveTask(taskJSON)
            .then((res) => {
                $("#addingTask").modal("toggle");
                window.DataUpdateSuccess();
            })
            .catch((e) => {
                e.json().then((err) => {
                    console.log(err.message);
                });
            });
    };

    viewDetails = (id) => {
        //inventory-activities?itemId={}&page={}&pageCount={}

        this.setState({
            loading: true,
        });

        inventoryService.getViewSpecificProduct(id).then((product) => {
            const details = {};
            details.name = product.variant.product.name;
            details.brand = product.variant.product.brand.name;
            details.quantity = product.quantity;
            details.msrp = product.variant.msrp;
            details.salePrice = product.salePrice;
            details.size = product.variant.mappedVisualAttributeValues.Size;
            this.setState({
                ...this.state,
                productDetails: details,
                pId: id,
            });
        });

        inventoryService
            .getProductActivities(id, this.state.activityOffset, 7)
            .then((activities) => {
                console.log(activities);
                this.setState({
                    activities,
                    loading: false,
                });
            });
    };

    getAllProducts = () => {
        const {dispatch} = this.props;
        dispatch(inventoryActions.getAllProducts(0));
    };

    addNewProduct = (e) => {
        e.preventDefault();
        const {dispatch} = this.props;
        this.setState({
            loading: true,
        });
        const variantJSON = {
            location: {
                id: this.app.locations[0].id,
            },
            variant: {
                id: this.state.variantId,
            },
            salePrice: this.state.salePrice,
            quantity: this.state.quantity,
        };

        inventoryService
            .addNewProduct(variantJSON)
            .then((res) => {
                dispatch(inventoryActions.getAllProducts(this.state.pageOffset, ""));

                this.clearState();

                this.setState({
                    loading: false,
                    place: "Type a product name",
                });
                $("#addProduct").modal("toggle");
                window.DataUpdateSuccess();
            })
            .catch((e) => {
                this.setState({
                    loading: false,
                });
                Swal.fire({
                    title: "Warning",
                    text: `LocatedProductVariant already exists!`,
                    type: "warning",
                    showCancelButton: false,
                    confirmButtonText: "Ok",
                });
            });
    };

    clearState = () => {
        this.setState({
            brndId: "",
            pId: "",
            name: "",
            upc: "",
            msrp: "",
            salePrice: "",
            quantity: "",
            variantId: "",
            variant: "",
            brndname: "",
            newProduct: "",
            attributes: "",
            addProductModal: false,
        });
    };

    deleteProduct = (id, name) => {
        const {dispatch} = this.props;
        Swal.fire({
            title: "Delete Product",
            text: `Are you sure you want to delete the product, ${name}?`,
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, keep it",
        }).then((result) => {
            if (result.value) {
                inventoryService
                    .deleteProduct(id)
                    .then(() => {
                        window.toast(
                            "error",
                            `Product ${name} has been deleted from the system`
                        );
                        dispatch(
                            inventoryActions.getAllProducts(
                                this.state.pageOffset,
                                this.state.brndId
                            )
                        );
                    })
                    .catch((e) => {
                        e.json().then((err) => {
                            Swal.fire({
                                title: "Error",
                                text: `${err.message}`,
                                type: "warning",
                                showCancelButton: false,
                                confirmButtonText: "Ok",
                            });
                        });
                    });
            }
        });
    };

    openUpdateModal = (v) => {
        this.setState({
            upc: v.variant.upc,
            msrp: v.variant.msrp,
            salePrice: v.salePrice,
            quantity: v.quantity,
            variantId: v.id,
            name: v.variant.product.name,
            size: v.variant.mappedVisualAttributeValues.Option
                ? v.variant.mappedVisualAttributeValues.Option
                : v.variant.mappedVisualAttributeValues.Size,
            brndname: v.variant.product.brand.name,
            pId: v.id,
        });
    };

    updateProduct = (e) => {
        e.preventDefault();
        const {dispatch} = this.props;
        this.setState({
            loading: true,
        });
        const upJSON = {
            location: {
                id: this.app.locations[0].id,
            },
            variant: {
                id: this.state.variantId,
            },
            salePrice: this.state.salePrice,
            quantity: this.state.quantity,
        };
        inventoryService
            .updateProduct(this.state.pId, upJSON)
            .then((res) => {
                dispatch(
                    inventoryActions.getAllProducts(
                        this.state.pageOffset,
                        this.state.brndId
                    )
                );
                this.clearState();
                this.setState({
                    loading: false,
                });
                $("#editVariant").modal("toggle");
                window.DataUpdateSuccess();
            })
            .catch((e) => {
                this.setState({
                    loading: false,
                });
                Swal.fire({
                    title: "Warning",
                    text: `The item is not updated!`,
                    type: "warning",
                    showCancelButton: false,
                    confirmButtonText: "Ok",
                });
            });
    };

    closeThisModal = () => {
        this.setState({
            place: "Type a product name",
            brnId: "",

        });
        this.clearState();
    };

    onProductSelect = (newProduct) => {
        this.setState({
            newProduct: newProduct.variants,
        });
    };

    addProductIntake = (data) => {
        inventoryService.addProductIntake(data).then((d) => {
            window.DataUpdateSuccess();
            this.setState({
                inventoryId: [...this.state.inventoryId, d.id],
            });
        });
    };
    clearPlace = () => {
        this.setState({
            place: "",
        });
    };


    editProduct = (p) => {
        this.setState({productDetails: p, images: p.variant.imageUrls})
    }


    openImagePicker = (productId) => {

        $('#addProduct').modal("toggle");
        this.setState({addProductModal: false})

        Swal.fire({
            title: "Product Created",
            text: `Product created. Would you like to add image(s) with this product?`,
            type: "success",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No, later",
        }).then(result => {
            if (result.value) {
                this.setState({addImage: true, productId})
                $('#productEditModal').modal('toggle')
            }
        })
    }

    deleteProductImages = async (imageIndex) => {
        Swal.fire({
            title: "Delete Image",
            text: `Are you sure you want to delete the image?`,
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, keep it",
        }).then(async result => {
            if (result.value) {
                const result = await inventoryService.removeImage(this.state.productDetails.variant.id, imageIndex);
                if (result.status === 200) {

                    this.setState(prevState => {
                        prevState.images.splice(imageIndex, 1)
                        return {
                            ...prevState,
                            images: prevState.images,
                            selectedFiles: undefined
                        }
                    })
                    this.loadProduct();
                } else {

                }
            }
        })

    }

    openProductDetails = (id) => {
        this.setState({productId: id})
        $('#productEditModal').modal('toggle')
    }


    render() {
        const {products, productsLoaded} = this.props;
        const {productDetails, activities} = this.state;


        var dupHtml = [];

        for (var i = 0; i < this.state.count; i++) {
            dupHtml.push(
                <div key={i} className="increased-content">
                    <hr/>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group">
                                <div>
                                    <input
                                        placeholder="UPC"
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <div>
                                    <input
                                        placeholder="MSRP"
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <div>
                                    <input
                                        placeholder="Salon Price"
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <div>
                                    <select className="form-control">
                                        <option>Choose attributes</option>
                                        <option>Size</option>
                                        <option>Box</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <div>
                                    <input
                                        placeholder="weight/qty"
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="duplicate-form-btn decrease-btn">
                        <button
                            onClick={this.clickHandlerToDecrease.bind(this)}
                            className="duplication btn btn-default btn-circle btn-lg btn-outline"
                        >
                            <i className="fa fa-minus"></i>
                        </button>
                    </div>
                </div>
            );
        }

        return (
            <div id="page-wrapper">
                <div className="search-sidebar">
                    <div className="closebtn">
                        <button>
                            <i className="fa fa-close"></i>
                        </button>
                    </div>
                    <div>
                        <div className="form-group">
                            <input
                                value={this.state.searchQuery}
                                type="text"
                                onChange={this.changeHandler}
                                className="form-control input-sm search-field"
                                name="searchQuery"
                                placeholder="Product Name, Price Or Size"
                            />
                        </div>
                    </div>
                    <div className="or">OR</div>
                    <div>
                        <div className="form-group">
                            <select
                                name="brndId"
                                onChange={this.changeHandler}
                                value={this.state.brndId}
                                className="search-option form-control inventory"
                            >
                                <option value="all">Select A Brand</option>
                                {this.app &&
                                    this.app.brands.map((b, key) => {
                                        return (
                                            <option key={key} value={b.id}>
                                                {b.name}
                                            </option>
                                        );
                                    })}
                            </select>
                            <button
                                className="btn btn-block btn-custom-info btn-sm"
                                onClick={() => {
                                    this.searchProducts();
                                }}
                            >
                                <i className="fa fa-search"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row bg-title search-row-mobile">
                        <div className="mobile-search-option">
                            <div className="paged">
                                <ul className="pager">
                                    <li>
                                        <a
                                            onClick={() => {
                                                this.getPreviousPage();
                                            }}
                                        >
                                            <i className="fa fa-angle-left"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            onClick={() => {
                                                this.getNextPage();
                                            }}
                                        >
                                            <i className="fa fa-angle-right"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div className="">
                                <button className="sidebar-trigger">
                                    <i className="fa fa-filter"></i>
                                </button>
                            </div>
                            <div className="clear-filter">
                                <button
                                    className="btn btn-warning"
                                    onClick={() => this.resetFilters()}
                                >
                                    Clear Filter
                                </button>
                            </div>
                            <div className="add-customer">
                                <a
                                    onClick={() => this.setState({addProductModal: true})}
                                    href="#addCustomer"
                                    className="btn btn-custom-info"
                                    data-toggle="modal"
                                >
                                    + Add{" "}
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="row bg-title custom-header desktop-search">
                        <div className="col-lg-1 col-md-2 col-sm-2 col-xs-12">
                            <h4 className="page-title">Inventory</h4>
                        </div>
                        <div className="col-lg-11 col-md-11 col-sm-11 col-xs-11">
                            <div className="row">
                                <div className="col-md-3 col-lg-3 col-xs-7">
                                    <div className="form-group">
                                        <input
                                            value={this.state.searchQuery}
                                            type="text"
                                            onChange={this.changeHandler}
                                            className="form-control input-sm search-field"
                                            name="searchQuery"
                                            placeholder="Product Name, Price Or Size"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-2 col-sm-3 col-lg-2 col-xs-6 text-right">
                                    <select
                                        name="brndId"
                                        onChange={this.changeHandler}
                                        value={this.state.brndId}
                                        className="search-option form-control"
                                    >
                                        <option value="all">Select A Brand</option>
                                        {this.app &&
                                            this.app.brands.map((b, key) => {
                                                return (
                                                    <option key={key} value={b.id}>
                                                        {b.name}
                                                    </option>
                                                );
                                            })}
                                    </select>
                                </div>

                                <div className="col-md-1 col-sm-3 col-lg-1 col-xs-5">
                                    <button
                                        className="btn btn-info btn-sm"
                                        onClick={() => {
                                            this.searchProducts();
                                        }}
                                    >
                                        Search
                                    </button>
                                </div>

                                <div className="col-md-2 col-sm-3 col-lg-2 col-xs-6">
                                    <button
                                        onClick={this.resetFilters}
                                        className="btn btn-warning btn-sm"
                                    >
                                        Clear Filters
                                    </button>
                                </div>
                                <div className="col-md-2 col-sm-3 col-lg-2 col-xs-6 text-right">
                                    <button
                                        onClick={() => window.addRow()}
                                        data-target="#activity"
                                        className="btn btn-custom-info pull-right"
                                        data-toggle="modal"
                                    >
                                        New Activity
                                    </button>
                                </div>

                                <div className="col-md-2 col-sm-3 col-lg-2 col-xs-6 text-right">
                                    <div className="pagination-wrapper">
                                        <ul className="pager">
                                            <li>
                                                <a
                                                    onClick={this.getPreviousPage}
                                                    style={{cursor: 'pointer'}}
                                                >
                                                    <i className="fa fa-angle-left"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a onClick={this.getNextPage} style={{cursor: 'pointer'}}>
                                                    <i className="fa fa-angle-right"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="white-box" style={{minHeight: 'calc(100vh - 100px)'}}>
                                <div className="overflow-fix">
                                    <div className="pull-left">
                                        <h2>
                                            {this.state.brndname != "" ? this.state.brndname : ""}
                                        </h2>
                                    </div>

                                    <div className="pull-right">
                                        <button
                                            onClick={() => this.setState({addProductModal: true})}
                                            className="btn btn-custom-info"
                                            data-target="#addProduct"
                                            data-toggle="modal"
                                        >
                                            Add Product
                                        </button>
                                        <div className="switcher-spacer"></div>
                                    </div>
                                </div>


                                <div>
                                    <InventoryTable productUpdateHandler={this.loadProduct}
                                                    detailsView={this.openProductDetails}/>
                                </div>

                                {products && productsLoaded == true && (
                                    <div className="table-responsive table-inventory">
                                        <table className="table table-bordered table-condensed">


                                            <tbody id="editable-input">
                                            {products &&
                                                productsLoaded == true &&
                                                products.length > 0 &&
                                                products.map((p, key) => {
                                                    return (
                                                        <tr key={key}>
                                                            <td className="d-flex">
                                                                <strong>{p.variant.product.name}</strong>
                                                            </td>
                                                            <td>
                                                                {p.variant.mappedVisualAttributeValues.Size}
                                                            </td>
                                                            <td>{p.variant.upc}</td>
                                                            <td>{p.quantity}</td>
                                                            <td>{(p.variant.msrp * 1).toFixed(2)}</td>
                                                            <td>
                                                                <a
                                                                    onClick={() => this.openUpdateModal(p)}
                                                                    href="#editVariant"
                                                                    data-toggle="modal"
                                                                >
                                                                    {(p.salePrice * 1).toFixed(2)}
                                                                </a>
                                                            </td>
                                                            <td align="center">
                                                                <a
                                                                    onClick={() => this.viewDetails(p.id)}
                                                                    title="Details"
                                                                    className="btn btn-outline btn-circle btn-lg btn-info"
                                                                    data-toggle="modal"
                                                                    href="#details"
                                                                >
                                                                    <i className="fa fa-eye"></i>
                                                                </a>
                                                                <a
                                                                    onClick={() => this.editProduct(p)}
                                                                    title="Edit"
                                                                    className="btn btn-outline btn-circle btn-lg btn-primary"
                                                                    data-toggle="modal"
                                                                    href="#editProduct"
                                                                >
                                                                    <i className="fa fa-edit"></i>
                                                                </a>
                                                                <button
                                                                    onClick={() =>
                                                                        this.deleteProduct(
                                                                            p.id,
                                                                            p.variant.product.name
                                                                        )
                                                                    }
                                                                    title="Delete Product"
                                                                    className="btn btn-lg btn-circle btn-danger btn-outline"
                                                                >
                                                                    <i className="fa fa-trash"></i>
                                                                </button>
                                                                <button
                                                                    onClick={() =>
                                                                        this.taskSelectedProduct(p.id)
                                                                    }
                                                                    title="Add Task"
                                                                    data-toggle="modal"
                                                                    data-target="#addingTask"
                                                                    className="btn btn-outline btn-lg btn-circle btn-info"
                                                                >
                                                                    <img width="15" src={taskImg} alt="task"/>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <InventoryActivity
                    getAllProducts={this.getAllProducts}
                    activitiesList={this.state.activitiesList}
                    addProductIntake={this.addProductIntake}
                    finalizeActivity={this.finalizeActivity}
                />

                <div
                    className="modal fade"
                    id="details"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel1"
                >
                    <div className="modal-dialog  modal-inventory" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                                <h4 className="modal-title" id="exampleModalLabel1">
                                    PRODUCT DETAILS:
                                </h4>
                            </div>

                            <div className="modal-body modal-full-height">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="switcher-spacer"></div>
                                    </div>
                                    {Object.keys(productDetails).length > 0 && (
                                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                            <h4 className="text-uppercase">
                                                <small className="weight-600">
                                                    {productDetails.name}
                                                </small>{" "}
                                                <small className="muted">({productDetails.size})</small>
                                            </h4>

                                            <br/>
                                            <div className="white-box">
                                                <div className="product-img">
                                                    <img
                                                        src={`http://thecoderteam.com/plugins/images/chair1.jpg`}
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="product-text">
                          <span className="pro-price bg-info bg-warning buy-price">
                            ${productDetails.msrp}
                          </span>
                                                    <span className="pro-price bg-info sell-price">
                            ${productDetails.salePrice}
                          </span>
                                                    <span className="qty">
                            <b>Qty: </b>
                                                        {productDetails.quantity}
                          </span>
                                                    <small className="box-title m-b-0">
                                                        {productDetails.brand}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    <div className="col-md-9">
                                        <h3>ACTIVITIES: </h3>
                                        <br/>
                                        <div className="table-responsive">
                                            <div className="pagination-wrapper">
                                                <ul className="pager">
                                                    <li>
                                                        <a
                                                            onClick={this.getPreviousActivities}
                                                            style={{cursor: 'pointer'}}
                                                        >
                                                            <i className="fa fa-angle-left"></i>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            onClick={this.getNextActivities}
                                                            style={{cursor: 'pointer'}}
                                                        >
                                                            <i className="fa fa-angle-right"></i>
                                                        </a>
                                                    </li>
                                                </ul>
                                                <hr/>
                                            </div>

                                            <table
                                                className="table table-condensed contact-list"
                                                data-page-size="7"
                                            >
                                                <thead>
                                                <tr>
                                                    <th>Staff</th>
                                                    <th>Date</th>
                                                    <th>Activity</th>
                                                    <th>Status</th>
                                                    <th>Qty</th>
                                                    <th>Invoice</th>
                                                    <th>Reason</th>
                                                </tr>
                                                </thead>

                                                <tbody id="order-list">
                                                {activities.length == 0 && (
                                                    <tr>
                                                        <td align="center" colSpan="7">
                                                            <h3>Records Not Found!</h3>
                                                        </td>
                                                    </tr>
                                                )}
                                                {activities.length > 0 &&
                                                    activities.map((a, k) => {
                                                        return (
                                                            <tr key={k}>
                                                                <td>
                                                                    <img
                                                                        className="img-circle"
                                                                        width="30"
                                                                        src={a.createUser.imageUrl}
                                                                        alt=""
                                                                    />{" "}
                                                                    {a.createUser.names.nick}
                                                                </td>
                                                                <td>
                                                                    {helperFunctions.formatDateTimeWithDay(
                                                                        moment(a.updatedTime),
                                                                        "latest"
                                                                    )}
                                                                </td>
                                                                <td>{a.type}</td>
                                                                <td>{a.status}</td>
                                                                <td>{a.quantity}</td>
                                                                <td>
                                                                    {a.invoiceNumber != ""
                                                                        ? a.invoiceNumber
                                                                        : "---"}
                                                                </td>
                                                                <td>
                                                                    Reason
                                                                    <div className="full-activity-reason">
                                                                        <p>
                                                                            Lorem Ipsum has been the industry's
                                                                            standard dummy text ever since the
                                                                            1500s, when an unknown
                                                                        </p>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {this.state.loading == true && (
                                <div className="custom-loading">
                                    <div className="loading">
                                        <i className="fa fa-spinner fa-spin"></i>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div
                    data-backdrop="static"
                    data-keyboard="false"
                    className="modal fade"
                    id="addProduct"
                    role="dialog"
                    aria-labelledby="exampleModalLabel1"
                >
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    onClick={this.closeThisModal}
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                                <h4 className="modal-title" id="exampleModalLabel1">
                                    ADD PRODUCT
                                </h4>
                            </div>

                            <div className="modal-body" style={{position: "relative"}}>
                                {this.state.addProductModal === true && (
                                    <AddNewProduct createNew={this.state.addProductModal}
                                                   refetchProduct={this.loadProduct} addImage={this.openImagePicker}/>
                                )}
                            </div>

                            {this.state.loading == true && (
                                <div className="custom-loading">
                                    <div className="loading">
                                        <i className="fa fa-spinner fa-spin"></i>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div
                    data-backdrop="static"
                    data-keyboard="false"
                    className="modal fade"
                    id="editVariant"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel1"
                >
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    onClick={this.closeThisModal}
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                                <h4 className="modal-title" id="exampleModalLabel1">
                                    EDIT PRODUCT
                                </h4>
                            </div>

                            <div className="modal-body">
                                <div className="product-form">
                                    <form className="form-horizontal">
                                        <div className="form-group">
                                            <label className="control-label col-md-2">
                                                Select Brand:{" "}
                                            </label>
                                            <div className="col-md-10">
                                                <input
                                                    type="text"
                                                    defaultValue={this.state.brndname}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="control-label col-md-2">
                                                Product Name:{" "}
                                            </label>
                                            <div className="col-md-10">
                                                <input
                                                    readOnly
                                                    className="form-control"
                                                    defaultValue={this.state.name}
                                                />
                                            </div>
                                        </div>

                                        <div className="space-top2"></div>

                                        <div className="variant-form">
                                            <label className="control-label col-md-2">
                                                Variant:{" "}
                                            </label>

                                            <div className="col-md-10">
                                                <div className="variant-content" id="variant-wrap">
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <div>
                                                                    <small>UPC</small>
                                                                    <input
                                                                        readOnly
                                                                        onChange={this.changeHandler}
                                                                        value={
                                                                            this.state.upc != "" && this.state.upc
                                                                        }
                                                                        type="text"
                                                                        name="upc"
                                                                        className="form-control"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <div>
                                                                    <small>MSRP</small>
                                                                    <input
                                                                        readOnly
                                                                        onChange={this.changeHandler}
                                                                        placeholder="MSRP"
                                                                        name="msrp"
                                                                        value={
                                                                            this.state.msrp != "" && this.state.msrp
                                                                        }
                                                                        type="text"
                                                                        className="form-control"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <div>
                                                                    <small>SALE PRICE</small>
                                                                    <input
                                                                        readOnly
                                                                        name="salePrice"
                                                                        onChange={this.changeHandler}
                                                                        value={this.state.salePrice}
                                                                        type="text"
                                                                        className="form-control"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <div>
                                                                    <input
                                                                        readOnly
                                                                        onChange={this.changeHandler}
                                                                        type="text"
                                                                        name="size"
                                                                        value={
                                                                            this.state.size != "" && this.state.size
                                                                        }
                                                                    />
                                                                    <small>SIZE / BOX</small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <div>
                                                                    <input
                                                                        readOnly
                                                                        name="quantity"
                                                                        onChange={this.changeHandler}
                                                                        value={this.state.quantity}
                                                                        type="text"
                                                                        className="form-control"
                                                                    />
                                                                    <small>WEIGHT / QUANTITY</small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group text-right">
                                            <button
                                                onClick={this.updateProduct}
                                                className="btn btn-custom-info"
                                            >
                                                Update Product
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            {this.state.loading == true && (
                                <div className="custom-loading">
                                    <div className="loading">
                                        <i className="fa fa-spinner fa-spin"></i>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div
                    className="modal fade"
                    id="addingTask"
                    tabIndex="-1"
                    role="dialog"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                                <h4 className="modal-title">Add Task</h4>
                            </div>

                            <div className="modal-body">
                                <AddTask
                                    statas="disabled"
                                    predefinedRelatedItem={
                                        this.state.taskSelected.taskSelectedProduct
                                    }
                                    predefinedRelatedId={
                                        this.state.taskSelected.taskSelectedItemId
                                    }
                                    predefinedRelatedTo="Product"
                                    saveTask={this.saveTask}
                                />
                            </div>
                        </div>
                    </div>
                </div>


                <div
                    className="modal fade"
                    id="productEditModal"
                    tabIndex="-1"
                    role="dialog"
                    aria-hidden="true"
                >

                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    className="close"
                                    type="button"
                                    data-dimiss="modal"
                                    aria-label="close"
                                    onClick={() => {
                                        this.setState({productId: ''}), $('#productEditModal').modal("toggle")
                                    }}
                                >&times;</button>
                                <h4 className="modal-title">Product Preview and Update</h4>
                            </div>

                            <div className="modal-body">
                                {this.state.productId !== '' &&
                                    <ProductPreview product id={this.state.productId} bsnsId={this.app.id}
                                                    refetchProduct={this.loadProduct}/>}
                            </div>
                        </div>

                    </div>

                </div>


                <div
                    className="modal fade"
                    id="editProduct"
                    tabIndex="-1"
                    role="dialog"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => this.setState({
                                        productDetails: {},
                                        images: [],
                                        selectedFiles: undefined
                                    })}
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                                <h4 className="modal-title">Edit Product</h4>
                            </div>

                            <div className="modal-body" style={{height: '650px'}}>
                                {/* --------Edit Products---------- */}
                                <div className="customer-details-tab">
                                    <ul className="nav nav-tabs tabs customtab">
                                        {this.state.addImage !== true &&
                                            <li data-toggle="tab" data-target="#a" className="active">
                                                <a onClick={() => this.setState({typeOfAction: 'productOnly'})}
                                                   href="#">INFO</a>
                                            </li>
                                        }
                                        {this.state.addImage !== true &&
                                            <li data-toggle="tab" data-target="#b">
                                                <a onClick={() => this.setState({typeOfAction: 'variantOnly'})}
                                                   href="#">VARIANTS</a>
                                            </li>}
                                        <li data-toggle="tab" data-target="#c"
                                            className={this.state.addImage === true ? 'active' : ''}>
                                            <a href="#">IMAGES</a>
                                        </li>
                                    </ul>

                                    <div className="tab-content">
                                        <div
                                            className={`tab-pane ${this.state.addImage !== true ? 'fade in active' : 'fade'} slickSetting`}
                                            id="a"
                                        >
                                            <div className="tech-add-form settings">
                                                <AddNewProduct
                                                    details={productDetails}
                                                    actionType={this.state.typeOfAction}
                                                    updateProduct={this.loadProduct}
                                                />
                                            </div>
                                        </div>
                                        <div className="tab-pane fade  slickSetting" id="b">
                                            <div className="tech-add-form settings">
                                                <AddNewProduct
                                                    details={productDetails}
                                                    actionType={this.state.typeOfAction}
                                                    refetchProduct={this.loadProduct}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={`tab-pane fade slickSetting ${this.state.addImage === true ? 'fade in active' : 'active fade in'}`}
                                            id="c">
                                            <div className="tech-add-form settings">

                                                {this.state.images.length > 0 && <h3>You can delete any images</h3>}
                                                <br/>

                                                <div>
                                                    <SilckCarousel images={this.state.images}
                                                                   removeImage={this.deleteProductImages}/>
                                                </div>


                                                <div className="image-picker">
                                                    <h3>You can add more images</h3>
                                                    <br/>

                                                    {this.state.uploading &&
                                                        <div className={"custom-loader"}
                                                             style={{width: '100%', height: '50%'}}>
                                                            <div className="loaderDiv">
                                                                <div className="loader"></div>
                                                            </div>
                                                        </div>
                                                    }

                                                    <RcDropZone selectedFiles={this.state.selectedFiles}
                                                                setSelectedFiles={(files) => this.setState({selectedFiles: files})}
                                                                details={productDetails}/>
                                                    <button onClick={this.updateImages}
                                                            className="btn btn-info btn-lg float-right"
                                                            style={{marginTop: '20px'}}>Add Images
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }

    searchProducts = () => {
        const {dispatch} = this.props;
        var brndId = this.state.brndId === "all" ? "" : this.state.brndId;
        this.loadProduct()
        this.setState({
            ...this.state,
            brndname:
                this.props.products &&
                this.props.products.length > 0 &&
                this.props.products[0].variant.product.brand.name,
        });
    };

    getNextPage = () => {
        var offset = this.state.pageOffset + 1;
        const {products, dispatch} = this.props;
        if (products && products.length != 0) {
            dispatch(
                inventoryActions.getAllProducts(
                    offset,
                    this.state.brndId,
                    this.state.searchQuery
                )
            );
            this.setState({pageOffset: offset});
        }
    };

    getPreviousPage = () => {
        var offset = this.state.pageOffset - 1;
        if (offset >= 0) {
            const {dispatch} = this.props;
            dispatch(
                inventoryActions.getAllProducts(
                    offset,
                    this.state.brndId,
                    this.state.searchQuery
                )
            );
            this.setState({pageOffset: offset});
        }
    };

    getNextActivities = () => {
        var offset = this.state.activityOffset + 1;
        const {activities, pId} = this.state;
        if (activities && activities.length != 0) {
            inventoryService
                .getProductActivities(pId, offset, 7)
                .then((activities) => {
                    this.setState({
                        activities,
                        activityOffset: offset,
                    });
                });
        }
    };

    getPreviousActivities = () => {
        var offset = this.state.activityOffset - 1;
        if (offset >= 0) {
            inventoryService
                .getProductActivities(this.state.pId, offset, 7)
                .then((activities) => {
                    this.setState({
                        activities,
                        activityOffset: offset,
                    });
                });
        }
    };

    resetFilters = () => {
        this.setState({pageOffset: 0});
        this.setState({brndId: ""});
        this.setState({searchQuery: ""});
        const {dispatch} = this.props;
        dispatch(inventoryActions.getAllProducts(0));
    };
    checkBrandName = (brndId) => {
        if (brndId != "") {
            const brand = this.app.brands.find((b) => b.id == brndId);
            if (brand != "") {
                return brand.name;
            }
        }
    };

    taskSelectedProduct = (id) => {
        let taskSelectedProduct = this.props.products.find((p) => p.id == id);

        this.setState({
            taskSelected: {
                ...this.state.taskSelected,
                taskSelectedProduct:
                    taskSelectedProduct.variant.product.name +
                    " (" +
                    taskSelectedProduct.variant.product.brand.name +
                    ")",
                taskSelectedItemId: id,
            },
        });
    };
}

function mapStateToProps(state) {
    const {products, productsLoaded} = state.inventory;

    return {
        products,
        productsLoaded,
    };
}

const connectedInventoryPage = connect(mapStateToProps)(Inventory);
export {connectedInventoryPage as Inventory};
