/* eslint-disable */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {userActions} from '../../_actions'
import {userService} from '../../_services'
import {appService} from '../../_services'
import {Link} from 'react-router-dom';
import {helperFunctions} from '../../_helpers';
import Footer from '../footer/footer'
import './technicians.css';
import taskImg from '../../task.png'
import AddTask from "../../_components/addTask";

import InlineMessage from '../../_components/inlineMessage';
import moment from 'moment';
import CreatableSelect from 'react-select/creatable';
import DatePicker from 'react-date-picker';
import Sorting from "../../_components/sorting";

class TechniciansPage extends Component {
    constructor(props) {
        super(props);
        this.app = JSON.parse(localStorage.getItem('app'));
        this.storeId = this.app.vanityId
        const {dispatch} = this.props;
        this.app = JSON.parse(localStorage.getItem('app'));
        dispatch(userActions.getAllTechnicians(0))
        var userRole = JSON.parse(localStorage.getItem('user')).role;
        this.MAN_DIREC = userRole == "MANAGER" || userRole == "DIRECTOR" || userRole == 'ADMIN' ? true : false;

        this.state = {
            pageOffset: 0,
            modalPageOffset: 0,
            ctgryId: '',
            team: '',
            searchQuery: '',
            taskSelected: {
                taskSelectedCustomer: '',
                taskSelectedItemId: ''
            },
            id: '',
            user: {
                firstName: '',
                lastName: '',
                nickName: '',
                email: '',
                mobileNumber: '',
                gender: 'Male',
                birthDate: new Date(),
                emailNotification: false,
                smsNotification: false,
                clockReminderEnabled: false,
                acceptClientRequestEnabled: false,
                customerBookingAllowed: false,
                customerAccessAllowed: true,
                autoLogoutEnabled: false,
                selfBookingEnabled: false,
                profilePic: '',
                nextBirthdayRewardDate: '',
                rewardBalance: 0,
                referralToken: this.app.referralToken,
                totalEarningsAsReferrer: '',
                instagramUrl: this.app.locations[0].id == 1 ? 'https://www.instagram.com/eterna_day_spa/' : this.app.locations[0].id == 2 ? 'https://www.instagram.com/cl9salon/' : this.app.locations[0].id == 3 ? 'https://www.instagram.com/salonh2omd/' : 'https://www.instagram.com/301studiobl/',
                accountStatus: 'Active',
                representationColor: '#000000',
                team: '',
                role: "TECHNICIAN",
                expertise: '',
                biography: '',
                specialties: [],
                password: '',
                cashPaymentPercentage: 0,
                technicianServices: [],
                productCommissionPercentage: 0,
                compensationStrategy: 'Commission',
                strategyWage: 0,


            },
            serviceAddTab: 'info',

            errors: {},

            allServices: '',
            selectedService: {},
            mode: '',
            loading: false,
            list: [],
            techs: [],
            cat: [],
            categories: []

        };

        appService.getAllServicesForStaffdetails(0).then(services => {
            this.setState({
                allServices: services
            })
        })

        this.handleChange = this.handleChange.bind(this);
        //fetch category
        this.getCategories();
    }

    getCategories() {
        appService.getCategories(0, 200).then(categories => {
            this.setState({categories})
            //this.initializeServices()
        }).catch(e => {
            console.log(e)
        })
    }

    closeServiceModal = () => {
        this.setState({
            id: ''
        })
    }

    handleChange(event) {
        const {name, value} = event.target;
        // eslint-disable-next-line
        const {user} = this.state;
        // eslint-disable-next-line
        if (name === "emailNotification") {
            this.setState({
                user: {
                    ...user,
                    emailNotification: !this.state.user.emailNotification
                }

            })

        }
        // eslint-disable-next-line
        else if (name === "smsNotification") {
            this.setState({
                user: {
                    ...user,
                    smsNotification: !this.state.user.smsNotification
                }

            })
        } else if (name === "customerBookingAllowed") {
            this.setState({
                user: {
                    ...user,
                    customerBookingAllowed: !this.state.user.customerBookingAllowed
                }

            })
        } else if (name === "clockReminderEnabled") {
            this.setState({
                user: {
                    ...user,
                    clockReminderEnabled: !this.state.user.clockReminderEnabled
                }

            })
        } else if (name === "acceptClientRequestEnabled") {
            this.setState({
                user: {
                    ...user,
                    acceptClientRequestEnabled: !this.state.user.acceptClientRequestEnabled
                }

            })
        } else if (name === "selfBookingEnabled") {
            this.setState({
                user: {
                    ...user,
                    selfBookingEnabled: !this.state.user.selfBookingEnabled
                }

            })
        } else if (name === "customerAccessAllowed") {
            this.setState({
                user: {
                    ...user,
                    customerAccessAllowed: !this.state.user.customerAccessAllowed
                }

            })
        } else if (name === "autoLogoutEnabled") {
            this.setState({
                user: {
                    ...user,
                    autoLogoutEnabled: !this.state.user.autoLogoutEnabled
                }

            })
        } else {

            this.setState({
                [name]: value,
                user: {
                    ...user,
                    [name]: value
                }
            });
        }
    }

    handleSelect = selectedOption => {
        this.setState(
            {
                user: {...this.state.user, specialties: selectedOption}
            }
        )

    }

    componentDidMount = async () => {
        const metaInfo = await appService.checkVersion();
        await helperFunctions.clearCache(metaInfo)

        window.sidebarInitialize();
        window.sidebarClose();

    }

    initialRelatedMethods() {
        window.initiateCheckBoxes();
        window.specialitiesSuggestions();
        window.expertiseSuggestions();

    }

    saveTask = (taskJSON) => {

        userService.saveTask(taskJSON)
            .then(() => {
                $('#addingTask').modal('toggle');
                window.DataUpdateSuccess()
            })

    }

    saveStaffInfos = (e) => {

        e.preventDefault();

        const {user} = this.state;

        this.setState({
            loading: true
        })


        let specialities = [];
        if (user.specialties && user.specialties.length != 0) {
            for (let i = 0; i < user.specialties.length; i++) {
                specialities.push(user.specialties[i].label)
            }
        }

        const errors = this.validateData(user);
        this.setState({
            errors,
            loading: false
        });


        user.mobileNumber = user.mobileNumber.replace(' ', '').replace(')', '').replace('(', '').replace('-', '');
        const newUser = {
            location: {
                id: this.app.locations[0].id
            },

            user: {
                business: {
                    id: this.app.id
                },
                emailAddress: user.email,
                mobileNumber: user.mobileNumber,
                birthDate: helperFunctions.dateFormat(user.birthDate),
                names: {
                    first: user.firstName,
                    last: user.lastName,
                    nick: user.nickName
                },
                gender: user.gender,

                role: user.role,
                referredByUser: {
                    referralToken: user.referralToken
                },
                password: user.password,
                emailNotificationEnabled: user.emailNotification,
                smsNotificationEnabled: user.smsNotification


            },
            clockReminderEnabled: user.clockReminderEnabled,
            customerBookingAllowed: user.customerBookingAllowed,
            acceptClientRequestEnabled: user.acceptClientRequestEnabled,
            customerAccessAllowed: user.customerAccessAllowed,
            autoLogoutEnabled: user.autoLogoutEnabled,
            instagramUrl: user.instagramUrl,
            accountStatus: user.accountStatus,
            representationColor: user.representationColor,
            team: user.team,
            expertise: user.expertise,
            biography: user.biography,
            specialties: specialities,
            productCommissionPercentage: 0,//user.productCommissionPercentage,
            compensationStrategy: user.compensationStrategy,
            strategyWage: user.strategyWage,
            cashPaymentPercentage: user.cashPaymentPercentage,
            displayOrder: 100

        }

        if (Object.keys(errors).length == 0) {
            const {dispatch} = this.props;
            userService.saveStaffInfos(newUser).then(res => {

                this.resetState();

                this.setState({
                    ...this.state,
                    id: res.id,
                    errors: {}
                })
                dispatch(userActions.getAllTechnicians(0));
                window.DataUpdateSuccess();


            }).catch(e => {
                e.json().then(err => {
                    Swal.fire({
                        title: 'Warning',
                        text: `${err.message}.`,
                        type: 'warning',
                        showCancelButton: false,
                        confirmButtonText: 'Ok'
                    })
                })
            })
        }


    }

    getDuration = (timeB, timeS) => {

        const sum = moment(moment(timeB)).diff(moment());
        const minutes = parseInt(moment.duration(sum).asMinutes());

        return minutes
    }

    validateData = (user) => {

        const errors = {}
        if (!user.firstName) errors.firstName = 'Please fill first name';
        if (!user.lastName) errors.lastName = 'Please fill last name';
        if (!user.nickName) errors.nickName = 'Please fill nick name';
        if (!user.mobileNumber) errors.mobileNumber = 'Please enter mobile number';
        if (!user.email) errors.emailAddress = 'Please enter a valid email';
        if (!user.gender) errors.gender = 'Please select a gender';
        if (!user.birthDate) errors.birthDate = 'Please enter valid date of birth';
        if (user.birthDate) {
            let duration = this.getDuration(this.state.user.birthDate, '');
            if (duration > 0) return errors.birthDate = 'Please enter valid date of birth';
        }


        if (!user.representationColor) errors.representationColor = 'Please choose a color';
        if (!user.accountStatus) errors.accountStatus = 'Please select account status';
        if (!user.team) errors.team = 'Please select a team';
        if (!user.expertise) errors.expertise = 'Please select an expertise';
        if (!user.biography) errors.biography = 'Please provide biography';
        if (user.specialties.length == 0) errors.specialties = 'Please select at least a specialties';
        if (!user.instagramUrl) errors.instagramUrl = 'Please enter your instagram url';
        if (!user.productCommissionPercentage) errors.productCommissionPercentage = 'Please enter product commission percentage';
        if (!user.role) errors.role = 'Please assign a role to the staff';
        if (!user.referralToken) errors.referralToken = 'Referall token should not be empty!';
        if (!user.password) errors.password = 'Please provide a password';

        return errors;
    }


    changeTech = (e) => {
        this.setState({
            selectedService: {...this.state.selectedService, [e.target.name]: e.target.value}
        });
    }

    handleBirthDate = date => {
        this.setState({
            user: {
                ...this.state.user,
                birthDate: date
            }
        })
    };

    openRearrangeModal = () => {
        let list = []
        this.technicianSorting(this.app.locations[0].technicians.filter(t => t.active == true && t.bookable == true)).forEach((technician) => {
            list.push(
                {
                    content: (
                        <div className="tech-list"><img src={technician.user.imageUrl} alt={technician.user.fullName}/>
                            <p>{technician.user.names.nick}</p></div>), classes: [technician.id]
                }
            )
        })
        this.setState({
            list
        })


    }
    technicianSorting = (techs) => {
        return techs.sort((a, b) => a.displayOrder - b.displayOrder);

    }
    changeOrder = (items) => {
        let {dispatch} = this.props
        for (let i = 0; i < items.length; i++) {
            userService.changeOrder(items[i].classes[0], {displayOrder: i + 1}).then(res => {
                //console.log(res)
            })
        }

        this.setState({
            list: items
        })

        dispatch(userActions.getAllTechnicians(0))

    }


    render() {

        const {technicians, techLoading} = this.props;
        const {searchQuery, team, user, allServices, errors} = this.state;

        var options = [];
        if (this.app) {
            const option = this.app.lookupGroup.technicianSpecialties.map(res => {
                return {value: res, label: res}
            });

            var arg3 = options.concat(option)

        }


        return (
            <div id="page-wrapper">
                <div className="search-sidebar">
                    <div className="closebtn">
                        <button><i className="ti ti-close"></i></button>
                    </div>
                    <div>
                        <div className="form-group">
                            <input type="text" className="form-control input-sm" value={searchQuery} name="searchQuery"
                                   onChange={this.handleChange} placeholder="Name, Email or Phone"/>

                            <button className="btn btn-block btn-info btn-sm" onClick={() => {
                                this.searchTechnician()
                            }}><i className="ti ti-search"></i></button>
                        </div>
                    </div>
                    <div className="or">OR</div>
                    <div>
                        <div className="form-group">
                            <select className="form-control input-sm" value={team} name="team"
                                    onChange={this.handleChange}>
                                <option value="">Select Team</option>
                                <option value="all">All</option>
                                {this.app && this.app.lookupGroup.teams.map((team, key) => {
                                    return <option key={key} value={team}>{team}</option>
                                })}
                            </select>
                            <button className="btn btn-block btn-info btn-sm" onClick={() => {
                                this.searchTechnician()
                            }}><i className="ti ti-search"></i></button>
                        </div>
                    </div>
                </div>


                <div className="container-fluid page-title">

                    <div className="row bg-title search-row-mobile">

                        <div className="mobile-search-option">
                            <div className="paged">
                                <ul className="pager">
                                    <li><a onClick={() => {
                                        this.getPreviousPage()
                                    }}><i className="fa fa-angle-left"></i></a>
                                    </li>
                                    <li><a onClick={() => {
                                        this.getNextPage()
                                    }}><i className="fa fa-angle-right"></i></a>
                                    </li>
                                </ul>
                            </div>

                            <div className="search-option">
                                <button className="sidebar-trigger"><i className="fa fa-filter"></i></button>
                            </div>
                            <div className="clear-filter">
                                <button className="btn btn-warning" onClick={() => this.resetFilters()}>Clear Filter
                                </button>
                            </div>
                            <div className="add-customer">
                                <a href="#add-tech" onClick={() => window.initiateCheckBoxes()}
                                   className="btn btn-custom-info"
                                   data-toggle="modal">+ Add</a>
                            </div>

                        </div>

                    </div>

                    <div className="row bg-title desktop-search custom-header">
                        <div className="col-lg-1 col-md-4 col-sm-2 col-xs-12">
                            <h4 className="page-title">Staffs</h4>
                        </div>
                        <div className="col-md-9 col-sm-10">
                            <div className="row">
                                <div className="col-md-3 col-sm-3">
                                    <div className="form-group">

                                        <input type="text" className="form-control input-sm" value={searchQuery}
                                               name="searchQuery" onChange={this.handleChange}
                                               placeholder="Name, Email or Phone"/>

                                    </div>
                                </div>
                                <div className="col-md-2 col-sm-3">
                                    <div className="form-group">
                                        <select className="form-control input-sm" value={team}
                                                name="team" onChange={this.handleChange}>
                                            <option value="">Select Team</option>
                                            {this.app && this.app.lookupGroup.teams.map((team, key) => {
                                                return <option key={key} value={team}>{team}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-2 col-sm-2 col-lg-2 col-xs-6">
                                    <button className="btn btn-info btn-sm" onClick={() => {
                                        this.searchTechnician()
                                    }}>Search
                                    </button>

                                </div>

                                <div className="col-md-5 col-sm-4 col-lg-5 col-xs-6 text-right">
                                    <button onClick={() => this.resetFilters()}
                                            className="btn btn-warning btn-sm">Clear Filters
                                    </button>
                                    &nbsp;
                                    <a onClick={this.initialRelatedMethods} href="#add-tech"
                                       className="btn btn-custom-info" data-toggle="modal">New Staff</a>&nbsp;
                                    <a onClick={this.openRearrangeModal} href="#sorting"
                                       className="btn btn-custom-info" data-toggle="modal">Change Order</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <ul className="pager pull-right">
                                <li><a onClick={() => {
                                    this.getPreviousPage()
                                }}><i className="fa fa-angle-left"></i></a>
                                </li>
                                <li><a onClick={() => {
                                    this.getNextPage()
                                }}><i className="fa fa-angle-right"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row flex-row techs">


                        {
                            technicians && techLoading == false &&
                            <React.Fragment>
                                <div className="col-md-4 col-sm-6 col-xs-12 single-customer">
                                    <div className="loading"></div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12 single-customer">
                                    <div className="loading"></div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12 single-customer">
                                    <div className="loading"></div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12 single-customer">
                                    <div className="loading"></div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12 single-customer">
                                    <div className="loading"></div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12 single-customer">
                                    <div className="loading"></div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12 single-customer">
                                    <div className="loading"></div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12 single-customer">
                                    <div className="loading"></div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12 single-customer">
                                    <div className="loading"></div>
                                </div>
                            </React.Fragment>
                        }

                        {
                            technicians && techLoading == true && technicians.length == 0 &&
                            <h2 style={{minWidth: "100%", textAlign: "center"}}>No Technicians Found</h2>
                        }
                        {technicians && techLoading == true &&
                            technicians.map((a, key) => {
                                return <div key={key} className="col-md-4 col-sm-6">
                                    <div className="white-box custom-card custom-shadow">
                                        <div className="custom-white-box">
                                            <div className="user-thumb">
                                                <Link to={`/${this.storeId}` + "/update-technician?staffId=" + a.id}>
                                                    <img src={a.user.imageUrl} alt="user"
                                                         className="user-circle-thumb"/>
                                                </Link>

                                            </div>

                                            <div className="user-info">
                                                <div className="user-info-header">
                                                    <h3 className="box-title"><strong>{a.user.names.nick}</strong></h3>
                                                    <small>{a.expertise}</small>
                                                </div>
                                                <div className="info-main">
                                                    <p><i className="fa fa-birthday-cake"></i>
                                                        <span> {helperFunctions.formatDateTime(a.user.nextBirthdayRewardDateTime, 'date')}</span>
                                                    </p>
                                                    <p><i className="fa fa-calendar"></i>
                                                        <span> {helperFunctions.formatDateTimeWithDay(a.createdTime, 'latest')}</span>
                                                    </p>
                                                    <p><i className="fa fa-phone"></i> <span> <a
                                                        href={"tel:" + helperFunctions.formatMobilePhone(a.user.mobileNumber)}>{helperFunctions.formatMobilePhone(a.user.mobileNumber)}</a></span>
                                                    </p>
                                                </div>

                                            </div>
                                            <div className="add-task-btn">
                                                <button
                                                    onClick={() => this.taskSelectedTechnician(a.id)}
                                                    title="Add Task"
                                                    className="btn"
                                                    data-toggle="modal"
                                                    data-target="#addingTask">
                                                    <img width="20" src={taskImg} alt=""/>
                                                </button>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
                <Footer/>

                <div data-keyboard="false" data-backdrop="false" className="modal fade" id="add-tech">
                    <div className="modal-dialog  modal-custom-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button onClick={this.resetState} type="button" className="close" data-dismiss="modal"
                                        aria-label="Close"><span
                                    aria-hidden="true">&times;</span></button>
                                <h4 className="modal-title" id="exampleModalLabel1">Add New Staff</h4></div>

                            <div className="modal-body">

                                <div className="col-md-12 col-lg-12 col-xs-12">
                                    <div className="customer-details-tab">
                                        <ul className="nav nav-tabs tabs customtab">
                                            {this.state.id == '' &&
                                                <li className="tab active">
                                                    <a onClick={this.initializeRelativeMethod} href="#info"
                                                       data-toggle="tab"
                                                       aria-expanded="true"> <span className="visible-xs"><i
                                                        className="fa fa-cog"></i></span> <span
                                                        className="hidden-xs">Info</span>
                                                    </a>
                                                </li>
                                            }
                                            {this.state.id != '' &&
                                                <li className="tab">
                                                    <a onClick={this.closeServiceModal} href="#services"
                                                       data-toggle="tab" aria-expanded="true"> <span
                                                        className="visible-xs"><i className="ti ti-list"></i></span>
                                                        <span
                                                            className="hidden-xs">Services</span> </a>

                                                </li>
                                            }

                                        </ul>
                                        <div className="tab-content">
                                            {this.state.id == '' &&
                                                <div className="tab-pane active fade in" id="info">

                                                    <div className="tech-add-form settings">
                                                        <form style={{paddingTop: 0}}>
                                                            <div className="row">
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="fname">First Name &nbsp;  <span
                                                                            className="text-danger">*</span></label>
                                                                        <input
                                                                            required
                                                                            maxLength="40"
                                                                            className="form-control"
                                                                            type="text"
                                                                            name="firstName"
                                                                            value={user.firstName}
                                                                            onChange={this.handleChange}
                                                                        />
                                                                        <p className="text-danger error">{errors.firstName &&
                                                                            <InlineMessage
                                                                                text={errors.firstName}/>}</p>

                                                                    </div>
                                                                </div>

                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="lname">Last Name &nbsp; <span
                                                                            className="text-danger">*</span></label>
                                                                        <input
                                                                            required
                                                                            maxLength="40"
                                                                            className="form-control"
                                                                            type="text"
                                                                            name="lastName"
                                                                            value={user.lastName}
                                                                            onChange={this.handleChange}
                                                                        />
                                                                        <p className="text-danger error">{errors.lastName &&
                                                                            <InlineMessage text={errors.lastName}/>}</p>

                                                                    </div>
                                                                </div>

                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="nickName">Nick Name &nbsp; <span
                                                                            className="text-danger">*</span></label>
                                                                        <input
                                                                            required
                                                                            maxLength="40"
                                                                            className="form-control"
                                                                            type="text"
                                                                            name="nickName"
                                                                            id="nickName"
                                                                            value={user.nickName}
                                                                            onChange={this.handleChange}
                                                                        />
                                                                        <p className="text-danger error">{errors.nickName &&
                                                                            <InlineMessage text={errors.nickName}/>}</p>

                                                                    </div>
                                                                </div>

                                                            </div>


                                                            <div className="row">
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="email">Email Address &nbsp;<span
                                                                            className="text-danger">*</span></label>
                                                                        <input
                                                                            required
                                                                            maxLength="100"
                                                                            className="form-control"
                                                                            type="text"
                                                                            name="email"
                                                                            id="email"
                                                                            value={user.email}
                                                                            onChange={this.handleChange}
                                                                        />
                                                                        <p className="text-danger error">{errors.emailAddress &&
                                                                            <InlineMessage
                                                                                text={errors.emailAddress}/>}</p>

                                                                    </div>
                                                                </div>

                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="phoneNumber">Phone Number &nbsp;
                                                                            <span
                                                                                className="text-danger">*</span></label>
                                                                        <input
                                                                            required
                                                                            maxLength="20"
                                                                            className="form-control mobileNumber"
                                                                            type="tel"
                                                                            name="mobileNumber"
                                                                            id="phoneNumber"
                                                                            value={user.mobileNumber}
                                                                            onChange={this.handleChange}
                                                                        />
                                                                        <p className="text-danger error">{errors.mobileNumber &&
                                                                            <InlineMessage
                                                                                text={errors.mobileNumber}/>}</p>

                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="phoneNumber">Gender &nbsp; <span
                                                                            className="text-danger">*</span></label>
                                                                        <select
                                                                            required
                                                                            onChange={this.handleChange}
                                                                            className="form-control"
                                                                            name="gender"
                                                                            value={user.gender}>
                                                                            <option value="Male">MALE</option>
                                                                            <option value="Female">FEMALE</option>

                                                                        </select>
                                                                        <p className="text-danger error">{errors.gender &&
                                                                            <InlineMessage text={errors.gender}/>}</p>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-md-4">
                                                                    <div className="form-group birthDate">
                                                                        <label htmlFor="db">Date Of Birth &nbsp; <span
                                                                            className="text-danger">*</span></label>
                                                                        <div className="dateOfBrth">
                                                                            <DatePicker
                                                                                onChange={this.handleBirthDate}
                                                                                value={this.state.user.birthDate}
                                                                            />
                                                                        </div>
                                                                        <p className="text-danger error">{errors.birthDate &&
                                                                            <InlineMessage
                                                                                text={errors.birthDate}/>}</p>

                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="joinDate">Account Status &nbsp;
                                                                            <span
                                                                                className="text-danger">*</span></label>
                                                                        <select
                                                                            onChange={this.handleChange}
                                                                            required className="form-control"
                                                                            name="accountStatus"
                                                                            value={user.accountStatus}
                                                                        >
                                                                            <option value="Active">Active</option>
                                                                            <option value="Inactive">Inactive</option>

                                                                        </select>
                                                                        <p className="text-danger error">{errors.accountStatus &&
                                                                            <InlineMessage
                                                                                text={errors.accountStatus}/>}</p>

                                                                    </div>
                                                                </div>


                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="rep">Rep. Color &nbsp;  <span
                                                                            className="text-danger">*</span></label>
                                                                        <input
                                                                            className="form-control"
                                                                            type="color"
                                                                            name="representationColor"
                                                                            value={user.representationColor}
                                                                            onChange={this.handleChange}/>
                                                                        <p className="text-danger error">{errors.representationColor &&
                                                                            <InlineMessage
                                                                                text={errors.representationColor}/>}</p>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-md-3">
                                                                    <div className="form-group">
                                                                        <label htmlFor="team">Choose a team &nbsp; <span
                                                                            className="text-danger">*</span></label>
                                                                        <select
                                                                            required
                                                                            name="team"
                                                                            onChange={this.handleChange}
                                                                            className="form-control"
                                                                            value={user.team}>
                                                                            <option value="">Select Team</option>
                                                                            {this.app && this.app.lookupGroup.teams.map((t, key) => {
                                                                                return <option value={t}
                                                                                               key={key}>{t}</option>
                                                                            })}
                                                                        </select>
                                                                        <p className="text-danger error">{errors.team &&
                                                                            <InlineMessage text={errors.team}/>}</p>

                                                                    </div>
                                                                </div>

                                                                <div className="col-md-3">
                                                                    <div className="form-group">
                                                                        <label htmlFor="team">Referall Token &nbsp;<span
                                                                            className="text-danger">*</span></label>
                                                                        <input
                                                                            required
                                                                            readOnly
                                                                            name="referralToken"
                                                                            className="form-control"
                                                                            defaultValue={user.referralToken}/>
                                                                        <p className="text-danger error">{errors.referralToken &&
                                                                            <InlineMessage
                                                                                text={errors.referralToken}/>}</p>

                                                                    </div>
                                                                </div>


                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label htmlFor="instagramUrl">Instagram
                                                                            Url  &nbsp;
                                                                            <span
                                                                                className="text-danger">*</span></label>
                                                                        <input
                                                                            required
                                                                            className="form-control"
                                                                            type="text"
                                                                            name="instagramUrl"
                                                                            id="instagramUrl"
                                                                            value={user.instagramUrl}
                                                                            disabled
                                                                        />
                                                                        <p className="text-danger error">{errors.instagramUrl &&
                                                                            <InlineMessage
                                                                                text={errors.instagramUrl}/>}</p>


                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div className="row">

                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label htmlFor="specialities">Expertise &nbsp;
                                                                            <span
                                                                                className="text-danger">*</span></label>

                                                                        <select
                                                                            value={user.expertise}
                                                                            name="expertise"
                                                                            onChange={this.handleChange}
                                                                        >
                                                                            <option value="">Select Expertise</option>

                                                                            {this.app && this.app.lookupGroup.technicianExpertises.map((e, key) => {
                                                                                return <option value={e}
                                                                                               key={key}>{e}</option>
                                                                            })}
                                                                        </select>
                                                                        <p className="text-danger error">{errors.expertise &&
                                                                            <InlineMessage
                                                                                text={errors.expertise}/>}</p>

                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 techs-specialities">
                                                                    <div className="form-group">
                                                                        <label htmlFor="specialities">Specialties &nbsp;
                                                                            <span
                                                                                className="text-danger">*</span></label>
                                                                        <CreatableSelect
                                                                            isMulti={true}
                                                                            onChange={this.handleSelect}
                                                                            options={arg3}
                                                                            isSearchable={true}
                                                                        />
                                                                        <p className="text-danger error">{errors.specialties &&
                                                                            <InlineMessage
                                                                                text={errors.specialties}/>}</p>

                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label htmlFor="wage">Pay Option &nbsp; <span
                                                                            className="text-danger">*</span></label>
                                                                        <select
                                                                            name="compensationStrategy"
                                                                            value={user.compensationStrategy}
                                                                            onChange={this.handleChange}
                                                                        >
                                                                            <option value="Hourly">Hourly</option>
                                                                            <option value="Weekly">Weekly</option>
                                                                            <option value="Commission">Commission
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Product Commission &nbsp; <span
                                                                            className="text-danger">*</span></label>
                                                                        <div className="commission-percent">
                                                                            <input
                                                                                type="text"
                                                                                name="productCommissionPercentage"
                                                                                onChange={this.handleChange}
                                                                                defaultValue={(user.productCommissionPercentage * 1).toFixed(2)}
                                                                            /> <b>%</b>
                                                                        </div>
                                                                        <p className="text-danger error">{errors.productCommissionPercentage &&
                                                                            <InlineMessage
                                                                                text={errors.productCommissionPercentage}/>}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    {user.compensationStrategy == 'Hourly' &&
                                                                        <div className="form-group">
                                                                            <label>Pay Rate  &nbsp; <span
                                                                                className="text-danger">*</span></label>

                                                                            <input
                                                                                className="form-control"
                                                                                name="strategyWage"
                                                                                onChange={this.handleChange}
                                                                                defaultValue={(user.strategyWage * 1).toFixed(2)}
                                                                                type="text"/>
                                                                            <p className="text-danger error">{errors.strategyWage &&
                                                                                <InlineMessage
                                                                                    text={errors.strategyWage}/>}</p>

                                                                        </div>
                                                                    }
                                                                    {user.compensationStrategy == 'Weekly' &&
                                                                        <div className="form-group">
                                                                            <label>Pay Rate  &nbsp; <span
                                                                                className="text-danger">*</span></label>

                                                                            <input
                                                                                className="form-control"
                                                                                name="strategyWage"
                                                                                onChange={this.handleChange}
                                                                                defaultValue={(user.strategyWage * 1).toFixed(2)}
                                                                                type="text"/>
                                                                            <p className="text-danger error">{errors.strategyWage &&
                                                                                <InlineMessage
                                                                                    text={errors.strategyWage}/>}</p>

                                                                        </div>
                                                                    }
                                                                    {user.compensationStrategy == 'Commission' &&
                                                                        <div className="form-group">
                                                                            <label>Pay Rate  &nbsp; <span
                                                                                className="text-danger">*</span></label>

                                                                            <input
                                                                                className="form-control"
                                                                                name="strategyWage"
                                                                                onChange={this.handleChange}
                                                                                defaultValue={(user.strategyWage * 1).toFixed(2)}
                                                                                disabled
                                                                                type="text"/>
                                                                            <p className="text-danger error">{errors.strategyWage &&
                                                                                <InlineMessage
                                                                                    text={errors.strategyWage}/>}</p>

                                                                        </div>
                                                                    }
                                                                    <div className="form-group">
                                                                        <label>Cash Ratio  &nbsp; <span
                                                                            className="text-danger">*</span></label>
                                                                        <div className="relative">
                                                                            <input
                                                                                className="form-control"
                                                                                name="cashPaymentPercentage"
                                                                                defaultValue={(user.cashPaymentPercentage * 1).toFixed(2)}
                                                                                onChange={this.handleChange}
                                                                                type="text"/>
                                                                            <b className="percentage">%</b>
                                                                        </div>
                                                                        <p className="text-danger error">{errors.cashPaymentPercentage &&
                                                                            <InlineMessage
                                                                                text={errors.cashPaymentPercentage}/>}</p>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="form-group bio">
                                                                        <label>Describe the Bio: &nbsp;<span
                                                                            className="text-danger">*</span></label>
                                                                        <textarea
                                                                            required
                                                                            onChange={this.handleChange}
                                                                            maxLength="1000"
                                                                            className="form-control"
                                                                            name="biography"
                                                                            value={user.biography}></textarea>
                                                                        <p className="text-danger error">{errors.biography &&
                                                                            <InlineMessage
                                                                                text={errors.biography}/>}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label htmlFor="role">Role &nbsp;<span
                                                                            className="text-danger">*</span></label>
                                                                        <select onChange={this.handleChange}
                                                                                value={user.role} name="role">
                                                                            {this.app && this.app.lookupGroup.roles.map((r, k) => {
                                                                                return <option key={k}
                                                                                               value={r}>{r}</option>
                                                                            })}
                                                                        </select>
                                                                        <p className="text-danger error">{errors.role &&
                                                                            <InlineMessage text={errors.role}/>}</p>

                                                                    </div>
                                                                    <div className="form-group">
                                                                        <div>
                                                                            <label htmlFor="role">Password &nbsp;<span
                                                                                className="text-danger">*</span></label>
                                                                            <input
                                                                                type="password"
                                                                                className="form-control"
                                                                                onChange={this.handleChange}
                                                                                value={user.password}
                                                                                name="password"/>

                                                                            <p className="text-danger error">{errors.password &&
                                                                                <InlineMessage
                                                                                    text={errors.password}/>}</p>
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>

                                                            <div className="row">

                                                                <div className="col-md-6">
                                                                    <div className="switcher-spacer"></div>
                                                                    <div className="flex align-middle">
                                                                        <label className="switch">
                                                                            <input type="checkbox"
                                                                                   checked={user.clockReminderEnabled}
                                                                                   name="clockReminderEnabled" readOnly
                                                                                   onClick={this.handleChange}/>
                                                                            <span className="switchSlider"></span>
                                                                        </label>
                                                                        <label
                                                                            htmlFor="emailNotification"> &nbsp; &nbsp; &nbsp; Clock
                                                                            In Reminder</label>
                                                                    </div>
                                                                    <div className="flex align-middle">
                                                                        <label className="switch">
                                                                            <input type="checkbox"
                                                                                   name="customerBookingAllowed"
                                                                                   checked={user.customerBookingAllowed}
                                                                                   readOnly
                                                                                   onClick={this.handleChange}
                                                                            />
                                                                            <span className="switchSlider"></span>
                                                                        </label>
                                                                        <label
                                                                            htmlFor="customerBookingAllowed">&nbsp; &nbsp; &nbsp; Customer
                                                                            Booking Allowed</label>
                                                                    </div>
                                                                    <div className="flex align-middle">
                                                                        <label className="switch">
                                                                            <input type="checkbox"
                                                                                   name="selfBookingEnabled"
                                                                                   checked={user.selfBookingEnabled}
                                                                                   readOnly
                                                                                   onClick={this.handleChange}
                                                                            />
                                                                            <span className="switchSlider"></span>
                                                                        </label>
                                                                        <label
                                                                            htmlFor="selfBookingEnabled">&nbsp; &nbsp; &nbsp; Self
                                                                            Booking Enabled</label>
                                                                    </div>
                                                                    {this.MAN_DIREC === true &&
                                                                        <div
                                                                            className={'flex align-middle'}>
                                                                            <label className="switch">
                                                                                <input type="checkbox"
                                                                                       name="customerAccessAllowed"
                                                                                       checked={user.customerAccessAllowed}
                                                                                       readOnly
                                                                                       onClick={this.handleChange}
                                                                                />
                                                                                <span className="switchSlider"></span>
                                                                            </label>
                                                                            <label
                                                                                htmlFor="customerAccessAllowed">&nbsp; &nbsp; &nbsp;Allow
                                                                                customer access</label>
                                                                        </div>
                                                                    }
                                                                </div>

                                                                <div className="col-md-6">
                                                                    <div className="switcher-spacer"></div>
                                                                    <div className="flex align-middle">
                                                                        <label className="switch">
                                                                            <input type="checkbox"
                                                                                   checked={user.emailNotification}
                                                                                   name="emailNotification" readOnly
                                                                                   onClick={this.handleChange}/>
                                                                            <span className="switchSlider"></span>
                                                                        </label>
                                                                        <label
                                                                            htmlFor="emailNotification"> &nbsp; &nbsp; &nbsp; Email
                                                                            Notifications</label>
                                                                    </div>
                                                                    <div className="flex align-middle">
                                                                        <label className="switch">
                                                                            <input type="checkbox"
                                                                                   name="smsNotification"
                                                                                   checked={user.smsNotification}
                                                                                   readOnly
                                                                                   onClick={this.handleChange}
                                                                            />
                                                                            <span className="switchSlider"></span>
                                                                        </label>
                                                                        <label
                                                                            htmlFor="smsNotification">&nbsp; &nbsp; &nbsp; Text
                                                                            Notifications</label>
                                                                    </div>
                                                                    {this.MAN_DIREC === true &&
                                                                        <div className="flex align-middle">
                                                                            <label className="switch">
                                                                                <input type="checkbox"
                                                                                       name="acceptClientRequestEnabled"
                                                                                       checked={user.acceptClientRequestEnabled}
                                                                                       readOnly
                                                                                       onClick={this.handleChange}
                                                                                />
                                                                                <span className="switchSlider"></span>
                                                                            </label>
                                                                            <label
                                                                                htmlFor="acceptClientRequestEnabled">&nbsp; &nbsp; &nbsp; Accept
                                                                                Client Request</label>
                                                                        </div>}
                                                                    {this.MAN_DIREC === true &&
                                                                        <div
                                                                            className={'flex align-middle'}>
                                                                            <label className="switch">
                                                                                <input type="checkbox"
                                                                                       name="autoLogoutEnabled"
                                                                                       checked={user.autoLogoutEnabled}
                                                                                       readOnly
                                                                                       onClick={this.handleChange}
                                                                                />
                                                                                <span className="switchSlider"></span>
                                                                            </label>
                                                                            <label
                                                                                htmlFor="autoLogoutEnabled">&nbsp; &nbsp; &nbsp;Enable
                                                                                auto Logout</label>
                                                                        </div>}
                                                                </div>

                                                            </div>


                                                        </form>

                                                        {this.state.loading == true &&
                                                            <div className="custom-loading">
                                                                <div className="loading"><i
                                                                    className="fa fa-spinner fa-spin"></i></div>
                                                            </div>
                                                        }

                                                    </div>

                                                </div>
                                            }


                                            {this.state.id != '' &&


                                                <div className="fade in" id="services">
                                                    <div className="bs-example bs-example-tabs settings">

                                                        <div className="row">
                                                            <div className="row">
                                                                <div className="col-md-9">
                                                                    <ul role="tablist" className="nav nav-tabs">


                                                                        {this.state.categories.map((osc, key) => {
                                                                            return this.checkCategoryForAll(osc, key)
                                                                        })}

                                                                    </ul>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <div className="action">
                                                                        <button onClick={() => {
                                                                            this.resetFilters()
                                                                        }} className="btn btn-default pull-left">Clear
                                                                            Filter
                                                                        </button>

                                                                        <ul className="pager  pull-left">
                                                                            <li><a onClick={() => {
                                                                                this.getPreviousModalPage()
                                                                            }}><i className="fa fa-angle-left"></i></a>
                                                                            </li>
                                                                            <li><a onClick={() => {
                                                                                this.getNextModalPage()
                                                                            }}><i className="fa fa-angle-right"></i></a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div className="table-responsive">
                                                                <table className="table table-bordered">
                                                                    <thead>
                                                                    <tr>
                                                                        <th>Service Name</th>
                                                                        <th>Price</th>
                                                                        <th>Mins</th>
                                                                        <th>Process</th>
                                                                        <th>Action</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>

                                                                    {allServices.length != 0 && allServices.filter(s => s.service.active == true).map((s, key) => {

                                                                        return this.checkAvailableService(s.service.id, s, key)

                                                                    })}</tbody>
                                                                </table>
                                                            </div>

                                                            {this.state.loading == true &&
                                                                <div className="custom-loading">
                                                                    <div className="loading"><i
                                                                        className="fa fa-spinner fa-spin"></i></div>
                                                                </div>
                                                            }
                                                        </div>

                                                    </div>

                                                </div>
                                            }


                                        </div>
                                    </div>
                                </div>


                            </div>
                            {this.state.id == '' &&
                                <div className="modal-footer">
                                    <button onClick={this.saveStaffInfos} className="btn btn-custom-info">Save Info
                                    </button>
                                </div>
                            }

                        </div>
                    </div>
                </div>

                <div className="modal" id="addServiceForm" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <button onClick={this.resetSelectedService} className="btn close"
                                        data-dismiss="modal">&times;</button>
                                <br/>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Price Type</label>
                                            <select
                                                name="mode"
                                                value={this.state.mode}
                                                onChange={this.handleChange}>
                                                <option>Select Price Type
                                                </option>
                                                <option
                                                    value="Price Range">Price Range
                                                </option>
                                                <option
                                                    value="Starting Price">Starting Price
                                                </option>
                                                <option
                                                    value="Fixed Price">FixedPrice
                                                </option>
                                                <option value="Per Consult">Per Consult
                                                </option>

                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Duration</label>
                                            <input
                                                type="text"
                                                name="durat"
                                                defaultValue={this.state.durat}
                                                className="form-control"
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Minimum Fee</label>
                                            <input
                                                type="text"
                                                name="min"
                                                defaultValue={this.state.min && (this.state.min * 1).toFixed(2)}
                                                className="form-control"
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Maximum Fee</label>
                                            <input
                                                type="text"
                                                name="max"
                                                defaultValue={this.state.max && (this.state.max * 1).toFixed(2)}
                                                onChange={this.handleChange}
                                                className="form-control"
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Processing Time</label>
                                            <input
                                                type="text"
                                                name="process"
                                                defaultValue={this.state.process}
                                                onChange={this.handleChange}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="switcher-spacer"></div>
                                        <div className="form-group">
                                            <button onClick={this.addServiceTechnician}
                                                    className="btn btn-custom-info pull-right">Add Service
                                            </button>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="addingTask" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <h4 className="modal-title">Add Task</h4>
                            </div>
                            <div className="modal-body">
                                <AddTask
                                    statas="disabled"
                                    predefinedRelatedItem={this.state.taskSelected.taskSelectedCustomer}
                                    predefinedRelatedId={this.state.taskSelected.taskSelectedItemId}
                                    predefinedRelatedTo="Staff"
                                    mode={this.state.mode}
                                    saveTask={this.saveTask}/>
                            </div>


                            <div className="modal-footer">

                                <div className="buttons">
                                    <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                                    <button type="button" className="btn btn-success">Submit</button>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>

                <div className="modal fade" id="sorting">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <Sorting
                                changeOrder={this.changeOrder}
                                list={this.state.list}/>
                        </div>
                    </div>
                </div>

            </div>
        );
    }

    getNextPage() {
        var offset = this.state.pageOffset + 1;
        const {technicians, dispatch} = this.props;
        if (technicians && technicians.length != 0) {
            dispatch(userActions.getAllTechnicians(offset, this.state.team, this.state.searchQuery));
            this.setState({pageOffset: offset});
        }

    }


    getPreviousPage() {
        var offset = this.state.pageOffset - 1;
        if (offset >= 0) {
            const {dispatch} = this.props;
            dispatch(userActions.getAllTechnicians(offset, this.state.team, this.state.searchQuery));
            this.setState({pageOffset: offset});
        }
    }

    searchTechnician() {
        const {dispatch} = this.props;
        dispatch(userActions.getAllTechnicians(0, this.state.team, this.state.searchQuery))
    }


    taskSelectedTechnician = (id) => {

        let taskSelectedTechnician = this.props.technicians.find(c => c.id == id);

        this.setState({
            taskSelected: {
                ...this.state.taskSelected,
                taskSelectedCustomer: taskSelectedTechnician.user.fullName,
                taskSelectedItemId: taskSelectedTechnician.id
            }
        });
    }


    resetFilters() {
        this.setState({pageOffset: 0});
        this.setState({team: ''});
        this.setState({searchQuery: ''});
        const {dispatch} = this.props;
        dispatch(userActions.getAllTechnicians(0))
    }


    resetState = () => {
        this.setState({
            ...this.state,
            user: {
                id: '',
                firstName: '',
                lastName: '',
                nickName: '',
                email: '',
                mobileNumber: '',
                gender: 'Male',
                birthDate: new Date(),
                emailNotification: false,
                smsNotification: false,
                profilePic: '',
                nextBirthdayRewardDate: '',
                rewardBalance: 0,
                referralToken: this.app.referralToken,
                totalEarningsAsReferrer: '',
                accountStatus: 'Active',
                representationColor: '#000000',
                team: '',
                role: "TECHNICIAN",
                expertise: '',
                biography: '',
                specialties: [],
                password: '',
                cashPaymentPercentage: 0,
                technicianServices: [],
                productCommissionPercentage: 0,
                compensationStrategy: 'Commission',
                strategyWage: 0,
                instagramUrl: this.app.locations[0].id == 1 ? 'https://www.instagram.com/eterna_day_spa/' : this.app.locations[0].id == 2 ? 'https://www.instagram.com/cl9salon/' : this.app.locations[0].id == 3 ? 'https://www.instagram.com/salonh2omd/' : 'https://www.instagram.com/301studiobl/',

            },
            id: ''


        });
    }


    checkPriceType = (min, max) => {

        if (min == '-1' && max == '-1') {
            return 'Per Consult'
        } else if (min > 0 && max > 0 && min < max) {
            return 'Price Range'
        } else if (min > 0 && max == -1) {
            return 'Starting Price'
        } else {
            return 'Fixed Price'
        }
    }


    saveTechnicianServices = (sid, duration, minimumFee, maximumFee, pricingModel, method, techSerId = '') => {
        if (sid != '' && duration != '' && minimumFee != '' && maximumFee != '' && pricingModel != '') {
            if (method == 'POST') {
                var durationmnt = '';
                var minimumFees = '';
                var maximumFees = '';
                if (duration in this.state.selectedService) {
                    durationmnt = this.state.selectedService[duration]
                } else {
                    durationmnt = this.isChecked(sid, 'duration');
                }
                if (minimumFee in this.state.selectedService && pricingModel in this.state.selectedService) {

                    minimumFees = this.state.selectedService[pricingModel] == 'Per Consult' ? '-1' : this.state.selectedService[pricingModel] == 'Price Range' ? this.state.selectedService[minimumFee] : this.state.selectedService[pricingModel] == 'Starting Price' ? this.state.selectedService[minimumFee] : this.state.selectedService[pricingModel] == 'Fixed Price' ? this.state.selectedService[minimumFee] : '';

                } else if (minimumFee in this.state.selectedService && !(pricingModel in this.state.selectedService)) {
                    minimumFees = this.isChecked(sid, 'pricingModel') == 'Per Consult' ? '-1' : this.isChecked(sid, 'pricingModel') == 'Price Range' ? this.state.selectedService[minimumFee] : this.isChecked(sid, 'pricingModel') == 'Starting Price' ? this.state.selectedService[minimumFee] : this.isChecked(sid, 'pricingModel') == 'Fixed Price' ? this.state.selectedService[minimumFee] : '';
                } else if (!(minimumFee in this.state.selectedService) && pricingModel in this.state.selectedService) {
                    minimumFees = this.state.selectedService[pricingModel] == 'Per Consult' ? '-1' : this.state.selectedService[pricingModel] == 'Price Range' ? this.isChecked(sid, 'minimumFee') : this.state.selectedService[pricingModel] == 'Starting Price' ? this.isChecked(sid, 'minimumFee') : this.state.selectedService[pricingModel] == 'Fixed Price' ? this.isChecked(sid, 'minimumFee') : '';
                } else {
                    minimumFees = this.isChecked(sid, 'pricingModel') == 'Per Consult' ? '-1' : this.isChecked(sid, 'pricingModel') == 'Price Range' ? this.isChecked(sid, 'minimumFee') : this.isChecked(sid, 'pricingModel') == 'Starting Price' ? this.isChecked(sid, 'minimumFee') : this.isChecked(sid, 'pricingModel') == 'Fixed Price' ? this.isChecked(sid, 'minimumFee') : '';
                }


                if (maximumFee in this.state.selectedService && pricingModel in this.state.selectedService) {

                    maximumFees = this.state.selectedService[pricingModel] == 'Per Consult' ? '-1' : this.state.selectedService[pricingModel] == 'Price Range' ? this.state.selectedService[maximumFee] : this.state.selectedService[pricingModel] == 'Starting Price' ? '-1' : this.state.selectedService[pricingModel] == 'Fixed Price' ? this.state.selectedService[minimumFee] : '';

                } else if (maximumFee in this.state.selectedService && !(pricingModel in this.state.selectedService)) {

                    maximumFees = this.isChecked(sid, 'pricingModel') == 'Per Consult' ? '-1' : this.isChecked(sid, 'pricingModel') == 'Price Range' ? this.state.selectedService[maximumFee] : this.isChecked(sid, 'pricingModel') == 'Starting Price' ? '-1' : this.isChecked(sid, 'pricingModel') == 'Fixed Price' ? this.state.selectedService[minimumFee] : '';

                } else if (!(maximumFee in this.state.selectedService) && pricingModel in this.state.selectedService) {

                    maximumFees = this.state.selectedService[pricingModel] == 'Per Consult' ? '-1' : this.state.selectedService[pricingModel] == 'Price Range' ? this.isChecked(sid, 'maximumFee') : this.state.selectedService[pricingModel] == 'Starting Price' ? '-1' : this.state.selectedService[pricingModel] == 'Fixed Price' ? this.state.selectedService[minimumFee] : '';

                } else {

                    maximumFees = this.isChecked(sid, 'pricingModel') == 'Per Consult' ? '-1' : this.isChecked(sid, 'pricingModel') == 'Price Range' ? this.isChecked(sid, 'maximumFee') : this.isChecked(sid, 'pricingModel') == 'Starting Price' ? '-1' : this.isChecked(sid, 'pricingModel') == 'Fixed Price' ? this.isChecked(sid, 'minimumFee') : '';

                }


                if (this.state.user.id != '') {
                    if (minimumFees != undefined && maximumFees != undefined && durationmnt != undefined) {
                        const techJSON = {
                            technician: {
                                id: this.state.id
                            },
                            service: {
                                id: sid
                            },
                            duration: 'PT' + durationmnt + 'M',
                            minimumFee: minimumFees,
                            maximumFee: maximumFees

                        }

                        appService.saveTechnicianToService(techJSON).then(res => {
                            window.DataUpdateSuccess();
                            appService.getAllServicesForStaffdetails(this.state.pageOffset, this.state.ctgryId).then(services => {
                                this.setState({
                                    allServices: services
                                })
                            })
                        }).catch(err => {
                            Swal.fire({
                                title: 'Invalid Data!',
                                text: `Pricing Model is invalid!`,
                                type: 'warning',
                                cancelButtonText: 'Ok'
                            })
                        })
                    } else {
                        Swal.fire({
                            title: 'Empty!',
                            text: `Please provide valid data!`,
                            type: 'warning',
                            cancelButtonText: 'Ok'
                        })
                    }
                } else {
                    Swal.fire({
                        title: 'Empty!',
                        text: `Please try to add technician info first!`,
                        type: 'warning',
                        cancelButtonText: 'Ok'
                    })
                }


            }
        }

    }

    isChecked = (id, query) => {
        if (this.state.user != '') {

            var service = this.state.allServices.find(s => s.service.id == id);
            if (service) {

                if (query === 'pricingModel') {
                    return this.checkPriceType(service.minimumFee, service.maximumFee)
                }
                if (query === 'duration') {
                    return helperFunctions.timeConvert(service.duration, 'minute');
                }

                if (query === 'minimumFee') {
                    return service.minimumFee
                }

                if (query === 'maximumFee') {
                    return service.maximumFee
                }

                if (query == 'sId') {
                    return service.id
                }


            }
        }
    }


    searchServicesByCategory(ctgryId) {
        this.setState({
            ...this.state,
            loading: true
        })
        const {modalPageOffset} = this.state;
        appService.getAllServicesForStaffdetails(modalPageOffset, ctgryId).then(service => {
            this.setState({
                ...this.state,
                allServices: service,
                ctgryId: ctgryId,
                loading: false
            });
        })


    }

    getNextModalPage() {

        this.setState({
            loading: true
        })

        var offset = this.state.modalPageOffset + 1;
        const {allServices, ctgryId} = this.state;
        if (allServices && allServices.length != 0) {
            appService.getAllServicesForStaffdetails(offset, ctgryId).then(service => {
                this.setState({
                    modalPageOffset: offset,
                    allServices: service,
                    loading: false
                });
            });

        }
    }

    getPreviousModalPage() {
        var offset = this.state.modalPageOffset - 1;
        if (offset >= 0) {
            const {allServices, ctgryId} = this.state;
            appService.getAllServicesForStaffdetails(offset, ctgryId).then(service => {
                this.setState({
                    modalPageOffset: offset,
                    allServices: service
                });
            });
        }
    }


    checkCategoryForAll = (s, k) => {
        if (s) {
            if (typeof (s.parent) == "undefined") {
                let sum = this.makeCategoryForAllList(s.id)
                return (
                    <React.Fragment key={k}>
                        {sum.length > 0 &&
                            <li className="dropdown" role="presentation"><a data-toggle="dropdown"
                                                                            className="dropdown-toggle">{s.name} <span
                                className="caret"></span></a>
                                <ul className="dropdown-menu">
                                    {this.makeCategoryForAllList(s.id)}
                                </ul>
                            </li>
                        }
                        {sum.length == 0 &&
                            <li className="dropdown" role="presentation"><a
                                onClick={() => this.searchServicesByCategory(s.id)} data-toggle="dropdown"
                                className="dropdown-toggle">{s.name}</a></li>

                        }

                    </React.Fragment>
                )

            }
        }
    }

    makeCategoryForAllList = (ids) => {

        const fill = this.state.categories.filter(osc => typeof (osc.parent) != "undefined")
        const chld = fill.filter(f => f.parent.id == ids)
        let list = []
        chld.map((ch, key) => {
            list.push(<li key={key}><a onClick={() => this.searchServicesByCategory(ch.id)}>{ch.name}</a></li>)

        })
        return list;

    }


    checkAvailableService = (id, s, key) => {
        return <tr key={key}>
            <td>{s.service.name}</td>
            <td>{this.isCheckedAllList(s.service.id, s, 'displayedPrice')}</td>
            <td>{this.isCheckedAllList(s.service.id, s, 'duration')}</td>
            <td>--</td>

            <td>
                <button data-toggle="modal" data-target="#addServiceForm" onClick={() => this.openAddModal(s)}
                        className="btn btn-outline btn-info btn-lg btn-circle"><i className="fa fa-check"></i></button>
            </td>
        </tr>
    }

    isCheckedAllList = (id, service, query) => {
        if (service) {

            if (query === 'pricingModel') {
                return this.checkPriceType(service.minimumFee, service.maximumFee)
            }
            if (query === 'duration') {
                return helperFunctions.timeConvert(service.duration, 'minute');
            }
            if (query === 'minimumFee') {
                return service.minimumFee
            }

            if (query === 'maximumFee') {
                return service.maximumFee
            }
            if (query === 'displayedPrice') {
                return service.displayedPrice
            }
            if (query == 'sId') {
                return service.id
            }
            if (query == 'processingTimeInMinutes') {
                return 0
            }
            if (query == 'newClientRetentionPercentage') {
                return service.newClientRetentionPercentage
            }
            if (query == 'oldClientRetentionPercentage') {
                return service.oldClientRetentionPercentage
            }


        }

    }

    openAddModal = (s) => {
        $('#add-tech').modal('hide')

        this.setState({
            ...this.state,
            mode: this.isCheckedAllList(s.service.id, s, 'pricingModel'),
            min: this.isCheckedAllList(s.service.id, s, 'minimumFee'),
            max: this.isCheckedAllList(s.service.id, s, 'maximumFee'),
            durat: this.isCheckedAllList(s.service.id, s, 'duration'),
            process: this.isCheckedAllList(s.service.id, s, 'processingTimeInMinutes'),
            sid: s.service.id,
            techServiceId: s.id

        })

    }

    addServiceTechnician = () => {
        $('#addServiceForm').modal('hide');
        this.setState({
            loading: true
        })
        const techJSON = {
            technician: {
                id: this.state.id
            },
            service: {
                id: this.state.sid
            },
            duration: 'PT' + this.state.durat + 'M',
            minimumFee: this.state.min,
            maximumFee: this.state.max,
            processingTime: 'PT' + this.state.process + 'M'

        }
        appService.saveTechnicianToService(techJSON).then((res) => {
            $('#add-tech').modal('show');
            const {dispatch} = this.props
            const newAllServices = this.state.allServices.filter(s => s.service.id != res.service.id)

            this.setState({
                allServices: newAllServices,
                loading: false
            })


            window.DataUpdateSuccess();

            dispatch(userActions.getAllTechnicians(this.state.pageOffset, this.state.searchQuery))

        });


    }


}


function mapStateToProps(state) {
    const {technicians, techLoading} = state.technicians;
    return {
        technicians,
        techLoading
    };
}


const connectedTechniciansPage = connect(mapStateToProps)(TechniciansPage);
export {connectedTechniciansPage as TechniciansPage};
