import React from 'react';
import {connect} from 'react-redux';
import './profile.css';
import {userActions} from '../../_actions';
import {appService, userService} from '../../_services';

import Footer from '../footer/footer'
import {helperFunctions} from "../../_helpers";

class ProfilePage extends React.Component {
    constructor(props) {
        super(props);

        let thisObj = this;
        userService.getUserInfo().then(user => {
            thisObj.setState({
                user: {
                    firstName: user.names.first,
                    lastName: user.names.last,
                    nickName: user.names.nick,
                    email: user.emailAddress,
                    mobileNumber: user.mobileNumber,
                    gender: user.gender,
                    birthDate: user.birthDate,
                    emailNotification: user.emailNotificationEnabled,
                    smsNotification: user.smsNotificationEnabled,
                    profilePic: user.imageUrl
                },
                submitted: false
            });
        });

        this.state = {
            user: {
                firstName: '',
                lastName: '',
                nickName: '',
                email: '',
                mobileNumber: '',
                gender: '',
                birthDate: '',
                emailNotification: false,
                smsNotification: false,
                profilePic: '',
            },
            submitted: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.profilePicHandler = this.profilePicHandler.bind(this);
    }

    componentDidMount = async () => {
        const metaInfo = await appService.checkVersion();
        await helperFunctions.clearCache(metaInfo)
    }

    profilePicHandler(e) {
        const {dispatch} = this.props;
        // eslint-disable-next-line
        if (e.target.files.length != 0)
            dispatch(userActions.updateProfilePic(e.target.files[0]));
    }

    handleChange(event) {
        const {name, value} = event.target;
        // eslint-disable-next-line
        const {user} = this.state;
        // eslint-disable-next-line
        if (name === "emailNotification") {
            this.setState({
                user: {
                    ...user,
                    emailNotification: !this.state.user.emailNotification
                }

            })

        }
        // eslint-disable-next-line
        else if (name === "smsNotification") {
            this.setState({
                user: {
                    ...user,
                    smsNotification: !this.state.user.smsNotification
                }

            })
        } else {
            this.setState({
                user: {
                    ...user,
                    [name]: value
                }
            });
        }
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({submitted: true});
        const {user} = this.state;
        const {dispatch} = this.props;

        if (user.firstName && user.lastName && user.birthDate && user.mobileNumber && user.gender) {
            user.mobileNumber = user.mobileNumber.replace(' ', '').replace(')', '').replace('(', '').replace('-', '');
            const updatedUser = {
                names: {
                    first: user.firstName,
                    last: user.lastName,
                    nick: user.nickName
                },
                mobileNumber: user.mobileNumber,
                birthDate: user.birthDate,
                gender: user.gender,
                emailNotificationEnabled: user.emailNotification,
                smsNotificationEnabled: user.smsNotification
            }

            dispatch(userActions.updateProfile(updatedUser));
        }
    }

    render() {
        const {profileUpdated} = this.props;
        const {submitted, user} = this.state;
        return (
            <div>
                <div id="page-wrapper">
                    <div className="container-fluid">
                        <div className="center col-sm-12">
                            <h1>PROFILE</h1>
                        </div>
                        <hr/>

                        <div className="row contact-wrap">
                            <div className="col-md-6 col-md-offset-3 col-xs-9 col-xs-offset-1">
                                <div className="center">
                                    <img src={user.profilePic} alt="profile display" className="profile-img"/>
                                    <p className="image-caption">click on image to change picture</p>
                                </div>
                                <div className="no-display">
                                    <input type="file" id="fileInput" name="fileInput"
                                           onChange={this.profilePicHandler}/>
                                </div>
                                <form name="form" onSubmit={this.handleSubmit}>
                                    <div className={'form-group' + (submitted && !user.firstName ? ' has-error' : '')}>
                                        <label htmlFor="firstName">FIRSTNAME</label>
                                        <input type="text" maxLength="40" className="form-control" name="firstName"
                                               value={user.firstName} onChange={this.handleChange}/>
                                        {submitted && !user.firstName &&
                                            <div className="help-block">First Name is required</div>
                                        }
                                    </div>
                                    <div className={'form-group' + (submitted && !user.lastName ? ' has-error' : '')}>
                                        <label htmlFor="lastName">LAST NAME</label>
                                        <input type="text" maxLength="40" className="form-control" name="lastName"
                                               value={user.lastName} onChange={this.handleChange}/>
                                        {submitted && !user.lastName &&
                                            <div className="help-block">Last Name is required</div>
                                        }
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="nickName">NICK NAME</label>
                                        <input type="text" maxLength="40" className="form-control" name="nickName"
                                               value={user.nickName} onChange={this.handleChange}/>
                                    </div>
                                    <div className='form-group'>
                                        <label htmlFor="email">EMAIL*</label>
                                        <input type="email" maxLength="100" className="form-control" name="email"
                                               value={user.email} disabled/>
                                    </div>
                                    <div
                                        className={'form-group' + (submitted && !user.mobileNumber ? ' has-error' : '')}>
                                        <label htmlFor="mobileNumber">MOBILE NUMBER</label>
                                        <input type="text" className="form-control mobileNumber" name="mobileNumber"
                                               value={user.mobileNumber} onChange={this.handleChange}/>
                                        {submitted && !user.mobileNumber &&
                                            <div className="help-block">Phone number is required</div>
                                        }
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="gender">GENDER</label>
                                        <select name="gender" onChange={this.handleChange} defaultValue={user.gender}>
                                            <option value="Male">MALE</option>
                                            <option value="Female">FEMALE</option>
                                        </select>
                                    </div>
                                    <div className={'form-group' + (submitted && !user.birthDate ? ' has-error' : '')}>
                                        <label htmlFor="birthDate">BIRTH DATE</label>
                                        <input type="date" name="birthDate" value={user.birthDate}
                                               onChange={this.handleChange}/>
                                        {submitted && !user.birthDate &&
                                            <div className="help-block">Birth Date is required</div>
                                        }
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className='form-group'>
                                                <label htmlFor="emailNotification">EMAIL NOTIFICATIONS</label>
                                                <label className="switch">
                                                    <input type="checkbox" checked={user.emailNotification}
                                                           name="emailNotification" onChange={this.handleChange}/>
                                                    <span className="switchSlider"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className='form-group'>
                                                <label htmlFor="smsNotification">TEXT NOTIFICATIONS</label>
                                                <label className="switch">
                                                    <input type="checkbox" name="smsNotification"
                                                           checked={user.smsNotification} onChange={this.handleChange}/>
                                                    <span className="switchSlider"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group center-xs">
                                        <input type="submit" className="btn btn-block btn-primary btn-lg btn-rounded"
                                               value="Update Profile"/>
                                    </div>
                                    {
                                        profileUpdated &&
                                        <div>
                                            <h4 className="center">Your profile has been updated successfully.</h4>
                                            <div className="checkmark">
                                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                     viewBox="0 0 130.2 130.2">
                                                    <circle className="path circle" fill="none" stroke="#73AF55"
                                                            strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1"
                                                            r="62.1"/>
                                                    <polyline className="path check" fill="none" stroke="#73AF55"
                                                              strokeWidth="6" strokeLinecap="round"
                                                              strokeMiterlimit="10"
                                                              points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
                                                </svg>
                                            </div>
                                        </div>
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }

}

function mapStateToProps(state) {
    const {profileUpdated} = state.updateProfile
    const {message} = state.alert;
    return {
        message,
        profileUpdated
    };
}

const connectedRegisterPage = connect(mapStateToProps)(ProfilePage);
export {connectedRegisterPage as ProfilePage};
