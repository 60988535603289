/* eslint-disable */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {userActions} from '../../_actions';
import './login.css';
import {appService} from '../../_services';
import {Link} from 'react-router-dom';
import {communicationAction} from "../../_actions/communication.actions";
import {Device} from 'twilio-client'
import {subscribeToTopic, unsubscribeToTopic} from "../../utils/firebase.utilities";
import {isSupported} from "firebase/messaging";
import {helperFunctions} from "../../_helpers";

class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            resetEmail: '',
            appMetaData: {},
            isPassword: true
        };

        // reset login status
        var storeId = window.location.href.split('/')[3];
        this.app = JSON.parse(localStorage.getItem('app'))
        if (storeId != undefined) {
            appService.getAppDetails(storeId).then(app => {
                this.setState({appMetaData: app});
                ['user', app.vanityId + '_user', app.vanityId + '_sessionId', app.vanityId + '_csrf'].map(key => localStorage.removeItem(key));
                localStorage.setItem('app', JSON.stringify(app))
            })
        }
        if (this.app != null) {
            if (localStorage.getItem('firebaseToken')) {
                if (isSupported) {
                    if (this.app.locations && this.app.locations[0].id) {
                        const token = localStorage.getItem('firebaseToken')
                        let apptTopics = 'lctn-appts-' + this.app.locations[0].id
                        let twillioTopics = 'lctn-sms-' + this.app.smsSenderNumber
                        let statusTopic = 'lctn-status-' + this.app.smsSenderNumber
                        let updateStatus = "app-settings-update";
                        [apptTopics, twillioTopics, statusTopic, updateStatus].map(topic => this.unsubscribeTokenToTopic(topic, token))
                    }
                }
            }
        }
        localStorage.removeItem('token')
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount = async () => {
        const metaInfo = await appService.checkVersion();
        await helperFunctions.clearCache(metaInfo)
        Device.destroy()
    }


    subscribeTokenToTopic = async (topic, firebaseToken) => {
        await subscribeToTopic(topic, firebaseToken);
    }

    handleChange(e) {
        const {name, value} = e.target;
        this.setState({[name]: value});
    }

    resetPassword() {
        const {dispatch} = this.props;
        if (this.state.resetEmail != '') {
            const resetEmailJson = {
                business: {
                    id: this.state.appMetaData.id
                },
                emailAddress: this.state.resetEmail
            }
            dispatch(userActions.resetPassword(resetEmailJson));
        }
    }

    login = () => {
        this.setState({submitted: true});
        const {username, password} = this.state;
        const {dispatch} = this.props;
        if (username && password) {
            localStorage.removeItem('sms')
            dispatch(userActions.login(username, password, this.state.appMetaData.id, this.state.appMetaData.vanityId));
            appService.getAppDetails(this.state.appMetaData.vanityId).then(res => {
                dispatch(communicationAction.captureInfo(res))
                if (localStorage.getItem('firebaseToken') !== null) {
                    const token = localStorage.getItem('firebaseToken');
                    let apptTopics = 'lctn-appts-' + res.locations[0].id
                    let twillioTopics = 'lctn-sms-' + res.smsSenderNumber
                    let statusTopic = 'lctn-status-' + res.smsSenderNumber
                    let updateStatus = "app-settings-update";
                    [apptTopics, twillioTopics, statusTopic, updateStatus].map(topic => this.subscribeTokenToTopic(topic, token))
                }

            })
        }
    }


    unsubscribeTokenToTopic = async (topic, firebaseToken) => await unsubscribeToTopic(topic, firebaseToken);


    render() {
        const {loginFailure, loggingIn, passwordReset, passwordResetError, failureMessage} = this.props
        const {appMetaData, isPassword} = this.state

        return (
            <div>
                {
                    appMetaData && !appMetaData.id &&
                    <div className="preloader">
                        <div className="cssload-speeding-wheel"></div>
                    </div>
                }
                {
                    appMetaData && appMetaData.id &&
                    <section id="wrapper" className="login-register" style={{
                        background: `url(${appMetaData.visualAttributes.silverbirdHomeImage})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat'
                    }}>
                        <div className="login-box login-sidebar">
                            <div className="white-box">
                                <a className="text-center db"><img src={appMetaData.logoUrls[1]} alt="Home"
                                                                   width="200"/></a>

                                <div id="loginform" className="fix">

                                    <div className="form-group fix m-t-40">
                                        <div className="col-xs-12">
                                            <input className="form-control username" autoComplete="username" type="text"
                                                   name="username" onChange={this.handleChange} required
                                                   placeholder="Username"/>
                                        </div>
                                    </div>
                                    <div className="form-group fix">
                                        <div className="col-xs-12">
                                            <input className="form-control" autoComplete="current-password"
                                                   type={isPassword ? 'password' : 'text'} name="password"
                                                   onChange={this.handleChange} required
                                                   placeholder="Password"/>
                                            <div className="password"
                                                 onClick={() => this.setState({isPassword: !this.state.isPassword})}>
                                                <i className={`fa fa-eye${isPassword ? '-slash text-muted' : ''} fa-2x`}></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group fix">
                                        <div className="col-md-12">

                                            <a id="to-recover" className="text-dark pull-right"><i
                                                className="fa fa-lock m-r-5"></i> Forgot pwd?</a></div>
                                    </div>
                                    <div className="form-group fix text-center m-t-20">
                                        <div className="col-xs-12">
                                            <button
                                                className="btn btn-info btn-lg btn-block text-uppercase waves-effect waves-light"
                                                onClick={() => {
                                                    this.login()
                                                }}>
                                                {
                                                    loggingIn &&
                                                    <span id="spinner" className="spinner-border" role="status"
                                                          aria-hidden="true"></span>
                                                }
                                                Log In
                                            </button>
                                        </div>
                                    </div>
                                    <div className="form-group fix center text-center m-t-20">
                                        <div className="col-xs-12 m-t-20">
                                            <Link to="/">Change Store</Link>
                                        </div>
                                    </div>

                                    {
                                        loginFailure && loginFailure == true && failureMessage &&
                                        <div className="form-group fix">
                                            <div className="col-xs-12 center mt10">
                                                <label className="warning"> {failureMessage} </label>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div id="recoverform">
                                    <div className="form-group ">
                                        <div className="col-xs-12">
                                            <h3>Recover Password</h3>
                                            <p className="text-muted">Enter your Email and instructions will be sent to
                                                you! </p>
                                        </div>
                                    </div>
                                    <div className="form-group ">
                                        <div className="col-xs-12">
                                            <input className="form-control" type="text" name="resetEmail"
                                                   onChange={this.handleChange} required="" placeholder="Email"/>
                                            <a id="to-login" className="text-dark pull-right"><i
                                                className="fa fa-lock m-r-5"></i>Login</a>
                                        </div>
                                    </div>
                                    <div className="form-group text-center m-t-20">
                                        <div className="col-xs-12">
                                            <button
                                                className="btn btn-primary btn-lg btn-block text-uppercase waves-effect waves-light"
                                                onClick={() => {
                                                    this.resetPassword()
                                                }} type="submit">Reset
                                            </button>
                                        </div>
                                    </div>
                                    {
                                        passwordReset && passwordReset == true && this.state.resetEmail != '' &&
                                        <div className="form-group">
                                            <div className="col-xs-12 center">
                                                <label className=""> An email has been sent with password reset
                                                    instructions. </label>
                                            </div>
                                        </div>
                                    }
                                    {
                                        passwordResetError && passwordResetError == true && this.state.resetEmail != '' &&
                                        <div className="form-group">
                                            <div className="col-xs-12 center">
                                                <label className="warning"> This email address doesn't exist in our
                                                    system </label>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                }

            </div>
        );
    }

}

function mapStateToProps(state) {
    const {loginFailure, loggingIn, failureMessage} = state.authentication;
    const {passwordReset, passwordResetError} = state.updateProfile;
    return {
        loginFailure,
        loggingIn,
        passwordReset,
        passwordResetError,
        failureMessage
    };
}


const connectedLoginPage = connect(mapStateToProps)(LoginPage);
export {connectedLoginPage as LoginPage};
