import React from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './SilckCarousel.css'
const SilckCarousel = (props) => {


  return (
    <div className="customSlick">
      {props.images.length > 0 &&
        <OwlCarousel items={3}
          className="owl-theme"
          loop={false}
          center={true}
          dots={false}
          nav={true}
          margin={8} >
          {props.images.length > 0 && props.images.map((image, key) => {
            return (
              <div key={key} className="imgBox">
                <div className="delation"><button onClick={() => props.removeImage('image', key)} className="btn btn-danger btn-circle btn-lg"><i className="fa fa-close"></i></button></div>
                <div className="carousel-item-image"><img src={image} alt="product image" /></div>
              </div>
            )
          })}
        </OwlCarousel>
      }
    </div>
  );
};

export default SilckCarousel;
