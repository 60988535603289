import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { CustomersPage } from "./_pages/customers/customers";

import CustomerDetailPage from "./_pages/customers/customerDetail";
import { TechniciansPage } from "./_pages/technicians/technicians";
import { TechnicianDetailPage } from "./_pages/technicians/technicianDetail";
import { ProfilePage } from "./_pages/profile/profile";
import { UpdatePasswordPage } from "./_pages/profile/updatePassword";
import { DashboardPage } from "./_pages/dashboard/dashboard";

import { ReservationCalendarPage } from "./_pages/reservation/calendarReservation";
import { Inventory } from "./_pages/inventory/inventory";
import { Config } from "./config";
import { ServicesPage } from "./_pages/services/services";
import { LoginPage } from "./_pages/login/login";
import { PrivateRoute } from "./_components";
import { HomePage } from "./_pages/home/home";
import { PayRoll } from "./_pages/payroll/payRoll";
import { Closeout } from "./_pages/closeout/closeout";
import { Header } from "./_pages/header/header";

var config = Config();

const AppContent = () => {
  return (
    <React.Fragment>
      <Header />

      <Switch>
        <Route exact path="/salon-h2o/login" component={LoginPage} />
        <PrivateRoute
          name="dashboard"
          exact
          path="/salon-h2o"
          component={DashboardPage}
        />
        <PrivateRoute
          name="customers"
          path={`/salon-h2o/customers`}
          component={CustomersPage}
        />
        <PrivateRoute
          name="customerDetail"
          path={`/salon-h2o/update-customer`}
          component={CustomerDetailPage}
        />
        <PrivateRoute
          name="profile"
          path={`/salon-h2o/profile`}
          component={ProfilePage}
        />
        <PrivateRoute
          name="calendarReservation"
          path={`/salon-h2o/calendar-reservation`}
          component={ReservationCalendarPage}
        />
        <PrivateRoute
          name="updatePassword"
          path={`/salon-h2o/update-password`}
          component={UpdatePasswordPage}
        />

        <Route exact name="home" path="/" component={HomePage} />
        <Route exact name="home-login" path="/login" component={HomePage} />

        <Route
          exact
          name="login"
          path={`/eterna-day-spa/login`}
          component={LoginPage}
        />
        <PrivateRoute
          name="dashboard"
          exact
          path={`/eterna-day-spa/`}
          component={DashboardPage}
        />
        <PrivateRoute
          name="customers"
          path={`/eterna-day-spa/customers`}
          component={CustomersPage}
        />
        <PrivateRoute
          name="customerDetail"
          path={`/eterna-day-spa/update-customer`}
          component={CustomerDetailPage}
        />
        <PrivateRoute
          name="profile"
          path={`/eterna-day-spa/profile`}
          component={ProfilePage}
        />
        <PrivateRoute
          name="calendarReservation"
          path={`/eterna-day-spa/calendar-reservation`}
          component={ReservationCalendarPage}
        />
        <PrivateRoute
          name="updatePassword"
          path={`/eterna-day-spa/update-password`}
          component={UpdatePasswordPage}
        />

        <Route
          exact
          name="login"
          path={`/cloud-9-salon/login`}
          component={LoginPage}
        />
        <PrivateRoute
          name="dashboard"
          exact
          path={`/cloud-9-salon/`}
          component={DashboardPage}
        />
        <PrivateRoute
          name="customers"
          path={`/cloud-9-salon/customers`}
          component={CustomersPage}
        />
        <PrivateRoute
          name="customerDetail"
          path={`/cloud-9-salon/update-customer`}
          component={CustomerDetailPage}
        />
        <PrivateRoute
          name="profile"
          path={`/cloud-9-salon/profile`}
          component={ProfilePage}
        />
        <PrivateRoute
          name="calendarReservation"
          path={`/cloud-9-salon/calendar-reservation`}
          component={ReservationCalendarPage}
        />
        <PrivateRoute
          name="updatePassword"
          path={`/cloud-9-salon/update-password`}
          component={UpdatePasswordPage}
        />

        <Route
          exact
          name="login"
          path={`/eterna-lash-brow/login`}
          component={LoginPage}
        />
        <PrivateRoute
          name="dashboard"
          exact
          path={`/eterna-lash-brow/`}
          component={DashboardPage}
        />
        <PrivateRoute
          name="customers"
          path={`/eterna-lash-brow/customers`}
          component={CustomersPage}
        />
        <PrivateRoute
          name="customerDetail"
          path={`/eterna-lash-brow/update-customer`}
          component={CustomerDetailPage}
        />
        <PrivateRoute
          name="profile"
          path={`/eterna-lash-brow/profile`}
          component={ProfilePage}
        />
        <PrivateRoute
          name="calendarReservation"
          path={`/eterna-lash-brow/calendar-reservation`}
          component={ReservationCalendarPage}
        />
        <PrivateRoute
          name="updatePassword"
          path={`/eterna-lash-brow/update-password`}
          component={UpdatePasswordPage}
        />

        <PrivateRoute
          name="services"
          path={`/${config.STORE_ID}/services`}
          component={ServicesPage}
        />
        <PrivateRoute
          name="manageStaffs"
          path={`/${config.STORE_ID}/staffs`}
          component={TechniciansPage}
        />
        <PrivateRoute
          name="updateTech"
          path={`/${config.STORE_ID}/update-technician`}
          component={TechnicianDetailPage}
        />
        <PrivateRoute
          name="inventory"
          path={`/${config.STORE_ID}/inventory`}
          component={Inventory}
        />
        <PrivateRoute
          name="payRoll"
          path={`/${config.STORE_ID}/pay-roll`}
          component={PayRoll}
        />

        <PrivateRoute
          name="closeout"
          path={`/${config.STORE_ID}/closeout`}
          component={Closeout}
        />
      </Switch>
    </React.Fragment>
  );
};

export default AppContent;
