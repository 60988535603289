/* eslint-disable */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {appActions} from '../../_actions'
import {appService} from '../../_services'
import {userService} from '../../_services'
import {helperFunctions} from '../../_helpers';
import Footer from '../footer/footer'
import './services.css';
import taskImg from '../../task.png'
import AddTask from "../../_components/addTask";
import InlineMessage from "../../_components/inlineMessage";
import utilities from "../../utils";

class ServicesPage extends Component {
    constructor(props) {
        super(props);
        const {dispatch} = this.props;
        this.app = JSON.parse(localStorage.getItem('app'));
        dispatch(appActions.getAllServices(0))

        dispatch(appActions.getCategories(0, 10));

        this.state = {
            pageOffset: 0,
            techPageOffset: 0,
            ctgryId: '',
            searchQuery: '',
            submitted: false,
            method: 'POST',
            sid: '',
            team: '',
            service: {
                id: '',
                name: '',
                active: true,
                visible: false,
                category: '',
                duration: '',
                minimumFee: '',
                maximumFee: '',
                description: '',
                pricingModel: 'Fixed Price',
                imageUrl: '',
                technicians: [],
                consentForms: [],
                durationInPTM: '',
                technicianServices: [],
                communicationTemplate: ''
            },

            taskSelected: {
                taskSelectedService: '',
                taskSelectedItemId: ''
            },

            selectedTech: {},
            key: [],

            serviceList: [],
            selectedOption: null,
            newSingleService: '',
            errors: {},
            selectedTeam: '',
            serviceAddTab: 'info',
            technicianId: [],
            techsFromApp: '',
            mode: '',
            min: '',
            max: '',
            durat: '',
            process: 0,
            techId: '',
            singleService: '',
            processingTime: 0,
            loading: false,
            item: [],
            bsnId: this.app.id,
            categories: []

        };


        userService.getAllTechnicians(0).then(res => {
            this.setState({
                ...this.state,
                techsFromApp: res
            })
        })


        // this.categories = [];
        // this.technicians = [];
        //
        // this.app.offeredServiceCategories.forEach(category => {
        //     this.categories.push(<option key={category.name} value={category.name}>{category.name}</option>);
        // })
        // this.app.locations[0].technicians.forEach(technician => {
        //     this.technicians.push(<option key={technician.id}
        //                                   value={technician.id}>{technician.user.fullName}</option>);
        // })

        this.handleSubmit = this.handleSubmit.bind(this);
        this.addServiceTechnicians = this.addServiceTechnicians.bind(this);
        //fetching business wise category
        this.getCategories();


    }

    getCategories() {
        appService.getCategories(0, 200).then(categories => {
            this.setState({categories})
        }).catch(e => {
            console.log(e)
        })
    }

    changeTech = (e) => {
        this.setState({
            selectedTech: {...this.state.selectedTech, [e.target.name]: e.target.value}
        });
    }

    openUpdateServiceModal = () => {
        /* eslint-disable */
        $('#updateService').modal('toggle');
    }


    addServiceTechnicians(e) {
        var options = e.target.options;
        var values = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                values.push(options[i].value);
            }
        }
        const service = this.state.service
        this.setState({
            service: {
                ...service,
                technicians: values
            }
        });
    }

    handleSubmit = async (e) => {
        e.preventDefault();

        this.setState({submitted: true});
        const {service} = this.state;

        if (service.name && service.category && service.duration && service.minimumFee && service.maximumFee) {

            const newService = {
                name: service.name,
                location: {
                    id: JSON.parse(localStorage.getItem('app')).locations[0].id
                },
                communicationTemplate: {
                    id: 1
                },
                category: service.category,
                description: service.description,
                duration: service.duration,
                minimumFee: service.minimumFee,
                maximumFee: service.maximumFee,
                imageUrl: service.imageUrl,
                active: service.active,
                technicians: []

            }
            this.state.service.technicians.forEach(techId => {
                newService.technicians.push({id: techId});
            })
            if (this.state.method == 'PUT')
                await appService.updateService(this.state.service.id, newService)
            else if (this.state.method == 'POST')
                await appService.createService(newService)

            const {dispatch} = this.props;
            dispatch(appActions.getAllServices("all", 0))
        }
    }

    handleUpdateInfoSubmit = async (e) => {
        e.preventDefault();
        this.setState({submitted: true});
        const {service} = this.state;
        const {dispatch} = this.props;

        const newService = {

            name: service.name,
            id: service.id,
            active: service.active,
            category: {id: service.category},
            duration: service.durationInPTM && service.durationInPTM != '' ? 'PT' + service.durationInPTM + 'M' : service.duration,
            minimumFee: service.pricingModel == 'Per Consult' ? -1 : service.pricingModel == 'Fixed Price' ? service.minimumFee : service.pricingModel == 'Starting Price' ? service.minimumFee : service.pricingModel == 'Price Range' ? service.minimumFee : '',
            maximumFee: service.pricingModel == 'Per Consult' ? -1 : service.pricingModel == 'Fixed Price' ? service.minimumFee : service.pricingModel == 'Starting Price' ? -1 : service.pricingModel == 'Price Range' ? service.maximumFee : '',


            description: service.description,
            processingTime: 'PT' + this.state.processingTime + 'M'

        }

        const NewServicePayLoad = {

            location: {
                id: JSON.parse(localStorage.getItem('app')).locations[0].id
            },
            service: newService,
            duration: newService.duration,
            processingTime: newService.processingTime,
            minimumFee: newService.minimumFee,
            maximumFee: newService.maximumFee,

        }


        if (this.state.method == 'PUT') {
            NewServicePayLoad['publiclyVisible'] = service.visible
            appService.updateService(this.state.sid, NewServicePayLoad).then(async response => {
                const {dispatch} = this.props;
                this.setState({
                    loading: false,
                    submitted: false
                })
                window.DataUpdateSuccess();
                response.technicianServices.map(async technician => {
                    await utilities.updateTechnicianService(technician.id, technician.technician.id, response.minimumFee, response.maximumFee, response.id, response.durationInMinutes, technician.processingTimeInMinutes);
                });
                dispatch(appActions.getAllServices(this.state.pageOffset, this.state.ctgryId, this.state.searchQuery))
            }).catch(e => {
                this.setState({
                    loading: false,
                    submitted: false
                })
                e.json().then(e => {
                    Swal.fire({
                        title: 'Error',
                        text: `${e.message}`,
                        type: 'error',
                        confirmButtonText: 'OK'
                    })
                })
            })


        } else if (this.state.method == 'POST') {
            const errors = this.validateServiceData(newService);

            this.setState({
                errors
            })

            if (Object.keys(errors).length == 0) {
                appService.createService(NewServicePayLoad).then(service => {

                    this.setState({
                        serviceAddTab: 'tech',
                        submitted: false,
                        errors: {},
                        sid: service.id
                    });
                    $('#addService').modal("toggle");
                    dispatch(appActions.getAllServices(this.state.pageOffset, this.state.ctgryId, this.state.searchQuery))
                }).catch(e => {
                    e.json().then(e => {
                        Swal.fire({
                            title: 'Error',
                            text: `${e.message}`,
                            type: 'error',
                            confirmButtonText: 'OK'
                        })
                    })
                    this.setState({
                        submitted: false
                    })
                });
            } else {
                this.setState({
                    submitted: false
                })
            }


        }


    }

    validateServiceData = (data) => {
        const errors = {}
        if (!data.name) errors.service = 'Please enter service name!';
        if (!data.duration) errors.duration = 'Please provide duration!';
        if (!data.category.id) errors.category = 'Please pick a category!';
        if (!data.minimumFee) errors.minimumFee = 'Please provide service price!';
        if (!data.maximumFee) errors.maximumFee = 'Please provide service price!';
        if (!data.description) errors.description = 'Please provide service description!';
        return errors;
    }


    changeHandler = (e) => {
        const {name, value} = e.target;
        const service = this.state.service
        if (name === "active") {
            this.setState({
                service: {
                    ...service,
                    active: !this.state.service.active
                }

            })

        } else if (name == 'visible') {
            this.setState({
                service: {
                    ...service,
                    visible: !this.state.service.visible
                }

            })
        } else if (name == 'consentForms') {
            if (e.target.checked) {
                this.setState({
                    service: {
                        ...service,
                        consentForms: this.state.service.consentForms.concat(value)
                    }
                })
            } else {
                this.setState({
                    service: {
                        ...service,
                        consentForms: this.state.service.consentForms.filter(form => form !== value)
                    }
                })
            }
        } else {
            this.setState({
                service: {
                    ...service,
                    [name]: value
                }
            });
        }

    }


    handleChange = (e) => {

        const {name, value} = e.target;
        if (name == 'selectedTeam') {
            this.setState({
                ...this.state,
                selectedTeam: value,
                submitted: true

            }, () => {
                this.searchTechnician()
            })

        } else {
            this.setState({
                ...this.state,
                [name]: value
            })
        }
    }


    setService(s) {
        var technicians = []
        s.technicians.forEach(tech => {
            technicians.push(tech.id);
        })
        this.setState({method: 'PUT'});
        this.setState({
            service: {
                id: s.id,
                name: s.name,
                active: s.active,
                category: s.category,
                duration: s.duration,
                minimumFee: s.minimumFee,
                maximumFee: s.maximumFee,
                description: s.description,
                imageUrl: s.imageUrl,
                technicians: technicians
            }
        })
    }

    getTechnicianNames(technicians) {
        var techNamesList = []
        technicians.forEach(visitTech => {
            var techName = this.findTechnicianName(visitTech.id)
            if (techNamesList.indexOf(techName) == -1) {
                techNamesList.push(techName)
            }
        });
        return techNamesList.join(", ")
    }

    componentDidMount = async () => {
        const metaInfo = await appService.checkVersion();
        await helperFunctions.clearCache(metaInfo)
        window.sidebarInitialize();
        window.sidebarClose();

    }


    checkCategoryForAll = (s, k) => {

        if (s) {
            if (typeof (s.parent) == "undefined") {
                let sum = this.makeCategoryList(s.id)
                return (
                    <React.Fragment key={k}>
                        {sum.length > 0 &&
                            <optgroup label={s.name}>
                                {this.makeCategoryList(s.id)}

                            </optgroup>
                        }
                        {sum.length == 0 &&
                            <option value={s.id}>{s.name}</option>

                        }

                    </React.Fragment>
                )

            }
        }
    }

    makeCategoryList = (ids) => {

        let {categories} = this.state;

        const fill = categories.filter(osc => typeof (osc.parent) != "undefined")
        const chld = fill.filter(f => f.parent.id == ids)
        let list = []


        chld.map((ch, key) => {
            if (ch.services.length > 0) {
                list.push(<option key={key} value={ch.id}>{ch.name}</option>)
            }
        })
        return list;

    }


    saveTask = (taskJSON) => {
        userService.saveTask(taskJSON)
            .then(() => {
                $('#addingTask').modal('toggle');
                window.DataUpdateSuccess()
            })
    }


    loadUpdateScreen = () => {
        const {dispatch} = this.props;
        dispatch(appActions.getAllServices(0));

        $('#updateService').modal('toggle');

    }


    searchByName = () => {
        appService.getAllServiceForAddingService(0, this.state.searchQuery).then(res => {
            this.setState({
                serviceList: res
            });
        })
    }


    selectedNewService = () => {
        const newSingleService = this.state.serviceList;


        let pricingModel = '';

        if (newSingleService.minimumFee == -1 && newSingleService.maximumFee == -1) {
            pricingModel = 'Per Consult'
        } else if (newSingleService.minimumFee > 0 && newSingleService.maximumFee > 0 && newSingleService.minimumFee != newSingleService.maximumFee) {
            pricingModel = 'Price Range'
        } else if (newSingleService.minimumFee > 0 && newSingleService.maximumFee == -1) {
            pricingModel = 'Starting Price'
        } else {
            pricingModel = 'Fixed Price'
        }

        this.setState({
            ...this.state,
            service: {
                ...this.state.service,
                id: newSingleService.id,
                name: newSingleService.name,
                active: newSingleService.active,
                visible: newSingleService.publiclyVisible,
                duration: newSingleService.duration,
                displayedPrice: newSingleService.displayedPrice,
                minimumFee: newSingleService.minimumFee,
                maximumFee: newSingleService.maximumFee,
                description: newSingleService.description,
                imageUrl: newSingleService.imageUrl,
                pricingModel: pricingModel,
                technicianServices: []

            },
            singleService: newSingleService,
            submitted: false
        })


    }

    searchService = () => {
        appService.getSingleServiceData(id = '', searchQuery = '').then((s) => {

            let pricingModel = '';

            if (s.minimumFee == -1 && s.maximumFee == -1) {

                pricingModel = 'Per Consult'
            } else if (s.minimumFee > 0 && s.maximumFee > 0 && s.minimumFee != s.maximumFee) {
                pricingModel = 'Price Range'
            } else if (s.minimumFee > 0 && s.maximumFee == -1) {
                pricingModel = 'Starting Price'
            } else {
                pricingModel = 'Fixed Price'
            }


            this.setState({
                method: 'POST',
                service: {
                    id: s.service.id,
                    name: s.service.name,
                    active: s.service.active,
                    visible: s.service.publiclyVisible,
                    category: s.service.category.id,
                    duration: s.duration,
                    minimumFee: s.minimumFee,
                    maximumFee: s.maximumFee,
                    description: s.service.description,
                    imageUrl: 'No Image',
                    technicians: s.technicianServices.map(t => ({
                        id: t.technician.id,
                        duration: t.duration,
                        minimumFee: t.minimumFee,
                        maximumFee: t.maximumFee
                    })),
                    pricingModel: pricingModel,
                    consentForms: s.service.consentForms,
                    durationInPTM: '',
                    tech: []
                }
            })
        })
    }

    changeSearchQuery = (e) => {
        this.setState({
            searchQuery: e.target.value
        });
    }


    onServiceSelect = (service) => {

        this.setState({
            ...this.state,
            serviceList: service,
            submitted: true
        }, () => {
            this.selectedNewService()
        })

    }

    closeUpdateService = () => {
        this.setState({
            service: {
                ...this.state.service,
                id: '',
                name: '',
                active: '',
                visible: '',
                duration: '',
                displayedPrice: '',
                minimumFee: '',
                maximumFee: '',
                description: '',
                imageUrl: 'No Image',
                pricingModel: '',
                technicianServices: [],
                category: '',
            },
            serviceAddTab: 'info'
        })
    }

    handleCheckbox = (e) => {
        if (e.target.checked) {
            this.setState({
                item: this.state.item.concat(e.target.value)
            })
        } else {
            this.setState({
                item: this.state.item.filter(i => i !== e.target.value)
            })
        }
    }


    render() {
        const {services, servicesLoaded, catLoader} = this.props;
        const {searchQuery, serviceType, service, categories, techsFromApp} = this.state;

        return (

            <div id="page-wrapper">

                <div className="search-sidebar">
                    <div className="closebtn">
                        <button><i className="ti ti-close"></i></button>
                    </div>
                    <div>
                        <div className="form-group">
                            <input type="text" className="form-control input-sm" value={this.state.service.searchQuery}
                                   name="searchQuery"
                                   onChange={this.handleChange} placeholder="Name, Price"/>

                            <button className="btn btn-block btn-info btn-sm"><i className="ti ti-search"></i></button>
                        </div>
                    </div>
                    <div className="or">OR</div>
                    <div>
                        <div className="form-group">
                            <select className="form-control input-sm" name="ctgryId"
                                    onChange={this.handleChange}>
                                <option>Type of Service</option>
                                {categories && categories.map((c, key) => {
                                    return <option key={key} value={c.id}>{c.name}</option>
                                })}
                            </select>
                            <button className="btn btn-block btn-info btn-sm"><i className="ti ti-search"></i></button>
                        </div>
                    </div>
                </div>


                <div className="container-fluid page-title">
                    <div className="row bg-title search-row-mobile">

                        <div className="mobile-search-option">
                            <div className="paged">
                                <ul className="pager">
                                    <li><a onClick={() => {
                                        this.getPreviousPage()
                                    }}><i className="fa fa-angle-left"></i></a>
                                    </li>
                                    <li><a onClick={() => {
                                        this.getNextPage()
                                    }}><i className="fa fa-angle-right"></i></a>
                                    </li>
                                </ul>
                            </div>

                            <div className="search-option">
                                <button className="sidebar-trigger"><i className="fa fa-filter"></i></button>
                            </div>
                            <div className="clear-filter">
                                <button onClick={() => this.resetFilters()}>Clear Filter</button>
                            </div>
                            <div className="add-customer">
                                <a onClick={() => this.setState({method: 'POST'})} href="#addService" className="btn"
                                   data-toggle="modal">+ Add</a>
                            </div>

                        </div>

                    </div>
                    <div className="row bg-title desktop-search custom-header">
                        <div className="col-lg-1 col-md-4 col-sm-2 col-xs-12">
                            <h4 className="page-title">services</h4>
                        </div>
                        <div className="col-md-8 col-sm-10">
                            <div className="row">
                                <div className="col-md-5 col-sm-5 d-flex">
                                    <div className="form-group">
                                        <input type="text" className="form-control input-sm service-search-field"
                                               value={this.state.service.searchQuery}
                                               name="searchQuery" onChange={this.handleChange}
                                               placeholder="Name, price"/>
                                    </div>
                                    <div className="form-group">
                                        <select className="form-control input-sm" name="ctgryId"
                                                onChange={this.handleChange}>
                                            <optgroup label="">
                                                <option>Search By Category</option>
                                            </optgroup>
                                            {categories && categories.length > 0 && categories.map((c, key) => {
                                                return this.checkCategoryForAll(c, key)
                                            })}
                                        </select>
                                    </div>
                                    <button className="btn btn-info btn-sm search-button" onClick={() => {
                                        this.searchServicesByCategory()
                                    }}><i className={"fa fa-search"}></i>
                                    </button>
                                </div>

                                <div className="col-md-3 col-sm-4 col-lg-3 col-xs-6">
                                    <button onClick={() => {
                                        this.resetFilters()
                                    }} className="btn btn-warning btn-sm">Clear Filters
                                    </button>
                                    &nbsp;
                                    {/*<a onClick={() => this.setState({method: 'POST'})} href="#addService"*/}
                                    {/*   className="btn btn-custom-info btn-sm" data-toggle="modal">Add*/}
                                    {/*    New</a>*/}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <ul className="pager pull-right service-pager">
                                <li><a onClick={() => {
                                    this.getPreviousPage()
                                }}><i className="fa fa-angle-left"></i></a>
                                </li>
                                <li><a onClick={() => {
                                    this.getNextPage()
                                }}><i className="fa fa-angle-right"></i></a>
                                </li>
                            </ul>
                            <a
                                onClick={() => this.setState({method: 'POST'})}
                                href="#addService"
                                className="btn btn-custom-info btn-sm pull-right"
                                data-toggle="modal">Add New</a>
                        </div>
                    </div>


                    {
                        services && servicesLoaded == false &&
                        <React.Fragment>
                            <div className="col-md-4 col-sm-6 col-xs-12 single-customer">
                                <div className="loading"></div>
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-12 single-customer">
                                <div className="loading"></div>
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-12 single-customer">
                                <div className="loading"></div>
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-12 single-customer">
                                <div className="loading"></div>
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-12 single-customer">
                                <div className="loading"></div>
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-12 single-customer">
                                <div className="loading"></div>
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-12 single-customer">
                                <div className="loading"></div>
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-12 single-customer">
                                <div className="loading"></div>
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-12 single-customer">
                                <div className="loading"></div>
                            </div>
                        </React.Fragment>
                    }

                    {
                        services && servicesLoaded == true && services.length == 0 &&
                        <h2 className="center">No services Found</h2>
                    }


                    {services && servicesLoaded == true && services.map((s, key) => {


                        return <div key={key} className="col-md-4 col-sm-6">
                            <div className="white-box service-white-box">
                                <div className="service-card">
                                    <div className="service-thumb">

                                        <img src={this.searchCategoryNameImage(s.service.category.id)?.image}
                                             alt="category"/>

                                        <div className="upper-border"></div>
                                    </div>

                                    <div className="service-info">
                                        <div className="info-left">
                                            <h3 className="box-title"><strong><br/>{s.service.name}</strong></h3>
                                            <div className="price">
                                                <p>Price: {s.displayedPrice}</p>
                                            </div>
                                            <p>
                                                <strong>Category: </strong> {this.searchCategoryNameImage(s.service.category.id)?.name}
                                            </p>
                                        </div>
                                        <div className="service-action pull-left">
                                            <button
                                                onClick={() => this.getSingleServiceData(s.id)}
                                                data-toggle="modal" data-target="#updateService"
                                                className="btn btn-custom-info">Edit
                                            </button>
                                            <button
                                                onClick={() => this.taskSelectedService(s.id)}
                                                title="Add Task"
                                                data-toggle="modal"
                                                data-target="#addingTask"
                                                className="btn btn-task">
                                                <img width="20" src={taskImg} alt=""/>
                                            </button>
                                        </div>
                                        <div className="info-right">
                                            <span>{helperFunctions.timeConvert(s.duration)}</span>
                                        </div>

                                    </div>

                                </div>

                                <div className="tech-thumb pull-right">
                                    {/*<div className="border-bottom"></div>*/}
                                    <img
                                        src={s.technicianServices.length > 0 ? s.technicianServices[0].technician.user.imageUrl : 'https://s3.amazonaws.com/zoracorp/bianca/default/user-no-image.png'}
                                        alt=""/>
                                    <div className="more more-tech">
                                        <i className="fa fa-ellipsis-h"></i>
                                    </div>

                                    {s.technicianServices.length > 0 &&
                                        <React.Fragment>

                                            <div className="menu-round">

                                                {
                                                    s.technicianServices.map((t, k) => {
                                                        if (k < 3) {
                                                            return <div key={k} className="btn-app">
                                                                <img
                                                                    src={t.technician.user.imageUrl}
                                                                    alt={t.technician.user.fullName}/>
                                                            </div>
                                                        }
                                                    })

                                                }


                                            </div>
                                            <div className="menu-line">
                                                {
                                                    s.technicianServices.map((t, k) => {
                                                        if (k > 3) {
                                                            return <div key={k} className="btn-app">
                                                                <img
                                                                    src={t.technician.user.imageUrl}
                                                                    alt={t.technician.user.fullName}/>
                                                            </div>
                                                        }
                                                    })

                                                }
                                            </div>
                                        </React.Fragment>

                                    }


                                </div>

                            </div>
                        </div>
                    })

                    }


                    <div data-keyboard="false" data-backdrop="false" className="modal fade" id="addService"
                         tabIndex="-1" role="dialog"
                         aria-labelledby="exampleModalLabel1">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content" data-height="700">
                                <div className="modal-header">
                                    <button onClick={this.clearSearchFilter} type="button" className="close"
                                            data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span></button>
                                    <h3 className="modal-title" id="exampleModalLabel1"><strong>Add a
                                        service &nbsp;</strong>

                                        <span
                                            className="text-danger">{this.state.errors.exist &&
                                            <InlineMessage text={this.state.errors.exist}/>}</span></h3>
                                </div>
                                <div className="modal-body services-modal-body">


                                    {this.state.serviceAddTab == 'info' &&

                                        <div className="serviceInfo">

                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group duration">
                                                        <label>Service Name &nbsp; <span
                                                            className="text-danger">*</span></label>
                                                        <input
                                                            type="text"
                                                            name="name"
                                                            onChange={this.changeHandler}
                                                            value={service.name}
                                                        />
                                                        <p className="text-danger">{this.state.errors.service &&
                                                            <InlineMessage text={this.state.errors.service}/>}</p>

                                                    </div>
                                                </div>

                                                <div className="col-md-6 col-lg-6 col-sm-12">
                                                    <div className="form-group duration">
                                                        <label>Duration &nbsp; <span
                                                            className="text-danger">*</span></label>
                                                        <div className="input-group">

                                                            <input
                                                                required
                                                                type="text"
                                                                name="durationInPTM"
                                                                onChange={this.changeHandler}
                                                                defaultValue={service.duration && helperFunctions.timeConvert(`${service.duration}`, 'minute')}

                                                                className="form-control"/>
                                                            <span className="input-group-addon">
                                                                    <span>mins</span>
                                                                </span>


                                                        </div>
                                                        <p className="text-danger">{this.state.errors.duration &&
                                                            <InlineMessage text={this.state.errors.duration}/>}</p>

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Processing Time (in minutes) &nbsp; <span
                                                            className="text-danger">*</span></label>
                                                        <input
                                                            type="text"
                                                            name="processingTime"
                                                            onChange={this.handleChange}
                                                            value={this.state.processingTime}
                                                        />
                                                    </div>
                                                </div>


                                                <div className="col-md-6">

                                                    <div className="form-group">

                                                        <label htmlFor="priceType">Price Type</label>
                                                        <select
                                                            className="form-control"
                                                            name="pricingModel"
                                                            onChange={this.changeHandler}
                                                            value={service.pricingModel}>
                                                            <option value="Per Consult">Per Consult</option>
                                                            <option value="Starting Price">Starting Price</option>
                                                            <option value="Fixed Price">Fixed Price</option>
                                                            <option value="Price Range">Price Range</option>
                                                        </select>

                                                    </div>

                                                </div>
                                            </div>


                                            <div className="row">

                                                {service.pricingModel == 'Starting Price' &&
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>Starting Price &nbsp; <span
                                                                className="text-danger">*</span></label>
                                                            <input
                                                                required
                                                                maxLength="9"
                                                                step="0.01"
                                                                type="text"
                                                                name="minimumFee"
                                                                onChange={this.changeHandler}
                                                                value={service.minimumFee}
                                                                className="form-control"/>
                                                            <p className="text-danger">{this.state.errors.minimumFee &&
                                                                <InlineMessage
                                                                    text={this.state.errors.minimumFee}/>}</p>
                                                        </div>
                                                    </div>
                                                }

                                                {service.pricingModel == 'Fixed Price' &&

                                                    <div className="col-md-12">

                                                        <div className="form-group">
                                                            <label>Price: &nbsp; <span
                                                                className="text-danger">*</span></label>
                                                            <input
                                                                required
                                                                maxLength="9"
                                                                step="0.01"
                                                                type="text"
                                                                name="fee"
                                                                name="minimumFee"
                                                                onChange={this.changeHandler}
                                                                value={service.minimumFee}
                                                                className="form-control"/>
                                                            <p className="text-danger">{this.state.errors.minimumFee &&
                                                                <InlineMessage
                                                                    text={this.state.errors.minimumFee}/>}</p>
                                                        </div>
                                                    </div>

                                                }

                                                {service.pricingModel == 'Price Range' &&
                                                    <div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>Min. Price: &nbsp; <span
                                                                    className="text-danger">*</span></label>
                                                                <input
                                                                    required
                                                                    maxLength="9"
                                                                    step="0.01"
                                                                    type="text"
                                                                    name="fee"
                                                                    name="minimumFee"
                                                                    onChange={this.changeHandler}
                                                                    value={service.minimumFee}
                                                                    className="form-control"/>
                                                                <p className="text-danger">{this.state.errors.minimumFee &&
                                                                    <InlineMessage
                                                                        text={this.state.errors.minimumFee}/>}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>Max. Price &nbsp; <span
                                                                    className="text-danger">*</span></label>
                                                                <input
                                                                    required
                                                                    maxLength="9"
                                                                    step="0.01"
                                                                    type="text" name="ee"
                                                                    name="maximumFee"
                                                                    onChange={this.changeHandler}
                                                                    value={service.maximumFee}
                                                                    className="form-control"/>
                                                                <p className="text-danger">{this.state.errors.maximumFee &&
                                                                    <InlineMessage
                                                                        text={this.state.errors.maximumFee}/>}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }


                                            </div>
                                            <div className={"row"}>
                                                <div className={"col-md-12"}>
                                                    <div className="form-group">
                                                        <label>Choose a category &nbsp; <span
                                                            className="text-danger">*</span></label>
                                                        <select value={service.category}
                                                                className="form-control input-sm" name="category"
                                                                onChange={this.changeHandler}>
                                                            <optgroup label="">
                                                                <option>Search By Category</option>
                                                            </optgroup>
                                                            {categories && categories.length > 0 && categories.map((c, key) => {
                                                                return this.checkCategoryForAll(c, key)
                                                            })}
                                                        </select>
                                                        <p className="text-danger">{this.state.errors.category &&
                                                            <InlineMessage text={this.state.errors.category}/>}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12">

                                                    <div className="form-group">
                                                        <label>Description &nbsp; <span
                                                            className="text-danger">*</span></label>
                                                        <textarea
                                                            maxLength="100"
                                                            required
                                                            className="form-control"
                                                            name="description"
                                                            onChange={this.changeHandler}
                                                            value={service.description}>
                                                        </textarea>
                                                        <p className="text-danger">{this.state.errors.description &&
                                                            <InlineMessage text={this.state.errors.description}/>}</p>
                                                    </div>
                                                </div>

                                                {/*<div className="col-md-6">*/}
                                                {/*    <div className="form-group">*/}
                                                {/*        <label>Consent Forms</label>*/}
                                                {/*        {service.consentForms && service.consentForms.length == 0 &&*/}
                                                {/*            <label>NONE</label>}*/}
                                                {/*        {service.consentForms && service.consentForms.length > 0 && service.consentForms.map((c, k) => {*/}
                                                {/*            return <label key={k}><i*/}
                                                {/*                className="fa fa-check"></i> {this.findConsentForms(c.id)}*/}
                                                {/*            </label>*/}
                                                {/*        })}*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}


                                            </div>


                                            <div className="row">
                                                <div className="col-md-3 d-flex align-items-center">
                                                    <strong>Visible: </strong>
                                                    <label className="switch m-l-5">
                                                        <input type="checkbox" name="visible"
                                                               checked={service.visible} readOnly
                                                               onClick={this.changeHandler}/>
                                                        <span className="switchSlider"></span>
                                                    </label>
                                                </div>

                                                <div className="col-md-3 d-flex align-items-center">
                                                    <strong>Activation: </strong>
                                                    <label className="switch m-l-5">
                                                        <input type="checkbox" name="active"
                                                               checked={service.active} readOnly
                                                               onClick={this.changeHandler}/>
                                                        <span className="switchSlider"></span>
                                                    </label>
                                                </div>


                                            </div>


                                        </div>

                                    }

                                    {/*{this.state.serviceAddTab == 'tech' &&*/}
                                    {/*    <React.Fragment>*/}
                                    {/*        <div className="row">*/}
                                    {/*            <div className="col-md-4 tech">*/}

                                    {/*                <div className="form-group">*/}
                                    {/*                    <select*/}
                                    {/*                        value={this.state.selectedTeam}*/}
                                    {/*                        name="selectedTeam"*/}
                                    {/*                        onChange={this.handleChange}*/}
                                    {/*                        className="form-control">*/}
                                    {/*                        <option value="all">All Teams</option>*/}
                                    {/*                        {this.app && this.app.lookupGroup.teams.map((team, key) => {*/}
                                    {/*                            return <option key={key}*/}
                                    {/*                                           value={team}>{team}</option>*/}
                                    {/*                        })}*/}
                                    {/*                    </select>*/}
                                    {/*                </div>*/}

                                    {/*            </div>*/}
                                    {/*            <div className="col-md-5">*/}

                                    {/*                <button onClick={this.clearTechFilter}*/}
                                    {/*                        className="btn btn-warning pull-right">Clear Filters*/}
                                    {/*                </button>*/}

                                    {/*            </div>*/}
                                    {/*            <div className="col-md-3">*/}
                                    {/*                <ul className="pager pull-right">*/}
                                    {/*                    <li><a onClick={() => {*/}
                                    {/*                        this.getPreviousTechPage()*/}
                                    {/*                    }}><i className="fa fa-angle-left"></i></a>*/}
                                    {/*                    </li>*/}
                                    {/*                    <li><a onClick={() => {*/}
                                    {/*                        this.getNextTechPage()*/}
                                    {/*                    }}><i className="fa fa-angle-right"></i></a>*/}
                                    {/*                    </li>*/}
                                    {/*                </ul>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}

                                    {/*        <div className="tech-drop">*/}
                                    {/*            <div className="table-responsive">*/}
                                    {/*                <table id="add-tech-table"*/}
                                    {/*                       className="table table-staff table-striped">*/}
                                    {/*                    <thead>*/}
                                    {/*                    <tr>*/}
                                    {/*                        <th>Staff Name</th>*/}
                                    {/*                        <th>Price</th>*/}
                                    {/*                        <th>Duration</th>*/}
                                    {/*                        <th>Process</th>*/}
                                    {/*                        <th>Retention</th>*/}
                                    {/*                        <th>Action</th>*/}
                                    {/*                    </tr>*/}

                                    {/*                    </thead>*/}

                                    {/*                    <tbody>{techsFromApp.length > 0 && techsFromApp.filter(t => t.active == true).map((t, key) => {*/}

                                    {/*                        return this.checkAvailableTechnician(t.user.id, t, key)*/}

                                    {/*                    })}</tbody>*/}
                                    {/*                </table>*/}


                                    {/*            </div>*/}

                                    {/*        </div>*/}
                                    {/*    </React.Fragment>}*/}

                                </div>

                                <div className="modal-footer">
                                    {this.state.serviceAddTab == 'info' &&
                                        <button
                                            onClick={this.handleUpdateInfoSubmit}
                                            className="btn btn-custom-info">Add Service Info
                                        </button>
                                    }
                                </div>

                                {this.state.submitted &&

                                    <div className="custom-loading">
                                        <div className="loading"><i className="fa fa-spinner fa-spin"></i></div>
                                    </div>

                                }


                            </div>
                        </div>
                    </div>


                    <div data-keyboard="false" data-backdrop="false" className="modal fade" id="updateService"
                         tabIndex="-1" role="dialog"
                         aria-labelledby="exampleModalLabel1">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content" data-height="700">
                                <div className="modal-header">
                                    <button onClick={this.closeUpdateService} type="button" className="close"
                                            data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span></button>
                                    <h4 className="modal-title" id="exampleModalLabel1">Update Service</h4>
                                </div>

                                <div className="modal-body services-modal-body">

                                    <ul className="nav nav-tabs tabs customtab" role="tablist">
                                        <li role="presentation" className="active"><a
                                            onClick={() => this.setState({serviceAddTab: 'info'})} href="#info"
                                            aria-controls="home"
                                            role="tab" data-toggle="tab">Service
                                            Info</a></li>
                                        <li role="presentation"><a
                                            onClick={() => this.setState({serviceAddTab: 'tech'})} href="#technicians"
                                            aria-controls="profile"
                                            role="tab" data-toggle="tab">Technicians</a></li>
                                    </ul>

                                    <div className="tab-content">
                                        <div role="tabpanel" className="tab-pane active" id="info">

                                            <div className="row">
                                                <div className="col-md-6 col-lg-6 col-sm-12">
                                                    <div className="form-group">
                                                        <label>Service Name &nbsp; <span
                                                            className="text-danger">*</span></label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            onChange={this.changeHandler}
                                                            maxLength="100"
                                                            defaultValue={service.name}
                                                            name="name"
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-6 col-lg-6 col-sm-12">
                                                    <div className="form-group">
                                                        <label>Duration &nbsp; <span
                                                            className="text-danger">*</span></label>
                                                        <div className="input-group">

                                                            <input
                                                                required
                                                                type="text"
                                                                name="durationInPTM"
                                                                onChange={this.changeHandler}
                                                                defaultValue={service.duration && helperFunctions.timeConvert(`${service.duration}`, 'minute')}

                                                                className="form-control"/>
                                                            <span className="input-group-addon">
                                                                        <span>mins</span>
                                                                    </span>

                                                        </div>

                                                    </div>
                                                </div>


                                            </div>


                                            <div className="row">
                                                <div className="col-md-4 col-lg-4 col-sm-12">
                                                    <div className="form-group">
                                                        <label>Category &nbsp; <span
                                                            className="text-danger">*</span></label>
                                                        <select
                                                            disabled
                                                            name="category"
                                                            value={service.category}
                                                            onChange={this.changeHandler}
                                                            className="form-control">
                                                            <option>Choose Category</option>
                                                            {this.app.offeredServiceCategories && this.app.offeredServiceCategories.map((c, key) => {
                                                                return <option key={key}
                                                                               value={c.id}>{c.name}</option>
                                                            })}

                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-md-8 col-lg-8 col-sm-12">
                                                    <div className="form-group">
                                                        <label>Image URL &nbsp; <span
                                                            className="text-danger">*</span></label>
                                                        <input
                                                            disabled
                                                            maxLength="300"
                                                            type="text"
                                                            name="imageUrl"
                                                            onChange={this.changeHandler}
                                                            value={service.imageUrl ? service.imageUrl : 'NONE'}
                                                            className="form-control"/>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-4">

                                                    <div className="form-group">

                                                        <label htmlFor="priceType">Price Type</label>
                                                        <select
                                                            name="pricingModel"
                                                            onChange={this.changeHandler}
                                                            value={service.pricingModel}>
                                                            <option value="Per Consult">Per Consult</option>
                                                            <option value="Starting Price">Starting Price</option>
                                                            <option value="Fixed Price">Fixed Price</option>
                                                            <option value="Price Range">Price Range</option>
                                                        </select>

                                                    </div>

                                                </div>


                                                {service.pricingModel == 'Starting Price' &&
                                                    <div className="col-md-8">
                                                        <div className="form-group">
                                                            <label>Starting Price &nbsp; <span
                                                                className="text-danger">*</span></label>
                                                            <input
                                                                required
                                                                maxLength="9"
                                                                step="0.01"
                                                                type="text"
                                                                name="minimumFee"
                                                                onChange={this.changeHandler}
                                                                value={service.minimumFee}
                                                                className="form-control"/>
                                                        </div>
                                                    </div>
                                                }

                                                {service.pricingModel == 'Fixed Price' &&

                                                    <div className="col-md-8">

                                                        <div className="form-group">
                                                            <label>Price: &nbsp; <span
                                                                className="text-danger">*</span></label>
                                                            <input
                                                                required
                                                                maxLength="9"
                                                                step="0.01"
                                                                type="text"
                                                                name="fee"
                                                                name="minimumFee"
                                                                onChange={this.changeHandler}
                                                                value={service.minimumFee}
                                                                className="form-control"/>
                                                        </div>
                                                    </div>

                                                }

                                                {service.pricingModel == 'Price Range' &&
                                                    <div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label>Min. Price: &nbsp; <span
                                                                    className="text-danger">*</span></label>
                                                                <input
                                                                    required
                                                                    maxLength="9"
                                                                    step="0.01"
                                                                    type="text"
                                                                    name="fee"
                                                                    name="minimumFee"
                                                                    onChange={this.changeHandler}
                                                                    value={service.minimumFee}
                                                                    className="form-control"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label>Max. Price &nbsp; <span
                                                                    className="text-danger">*</span></label>
                                                                <input
                                                                    required
                                                                    maxLength="9"
                                                                    step="0.01"
                                                                    type="text" name="ee"
                                                                    name="maximumFee"
                                                                    onChange={this.changeHandler}
                                                                    value={service.maximumFee}
                                                                    className="form-control"/>
                                                            </div>
                                                        </div>
                                                    </div>


                                                }
                                            </div>


                                            <div className="row">
                                                <div className="col-md-7">

                                                    <div className="form-group">
                                                        <label>Description &nbsp; <span
                                                            className="text-danger">*</span></label>
                                                        <textarea
                                                            maxLength="100"
                                                            required
                                                            className="form-control"
                                                            name="description"
                                                            onChange={this.changeHandler}
                                                            value={service.description}
                                                        ></textarea>
                                                    </div>
                                                </div>

                                                <div className="col-md-5">
                                                    <div className="form-group">
                                                        <label>Consent Forms</label>
                                                        {service.consentForms && service.consentForms.length == 0 &&
                                                            <label>NONE</label>}
                                                        {service.consentForms && service.consentForms.length > 0 && service.consentForms.map((c, k) => {
                                                            return <label key={k}><i
                                                                className="fa fa-check"></i> {this.findConsentForms(c.id)}
                                                            </label>
                                                        })}


                                                    </div>
                                                </div>


                                            </div>


                                            <div className="row">
                                                <div className="col-md-1">
                                                    <strong>Active: </strong>
                                                    <label className="switch">
                                                        <input onClick={this.changeHandler} type="checkbox"
                                                               name="active"
                                                               checked={service.active} readOnly
                                                        />
                                                        <span className="switchSlider"></span>
                                                    </label>
                                                </div>

                                                <div className="col-md-1">
                                                    <strong>Visible: </strong>
                                                    <label className="switch">
                                                        <input type="checkbox" name="visible"
                                                               checked={service.visible} readOnly
                                                               onClick={this.changeHandler}
                                                        />
                                                        <span className="switchSlider"></span>
                                                    </label>
                                                </div>
                                            </div>


                                        </div>
                                        <div role="tabpanel" className="tab-pane" id="technicians">


                                            <React.Fragment>

                                                <div className="row">
                                                    <div className="col-md-4 tech">

                                                        <div className="form-group">


                                                            <select
                                                                value={this.state.selectedTeam}
                                                                name="selectedTeam"
                                                                onChange={this.handleChange}
                                                                className="form-control">
                                                                <option>All Teams</option>
                                                                {this.app && this.app.lookupGroup.teams.map((team, key) => {
                                                                    return <option key={key}
                                                                                   value={team}>{team}</option>
                                                                })}
                                                            </select>


                                                        </div>

                                                    </div>
                                                    <div className="col-md-5">
                                                        <ul className="pager pull-right">
                                                            <li><a onClick={() => {
                                                                this.getPreviousTechPage()
                                                            }}><i className="fa fa-angle-left"></i></a>
                                                            </li>
                                                            <li><a onClick={() => {
                                                                this.getNextTechPage()
                                                            }}><i className="fa fa-angle-right"></i></a>
                                                            </li>
                                                        </ul>
                                                        <button onClick={this.clearTechFilter}
                                                                className="btn btn-warning pull-right">Clear Filters
                                                        </button>

                                                    </div>

                                                    <div className="col-md-1">

                                                        <button data-target="#addNewServiceToTech" data-toggle="modal"
                                                                onClick={this.clearTechFilter}
                                                                className="btn default pull-right">Add New
                                                        </button>

                                                    </div>


                                                    <div>

                                                        <div className="tech-drop">
                                                            <div className="table-responsive">
                                                                <table id="included-service-table"
                                                                       className="table table-staff table-striped">
                                                                    <thead>
                                                                    <tr>
                                                                        <th>Staff Name</th>
                                                                        <th>Price</th>
                                                                        <th>Duration</th>
                                                                        <th>Process</th>
                                                                        <th>Retention</th>
                                                                        <th>Action</th>
                                                                    </tr>

                                                                    </thead>

                                                                    <tbody>{techsFromApp.length > 0 && techsFromApp.filter(t => t.active == true).map((t, key) => {

                                                                        return this.checkIncludedTechnician(t.id, t, key)


                                                                    })}</tbody>
                                                                </table>
                                                                {this.state.item.length > 0 &&
                                                                    <div>
                                                                        <button className="deleteSelected"
                                                                                onClick={this.deleteServiceTechnician}>Delete
                                                                            Selected
                                                                        </button>
                                                                    </div>

                                                                }


                                                            </div>

                                                        </div>
                                                    </div>


                                                </div>
                                            </React.Fragment>


                                        </div>
                                    </div>

                                </div>

                                {this.state.serviceAddTab == "info" &&

                                    <div className="modal-footer">

                                        <button className="fcbtn btn btn-custom-info"
                                                onClick={this.handleUpdateInfoSubmit}>Update Info
                                        </button>

                                    </div>
                                }


                                {this.state.submitted == true &&
                                    <div className="custom-loading">
                                        <div className="loading"><i className="fa fa-spinner fa-spin"></i></div>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                    <div data-keyboard="false" data-backdrop="false" className="modal fade" id="addNewServiceToTech"
                         tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button onClick={this.openUpdateServiceModal} type="button" className="close"
                                            data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    <h4 className="modal-title">Add technicians to the Service</h4>
                                </div>
                                <div className="modal-body">

                                    <div className="row">
                                        <div className="col-md-4 tech">

                                            <div className="form-group">

                                                <select
                                                    value={this.state.selectedTeam}
                                                    name="selectedTeam"
                                                    onChange={this.handleChange}
                                                    className="form-control">
                                                    <option>All Teams</option>
                                                    {this.app && this.app.lookupGroup.teams.map((team, key) => {
                                                        return <option key={key}
                                                                       value={team}>{team}</option>
                                                    })}
                                                </select>


                                            </div>

                                        </div>
                                        <div className="col-md-5">

                                            <button onClick={this.clearTechFilterForAvailable}
                                                    className="btn btn-warning pull-right">Clear Filters
                                            </button>

                                        </div>
                                        <div className="col-md-3">
                                            <ul className="pager pull-right">
                                                <li><a onClick={() => {
                                                    this.getPreviousTechPage()
                                                }}><i className="fa fa-angle-left"></i></a>
                                                </li>
                                                <li><a onClick={() => {
                                                    this.getNextTechPage()
                                                }}><i className="fa fa-angle-right"></i></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="tech-drop">
                                        <div className="table-responsive">
                                            <table className="table table-staff table-striped">
                                                <thead>
                                                <tr>
                                                    <th>Staff Name</th>
                                                    <th>Price</th>
                                                    <th>Duration</th>
                                                    <th>Process</th>
                                                    <th>Retention</th>
                                                    <th>Action</th>
                                                </tr>

                                                </thead>

                                                <tbody>{techsFromApp.length > 0 && techsFromApp.filter(t => t.active == true).map((t, key) => {

                                                    return this.checkAvailableTechnician(t.id, t, key)


                                                })}</tbody>
                                            </table>

                                            {this.state.item.length > 0 &&
                                                <div>
                                                    <button className="addSelelcted"
                                                            onClick={this.addNewTechToService}>Add Selected
                                                    </button>
                                                </div>

                                            }


                                        </div>

                                    </div>
                                </div>
                                {this.state.submitted &&

                                    <div className="custom-loading">
                                        <div className="loading"><i className="fa fa-spinner fa-spin"></i></div>
                                    </div>

                                }


                            </div>

                        </div>

                    </div>


                    <div className="modal fade" id="updateStaffService" data-backdrop="static" data-keyboard="false">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <button
                                        data-toggle="modal"
                                        data-target="#updateService"
                                        onClick={this.resetSelectedService} className="btn close"
                                        data-dismiss="modal">&times;</button>
                                    <hr/>


                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Price Type</label>
                                                <select
                                                    name="mode"
                                                    value={this.state.mode}
                                                    onChange={this.handleChange}>
                                                    <option
                                                        value="Price Range">Price Range
                                                    </option>
                                                    <option
                                                        value="Starting Price">Starting Price
                                                    </option>
                                                    <option
                                                        value="Fixed Price">FixedPrice
                                                    </option>
                                                    <option value="Per Consult">Per Consult
                                                    </option>

                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Duration</label>
                                                <input
                                                    type="text"
                                                    name="durat"
                                                    value={this.state.durat}
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        {this.state.mode == 'Fixed Price' &&
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Minimum Fee</label>
                                                    <input
                                                        type="text"
                                                        name="min"
                                                        value={this.state.min && (this.state.min * 1).toFixed(2)}
                                                        className="form-control"
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                            </div>}
                                        {this.state.mode == 'Starting Price' &&
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Minimum Fee</label>
                                                    <input
                                                        type="text"
                                                        name="min"
                                                        value={this.state.min && (this.state.min * 1).toFixed(2)}
                                                        className="form-control"
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {this.state.mode == 'Price Range' &&
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Minimum Fee</label>
                                                    <input
                                                        type="text"
                                                        name="min"
                                                        value={this.state.min && (this.state.min * 1).toFixed(2)}
                                                        className="form-control"
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {this.state.mode == 'Price Range' &&
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Maximum Fee</label>
                                                    <input
                                                        type="text"
                                                        name="max"
                                                        value={this.state.max && (this.state.max * 1).toFixed(2)}
                                                        onChange={this.handleChange}
                                                        className="form-control"
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                            </div>}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Processing Time</label>
                                                <input
                                                    type="text"
                                                    name="process"
                                                    value={this.state.process}
                                                    onChange={this.handleChange}
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="switcher-spacer"></div>
                                            <div className="form-group">
                                                <button onClick={this.deleteServiceTechnician}
                                                        className="btn btn-danger pull-right">Delete
                                                </button>
                                                <button onClick={this.updateServiceTechnician}
                                                        className="btn btn-custom-info pull-right update">Update
                                                </button>

                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade" id="addServiceForm" data-backdrop="static" data-keyboard="false">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <button onClick={this.resetSelectedService} className="btn close"
                                            data-dismiss="modal">&times;</button>
                                    <br/>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Price Type</label>
                                                <select
                                                    name="mode"
                                                    value={this.state.mode}
                                                    onChange={this.handleChange}>

                                                    <option
                                                        value="Price Range">Price Range
                                                    </option>
                                                    <option
                                                        value="Starting Price">Starting Price
                                                    </option>
                                                    <option
                                                        value="Fixed Price">FixedPrice
                                                    </option>
                                                    <option value="Per Consult">Per Consult
                                                    </option>

                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Duration</label>
                                                <input
                                                    type="text"
                                                    name="durat"
                                                    value={this.state.durat}
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        {this.state.mode == 'Fixed Price' &&
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Minimum Fee</label>
                                                    <input
                                                        type="text"
                                                        name="min"
                                                        defaultValue={this.state.min && (this.state.min * 1).toFixed(2)}
                                                        className="form-control"
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                            </div>}
                                        {this.state.mode == 'Starting Price' &&
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Minimum Fee</label>
                                                    <input
                                                        type="text"
                                                        name="min"
                                                        defaultValue={this.state.min && (this.state.min * 1).toFixed(2)}
                                                        className="form-control"
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {this.state.mode == 'Price Range' &&
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Minimum Fee</label>
                                                    <input
                                                        type="text"
                                                        name="min"
                                                        defaultValue={this.state.min && (this.state.min * 1).toFixed(2)}
                                                        className="form-control"
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {this.state.mode == 'Price Range' &&
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Maximum Fee</label>
                                                    <input
                                                        type="text"
                                                        name="max"
                                                        defaultValue={this.state.max && (this.state.max * 1).toFixed(2)}
                                                        onChange={this.handleChange}
                                                        className="form-control"
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                            </div>}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Processing Time</label>
                                                <input
                                                    type="text"
                                                    name="process"
                                                    defaultValue={this.state.process}
                                                    onChange={this.handleChange}
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                <div className="modal-footer">
                                    <button onClick={this.addNewTechToService}
                                            className="btn btn-custom-info pull-right">Add Technicians
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="modal fade" id="addingTask" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    <h4 className="modal-title">Add Task</h4>
                                </div>
                                <div className="modal-body">
                                    <AddTask
                                        statas="disabled"
                                        predefinedRelatedItem={this.state.taskSelected.taskSelectedService}
                                        predefinedRelatedId={this.state.taskSelected.taskSelectedItemId}
                                        predefinedRelatedTo="Service"
                                        mode={this.state.mode}
                                        saveTask={this.saveTask}/>
                                </div>


                            </div>

                        </div>

                    </div>
                </div>
                <Footer/>
            </div>


        );
    }


    handleServiceSubmit = () => {
        const selectedOpt = this.state.selectedTech;
        const keysArgs = Object.keys(selectedOpt);


        let tchid = [];

        this.app.locations[0].technicians.filter(t => t.active == true && t.bookable == true).map((t, key) => {
            tchid.push(t.id)
        });

        let collection = [];
        for (var i = 0; i < keysArgs.length; i++) {
            if (keysArgs[i].split('-').indexOf(tchid.find(id => {
                id == keysArgs[i].split('-')[1]
            }))) {
                var obj = {
                    service: {
                        id: this.state.service.id
                    },
                    technician: {
                        id: keysArgs[i].split('-')[1]
                    },
                    duration: 'PT' + this.state.selectedTech[`duration-${keysArgs[i].split('-')[1]}`] + 'M',

                    minimumFee: this.state.selectedTech[`pricingModel-${keysArgs[i].split('-')[1]}`] == 'Per Consult' ? -1 : this.state.selectedTech[`pricingModel-${keysArgs[i].split('-')[1]}`] == 'Fixed Price' ? this.state.selectedTech[`minimumFee-${keysArgs[i].split('-')[1]}`] : this.state.selectedTech[`pricingModel-${keysArgs[i].split('-')[1]}`] == 'Starting Price' ? this.state.selectedTech[`minimumFee-${keysArgs[i].split('-')[1]}`] : this.state.selectedTech[`pricingModel-${keysArgs[i].split('-')[1]}`] == 'Price Range' ? this.state.selectedTech[`minimumFee-${keysArgs[i].split('-')[1]}`] : '',
                    maximumFee: this.state.selectedTech[`pricingModel-${keysArgs[i].split('-')[1]}`] == 'Per Consult' ? -1 : this.state.selectedTech[`pricingModel-${keysArgs[i].split('-')[1]}`] == 'Fixed Price' ? this.state.selectedTech[`minimumFee-${keysArgs[i].split('-')[1]}`] : this.state.selectedTech[`pricingModel-${keysArgs[i].split('-')[1]}`] == 'Starting Price' ? -1 : this.state.selectedTech[`pricingModel-${keysArgs[i].split('-')[1]}`] == 'Price Range' ? this.state.selectedTech[`maximumFee-${keysArgs[i].split('-')[1]}`] : '',
                }
            }

            collection.push(obj);

        }

        if (this.state.service.id != '') {

            for (var i = 0; i < collection.length; i += 4) {

                appService.saveTechnicianToService(collection[i]).then(res => {
                    const {dispatch} = this.props;
                    dispatch(appActions.getAllServices(0, this.state.pageOffset))
                    window.DataUpdateSuccess();
                    this.resetServiceVars();
                    $('#addService').modal('toggle');
                }).catch(err => {
                    Swal.fire({
                        title: 'Not Saved',
                        text: `Please provide valid value!`,
                        type: 'warning',
                        cancelButtonText: 'Ok'
                    })
                })

            }
        } else {

            Swal.fire({
                title: 'Not Selected',
                text: `Service is not selected yet!`,
                type: 'warning',
                cancelButtonText: 'Ok'
            })

        }


    }


    findTechnicianName(technicianId) {
        // eslint-disable-next-line
        let technician = this.app.locations[0].technicians.find(technician => technician.id == technicianId)
        return technician.user.names.nick;
    }

    getNextPage() {
        var offset = this.state.pageOffset + 1;
        const {services, dispatch} = this.props;
        if (services && services.length != 0) {
            dispatch(appActions.getAllServices(offset, this.state.ctgryId, this.state.searchQuery));
            this.setState({pageOffset: offset});
        }

    }

    getPreviousPage() {
        var offset = this.state.pageOffset - 1;
        if (offset >= 0) {
            const {dispatch} = this.props;
            dispatch(appActions.getAllServices(offset, this.state.ctgryId, this.state.searchQuery));
            this.setState({pageOffset: offset});
        }
    }

    searchServicesByCategory = () => {
        const {dispatch} = this.props;
        dispatch(appActions.getAllServices(this.state.pageOffset, this.state.ctgryId, this.state.searchQuery));
        this.setState({
            pageOffset: this.state.pageOffset,
            ctgryId: this.state.ctgryId,
            searchQuery: this.state.searchQuery
        })

    }

    resetServiceVars() {

        this.setState({method: 'POST'});
        this.setState({
            service: {
                id: '',
                name: '',
                active: true,
                category: '',
                duration: '',
                minimumFee: '',
                maximumFee: '',
                description: '',
                imageUrl: 'No Image',
                technicians: ''
            }
        })
    }

    getSingleServiceData = (id) => {
        //alert(id)

        appService.getSingleServiceData(id).then((s) => {

            let pricingModel = '';

            if (s.minimumFee == -1 && s.maximumFee == -1) {

                pricingModel = 'Per Consult'
            } else if (s.minimumFee > 0 && s.maximumFee > 0 && s.minimumFee != s.maximumFee) {
                pricingModel = 'Price Range'
            } else if (s.minimumFee > 0 && s.maximumFee == -1) {
                pricingModel = 'Starting Price'
            } else {
                pricingModel = 'Fixed Price'
            }

            this.setState({
                method: 'PUT',
                sid: s.id,
                service: {
                    id: s.service.id,
                    name: s.service.name,
                    active: s.service.active,
                    visible: s.publiclyVisible,
                    category: s.service.category.id,
                    duration: s.duration,
                    minimumFee: s.minimumFee,
                    maximumFee: s.maximumFee,
                    description: s.service.description,
                    imageUrl: s.service.imageUrl,
                    communicationTemplate: s.service.communicationTemplate,
                    technicians: s.technicianServices.map(t => ({
                        techSId: t.id,
                        id: t.technician.id,
                        duration: t.duration,
                        minimumFee: t.minimumFee,
                        maximumFee: t.maximumFee
                    })),
                    technicianServices: s.technicianServices,
                    pricingModel: pricingModel,
                    consentForms: s.service.consentForms,
                    durationInPTM: '',
                    tech: []
                },

                singleService: s

            })
        })
    }


    checkIncludedTechnician = (id, techs, key) => {
        const s = this.state.service.technicianServices && this.state.service.technicianServices.length > 0 && this.state.service.technicianServices.find(t => t.technician.id == id);
        if (s) {
            return <tr key={key}>
                <td>
                    <div className="form-check">
                        <input
                            onChange={this.handleCheckbox}
                            value={s.id}
                            type="checkbox"
                            id={`return-${s.id}`}
                            className="form-check-input"/> &nbsp;
                        <label
                            className="form-check-label"
                            htmlFor={`return-${s.id}`}>{s.technician.user.names.nick}</label>
                    </div>
                </td>
                <td>{this.isChecked(s.id, 'displayedPrice')}</td>
                <td>{this.isChecked(s.id, 'duration')}</td>
                <td>{this.isChecked(s.id, 'processingTimeInMinutes')}</td>
                <td>
                    <label
                        className="label light-green lable-info">{this.isChecked(s.id, 'newClientRetentionPercentage')}</label>


                    <label
                        className="label dark-green label-info">{this.isChecked(s.id, 'oldClientRetentionPercentage')}</label>

                </td>
                <td>
                    <button
                        data-toggle="modal"
                        data-dismiss="modal"
                        data-target="#updateStaffService" onClick={() => this.openEditModal(s)}
                        className="btn btn-outline btn-info btn-lg btn-circle"><i className="fa fa-pencil"></i>
                    </button>
                </td>
            </tr>

        }

    }

    checkAvailableTechnician = (id, techs, key) => {

        const s = this.state.service.technicianServices && this.state.service.technicianServices.length > 0 && this.state.service.technicianServices.find(t => t.technician.id == id);
        if (s) {
            //
        } else {
            return <tr key={key}>
                <td>
                    <div className="form-check">
                        <input
                            onChange={(event) => this.handleCheckboxForAdd(event, this.state.singleService)}
                            type="checkbox"
                            value={id}
                            id={`adds-${id}`}
                            className="form-check-input"/> &nbsp;
                        <label
                            className="form-check-label">{techs.user.fullName}</label>
                    </div>
                </td>
                <td>{this.state.singleService.displayedPrice}</td>
                <td>{this.state.singleService.duration && helperFunctions.timeConvert(this.state.singleService.duration, 'minute')}</td>
                <td>0</td>
                <td>- - -</td>
                <td>
                    <button
                        data-toggle="modal"
                        data-target="#addServiceForm"
                        data-dismiss="modal"
                        onClick={() => this.openAddModal(id, this.state.singleService)}
                        className="btn btn-outline btn-info btn-lg btn-circle"><i className="fa fa-check"></i>
                    </button>
                </td>

            </tr>

        }

    }
    openEditModal = (s) => {

        this.setState({
            ...this.state,
            mode: this.isChecked(s.id, 'pricingModel'),
            min: this.isChecked(s.id, 'minimumFee'),
            max: this.isChecked(s.id, 'maximumFee'),
            durat: this.isChecked(s.id, 'duration'),
            process: this.isChecked(s.id, 'processingTimeInMinutes'),
            sid: s.service.id,
            techId: s.technician.id,
            techServiceId: s.id,

        })

    }

    openAddModal = (id, s) => {

        this.setState({
            ...this.state,
            mode: this.isCheckedAllList(s.id, s, 'pricingModel'),
            min: this.isCheckedAllList(s.id, s, 'minimumFee'),
            max: this.isCheckedAllList(s.id, s, 'maximumFee'),
            durat: this.isCheckedAllList(s.id, s, 'duration'),
            process: 0,
            sid: this.state.method == 'POST' ? s.id : s.service.id,
            techId: id

        })

    }

    handleCheckboxForAdd = (e, s) => {

        var item = this.state.item;

        if (e.target.checked) {
            item.push({
                mode: this.isCheckedAllList(s.id, s, 'pricingModel'),
                min: this.isCheckedAllList(s.id, s, 'minimumFee'),
                max: this.isCheckedAllList(s.id, s, 'maximumFee'),
                durat: this.isCheckedAllList(s.id, s, 'duration'),
                process: 0,
                sid: this.state.method == 'POST' ? s.id : s.service.id,
                techId: e.target.value
            });
            this.setState({
                item: item
            })
        } else {
            const newItem = this.state.item.filter(i => i.techId != e.target.value);
            this.setState({
                item: newItem
            })
        }
    }


    isChecked = (id, query) => {

        if (this.state.service != '') {

            var techs = this.state.service.technicianServices.find(t => t.id == id);
            if (techs) {

                if (query === 'pricingModel') {
                    return this.checkPriceType(techs.minimumFee, techs.maximumFee)
                }
                if (query === 'duration') {
                    return helperFunctions.timeConvert(techs.duration, 'minute');
                }
                if (query === 'displayedPrice') {
                    return techs.displayedPrice;
                }
                if (query === 'processingTimeInMinutes') {
                    return techs.processingTimeInMinutes;
                }
                if (query === 'newClientRetentionPercentage') {
                    return techs.newClientRetentionPercentage;
                }

                if (query === 'oldClientRetentionPercentage') {
                    return techs.oldClientRetentionPercentage;
                }


                if (query === 'minimumFee') {
                    return techs.minimumFee
                }

                if (query === 'maximumFee') {
                    return techs.maximumFee
                }

                if (query == 'techSId') {
                    return techs.techSId
                }


            }
        }
    }

    isCheckedAllList = (id, techs, query) => {

        if (techs) {

            if (query === 'pricingModel') {
                return this.checkPriceType(techs.minimumFee, techs.maximumFee)
            }
            if (query === 'duration') {
                return helperFunctions.timeConvert(techs.duration, 'minute');
            }
            if (query === 'displayedPrice') {
                return techs.displayedPrice;
            }
            if (query === 'processingTimeInMinutes') {
                return techs.processingTimeInMinutes;
            }
            if (query === 'newClientRetentionPercentage') {
                return techs.newClientRetentionPercentage;
            }

            if (query === 'oldClientRetentionPercentage') {
                return techs.oldClientRetentionPercentage;
            }


            if (query === 'minimumFee') {
                return techs.minimumFee
            }

            if (query === 'maximumFee') {
                return techs.maximumFee
            }

            if (query == 'techSId') {
                return techs.techSId
            }


        }

    }

    checkPriceType = (min, max) => {

        if (min == '-1' && max == '-1') {
            return 'Per Consult'
        } else if (min > 0 && max > 0 && min < max) {
            return 'Price Range'
        } else if (min > 0 && max == -1) {
            return 'Starting Price'
        } else {
            return 'Fixed Price'
        }
    }


    searchCategoryNameImage = (catId) => {
        if (catId) {
            const catOBJ = this.state.categories.find((c) => c.id == catId);
            if (catOBJ) {
                return {
                    name: catOBJ.name,
                    image: catOBJ.imageUrl
                }
            }
        }
    }


    taskSelectedService = (id) => {

        let taskSelectedService = this.props.services.find(s => s.id == id);

        this.setState({
            taskSelected: {
                ...this.state.taskSelected,
                taskSelectedService: taskSelectedService.service.name,
                taskSelectedItemId: taskSelectedService.id
            }
        })
    }


    getTechnicianName = (id) => {
        const singleName = this.app.locations[0].technicians.find(t => t.id == id);
        return singleName.user.fullName;
    }


    clearSearchFilter = () => {
        this.setState({

            serviceList: [],
            selectedOption: '',
            searchQuery: '',
            newSingleService: '',
            errors: {},
            service: {
                ...this.state.service,
                id: '',
                name: '',
                active: '',
                visible: '',
                duration: '',
                minimumFee: '',
                maximumFee: '',
                description: '',
                imageUrl: 'No Image',
                pricingModel: '',
                technicians: [],
            },
            singleService: '',
            loading: false
        })
    }


    resetFilters() {
        this.setState({pageOffset: 0});
        this.setState({ctgryId: ''});
        this.setState({searchQuery: ''});
        const {dispatch} = this.props;
        dispatch(appActions.getAllServices(0))
    }


    clearTechFilter = () => {

        userService.getAllTechnicians(0).then(res => {
            this.setState({
                ...this.state,
                techsFromApp: res,
                item: []
            })
        })
        window.uncheckEveryone('#included-service-table input')
        $('#updateService').modal('toggle');
    }

    clearTechFilterForAvailable = () => {
        userService.getAllTechnicians(0).then(res => {
            this.setState({
                ...this.state,
                techsFromApp: res,
                item: []
            })
        })
    }


    searchTechnician = () => {
        userService.getAllTechnicians(0, this.state.selectedTeam).then(res => {
            this.setState({
                ...this.state,
                techsFromApp: res,
                submitted: false
            })
        })
    }
    getNextTechPage = () => {
        this.setState({
            submitted: true
        })
        var offset = this.state.techPageOffset + 1;
        const {techsFromApp} = this.state;
        if (techsFromApp && techsFromApp.length != 0) {
            userService.getAllTechnicians(offset, this.state.team).then(res => {
                this.setState({
                    ...this.state,
                    techsFromApp: res,
                    techPageOffset: offset,
                    submitted: false
                })
            })

        }

    }


    getPreviousTechPage() {
        this.setState({
            loading: true
        })
        var offset = this.state.techPageOffset - 1;
        if (offset >= 0) {
            userService.getAllTechnicians(offset, this.state.team).then(res => {
                this.setState({
                    ...this.state,
                    techsFromApp: res,
                    techPageOffset: offset,
                    loading: false
                })
            })
        }
    }


    updateServiceTechnician = () => {
        var min = '';
        var max = '';
        if (this.state.mode == 'Per Consult') {
            min = -1;
            max = -1
        } else if (this.state.mode == 'Price Range') {
            min = this.state.min;
            max = this.state.max
        } else if (this.state.mode == 'Starting Price') {
            min = this.state.min;
            max = -1
        } else if (this.state.mode == 'Fixed Price') {
            min = this.state.min;
            max = this.state.min
        } else {
            min = this.state.min;
            max = this.state.max
        }
        const techJSON = {
            technician: {
                id: this.state.techId
            },
            service: {
                id: this.state.sid
            },
            duration: 'PT' + this.state.durat + 'M',
            minimumFee: min,
            maximumFee: max,
            processingTime: 'PT' + this.state.process + 'M'

        }
        appService.updateServiceTechnician(this.state.techServiceId, techJSON).then((res) => {

            appService.getSingleServiceData(this.state.sid).then(res => {
                this.setState({
                    ...this.state,
                    service: {
                        technicianServices: res.technicianServices
                    }
                })
            })
            $('#updateStaffService').modal('hide');
            $('#updateService').modal('show');
            this.resetSelectedService()
            window.DataUpdateSuccess();


        });

    }

    addNewTechToService = () => {

        if (this.state.item.length > 0) {
            for (var i = 0; i < this.state.item.length; i++) {
                const techJSON = {
                    technician: {
                        id: this.state.item[i].techId
                    },
                    service: {
                        id: this.state.sid
                    },
                    duration: 'PT' + this.state.item[i].durat + 'M',
                    minimumFee: this.state.item[i].min,
                    maximumFee: this.state.item[i].max,
                    processingTime: 'PT' + this.state.item[i].process + 'M'

                }

                appService.saveTechnicianToService(techJSON).then(res => {
                    const technicianServices = this.state.service.technicianServices;
                    technicianServices.push(res);
                    this.setState({
                        technicianServices: technicianServices
                    })

                })

                this.setState({
                    item: []
                })

            }

            window.uncheckEveryone('#add-tech-table input')


        } else {

            var min = '';
            var max = '';
            if (this.state.mode == 'Per Consult') {
                min = -1;
                max = -1
            } else if (this.state.mode == 'Price Range') {
                min = this.state.min;
                max = this.state.max
            } else if (this.state.mode == 'Starting Price') {
                min = this.state.min;
                max = -1
            } else if (this.state.mode == 'Fixed Price') {
                min = this.state.min;
                max = this.state.min
            } else {
                min = this.state.min;
                max = this.state.max
            }

            const techJSON = {
                technician: {
                    id: this.state.techId
                },
                service: {
                    id: this.state.sid
                },
                duration: 'PT' + this.state.durat + 'M',
                minimumFee: min,
                maximumFee: max,
                processingTime: 'PT' + this.state.process + 'M'

            }
            appService.saveTechnicianToService(techJSON).then(res => {
                const technicianServices = this.state.service.technicianServices;
                technicianServices.push(res);

                this.setState({

                    service: {
                        ...this.state.service,
                        active: '',
                        category: '',
                        consentForms: [],
                        description: '',
                        displayedPrice: '',
                        duration: '',
                        durationInPTM: '',
                        id: '',
                        imageUrl: '',
                        maximumFee: '',
                        minimumFee: '',
                        name: '',
                        pricingModel: '',
                        technicianServices: technicianServices
                    },
                    mode: '',
                    techId: '',
                    durat: '',
                    process: 0,
                    min: '',
                    max: '',
                    technicianId: '',
                    sid: '',
                })


                this.resetSelectedService()
                window.DataUpdateSuccess();

                $('#addServiceForm').modal('hide');
                $('#addNewServiceToTech').modal('show');


            }).catch(err => {

                Swal.fire({
                    title: 'Duplicate!',
                    text: `This Staff is already exist for this service!`,
                    type: 'warning',
                    cancelButtonText: 'Ok'
                })
            })
        }

    }


    deleteServiceTechnician = () => {
        Swal.fire({
            title: 'Delete Service',
            text: `Are you sure you want to delete this Service?`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result) => {
            if (result.value) {


                if (this.state.item.length > 0) {
                    for (var i = 0; i < this.state.item.length; i++) {
                        appService.deleteTechService(this.state.item[i]).then(res => {
                            this.getSingleServiceData(this.state.sid)
                        })

                    }
                    this.setState({
                        item: []
                    })
                    window.uncheckEveryone('#included-service-table input')
                    window.toast('error', `The service has been deleted from the system`);

                } else {
                    appService.deleteTechService(this.state.techServiceId).then(res => {
                        $('#updateStaffService').modal('hide');
                        this.getSingleServiceData(this.state.sid)
                        window.toast('error', `The service has been deleted from the system`);

                    })
                }


            }
        })
    }


    resetSelectedService = () => {
        this.setState({
            ...this.state,
            mode: '',
            durat: '',
            max: '',
            min: '',
            process: '',
            sid: '',
            techServiceId: ''
        })

    }


    findConsentForms = (id) => {
        //t(id)
        const consent = this.app.consentForms.find(c => c.id == id);

        if (consent) {
            return consent.name
        }
    }


}


function mapStateToProps(state) {
    const {services, servicesLoaded} = state.app;
    return {
        services,
        servicesLoaded
    };
}


const connectedServicesPage = connect(mapStateToProps)(ServicesPage);
export {connectedServicesPage as ServicesPage};
