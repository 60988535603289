/* eslint-disable */
import React, {Component} from 'react';
import {helperFunctions} from "../_helpers";
import moment from "moment";
class PastAppt extends Component {
    constructor(props){
        super(props)
        this.app = JSON.parse(localStorage.getItem('app'));

    }



    closePastAppts=()=>{
        this.props.closePastAppts()
    }



    render(){
        const {pastAppts} = this.props;

        return(
            <React.Fragment>
            {pastAppts &&
            <div data-backdrop="static" data-keyboard="false"
                 className="modal fade" id="MiniModal">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button className="close" onClick={this.closePastAppts}>&times;</button>
                            <h4>Appointment
                                on {pastAppts && pastAppts.length > 0 && helperFunctions.formatDateTimeWithDay(moment(pastAppts[0].bookedTime), 'date')} {pastAppts && helperFunctions.formatDateTimeWithDay(pastAppts[0].bookedTime, 'time')}</h4>

                            <ul>
                                {
                                    pastAppts && pastAppts.length > 0 &&
                                    pastAppts[0].visitTechnicians.map((s, key) => {
                                        return <li key={key}>
                                            <strong>{this.findServiceName(s.offeredService.id, s.offeredService.category.id)}</strong> with <strong>{this.findTechnician(s.technician.id, 'name')}</strong>

                                        </li>
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
    }
    </React.Fragment>



        )
    }
    findServiceName=(serviceId, categoryId)=>{
        // eslint-disable-next-line
        let category = this.app.offeredServiceCategories.find(sc => sc.id == categoryId)
        if (category == null)
            return "Service not found"
        let service = category.services.find(s => s.id == serviceId)
        if (service == null)
            return "Service not found"
        return service.name;
    }
    findTechnician(technicianId, query) {

        // eslint-disable-next-line
        let technician = this.app.locations[0].technicians.find(technician => technician.id == technicianId)
        if (technician)
            if (query == 'image') {
                return technician.user.imageUrl;
            } else if (query == 'nick') {
                return technician.user.names.nick;
            } else {
                return technician.user.fullName;
            }

        return "";
    }
}

export default PastAppt
