export const userConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',
 
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    GET_USER_INFO_REQUEST: 'GET_USER_INFO_REQUEST',
    GET_USER_INFO_SUCCESS: 'GET_USER_INFO_SUCCESS',
    GET_USER_INFO_FAILURE: 'GET_USER_INFO_FAILURE',
 
    LOGOUT: 'USERS_LOGOUT',
 
    UPDATE_PROFILE_REQUEST: 'USERS_UPDATE_PROFILE_REQUEST',
    UPDATE_PROFILE_SUCCESS: 'USERS_UPDATE_PROFILE_SUCCESS',
    UPDATE_PROFILE_FAILURE: 'USERS_UPDATE_PROFILE_FAILURE',   

    UPDATE_PASSWORD_REQUEST: 'USERS_UPDATE_PASSWORD_REQUEST',
    UPDATE_PASSWORD_SUCCESS: 'USERS_UPDATE_PASSWORD_SUCCESS',
    UPDATE_PASSWORD_FAILURE: 'USERS_UPDATE_PASSWORD_FAILURE',  

    UPDATE_REWARD_SUCCESS: 'USERS_UPDATE_REWARD_SUCCESS',
    UPDATE_REWARD_FAILURE: 'USERS_UPDATE_REWARD_FAILURE',

    RESET_PASSWORD_REQUEST: 'USERS_RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: 'USERS_RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: 'USERS_RESET_PASSWORD_FAILURE',  

    GET_ALL_USERS_REQUEST: 'GET_ALL_USERS_REQUEST',
    GET_ALL_USERS_SUCCESS: 'GET_ALL_USERS_SUCCESS',
    GET_ALL_USERS_FAILURE: 'GET_ALL_USERS_FAILURE',
    
    GET_ALL_TECHNICIANS_REQUEST: 'GET_ALL_TECHNICIANS_REQUEST',
    GET_ALL_TECHNICIANS_SUCCESS: 'GET_ALL_TECHNICIANS_SUCCESS',
    GET_ALL_TECHNICIANS_FAILURE: 'GET_ALL_TECHNICIANS_FAILURE', 

    GET_TECHNICIANS_SCHEDULE_REQUEST: 'GET_TECHNICIANS_SCHEDULE_REQUEST',
    GET_TECHNICIANS_SCHEDULE_SUCCESS: 'GET_TECHNICIANS_SCHEDULE_SUCCESS',
    GET_TECHNICIANS_SCHEDULE_FAILURE: 'GET_TECHNICIANS_SCHEDULE_FAILURE' ,

    SAVE_CUSTOMER_NOTE: 'SAVE_CUSTOMER_NOTE',




    REQUEST_SAVE_FORMULA: 'REQUEST_SAVE_FORMULA',
    SAVE_FORMULA: 'SAVE_FORMULA',
    REQUEST_FORMULA_DATA: 'REQUEST_FORMULA_DATA',
    FETCH_FORMULA_DATA: 'FETCH_FORMULA_DATA',
    FAIL_FORMULA_DATA: 'FAIL_FORMULA_DATA',
    UPDATE_REQUEST_FOR_FORMULA: 'UPDATE_REQUEST_FOR_FORMULA',
    UPDATE_CUSTOMER_FORMULA: 'UPDATE_CUSTOMER_FORMULA',

    REQUEST_DELETE_FORMULA: 'REQUEST_DELETE_FORMULA',
    DELETE_CUSTOMER_FORMULA: 'DELETE_CUSTOMER_FORMULA',


    REQUEST_FOR_USER_REWARDS: 'REQUEST_FOR_USER_REWARDS',
    GET_ALL_USER_REWARDS: 'GET_ALL_USER_REWARDS',
    GET_ALL_UPDATED_REWARDS: 'GET_ALL_UPDATED_REWARDS',
    REWARDS_FAILURE_IN_FETCHING: 'REWARDS_FAILURE_IN_FETCHING',

    GET_MERGE_CUSTOMER: 'GET_MERGE_CUSTOMER',
    MERGE_CUSTOMER_REQUEST: 'MERGE_CUSTOMER_REQUEST',


    SAVE_TASK: 'SAVE_TASK',
    GET_ALL_TASK_REQUEST: 'GET_ALL_TASK_REQUEST',
    GET_ALL_TASK_SUCCESS: 'GET_ALL_TASK_SUCCESS',
    GET_ALL_TASK_ERROR: 'GET_ALL_TASK_ERROR'



};