/* eslint-disable */
import React, {Component} from 'react';
import CheckOut from "./checkOut";
import noteImg from '../note.png'
import noteCstmr from '../note-icon.png'
import 'rc-datetime-picker/dist/picker.min.css';
import moment from 'moment';
import {DatetimePickerTrigger} from 'rc-datetime-picker';
import tasksImg from './../task.png'
import notesImg from './../note.png'
import {helperFunctions} from '../_helpers'
import {appService} from '../_services'
import InlineMessage from './inlineMessage';
import ServiceSearchBar from './serviceSearchBar';
import Select from 'react-select';
import 'rc-time-picker/assets/index.css';
import TimePicker from 'rc-time-picker';
import ProductSearchBar from './productSearchBar'
import { inventoryService } from '../_services';
import EditCart from "../_components/editCart";
const format = 'hh:mm a';
const now = moment().hour(0).minute(0);



class GroupCart extends Component {
    constructor(props){
        super(props)
    this.app = JSON.parse(localStorage.getItem('app'));
    this.state = {
        staffNote: false,
        customerNote: false,
        editDate: false,
        addService: false,
        products: false,
        dueTime: moment(),

        staffNotes: '',
        ctgryId: '',
        services: '',
        sid: '',
        sprice: '',
        selectedOption: null,
        searchQuery: '',
        techServices: '',
        startTime:  '',
        endTime: moment(),
        errors:{},
        method: 'POST',
        singleSVtId:'',
        cancelReason:'',
        buttonText: false,
        updateText: false,
        apptBookedTime: moment(),
        expectedStartTime: '',
        expectedEndtTime: '',
        periodTo: '',
        value: '',
        serviceId: '',
        serviceName: '',
        servicePrice: '',
        hidden: false,
        technician: '',
        durat: '',
        singleProduct: '',
        productPrice: '',
        productId: '',
        qty: 1,
        productName: '',
        place: '',
        mode: '',
        productCollection: [],
        purchaseId: '',
        singleService: '',
        modalClass: false,
        pmodalClass: false,
        total: '',

        creditPaidAmount: 0,
        cashPaidAmount: 0,
        checkPaidAmount: 0,
        rewardDeductionAmount: 0,
        discountDeductionAmount: 0,
        giftCardPaidAmount: 0



        }

        
    }
    removeFromCart=(id)=>{
        this.props.removeFromCart(id)
    }
    editCart=(id)=>{
        this.props.editCartFromCollection(id)
        $('#cartModal').modal('toggle')
    }

    changeHandle=(e)=>{
        const {name,value} = e.target
        this.setState({
            [name]: value
        })
    }
    placeGroupCheckOut=()=>{
        let ids = ''

        this.props.cartCollections.map((c,k)=>{
            var comma = k == 0 ? '' : ','
            ids += comma+c.id
        })

        const groupCheckJSON = {
            creditPaidAmount: this.state.creditPaidAmount,
            cashPaidAmount: this.state.cashPaidAmount,
            checkPaidAmount: this.state.checkPaidAmount,
            giftCardPaidAmount: this.state.giftCardPaidAmount,
            rewardDeductionAmount: this.state.rewardDeductionAmount,
            discountDeductionAmount: this.state.discountDeductionAmount
        }

        this.props.placeGroupCheckOut(ids,groupCheckJSON);


    }

    render() {

        
        
        const {cartCollections} = this.props
        const {services,searchQuery,selectedOption,techServices,errors} = this.state
   
        
        return (

            
            <React.Fragment>
                <div className="modal-body">
                    <div className="table-responsive">

                        <table className="table tabl-striped group-cart">
                         
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Techs</th>
                                <th>Price</th>
                                <th className="text-center">Action</th>
                            </tr>
                            </thead>


                            <tbody>
                            {!cartCollections  &&
                                <tr>
                                    <td colspan="4" align="center"><h2>Cart is empty!</h2></td>
                                </tr>
                            }
                             {cartCollections.length == 0  &&
                                <tr>
                                    <td colspan="4" align="center"><h2>Cart is empty!</h2></td>
                                </tr>
                            }
                            {cartCollections.length > 0 && cartCollections.map((c,k)=>{
                                return <tr key={k} className="trigger-content">
                                        <td>{c.client.fullName}</td>
                                        <td>
                                            {c.visitTechnicians.length > 0 &&
                                            <img className="img-circle" width="30"
                                                 src={this.findTechnician(c.visitTechnicians[0].technician.id,'image')}/>
                                            }     
                                            <span> {c.visitTechnicians.length > 2 && `+ ${c.visitTechnicians.length - 1}`} </span>
                                        </td>
                                        <td>{c.totalChargeAmount}</td>
                                        <td align="center">
                                            <button onClick={()=>this.removeFromCart(c.id)} className="btn-custom-danger m-r-5"><i
                                                className="ti ti-close"></i></button>
                                            <button onClick={()=>this.editCart(c.id)} className="btn-custom-info m-r-5"><i
                                                className="ti ti-pencil"></i></button>

                                        </td>

                                    </tr>
                               
                            })}

                            </tbody>

                        </table>

                    </div>

                    <div className="botom-info">
                        
                        <div>
                            <div>
                                <div className="check-out-info">
                                    <div className="expense col-md-5">
                                        <ul className="list-group">
                                            <li className="list-group-item">
                                                <b>Costs: <span className="text-warning cost">($ {this.props.total && (this.props.total*1).toFixed(2)})</span></b>
                                            </li>
                                            <li className="list-group-item">
                                                <p className="title-fade">Service Charge Amount &nbsp; <span className="text-danger">*</span></p>
                                                <p className="title-amount">
                                                    <input
                                                        readOnly
                                                        type="text"
                                                        maxLength="9"
                                                        step="0.01"
                                                        value={this.props.serviceChargeAmount && this.props.serviceChargeAmount.toFixed(2)}/>
                                                </p>
                                            </li>
                                            <li className="list-group-item">
                                                <p className="title-fade">Products Purchase Amount &nbsp; <span className="text-danger">*</span></p>
                                                <p className="title-amount">
                                                    <input 
                                                    readOnly 
                                                    type="text" 
                                                    maxLength="9" 
                                                    step="0.01"
                                                    value={this.props.productPurchaseAmount && this.props.productPurchaseAmount.toFixed(2)}
                                                    />
                                                </p>
                                            </li>

                                            <li className="list-group-item">
                                                <p className="title-fade">Tip Amount &nbsp;<span className="text-danger">*</span></p>
                                                <p className="title-amount">
                                                    <input 
                                                    readOnly 
                                                    type="text" 
                                                    maxLength="9" step="0.01"
                                                    value={this.props.tipAmount && this.props.tipAmount.toFixed(2)}
                                                    />
                                                </p>
                                            </li>
                                            <li className="list-group-item">
                                                <p className="title-fade">Tax Amount &nbsp;<span className="text-danger">*</span></p>
                                                <p className="title-amount">
                                                    <input 
                                                    readOnly 
                                                    type="text" 
                                                    maxLength="9" step="0.01"
                                                    value={this.props.taxAmount && this.props.taxAmount.toFixed(2)}
                                                    />
                                                </p>
                                            </li>
                                            <li className="list-group-item">
                                                <p className="title-fade"><b>Total Amount</b></p>
                                                <p className="title-amount">
                                                    <input 
                                                    readOnly 
                                                    type="text" 
                                                    maxLength="9" 
                                                    step="0.01"
                                                    value={this.props.total && this.props.total.toFixed(2)}
                                                    />
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="expense col-md-5 col-md-offset-1">
                                        <ul className="list-group">
                                            <li className="list-group-item">
                                                <b>Payments: <span className="text-custom-info">($ {((this.state.creditPaidAmount*1) + (this.state.cashPaidAmount*1) + (this.state.checkPaidAmount*1) + (this.state.rewardDeductionAmount*1) + (this.state.giftCardPaidAmount*1) + (this.state.discountDeductionAmount*1)).toFixed(2)  })</span></b>
                                            </li>
                                            <li className="list-group-item">
                                                <p className="title-fade">Credit Paid Amount &nbsp; <span className="text-danger">*</span></p>
                                                <p className="title-amount">
                                                    <input 
                                                     
                                                    type="text" 
                                                    maxLength="9" 
                                                    name="creditPaidAmount"
                                                    onChange={this.changeHandle}
                                                    value={this.state.creditPaidAmount}
                                                    />
                                                </p>
                                            </li>
                                            <li className="list-group-item">
                                                <p className="title-fade">Cash Paid Amount &nbsp; <span className="text-danger">*</span></p>
                                                <p className="title-amount">
                                                    <input 
                                                    type="text" 
                                                    maxLength="9" 
                                                    name="cashPaidAmount"
                                                    onChange={this.changeHandle}
                                                    value={this.state.cashPaidAmount}
                                                    />
                                                </p>
                                            </li>

                                            <li className="list-group-item">
                                                <p className="title-fade">Check Paid Amount &nbsp; <span className="text-danger">*</span></p>
                                                <p className="title-amount">
                                                    <input 
                                                    name="checkPaidAmount"
                                                    type="text" 
                                                    maxLength="9" 
                                                    onChange={this.changeHandle}
                                                    value={this.state.checkPaidAmount}
                                                    />
                                                </p>
                                            </li>
                                            <li className="list-group-item">
                                                <p className="title-fade">Gift Card Amount &nbsp; <span className="text-danger">*</span></p>
                                                <p className="title-amount">
                                                    <input 
                                                    name="giftCardPaidAmount" 
                                                    type="text" 
                                                    maxLength="9" 
                                                    onChange={this.changeHandle}
                                                    value={this.state.giftCardPaidAmount}
                                                    />
                                                </p>
                                            </li>


                                            <li className="list-group-item">
                                                <p className="title-fade">Reward Points Used &nbsp; <span className="text-danger">*</span></p>
                                                <p className="title-amount">
                                                    <input 
                                                     
                                                    type="text" 
                                                    name="rewardDeductionAmount"
                                                    onChange={this.changeHandle}
                                                    value={this.state.rewardDeductionAmount}
                                                    />
                                                </p>
                                            </li>

                                            <li className="list-group-item">
                                                <p className="title-fade">Discount Amount &nbsp; <span className="text-danger">*</span></p>
                                                <p className="title-amount">
                                                    <input 
                                                     
                                                    type="text" 
                                                    name="discountDeductionAmount"
                                                    onChange={this.changeHandle}
                                                    value={this.state.discountDeductionAmount}
                                                    />
                                                </p>
                                            </li>
                                            
                                            
                                            
                                        </ul>
                                    </div>  


                                </div>
                            </div>


                        </div>

                    </div>
                    
                    
                </div>


                <div className="modal-footer text-right">

                    <button onClick={this.placeGroupCheckOut} data-toggle="modal"
                            className="btn btn-custom-info">Check Out ( {this.props.total && this.props.total.toFixed(2)} )
                    </button>
                </div>
            </React.Fragment>
        );
    }
        getRequestSource(reqSrc) {
        let src = '';
        src = reqSrc == "Phone Call" ? 'fa fa-phone text-warning custom mb0'
            : reqSrc == "Walk In" ? 'mdi mdi-run custom mb0'
                : reqSrc == "Android App" ? 'fa fa-android text-purple custom'
                    : reqSrc == "iOS App" ? 'fa fa-mobile text-purple custom'
                        : reqSrc == "Website" ? 'fa fa-desktop custom'
                            : reqSrc == "iPad App" ? 'fas fa-tablet custom'
                                : reqSrc == "Tablet App" ? 'mdi mdi-tablet-android custom'
                                    : ''

        return src;
    }
    findTechnician(technicianId,query){
        
        // eslint-disable-next-line
        let technician = this.app.locations[0].technicians.find(technician => technician.id == technicianId)
        if(technician)
            if(query == 'image'){
                return technician.user.imageUrl;
            }else{
                return technician.user.fullName;
            }
            
        return "";
    }

    findServicePrice(serviceId,techId, catId){
        
        let servicePrice = ""
        
        let s = this.app.locations[0].technicians.find(tech => tech.id == techId)
        .offeredServices.find(service => service.id == serviceId)

        if(s == null){
            s = this.app.offeredServiceCategories.find(c => c.id == catId)
                    .services.find(service => service.id == serviceId)
        }

        servicePrice = s.minimumFee == -1 && s.maximumFee == -1 ? "Per Consult" :
                s.minimumFee > -1 && s.maximumFee == -1 ? "From $" + s.minimumFee :
                (s.minimumFee > -1 && s.maximumFee > -1) && (s.minimumFee == s.maximumFee) ? "$" + s.minimumFee :
                (s.minimumFee > -1 && s.maximumFee > -1) && (s.minimumFee != s.maximumFee) ? "$" + s.minimumFee + " - $" + s.maximumFee : "";
    
        
        return servicePrice;
    }
    findServiceName(serviceId, categoryId){
        // eslint-disable-next-line
        let category = this.app.offeredServiceCategories.find(sc => sc.id == categoryId)
        if(category == null)
            return "Service not found"
        let service = category.services.find(s => s.id == serviceId)
        if(service == null)
            return "Service not found"
        return service.name;
    }



    checkPrice=(id)=>{
        const service = this.state.services.find(s=>s.id==id)
        if(service != null){
            const price = this.checkPriceType(service.minimumFee,service.maximumFee);
            return price
        }
        
    }
    checkUpdatePrice=(id)=>{
        const service = this.state[`services-${this.state.singleSVtId}`].find(s=>s.id==id)
        if(service != null){
            const price = this.checkPriceType(service.minimumFee,service.maximumFee);
            return price
        }
        
    }




    checkPriceType = (min, max) => {

        if (min == '-1' && max == '-1') {
            return ''
        } else if (min > 0 && max > 0 && min < max) {
            return min*1
        } else if (min > 0 && max == -1) {
            return min*1
        } else {
            return min*1;
        }
    }

    checkTechAttachedToService=(id)=>{
        const service = this.state.services.find(s=>s.id==id)
        if(service != null){
            return service.technicianServices
        } 
    }
    checkTechUpdateAttachedToService=(id)=>{
    
        const service = this.state[`services-${this.state.singleSVtId}`].find(s=>s.id==id)
    
        if(service != null){
            return service.technicianServices
        }
    }

    checkCategory=(id)=>{
        const category = this.app.offeredServiceCategories.find(c=>c.id==id);
        if(category != null){
            return category.name
        }
    }





}

export default GroupCart;