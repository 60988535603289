/* eslint-disable */
import React, {Component} from 'react';
import {Router, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import {alertActions} from './_actions';

import {helperFunctions, history} from './_helpers';
import {Config} from './config';
import {appService, userService} from "./_services";
import {Device} from 'twilio-client'
import AppContent from './appContent';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {requestFirebaseNotification} from "./Services/firebase";
import {isSupported} from "firebase/messaging";

var config = Config()

class App extends Component {
    constructor(props) {
        super(props);
        const {dispatch} = this.props;
        this.state = {
            app: {}
        }
        this.app = localStorage.getItem('app') != null ? JSON.parse(localStorage.getItem('app')) : '';
        if (this.app.id != undefined) {
            appService.getAppDetails(this.app.id).then(response => {
                localStorage.removeItem('app')
                localStorage.setItem('app', JSON.stringify(response));
            }).catch(e => {
                console.log(e)
            })
        }
        history.listen((location, action) => {
            // clear alert on location change
            dispatch(alertActions.clear());
        });
        this.app = localStorage.getItem('app') && JSON.parse(localStorage.getItem('app'));
        this.loggedUser = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : ''


    }

    getTwillioReady = () => {
        const {dispatch} = this.props
        if (this.props.user) {
            if (this.props.user.role == 'DIRECTOR' || this.props.user.role == 'MANAGER' || this.props.user.role == 'RECEPTIONIST') {
                userService.getTwillioReady(this.props.info ? this.props.info.id : this.app.id).then(res => {
                    Device.setup(res.token)
                })
            }
        } else {
            if (this.loggedUser.role == 'DIRECTOR' || this.loggedUser.role == 'MANAGER' || this.loggedUser.role == 'RECEPTIONIST') {
                userService.getTwillioReady(this.props.info ? this.props.info.id : this.app.id).then(res => {
                    Device.setup(res.token)
                })
            }
        }
    }


    componentDidMount = async () => {
        const metaInfo = await appService.checkVersion();
        await helperFunctions.clearCache(metaInfo)

        if (this.app) {
            this.getTwillioReady(this.app.id)
        }

        if (await isSupported() === true) {
            await requestFirebaseNotification();
        }

    }


    checkUrl = () => {
        if (location.protocol !== 'https:') {
            location.replace(`https:${location.href.substring(location.protocol.length)}`);
        }
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.app) {
            this.getTwillioReady(this.app.id)
        }
    }

    render() {
        console.log("hello v-13")
        return (
            <Router history={history}>
                <div>

                    <AppContent/>

                </div>
            </Router>
        );
    }
}

function mapStateToProps(state) {
    const {user} = state.authentication;
    const {info} = state.Communication
    const {alert} = state;
    return {alert, user, info}
}

const connectedApp = connect(mapStateToProps)(App);
export {connectedApp as App};
