/* eslint-disable */
import React, {Component} from 'react';

class CheckOut extends Component {
    constructor(props){
        super(props);
        this.state={
            creditPaidAmount: 0,
            cashPaidAmount: 0,
            checkPaidAmount: 0,
            giftCardPaidAmount: 0,
            rewardDeductionAmount: 0,
            discountDeductionAmount: 0
        }
    }

    changeHandle=(e)=>{
        e.preventDefault();
        const {name,value} = e.target;
        this.setState({
            ...this.state,
            [name]: value
        })
    }


    apptCheckout=()=>{
        const {checkoutDetails} = this.props;
        const paymentJSON = {
            serviceChargeAmount: checkoutDetails.serviceChargeAmount,
            productPurchaseAmount: checkoutDetails.productPurchaseAmount,
            taxAmount: checkoutDetails.taxAmount,
            tipAmount: checkoutDetails.tipAmount,
            totalChargeAmount: checkoutDetails.totalChargeAmount,
            creditPaidAmount: this.state.creditPaidAmount < 0 ? 0 : this.state.creditPaidAmount,
            cashPaidAmount: this.state.cashPaidAmount < 0 ? 0 : this.state.cashPaidAmount,
            checkPaidAmount: this.state.checkPaidAmount < 0 ? 0 : this.state.checkPaidAmount ,
            giftCardPaidAmount: this.state.giftCardPaidAmount < 0 ? 0 : this.state.giftCardPaidAmount,
            rewardDeductionAmount: this.state.rewardDeductionAmount < 0 ? 0 : this.state.rewardDeductionAmount,
            discountDeductionAmount: this.state.discountDeductionAmount < 0 ? 0 : this.state.discountDeductionAmount
        }
        this.props.apptCheckout(checkoutDetails.id, paymentJSON)
    }





    render() {
        const {checkoutDetails} = this.props;
        
        return (
            <React.Fragment>
            {checkoutDetails && checkoutDetails != '' &&
                <div className="check-out-info">
                    <div className="expense col-md-5">
                        <ul className="list-group">
                            <li className="list-group-item">
                                <b>Costs: <span className="text-warning cost">($ {checkoutDetails && (checkoutDetails.totalChargeAmount*1).toFixed(2)})</span></b>
                            </li>
                            <li className="list-group-item">
                                <p className="title-fade">Service Charge Amount &nbsp; <span className="text-danger">*</span></p>
                                <p className="title-amount">
                                    <input
                                        readOnly
                                        type="text"
                                        maxLength="9"
                                        step="0.01"
                                        value={checkoutDetails.serviceChargeAmount && (checkoutDetails.serviceChargeAmount*1).toFixed(2)}/>
                                </p>
                            </li>
                            <li className="list-group-item">
                                <p className="title-fade">Products Purchase Amount &nbsp; <span className="text-danger">*</span></p>
                                <p className="title-amount">
                                    <input
                                    readOnly
                                    type="text"
                                    maxLength="9"
                                    step="0.01"
                                    value={checkoutDetails.productPurchaseAmount && (checkoutDetails.productPurchaseAmount*1).toFixed(2)}
                                    />
                                </p>
                            </li>

                            <li className="list-group-item">
                                <p className="title-fade">Tip Amount &nbsp;<span className="text-danger">*</span></p>
                                <p className="title-amount">
                                    <input
                                    readOnly
                                    type="text"
                                    maxLength="9" step="0.01"
                                    value={checkoutDetails.tipAmount && (checkoutDetails.tipAmount*1).toFixed(2)}
                                    />
                                </p>
                            </li>
                            <li className="list-group-item">
                                <p className="title-fade">Tax Amount &nbsp;<span className="text-danger">*</span></p>
                                <p className="title-amount">
                                    <input
                                    readOnly
                                    type="text"
                                    maxLength="9" step="0.01"
                                    value={this.props.taxAmount && (this.props.taxAmount*1).toFixed(2)}
                                    />
                                </p>
                            </li>
                            <li className="list-group-item">
                                <p className="title-fade"><b>Total Amount</b></p>
                                <p className="title-amount">
                                    <input
                                    readOnly
                                    type="text"
                                    maxLength="9"
                                    step="0.01"
                                    value={checkoutDetails && (checkoutDetails.totalChargeAmount*1).toFixed(2)}
                                    />
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div className="expense col-md-5 col-md-offset-1">
                        <ul className="list-group">
                            <li className="list-group-item">
                                <b>Payments: <span className="text-custom-info">($ {((this.state.creditPaidAmount*1) + (this.state.cashPaidAmount*1) + (this.state.checkPaidAmount*1) + (this.state.rewardDeductionAmount*1) + (this.state.giftCardPaidAmount*1) + (this.state.discountDeductionAmount*1)).toFixed(2)  })</span></b>
                            </li>
                            <li className="list-group-item">
                                <p className="title-fade">Credit Paid Amount &nbsp; <span className="text-danger">*</span></p>
                                <p className="title-amount">
                                    <input

                                    type="text"
                                    maxLength="9"
                                    name="creditPaidAmount"
                                    onChange={this.changeHandle}
                                    value={this.state.creditPaidAmount}
                                    />
                                </p>
                            </li>
                            <li className="list-group-item">
                                <p className="title-fade">Cash Paid Amount &nbsp; <span className="text-danger">*</span></p>
                                <p className="title-amount">
                                    <input
                                    type="text"
                                    maxLength="9"
                                    name="cashPaidAmount"
                                    onChange={this.changeHandle}
                                    value={this.state.cashPaidAmount}
                                    />
                                </p>
                            </li>

                            <li className="list-group-item">
                                <p className="title-fade">Check Paid Amount &nbsp; <span className="text-danger">*</span></p>
                                <p className="title-amount">
                                    <input
                                    name="checkPaidAmount"
                                    type="text"
                                    maxLength="9"
                                    onChange={this.changeHandle}
                                    value={this.state.checkPaidAmount}
                                    />
                                </p>
                            </li>
                            <li className="list-group-item">
                                <p className="title-fade">Gift Card Amount &nbsp; <span className="text-danger">*</span></p>
                                <p className="title-amount">
                                    <input
                                    name="giftCardPaidAmount"
                                    type="text"
                                    maxLength="9"
                                    onChange={this.changeHandle}
                                    value={this.state.giftCardPaidAmount}
                                    />
                                </p>
                            </li>


                            <li className="list-group-item">
                                <p className="title-fade">Reward Points Used &nbsp; <span className="text-danger">*</span></p>
                                <p className="title-amount">
                                    <input

                                    type="text"
                                    name="rewardDeductionAmount"
                                    onChange={this.changeHandle}
                                    value={this.state.rewardDeductionAmount}
                                    />
                                </p>
                            </li>

                            <li className="list-group-item">
                                <p className="title-fade">Discount Amount &nbsp; <span className="text-danger">*</span></p>
                                <p className="title-amount">
                                    <input

                                    type="text"
                                    name="discountDeductionAmount"
                                    onChange={this.changeHandle}
                                    value={this.state.discountDeductionAmount}
                                    />
                                </p>
                            </li>



                        </ul>
                        <div className="switcher-spacer"></div>
                    </div>



                    <div className="modal-footer">
                        <button onClick={this.apptCheckout} className="btn btn-custom-info checkout">Check Out</button>
                    </div>


                </div>
                }

            </React.Fragment>
        );
    }
}

export default CheckOut;