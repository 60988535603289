export const reservationConstants = {
    APPOINTMENTS_REQUEST: 'APPOINTMENTS_SLOTS',
    APPOINTMENTS_SUCCESS: 'APPOINTMENTS_SLOTS',
    APPOINTMENTS_FAILURE: 'APPOINTMENTS_SLOTS',
 
    PLACE_RESERVATION_REQUEST: 'PLACE_RESERVATION_REQUEST',
    PLACE_RESERVATION_SUCCESS: 'PLACE_RESERVATION_SUCCESS',
    PLACE_RESERVATION_FAILURE: 'PLACE_RESERVATION_FAILURE',
 
    CHECK_IN_CUSTOMER_REQUEST: 'CHECK_IN_CUSTOMER_REQUEST',
    CHECK_IN_CUSTOMER_SUCCESS: 'CHECK_IN_CUSTOMER_SUCCESS',
    CHECK_IN_CUSTOMER_FAILURE: 'CHECK_IN_CUSTOMER_FAILURE',

    CHECK_OUT_CUSTOMER_REQUEST: 'CHECK_OUT_CUSTOMER_REQUEST',
    CHECK_OUT_CUSTOMER_SUCCESS: 'CHECK_OUT_CUSTOMER_SUCCESS',
    CHECK_OUT_CUSTOMER_FAILURE: 'CHECK_OUT_CUSTOMER_FAILURE',

    CHECK_IN_APPOINTMENT_REQUEST: 'CHECK_IN_APPOINTMENT_REQUEST',
    CHECK_IN_APPOINTMENT_SUCCESS: 'CHECK_IN_APPOINTMENT_SUCCESS',
    CHECK_IN_APPOINTMENT_FAILURE: 'CHECK_IN_APPOINTMENT_FAILURE',

    CHECK_OUT_APPOINTMENT_REQUEST: 'CHECK_OUT_APPOINTMENT_REQUEST',
    CHECK_OUT_APPOINTMENT_SUCCESS: 'CHECK_OUT_APPOINTMENT_SUCCESS',
    CHECK_OUT_APPOINTMENT_FAILURE: 'CHECK_OUT_APPOINTMENT_FAILURE',

    APPOINTMENT_SLOTS_REQUEST: 'APPOINTMENT_SLOTS_REQUEST',
    APPOINTMENT_SLOTS_SUCCESS: 'APPOINTMENT_SLOTS_SUCCESS',
    APPOINTMENT_SLOTS_FAILURE: 'APPOINTMENT_SLOTS_FAILURE',

 
};