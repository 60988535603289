import React, { useEffect, useState, useCallback } from "react";
import _ from "lodash";

const Search = ({ changeValue }) => {
  const [value, setValue] = useState('')
  const inputRef = React.createRef(null);


  //send value in delay
  const sendValueWithDebounce = useCallback(_.debounce((value) => changeValue(value), 400), []);
  const changeHandler = (e) => {
    const { value } = e.target;
    setValue(value);
    sendValueWithDebounce(value);
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <div className="dropdown-search-field">
      <input
        value={value}
        type="search"
        ref={inputRef}
        autoFocus
        onChange={changeHandler}
        placeholder="Search here..."
        className="form-control"
      />
      {value !== '' &&
        <div onClick={() => { setValue(''); changeValue('') }} className="search-closer">&times;</div>
      }
    </div>
  );
};

export default Search;
