import React, {Component} from 'react';


class Persona extends Component {


    componentDidMount() {
        window.initiPop()
    }

    render() {

        return (
            <div>
                <div className="persona-wrapper">

                    <div id="carouselExampleSlidesOnly" className="carousel slide" data-ride="carousel">

                        <div className="carousel-inner">
                            {
                                this.props.tribes.length > 0 && this.props.tribes.map((s,key)=>{
                                return <div key={key} className={key == 0 ? `item active` : `item` } style={{backgroundImage: "url("+s.imageUrl+")"}}></div>
                            }) }
                        </div>
                        <a className="left carousel-control" href="#carouselExampleSlidesOnly" role="button"
                           data-slide="prev">
                            <span className="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </a>
                        <a className="right carousel-control" href="#carouselExampleSlidesOnly" role="button"
                           data-slide="next">
                            <span className="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </a>
                    </div>


                </div>
                <div className="persona-list">
                    {this.props.prevQuestions.length > 0 && this.props.prevQuestions.map((p,k)=>{
                        return <div key={k} className="lists">
                            <div className="title-fade">
                                <div>{p.question.title}</div>
                                <div>
                                    {
                                        p.comment != '' && <button data-placement="left" className="no-border" onMouseEnter={()=>window.initiPop()} data-toggle="popover" data-content={'<p style="font-size: 12px;font-style: italic">'+p.comment+'</p>'}><i className="fa fa-comments"></i></button>
                                    }
                                </div>
                            </div>
                            <div className="result">

                                <div>
                                    {p.values.length > 0 && p.values.map((v,k)=>{
                                        return <ul key={k}>{this.findAnswers(v.questionOption.id,p.question.options,p.question.responseType)}</ul>
                                    })}
                                </div>
                                <div>
                                    {p.values.length > 0 && p.values.map((v,k)=>{
                                        return v.comment != '' && <button key={k} data-placement="left" className="no-border" onMouseEnter={()=>window.initiPop()} data-toggle="popover" data-content={'<p style="font-size: 12px;font-style: italic">'+v.comment+'</p>'}><i className="fa fa-comments"></i></button>
                                    })}
                                </div>
                            </div>
                        </div>
                    })}

                </div>
            </div>
        )
    }

    findAnswers=(id,options,type)=>{
        const exist = options.find(op=>op.id == id);
        if(exist){
            if(type == "SingleValueRadioButton" || type == "MultiValueCheckBox"){
                return <li>{exist.text}</li>
            }else{
                return <li>{exist.hint}</li>
            }
        }
    }

    makeSlider=(slides)=>{
        slides.map((sl,k)=>{
            let Activeclass = k == 0 ? 'active' : '';

        })
}

}

export default Persona
