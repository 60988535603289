/* eslint-disable */
import React, {useEffect, useState, useRef} from "react";
import CustomDropDown from "./custom-dropdown";
import NewProductSearchBar from "./newProductSearchBar";
import {connect} from "react-redux";
import ManageBrand from "./brand/manage";
import {inventoryActions} from "../_actions";
import BrandForm from "./brand";
import {inventoryService} from "../_services";
import CreatableSelect from "react-select/creatable";
import JoditEditor from "jodit-react";


const variantModel = [
    {
        id: 1,
        upc: '',
        msrp: '',
        salePrice: '',
        quantity: '',
        variantId: '',
        selectedType: {},
        selectedAttributes: {},
        attributes: [],
        success: undefined,
        message: ''
    }
];


const AddNewProduct = (props) => {
    const [addNew, setAddNew] = useState(false);
    const [errors, setErrors] = useState({});
    const [type, setType] = useState("");
    const [brndId, setBrandId] = useState("");
    const [selectedItem, setSelectedItem] = useState({});
    const [place, setPlace] = useState("");
    const [brandQueries, setBrandQueries] = useState({
        searchValue: "",
        pageCount: 0,
        pageSize: 500,
    });
    const editor = useRef(null);
    const [checkBoxes, setCheckBoxes] = useState([]);
    const [editableItem, setEditableItem] = useState({});
    const [selectedType, setSelectedType] = useState({});
    const [types, setTypes] = useState([]);
    const [allAttributes, setAllAttributes] = useState({});
    const [proccess, setProccess] = useState(false);
    const [instock, setStock] = useState(true);
    const [visible, setVisible] = useState(true);

    const [productId, setProductId] = useState('')


    const [variantArr, setVariantArr] = useState(variantModel)


    const [newProduct, setProduct] = useState({
        productName: "",
        brandId: "",
        salePrice: "",
        upc: "",
        msrp: "",
        quantity: "",
        details: "",
        ingredients: "",
        usage: "",
        productId: '',
        variantId: ''
    });


    useEffect(() => {
        setProduct(
            {
                productName: "",
                brandId: "",
                salePrice: "",
                upc: "",
                msrp: "",
                quantity: "",
                details: "",
                ingredients: "",
                usage: "",
                productId: '',
                variantId: ''
            }
        )
        setVariantArr(variantModel)
    }, [props.createNew]);


    const addMoreVariants = () => {
        const args = variantArr.slice();
        args.push({
            ...variantModel[0],
            id: variantArr[variantArr.length - 1].id + 1,
            upc: '',
            msrp: '',
            salePrice: '',
            quantity: '',
            variantId: '',
            success: undefined,
            message: ''
        })
        setVariantArr(args);
    }

    const removeVariants = (id) => {
        const filteredVariants = variantArr.slice();
        setVariantArr(filteredVariants.filter(item => item.id !== id))
    }

    const variantChangeHandler = (e) => {
        e.preventDefault();

        const {name, value, id} = e.target;
        setVariantArr(s => {
            const newArr = s.slice();
            newArr[id][name] = value;

            return newArr;
        });
    }


    const changeSearchValue = (value) => {
        setBrandQueries({...brandQueries, searchValue: value});
    };

    const changeHandler = (e) => {
        const {name, value} = e.target;

        setProduct({...newProduct, [name]: value});
    };

    const clearPlace = () => {
        setPlace("");
    };

    const onProductSelect = (product) => {
        setPlace(product.name);
    };

    //take action when start search
    const productNameHandler = (name) => {
        setProduct({
            ...newProduct,
            productName: name,
            quantity: "",
        });
    };

    useEffect(() => {
        const {dispatch} = props;
        dispatch(inventoryActions.getBrands(brandQueries));
    }, [brandQueries.searchValue]);

    useEffect(() => {
        const getAttributes = async () => {
            const attributes = await inventoryService.getVisualAttributes();
            if (attributes.status) {
                Swal.fire({
                    title: "Error",
                    text: attributes.message,
                    type: "error",
                    showCancelButton: false,
                    confirmButtonText: "Okay",
                });
            } else {

                setTypes(
                    Object.keys(attributes).length > 0
                        ? Object.keys(attributes).map((k) => {
                            return {label: k, value: k};
                        })
                        : []
                );

                setVariantArr(s => {
                    const newArr = s.slice();
                    newArr[0]['selectedType'] = Object.keys(attributes).length > 0
                        ? {
                            label: Object.keys(attributes)[0],
                            value: Object.keys(attributes)[0],
                        }
                        : "";
                    newArr[0]['attributes'] = Object.keys(attributes).length > 0
                        ? attributes[Object.keys(attributes)[0]]
                        : []

                    return newArr;
                });

                setSelectedType(
                    Object.keys(attributes).length > 0
                        ? {
                            label: Object.keys(attributes)[0],
                            value: Object.keys(attributes)[0],
                        }
                        : ""
                );
                setAllAttributes(attributes);
            }
        };
        getAttributes();
    }, []);

    //type change handler

    const changeTypesHandler = (types, id) => {
        setVariantArr(s => {
            const newArr = s.slice();
            newArr[id]['selectedType'] = types;
            newArr[id]['selectedAttributes'] = {};
            newArr[id]['attributes'] =
                Object.keys(allAttributes).length > 0 && allAttributes[types.value]
                    ? allAttributes[types.value]
                    : []

            return newArr;
        });

    };

    //getting selected brand
    const onItemSelect = (item) => {
        setSelectedItem(item);
        setBrandId(item.id);
        clearPlace();
        resetProduct();
    };

    const resetProduct = () => {
        setProduct({
            productName: "",
            brandId: "",
            salePrice: "",
            upc: "",
            msrp: "",
            quantity: "",
            details: "",
            ingredients: "",
            usage: "",
        });
    };

    //open and determine which type of form need to show
    const setTypeAndOpenState = (type) => {
        setAddNew(!addNew);
        setType(type);
        setSelectedItem({});
        setEditableItem({});
        setCheckBoxes([]);
        setErrors({});
    };

    //create new item etc. brand

    const saveNewItem = (label, callBack) => {
        if (label !== "") {
            setErrors({});
            inventoryService
                .createBrands(label)
                .then((response) => {
                    const {dispatch} = props;
                    dispatch(inventoryActions.getBrands(brandQueries));

                    setTypeAndOpenState("");
                    setSelectedItem(response);
                    callBack();
                })
                .catch((e) => {
                    e.json().then((e) => {
                        console.log(e);
                    });
                });
        } else {
            setErrors({brandName: "Please enter brand name"});
        }
    };

    //collection of deletable ids
    const selectToDelete = (item) => {
        const selectedCheckboxes = checkBoxes;
        const findIdx = selectedCheckboxes.indexOf(item.id);
        if (findIdx > -1) {
            selectedCheckboxes.splice(findIdx, 1);
        } else {
            selectedCheckboxes.push(item.id);
        }

        setCheckBoxes([...selectedCheckboxes]);
    };

    //delete selectedItem
    const deleteHandler = (ids) => {
        Swal.fire({
            title: "Delete",
            text: `Are you sure to delete item/s?`,
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete!",
            cancelButtonText: "No, keep",
        }).then((result) => {
            if (result.value) {
                inventoryService
                    .deleteBrands(ids)
                    .then(() => {
                        const {dispatch} = props;
                        setSelectedItem({});
                        window.toast("success", `Selected brand(s) deleted successfully.`);
                        dispatch(inventoryActions.getBrands(brandQueries));
                    })
                    .catch((err) => {
                        err.json().then((e) => {
                            Swal.fire({
                                title: "Error",
                                text: `${e.message}.`,
                                type: "warning",
                                showCancelButton: true,
                                showConfirmButton: false,
                                cancelButtonText: "Ok",
                            });
                        });
                    });
            }
        });
    };

    //edit / update item

    const selectForEdit = (item) => {
        setEditableItem(item);
        setErrors({});
    };
    const editChangeHandler = (e) => {
        setEditableItem({...editableItem, name: e.target.value});
    };
    //updating item
    const updateItem = () => {
        const {name, id} = editableItem;
        if (name !== "") {
            setErrors({});
            inventoryService
                .updateBrands(name, id)
                .then((response) => {
                    const {dispatch} = props;
                    window.toast("success", `Item updated successfully.`);
                    setEditableItem({});
                    dispatch(inventoryActions.getBrands(brandQueries));
                })
                .catch((e) => {
                    e.json().then((e) => {
                        Swal.fire({
                            title: "Error",
                            text: `${e.message}.`,
                            type: "warning",
                            showCancelButton: true,
                            showConfirmButton: false,
                            cancelButtonText: "Ok",
                        });
                    });
                });
        } else {
            setErrors({editableItemName: "Please enter a valid brand name"});
        }
    };

    //selecting attributes
    const selectAttributes = (item, id) => {
        setVariantArr(s => {
            const newArr = s.slice();
            newArr[id]['selectedAttributes'] = item;
            return newArr;
        });
    };

    //adding new product
    const addProduct = async (e) => {
        e.preventDefault();
        const {
            productName,
            details,
            ingredients,
            usage,
        } = newProduct;

        const productJSON = {
            product: {
                brand: {
                    id: brndId,
                },
                name: productName,
                details: details,
                usage: usage,
                ingredients: ingredients,
                rating: 5,
                inStock: instock,
                visible: visible,
            },

            variants: formatVariants()

        };

        const errors = validateData(productJSON);
        setErrors(errors);
        if (Object.keys(errors).length === 0) {
            setErrors({});
            setProccess(true);
            if (newProduct.productId === undefined || newProduct.productId === '') {
                const result = await inventoryService.addProduct(productJSON.product);
                if (result.status) {
                    showErrorMessage(result)
                    setProccess(false);
                } else {
                    setProductId(result.id)
                    addVariants(result.id, productJSON.variants);

                }
            } else {
                if (props.actionType === 'productOnly') {

                    updateProductInfo(productJSON)

                } else if (props.actionType === 'variantOnly') {

                    productJSON.variants[0].product = {id: newProduct.productId}

                    const variant = await inventoryService.updateProductVariant(productJSON.variants[0], variantArr[0].variantId);
                    if (variant.status) {
                        setProccess(false)
                        showErrorMessage(variant)
                    } else {
                        window.toast("success", `Product updated successfully`);
                        setProccess(false)
                        if (props.updatedVariant) {
                            props.updatedVariant(variant)
                        }

                        if (props.refetchProduct) {
                            props.refetchProduct()
                        }
                    }

                } else {
                    ///
                }
            }
        }
    };

    //formate variant before save

    const formatVariants = () => {
        return variantArr.filter(variant => variant.success !== true).map(variant => {
            const {label} = variant.selectedType
            const {value} = variant.selectedAttributes
            return {
                product: {},
                upc: variant.upc,
                msrp: variant.msrp,
                salePrice: variant.salePrice,
                quantity: variant.quantity,
                estimatedDaysToShip: 15,

                visualAttributeValues: [
                    {
                        attribute: label !== undefined ? label : '',
                        value: value !== undefined ? value : '',
                    },
                ],
            }
        })

    }

    //adding variants with product
    const addVariants = (id, variants) => {

        variants.map(async (variant, index) => {
            variant.product = {id};
            const variantRes = await inventoryService.addProductVariant(variant);
            if (variantRes.status) {

                setVariantArr(prev => {
                    const listWidthError = prev.map((v, key) => {
                        if (key === index) {
                            return {
                                ...v,
                                success: false,
                                message: 'Failed, please fix input data and retry'
                            }
                        }
                        return v
                    });
                    return listWidthError

                })
            } else {

                setVariantArr(prev => {
                    const listWidthSuccess = prev.map((v, key) => {
                        if (key === index) {
                            return {
                                ...v,
                                success: true,
                                message: 'This variant created successfully!'
                            }
                        }
                        return v
                    });
                    return listWidthSuccess

                })
            }
            if (index === variants.length - 1) {
                setProccess(false)
            }
        })
    }

    //retry to add if failes
    const addProductFailure = () => {
        setProccess(true);
        addVariants(productId, formatVariants());
    }

    //update product informtion
    const updateProductInfo = async (productJSON) => {
        const updatedProduct = await inventoryService.updateProduct(newProduct.productId, productJSON.product);
        if (updatedProduct.status) {
            setProccess(false)
            showErrorMessage(updatedProduct)
        } else {
            setProccess(false)
            window.toast("success", `Product updated successfully`);
            if (props.updatedInfo) {
                props.updatedInfo(updatedProduct)
            }
            if (props.refetchProduct) {
                props.refetchProduct()
            }

        }
    }

    const showErrorMessage = (error) => {
        console.log(error)
        const errorMessage = error.errors ? error.errors : error.message;
        Swal.fire({
            title: "Error",
            text: `${errorMessage.join(';')}.`,
            type: "error",
            confirmButtonText: "Ok",
        });
    }


    //asking user to add image
    const askingForImage = () => {
        props.addImage(productId)
    }


    //validate
    const validateData = (data) => {

        const errors = {};
        if (data.product.brand.id === "")

            errors.brandId = "Please select or create a brand first";

        if (data.product.name === "") errors.name = "Please enter product name";
        if (newProduct.productId === undefined || newProduct.productId === '') {
            data.variants.map((variant, key) => {
                //if (variant.upc === "") errors[`upc-${key + 1}`] = "Enter upc code";
                if (variant.msrp === "") errors[`msrp-${key + 1}`] = "Enter msrp";
                if (variant.salePrice === "") errors[`salePrice-${key + 1}`] = "Enter sale price";
                if (variant.quantity === "") errors[`quantity-${key + 1}`] = "Invalid quantity";
                if (variant.visualAttributeValues[0].attribute === '')
                    errors[`attribute-${key + 1}`] = "Invalid attribute";
                if (variant.visualAttributeValues[0].value === '')
                    errors[`value-${key + 1}`] = "Invalid attribute value";
            })
        }

        return errors;
    };


    //EDIT EDIT PROUDCT
    useEffect(() => {
        if (props.details && Object.keys(props.details).length > 0) {
            const {details} = props;

            if (details !== null && details !== undefined && Object.keys(details).length > 0) {
                setSelectedItem({id: details.brand.id, name: details.brand.name});
                setBrandId(details.brand.id);
                clearPlace();
                resetProduct();
                setProduct({
                    productName: details.name,
                    brndId: details.brand.id,
                    upc: details.upc,
                    msrp: details.msrp,
                    salePrice: details.salePrice,
                    quantity: details.quantity,
                    productId: details.id,
                    details: details.details ? details.details : '',
                    ingredients: details.ingredients ? details.ingredients : '',
                    usage: details.usage ? details.usage : '',
                });
                setStock(details.inStock);
                setVisible(details.visible)
                setPlace(details.name)
            }
        }
        if (props.variant && Object.keys(props.variant).length > 0) {
            const variants = variantArr.slice();
            const variantInfos = variants.map(v => {
                const {variant} = props;
                return {
                    ...v,
                    id: variant.id,
                    upc: variant.upc,
                    msrp: variant.msrp,
                    salePrice: variant.salePrice,
                    quantity: variant.quantity,
                    variantId: variant.id,
                    success: undefined,
                    message: '',
                    selectedType: {
                        label: variant.visualAttributeValues[0].attribute,
                        value: variant.visualAttributeValues[0].attribute
                    },
                    selectedAttributes: {
                        label: variant.visualAttributeValues[0].value,
                        value: variant.visualAttributeValues[0].value
                    }
                }
            })
            setVariantArr(variantInfos)

        }


    }, [props.details, props.variant])


    const {productName, brandId, salePrice, msrp, upc, quantity} = newProduct;
    const {loading, brands} = props;
    return (
        <React.Fragment>
            {proccess && (
                <div
                    className={"custom-loader"}
                    style={{height: "100%", left: 0, right: 0, top: 0, bottom: 0}}
                >
                    <div className="loaderDiv">
                        <div className="loader"></div>
                    </div>
                </div>
            )}

            <div className="row">
                <div className="col-md-12">
                    <br/>

                    {props.actionType !== 'variantOnly' && <h3>Product Information</h3>}
                    <br/>

                    {/* BRAND DROPDOWN STARTS HERE */}
                    {props.actionType !== 'variantOnly' &&
                        <div className="form-group">
                            <label>Select Brand*</label>
                            <div>

                                <CustomDropDown
                                    addType="add-brand"
                                    manageType="manage-brand"
                                    items={brands}
                                    details={props.details}
                                    changeSearchValue={changeSearchValue}
                                    onSelect={onItemSelect}
                                    selectedItem={selectedItem}
                                    openAddNew={setTypeAndOpenState}
                                />
                                <small className="invalid">
                                    {errors.brandId ? errors.brandId : ""}
                                </small>
                            </div>
                        </div>
                    }{/*BRAND DROPDOWN ENDS HERE*/}


                    {/*PRODUCT NAME*/}
                    {brndId != "" && brndId != "none" && (

                        <div className="form-group">
                            <div>

                                {props.actionType !== 'variantOnly' &&
                                    <React.Fragment>
                                        <label>Product Name*</label>

                                        <NewProductSearchBar
                                            brands={[brndId]}
                                            place={place}
                                            getProductName={productNameHandler}
                                            clearPlace={clearPlace}
                                            onProductSelect={onProductSelect}
                                        />

                                    </React.Fragment>
                                }
                                {errors.name ? (
                                    <p className="text-danger">{errors.name}</p>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    )}
                    {/* PRODUCT NAME ENDS */}

                    {/* Jodit editor */}

                    {props.actionType !== 'variantOnly' &&
                        <div>
                            <div className="customAccordion">
                                <div className="panel-group" id="accordion">
                                    {["details", "ingredients", "usage"].map(
                                        (item, key) => {
                                            return (
                                                <div className="panel panel-default" key={key}>
                                                    <div className="panel-heading">
                                                        <h4 className="panel-title">
                                                            <a
                                                                data-toggle="collapse"
                                                                data-parent="#accordion"
                                                                href={`#-${item}`}
                                                            >
                                                                + {item}
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div
                                                        id={`-${item}`}
                                                        className="panel-collapse collapse"
                                                    >
                                                        <div
                                                            className="panel-body"
                                                            style={{padding: "0px !importent"}}
                                                        >
                                                            <JoditEditor
                                                                ref={editor}
                                                                value={newProduct[item]}
                                                                onChange={(newContent) =>
                                                                    setProduct({
                                                                        ...newProduct,
                                                                        [item]: newContent,
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    )}
                                </div>
                            </div>

                        </div>}

                    {/* product information end */}

                    {/* SWITHCER START*/}
                    {props.actionType !== 'variantOnly' &&
                        <div>
                            <div className="instock d-flex items-center">
                                <label htmlFor="instock">Instock &nbsp; &nbsp; &nbsp; </label>
                                <label className="switch">
                                    <input
                                        id={"instock"}
                                        type="checkbox"
                                        checked={instock}
                                        readOnly
                                        onClick={() => setStock(!instock)}
                                    />
                                    <span className="switchSlider"></span>
                                </label>
                            </div>

                            <div className="instock d-flex items-center">
                                <label htmlFor="visible">Visible &nbsp; &nbsp; &nbsp; </label>

                                <label className="switch">
                                    <input
                                        id={"visible"}
                                        type="checkbox"
                                        checked={visible}
                                        readOnly
                                        onClick={() => setVisible(!visible)}
                                    />
                                    <span className="switchSlider"></span>
                                </label>
                            </div>
                        </div>
                    }
                    {/* SWITCHER ENDS */}

                </div>
            </div>
            {props.actionType !== 'productOnly' &&
                <React.Fragment>
                    <div className="row">
                        <div className="col-md-12">
                            <br/>

                            <h3>Please add variants</h3>
                        </div>
                    </div>
                    {variantArr.length > 0 && variantArr.map((item, key) => {
                        return (
                            <React.Fragment key={key}>
                                <div
                                    className={`row ${item.success === true ? 'succeed' : item.success === false ? 'failed' : ''}`}>
                                    <div className="col-md-12 text-center">
                                        <span className="counter">{key + 1}</span>
                                        {key !== 0 &&
                                            <span style={{cursor: 'pointer'}} onClick={() => removeVariants(item.id)}
                                                  className="counter">&times;</span>
                                        }
                                    </div>
                                    {/* UPC BLOCK */}
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <div>
                                                <small>UPC
                                                    <span
                                                        className="invalid"> {errors[`upc-${key + 1}`] ? '(' + errors[`upc-${key + 1}`] + ')' : ""}</span>
                                                </small>
                                                <input
                                                    id={key}
                                                    onChange={variantChangeHandler}
                                                    value={item.upc}
                                                    placeholder="UPC"
                                                    type="text"
                                                    name="upc"
                                                    className={`form-control`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* UPC ENDS */}
                                    {/* MSRP BLOCK */}
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <div>
                                                <small>MSRP
                                                    <span
                                                        className="invalid"> {errors[`msrp-${key + 1}`] ? '(' + errors[`msrp-${key + 1}`] + ')' : ""}</span>
                                                </small>
                                                <input
                                                    id={key}
                                                    placeholder="MSRP"
                                                    onChange={variantChangeHandler}
                                                    value={item.msrp}
                                                    name="msrp"
                                                    type="text"
                                                    className={`form-control`}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                    {/* MSRP ENDS */}

                                    {/* SALE PRICE */}
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <div>
                                                <small>SALE PRICE
                                                    <span
                                                        className="invalid"> {errors[`salePrice-${key + 1}`] ? '(' + errors[`salePrice-${key + 1}`] + ')' : ""}</span>
                                                </small>
                                                <input
                                                    id={key}
                                                    name="salePrice"
                                                    onChange={variantChangeHandler}
                                                    value={item.salePrice}
                                                    placeholder="Salon Price"
                                                    type="text"
                                                    className={`form-control`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* -/.SALE PRICE ENDS */}

                                    {/* ATRRIBUTES TYPES START */}
                                    <div className="col-md-4">

                                        <div className="form-group">
                                            <div>
                                                <small>
                                                    TYPES{" "}
                                                    {Object.keys(selectedType).length > 0
                                                        ? `- ${item.selectedType.label}`
                                                        : ""}

                                                    <span
                                                        className="invalid"> {errors[`attribute-${key + 1}`] ? '(' + errors[`attribute-${key + 1}`] + ')' : ""}</span>
                                                </small>

                                                {Object.keys(item.selectedType).length > 0 && (
                                                    <CreatableSelect
                                                        className={`attribute-selection type-selection`}
                                                        onChange={(types) => changeTypesHandler(types, key)}
                                                        isMulti={false}
                                                        options={types}
                                                        isSearchable={true}
                                                        defaultValue={item.selectedType}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {/*--- ATTRIBUTES TYPES ENDS --- */}

                                    {/* ATRRIBUTES START */}
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <div>
                                                <small>
                                                    ATTRIBUTES{" "}
                                                    {Object.keys(item.selectedAttributes).length > 0
                                                        ? `- ${item.selectedAttributes.label}`
                                                        : ""}


                                                    <span
                                                        className="invalid"> {errors[`value-${key + 1}`] ? '(' + errors[`value-${key + 1}`] + ')' : ""}</span>

                                                </small>

                                                <CreatableSelect
                                                    className={`attribute-selection`}
                                                    onChange={(attr) => selectAttributes(attr, key)}
                                                    options={
                                                        item.attributes.length > 0
                                                            ? item.attributes.map((attr) => {
                                                                return {label: attr, value: attr};
                                                            })
                                                            : []
                                                    }
                                                    isMulti={false}
                                                    isSearchable={true}
                                                    value={
                                                        Object.keys(item.selectedAttributes).length > 0
                                                            ? item.selectedAttributes
                                                            : ""
                                                    }
                                                />

                                            </div>
                                        </div>
                                    </div>
                                    {/*--- ATTRIBUTES ENDS --- */}

                                    {/* QUNATITY START */}
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <div>
                                                <small>
                                                    ATTR. VALUES{" "}

                                                    <span
                                                        className="invalid"> {errors[`quantity-${key + 1}`] ? '(' + errors[`quantity-${key + 1}`] + ')' : ""}</span>
                                                </small>
                                                <input
                                                    name="quantity"
                                                    id={key}
                                                    onChange={variantChangeHandler}
                                                    value={item.quantity}
                                                    placeholder="weight/qty"
                                                    type="text"
                                                    className={`form-control`}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                    {/*--- QUANTITY ENDS --- */}
                                    <span className="col-md-12"><strong>
                  {item.success === true ? <i className="fa fa-check"></i>
                      : item.success === false ? <i className="fa fa-ban"></i>
                          : null}
                                        {item.message}</strong>

                </span>

                                </div>

                            </React.Fragment>

                        )
                    })}

                    <div className="row">
                        <div className="col-md-4">
                            <div className="line"></div>
                        </div>
                        <div className="col-md-4">
                            <div className="add-variant-form text-center">
                                <button onClick={addMoreVariants}>+ More Variants</button>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="line"></div>
                        </div>
                    </div>

                </React.Fragment>
            }

            <br/>
            <br/>

            <div className="row">
                <div className="col-md-12 text-right">
                    {variantArr.filter(variant => variant.success === false).length > 0 &&
                        <button onClick={addProductFailure} className="btn btn-custom-info">
                            Retry
                        </button>}

                    {variantArr.filter(variant => variant.success === true).length > 0 ?

                        <button onClick={askingForImage} className="btn btn-primary" style={{marginLeft: '10px'}}>
                            Finish proccess
                        </button> :

                        <button onClick={addProduct} className="btn btn-custom-info">
                            {props.actionType === 'productOnly' ? 'Update Product' : props.actionType === 'variantOnly' ? 'Update Variant' : props.actionType === 'imageOnly' ? 'Update Images' : 'Add Product'}
                        </button>
                    }

                </div>
            </div>


            <div
                className={`add-new-item d-flex flex-column justify-between ${addNew ? "visible" : ""
                }`}
            >
                <div className="d-flex justify-between items-center">
                    <h3>Create New Item</h3>
                    <button
                        onClick={() => setTypeAndOpenState("")}
                        className="btn btn-md btn-circle btn-danger btn-outline"
                        style={{fontSize: "20px"}}
                    >
                        &times;
                    </button>
                </div>

                {type === "add-brand" ? (
                    <BrandForm
                        openAddNew={() => setAddNew(false)}
                        saveNew={saveNewItem}
                        errors={errors}
                    />
                ) : (
                    <ManageBrand
                        errors={errors}
                        onEdit={selectForEdit}
                        editableItem={editableItem}
                        editItemName={editChangeHandler}
                        onDelete={() => deleteHandler(checkBoxes)}
                        deleteItem={(item) => deleteHandler([item.id])}
                        openAddNew={() => {
                            setAddNew(false);
                            setCheckBoxes([]), setEditableItem({}), setErrors({});
                        }}
                        hide={() => setEditableItem({})}
                        update={updateItem}
                        cls={addNew ? "visible" : ""}
                        items={brands}
                        deletableItems={checkBoxes}
                        collectCheckbox={selectToDelete}
                    />
                )}
            </div>

        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    const {loading, error, brands} = state.inventory;
    return {loading, error, brands};
};

export default connect(mapStateToProps)(AddNewProduct);
