/* eslint-disable */
import {userConstants} from '../_constants';
import {userService} from '../_services';
import {history} from '../_helpers/history';
import {appService} from '../_services';

export const userActions = {
    login,
    logout,
    register,
    updateProfile,
    updateProfilePic,
    updatePassword,
    resetPassword,
    updateAppUser,
    getUserInfo,
    getAllCustomers,
    getAllTechnicians,
    saveNote,
    updateCustomerProfile,
    updateCustomerPassword,
    updateCustomerRewardBalance,
    saveFormulData,
    getCustomerFormulas,
    updateFormulData,
    getAllRewards,
    getAllTask
};

function saveScreenShot(img) {
    alert(img)
}

function login(username, password, businessId, storeId) {
    return dispatch => {
        dispatch(request({username}));

        userService.login(username, password, businessId, storeId)
            .then(
                user => {
                    appService.getAppDetails(storeId).then(r => {
                        if (user.passwordResetRequired == true) {
                            history.push(`/${storeId}/update-password`);
                        } else {
                            history.push(`/${storeId}/`);
                        }
                        dispatch(success(user));
                    });
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(user) {
        return {type: userConstants.LOGIN_REQUEST, user}
    }

    function success(user) {
        return {type: userConstants.LOGIN_SUCCESS, user}
    }

    function failure(error) {
        return {type: userConstants.LOGIN_FAILURE, error}
    }
}

function logout() {
    userService.logout();
    return {type: userConstants.LOGOUT};
}

function updateAppUser() {
    return dispatch => {
        userService.getUserInfo()
            .then(
                user => {
                    dispatch(success(user));
                    return user;
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function success(user) {
        return {type: userConstants.LOGIN_SUCCESS, user}
    }

    function failure(error) {
        return {type: userConstants.LOGIN_FAILURE, error}
    }
}

function saveNote(id, note) {

    return dispatch => {
        userService.saveNote(id, note)
            .then(response => {
                dispatch(success(note))
            })
    }

    function success(note) {
        return {type: userConstants.SAVE_CUSTOMER_NOTE, note}
    }
}


function getCustomerFormulas(id) {

    return dispatch => {
        dispatch(request({}))
        userService.getCustomerFormulas(id)
            .then(
                formula => {
                    dispatch(success(formula));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() {
        return {type: userConstants.REQUEST_FORMULA_DATA}
    }

    function success(formula) {
        return {type: userConstants.FETCH_FORMULA_DATA, formula}
    }

    function failure(formula) {
        return {type: userConstants.FAIL_FORMULA_DATA, formula}
    }
}

function deleteFormula(id) {
    //alert(id)
    return dispatch => {
        dispatch(request({}))
        userService.deleteFormula(id)
            .then(response => {
                dispatch(success(id))
            })
    }

    function request() {
        return {type: userConstants.REQUEST_DELETE_FORMULA}
    }

    function success(id) {
        return {type: userConstants.DELETE_CUSTOMER_FORMULA, id}
    }
}


function saveFormulData(data) {

    return dispatch => {
        dispatch(request({}));
        userService.saveFormulData(data)
            .then(datas => {
                dispatch(success(datas))
            })
    }

    function request() {
        return {type: userConstants.REQUEST_SAVE_FORMULA}
    }

    function success(datas) {
        return {type: userConstants.SAVE_FORMULA, datas}
    }


}

function updateFormulData(data, id) {

    return dispatch => {
        dispatch(request({}));
        userService.updateFormulData(data, id)
            .then(response => {
                dispatch(success(data, id))
            })
    }

    function request() {
        return {type: userConstants.UPDATE_REQUEST_FOR_FORMULA}
    }

    function success(data, id) {
        return {type: userConstants.UPDATE_CUSTOMER_FORMULA, data: data, id: id}
    }
}

function getUserInfo() {
    return dispatch => {
        dispatch(request({}));

        userService.getUserInfo()
            .then(
                user => {
                    dispatch(success(user));
                    return user;
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() {
        return {type: userConstants.GET_USER_INFO_REQUEST}
    }

    function success(user) {
        return {type: userConstants.GET_USER_INFO_SUCCESS, user}
    }

    function failure(error) {
        return {type: userConstants.GET_USER_INFO_FAILURE, error}
    }

}


function register(user) {
    return dispatch => {
        dispatch(request(user));

        userService.register(user)
            .then(
                createdUser => {
                    dispatch(success(createdUser));
                },
                error => {
                    error.text().then(errorMessage => {
                        let errMsg = JSON.parse(errorMessage).message
                        dispatch(failure(errMsg));
                    })

                }
            );
    };

    function request(user) {
        return {type: userConstants.REGISTER_REQUEST, user}
    }

    function success(user) {
        return {type: userConstants.REGISTER_SUCCESS, user}
    }

    function failure(error) {
        return {type: userConstants.REGISTER_FAILURE, error}
    }
}


function updateProfile(user) {
    return dispatch => {
        dispatch(request(user));

        userService.updateProfile(user)
            .then(
                updatedUser => {
                    let oldUser = JSON.parse(localStorage.getItem('user'))
                    updatedUser.token = oldUser.token;
                    updatedUser.csrfToken = oldUser.csrfToken;
                    updatedUser.loggedIn = true;
                    localStorage.setItem('user', JSON.stringify(updatedUser));
                    dispatch(success(updatedUser));
                    dispatch(updateProfileSuccess(updatedUser));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(user) {
        return {type: userConstants.UPDATE_PROFILE_REQUEST, user}
    }

    function success(user) {
        return {type: userConstants.LOGIN_SUCCESS, user}
    }

    function updateProfileSuccess(user) {
        return {type: userConstants.UPDATE_PROFILE_SUCCESS, user}
    }

    function failure(error) {
        return {type: userConstants.UPDATE_PROFILE_FAILURE, error}
    }
}

function updateCustomerProfile(userId, user) {
    return dispatch => {
        dispatch(request(user));

        userService.updateCustomerProfile(userId, user)
            .then(
                updatedUser => {
                    dispatch(success(updatedUser));
                },
                error => {
                    error.text().then(errorMessage => {
                        var errMsg = JSON.parse(errorMessage).message
                        errMsg += JSON.parse(errorMessage).errors != undefined ? ": " + JSON.parse(errorMessage).errors.toString() : ''
                        dispatch(failure(errMsg));
                    })
                }
            );
    };

    function request(user) {
        return {type: userConstants.UPDATE_PROFILE_REQUEST, user}
    }

    function success(user) {
        return {type: userConstants.UPDATE_PROFILE_SUCCESS, user}
    }

    function failure(error) {
        return {type: userConstants.UPDATE_PROFILE_FAILURE, error}
    }
}

function updateProfilePic(file) {
    return dispatch => {
        dispatch(request(file));
        userService.updateProfilePic(file)
            .then(
                updatedUser => {
                    let oldUser = JSON.parse(localStorage.getItem('user'))
                    updatedUser.token = oldUser.token;
                    updatedUser.csrfToken = oldUser.csrfToken;
                    updatedUser.loggedIn = true;
                    localStorage.setItem('user', JSON.stringify(updatedUser));
                    dispatch(success(updatedUser));
                    dispatch(updateProfileSuccess(updatedUser));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };


    function request(user) {
        return {type: userConstants.UPDATE_PROFILE_REQUEST, user}
    }

    function success(user) {
        return {type: userConstants.LOGIN_SUCCESS, user}
    }

    function updateProfileSuccess(user) {
        return {type: userConstants.UPDATE_PROFILE_SUCCESS, user}
    }

    function failure(error) {
        return {type: userConstants.UPDATE_PROFILE_FAILURE, error}
    }
}

function updatePassword(newPassword) {
    return dispatch => {
        dispatch(request());
        userService.updatePassword(newPassword)
            .then(
                updatedUser => {
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() {
        return {type: userConstants.UPDATE_PASSWORD_REQUEST}
    }

    function success() {
        return {type: userConstants.UPDATE_PASSWORD_SUCCESS}
    }

    function failure(error) {
        return {type: userConstants.UPDATE_PASSWORD_FAILURE, error}
    }
}

function resetPassword(email) {
    return dispatch => {
        dispatch(request(email));
        userService.resetPassword(email)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() {
        return {type: userConstants.RESET_PASSWORD_REQUEST}
    }

    function success(response) {
        return {type: userConstants.RESET_PASSWORD_SUCCESS, response}
    }

    function failure(error) {
        return {type: userConstants.RESET_PASSWORD_FAILURE, error}
    }
}

function getAllCustomers(type, offset, searchVal, refresh) {
    return dispatch => {
        dispatch(loading())
        userService.searchCustomers(type, offset, searchVal)
            .then(
                customers => {

                    dispatch(success(customers));
                    //refresh(customers);
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function success(customers) {
        return {type: userConstants.GET_ALL_USERS_SUCCESS, customers}
    }

    function failure(error) {
        return {type: userConstants.GET_ALL_USERS_FAILURE, error}
    }

    function loading() {
        return {type: userConstants.GET_ALL_USERS_REQUEST}
    }
}

function getSpecificCustomer(type, offset, searchVal) {
    return dispatch => {
        dispatch(request({}))
        userService.getSpecificCustomer(type, offset, searchVal)
            .then(
                mergeCustomer => {
                    dispatch(success(mergeCustomer));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }

    function request() {
        return {type: userConstants.MERGE_CUSTOMER_REQUEST}
    }

    function success(mergeCustomer) {
        return {type: userConstants.GET_MERGE_CUSTOMER, mergeCustomer}
    }


}

function getAllRewards(id, type, offset, searchVal) {
    return dispatch => {
        dispatch(request({}))
        userService.getAllRewards(id, type, offset, searchVal)
            .then(
                rewards => {
                    dispatch(success(rewards));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() {
        return {type: userConstants.REQUEST_FOR_USER_REWARDS}
    }

    function success(rewards) {
        return {type: userConstants.GET_ALL_USER_REWARDS, rewards}
    }

    function failure(error) {
        return {type: userConstants.REWARDS_FAILURE_IN_FETCHING, rewards}
    }

}

function getAllUpdatedRewards(id, type, offset, searchVal) {
    return dispatch => {
        userService.getAllRewards(id, type, offset, searchVal)
            .then(
                rewards => {
                    dispatch(success(rewards));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function success(rewards) {
        return {type: userConstants.GET_ALL_UPDATED_REWARDS, rewards}
    }

}


function getAllTechnicians(type, offset, searchVal) {

    return dispatch => {
        dispatch(request({}))
        userService.getAllTechnicians(type, offset, searchVal)
            .then(
                technicians => {
                    dispatch(success(technicians));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() {
        return {type: userConstants.GET_ALL_TECHNICIANS_REQUEST}
    }

    function success(technicians) {
        return {type: userConstants.GET_ALL_TECHNICIANS_SUCCESS, technicians}
    }

    function failure(error) {
        return {type: userConstants.GET_ALL_TECHNICIANS_FAILURE, error}
    }
}

function getTechnicansSchedule(id) {
    return dispatch => {

        userService.getTechniciansSchedule(id)
            .then(
                schedule => {
                    dispatch(success(schedule));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function success(schedule) {
        return {type: userConstants.GET_TECHNICIANS_SCHEDULE_SUCCESS, schedule}
    }

    function failure(error) {
        return {type: userConstants.GET_TECHNICIANS_SCHEDULE_FAILURE, error}
    }
}

function updateCustomerPassword(userId, newPassword) {
    return dispatch => {
        userService.updateCustomerPassword(userId, newPassword)
            .then(
                updatedUser => {
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function success() {
        return {type: userConstants.UPDATE_PASSWORD_SUCCESS}
    }

    function failure(error) {
        return {type: userConstants.UPDATE_PASSWORD_FAILURE, error}
    }
}

function updateCustomerRewardBalance(rewardJson) {
    return dispatch => {
        userService.updateCustomerRewardBalance(rewardJson)
            .then(
                updatedUser => {
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };


    function success() {
        return {type: userConstants.UPDATE_REWARD_SUCCESS}
    }

    function failure(error) {
        return {type: userConstants.UPDATE_REWARD_FAILURE, error}
    }
}


function getAllTask(offset) {
    return dispatch => {
        dispatch(request({}))
        userService.getAllTask(offset)
            .then(
                task => {
                    dispatch(success(task));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() {
        return {type: userConstants.GET_ALL_TASK_REQUEST}
    }

    function success(task) {
        return {type: userConstants.GET_ALL_TASK_SUCCESS, task}
    }

    function failure(error) {
        return {type: userConstants.GET_ALL_TASK_ERROR, error}
    }

}
