import { inventoryConstants } from '../_constants';
const initialSate = {
  brands: [],
  loading: false,
  error: {},
  totalProducts: 0
}
export function inventory(state = initialSate, action) {

  switch (action.type) {
    case inventoryConstants.GET_ALL_PRODUCTS_REQUEST:
      return {
        ...state,
        products: [],
        productsLoaded: false,
        totalProducts: 0
      }

    case inventoryConstants.GET_ALL_PRODUCTS_SUCCESS:

      return {
        ...state,
        products: action.inventory.data,
        productsLoaded: true,
        totalProduct: action.inventory.totalCount
      }
    case inventoryConstants.GET_ALL_PRODUCTS_FAILURE:
      return {
        ...state,
        products: [],
        productsLoaded: false,
        totalProducts: 0
      }


    case inventoryConstants.BRANDS_LOADING:
      return {
        ...state,
        loading: true
      }
    case inventoryConstants.BRANDS_FETCHED:
      return {
        ...state,
        loading: false,
        error: {},
        brands: action.brands
      }
    case inventoryConstants.BRANDS_FAILURE:
      return {
        ...state,
        loading: false,
        errror: action.error
      }
    default:
      return state
  }
}
