/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { inventoryService } from '../../_services'
import ProductInfo from './productInfo';
import VariantItem from './variantItem';
import './inventory-component.css'
import AddNewProduct from '../addNewProduct';
import RcDropZone from '../rc-dropzone';

const ProductPreview = ({ id, bsnsId, refetchProduct }) => {
  const [info, setInfo] = useState({});
  const [variants, setVariants] = useState([])
  const [typeOfAction, setTypeOfAction] = useState('productOnly');
  const [productDetails, setProductDetails] = useState({});
  const [isEdit, setIsEdit] = useState(false)
  const [singleVariant, setSingleVariant] = useState({})
  const [loading, setLoading] = useState(true)
  const [files, setFiles] = useState(undefined);
  const [visualFiles, setVisualFiles] = useState([])

  useEffect(() => {
    setIsEdit(false)
    if (id !== undefined) {
      const getData = async () => {
        const product = await inventoryService.getProductById(id, bsnsId);
        if (product.status === undefined && Object.keys(product).length > 0) {
          reOrganizeInfo(product);
          setLoading(false)

        }
      }
      getData();
    }
  }, [id]);
  const openEditFormHandler = (type, variant = undefined) => {
    setTypeOfAction(type);
    setIsEdit(true);
    setSingleVariant(variant === undefined ? {} : variant)
  }

  const closeEditForm = () => {
    setIsEdit(false);
    setSingleVariant({});
    setFiles(undefined);
    setVisualFiles([])

  }

  const getUpdatedInfo = (info) => {
    reOrganizeInfo(info);
  }

  const setUpdatedVariant = (variant) => {
    setVariants(prev => {
      prev.splice(prev.indexOf(singleVariant), 1, variant)
      return prev;
    })
  }


  const reOrganizeInfo = (product) => {
    const { id: ProductId, name, brand, visible, inStock, details, usage, ingredients, rating, variants } = product;
    const info = {
      id: ProductId,
      name,
      brand,
      visible,
      inStock,
      details,
      usage,
      ingredients,
      rating
    }
    setInfo(info)
    setProductDetails(info)
    setVariants(variants)
  }

  const getSelectedFiles = (fls) => {
    setFiles(fls)
    setVisualFiles(fls.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    })));
  }


  const updateImages = async () => {
    if (!Array.isArray(files)) {
      Swal.fire({
        title: "Warning",
        text: `Please select image or images file first`,
        type: "warning",
        showCancelButton: false,
        confirmButtonText: "Ok",
      });
    } else {

      setLoading(true)
      const result = await inventoryService.addImages(files, singleVariant.id);
      if (result.status) {
        setLoading(false)
        Swal.fire({
          title: "Error",
          text: `{result.message}`,
          type: "error",
          showCancelButton: false,
          confirmButtonText: "Ok",
        });

      } else {
        setLoading(false)
        window.toast("success", `Product image(s) are updated successfully`);
        refetchProduct();
        setUpdatedVariant(result)

      }
    }

  }

  const deleteItem = async (type, variant, index) => {

    if (type === 'image') {
      setSingleVariant(variant)
      const result = await inventoryService.removeImage(variant.id, index);
      if (result.status === 200) {
        const updatedVariant = variant;
        updatedVariant.imageUrls.splice(index, 1)
        setUpdatedVariant(updatedVariant)

      } else {

      }
    }

  }


  return (
    <div className="row">
      <div className="col-md-12">
        {Object.keys(info).length > 0 && <ProductInfo openEditForm={() => openEditFormHandler('productOnly')} product={info} />}
      </div>

      <br />
      <div style={{ margin: '30px 0' }} className="col-md-12 d-flex items-center">
        <h3><strong>Product Variants</strong></h3>
        <button className="btn btn-info btn-lg btn-circle" style={{ marginLeft: '30px' }}><i className="fa fa-plus"></i></button>
      </div>


      {variants.length > 0 && variants.map((variant, key) => {
        return (
          <VariantItem deleteFromProduct={deleteItem} key={key} variant={variant} openEditForm={(type, variant) => openEditFormHandler(type, variant)} />
        )
      })}
      <div className={`product-edit-pane ${isEdit ? 'opened' : ''}`}>
        <div style={{ padding: '30px' }}>
          <div className="d-flex items-center justify-between">
            <h3>Edit Product</h3>
            <button onClick={() => closeEditForm('productOnly')} className="btn btn-circle btn-lg btn-danger">&times;</button>
          </div>

          {typeOfAction === 'imageOnly' ? (

            <div className="add-images text-center" style={{ marginTop: '100px' }}>
              <div className='thumb'>
                {visualFiles.length > 0 && visualFiles.map((file, key) => {
                  return <div key={key} className='thumbInner'>
                    <img
                      src={file.preview}
                      attr='image'
                    />
                  </div>
                })}
              </div>
              <RcDropZone selectedFiles={files} setSelectedFiles={getSelectedFiles} />
              <button onClick={updateImages} className="btn btn-info btn-lg">Add Images</button>
            </div>) : <AddNewProduct
            updatedInfo={getUpdatedInfo}
            refetchProduct={refetchProduct}
            details={productDetails}
            actionType={typeOfAction}
            variant={singleVariant}
            updatedVariant={setUpdatedVariant}
            variants={variants} />}

          {loading && (
            <div
              className={"custom-loader"}
              style={{ height: "100%", left: 0, right: 0, top: 0, bottom: 0, zIndex: 9999999 }}
            >
              <div className="loaderDiv">
                <div className="loader"></div>
              </div>
            </div>
          )}

        </div>
      </div>


      {loading && (
        <div
          className={"custom-loader"}
          style={{ height: "100%", left: 0, right: 0, top: 0, bottom: 0, zIndex: 9999999 }}
        >
          <div className="loaderDiv">
            <div className="loader"></div>
          </div>
        </div>
      )}

    </div>
  )

}
export default ProductPreview;
