import React from "react";
import Item from "./item";
import Search from "./search";
import Footer from "./footer";
const SearchResult = ({ items, changeSearchValue, onSelect, selectedId, openAddNew, addType, manageType }) => {

  return (
    <div className="search-result">
      <Search changeValue={changeSearchValue} />
      <div className="search-items-content">
        <div className="scroller">
          <ul className="list-group">
            {items && items.length > 0 && items.map((item, key) => {
              return <Item item={item} key={key} onSelect={onSelect} id={selectedId} />;
            })}
          </ul>
        </div>
      </div>
      <Footer openAddNew={openAddNew} addType={addType} manageType={manageType} />
    </div>
  );
};

export default SearchResult;
