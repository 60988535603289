/* eslint-disable */
import React, {Component} from 'react';
import InlineMessage from './inlineMessage';
import 'rc-datetime-picker/dist/picker.min.css';
import moment from 'moment';
import {DatetimePickerTrigger} from 'rc-datetime-picker';
import Select from 'react-select';
import {userService} from '../_services'
import UserSearchBar from './userSearchBar'
import {helperFunctions} from '../_helpers'


class EditTask extends Component {
    constructor(props) {
        super(props);
        const {dispatch} = this.props;
        this.app = JSON.parse(localStorage.getItem('app'));


        this.state = {
            type: 'all',
            pageOffset: 0,
            errors: {},
            task: {
                locationid: this.app.locations[0].id,
                technicianid: '',
                subject: '',
                priority: '',
                relatedItemType: '',
                comments: '',
                status: '',
                selectedItem: '',
                selectedId: '',
                searchItem: ''
            },
            dueTime: '',
            selectedOption: '',
            relatedToItem: '',
            val: 'exist'

        };

    }


    taskChangeDuetime = (dueTime) => {

        this.setState({
            dueTime: dueTime
        })
    }

    taskChangeHandler = (e) => {
        this.setState({
            task: {
                ...this.state.task,
                [e.target.name]: e.target.value

            }

        });
    }


    updateTask = () => {
        const {task} = this.state;
        var taskJSON = '';
        if (this.props.predefinedRelatedItem != '') {
            taskJSON = {
                location: {
                    id: task.locationid
                },
                technician: {
                    id: task.technicianid != '' ? task.technicianid : this.props.assignedto
                },
                subject: task.subject != '' ? task.subject : this.props.title,
                priority: task.priority != '' ? task.priority : this.props.priority,
                relatedItemType: task.relatedItemType != '' ? task.relatedItemType : this.props.predefinedRelatedTo,
                comments: task.comments != '' ? task.comments : this.props.comments,
                status: task.status != '' ? task.status : this.props.status,
                dueTime: this.state.dueTime != '' ? moment(this.state.dueTime).format("YYYY-MM-DDTHH:mm:ss") : moment(this.props.dueTime).format("YYYY-MM-DDTHH:mm:ss"),
                relatedItem: {
                    id: task.selectedId != '' ? task.selectedId : this.props.selectedId
                }


            }
        } else {
            //alert('hello')
            taskJSON = {
                location: {
                    id: task.locationid
                },
                technician: {
                    id: task.technicianid != '' ? task.technicianid : this.props.assignedto
                },
                subject: task.subject != '' ? task.subject : this.props.title,
                priority: task.priority != '' ? task.priority : this.props.priority,
                relatedItemType: task.relatedItemType != '' ? task.relatedItemType : this.props.predefinedRelatedTo,
                comments: task.comments != '' ? task.comments : this.props.comments,
                status: task.status != '' ? task.status : this.props.status,
                dueTime: moment(this.state.dueTime).format("YYYY-MM-DDTHH:mm:ss"),


            }
        }


        const errors = this.validateTask(taskJSON);
        this.setState({errors});

        if (Object.keys(errors).length == 0) {

            this.props.updateTask(taskJSON);

            this.setState({
                errors: {},
                task: {
                    locationid: this.app.locations[0].id,
                    technicianid: '',
                    subject: '',
                    priority: '',
                    relatedItemType: '',
                    comments: '',
                    status: '',
                    selectedItem: '',
                    selectedId: ''

                },
                dueTime: '',
                selectedOption: ''
            })


        }
    }
    getDuration = (timeB, timeS) => {
        const sum = moment(timeB).diff(moment());
        const minutes = parseInt(moment.duration(sum).asMinutes());

        return minutes
    }
    validateTask = (data) => {

        const errors = {}
        if (!data.technician.id) errors.tech = 'Please select a technician';
        if (!data.subject) errors.subject = 'Please enter a subject';
        if (data.dueTime) {
            let duration = this.getDuration(this.state.dueTime, moment().format('YYYY-MM-DD'))
            if (duration < 0) errors.dueTime = 'Please enter valid date';
        }
        if (!data.priority) errors.priority = 'Please select a priority';
        if (!data.relatedItemType) errors.relatedItemType = 'Please select related type';
        if (!data.status) errors.status = 'Please select status';

        return errors;
    }


    clearSearchVal = () => {
        this.setState({
            ...this.state,
            selectedOption: '',
            optin: true,
            task: {
                ...this.state.task,
                searchItem: ''
            }
        })

    }

    onUserSelect = (user) => {
        const {task} = this.state
        if (task.relatedItemType == 'Customer') {
            this.setState({
                ...this.state,
                task: {
                    ...this.state.task,
                    selectedItem: user.fullName,
                    selectedId: user.id
                }
            })
        } else if (task.relatedItemType == 'Staff') {
            this.setState({
                ...this.state,
                task: {
                    ...this.state.task,
                    selectedItem: user.user.fullName,
                    selectedId: user.user.id
                }
            })
        } else if (task.relatedItemType == 'Service') {
            this.setState({
                ...this.state,
                task: {
                    ...this.state.task,
                    selectedItem: user.service.name,
                    selectedId: user.service.id
                }
            })
        }
    }


    taskRelatedToChangeHandler = (e) => {
        this.setState({
            task: {
                ...this.state.task,
                relatedItemType: e.target.value,

            },
            val: ''

        }, () => {
            this.setState({
                editing: true
            })
        });
    }


    render() {
        const {errors, selectedOption, task} = this.state;
        const {customers, searchVals, searchType} = this.props;
        return (
            <React.Fragment>

                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Title &nbsp; <span className="text-danger">*</span></label>

                            {this.props.predefinedRelatedTo == 'Exhausted Products' || this.props.predefinedRelatedTo == 'Unconfirmed Appointments' || this.props.predefinedRelatedTo == 'Exhausted Standings' || this.props.predefinedRelatedTo == 'Missed Calls' ?
                                <input
                                    maxLength="40"
                                    required
                                    className="form-control"
                                    type="text"
                                    name="subject"
                                    readOnly
                                    defaultValue={this.state.task.subject != '' ? this.state.task.subject : this.props.title}
                                />

                                : <React.Fragment><input

                                    required
                                    className="form-control"
                                    type="text"
                                    name="subject"
                                    value={this.state.task.subject ? this.state.task.subject : this.props.title}
                                    onChange={this.taskChangeHandler}/>
                                    <p className="text-danger error">{errors &&
                                        <InlineMessage text={errors.subject}/>}</p>
                                </React.Fragment>
                            }


                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Due Date:</label>
                            <DatetimePickerTrigger
                                moment={this.state.dueTime !== '' ? this.state.dueTime : this.props.dueTime}
                                onChange={this.taskChangeDuetime}>
                                <input
                                    type="text"
                                    value={this.state.dueTime != '' ? this.state.dueTime.format('YYYY-MM-DD') + " " + helperFunctions.formatDateTimeWithDay(this.state.dueTime, 'time') : this.props.dueTime ? moment(this.props.dueTime).format('YYYY-MM-DD') + ' ' + helperFunctions.formatDateTimeWithDay(moment(this.props.dueTime), 'time') : moment().format('YYYY-MM-DD') + ' ' + helperFunctions.formatDateTimeWithDay(moment(), 'time')}
                                    readOnly/>
                            </DatetimePickerTrigger>
                            <p className="text-danger error">{errors.dueTime &&
                                <InlineMessage text={errors.dueTime}/>}</p>

                        </div>
                    </div>


                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Related To &nbsp; <span className="text-danger">*</span></label>
                            <div className="flex-input">

                                {this.props.predefinedRelatedTo == 'Exhausted Products' || this.props.predefinedRelatedTo == 'Unconfirmed Appointments' || this.props.predefinedRelatedTo == 'Exhausted Standings' || this.props.predefinedRelatedTo == 'Missed Calls' ?
                                    <input className="form-control" type="text" readOnly
                                           value={this.props.predefinedRelatedTo}/>
                                    : <select
                                        value={this.state.task.relatedItemType != '' ? this.state.task.relatedItemType : this.props.predefinedRelatedTo}
                                        className="form-control"
                                        name="relatedItemType"
                                        onChange={this.taskRelatedToChangeHandler}
                                        required>
                                        <option>Select Type</option>
                                        {this.app && this.app.lookupGroup.taskItemTypes.map((a, key) => (
                                            <option key={key} value={a}>{a}</option>
                                        ))}

                                    </select>
                                }


                            </div>


                        </div>

                    </div>

                    <div className="col-md-6 mt-top">
                        {this.props.predefinedRelatedTo == 'Exhausted Products' || this.props.predefinedRelatedTo == 'Unconfirmed Appointments' || this.props.predefinedRelatedTo == 'Exhausted Standings' || this.props.predefinedRelatedTo == 'Missed Calls' ?
                            <input type="text" className="form-control" readOnly value="N/A"/>
                            : <UserSearchBar
                                id="userId"
                                onUserSelect={this.onUserSelect}
                                place={this.props.predefinedRelatedItem}
                                relatedToItem={this.state.task.relatedItem ? this.state.task.relatedItem : this.props.predefinedRelatedItem}
                                predefinedRelatedTo={this.state.task.relatedItemType ? this.state.task.relatedItemType : this.props.predefinedRelatedTo}
                            />
                        }


                    </div>
                </div>


                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Assigned To:</label>
                            <React.Fragment><select
                                value={this.props.assignedto != '' ? this.props.assignedto : this.state.task.technicianid}
                                onChange={this.taskChangeHandler}
                                name="technicianid"
                                className="form-control">
                                <option>Select Technician</option>
                                {this.app.locations[0].technicians.filter(t => t.active == true).map((t, key) => {

                                    return (
                                        <option key={key} value={t.id}>{t.user.names.nick}</option>
                                    )

                                })}
                            </select>
                                <p className="text-danger error">{errors && <InlineMessage text={errors.tech}/>}</p>
                            </React.Fragment>

                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Priority &nbsp; <span className="text-danger">*</span></label>
                            <select
                                className="form-control"
                                value={this.props.priority != '' ? this.props.priority : this.state.task.priority}
                                name="priority" className="form-control"
                                onChange={this.taskChangeHandler}
                                required>
                                <option>Select Priority</option>
                                {this.app && this.app.lookupGroup.taskPriorities.map((a, key) => (
                                    <option key={key} value={a}>{a}</option>
                                ))}
                            </select>
                            <p className="text-danger error">{errors && <InlineMessage text={errors.priority}/>}</p>
                        </div>

                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Comments</label>
                            <textarea
                                value={this.props.comments != '' ? this.props.comments : this.state.task.comments}
                                maxLength="1000"
                                className="form-control"
                                name="comments"
                                onChange={this.taskChangeHandler}

                            >
                                 </textarea>

                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Status &nbsp; <span className="text-danger">*</span></label>
                            <select
                                name="status"
                                value={this.props.status != '' ? this.props.status : this.state.task.status}
                                className="form-control"
                                onChange={this.taskChangeHandler}
                                required>
                                <option>Select Status</option>
                                {this.app && this.app.lookupGroup.taskStatuses.map((a, key) => (
                                    <option key={key} value={a}>{a}</option>
                                ))}
                            </select>
                            <p className="text-danger error">{errors && <InlineMessage text={errors.status}/>}</p>
                        </div>
                        <div className="form-group">
                            <label className="custom-info">Created
                                By: {this.props.createUser} ({helperFunctions.formatDateTimeWithDay(this.props.createTime, 'time_after')}) </label>

                            {this.props.lastUpdateUser != '' &&
                                <label className="custom-info">Updated
                                    By: {this.props.lastUpdateUser && this.findTechnicianName(this.props.lastUpdateUser)} ({this.props.updatedTime && helperFunctions.formatDateTimeWithDay(this.props.updatedTime, 'time_after')}) </label>
                            }
                        </div>
                    </div>

                </div>

                <div className="switcher-spacer"></div>


                <div className="row">
                    <div className="col-md-12">
                        <div className="buttons text-right">
                            <button onClick={this.updateTask} type="button" className="btn btn-custom-info">Update
                                Task
                            </button>
                        </div>
                    </div>
                </div>


            </React.Fragment>
        );
    }

    findTechnicianName(technicianId) {

        // eslint-disable-next-line
        let technician = this.app.locations[0].technicians.find(technician => technician.id == technicianId)
        return technician.user.names.nick;
    }
}


export default EditTask;
