/* eslint-disable */
import {Config} from '../config';

var config = Config()
import {authHeader} from '../_helpers';

const apiPath = Config().serverUrl;

export const appService = {
    checkVersion,
    getAppDetails,
    getAppMetaData,
    getSalesData,
    getNewCustomersData,
    getUpcomingBirthdaysData,
    getWinBoardData,
    getTodayAppointments,
    getAllServices,
    updateService,
    createService,
    createLocationService,
    getSingleServiceData,
    updateServiceTechnician,
    getAllServiceForAddingService,
    getAllTheServices,
    saveTechnicianToService,
    deleteTechService,
    getAllServicesForStaffdetails,
    confirmAppt,
    apptAddUpdateService,
    UpdateService,
    cancelServieFromAppt,
    checkServiceIn,
    UndocheckServiceIn,
    checkServiceOut,
    getSingleVtService,
    updateBookingTime,
    appointmentCheckIn,
    appointmentCheckOut,
    cancelAppt,
    saveProduct,
    updateProduct,
    deleteProduct,
    placeCheckOut,
    getCategories,
    getSingleOfferedService,
    getTechnicianServiceByLocationId
};

async function checkVersion() {
    const requestOptions = {
        method: 'GET',
        headers: {'Content-Type': 'application/json'},
    };
    const response = await fetch(apiPath + "meta/public-settings", requestOptions);
    const info = await response.json();
    return info;

}

function placeCheckOut(ids, group) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(group)
    };

    return fetch(apiPath + 'visits/group-checkout/' + ids, requestOptions).then(handleResponse);
}


function getAppMetaData(storeId) {
    const requestOptions = {
        method: 'GET',
        headers: {'Content-Type': 'application/json'},
    };

    return fetch(apiPath + 'businesses/' + storeId + '/shallow', requestOptions).then(resp => {
        if (!resp.ok) {
            return Promise.reject(resp.statusText);
        }
        return resp.json()
    }).then(app => {
        return app;
    })
}

function getAppDetails(storeId) {
    const requestOptions = {
        method: 'GET',
        headers: {'Content-Type': 'application/json'},
    };

    return fetch(apiPath + 'businesses/' + storeId, requestOptions)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response.statusText);
            }
            return response.json();
        })
        .then(app => {
            localStorage.setItem('app', JSON.stringify(app));
            return app;
        });
}

function getSalesData() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',
    };

    return fetch(apiPath + 'reports/sales?locId=' + JSON.parse(localStorage.getItem('app')).locations[0].id, requestOptions).then(response => {
        if (!response.ok) {
            if (response.status == 401 || response.status == 403) {
                //window.location = `/${window.location.href.split('/')[3]}/login`;
            }
            return Promise.reject(response.status);
        }
        return response.json();
    })
        .then(data => {
            localStorage.setItem('salesData', JSON.stringify(data));
            return data;
        });
}


function getNewCustomersData() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',
    };

    return fetch(apiPath + 'reports/new-guests?locId=' + JSON.parse(localStorage.getItem('app')).locations[0].id, requestOptions).then(handleResponse);
}


function getUpcomingBirthdaysData() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',
    };

    return fetch(apiPath + 'users?businessId=' + JSON.parse(localStorage.getItem('app')).id + '&type=bday_soon&page=0', requestOptions).then(handleResponse);
}


function getWinBoardData() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',
    };

    return fetch(apiPath + 'users?businessId=' + JSON.parse(localStorage.getItem('app')).id + '&type=bn_awhle&page=0', requestOptions).then(handleResponse);
}


function getTodayAppointments() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',
    };

    return fetch(apiPath + 'visits?locId=' + JSON.parse(localStorage.getItem('app')).locations[0].id + '&type=today', requestOptions).then(handleResponse);
}

function saveProduct(productJSON) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(productJSON)
    };

    return fetch(apiPath + 'visit-purchase-items/', requestOptions).then(handleResponse);
}

function updateProduct(productJSON, id) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(productJSON)
    };

    return fetch(apiPath + 'visit-purchase-items/' + id, requestOptions).then(handleResponse);
}

function deleteProduct(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify({})
    };

    return fetch(apiPath + 'visit-purchase-items/' + id, requestOptions).then(handleDeleteResponse);
}


function getAllServices(offset, ctgryId, searchVal) {
    //console.log(offset, ctgryId, searchVal);
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',
    };
    var queryParams = "locId=" + JSON.parse(localStorage.getItem('app')).locations[0].id + "&page=" + offset + "&pageCount=" + config.recordsPageSize;
    //alert(queryParams);
    if (ctgryId != undefined && ctgryId != '') {
        queryParams += "&ctgryId=" + ctgryId
    }

    if (searchVal != undefined && searchVal != '') {
        queryParams += "&searchVal=" + searchVal
    }
    return fetch(apiPath + 'location-services?' + queryParams, requestOptions).then(handleResponse);


}

function getAllTheServices(offset, ctgryId, searchVal) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',
    };
    var queryParams = "locId=" + JSON.parse(localStorage.getItem('app')).locations[0].id + "&page=" + offset;
    //alert(queryParams);
    if (ctgryId != undefined && ctgryId != '') {
        queryParams += "&ctgryId=" + ctgryId
    }
    if (searchVal != undefined && searchVal != '') {
        queryParams += "&searchVal=" + searchVal
    }
    return fetch(apiPath + 'location-services?' + queryParams, requestOptions).then(handleResponse);
}

function getCategories(page, pageCount) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',
    };
    return fetch(`${apiPath}offered-service-categories?bsnsId=${JSON.parse(localStorage.getItem('app')).id}&page=${page}&pageCount=${pageCount}`, requestOptions).then(handleResponse);
}

function getAllServicesForStaffdetails(offset, ctgryId, key) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include'
    };
    var queryParams = "locId=" + JSON.parse(localStorage.getItem('app')).locations[0].id + "&page=" + offset + "&pageCount=" + config.recordsPageSize;
    if (ctgryId != undefined && ctgryId !== '')
        queryParams += "&ctgryId=" + ctgryId
    if (key != undefined && key !== '') {
        queryParams += '&searchVal=' + key
    }
    return fetch(apiPath + 'location-services?' + queryParams, requestOptions).then(handleResponse);
}


function getSingleServiceData(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include'
    }
    return fetch(apiPath + 'location-services/' + id, requestOptions).then(handleResponse);

}

function getTechnicianServiceByLocationId(locId, srvcId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include'
    }
    return fetch(apiPath + 'technician-services?locId=' + locId + '&srvcId=' + srvcId, requestOptions).then(handleResponse);

}

function getSingleOfferedService(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include'
    }
    return fetch(apiPath + 'offered-services/' + id, requestOptions).then(handleResponse);

}

function updateService(serviceId, service) {
    //console.log(serviceId,service)
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(service)
    };

    return fetch(apiPath + 'location-services/' + serviceId, requestOptions).then(handleResponse);
}


function getAllServiceForAddingService(offset, searchVal) {

    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include'
    };
    var queryParams = "bsnsId=" + JSON.parse(localStorage.getItem('app')).id + '&locId=' + JSON.parse(localStorage.getItem('app')).locations[0].id + "&page=" + offset + "&pageCount=" + config.recordsPageSize;
    if (searchVal != undefined)
        queryParams += "&searchVal=" + searchVal

    return fetch(apiPath + 'offered-services?' + queryParams, requestOptions).then(handleResponse);
}

function deleteTechService(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include'
    };

    return fetch(apiPath + 'technician-services/' + id, requestOptions).then({});
}

function updateServiceTechnician(id, techJSON) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(techJSON)
    };

    return fetch(apiPath + 'technician-services/' + id, requestOptions).then(handleResponse);
}


function createService(service) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(service)
    };

    return fetch(apiPath + 'location-services', requestOptions).then(handleResponse);
}

function createLocationService(service) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(service)
    };

    return fetch(apiPath + 'location-services', requestOptions).then(handleResponse);
}


function saveTechnicianToService(singelService) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(singelService)
    };

    return fetch(apiPath + 'technician-services', requestOptions).then(handleResponse);
}


function confirmAppt(id, time) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(time)

    };
    return fetch(apiPath + 'visits/update/' + id + '/confirmation', requestOptions).then(handleResponse);
}


function apptAddUpdateService(service) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(service)

    };
    return fetch(apiPath + 'visit-technicians', requestOptions).then(handleResponse);
}

function getSingleVtService(id) {

    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include'

    };
    return fetch(apiPath + 'visits/' + id, requestOptions).then(handleResponse);
}


function UpdateService(id, service) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(service)

    };
    return fetch(apiPath + 'visit-technicians/update/' + id, requestOptions).then(handleResponse);

}

//check in service
function checkServiceIn(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify({})

    };
    return fetch(apiPath + 'visit-technicians/' + id, requestOptions).then(handleResponse);

}

//undo service check in
function UndocheckServiceIn(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify({})

    };
    return fetch(apiPath + 'visits/' + id + "/undo", requestOptions).then(handleResponse);

}


//cancel service from the appt
function cancelServieFromAppt(id, reason) {

    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(reason)
    };

    return fetch(apiPath + 'visit-technicians/' + id, requestOptions).then(handleResponse);

}

//checkout service
function checkServiceOut(id, amount) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(amount)
    };

    return fetch(apiPath + 'visit-technicians/' + id, requestOptions).then(handleResponse);

}

//checkout service
function updateBookingTime(id, time) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(time)
    };

    return fetch(apiPath + 'visits/update/' + id, requestOptions).then(handleResponse);

}

//appointment check in
function appointmentCheckIn(id, note) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(note)
    };

    return fetch(apiPath + 'visits/' + id, requestOptions).then(handleResponse);

}

//appointment check out
function appointmentCheckOut(id, payment) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(payment)
    };

    //console.log(JSON.stringify(payment))

    return fetch(apiPath + 'visits/' + id, requestOptions).then(handleResponse);

}

//appointment cancel
function cancelAppt(id, reason) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(reason)
    };

    return fetch(apiPath + 'visits/' + id, requestOptions).then(handleResponse);

}


function handleResponse(response) {
    if (!response.ok) {
        //console.log(response.status)
        if (response.status == 401 || response.status == 403) {
            localStorage.removeItem('user')
            window.location = `/${window.location.href.split('/')[3]}/login`;
        }
        if (response.status == 400 || response.status == 409 || response.status == 404 || response.status == 422 || response.status == 401) {
            throw response;
        }
        return Promise.reject(response);
    }
    if (response.length == 0)
        return {};
    return response.json();
}

function handleDeleteResponse(response) {

    if (!response.ok) {
        if (response.status == 401 || response.status == 403) {
            localStorage.removeItem('user')
            alert("app service" + JSON.stringify(window.location.href.split('/')))
            window.location = `/${window.location.href.split('/')[3]}/login`;
        }
        return Promise.reject(response);

    }
    return {"status": "ok"}

}
