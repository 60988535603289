/* eslint-disable */
import React from 'react';
import { inventoryService } from '../../_services'

const ProductRow = ({ product, name, productUpdateHandler, detailsView }) => {
  const updateProduct = async (item) => {
    const { variant } = item;
    const { product } = variant
    const productJSON = {
      brand: {
        id: product.brand.id,
      },
      name: product.name,
      details: product.details,
      usage: product.usage ? product.usage : '',
      ingredients: product.ingredients,

      rating: 5,
      visible: !product.visible,
    }
    const result = await inventoryService.updateProduct(product.id, productJSON);

    if (result.status) {
      Swal.fire({
        title: "Error",
        text: `${result.message}`,
        type: "error",
        showCancelButton: false,
        confirmButtonText: "Ok",
      });

    } else {
      productUpdateHandler && productUpdateHandler()
    }

  }
  return (
    <React.Fragment>

      {product.map((item, key) => (
        <tr key={key}>
          {key == 0 &&
            <td style={{ maxWidth: '300px' }} rowSpan={key === 0 && product.length > 1 ? product.length : null} >{key === 0 ? name : null}</td>
          }

          <td>{item.variant.visualAttributeValues[0].value}</td>
          <td>{item.variant.upc}</td>
          <td>{item.quantity}</td>
          <td>{item.variant.msrp.toFixed(2)}</td>
          <td>{item.salePrice.toFixed(2)}</td>
          {key == 0 &&
            <td align="center" rowSpan={key === 0 && product.length > 1 ? product.length : null}>
              <span onClick={() => updateProduct(item)} className={`label ${item.variant.product.visible === true ? 'label-info' : 'label-danger'}`}>
                <i className={`fa fa-thumbs-${item.variant.product.visible === true ? 'up' : 'down'}`}></i>
              </span>

            </td>
          }
          {key == 0 &&
            <td align="center" rowSpan={key === 0 && product.length > 1 ? product.length : null} style={{ maxWidth: '80px' }}>
              <div className="group-btn">

                <a onClick={() => detailsView(item.variant.product.id)} className="btn btn-xs btn-primary">
                  <i className="fa fa-edit"></i>
                </a>
                <button className="btn btn-xs btn-danger " style={{ marginLeft: '5px' }}>
                  <i className="fa fa-trash"></i>
                </button>

              </div>

            </td>
          }
        </tr>

      ))
      }
    </React.Fragment >

  )
}

export default ProductRow;
