import React from 'react';
import {Link} from "react-router-dom";

class MainSidebar extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {storeId, vanityId, MAN_DIREC, location, user, app} = this.props;
        return (
            <div className="navbar-default sidebar" role="navigation">
                <div className="sidebar-nav slimscrollsidebar">
                    <div className="sidebar-head custom-info-bg">
                        <h3><span className="fa-fw open-close"><i className="fa fa-bars"></i><i
                            className="fa fa-close visible-xs"></i></span> <span
                            className="hide-menu">Navigation</span></h3>
                    </div>
                    <ul className="nav" id="side-menu">
                        <li><Link to={`/${storeId}`}
                                  className={location == `/${vanityId}` ? "waves-effect active" : location == `/${vanityId}/` ? "waves-effect active" : "waves-effect"}><i
                            className="fa fa-home"></i><span className="hide-menu">Dashboard</span></Link>
                        </li>
                        <li><Link to={`/${storeId}/calendar-reservation`}
                                  className={location == `/${vanityId}/calendar-reservation` ? "active waves-effect" : "waves-effect"}>
                            <i className="fa fa-calendar" data-icon="v"></i>
                            <span className="hide-menu">Calendar</span>
                        </Link>
                        </li>
                        {user && user.technicianResponsibilities.length > 0 && user.technicianResponsibilities[0].customerAccessAllowed === true ?
                            <li><Link to={`/${storeId}/customers`}
                                      className={location == `/${vanityId}/customers` ? "active waves-effect" : "waves-effect"}>
                                <i className="fa fa-users" data-icon="v"></i>
                                <span className="hide-menu">Customers</span>
                            </Link>
                            </li> : user && <li><Link to={`/${storeId}/customers`}
                                                      className={location == `/${vanityId}/customers` ? "active waves-effect" : "waves-effect"}>
                            <i className="fa fa-users" data-icon="v"></i>
                            <span className="hide-menu">Customers</span>
                        </Link>
                        </li>

                        }

                        {MAN_DIREC === true &&
                            <li><Link to={`/${storeId}/services`}
                                      className={location == `/${vanityId}/services` ? "active waves-effect" : "waves-effect"}>
                                <i className="fa fa-gear" data-icon="v"></i>
                                <span className="hide-menu">Services</span></Link>
                            </li>
                        }
                        {MAN_DIREC === true &&
                            <li><Link to={`/${storeId}/staffs`}
                                      className={location == `/${vanityId}/manage-staffs` ? "active waves-effect" : "waves-effect"}>
                                <i className="fa fa-user" data-icon="v"></i>
                                <span className="hide-menu">Staff</span>
                            </Link>
                            </li>
                        }

                        {MAN_DIREC == true && app != null && app.locations[0].brands.length > 0 &&
                            <li><Link to={`/${storeId}/inventory`}
                                      className={location == `/${vanityId}/inventory` ? "active waves-effect" : "waves-effect"}>
                                <i className="fa fa-magic" data-icon="v"></i>
                                <span className="hide-menu">Inventory</span></Link>
                            </li>}

                        {MAN_DIREC == true &&
                            <li><Link to={`/${storeId}/pay-roll`}
                                      className={location == `/${vanityId}/pay-roll` ? "active waves-effect" : "waves-effect"}>
                                <i className="fa fa-calculator" data-icon="v"></i>
                                <span className="hide-menu">Payroll</span></Link>
                            </li>}
                        {user.role == 'MANAGER' &&
                            <li><Link to={`/${storeId}/closeout`}
                                      className={location == `/${vanityId}/closeout` ? "active waves-effect" : "waves-effect"}>
                                <i className="fa fa-hourglass-end" data-icon="v"></i>
                                <span className="hide-menu">Close Out</span>
                            </Link>
                            </li>}

                        <li style={{marginTop: '200px', marginLeft: '17px'}}>
                            <span style={{color: '#ddd', fontSize: '11px'}}>v2.5.7</span>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default MainSidebar;
