import React, {Component} from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

const MailShow = ({htmlElement}) => {

    return(
        <div data-backdrop="static" data-keyboard="false" className="modal fade" id="mail">
        <div className="modal-dialog modal-custom">
            <div className="modal-content">
                <div className="modal-header">
                    <button className="close" data-dismiss="modal">&times;</button>
                </div>
                <div className="modal-body">
                    <div className="modal-inner">

                        { ReactHtmlParser(htmlElement) }

                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default MailShow
