import { payrollConstants } from '../_constants';
 
export function payroll(state = {}, action) {

  switch (action.type) {
      case payrollConstants.GET_ALL_PAYROLL_REQUEST:
        return {
            payroll: [],
            payrollLoaded: false,
        }

       case payrollConstants.GET_ALL_PAYROLL_SUCCESS:

       return {
           payroll: action.payroll,
           payrollLoaded: true,
        }
       case payrollConstants.GET_ALL_PAYROLL_FAILURE:
       return {
           payroll: [],
           payrollLoaded: false,
        }
    
      default:
        return state
  }
}