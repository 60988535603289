export const appConstants = {
    GET_SERVICES_REQUEST: 'GET_SERVICES_REQUEST',
    GET_SERVICES_SUCCESS: 'GET_SERVICES_SUCCESS',
    GET_SERVICES_FAILURE: 'GET_SERVICES_FAILURE',
    RELOADER_SUCCESS: 'RELOADER_SUCCESS',

    GET_CATEGORY_INIT: 'GET_CATEGORY_INIT',
    GET_CATEGORY_SUCCESS: 'GET_CATEGORY_SUCCESS',
    GET_CATEGORY_FAILURE: 'GET_CATEGORY_FAILURE',
};
