import React from "react";
import "./note-popup.css";

const NotePopUp = ({isVisible, btnClick, close, handler, staffNotes, updatable, onUpdate, customerNotes}) => {
    return (
        <div
            style={{paddingTop: "20px"}}
            className={`hidden-note-popup overflow-hidden absolute z-100 w-80 h-full right-0 bg-white top-0 ${isVisible ? "opacity-100" : "opacity-100 w-5"
            } transition-all duration-500`}
        >
            <div
                className={`d-flex flex-column justify-around h-550 overflow-hidden note-container ${isVisible ? "opacity-100 w-80 margin-auto" : "opacity-100 w-0"
                }`}
            >
                <div className="customer-permanent-note">
                    <strong>
                        <i>Customer Note:</i>
                    </strong>
                    <p>
                        <i>{customerNotes}</i>
                    </p>
                </div>
                <hr/>
                <div className="appointment-note">
                    <strong>
                        <i>Appointment note:</i>
                    </strong>
                    <textarea
                        value={staffNotes}
                        onChange={handler}
                        name="customerNotes"
                        autoFocus
                        rows="6"
                        placeholder="Appintment note..."
                    ></textarea>
                </div>
                <div
                    className="sideButton"
                    style={{
                        position: "absolute",
                        left: "10px",
                        top: "50%",
                        margin: "-10px",
                    }}
                    onClick={btnClick}
                >
                    <i className="fa fa-chevron-right arrowCustomize"></i>
                </div>
                {updatable ? <div className="stumped-body">
                        <div className="stumped-action">
                            <button onClick={onUpdate} className="btn btn-default btn-custom-default">Update</button>
                        </div>
                    </div> :
                    <div><strong><em style={{color: '#999'}}>***This note will save when you will create the
                        appointment.***</em></strong></div>}
            </div>
        </div>
    );
};

export default NotePopUp;
