/* eslint-disable */
import {Config} from "../config";
import {authHeader} from "../_helpers";

var config = Config();
const apiPath = config.serverUrl;


//ERROR IS HERE
var app =
    JSON.parse(localStorage.getItem("app")) !== null && JSON.parse(localStorage.getItem("app")).locations
        ? JSON.parse(localStorage.getItem("app")).locations[0]
        : null;


var brands = [];
if (app) {
    app.brands.forEach((item) => {
        brands.push(item.id);
    });
}

export const inventoryService = {
    getAllProducts,
    searchProduct,
    getProductInfo,
    getProductActivities,
    addProductIntake,
    addProductAdjustment,
    addProductReturn,
    getViewSpecificProduct,
    getSingleInventory,
    searchNewProduct,
    addNewProduct,
    deleteProduct,
    finalizeActivity,
    getAllOpenIntake,
    deleteActivity,
    updateActivity,

    //visual attributes

    getVisualAttributes,

    //brands
    getBrands,
    createBrands,
    updateBrands,
    deleteBrands,

    //product
    addProduct,
    addProductVariant,
    addImages,
    removeImage,
    updateProduct,
    updateProductVariant,
    deleteVariant,
    getProductById

};

// function getAllProducts(offset,brnId,searchVal){
//     let brndIds = [1,2,3]
//     const requestOptions = {
//         method: 'GET',
//         headers: authHeader(),
//         credentials: 'include'
//     };
//     var queryParams = "locId=" + 101 + '&brndIds=' + brndIds + "&page=" + offset + "&pageCount=" + 20;
//
//     if(searchVal != undefined && searchVal != '')
//         queryParams += "&searchVal=" + searchVal
//     return fetch(apiPath+'inventory?'+queryParams, requestOptions).then(handleResponse);
//
// }

function getAllProducts(offset, brnId = brands, pageSize, searchVal) {
    console.log('awfwaef')
    const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json"},
    };

    let locID = JSON.parse(localStorage.getItem("app")).locations[0].id;
    var queryParams =
        "locId=" +
        locID +
        "&brndIds=" +
        brnId +
        "&page=" +
        offset +
        "&pageCount=" + pageSize;

    // eslint-disable-next-line
    if (searchVal != undefined && searchVal != "")
        // eslint-disable-next-line
        queryParams += "&searchVal=" + searchVal;
    return fetch(apiPath + "inventory?" + queryParams, requestOptions).then(
        handleResponse
    );
}

// function getAllProducts(offset, brnId, searchVal, locId, count) {
//
//     const requestOptions = {
//         method: 'GET',
//         headers: {'Content-Type': 'application/json'}
//     };
//
//
//     var queryParams = "locId=" + locId + '&brndIds=' + brnId + "&page=" + offset + "&pageCount=" + count;
//
//     // eslint-disable-next-line
//     if (searchVal != undefined && searchVal != '')
//         // eslint-disable-next-line
//         queryParams += "&searchVal=" + searchVal
//     return fetch(apiPath + 'inventory?' + queryParams, requestOptions).then(handleResponse);
// }

// function searchProduct(searchVal,pageCount){
//     const requestOptions = {
//         method: 'GET',
//         headers: authHeader(),
//         credentials: 'include',
//     };
//     var queryParams = `locId=${JSON.parse(localStorage.getItem('app')).locations[0].id}&searchVal=${searchVal}&page=0&pageCount=${pageCount}`
//     return fetch(apiPath+'inventory?'+queryParams, requestOptions).then(handleResponse);
// }
function searchProduct(searchVal, count, brandIds = brands) {
    const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json"},
    };
    let locID = JSON.parse(localStorage.getItem("app")).locations[0].id;

    var queryParams =
        "locId=" +
        locID +
        "&brndIds=" +
        brandIds +
        "&page=0" +
        "&pageCount=" +
        count;

    // eslint-disable-next-line
    if (searchVal != undefined && searchVal != "")
        // eslint-disable-next-line
        queryParams += "&searchVal=" + searchVal;
    return fetch(apiPath + "inventory/variant?" + queryParams, requestOptions).then(
        handleResponse
    );
}

function getProductInfo(id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
        credentials: "include",
    };
    var queryParams = `locId=${JSON.parse(localStorage.getItem("app")).locations[0].id
    }`;
    return fetch(
        `${apiPath}inventory/product/${id}?${queryParams}`,
        requestOptions
    ).then(handleResponse);
}

function getViewSpecificProduct(id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
        credentials: "include",
    };

    return fetch(apiPath + "inventory/variant/" + id, requestOptions).then(
        handleResponse
    );
}

function getSingleInventory(id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
        credentials: "include",
    };

    return fetch(apiPath + "inventory/" + id, requestOptions).then(
        handleResponse
    );
}

async function getProductById(id, bsnsId) {
    const requestOptions = {
        method: 'GET',
        header: authHeader(),
        credentials: 'include'
    }
    const response = await fetch(`${apiPath}products/${id}?bsnsId=${bsnsId}`, requestOptions);
    const product = await response.json();
    return product;
}

function getProductActivities(id, page, pageCount) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
        credentials: "include",
    };
    var queryParams = `itemId=${id}&page=${page}&pageCount=${pageCount}`;
    return fetch(
        `${apiPath}inventory-activities?${queryParams}`,
        requestOptions
    ).then(handleResponse);
}

function searchNewProduct(brands, searchVal, size) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
        credentials: "include",
    };

    return fetch(
        `${apiPath}products/search?brandIds=${brands}&searchVal=${searchVal}&page=0&pageCount=${size}`,
        requestOptions
    ).then(handleResponse);
}

function addNewProduct(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        credentials: "include",
        body: JSON.stringify(data),
    };
    return fetch(apiPath + "inventory", requestOptions).then(handleResponse);
}

async function updateProduct(id, data) {
    const requestOptions = {
        method: "PUT",
        headers: authHeader(),
        credentials: "include",
        body: JSON.stringify(data),
    };
    const response = await fetch(apiPath + "products/" + id, requestOptions);
    const result = await response.json();
    return result;
}

//updating product variant by id
async function updateProductVariant(data, id) {
    const requestOptions = {
        method: "PUT",
        headers: authHeader(),
        credentials: "include",
        body: JSON.stringify(data),
    };
    return fetch(apiPath + "product-variants/" + id + "/" + JSON.parse(localStorage.getItem("app")).locations[0].id, requestOptions).then(
        handleResponse
    );
}


//delete product variant by variant ID
async function deleteVariant(id) {
    const requestOptions = {
        method: "PUT",
        headers: authHeader(),
        credentials: "include",
        body: JSON.stringify(data),
    };
    const response = await fetch(apiPath + "products/" + id, requestOptions);
    const result = await response.json();
    return result;


}


function deleteProduct(id) {
    const requestOptions = {
        method: "DELETE",
        headers: authHeader(),
        credentials: "include",
    };
    return fetch(apiPath + "inventory/" + id, requestOptions).then(
        handleDeleteResponse
    );
}

function addProductIntake(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        credentials: "include",
        body: JSON.stringify(data),
    };
    return fetch(apiPath + "inventory-activities", requestOptions).then(
        handleResponse
    );
}

function finalizeActivity(id) {
    const requestOptions = {
        method: "PUT",
        headers: authHeader(),
        credentials: "include",
        body: JSON.stringify({}),
    };
    return fetch(
        apiPath + "inventory-activities/" + id + "/status",
        requestOptions
    ).then(handleResponse);
}

function getAllOpenIntake(offset, type) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
        credentials: "include",
    };
    var queryParams =
        "type=" +
        type +
        "&locId=" +
        JSON.parse(localStorage.getItem("app")).locations[0].id +
        "&page=" +
        offset +
        "&pageCount=" +
        5;

    return fetch(
        apiPath + "inventory-activities?" + queryParams,
        requestOptions
    ).then(handleResponse);
}

function deleteActivity(id) {
    const requestOptions = {
        method: "DELETE",
        headers: authHeader(),
        credentials: "include",
    };
    return fetch(apiPath + "inventory-activities/" + id, requestOptions).then(
        handleDeleteResponse
    );
}

function updateActivity(id, data) {
    const requestOptions = {
        method: "PUT",
        headers: authHeader(),
        credentials: "include",
        body: JSON.stringify(data),
    };
    return fetch(apiPath + "inventory-activities/" + id, requestOptions).then(
        handleResponse
    );
}

function addProductAdjustment() {
}

function addProductReturn() {
}


//visual attributes

async function getVisualAttributes() {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
        credentials: "include",
    };

    const response = await fetch(`${apiPath}product-variants/attributes`, requestOptions);
    const data = await response.json();
    return data;
}


//brands

function getBrands(value, page, size) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
        credentials: "include",
    };

    return fetch(
        `${apiPath}brands?sValue=${value}&page=${page}&pageCount=${size}`,
        requestOptions
    ).then(handleResponse);
}

function createBrands(value) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        credentials: "include",
        body: JSON.stringify({name: value})
    };

    return fetch(
        `${apiPath}brands`,
        requestOptions
    ).then(handleResponse);
}

function updateBrands(name, id) {
    const requestOptions = {
        method: "PUT",
        headers: authHeader(),
        credentials: "include",
        body: JSON.stringify({name}),
    };
    return fetch(apiPath + "brands/" + id, requestOptions).then(
        handleResponse
    );
}

function deleteBrands(ids) {
    const requestOptions = {
        method: "DELETE",
        headers: authHeader(),
        credentials: "include",
    };
    return fetch(apiPath + "brands?ids=" + ids, requestOptions).then(
        handleDeleteResponse
    );
}


//product
async function addProduct(product) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        credentials: "include",
        body: JSON.stringify(product)
    };

    const response = await fetch(`${apiPath}products`, requestOptions);
    const data = await response.json();
    return data;
}

async function addProductVariant(variant) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        credentials: "include",
        body: JSON.stringify(variant)
    };

    const response = await fetch(`${apiPath}product-variants/${JSON.parse(localStorage.getItem("app")).locations[0].id}`, requestOptions);
    const data = await response.json();
    return data;
}

async function addImages(images, id) {
    let data = new FormData();
    images.map(image => {
        data.append('imgs', image);
    });
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        credentials: "include",
        body: data
    };

    delete requestOptions.headers["Content-Type"];

    const result = await fetch(`${apiPath}product-variants/${id}/pictures`, requestOptions);
    return await result.json()
}

async function removeImage(variantId, imgIndex) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include',
    }
    const result = await fetch(`${apiPath}product-variants/${variantId}/${imgIndex}/picture`, requestOptions);
    return result;
}

function handleResponse(response) {
    if (!response.ok) {
        if (response.status == 401 || response.status == 403) {
            window.location = `/${window.location.href.split('/')[3]}/login`;
        } else {
            return Promise.reject(response);
        }
    } else {
        return response.json();
    }
}

function handleDeleteResponse(response) {
    if (!response.ok) {
        if (response.status == 401 || response.status == 403) {
            window.location = `/${window.location.href.split("/")[3]}/login`;
        }

        return Promise.reject(response);
    }
}
