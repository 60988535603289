/* eslint-disable */
import React from "react";
import Footer from "../footer/footer";
import taskImg from "../../task.png";
import AddTask from "../../_components/addTask";
import {connect} from "react-redux";
import {payRollActions} from "../../_actions/payroll.action";
import {helperFunctions} from "../../_helpers";
import moment from "moment";
import {appService, userService} from "../../_services";
import {payrollService} from "../../_services";

class PayRoll extends React.Component {
    constructor(props) {
        super(props);
        this.app = JSON.parse(localStorage.getItem("app"));
        this.state = {
            isEditable: false,
            taskSelected: {
                taskSelectedPayroll: "",
                taskSelectedItemId: "",
            },
            processRecords: "",
            pid: "",
            item: [],
            revenue: "",
            revAdjust: "",
            income: "",
            inAdjust: "",
            revReason: "",
            inReason: "",
            cashPaymentAmount: "",
            rId: "",
        };
        const {dispatch} = this.props;
        dispatch(payRollActions.getAllPayroll());
    }

    editMode() {
        this.setState({
            isEditable: true,
        });
    }

    savingData() {
        this.setState({
            isEditable: false,
        });
    }

    componentDidMount = async () => {
        const metaInfo = await appService.checkVersion();
        await helperFunctions.clearCache(metaInfo)
    }

    changeHandler = (e) => {
        const {name, value} = e.target;

        this.setState({
            ...this.state,
            [name]: value,
        });
    };

    taskSelectedPayroll = (id, num, name) => {
        this.setState({
            taskSelected: {
                ...this.state.taskSelected,
                taskSelectedPayroll: name + "( Batch: " + num + ")",
                taskSelectedItemId: id,
            },
        });
    };
    saveTask = (taskJSON) => {
        userService.saveTask(taskJSON).then(() => {
            $("#addingTask").modal("toggle");
            window.DataUpdateSuccess();
        });
    };

    initiateProcess = (id) => {
        payrollService.getSinglePayRoll(id).then((processRecords) => {
            this.setState({
                processRecords,
                pid: id,
            });
        });
        window.initiateCartTable();
    };

    clearState = () => {
        this.setState({
            processRecords: "",
        });
    };

    finalizeRecords = (id, type) => {
        Swal.fire({
            title: "Finalize Product",
            text: `Are you sure you want to finalize?`,
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes!",
            cancelButtonText: "Nope!",
        }).then((result) => {
            if (result.value) {
                payrollService
                    .finalizeRecords(id, type)
                    .then((res) => {
                        this.initiateProcess(this.state.pid);
                        if (type == "process") {
                            window.toast(
                                "success",
                                "All records are successfully finalized!"
                            );
                        } else {
                            window.toast("success", "The record is successfully finalized!");
                        }
                    })
                    .catch((e) => {
                        Swal.fire({
                            title: "Error",
                            text: `Already finalized!`,
                            type: "warning",
                            showCancelButton: false,
                            confirmButtonText: "Ok!",
                        });
                    });
            }
        });
    };

    handleCheckboxForAdd = (e) => {
        if (e.target.checked) {
            this.setState({
                item: this.state.item.concat(e.target.value),
            });
        } else {
            this.setState({
                item: this.state.item.filter((i) => i !== e.target.value),
            });
        }
    };

    finalizeSelected = () => {
        Swal.fire({
            title: "Warning",
            text: `Are you sure you want finalize?`,
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "Nope!",
        }).then((result) => {
            if (result.value) {
                if (this.state.item.length > 0) {
                    for (var i = 0; i < this.state.item.length; i++) {
                        payrollService
                            .finalizeRecords(this.state.item[i], "records")
                            .then((res) => {
                                this.initiateProcess(this.state.pid);
                            })
                            .catch((e) => {
                                e.json().then((err) => {
                                    Swal.fire({
                                        title: "Error",
                                        text: `${err.message}`,
                                        type: "error",
                                        showCancelButton: false,
                                        confirmButtonText: "Ok",
                                    });
                                });
                            });
                    }
                    this.setState({
                        item: [],
                    });
                    window.uncheckEveryone("#records-table input");
                    //window.toast('success', 'All records are successfully finalized!')
                }
            }
        });
    };

    openEditModal = (record) => {
        this.setState({
            revenue: (record.gross.amount * 1).toFixed(2),
            revAdjust: (record.gross.adjustment * 1).toFixed(2),
            income: (record.income.amount * 1).toFixed(2),
            inAdjust: (record.income.adjustment * 1).toFixed(2),
            revReason: "",
            inReason: "",
            cashPaymentAmount: (record.cashPaymentAmount * 1).toFixed(2),
            rId: record.id,
        });
    };

    updateRecords = () => {
        const recordJSON = {
            gross: {
                adjustment: (this.state.revAdjust * 1).toFixed(2),
                adjustmentReason: this.state.revReason,
            },
            income: {
                adjustment: (this.state.inAdjust * 1).toFixed(2),
                adjustmentReason: this.state.inReason,
            },
            cashPaymentAmount: this.state.cashPaymentAmount,
        };

        payrollService
            .updateRecords(this.state.rId, recordJSON)
            .then((res) => {
                $("#editProcess").modal("toggle");
                window.DataUpdateSuccess();
                this.initiateProcess(this.state.pid);
            })
            .catch((er) => {
                er.json().then((e) => {
                    console.log(e);
                    Swal.fire({
                        title: "Error",
                        text: `${e.message}`,
                        type: "error",
                        showCancelButton: false,
                        confirmButtonText: "Ok",
                    });
                });
            });
    };

    render() {
        const {payroll, payrollLoaded} = this.props;
        const {processRecords} = this.state;

        if (payroll) console.log(payroll);

        let cls = this.state.isEditable ? "editable" : "";

        return (
            <div id="page-wrapper">
                <div className="container-fluid">
                    <div className="row bg-title custom-header">
                        <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <h4 className="page-title">Payroll</h4>
                        </div>
                    </div>

                    <div className="row">
                        {payroll && payrollLoaded == false && (
                            <React.Fragment>
                                <div className="col-md-4 col-sm-6 col-xs-12 single-customer">
                                    <div className="loading"></div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12 single-customer">
                                    <div className="loading"></div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12 single-customer">
                                    <div className="loading"></div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12 single-customer">
                                    <div className="loading"></div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12 single-customer">
                                    <div className="loading"></div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12 single-customer">
                                    <div className="loading"></div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12 single-customer">
                                    <div className="loading"></div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12 single-customer">
                                    <div className="loading"></div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12 single-customer">
                                    <div className="loading"></div>
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                    <div className="row">
                        {payroll && payroll.length === 0 && payrollLoaded == true && (
                            <div className="text-center">
                                <h2 className="center">Records not found!!</h2>
                            </div>
                        )}
                    </div>

                    <div className="row">
                        {payroll &&
                            payroll.length > 0 &&
                            payrollLoaded == true &&
                            payroll.map((p, k) => {
                                return (
                                    <div key={k} className="col-md-4">
                                        <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <strong>{p.payCycle.compensationCategory}</strong>
                                            </div>
                                            <div className="panel-body">
                                                <ul className="payroll">
                                                    <li>
                                                        <i className="fa fa-list"></i>
                                                        <span className="payroll-tittle">Batch No</span>
                                                        <span className="colon">:</span>
                                                        <span className="value">{p.batchNum}</span>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-calendar"></i>
                                                        <span className="payroll-tittle">Period</span>
                                                        <span className="colon">:</span>
                                                        <span className="value">
                              {helperFunctions.getTimelineDate(
                                  moment(p.period.from),
                                  "fullDate"
                              )}{" "}
                                                            -{" "}
                                                            {helperFunctions.getTimelineDate(
                                                                moment(p.period.to),
                                                                "fullDate"
                                                            )}
                            </span>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-credit-card"></i>
                                                        <span className="payroll-tittle">Gross</span>
                                                        <span className="colon">:</span>
                                                        <span className="value">
                              ${(p.gross.amount * 1).toFixed(2)}
                            </span>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-adjust"></i>
                                                        <span className="payroll-tittle">Gross Ajd.</span>
                                                        <span className="colon">:</span>
                                                        <span className="value">
                              ${(p.gross.adjustment * 1).toFixed(2)}
                            </span>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check"></i>
                                                        <span className="payroll-tittle">Payout</span>
                                                        <span className="colon">:</span>
                                                        <span className="value">
                              ${(p.payout.amount * 1).toFixed(2)}
                            </span>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-adjust"></i>
                                                        <span className="payroll-tittle">Payout Adj.</span>
                                                        <span className="colon">:</span>
                                                        <span className="value">
                              ${(p.payout.adjustment * 1).toFixed(2)}
                            </span>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="panel-footer">
                                                <button
                                                    onClick={() =>
                                                        this.taskSelectedPayroll(
                                                            p.id,
                                                            p.batchNum,
                                                            p.payCycle.compensationCategory
                                                        )
                                                    }
                                                    title="Add Task"
                                                    className="btn pull-left btn-task"
                                                    data-toggle="modal"
                                                    data-target="#addingTask"
                                                >
                                                    <img width="15" src={taskImg} alt="task"/>
                                                </button>
                                                <button
                                                    onClick={() => this.initiateProcess(p.id)}
                                                    data-toggle="modal"
                                                    data-target="#process"
                                                    className="btn  btn-custom-info text-uppercase process-btn pull-right"
                                                >
                                                    Process
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>

                <Footer/>
                <div className="modal fade" id="process">
                    <div className="modal-dialog modal-lg-custom">
                        <div className="modal-content">
                            <div className="modal-header">
                                {this.state.item.length > 0 && (
                                    <div className="pull-left">
                                        <button
                                            className="deleteSelected"
                                            onClick={this.finalizeSelected}
                                        >
                                            Finalize Selected
                                        </button>
                                    </div>
                                )}
                                <button
                                    onClick={this.clearState}
                                    className="close pull-right"
                                    data-dismiss="modal"
                                >
                                    &times;
                                </button>
                            </div>
                            <div className="modal-body">
                                {!processRecords.records && (
                                    <div className="empty">
                                        <div className="single loading"></div>
                                        <div className="single loading"></div>
                                        <div className="single loading"></div>
                                        <div className="single loading"></div>
                                        <div className="single loading"></div>
                                        <div className="single loading"></div>
                                        <div className="single loading"></div>
                                        <div className="single loading"></div>
                                        <div className="single loading"></div>
                                        <div className="single loading"></div>
                                        <div className="single loading"></div>
                                        <div className="single loading"></div>
                                        <div className="single loading"></div>
                                        <div className="single loading"></div>
                                    </div>
                                )}
                                {processRecords.records && (
                                    <div className="expand-content">
                                        <div className="white-box no-boxshadow">
                                            <div className="table-responsive expand-table">
                                                <table className="table" id="records-table">
                                                    <thead>
                                                    <tr className="text-center">
                                                        <td colSpan="6">
                                                            <h2>
                                                                Batch -{" "}
                                                                {processRecords.batchNum &&
                                                                    processRecords.batchNum}
                                                            </h2>
                                                            <p className="text-center">
                                                                {helperFunctions.getTimelineDate(
                                                                    moment(processRecords.period.from),
                                                                    "fullDate"
                                                                )}{" "}
                                                                -{" "}
                                                                {helperFunctions.getTimelineDate(
                                                                    moment(processRecords.period.to),
                                                                    "fullDate"
                                                                )}
                                                            </p>
                                                            <h4>
                                  <span>
                                    <b>Type:</b>{" "}
                                      {processRecords.payCycle &&
                                          processRecords.payCycle.cycleType}
                                  </span>{" "}
                                                                &nbsp; &nbsp; &nbsp;
                                                                <span>
                                    {" "}
                                                                    <b>Status: </b>
                                                                    {processRecords.status == "Initiated" && (
                                                                        <button
                                                                            onClick={() =>
                                                                                this.finalizeRecords(
                                                                                    processRecords.id,
                                                                                    "process"
                                                                                )
                                                                            }
                                                                            className="btn btn-warning"
                                                                        >
                                                                            <i className="fa fa-thumbs-down"></i>{" "}
                                                                            Initiated
                                                                        </button>
                                                                    )}
                                                                    {processRecords.status != "Initiated" && (
                                                                        <button className="btn btn-success">
                                                                            <i className="fa fa-thumbs-up"></i>{" "}
                                                                            Accepted
                                                                        </button>
                                                                    )}
                                  </span>
                                                            </h4>

                                                            <br/>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <th>
                                                            <strong>Staff</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Revenue</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Adjustment</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Income</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Adjustment</strong>
                                                        </th>
                                                        <th className="text-right">
                                                            <strong>Action</strong>
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {processRecords.records.length == 0 && (
                                                        <tr>
                                                            <td colSpan="6">
                                                                <h3 className="text-center">
                                                                    Records not found!
                                                                </h3>
                                                            </td>
                                                        </tr>
                                                    )}
                                                    {processRecords.records.length > 0 &&
                                                        processRecords.records.map((r, k) => {
                                                            return (
                                                                <React.Fragment key={k}>
                                                                    <tr className={`trigger-content ${cls}`}>
                                                                        <td className="text-info">
                                                                            {r.status == "Initiated" && (
                                                                                <input
                                                                                    onChange={(event) =>
                                                                                        this.handleCheckboxForAdd(
                                                                                            event,
                                                                                            r.id
                                                                                        )
                                                                                    }
                                                                                    type="checkbox"
                                                                                    value={r.id}
                                                                                    id={`final-${r.id}`}
                                                                                    className="form-check-input"
                                                                                />
                                                                            )}

                                                                            <img
                                                                                className="img-circle"
                                                                                width="36"
                                                                                src={this.findTechnician(
                                                                                    r.technician.id,
                                                                                    "image"
                                                                                )}
                                                                                alt=""
                                                                            />
                                                                            <b>
                                                                                {" "}
                                                                                {this.findTechnician(
                                                                                    r.technician.id,
                                                                                    "nick"
                                                                                )}
                                                                            </b>
                                                                        </td>
                                                                        <td className="text-info">
                                                                            ${(r.gross.amount * 1).toFixed(2)}
                                                                        </td>
                                                                        <td className="text-info">
                                                                            ${(r.gross.adjustment * 1).toFixed(2)}
                                                                        </td>
                                                                        <td className="text-info">
                                                                            ${(r.income.amount * 1).toFixed(2)}
                                                                        </td>
                                                                        <td className="text-info">
                                                                            ${(r.income.adjustment * 1).toFixed(2)}
                                                                        </td>
                                                                        <td align="right" className="text-info">
                                                                            {r.status == "Initiated" && (
                                                                                <button
                                                                                    onClick={() =>
                                                                                        this.openEditModal(r)
                                                                                    }
                                                                                    data-toggle="modal"
                                                                                    data-target="#editProcess"
                                                                                    data-dismiss="modal"
                                                                                    className="btn btn-lg btn-outline btn-info btn-circle"
                                                                                >
                                                                                    <i className="fa fa-pencil"></i>
                                                                                </button>
                                                                            )}
                                                                            {r.status == "Initiated" && (
                                                                                <button
                                                                                    onClick={() =>
                                                                                        this.finalizeRecords(
                                                                                            r.id,
                                                                                            "records"
                                                                                        )
                                                                                    }
                                                                                    className="btn btn-lg btn-outline btn-info btn-circle"
                                                                                >
                                                                                    <i className="fa fa-thumbs-down"></i>
                                                                                </button>
                                                                            )}
                                                                            {r.status == "Finalized" && (
                                                                                <button
                                                                                    disabled
                                                                                    className="btn btn-lg btn-outline btn-success btn-circle"
                                                                                >
                                                                                    <i className="fa fa-thumbs-up"></i>
                                                                                </button>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                    <tr className="hidden-content">
                                                                        <td colSpan="6">
                                                                            <table
                                                                                className="table table-bordered hidden-tbl"
                                                                                width="300"
                                                                            >
                                                                                <thead>
                                                                                <tr>
                                                                                    <th>Amount</th>
                                                                                    <th>Commission</th>
                                                                                    <th>Income</th>
                                                                                </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                {r.items.length == 0 && (
                                                                                    <tr>
                                                                                        <td colSpan="3">
                                                                                            <h4 className="text-center">
                                                                                                Records Not Found!
                                                                                            </h4>
                                                                                        </td>
                                                                                    </tr>
                                                                                )}
                                                                                {r.items.length > 0 &&
                                                                                    r.items.map((i, k) => {
                                                                                        return (
                                                                                            <tr key={k}>
                                                                                                <td>
                                                                                                    $
                                                                                                    {(
                                                                                                        i.baseAmount * 1
                                                                                                    ).toFixed(2)}
                                                                                                </td>
                                                                                                <td>{i.itemName}</td>
                                                                                                <td>
                                                                                                    $
                                                                                                    {(
                                                                                                        i.derivedAmount * 1
                                                                                                    ).toFixed(2)}
                                                                                                </td>
                                                                                            </tr>
                                                                                        );
                                                                                    })}
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </React.Fragment>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="editProcess">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <h4 className="modal-title">Update Process</h4>
                            </div>
                            <div className="modal-body">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Revenue</label>
                                        <input
                                            readOnly
                                            type="text"
                                            name="revenue"
                                            onChange={this.changeHandler}
                                            value={this.state.revenue}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Adjust</label>
                                        <input
                                            type="text"
                                            name="revAdjust"
                                            onChange={this.changeHandler}
                                            value={this.state.revAdjust}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Adjustment Reason</label>
                                        <textarea
                                            rows="5"
                                            name="revReason"
                                            onChange={this.changeHandler}
                                            value={this.state.revReason}
                                        ></textarea>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Income</label>
                                        <input
                                            readOnly
                                            type="text"
                                            name="income"
                                            onChange={this.changeHandler}
                                            value={this.state.income}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Adjust</label>
                                        <input
                                            type="text"
                                            name="inAdjust"
                                            onChange={this.changeHandler}
                                            value={this.state.inAdjust}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Adjustment Reason</label>
                                        <textarea
                                            rows="5"
                                            name="inReason"
                                            onChange={this.changeHandler}
                                            value={this.state.inReason}
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Cash Payment Amount</label>
                                        <input
                                            type="text"
                                            name="cashPaymentAmount"
                                            onChange={this.changeHandler}
                                            value={this.state.cashPaymentAmount}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="modal-footer">
                                <div className="buttons">
                                    <button
                                        onClick={this.updateRecords}
                                        type="button"
                                        className="btn btn-custom-info"
                                    >
                                        Update
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className="modal fade"
                    id="addingTask"
                    tabIndex="-1"
                    role="dialog"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <h4 className="modal-title">Add Task</h4>
                            </div>
                            <div className="modal-body">
                                <AddTask
                                    statas="disabled"
                                    predefinedRelatedItem={
                                        this.state.taskSelected.taskSelectedPayroll
                                    }
                                    predefinedRelatedId={
                                        this.state.taskSelected.taskSelectedItemId
                                    }
                                    predefinedRelatedTo="Payroll"
                                    mode={this.state.mode}
                                    saveTask={this.saveTask}
                                />
                            </div>

                            <div className="modal-footer">
                                <div className="buttons">
                                    <button type="button" className="btn btn-success">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    findTechnician(technicianId, query) {
        // eslint-disable-next-line
        let technician = this.app.locations[0].technicians.find(
            (technician) => technician.id == technicianId
        );

        if (technician)
            if (query == "image") {
                return technician.user.imageUrl;
            }
        if (query == "nick") {
            return technician.user.names.nick;
        } else {
            return technician.user.fullName;
        }

        return "";
    }
}

function mapStateToProps(state) {
    const {payroll, payrollLoaded} = state.payroll;

    return {
        payroll,
        payrollLoaded,
    };
}

const connectedPayRollPage = connect(mapStateToProps)(PayRoll);
export {connectedPayRollPage as PayRoll};
