import { inventoryConstants } from '../_constants';
import { inventoryService } from '../_services';

export const inventoryActions = {
  getAllProducts,
  getBrands
};

function getAllProducts(page, brndId, pageSize, searchVal) {

  return dispatch => {
    dispatch(loading())
    inventoryService.getAllProducts(page, brndId, pageSize, searchVal)
      .then(
        inventory => {
          dispatch(success(inventory));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };
  function success(inventory) { return { type: inventoryConstants.GET_ALL_PRODUCTS_SUCCESS, inventory } }
  function failure(error) { return { type: inventoryConstants.GET_ALL_PRODUCTS_FAILURE, error } }
  function loading() { return { type: inventoryConstants.GET_ALL_PRODUCTS_REQUEST } }
}

function getBrands({ searchValue, pageCount, pageSize }) {
  return dispatch => {
    dispatch(loading());
    inventoryService.getBrands(searchValue, pageCount, pageSize).then(response => {
      dispatch(success(response));
    }).catch(e => {
      console.log(e)
      e.json().then(error => {
        dispatch(failure(error))
      })
    })
  }

  function loading() { return { type: inventoryConstants.BRANDS_LOADING } }
  function success(brands) { return { type: inventoryConstants.BRANDS_FETCHED, brands } }
  function failure(error) { return { type: inventoryConstants.BRANDS_FAILURE, error } }



}
