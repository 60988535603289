import React from 'react';
import {helperFunctions} from "../../_helpers";


class WaitLists extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {waitList, next, prev, cls, remove, findTechnician, waitlsts, edit, deletes} = this.props;
        return (
            <div className={"activities " + cls}>
                <button onClick={remove} className="close-activities"><i
                    className="fa fa-angle-right"></i></button>
                <div className="fileters">
                    <div className="title">
                        <h3><strong>Wait Lists</strong></h3>
                    </div>
                    <div className="see-all">
                        <ul className="pager">
                            <li><a onClick={prev}><i className="fa fa-angle-left"></i></a>
                            </li>
                            <li><a onClick={next}><i className="fa fa-angle-right"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="activities-body">
                    {waitlsts == true && waitList && waitList.length == 0 &&
                        <h3 className="text-center">Records not found </h3>
                    }
                    <div className="activities-scroll">
                        {waitlsts == true && waitList.length > 0 &&
                            <div className="steamline">

                                {waitlsts == true && waitList && waitList.length == 0 &&
                                    <h3 className="text-center">Records not found </h3>
                                }
                                {waitList && waitList.length > 0 && waitList.map((a, key) => {
                                    return <div key={key} className="sl-item">
                                        <div className="sl-left"><img
                                            width="36"
                                            src={a.client.imageUrl}
                                            alt="user" className="img-circle"/></div>
                                        <div className="sl-right">
                                            <div className="m-l-40">

                                                <p><strong>{a.client.fullName}</strong> &nbsp;
                                                    <span>{a.readableCreateTime}</span>
                                                </p>
                                                <b><em><i
                                                    className="fa fa-phone"></i> {helperFunctions.formatMobilePhone(a.client.mobileNumber)}
                                                    <br/>
                                                    <i className="fa fa-envelope-o"></i>{a.client.emailAddress}<br/>
                                                </em></b>

                                                {a.services.length > 0 && a.services.map((s, k) => {
                                                    return <React.Fragment key={k}>

                                                        <b>{s.service.name}</b> with <b>{s.technicians.length > 0 ? findTechnician(s.technicians[0].id, 'nick') : 'Any Stylist'}
                                                        {s.technicians.length > 1 &&
                                                            <React.Fragment><a
                                                                style={{cursor: 'pointer'}}
                                                                onClick={window.initiatePopover()}
                                                                tabIndex="0"
                                                                data-toggle="popover"
                                                                data-popover-content="#a1"
                                                                data-placement="right"> {s.technicians.length} Others</a>
                                                                <div id="a1" className="hidden">
                                                                    <div
                                                                        className="popover-heading">Technician
                                                                        Lists
                                                                    </div>

                                                                    <div
                                                                        className="popover-body">
                                                                        <ul>
                                                                            {s.technicians.map((t, k) => {
                                                                                return <li
                                                                                    key={k}>{this.findTechnician(t.id, 'nick')}</li>
                                                                            })}
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </React.Fragment>

                                                        }</b>
                                                        <br/>


                                                        <p className="text-italic">{helperFunctions.formatDateTimeWithDay(a.periods[k].from, 'latest')} - {helperFunctions.formatDateTimeWithDay(a.periods[k].to, 'latest')}</p>
                                                    </React.Fragment>
                                                })

                                                }
                                            </div>
                                            <p className="pb35">
                                    <span className="pull-right">
                                      <button data-dismiss="modal" data-target="#editWaitList"
                                              data-toggle="modal"
                                              onClick={() => edit(a, a.client.id)}
                                              className="btn m-t-10 m-r-5 btn-custom-info">Edit</button>

                                      <button
                                          onClick={() => deletes(a.id)}
                                          data-toggle="modal"
                                          className="btn m-t-10 m-r-5 btn-danger">Delete</button>
                                    </span>
                                            </p>
                                        </div>
                                    </div>
                                })}
                            </div>
                        }
                    </div>
                </div>


            </div>
        )
    }
}

export default WaitLists;
