/* eslint-disable */
import React, {Component} from "react";
import {connect} from "react-redux";
import {appService} from "../../_services";
import Footer from "../footer/footer";
import "./dashboard.css";
import {helperFunctions} from "../../_helpers";
import {Link} from "react-router-dom";
import {Config} from "../../config";
import CheckOut from "../../_components/checkOut";
import AddTask from "../../_components/addTask";
import EditTask from "../../_components/editTask";
import CheckIn from "../../_components/checkIn";
import EditCart from "../../_components/editCart";
import GroupCart from "../../_components/groupCart";
import ViewDetails from "../../_components/viewDetails";
import appt from "../../icons/appt.png";
import emptyappt from "../../icons/emptyappt.png";
import emptypr from "../../icons/emptypr.png";
import other from "../../icons/other.png";
import pr from "../../icons/pr.png";
import serviceicon from "../../icons/service.png";
import systemIcon from "../../icons/system.png";

import {outgoingAction} from "../../_actions";
import {userService} from "../../_services";
import "rc-datetime-picker/dist/picker.min.css";
import moment from "moment";
import Odometer from "react-odometerjs";
import "odometer/themes/odometer-theme-default.css";
import {communicationAction} from "../../_actions/communication.actions";

window.odometerOptions = {
    formatFunction: function (value) {
        return value.toFixed(2);
    },
};

var config = Config();

class DashboardPage extends Component {
    constructor(props) {
        super(props);
        this.app = JSON.parse(localStorage.getItem("app"));
        this.loggedUser = JSON.parse(localStorage.getItem("user"));
        this.storeId = this.app.vanityId;
        this.state = {
            locationid: this.app.locations && this.app.locations[0].id,
            id: "",
            pageOffset: 0,
            salesData: [],
            guestsData: [],
            birthdaysData: [],
            winBoardData: [],
            appointmentsTodayData: [],
            menuState: false,
            dueTime: moment(),

            viewDetails: "",
            taskSelected: {
                taskSelectedClient: "",
                taskSelectedItemId: "",
            },

            task: {
                subject: "",
                priority: "",
                relatedItemType: "",
                relatedItem: "",
                comments: "",
                status: "",
                dueTime: "",
                technician: "",
                selectedId: "",
                createUser: "",
                createdTime: "",
                lastUpdateUser: "",
                updatedTime: "",
            },
            searchVals: "",
            searchType: "",
            optin: false,
            selectedOption: "",
            searchOptionNull: false,
            searchUpdateVals: "",
            editApptDetails: "",
            staffNotes: "",
            loading: false,
            cancelReason: "",
            predefinedRelatedTo: "",
            status: "",
            mode: "",
            firstBtn: "actve",
            secondBtn: "",
            counting: "bookings",
            key: 1,
            cartCollections: [],
            totalCharge: 0,

            cashPaidAmount: 0,
            checkPaidAmount: 0,
            creditPaidAmount: 0,
            discountDeductionAmount: 0,
            giftCardPaidAmount: 0,
            productPurchaseAmount: 0,
            rewardDeductionAmount: 0,
            serviceChargeAmount: 0,
            taxAmount: 0,
            tipAmount: 0,
            cartAdded: "",
            userAccess: "",
            tasks: "",
            place: "",
            checkOut: false,
            editProccess: false,
            notify: "",
            keyId: "",
            dataCame: false,
            categories: [],
        };

        this.initializeCharts = this.initializeCharts.bind(this);
        //fetch category
        this.getCategories();
    }

    getCategories() {
        appService
            .getCategories(0, 200)
            .then((categories) => {
                this.setState({categories});
                //this.initializeServices()
            })
            .catch((e) => {
                console.log(e);
            });
    }

    componentDidMount = async () => {
        const metaInfo = await appService.checkVersion();
        await helperFunctions.clearCache(metaInfo)
        setTimeout(this.initializeCharts, 1000);
        setTimeout(window.initiateFootable, 1000);

        let thisObj = this;
        appService.getUpcomingBirthdaysData().then((data) => {
            thisObj.setState({birthdaysData: data});
        });

        appService.getWinBoardData().then((data) => {
            thisObj.setState({winBoardData: data});
        });

        appService.getTodayAppointments().then((data) => {
            thisObj.setState({appointmentsTodayData: data});
        });

        window.initializeDashboardMissedResaon();
        window.initiateCartTable();
        window.initiateCollapse();
        window.initiateSummaryCheckout();
        this.getAllTasks(this.state.pageOffset);
    }

    getAllTasks = (offset) => {
        userService.getAllTask(offset).then((res) => {
            this.setState({
                tasks: res,
                loading: false,
            });
        });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.props.reloadData != undefined &&
            this.props.reloadData.bookedTime != undefined
        ) {
            if (Object.keys(this.props.reloadData).length > 0) {
                this.reloadTodayScreen(this.props.reloadData);
            }
        }
    }

    initializeCharts() {
        window.initializeTabs();
        appService.getSalesData().then((data) => {
            this.setState({salesData: data.bookingsToday});
            appService.getNewCustomersData().then((data) => {
                var salesData = JSON.parse(localStorage.getItem("salesData")).today;
                window.chartInit(salesData, data);
                this.setState({guestsData: data});
            });
        });
    }

    getTodaySalesData(counting) {
        if (counting == "bookings") {
            var salesData = JSON.parse(
                localStorage.getItem("salesData")
            ).bookingsToday;
        } else {
            var salesData = JSON.parse(localStorage.getItem("salesData")).today;
        }

        //window.chartInit(salesData,this.state.guestsData);
        this.setState({
            salesData: salesData,
        });
    }

    getThisWeekSalesData(counting) {
        if (counting == "bookings") {
            var salesData = JSON.parse(
                localStorage.getItem("salesData")
            ).bookingsThisWeek;
            this.setState({salesData: salesData});
        } else {
            var salesData = JSON.parse(localStorage.getItem("salesData")).thisWeek;
            this.setState({salesData: salesData});
        }

        //window.chartInit(salesData,this.state.guestsData);
    }

    getlast30DaysSalesData(counting) {
        if (counting == "bookings") {
            var salesData = JSON.parse(
                localStorage.getItem("salesData")
            ).bookingsLast30Days;
        } else {
            var salesData = JSON.parse(localStorage.getItem("salesData")).thisMonth;
        }

        //window.chartInit(salesData,this.state.guestsData);
        this.setState({
            salesData: salesData,
        });
    }

    getlast90DaysSalesData(counting) {
        if (counting == "bookings") {
            var salesData = JSON.parse(
                localStorage.getItem("salesData")
            ).bookingsLast90Days;
        } else {
            var salesData = JSON.parse(localStorage.getItem("salesData")).last90Days;
        }

        //window.chartInit(salesData,this.state.guestsData);
        this.setState({salesData: salesData});
    }

    //context menu
    contextMenus(e, cls, a, k) {
        e.preventDefault();
        this.setState(
            {
                menuState: true,
                viewDetails: a,
                keyId: k,
            },
            () => {
                let element = document.getElementById("contextMenu");
                element.classList.add(cls);
                element.style.left =
                    event.pageX >= window.innerWidth - 200
                        ? event.pageX - 210 + "px"
                        : event.pageX + "px";
                element.style.top =
                    event.pageY >= window.innerHeight - 200
                        ? event.pageY - 200 + "px"
                        : event.pageY + "px";
            }
        );
    }

    closeContextMenu = () => {
        this.setState({
            ...this.state,
            menuState: false,
            viewDetails: "",
            keyId: "",
        });
    };

    handleChange = (e) => {
        const {name, value} = e.target;
        this.setState({
            ...this.state,
            [name]: value,
        });
    };

    openModal = () => {
        var collection = "";
        collection = this.state.viewDetails;

        if (collection) {
            const collectionCheck = this.state.cartCollections.find(
                (v, i) => v.id == collection.id
            );
            if (collectionCheck) {
                this.setState({
                    cartAdded: "exist",
                });
            } else {
                const cartCollections = this.state.cartCollections;
                cartCollections.push(collection);

                this.setState({
                    cartCollections: cartCollections,
                    totalCharge:
                        this.state.totalCharge * 1 + collection.totalChargeAmount * 1,
                    serviceChargeAmount:
                        this.state.serviceChargeAmount * 1 +
                        collection.serviceChargeAmount * 1,
                    cashPaidAmount:
                        this.state.cashPaidAmount * 1 + collection.cashPaidAmount * 1,
                    checkPaidAmount:
                        this.state.checkPaidAmount * 1 + collection.checkPaidAmount * 1,
                    creditPaidAmount:
                        this.state.creditPaidAmount * 1 + collection.creditPaidAmount * 1,
                    discountDeductionAmount:
                        this.state.discountDeductionAmount * 1 +
                        collection.discountDeductionAmount * 1,
                    giftCardPaidAmount:
                        this.state.giftCardPaidAmount * 1 +
                        collection.giftCardPaidAmount * 1,
                    productPurchaseAmount:
                        this.state.productPurchaseAmount * 1 +
                        collection.productPurchaseAmount * 1,
                    rewardDeductionAmount:
                        this.state.rewardDeductionAmount * 1 +
                        collection.rewardDeductionAmount * 1,
                    taxAmount: this.state.taxAmount * 1 + collection.taxAmount * 1,
                    tipAmount: this.state.tipAmount * 1 + collection.tipAmount * 1,
                    cartAdded: "added",
                });
            }
        } else {
            this.setState({
                cartCollections: this.state.cartCollections,
            });
        }

        window.initiateCartTable();
        window.initiateCollapse();
        window.initiateSummaryCheckout();
    };

    openEditModal = () => {
        this.setState({
            ...this.state,
            loading: true,
            menuState: false,
            checkOut: false,
        });
        appService.getSingleVtService(this.state.viewDetails.id).then((res) => {
            this.setState({
                ...this.state,
                viewDetails: res,
                editApptDetails: res,
                loading: false,
            });
        });

        $("#editModal").modal("show");
        window.initiateSummaryCheckout();
    };
    apptViewDetails = () => {
        this.setState({
            ...this.state,
            loading: true,
            menuState: false,
        });
        appService.getSingleVtService(this.state.viewDetails.id).then((res) => {
            this.setState({
                ...this.state,
                viewDetails: res,
                editApptDetails: res,
                loading: false,
            });
        });
    };

    openCheckinModal = () => {
        this.setState({
            ...this.state,
            loading: true,
            menuState: false,
        });
        appService.getSingleVtService(this.state.viewDetails.id).then((res) => {
            this.setState({
                ...this.state,
                viewDetails: res,
                editApptDetails: res,
                loading: false,
            });
        });
    };

    openCheckOutModal = () => {
        this.setState({
            ...this.state,
            loading: true,
            menuState: false,
            checkOut: true,
        });
        appService.getSingleVtService(this.state.viewDetails.id).then((res) => {
            this.setState({
                ...this.state,
                editApptDetails: res,
                loading: false,
            });
        });
    };
    resetStateWithModalClose = () => {
        this.setState({
            ...this.state,
            editApptDetails: "",
        });
    };

    closeCheckOutModal = () => {
        this.setState({
            ...this.state,
            editApptDetails: "",
        });
    };
    closeAddTaskModal = () => {
        this.setState({
            ...this.state,
            editApptDetails: "",
            status: "",
            taskSelected: {
                taskSelectedClient: "",
                taskSelectedItemId: "",
            },
            predefinedRelatedTo: "",
        });
    };

    proccessCheckOut = () => {
        //do checkout please
    };

    clearEditApptModal = () => {
        if (this.state.cartCollections.length > 0) {
            this.openModal(this.state.editApptDetails);
        }
        this.setState({
            ...this.state,
            editApptDetails: "",
        });
    };

    taskSelectedItem = (details) => {
        this.setState({
            predefinedRelatedTo: "Appointment",
            status: "disabled",
            mode: "",
            menuState: false,
            taskSelected: {
                ...this.state.taskSelected,
                taskSelectedClient:
                    details.client.names.first +
                    " " +
                    (details.client.names.last && details.client.names.last.charAt(0)),
                taskSelectedItemId: details.id,
            },
        });
    };

    openEditTaskModal = (id) => {
        userService.getSpecificTask(id).then((res) => {

            //alert(res.dueTime)
            this.setState(
                {
                    ...this.state,
                    id: res.id,
                    mode: "edit",
                    menuState: false,
                    task: {
                        ...this.state.task,
                        subject: res.subject,
                        priority: res.priority,
                        relatedItemType: res.relatedItemType,
                        relatedItem:
                            res.relatedItemType == "Staff"
                                ? res.relatedItem.user.fullName
                                : res.relatedItemType == "Customer"
                                    ? res.relatedItem.fullName
                                    : res.relatedItemType == "Appointment"
                                        ? res.relatedItem.client.fullName
                                        : res.relatedItemType == "Service"
                                            ? res.relatedItem.name
                                            : res.relatedItemType == "Product"
                                                ? res.relatedItem.variant.product.name
                                                : "",
                        comments: res.comments,
                        status: res.status,
                        dueTime: res.dueTime ? moment(res.dueTime) : "",
                        technician: res.technician ? res.technician.user.id : "",
                        createUser: res.createUser && res.createUser.names.nick,
                        createdTime: res.createdTime,
                        lastUpdateUser: res.lastUpdateUser ? res.lastUpdateUser.id : "",
                        updatedTime: res.updatedTime ? res.updatedTime : "",
                        selectedId: res.relatedItem,
                    },
                },
                () => {
                    this.setState({
                        editProccess: true,
                    });
                }
            );
        });
    };
    handleSelectTask = (selectedOption) => {
        this.setState({
            selectedOption,
            task: {
                ...this.state.task,
                selectedId: selectedOption.value,
            },
        });
    };

    searchFromUpdateTask = () => {
        if (this.state.task.relatedItemType == "Customer") {
            //GET /visits?type=all&locId=1&page=0&pageCount=9&searchVal=Jewel
            this.searchItemFromUpdate(
                "all",
                0,
                this.state.task.searchItem,
                "customer"
            );
        } else if (this.state.task.relatedItemType == "Staff") {
            this.searchItemFromUpdate("", 0, this.state.task.searchItem, "staff");
        } else if (this.state.task.relatedItemType == "Product") {
            this.searchItemFromUpdate("", 0, this.state.task.searchItem, "staff");
        } else if (this.state.task.relatedItemType == "Service") {
            this.searchItemFromUpdate("", 0, this.state.task.searchItem, "service");
        }
        this.setState({
            ...this.state,
            optin: false,
        });
    };

    searchItem = (type, page, searchVal, query) => {
        if (type != "" && query == "customer") {
            userService.getAllCustomers(type, page, searchVal).then((res) => {
                this.setState({
                    ...this.state,
                    searchVals: res,
                    searchType: "customer",
                });
            });
        } else if (type == "" && query == "staff") {
            userService.getAllTechnicians(0, "", searchVal).then((res) => {
                this.setState({
                    ...this.state,
                    searchVals: res,
                    searchType: "staff",
                });
            });
        }
    };

    searchItemFromUpdate = (type, page, searchVal, query) => {
        this.setState({
            ...this.state,
            searchOptionNull: true,
        });

        if (type != "" && query == "customer") {
            userService.getAllCustomers(type, page, searchVal).then((res) => {
                this.setState({
                    ...this.state,
                    searchUpdateVals: res,
                    searchType: "customer",
                });
            });
        } else if (type == "" && query == "staff") {
            userService.getAllTechnicians(0, "", searchVal).then((res) => {
                this.setState({
                    ...this.state,
                    searchUpdateVals: res,
                    searchType: "staff",
                });
            });
        } else if (type == "" && query == "service") {
            appService.getAllServices(0, "", searchVal).then((res) => {
                this.setState({
                    ...this.state,
                    searchUpdateVals: res,
                    searchType: "service",
                });
            });
        }
    };

    saveTask = (taskJSON) => {
        const {dispatch} = this.props;

        userService
            .saveTask(taskJSON)
            .then(() => {
                $("#addingTask").modal("toggle");
                this.getAllTasks(this.state.pageOffset);
            })
            .catch((error) => {
                error.json().then((e) => {
                    Swal.fire({
                        title: "Error",
                        text: `${e.message}`,
                        type: "warning",
                        showCancelButton: false,
                        confirmButtonText: "Ok!",
                    });
                });
            });
    };
    updateTask = (taskJSON) => {
        const {dispatch} = this.props;
        userService
            .updateTask(this.state.id, taskJSON)
            .then((res) => {
                this.getAllTasks(this.state.pageOffset);
                window.DataUpdateSuccess();
                $("#editTask").modal("toggle");
                this.setState({
                    task: {
                        ...this.state.task,
                        subject: "",
                        priority: "",
                        relatedItemType: "",
                        relatedItem: "",
                        comments: "",
                        status: "",
                        dueTime: "",
                        technician: "",
                        createUser: "",
                        createdTime: "",
                        lastUpdateUser: "",
                        updatedTime: "",
                        selectedId: "",
                    },
                });
            })
            .catch((e) => {
                Swal.fire({
                    title: "Error",
                    text: `${e.message}`,
                    type: "warning",
                    showCancelButton: false,
                    confirmButtonText: "Ok!",
                });
            });
    };

    deleteTask = (id) => {
        const {dispatch} = this.props;
        Swal.fire({
            title: "Delete Customer",
            text: `Are you sure you want to delete this task?`,
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, keep it",
        }).then((result) => {
            if (result.value) {
                this.setState({
                    loading: true,
                });
                userService
                    .deleteTask(id)
                    .then(() => {
                        window.toast("error", `The task has been deleted from the system`);

                        this.getAllTasks(this.state.pageOffset);
                    })
                    .catch((e) => {
                        this.setState({
                            loading: false,
                        });
                        e.json().then((err) => {
                            Swal.fire({
                                title: "Error",
                                text: `${err.message}`,
                                type: "warning",
                                showCancelButton: false,
                                confirmButtonText: "Ok!",
                            });
                        });
                    });
            }
        });
    };

    taskChangeHandler = (e) => {
        this.setState({
            task: {
                ...this.state.task,
                [e.target.name]: e.target.value,
            },
        });
    };

    taskChangeDuetime = (dueTime) => {
        this.setState({
            ...this.state,
            dueTime: dueTime,
        });
    };

    closeUpdateModal = () => {
        this.setState({
            ...this.state,
            searchUpdateVals: "",
        });
    };

    updateStaffNotes = (id, notes) => {
        userService.saveApptStaffNotes(id, notes).then((res) => {
            appService.getSingleVtService(this.state.viewDetails.id).then((res) => {
                this.setState({
                    ...this.state,
                    viewDetails: res,
                    editApptDetails: res,
                    loading: false,
                });
            });
            window.DataUpdateSuccess();
            appService.getTodayAppointments().then((data) => {
                this.setState({appointmentsTodayData: data});
            });
        });
    };

    saveNote = (e) => {
        e.preventDefault();
        const notes = {
            staffNotes:
                this.state.staffNotes != ""
                    ? this.state.staffNotes
                    : this.state.viewDetails.staffNotes,
        };
        this.updateStaffNotes(this.state.viewDetails.id, notes);
        $("#addNote").modal("toggle");
    };

    confirmAppt = (id, time) => {
        this.setState({
            ...this.state,
            loading: true,
        });
        appService
            .confirmAppt(id, time)
            .then((res) => {
                this.setState({
                    ...this.state,
                    viewDetails: res,
                    editApptDetails: res,
                    loading: false,
                });

                window.DataUpdateSuccess();
                appService.getTodayAppointments().then((data) => {
                    this.setState({appointmentsTodayData: data});
                });
            })
            .catch((e) => {
                this.setState({
                    ...this.state,
                    loading: false,
                });
            });
    };

    apptAddUpdateService = (id, apttService) => {
        this.setState({
            ...this.state,
            loading: true,
        });
        if (id !== "Nill") {
            appService
                .UpdateService(id, apttService)
                .then((res) => {
                    appService
                        .getSingleVtService(this.state.viewDetails.id)
                        .then((res) => {
                            this.setState({
                                ...this.state,
                                viewDetails: res,
                                editApptDetails: res,
                                loading: false,
                            });
                        });
                    window.DataUpdateSuccess();
                    appService.getTodayAppointments().then((data) => {
                        this.setState({appointmentsTodayData: data});
                    });
                })
                .catch((e) => {
                    this.setState({
                        ...this.state,
                        loading: false,
                    });
                });
        } else {
            appService
                .apptAddUpdateService(apttService)
                .then((res) => {
                    appService
                        .getSingleVtService(this.state.viewDetails.id)
                        .then((res) => {
                            this.setState({
                                ...this.state,
                                viewDetails: res,
                                editApptDetails: res,
                                loading: false,
                            });
                        });

                    window.DataUpdateSuccess();
                    appService.getTodayAppointments().then((data) => {
                        this.setState({appointmentsTodayData: data});
                    });
                })
                .catch((e) => {
                    this.setState({
                        ...this.state,
                        loading: false,
                    });
                    e.json().then((err) => {
                        Swal.fire({
                            title: "Error",
                            text: `${err.message}`,
                            type: "warning",
                            showCancelButton: false,
                            confirmButtonText: "Ok",
                        });
                    });
                });
        }
    };

    //cancel service from the app
    cancelServieFromAppt = (id, canelReason, count) => {
        this.setState({
            loading: true,
        });
        appService
            .cancelServieFromAppt(id, canelReason)
            .then((res) => {
                window.toast(
                    "error",
                    `The service has been deleted from the appointment`
                );
                /* eslint-disable */
                appService.getSingleVtService(this.state.viewDetails.id).then((res) => {
                    this.setState(
                        {
                            ...this.state,
                            viewDetails: res,
                            editApptDetails: res,
                            loading: false,
                        },
                        () => {
                            if (this.state.viewDetails.status != "Cancelled") {
                                $("#editModal").modal("show");
                            }
                        }
                    );
                });

                appService.getTodayAppointments().then((data) => {
                    this.setState({appointmentsTodayData: data});
                });
            })
            .catch((e) => {
                this.setState({
                    loading: false,
                });
                e.json().then((err) => {
                    Swal.fire({
                        title: "Error!",
                        text: `${err.message}`,
                        type: "warning",
                        showCancelButton: false,
                    });
                });
            });
    };

    //check in service
    checkServiceIn = (vt) => {
        this.setState({
            ...this.state,
            loading: true,
        });
        appService
            .checkServiceIn(vt)
            .then((res) => {
                appService.getSingleVtService(this.state.viewDetails.id).then((v) => {
                    this.setState({
                        ...this.state,
                        viewDetails: v,
                        editApptDetails: v,
                        loading: false,
                    });
                });
                window.toast("success", "Appointment checked in successfully");
                appService.getTodayAppointments().then((data) => {
                    this.setState({appointmentsTodayData: data});
                });
            })
            .catch((e) => {
                Swal.fire({
                    title: "Error",
                    text: `Check-in is only allowed for today's appointments`,
                    type: "warning",
                    showCancelButton: false,
                    confirmButtonText: "Ok!",
                });
                this.setState({
                    ...this.state,
                    loading: false,
                });
            });
    };

    //check out service
    checkServiceOut = (vt, amount) => {
        this.setState({
            ...this.state,
            loading: true,
        });

        appService
            .checkServiceOut(vt, amount)
            .then((res) => {
                window.toast("success", "Service checked out successfully");

                appService.getSingleVtService(this.state.viewDetails.id).then((res) => {
                    this.setState({
                        ...this.state,
                        viewDetails: res,
                        editApptDetails: res,
                        loading: false,
                    });
                });
                appService.getTodayAppointments().then((data) => {
                    this.setState({appointmentsTodayData: data});
                });
            })
            .catch((e) => {
                this.setState({
                    ...this.state,
                    loading: false,
                });
            });
    };

    UndocheckServiceIn = (vt) => {
        this.setState({
            ...this.state,
            loading: true,
            menuState: false,
        });
        appService
            .UndocheckServiceIn(vt)
            .then((res) => {
                window.toast("error", "Appointment undo  checked in is successfull");
                appService.getSingleVtService(this.state.viewDetails.id).then((res) => {
                    this.setState({
                        ...this.state,
                        viewDetails: res,
                        editApptDetails: res,
                        loading: false,
                    });
                });
                appService.getTodayAppointments().then((data) => {
                    this.setState({appointmentsTodayData: data});
                });
            })
            .catch((e) => {
                this.setState({
                    ...this.state,
                    loading: false,
                });
            });
    };

    updateBookingTime = (id, timeJSON) => {
        appService
            .updateBookingTime(id, timeJSON)
            .then((res) => {
                $("#editModal").modal("toggle");
                appService.getSingleVtService(this.state.viewDetails.id).then((res) => {
                    this.setState({
                        ...this.state,
                        viewDetails: res,
                        editApptDetails: res,
                        loading: false,
                    });
                });
                window.DataUpdateSuccess();
                appService.getTodayAppointments().then((data) => {
                    this.setState({appointmentsTodayData: data});
                });
            })
            .catch((e) => {
                Swal.fire({
                    title: "Error",
                    text: `${e.message}`,
                    type: "warning",
                    showCancelButton: false,
                    confirmButtonText: "Ok!",
                });
                this.setState({
                    ...this.state,
                    loading: false,
                });
            });
    };

    appointmentCheckIn = (id, note) => {
        appService
            .appointmentCheckIn(id, note)
            .then((res) => {
                appService.getSingleVtService(this.state.viewDetails.id).then((res) => {
                    this.setState({
                        ...this.state,
                        viewDetails: res,
                        editApptDetails: res,
                        loading: false,
                        staffNotes: "",
                    });
                });
                window.DataUpdateSuccess();
                appService.getTodayAppointments().then((data) => {
                    this.setState({appointmentsTodayData: data});
                });
            })
            .catch((e) => {
                Swal.fire({
                    title: "Error",
                    text: `Check-in is only allowed for today's appointments`,
                    type: "warning",
                    showCancelButton: false,
                    confirmButtonText: "Ok!",
                });
                this.setState({
                    ...this.state,
                    loading: false,
                });
            });
    };

    //appointment check out
    apptCheckout = (id, payments) => {
        appService
            .appointmentCheckOut(id, payments)
            .then((res) => {
                $("#editModal").modal("toggle");
                window.toast("success", `The Appointment Check Out is successful!`);
                appService.getTodayAppointments().then((data) => {
                    this.setState({appointmentsTodayData: data});
                });
            })
            .catch((e) => {
                e.json().then((err) => {
                    Swal.fire({
                        title: "Error",
                        text: `${err.message}`,
                        type: "warning",
                        showCancelButton: false,
                        confirmButtonText: "Ok!",
                    });
                });
            });
    };

    openCancelModal = () => {
        this.setState({
            ...this.state,
            loading: true,
            menuState: false,
        });
        appService.getSingleVtService(this.state.viewDetails.id).then((res) => {
            this.setState({
                ...this.state,
                viewDetails: res,
                editApptDetails: res,
                loading: false,
            });
        });
    };

    cancelAppt = () => {
        const reason = {
            cancellationReason: this.state.cancelReason,
        };
        Swal.fire({
            title: "Cancel Appointment",
            text: `Are you sure you want to cancel this appointment`,
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, Cancel it!",
            cancelButtonText: "No, keep it",
        }).then((result) => {
            if (result.value) {
                this.setState({
                    ...this.state,
                    loading: true,
                });

                if (this.state.editApptDetails.status == "canceled") {
                    Swal.fire({
                        title: "Error!",
                        text: `This appointment is already canceled!`,
                        type: "warning",
                        showCancelButton: true,
                        cancelButtonText: "Ok",
                    });
                } else {
                    appService
                        .cancelAppt(this.state.editApptDetails.id, reason)
                        .then((res) => {
                            window.toast(
                                "error",
                                `The appointment has been canceled from the appointment`
                            );
                            /* eslint-disable */
                            appService
                                .getSingleVtService(this.state.viewDetails.id)
                                .then((res) => {
                                    this.setState({
                                        ...this.state,
                                        viewDetails: res,
                                        editApptDetails: res,
                                        cancelReason: "",
                                        menuState: false,
                                        loading: false,
                                    });

                                    $("#cancelAppt").modal("toggle");
                                });
                            appService.getTodayAppointments().then((data) => {
                                this.setState({appointmentsTodayData: data});
                            });
                        })
                        .catch((e) => {
                            e.json().then((error) => {
                                Swal.fire({
                                    title: "Error",
                                    text: `${error.message}`,
                                    type: "error",
                                    showCancelButton: false,
                                    confirmButtonText: "Ok",
                                });
                            });
                            this.setState({
                                ...this.state,
                                loading: false,
                            });
                        });
                }
            } else {
                this.setState({
                    ...this.state,
                    loading: false,
                });
            }
        });
    };

    makeFirstBtnActive = () => {
        appService.getSalesData().then((data) => {
            this.setState({
                ...this.state,
                firstBtn: "actve",
                secondBtn: "",
                counting: "bookings",
                salesData: data.bookingsToday,
            });
        });
    };
    makeSecondBtnActive = () => {
        appService.getSalesData().then((data) => {
            this.setState({
                ...this.state,
                firstBtn: "",
                secondBtn: "actve",
                counting: "checkout",
                salesData: data.today,
            });
        });
    };

    saveProduct = (prJson, id) => {
        if (id == "nill") {
            appService.saveProduct(prJson).then((res) => {
                window.DataUpdateSuccess();
                appService.getSingleVtService(this.state.viewDetails.id).then((res) => {
                    this.setState({
                        ...this.state,
                        viewDetails: res,
                        editApptDetails: res,
                        loading: false,
                    });
                });
                appService.getTodayAppointments().then((data) => {
                    this.setState({appointmentsTodayData: data});
                });
            });
        } else {
            appService.updateProduct(prJson, id).then((res) => {
                appService.getSingleVtService(this.state.viewDetails.id).then((res) => {
                    this.setState({
                        ...this.state,
                        viewDetails: res,
                        editApptDetails: res,
                        loading: false,
                    });
                });
                window.DataUpdateSuccess();
                appService.getTodayAppointments().then((data) => {
                    this.setState({appointmentsTodayData: data});
                });
            });
        }
    };

    deleteProduct = (id) => {
        Swal.fire({
            title: "Delete Product",
            text: `Are you sure you want to delete this Product?`,
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, keep it",
        }).then((result) => {
            if (result.value) {
                this.setState({
                    ...this.state,
                    loading: true,
                });
                appService.deleteProduct(id).then((res) => {
                    appService
                        .getSingleVtService(this.state.viewDetails.id)
                        .then((res) => {
                            this.setState({
                                ...this.state,
                                viewDetails: res,
                                editApptDetails: res,
                                loading: false,
                            });
                        });
                    window.toast(
                        "error",
                        `The product has been deleted from the appointment`
                    );
                    /* eslint-disable */

                    appService.getTodayAppointments().then((data) => {
                        this.setState({appointmentsTodayData: data});
                    });
                });
            } else {
                this.setState({
                    ...this.state,
                    loading: false,
                });
            }
        });
    };

    removeFromCart = (id) => {
        let cartCollections = this.state.cartCollections;
        const newCollection = cartCollections.filter((c) => c.id != id);
        const old = cartCollections.filter((c) => c.id == id);
        if (cartCollections.length < 2) {
            $("#cartModal").modal("toggle");
            this.setState({
                cartAdded: "",
            });
        }

        this.setState({
            cartCollections: newCollection,
            totalCharge: this.state.totalCharge * 1 - old[0].totalChargeAmount,
            serviceChargeAmount:
                this.state.serviceChargeAmount * 1 - old[0].serviceChargeAmount,
            cashPaidAmount: this.state.cashPaidAmount * 1 - old[0].cashPaidAmount,
            checkPaidAmount: this.state.checkPaidAmount * 1 - old[0].checkPaidAmount,
            creditPaidAmount:
                this.state.creditPaidAmount * 1 - old[0].creditPaidAmount,
            discountDeductionAmount:
                this.state.discountDeductionAmount * 1 - old[0].discountDeductionAmount,
            giftCardPaidAmount:
                this.state.giftCardPaidAmount * 1 - old[0].giftCardPaidAmount,
            productPurchaseAmount:
                this.state.productPurchaseAmount * 1 - old[0].productPurchaseAmount,
            rewardDeductionAmount:
                this.state.rewardDeductionAmount * 1 - old[0].rewardDeductionAmount,
            taxAmount: this.state.taxAmount * 1 - old[0].taxAmount,
            tipAmount: this.state.tipAmount * 1 - old[0].tipAmount,
        });
    };
    editCartFromCollection = (id) => {
        let cartCollections = this.state.cartCollections;
        const newCollection = cartCollections.filter((c) => c.id != id);

        this.setState({
            cartCollections: newCollection,
            loading: true,
            cartAdded: "",
        });

        appService.getSingleVtService(id).then((res) => {
            this.setState({
                viewDetails: res,
                editApptDetails: res,
                loading: false,
            });
        });

        $("#editModal").modal("toggle");
    };

    placeGroupCheckOut = (id, group) => {
        appService
            .placeCheckOut(id, group)
            .then(() => {
                $("#cartModal").modal("toggle");
                window.toast("success", `The appointment is successfully checked out!`);
                /* eslint-disable */
                appService.getTodayAppointments().then((data) => {
                    this.setState({appointmentsTodayData: data});
                });
            })
            .catch((e) => {
                e.json().then((err) => {
                    Swal.fire({
                        title: "Error",
                        text: `${err.message}`,
                        type: "warning",
                        showCancelButton: false,
                        confirmButtonText: "Ok!",
                    });
                });
            });
    };

    reloadTodayScreen = (data) => {
        const {dispatch} = this.props;
        let apptDate = moment(data.bookedTime).format("YYYY-MM-DD");

        let currentDate = moment().format("YYYY-MM-DD");

        if (apptDate == currentDate && data.requestType != "Time-Off") {
            appService.getTodayAppointments().then((data) => {
                this.setState({appointmentsTodayData: data, dataCame: true});
            });

            dispatch(communicationAction.procceedToReload({}));
        }
    };

    openCaller = (user) => {
        if (
            this.loggedUser.role == "DIRECTOR" ||
            this.loggedUser.role == "MANAGER" ||
            this.loggedUser.role == "RECEPTIONIST"
        ) {
            const {dispatch} = this.props;
            if (this.state.viewDetails != "") {
                userService
                    .getCustomerInfo(this.state.viewDetails.client.id)
                    .then((user) => {
                        dispatch(outgoingAction.proccessCall(user));
                    });
            }
        }
    };

    setPredefined = () => {
        this.setState(
            {
                taskSelected: {
                    taskSelectedClient: "",
                },
            },
            () => {
                this.setState({
                    status: "",
                });
            }
        );
    };

    render() {
        const {reloadData, user} = this.props;

        const {
            salesData,
            guestsData,
            birthdaysData,
            winBoardData,
            appointmentsTodayData,
            task,
            searchUpdateVals,
            searchType,
            tasks,
        } = this.state;

        return (
            <React.Fragment>
                <div id="page-wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div
                                    className="panel panel-default counter-panel"
                                    id="dashboard-panel"
                                >
                                    <div className="panel-wrapper collapse in">
                                        <div className="scroll-wrapper">
                                            <div className="scroll-content">
                                                <ul
                                                    role="tablist"
                                                    className="nav customtab nav-tabs whitebg"
                                                >
                                                    <li className="active" role="presentation">
                                                        <a
                                                            href="#"
                                                            onClick={() => {
                                                                this.getTodaySalesData(this.state.counting);
                                                            }}
                                                            aria-expanded="true"
                                                            data-toggle="tab"
                                                            role="tab"
                                                            aria-controls="home"
                                                        >
                                                            <span className="visible-xs">Today</span>
                                                            <span className="hidden-xs"> Today</span>
                                                        </a>
                                                    </li>
                                                    <li className="" role="presentation">
                                                        <a
                                                            href="#"
                                                            onClick={() => {
                                                                this.getThisWeekSalesData(this.state.counting);
                                                            }}
                                                            aria-expanded="false"
                                                            data-toggle="tab"
                                                            role="tab"
                                                            aria-controls="profile"
                                                        >
                                                            <span className="visible-xs">This Week</span>{" "}
                                                            <span className="hidden-xs">This Week</span>
                                                        </a>
                                                    </li>
                                                    <li role="presentation" className="">
                                                        <a
                                                            href="#"
                                                            onClick={() => {
                                                                this.getlast30DaysSalesData(this.state.counting);
                                                            }}
                                                            data-toggle="tab"
                                                            role="tab"
                                                            aria-controls="messages"
                                                            aria-expanded="false"
                                                        >
                                                            <span className="visible-xs">This Month</span>{" "}
                                                            <span className="hidden-xs">This Month</span>
                                                        </a>
                                                    </li>
                                                    <li role="presentation" className="">
                                                        <a
                                                            href="#"
                                                            onClick={() => {
                                                                this.getlast90DaysSalesData(this.state.counting);
                                                            }}
                                                            data-toggle="tab"
                                                            role="tab"
                                                            aria-controls="messages"
                                                            aria-expanded="false"
                                                        >
                                                            <span className="visible-xs">90 Days</span>{" "}
                                                            <span className="hidden-xs">90 Days</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="switches">
                                                    <button
                                                        onClick={this.makeFirstBtnActive}
                                                        className={this.state.firstBtn}
                                                    >
                                                        Bookings
                                                    </button>
                                                    <button
                                                        onClick={this.makeSecondBtnActive}
                                                        className={this.state.secondBtn}
                                                    >
                                                        Check Outs
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="panel-body panel-cus p0">
                                            <div className="tab-content m-t-0 home-page-tab">
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        {salesData && this.state.firstBtn == "actve" && (
                                                            <div className="row">
                                                                <div className="col-md-2 col-lg-2 col-sm-2">
                                                                    <div
                                                                        className={
                                                                            salesData.length == 0
                                                                                ? "custom-tab-content before-loading"
                                                                                : "custom-tab-content"
                                                                        }
                                                                    >
                                                                        <ul className="list-unstyled">
                                                                            <li>
                                        <span className="circle circle-md fade-bg">
                                          <i className="fa fa-users"></i>
                                        </span>
                                                                            </li>

                                                                            <li className="col-middle text-center">
                                                                                <h4 className="text-danger">
                                                                                    NEW GUESTS
                                                                                </h4>
                                                                            </li>

                                                                            <li className="col-last">
                                                                                <h3 className="counter text-center text-danger">
                                                                                    {salesData.noOfNewGuests}
                                                                                </h3>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-2 col-lg-2 col-sm-2">
                                                                    <div
                                                                        className={
                                                                            salesData.length == 0
                                                                                ? "custom-tab-content before-loading"
                                                                                : "custom-tab-content"
                                                                        }
                                                                    >
                                                                        <ul className="list-unstyled">
                                                                            <li>
                                        <span className="circle circle-md fade-bg">
                                          <i className="fa fa-refresh custom-info"></i>
                                        </span>
                                                                            </li>
                                                                            <li className="col-middle">
                                                                                <h4 className="text-info">
                                                                                    REPEAT GUESTS
                                                                                </h4>
                                                                            </li>
                                                                            <li className="col-last">
                                                                                <h3 className="counter text-center text-info">
                                                                                    {salesData.noOfRepeatGuests}
                                                                                </h3>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-2 col-lg-2 col-sm-2">
                                                                    <div
                                                                        className={
                                                                            salesData.length == 0
                                                                                ? "custom-tab-content before-loading"
                                                                                : "custom-tab-content"
                                                                        }
                                                                    >
                                                                        <ul className="list-unstyled">
                                                                            <li>
                                        <span className="circle circle-md fade-bg">
                                          <i className="mdi mdi-run mdi-2x custom-info"></i>
                                        </span>
                                                                            </li>
                                                                            <li className="col-middle">
                                                                                <h4 className="text-success">
                                                                                    WALK INS
                                                                                </h4>
                                                                            </li>
                                                                            <li className="col-last">
                                                                                <h3 className="counter text-center text-success">
                                                                                    {salesData.noOfWalkInBooking}
                                                                                </h3>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2 col-lg-2 col-sm-2">
                                                                    <div
                                                                        className={
                                                                            salesData.length == 0
                                                                                ? "custom-tab-content before-loading"
                                                                                : "custom-tab-content"
                                                                        }
                                                                    >
                                                                        <ul className="list-unstyled">
                                                                            <li>
                                        <span className="circle circle-md fade-bg">
                                          <i className="fa fa-phone custom-info"></i>
                                        </span>
                                                                            </li>
                                                                            <li className="col-middle">
                                                                                <h4 className="text-warning">PHONE</h4>
                                                                            </li>
                                                                            <li className="col-last">
                                                                                <h3 className="counter text-center text-warning">
                                                                                    {salesData.noOfPhoneInBooking}
                                                                                </h3>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2 col-lg-2 col-sm-2">
                                                                    <div
                                                                        className={
                                                                            salesData.length == 0
                                                                                ? "custom-tab-content before-loading"
                                                                                : "custom-tab-content"
                                                                        }
                                                                    >
                                                                        <ul className="list-unstyled">
                                                                            <li>
                                        <span className="circle circle-md fade-bg">
                                          <i className="fa fa-apple custom-info"></i>
                                        </span>
                                                                            </li>
                                                                            <li className="col-middle">
                                                                                <h4 className="text-info">APP</h4>
                                                                            </li>
                                                                            <li className="col-last">
                                                                                <h3 className="counter text-center text-info">
                                                                                    {salesData.noOfAppBooking}
                                                                                </h3>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2 col-lg-2 col-sm-2">
                                                                    <div
                                                                        className={
                                                                            salesData.length == 0
                                                                                ? "custom-tab-content before-loading"
                                                                                : "custom-tab-content"
                                                                        }
                                                                    >
                                                                        <ul className="list-unstyled">
                                                                            <li>
                                        <span className="circle circle-md fade-bg">
                                          <i className="fa fa-globe custom-info"></i>
                                        </span>
                                                                            </li>
                                                                            <li className="col-middle">
                                                                                <h4 className="text-danger">
                                                                                    WEB SITE
                                                                                </h4>
                                                                            </li>
                                                                            <li className="col-last">
                                                                                <h3 className="counter text-center text-danger">
                                                                                    {salesData.noOfWebsiteBooking}
                                                                                </h3>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}

                                                        {salesData && this.state.secondBtn == "actve" && (
                                                            <div className="row secondBtn">
                                                                <div className="col-md-2 col-lg-2 col-sm-2">
                                                                    <div className="custom-tab-content">
                                                                        <ul className="list-unstyled">
                                                                            <li>
                                        <span className="circle circle-md fade-bg">
                                          <i className="icon-people custom-info"></i>
                                        </span>
                                                                            </li>

                                                                            <li className="col-middle text-center">
                                                                                <h4 className="text-danger">
                                                                                    NEW GUESTS
                                                                                </h4>
                                                                            </li>

                                                                            <li className="col-last">
                                                                                <h3 className="counter text-center text-danger">
                                                                                    {salesData.noOfNewGuests}
                                                                                </h3>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-2 col-lg-2 col-sm-2">
                                                                    <div className="custom-tab-content">
                                                                        <ul className="list-unstyled">
                                                                            <li>
                                        <span className="circle circle-md fade-bg">
                                          <i className="fa fa-refresh custom-info"></i>
                                        </span>
                                                                            </li>
                                                                            <li className="col-middle">
                                                                                <h4 className="text-info">
                                                                                    REPEAT GUESTS
                                                                                </h4>
                                                                            </li>
                                                                            <li className="col-last">
                                                                                <h3 className="counter text-center text-info">
                                                                                    {salesData.noOfRepeatGuests}
                                                                                </h3>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-2 col-lg-2 col-sm-2">
                                                                    <div className="custom-tab-content">
                                                                        <ul className="list-unstyled">
                                                                            <li>
                                        <span className="circle circle-md fade-bg">
                                          <i className="mdi mdi-run mdi-2x custom-info"></i>
                                        </span>
                                                                            </li>
                                                                            <li className="col-middle">
                                                                                <h4 className="text-success">
                                                                                    WALK INS
                                                                                </h4>
                                                                            </li>
                                                                            <li className="col-last">
                                                                                <h3 className="counter text-center text-success">
                                                                                    {salesData.noOfWalkInBooking}
                                                                                </h3>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2 col-lg-2 col-sm-2">
                                                                    <div className="custom-tab-content">
                                                                        <ul className="list-unstyled">
                                                                            <li>
                                        <span className="circle circle-md fade-bg">
                                          <i className="fa fa-phone custom-info"></i>
                                        </span>
                                                                            </li>
                                                                            <li className="col-middle">
                                                                                <h4 className="text-warning">PHONE</h4>
                                                                            </li>
                                                                            <li className="col-last">
                                                                                <h3 className="counter text-center text-warning">
                                                                                    {salesData.noOfPhoneInBooking}
                                                                                </h3>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2 col-lg-2 col-sm-2">
                                                                    <div className="custom-tab-content">
                                                                        <ul className="list-unstyled">
                                                                            <li>
                                        <span className="circle circle-md fade-bg">
                                          <i className="fa fa-apple custom-info"></i>
                                        </span>
                                                                            </li>
                                                                            <li className="col-middle">
                                                                                <h4 className="text-info">APP</h4>
                                                                            </li>
                                                                            <li className="col-last">
                                                                                <h3 className="counter text-center text-info">
                                                                                    {salesData.noOfAppBooking}
                                                                                </h3>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2 col-lg-2 col-sm-2 right">
                                                                    <div className="custom-tab-content">
                                                                        <ul className="list-unstyled">
                                                                            <li>
                                        <span className="circle circle-md fade-bg">
                                          <i className="fa fa-globe custom-info"></i>
                                        </span>
                                                                            </li>
                                                                            <li className="col-middle">
                                                                                <h4 className="text-danger">
                                                                                    WEB SITE
                                                                                </h4>
                                                                            </li>
                                                                            <li className="col-last">
                                                                                <h3 className="counter text-center text-danger">
                                                                                    {salesData.noOfWebsiteBooking}
                                                                                </h3>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="white-box">
                                    <h3 className="box-title pointer">
                                        Appointments Today
                                        {this.state.cartCollections.length > 0 && (
                                            <a
                                                data-target="#cartModal"
                                                data-toggle="modal"
                                                className="pull-right"
                                            >
                                                <i className="icon-basket"></i>
                                                <label className="item-count">
                                                    {this.state.cartCollections.length}
                                                </label>
                                            </a>
                                        )}
                                        <i
                                            className="pull-right fa fa-search-plus"
                                            data-toggle="modal"
                                            data-target="#appointmentsModal"
                                        ></i>
                                    </h3>

                                    <div className="table-responsive appt-table">
                                        <table className="table color-table info-table">
                                            <thead>
                                            <tr className="center">
                                                <th>Type</th>
                                                <th>Status</th>
                                                <th>Name</th>
                                                <th>Time</th>
                                                <th>Appreciation</th>
                                                <th>Technicians</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {appointmentsTodayData.length == 0 && (
                                                <tr>
                                                    <td colSpan="6" className="center">
                                                        {" "}
                                                        No Appointments scheduled today
                                                    </td>
                                                </tr>
                                            )}
                                            {appointmentsTodayData.length > 0 &&
                                                appointmentsTodayData.map((a, k) => {
                                                    return (
                                                        <tr
                                                            className={
                                                                this.state.keyId === k ? "active-context" : ""
                                                            }
                                                            key={k}
                                                            onClick={() =>
                                                                this.contextMenus(event, "single", a, k)
                                                            }
                                                        >
                                                            <td>
                                                                <a style={{cursor: "pointer"}}>
                                                                    <i
                                                                        className={
                                                                            a.requestSource == "Walk In"
                                                                                ? "mdi mdi-run text-warning"
                                                                                : a.requestSource == "Phone Call"
                                                                                    ? "fa fa-phone text-danger"
                                                                                    : a.requestSource == "Android App"
                                                                                        ? "fa fa-android text-purple"
                                                                                        : a.requestSource == "iOS App"
                                                                                            ? "fa fa-mobile text-purple"
                                                                                            : a.requestSource == "Website"
                                                                                                ? "fa fa-desktop"
                                                                                                : ""
                                                                        }
                                                                    ></i>
                                                                </a>
                                                            </td>
                                                            <td className="txt-oflo">
                                                                <a style={{cursor: 'pointer'}}>
                                                                    {a.unfinalizedAppearanceRating == "1" ? (
                                                                        <button
                                                                            type="button"
                                                                            className="btn mt0 btn-act btn-circle"
                                                                        ></button>
                                                                    ) : a.unfinalizedAppearanceRating == "2" ? (
                                                                        <button
                                                                            type="button"
                                                                            className="btn mt0 btn-warning btn-circle"
                                                                        ></button>
                                                                    ) : a.unfinalizedAppearanceRating == "3" ? (
                                                                        <button
                                                                            type="button"
                                                                            className="btn mt0 btn-danger btn-circle"
                                                                        ></button>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </a>
                                                            </td>
                                                            <td className="txt-oflo">
                                                                {this.loggedUser.technicianResponsibilities.length > 0 && this.loggedUser.technicianResponsibilities[0].customerAccessAllowed === false ?
                                                                    <span>{a.client.names.first + " " + a.client.names.last[0]}</span> :

                                                                    (<Link
                                                                        to={
                                                                            `/${this.storeId}` +
                                                                            "/update-customer?custId=" +
                                                                            a.client.id
                                                                        }
                                                                    >
                                                                        {a.client.names.first +
                                                                            " " +
                                                                            a.client.names.last[0] +
                                                                            "."}
                                                                    </Link>)
                                                                }

                                                            </td>

                                                            <td className="txt-oflo">
                                                                <a style={{cursor: 'pointer'}}>
                                                                    {a.status == "Booked"
                                                                        ? helperFunctions.formatDateTime(
                                                                            a.bookedTime,
                                                                            "time"
                                                                        )
                                                                        : helperFunctions.formatDateTime(
                                                                            a.period.from,
                                                                            "time"
                                                                        )}
                                                                </a>
                                                            </td>

                                                            <td className="txt-oflo">{a.appreciation}</td>
                                                            <td>
                                                                {a.visitTechnicians.map((t, k) => {
                                                                    return (
                                                                        <img
                                                                            key={k}
                                                                            src={this.findTechnicianImage(
                                                                                t.technician.id
                                                                            )}
                                                                            alt="user"
                                                                            className="img-circle img-small-circle"
                                                                        />
                                                                    );
                                                                })}
                                                                {this.checkCartStatus(a.id) && (
                                                                    <React.Fragment>
                                                                        {" "}
                                                                        &nbsp;{" "}
                                                                        <i className="ti ti-check custom-text-info"></i>
                                                                    </React.Fragment>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12 no-padding-right">
                                <div className="white-box pb3">
                                    <h3 className="box-title">
                                        {/* <i className="icon-people text-info"></i> */}
                                        <i className="fa fa-user text-info"></i>
                                        New Guests
                                    </h3>
                                    <div>
                                        <canvas height="400" id="chart2" width="800"></canvas>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-12 col-md-6 col-lg-6">
                                            <div className="table-responsive count-tbl">
                                                <table className="table">
                                                    <thead>
                                                    <tr>
                                                        <th>Months</th>
                                                        <th>Last year</th>
                                                        <th>Current year</th>
                                                    </tr>
                                                    </thead>
                                                    {guestsData.previousYear &&
                                                        guestsData.previousYear.length > 0 && (
                                                            <tbody>
                                                            <tr>
                                                                <th>Jan</th>
                                                                <td>
                                                                    {guestsData.previousYear[0].totalCount}
                                                                </td>
                                                                <td>
                                                                    {guestsData.currentYear[0].totalCount}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>Feb</th>
                                                                <td>
                                                                    {guestsData.previousYear[1].totalCount}
                                                                </td>
                                                                <td>
                                                                    {guestsData.currentYear[1].totalCount}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>Mar</th>
                                                                <td>
                                                                    {guestsData.previousYear[2].totalCount}
                                                                </td>
                                                                <td>
                                                                    {guestsData.currentYear[2].totalCount}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>Apr</th>
                                                                <td>
                                                                    {guestsData.previousYear[3].totalCount}
                                                                </td>
                                                                <td>
                                                                    {guestsData.currentYear[3].totalCount}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>May</th>
                                                                <td>
                                                                    {guestsData.previousYear[4].totalCount}
                                                                </td>
                                                                <td>
                                                                    {guestsData.currentYear[4].totalCount}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>Jun</th>
                                                                <td>
                                                                    {guestsData.previousYear[5].totalCount}
                                                                </td>
                                                                <td>
                                                                    {guestsData.currentYear[5].totalCount}
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        )}
                                                </table>
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                            <div className="table-responsive count-tbl">
                                                <table className="table">
                                                    <thead>
                                                    <tr>
                                                        <th>Months</th>
                                                        <th>Last year</th>
                                                        <th>Current year</th>
                                                    </tr>
                                                    </thead>
                                                    {guestsData.previousYear &&
                                                        guestsData.previousYear.length > 0 && (
                                                            <tbody>
                                                            <tr>
                                                                <th>Jul</th>
                                                                <td>
                                                                    {guestsData.previousYear[6].totalCount}
                                                                </td>
                                                                <td>
                                                                    {guestsData.currentYear[6].totalCount}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>Aug</th>
                                                                <td>
                                                                    {guestsData.previousYear[7].totalCount}
                                                                </td>
                                                                <td>
                                                                    {guestsData.currentYear[7].totalCount}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>Sept</th>
                                                                <td>
                                                                    {guestsData.previousYear[8].totalCount}
                                                                </td>
                                                                <td>
                                                                    {guestsData.currentYear[8].totalCount}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>Oct</th>
                                                                <td>
                                                                    {guestsData.previousYear[9].totalCount}
                                                                </td>
                                                                <td>
                                                                    {guestsData.currentYear[9].totalCount}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>Nov</th>
                                                                <td>
                                                                    {guestsData.previousYear[10].totalCount}
                                                                </td>
                                                                <td>
                                                                    {guestsData.currentYear[10].totalCount}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>Dec</th>
                                                                <td>
                                                                    {guestsData.previousYear[11].totalCount}
                                                                </td>
                                                                <td>
                                                                    {guestsData.currentYear[11].totalCount}
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        )}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-6 col-lg-6 col-xs-12">
                                <div className="white-box tasks">
                                    <div className="panel">
                                        <div className="p-20">
                                            <div className="row">
                                                <div className="col-xs-6">
                                                    <h5 className="m-b-0">This months task</h5>
                                                    <h3 className="m-t-0 font-medium">TO-DO LIST</h3>
                                                </div>
                                                <div className="col-xs-6">
                                                    <a
                                                        onClick={this.setPredefined}

                                                        className="pull-right btn btn-custom-info m-t-15"
                                                        data-toggle="modal"
                                                        data-target="#addingTask"
                                                    >
                                                        + Add Task
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                        {tasks && tasks.length === 0 && (
                                            <h3 className="text-center">Records Not Found!</h3>
                                        )}

                                        {tasks &&
                                            tasks.length > 0 &&
                                            tasks.map((task, key) => {
                                                return (
                                                    <div key={key} className="panel-footer">
                                                        <ul
                                                            className="list-task todo-list list-group m-b-0"
                                                            data-role="tasklist"
                                                        >
                                                            <li className="list-group-item" data-role="task">
                                                                <div className="checkbox checkbox-info">
                                                                    <label>
                                                                        {" "}
                                                                        <span>{task.subject}</span>
                                                                    </label>
                                                                </div>
                                                                <ul className="assignedto">
                                                                    <li>
                                                                        <img
                                                                            title="Customer: Rose Mary"
                                                                            data-toggle="popover"
                                                                            data-content="Mountaineering boots are a type of footwear used in the sport of mountain climbing."
                                                                            data-placement="top"
                                                                            src={
                                                                                task.createUser
                                                                                    ? task.createUser.imageUrl
                                                                                    : systemIcon
                                                                            }
                                                                            alt="user img"
                                                                        />
                                                                    </li>
                                                                    <li>
                                                                        <img
                                                                            title="Staff: Christina Jessee "
                                                                            data-toggle="popover"
                                                                            data-content="Mountaineering boots are a type of footwear used in the sport of mountain climbing."
                                                                            data-placement="top"
                                                                            src={this.getrelatedTypeImage(
                                                                                task.relatedItemType,
                                                                                task
                                                                            )}
                                                                            alt="user img"
                                                                        />
                                                                    </li>
                                                                    <li>
                                                                        <img
                                                                            title="Product: Angel.Wash "
                                                                            data-toggle="popover"
                                                                            data-content="Mountaineering boots are a type of footwear used in the sport of mountain climbing."
                                                                            data-placement="top"
                                                                            src={this.getRelatedItemImage(
                                                                                task.relatedItemType,
                                                                                task
                                                                            )}
                                                                            alt="user img"
                                                                        />
                                                                    </li>
                                                                </ul>

                                                                <div className="taskBtn">
                                                                    {task.relatedItemType ==
                                                                    "Exhausted Standings" ||
                                                                    task.relatedItemType ==
                                                                    "Exhausted Products" ||
                                                                    task.relatedItemType ==
                                                                    "Unconfirmed Appointments" ? (
                                                                        ""
                                                                    ) : (
                                                                        <React.Fragment>
                                                                            <button
                                                                                onClick={() =>
                                                                                    this.openEditTaskModal(task.id)
                                                                                }
                                                                                data-toggle="modal"
                                                                                data-target="#editTask"
                                                                                className="btn btn-info btn-lg btn-outline btn-circle"
                                                                            >
                                                                                <i className="fa fa-pencil"></i>
                                                                            </button>
                                                                            <button
                                                                                onClick={() => this.deleteTask(task.id)}
                                                                                className="btn btn-danger btn-lg btn-outline btn-circle"
                                                                            >
                                                                                <i className="fa fa-trash text-danger"></i>
                                                                            </button>
                                                                        </React.Fragment>
                                                                    )}
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                );
                                            })}

                                        <ul className="pager pull-right">
                                            <li>
                                                <a onClick={this.prevTodo}>
                                                    <i className="fa fa-angle-left"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a onClick={this.nextTodo}>
                                                    <i className="fa fa-angle-right"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>

                                    {this.state.loading == true && (
                                        <div className="custom-loading">
                                            <div className="loading">
                                                <i className="fa fa-spinner fa-spin"></i>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    {this.state.menuState ? (
                        <ul className="context-menu" id="contextMenu">
                            {Object.keys(this.state.viewDetails).length > 0 && (
                                <li style={{cursor: "pointer"}} className="more-info">
                  <span className="soruce">
                    <i
                        className={this.getRequestSource(
                            this.state.viewDetails.requestSource
                        )}
                    ></i>
                  </span>
                                    <span>
                    {this.state.viewDetails.client.fullName} -{" "}
                                        <i className="custom-info" onClick={this.openCaller}>
                      {this.loggedUser.technicianResponsibilities.length > 0 && this.loggedUser.technicianResponsibilities[0].customerAccessAllowed === false ? null : helperFunctions.formatMobilePhone(
                          this.state.viewDetails.client.mobileNumber
                      )}
                    </i>
                  </span>
                                    <span>
                    {this.state.viewDetails.visitTechnicians.length > 0 &&
                        this.findServiceName(
                            this.state.viewDetails.visitTechnicians[0]
                                .offeredService.id,
                            this.state.viewDetails.visitTechnicians[0]
                                .offeredService.category.id
                        ) +
                        " with " +
                        this.findTechnician(
                            this.state.viewDetails.visitTechnicians[0].technician
                                .id,
                            "nick"
                        )}
                                        {this.state.viewDetails.visitTechnicians.length > 1 &&
                                            `(${this.state.viewDetails.visitTechnicians.length})`}
                  </span>
                                </li>
                            )}

                            <li className="positioned_close">
                                <a onClick={this.closeContextMenu} href="javascript:void(0)">
                                    <i className="fa fa-close"></i>
                                </a>
                            </li>

                            {this.state.viewDetails.status == "Checked Out" && (
                                <li>
                                    <a
                                        href="#viewDetails"
                                        data-toggle="modal"
                                        onClick={() => this.apptViewDetails(this.state.viewDetails)}
                                    >
                                        <i className="fa fa-eye"></i>View Details
                                    </a>
                                </li>
                            )}
                            {Object.keys(this.state.viewDetails).length > 0 && this.loggedUser.technicianResponsibilities.length > 0 && this.loggedUser.technicianResponsibilities[0].customerAccessAllowed === false ? null : (
                                <li>
                                    <Link
                                        to={
                                            `/${this.storeId}` +
                                            "/update-customer?custId=" +
                                            this.state.viewDetails.client.id
                                        }
                                    >
                                        <i className="fa fa-user"></i>Client Details
                                    </Link>
                                </li>
                            )}
                            {this.state.viewDetails.checkInOutAllowed == true &&
                                this.state.viewDetails.status == "Booked" && (
                                    <li>
                                        <a
                                            onClick={() =>
                                                this.openCheckinModal(this.state.viewDetails)
                                            }
                                            href="#checkin"
                                            data-toggle="modal"
                                        >
                                            <i className="fa fa-check"></i>Check In
                                        </a>
                                    </li>
                                )}

                            {this.state.viewDetails.status == "Checked In" &&
                                this.state.viewDetails.checkInOutAllowed == true && (
                                    <li>
                                        <a
                                            onClick={() =>
                                                this.UndocheckServiceIn(this.state.viewDetails.id)
                                            }
                                        >
                                            <i className="fa fa-undo"></i>Undo Checked In
                                        </a>
                                    </li>
                                )}

                            {this.state.viewDetails.checkInOutAllowed == true &&
                                this.state.viewDetails.status == "Booked" && (
                                    <li>
                                        <a
                                            onClick={this.openCheckOutModal}
                                            href="#editModal"
                                            data-toggle="modal"
                                        >
                                            <i className="mdi mdi-run"></i>Check Out
                                        </a>
                                    </li>
                                )}
                            {this.state.viewDetails.checkInOutAllowed == true &&
                                this.state.viewDetails.status == "Checked In" && (
                                    <li>
                                        <a
                                            onClick={this.openCheckOutModal}
                                            href="#editModal"
                                            data-toggle="modal"
                                        >
                                            <i className="mdi mdi-run"></i>Check Out
                                        </a>
                                    </li>
                                )}

                            {this.state.viewDetails.status == "Checked In" && (
                                <li>
                                    <a href="javascript:void(0)" onClick={this.openModal}>
                                        <i className="fa fa-plus"></i>Add To Cart
                                    </a>
                                </li>
                            )}

                            <li>
                                <a
                                    href="javascript:void(0)"
                                    onClick={() => this.openEditModal(this.state.viewDetails)}
                                >
                                    <i className="fa fa-pencil"></i>Edit Appt
                                </a>
                            </li>

                            {this.state.viewDetails.status == "Booked" && (
                                <React.Fragment>
                                    <li>
                                        <a
                                            onClick={() =>
                                                this.taskSelectedItem(this.state.viewDetails)
                                            }
                                            href="#addingTask"
                                            data-toggle="modal"
                                        >
                                            <i className="fa fa-check-square"></i>Add Task
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            onClick={() => this.setState({menuState: false})}
                                            href="#addNote"
                                            data-toggle="modal"
                                        >
                                            <i className="fa fa-sticky-note-o"></i>Edit Note
                                        </a>
                                    </li>
                                </React.Fragment>
                            )}
                            {this.state.viewDetails.status == "Checked In" && (
                                <React.Fragment>
                                    <li>
                                        <a
                                            onClick={() =>
                                                this.taskSelectedItem(this.state.viewDetails)
                                            }
                                            href="#addingTask"
                                            data-toggle="modal"
                                        >
                                            <i className="fa fa-check-square"></i>Add Task
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            onClick={() => this.setState({menuState: false})}
                                            href="#addNote"
                                            data-toggle="modal"
                                        >
                                            <i className="fa fa-sticky-note-o"></i>Edit Note
                                        </a>
                                    </li>
                                </React.Fragment>
                            )}
                            {this.state.viewDetails.status == "Checked Out" && (
                                <React.Fragment>
                                    <li>
                                        <a
                                            onClick={() =>
                                                this.taskSelectedItem(this.state.viewDetails)
                                            }
                                            href="#addingTask"
                                            data-toggle="modal"
                                        >
                                            <i className="fa fa-check-square"></i>Add Task
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            onClick={() => this.setState({menuState: false})}
                                            href="#addNote"
                                            data-toggle="modal"
                                        >
                                            <i className="fa fa-sticky-note-o"></i>Edit Note
                                        </a>
                                    </li>
                                </React.Fragment>
                            )}
                            {this.state.viewDetails.status == "Booked" && (
                                <li>
                                    <a
                                        onClick={() => this.openCancelModal()}
                                        href="#cancelAppt"
                                        data-toggle="modal"
                                    >
                                        <i className="fa fa-trash-o"></i>Cancel
                                    </a>
                                </li>
                            )}
                            {this.state.viewDetails.status == "Checked In" && (
                                <li>
                                    <a
                                        onClick={() => this.openCancelModal()}
                                        href="#cancelAppt"
                                        data-toggle="modal"
                                    >
                                        <i className="fa fa-trash-o"></i>Cancel
                                    </a>
                                </li>
                            )}

                            {this.state.viewDetails.bookedTime && (
                                <li className="bookedBy">
                                    Booked By{" "}
                                    <span className="text-primary">
                    {this.state.viewDetails.createUser.names.nick}
                  </span>
                                    <br/>{" "}
                                    {helperFunctions.formatDateTimeWithDay(
                                        moment(this.state.viewDetails.bookedTime),
                                        "latest"
                                    )}
                                </li>
                            )}
                        </ul>
                    ) : (
                        ""
                    )}
                    {this.state.cartAdded != "" && (
                        <div
                            data-target="#cartModal"
                            data-toggle="modal"
                            className="cart-label"
                        >
                            <div
                                className="alert alert-warning alert-dismissible"
                                role="alert"
                            >
                                <div className="cart-techs">
                                    {this.state.cartCollections && (
                                        <img
                                            width="70"
                                            src={this.findTechnicianImage(
                                                this.state.cartCollections[0].visitTechnicians[0]
                                                    .technician.id
                                            )}
                                        />
                                    )}
                                </div>

                                {this.state.cartAdded == "added" && (
                                    <strong>
                                        Added{" "}
                                        <span className="counts text-danger">
                      {this.state.cartCollections.length}
                    </span>{" "}
                                        item{this.state.cartCollections.length < 2 ? "" : "s"} in
                                        the cart
                                    </strong>
                                )}
                                {this.state.cartAdded == "exist" && (
                                    <strong>Already exist in the cart </strong>
                                )}
                                <div className="odometer">
                                    <strong>Cart Total : </strong>
                                    <span className="text-danger">
                    <Odometer
                        duration={1000}
                        value={this.state.totalCharge}
                        format="(,ddd).ddd"
                    />
                  </span>
                                </div>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="alert"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>
                    )}
                    <Footer/>

                    <div
                        className="modal modal-appoinments in full"
                        id="appointmentsModal"
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLabel1"
                    >
                        <div className="modal-dialog" role="document">
                            <div className="modal-content" data-height="auto">
                                <div className="modal-body">
                                    <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">×</span>
                                    </button>
                                    <div className="white-box">
                                        <h3 className="box-title pointer">
                                            Appointments Today
                                            {this.state.cartCollections.length > 0 && (
                                                <a
                                                    data-target="#cartModal"
                                                    data-toggle="modal"
                                                    className="pull-right"
                                                >
                                                    <i className="icon-basket"></i>

                                                    <label className="item-count">
                                                        {this.state.cartCollections.length}
                                                    </label>
                                                </a>
                                            )}
                                        </h3>
                                        <div className="table-responsive">
                                            <table className="table color-table info-table">
                                                <thead>
                                                <tr className="center">
                                                    <th>Type</th>
                                                    <th>Status</th>
                                                    <th>Name</th>
                                                    <th>Time</th>
                                                    <th>Appreciation</th>
                                                    <th>Technicians</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {appointmentsTodayData.length == 0 && (
                                                    <tr>
                                                        <td colSpan="6" className="center">
                                                            {" "}
                                                            No Appointments scheduled today
                                                        </td>
                                                    </tr>
                                                )}
                                                {appointmentsTodayData.length > 0 &&
                                                    appointmentsTodayData.map((a, k) => {
                                                        return (
                                                            <tr
                                                                className={
                                                                    this.state.keyId == k
                                                                        ? "active-context"
                                                                        : ""
                                                                }
                                                                key={k}
                                                                onContextMenu={() =>
                                                                    this.contextMenus(event, "full", a, k)
                                                                }
                                                            >
                                                                <td>
                                                                    <i
                                                                        className={
                                                                            a.requestSource == "Walk In"
                                                                                ? "mdi mdi-run text-warning"
                                                                                : a.requestSource == "Phone Call"
                                                                                    ? "fa fa-phone text-danger"
                                                                                    : a.requestSource == "Android App"
                                                                                        ? "fa fa-android text-purple"
                                                                                        : a.requestSource == "iOS App"
                                                                                            ? "fa fa-mobile text-purple"
                                                                                            : a.requestSource == "Website"
                                                                                                ? "fa fa-desktop"
                                                                                                : ""
                                                                        }
                                                                    ></i>
                                                                </td>
                                                                <td className="txt-oflo">
                                                                    <a style={{cursor: 'pointer'}}>
                                                                        {a.unfinalizedAppearanceRating == "1" ? (
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-lg mt0 btn-act btn-circle"
                                                                            ></button>
                                                                        ) : a.unfinalizedAppearanceRating ==
                                                                        "2" ? (
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-lg mt0 btn-warning btn-circle"
                                                                            ></button>
                                                                        ) : a.unfinalizedAppearanceRating ==
                                                                        "3" ? (
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-lg mt0 btn-danger btn-circle"
                                                                            ></button>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </a>
                                                                </td>
                                                                <td className="txt-oflo">
                                                                    <Link
                                                                        to={
                                                                            `/${this.storeId}` +
                                                                            "/update-customer?custId=" +
                                                                            a.client.id
                                                                        }
                                                                    >
                                                                        <b>
                                                                            {a.client.names.first +
                                                                                " " +
                                                                                a.client.names.last[0] +
                                                                                "."}
                                                                        </b>
                                                                    </Link>
                                                                </td>
                                                                <td className="txt-oflo">
                                                                    <a style={{cursor: 'pointer'}}>
                                                                        <b>
                                                                            {a.status == "Booked"
                                                                                ? helperFunctions.formatDateTime(
                                                                                    a.bookedTime,
                                                                                    "time"
                                                                                )
                                                                                : helperFunctions.formatDateTime(
                                                                                    a.period.from,
                                                                                    "time"
                                                                                )}
                                                                        </b>
                                                                    </a>
                                                                </td>
                                                                <td className="txt-oflo">
                                                                    <b>{a.appreciation}</b>
                                                                </td>
                                                                <td>
                                                                    {a.visitTechnicians.map((t, k) => {
                                                                        return (
                                                                            <img
                                                                                key={k}
                                                                                src={this.findTechnicianImage(
                                                                                    t.technician.id
                                                                                )}
                                                                                alt="user"
                                                                                className="img-circle img-small-circle"
                                                                            />
                                                                        );
                                                                    })}
                                                                    {this.checkCartStatus(a.id) && (
                                                                        <React.Fragment>
                                                                            {" "}
                                                                            &nbsp;{" "}
                                                                            <i className="fa fa-check custom-text-info"></i>
                                                                        </React.Fragment>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        className="modal fade"
                        id="checkoutCustomer"
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="checkoutcustomerlabel"
                    >
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    <h4 className="modal-title" id="checkoutcustomerlabel">
                                        Check out customer
                                    </h4>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group col-sm-12">
                                        <label
                                            htmlFor="serviceChargeAmount"
                                            className="col-sm-5 control-label"
                                        >
                                            Service Charge Amount
                                        </label>
                                        <div className="col-sm-7">
                                            <input
                                                type="number"
                                                name="serviceChargeAmount"
                                                value="0"
                                                onChange={this.handleChange}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="form-group col-sm-12">
                                        <label
                                            htmlFor="productPurchaseAmount"
                                            className="col-sm-5 control-label"
                                        >
                                            Product Purchase Amount
                                        </label>
                                        <div className="col-sm-7">
                                            <input
                                                type="number"
                                                name="productPurchaseAmount"
                                                value="0"
                                                onChange={this.handleChange}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="form-group col-sm-12">
                                        <label
                                            htmlFor="tipAmount"
                                            className="col-sm-5 control-label"
                                        >
                                            Tip Amount:
                                        </label>
                                        <div className="col-sm-7">
                                            <input
                                                type="number"
                                                name="tipAmount"
                                                value="0"
                                                onChange={this.handleChange}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className="col-sm-12 form-group ">
                                        <label
                                            htmlFor="totalCharge"
                                            className="col-sm-5 control-label"
                                        >
                                            Total Charge Amount
                                        </label>
                                        <div className="col-sm-7">
                                            <input
                                                type="number"
                                                name="totalCharge"
                                                onChange={this.handleChange}
                                                value="0"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <hr/>
                                    <br/>
                                    <div className="form-group col-sm-12">
                                        <label
                                            htmlFor="rewardDeduction"
                                            className="col-sm-5 control-label"
                                        >
                                            Reward Deduction Amount
                                        </label>
                                        <div className="col-sm-7">
                                            <input
                                                type="number"
                                                name="rewardDeduction"
                                                onChange={this.handleChange}
                                                value="0"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="form-group col-sm-12">
                                        <label htmlFor="credit" className="col-sm-5 control-label">
                                            Credit Paid Amount
                                        </label>
                                        <div className="col-sm-7">
                                            <input
                                                type="number"
                                                name="credit"
                                                onChange={this.handleChange}
                                                value="0"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="form-group col-sm-12">
                                        <label htmlFor="check" className="col-sm-5 control-label">
                                            Check Paid Amount
                                        </label>
                                        <div className="col-sm-7">
                                            <input
                                                type="number"
                                                name="check"
                                                onChange={this.handleChange}
                                                value="0"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="form-group col-sm-12">
                                        <label htmlFor="cash" className="col-sm-5 control-label">
                                            Cash Paid Amount
                                        </label>
                                        <div className="col-sm-7">
                                            <input
                                                type="number"
                                                name="cash"
                                                onChange={this.handleChange}
                                                value="0"
                                                className="form-control"
                                                id="Notes"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group col-sm-12">
                                        <label htmlFor="balance" className="col-sm-5 control-label">
                                            Balance
                                        </label>
                                        <div className="col-sm-7">
                                            <input
                                                disabled
                                                type="number"
                                                name="cash"
                                                onChange={this.handleChange}
                                                value="0"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group col-sm-12">
                                        <label htmlFor="notes" className="col-sm-5 control-label">
                                            Notes
                                        </label>
                                        <div className="col-sm-7">
                      <textarea
                          rows="4"
                          onChange={this.handleChange}
                          name="notes"
                          className="form-control"
                          id="Notes"
                      />
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-custom-info"
                                        onClick={() => this.checkOutCustomer()}
                                    >
                                        Check Out
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        data-backdrop="static"
                        data-keyboard="false"
                        className="modal fade"
                        id="checkin"
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLabel1"
                    >
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button
                                        onClick={this.resetStateWithModalClose}
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    <h4 className="modal-title" id="exampleModalLabel1">
                                        Check In
                                    </h4>
                                </div>

                                <div className="modal-body">
                                    <CheckIn
                                        singleAppt={this.state.editApptDetails}
                                        appointmentCheckIn={this.appointmentCheckIn}
                                    />
                                </div>
                                {this.state.loading == true && (
                                    <div className="custom-loading">
                                        <div className="loading">
                                            <i className="fa fa-spinner fa-spin"></i>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <EditCart
                        singleAppt={this.state.editApptDetails}
                        updateStaffNotes={this.updateStaffNotes}
                        confirmAppt={this.confirmAppt}
                        apptAddUpdateService={this.apptAddUpdateService}
                        cancelServieFromAppt={this.cancelServieFromAppt}
                        checkServiceIn={this.checkServiceIn}
                        UndocheckServiceIn={this.UndocheckServiceIn}
                        checkServiceOut={this.checkServiceOut}
                        updateBookingTime={this.updateBookingTime}
                        saveProduct={this.saveProduct}
                        deleteProduct={this.deleteProduct}
                        loading={this.state.loading}
                        refreshServiceSearch={this.refreshServiceSearch}
                        place={this.state.place}
                        checkOut={this.state.checkOut}
                        apptCheckout={this.apptCheckout}
                    />

                    <div
                        data-backdrop="static"
                        data-keyboard="false"
                        className="modal"
                        id="viewDetails"
                    >
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button
                                        onClick={this.resetStateWithModalClose}
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">×</span>
                                    </button>
                                    <h2 className="modal-title" id="cancelLabelModel">
                                        View Details
                                    </h2>
                                </div>
                                <div className="modal-body">
                                    <ViewDetails
                                        singleAppt={this.state.editApptDetails}
                                        updateStaffNotes={this.updateStaffNotes}
                                        confirmAppt={this.confirmAppt}
                                        apptAddUpdateService={this.apptAddUpdateService}
                                        cancelServieFromAppt={this.cancelServieFromAppt}
                                        checkServiceIn={this.checkServiceIn}
                                        UndocheckServiceIn={this.UndocheckServiceIn}
                                        checkServiceOut={this.checkServiceOut}
                                        updateBookingTime={this.updateBookingTime}
                                    />
                                </div>

                                {this.state.loading == true && (
                                    <div className="custom-loading">
                                        <div className="loading">
                                            <i className="fa fa-spinner fa-spin"></i>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Checkout Modal */}
                    <div className="modal" id="cartModal">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">×</span>
                                    </button>
                                    <h4 className="modal-title" id="cancelLabelModel">
                                        Check Out Appointment
                                    </h4>
                                </div>

                                {this.state.cartCollections.length > 0 && (
                                    <GroupCart
                                        taxAmount={this.state.taxAmount}
                                        tipAmount={this.state.tipAmount}
                                        productPurchaseAmount={this.state.productPurchaseAmount}
                                        serviceChargeAmount={this.state.serviceChargeAmount}
                                        total={this.state.totalCharge}
                                        editCartFromCollection={this.editCartFromCollection}
                                        removeFromCart={this.removeFromCart}
                                        discountDeductionAmount={this.state.discountDeductionAmount}
                                        rewardDeductionAmount={this.state.rewardDeductionAmount}
                                        creditPaidAmount={this.state.creditPaidAmount}
                                        cashPaidAmount={this.state.cashPaidAmount}
                                        giftCardPaidAmount={this.state.giftCardPaidAmount}
                                        placeGroupCheckOut={this.placeGroupCheckOut}
                                        cartCollections={this.state.cartCollections}
                                    />
                                )}
                            </div>
                        </div>
                    </div>

                    <div
                        data-backdrop="static"
                        data-keyboard="false"
                        className="modal"
                        id="checkOutModal"
                    >
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button
                                        onClick={this.resetStateWithModalClose}
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">×</span>
                                    </button>
                                    <h4 className="modal-title" id="cancelLabelModel">
                                        Check Out
                                    </h4>
                                </div>

                                <div className="modal-body">
                                    <div className="row">
                                        <div>
                                            <CheckOut
                                                checkoutDetails={this.state.editApptDetails}
                                                proccessCheckOut={this.proccessCheckOut}
                                                apptCheckout={this.apptCheckout}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {this.state.loading == true && (
                                    <div className="custom-loading">
                                        <div className="loading">
                                            <i className="fa fa-spinner fa-spin"></i>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div
                        data-backdrop="static"
                        data-keyboard="false"
                        className="modal fade"
                        id="addingTask"
                        tabIndex="-1"
                        role="dialog"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button
                                        onClick={this.closeAddTaskModal}
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    <h4 className="modal-title">Add Task</h4>
                                </div>
                                <div className="modal-body">
                                    <AddTask
                                        statas={this.state.status}
                                        predefinedRelatedItem={
                                            this.state.taskSelected.taskSelectedClient
                                        }
                                        predefinedRelatedId={
                                            this.state.taskSelected.taskSelectedItemId
                                        }
                                        predefinedRelatedTo={this.state.predefinedRelatedTo}
                                        mode={this.state.mode}
                                        saveTask={this.saveTask}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        data-backdrop="static"
                        data-keyboard="false"
                        className="modal fade"
                        id="editTask"
                        tabIndex="-1"
                        role="dialog"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button
                                        onClick={this.closeUpdateModal}
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    <h4 className="modal-title">Update Task</h4>
                                </div>

                                <div>
                                    <div className="modal-body">
                                        <EditTask
                                            title={this.state.task.subject}
                                            comments={this.state.task.comments}
                                            status={this.state.task.status}
                                            dueTime={this.state.task.dueTime}
                                            priority={this.state.task.priority}
                                            predefinedRelatedItem={this.state.task.relatedItem}
                                            predefinedRelatedId={
                                                this.state.taskSelected.taskSelectedItemId
                                            }
                                            predefinedRelatedTo={this.state.task.relatedItemType}
                                            assignedto={this.state.task.technician}
                                            mode="edit"
                                            createUser={this.state.task.createUser}
                                            createTime={this.state.task.createTime}
                                            lastUpdateUser={this.state.task.lastUpdateUser}
                                            updatedTime={this.state.task.updatedTime}
                                            updateTask={this.updateTask}
                                            selectedId={this.state.task.selectedId.id}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        data-backdrop="static"
                        data-keyboard="false"
                        className="modal"
                        id="addNote"
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLabel1"
                    >
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button className="btn close" data-dismiss="modal">
                                        &times;
                                    </button>
                                    <h3>Edit Note</h3>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label>
                                            Write a note here: &nbsp;{" "}
                                            <span className="text-danger">*</span>
                                        </label>
                                        <textarea
                                            onChange={this.handleChange}
                                            required
                                            maxLength="1000"
                                            className="form-control height-200"
                                            value={
                                                this.state.staffNotes != ""
                                                    ? this.state.staffNotes
                                                    : this.state.viewDetails.staffNotes
                                            }
                                            name="staffNotes"
                                        ></textarea>
                                    </div>
                                </div>

                                <div className="modal-footer">
                                    <button
                                        onClick={this.saveNote}
                                        type="submit"
                                        className="btn btn-custom-info"
                                    >
                                        Update Note
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        data-backdrop="static"
                        data-keyboard="false"
                        className="modal"
                        id="cancelAppt"
                    >
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button
                                        onClick={this.resetStateWithModalClose}
                                        className="btn close"
                                        data-dismiss="modal"
                                    >
                                        &times;
                                    </button>
                                    <h3>Cancellation Reason</h3>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label>
                                            Reason: &nbsp; <span className="text-danger">*</span>
                                        </label>
                                        <textarea
                                            onChange={this.handleChange}
                                            required
                                            maxLength="1000"
                                            className="form-control height-200"
                                            value={this.state.cancelReason}
                                            name="cancelReason"
                                        ></textarea>
                                    </div>

                                    <div className="switcher-spacer"></div>
                                    <div className="switcher-spacer"></div>

                                    <div className="form-group text-right">
                                        <button
                                            onClick={() => this.cancelAppt()}
                                            type="submit"
                                            className="btn btn-custom-info"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                                {this.state.loading == true && (
                                    <div className="custom-loading">
                                        <div className="loading">
                                            <i className="fa fa-spinner fa-spin"></i>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    checkCartStatus = (id) => {
        const cartStatus = this.state.cartCollections.find((c) => c.id == id);
        if (cartStatus) {
            return true;
        } else {
            return false;
        }
    };

    findTechnicianImage(technicianId) {
        let technician = this.app.locations[0].technicians.find(
            (technician) => technician.id == technicianId
        );
        return technician.user.imageUrl;
    }

    getTechnicianNames(technicians) {
        var techNamesList = [];
        technicians.forEach((visitTech) => {
            var techName = this.findTechnicianName(visitTech.technician.id);
            if (techNamesList.indexOf(techName) == -1) {
                techNamesList.push(techName);
            }
        });

        return techNamesList.join(", ");
    }

    findTechnicianName(technicianId) {
        // eslint-disable-next-line
        let technician = this.app.locations[0].technicians.find(
            (technician) => technician.id == technicianId
        );
        return technician.user.names.nick;
    }

    showLastVisitPopup(user) {
        var data = {
            fullName: user.fullName,
            mobileNumber: helperFunctions.formatMobilePhone(user.mobileNumber),
            apptTime: helperFunctions.formatDateTime(user.lastVisit.bookedTime),
            requestSource: user.lastVisit.requestSource,
            technicians: this.getTechnicianNames(user.lastVisit.visitTechnicians),
            visitId: user.lastVisit.id,
        };
        window.reservationDetailAlert(data, this.storeId);
    }

    getRequestSource(reqSrc) {
        let src = "";
        src =
            reqSrc == "Phone Call"
                ? "fa fa-phone text-warning custom mb0"
                : reqSrc == "Walk In"
                    ? "mdi mdi-run custom mb0"
                    : reqSrc == "Android App"
                        ? "fa fa-android text-purple custom"
                        : reqSrc == "iOS App"
                            ? "fa fa-mobile text-purple custom"
                            : reqSrc == "Website"
                                ? "fa fa-desktop custom"
                                : reqSrc == "iPad App"
                                    ? "fas fa-tablet custom"
                                    : reqSrc == "Tablet App"
                                        ? "mdi mdi-tablet-android custom"
                                        : "";

        return src;
    }

    showCurrentVisitPopup(visit) {
        var data = {
            fullName: visit.client.fullName,
            mobileNumber: helperFunctions.formatMobilePhone(
                visit.client.mobileNumber
            ),
            apptTime: helperFunctions.formatDateTime(visit.bookedTime),
            requestSource: this.getRequestSource(visit.requestSource),
            technicians: this.getTechnicianNames(visit.visitTechnicians),
            customerNotes: visit.client.customerNotes
                ? visit.client.customerNotes
                : "NONE",
            rewardBalance: visit.client.rewardBalance,
            standingAppointment: visit.standingAppointment
                ? '<i className="fa fa-check text-success"></i>'
                : '<i className="fa fa-times text-danger"></i>',
            visitId: visit.id,
        };

        window.reservationDetailAlert(data, this.storeId);
    }

    getrelatedTypeImage = (relatedItem, task) => {
        if (
            relatedItem == "Staff" ||
            relatedItem == "Customer" ||
            relatedItem == "Appointment" ||
            relatedItem == "Product" ||
            relatedItem == "Payroll" ||
            relatedItem == "Service" ||
            relatedItem == "Other"
        ) {
            return task.technician && task.technician.user.imageUrl;
        }
        if (relatedItem == "Unconfirmed Appointments") {
            return appt;
        }
        if (relatedItem == "Exhausted Standings") {
            return emptyappt;
        }
        if (relatedItem == "Exhausted Products") {
            return emptypr;
        }
    };
    getRelatedItemImage = (relatedItem, task) => {
        if (relatedItem == "Unconfirmed Appointments") {
            return appt;
        }
        if (relatedItem == "Exhausted Standings") {
            return emptyappt;
        }
        if (relatedItem == "Exhausted Products") {
            return emptypr;
        }
        if (relatedItem == "Staff") {
            return task.relatedItem && task.relatedItem.user.imageUrl;
        }

        if (relatedItem == "Customer") {
            return task.relatedItem && task.relatedItem.imageUrl;
        }
        if (relatedItem == "Appointment") {
            return appt;
        }
        if (relatedItem == "Product") {
            return pr;
        }
        if (relatedItem == "Payroll") {
            return pr;
        }
        if (relatedItem == "Service") {
            return serviceicon;
        }
        if (relatedItem == "Other") {
            return other;
        }
    };

    nextTodo = () => {
        var offset = this.state.pageOffset + 1;
        const {tasks} = this.state;

        if (tasks && tasks.length == 7) {
            this.getAllTasks(offset);
            this.setState({
                ...this.state,
                pageOffset: offset,
            });
        }
    };
    prevTodo = () => {
        var offset = this.state.pageOffset - 1;
        if (offset >= 0) {
            this.getAllTasks(offset);
            this.setState({
                ...this.state,
                pageOffset: offset,
            });
        }
    };

    findServiceName(serviceId, categoryId) {
        const {categories} = this.state;
        // eslint-disable-next-line
        let category = categories.find((sc) => sc.id == categoryId);
        if (category == null) return "Service not found";
        let service = category.services.find((s) => s.id == serviceId);
        if (service == null) return "Service not found";
        return service.name;
    }

    findTechnician(technicianId, query) {
        // eslint-disable-next-line
        let technician = this.app.locations[0].technicians.find(
            (technician) => technician.id == technicianId
        );

        if (technician) {
            if (query == "image") {
                return technician.user.imageUrl;
            } else if (query == "active") {
                if (technician.active == "true") {
                    return "active";
                }
            } else if (query == "nick") {
                return technician.user.names.nick;
            } else if (query == "clientId") {
                return technician.user.id;
            } else {
                return technician.user.fullName;
            }
        } else {
            return "";
        }
    }
}

function mapStateToProps(state) {
    const {customers, tasks} = state.customers;
    const {user} = state.authentication;
    const {reloadData} = state.Communication;
    return {
        customers,
        tasks,
        reloadData,
        user,
    };
}

const connectedDashboardPage = connect(mapStateToProps)(DashboardPage);
export {connectedDashboardPage as DashboardPage};
