/* eslint-disable */
import React from 'react';
import SlickCarousel from '../slick-carousel/SilckCarousel'
const VariantItem = ({ variant, openEditForm, deleteFromProduct }) => {

  const deleteHandler = (type, index = undefined) => {
    Swal.fire({
      title: "Warning",
      text: `Are you sure to delete this item?`,
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then(async result => {
      if (result.value) {
        deleteFromProduct(type, variant, index);
      }
    });
  }

  return (
    <div className="col-md-6">

      <div class="card">
        {variant.imageUrls.length > 0 ? <SlickCarousel removeImage={deleteHandler} images={variant.imageUrls} />
          : <div className="no-image" style={{ width: '150px', margin: 'auto' }}><img className="img-responsive" src="/assets/images/no_image.jpg" /></div>}
        <div class="card-body">
          <div className="variants">
            <div className="variant-details d-flex">
              <span><strong>{variant.visualAttributeValues[0].attribute.toUpperCase()}:</strong> 30mgl</span>
              <span><strong>UPC: </strong>{variant.upc}</span>
            </div>
            <div className="variant-details d-flex">
              <span><strong>MSRP: </strong>${variant.msrp.toFixed(2)}</span>
              <span><strong>S.PRICE: </strong>${variant.salePrice.toFixed(2)}</span>
            </div>
            <div className="variant-details d-flex">
              <span><strong>QTY. </strong>{variant.quantity}</span>
            </div>
          </div>
          <br />

          <div className="variant-footer d-flex">
            <button onClick={() => deleteHandler('variant')} className="btn btn-danger" style={{ marginRight: '10px' }}><i className="fa fa-trash"></i> Delete</button>
            <button onClick={() => openEditForm('variantOnly', variant)} className="btn btn-primary" style={{ marginRight: '10px' }}><i className="fa fa-pencil"></i> Edit</button>

            <button onClick={() => openEditForm('imageOnly', variant)} className="btn btn-info"><i className="fa fa-plus"></i> Edit Images</button>
          </div>

        </div>
      </div>
    </div>

  )
}

export default VariantItem;
