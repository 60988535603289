/* eslint-disable */
import {authHeader} from '../_helpers';
import {Config} from '../config';

var config = Config()
const apiPath = config.serverUrl;

export const userService = {
    login,
    logout,
    register,
    updateProfile,
    saveNote,
    updateProfilePic,
    updatePassword,
    resetPassword,
    getUserInfo,
    searchCustomers,
    getAllTechnicians,
    getTechniciansSchedule,
    getCustomerInfo,
    getTechnicianInfo,
    deleteCustomer,
    updateCustomerProfile,
    updateCustomerPassword,
    updateCustomerRewardBalance,
    getCustomerFormulas,
    saveFormulData,
    updateFormulData,
    deleteFormula,
    getAllRewards,
    getSpecificCustomer,
    processMerge,
    saveTask,
    getAllTask,
    updateStaffHours,
    updateStaffInfos,
    saveStaffInfos,
    saveStaffHours,
    saveCommissionScale,
    updateCommissionScale,
    deleteCommissionScale,
    getAllTechniciansForCalendarReservation,
    getAllActivities,
    getSpecificTask,
    updateTask,
    saveApptStaffNotes,
    getTechSalesData,
    getTechHoursData,
    addNewTimeCard,
    updateBreakTime,
    deleteBreakPoint,
    updateTimeCard,
    makeitApproved,
    deleteTimeCard,
    deleteTask,
    getHTMLString,
    saveConsentForm,
    getAllAnswers,
    getConsent,
    getTwillioReady,
    calling,
    loadCommunication,
    sendTextMessage,
    getCustomerInfoForCall,
    getCompletedForms,
    viewCompletedFormById,
    deleteCompletedForm,
    changeOrder,
    markAsContacted,

};


function headers() {
    let user = JSON.parse(localStorage.getItem('user'));

    if (user && user.token) {
        return {'X-Auth-Token': user.token, 'Content-Type': 'multipart/form-data', 'X-Xsrf-Token': user.csrfToken};
    } else {
        return {};
    }
}

function saveConsentForm(fid, cid, datas) {
    let data = new FormData()
    //data.append('imgFile',blob);
    data.append('img', datas);
    const requestOptions = {
        method: 'POST',
        headers: headers(),
        credentials: 'include',
        crossDomain: true,
        mimeType: "multipart/form-data",
        processData: false,
        contentType: false,
        body: data
    };
    delete requestOptions.headers["Content-Type"];
    return fetch(apiPath + 'user-consent-forms?formId=' + fid + '&usrId=' + cid, requestOptions).then(handleResponse);
}

function getCompletedForms(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include'

    };
    return fetch(apiPath + 'user-consent-forms?usrId=' + id, requestOptions).then(handleResponse);

}

function viewCompletedFormById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include'

    };
    return fetch(apiPath + 'user-consent-forms/' + id, requestOptions).then(handleResponse);

}

function deleteCompletedForm(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include'

    };
    return fetch(apiPath + 'user-consent-forms/' + id, requestOptions).then(response => {
        return response.text()
    });

}


function getTwillioReady(bsnId) {

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify({page: window.location.pathname})

    };
    return fetch(apiPath + 'communications/token?bsnsId=' + bsnId, requestOptions).then(handleResponse);

}

function loadCommunication(types, directions, status, to, from, page, bsnId) {

    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include'

    };
    //userId={}&types={,}&directions={,}&statuses={,}&page={}&pageCount={}
    var extensions = 'To=' + to
    if (types.length > 0) {
        extensions += '&types=' + types
    }
    if (directions.length > 0) {
        extensions += '&directions=' + directions
    }
    if (status.length > 0) {
        extensions += '&statuses=' + status
    }
    if (from != '') {
        extensions += '&From=' + from
    }
    if (bsnId != '') {
        extensions += '&bsnsId=' + bsnId
    }

    extensions += '&page=' + page + '&pageCount=' + 10

    return fetch(apiPath + 'communications?' + extensions, requestOptions).then(handleResponse);

}


function sendTextMessage(to, from, message) {
    let requestOptions = {
        headers: authHeader(),
        credentials: 'include',
        method: 'POST'

    };

    return fetch(apiPath + 'communications/sms?To=' + to + '&From=' + from + '&Body=' + message, requestOptions).then(function (response) {
        return response
    })

}

function calling(to, from) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify({})

    };
    return fetch(apiPath + 'communications/call?To=' + to + '&From=' + from + '&Outbound=true', requestOptions).then(handleTweillioResponse);

}

function getConsent() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',
    };
    return fetch(apiPath + 'user-consent-forms/1047', requestOptions).then(handleResponse);
}


// function savePdf(formData){
//     const requestOptions = {
//         method: 'POST',
//         headers: headers(),
//         credentials:'include',
//         body: formData,
//         mode: 'no-cors',
//         contentType: false,
//         processData: false
//     };
//     return fetch(apiPath + 'user-consent-forms', requestOptions).then(handleResponse);
// }

function getAllTechniciansForCalendarReservation(type, offset, searchVal) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',
    };
    var queryParams = "type=" + type + "&locId=" + JSON.parse(localStorage.getItem('app')).locations[0].id + "&page=" + offset;
    if (searchVal != undefined)
        queryParams += "&searchVal=" + searchVal
    return fetch(apiPath + 'technicians?' + queryParams, requestOptions).then(handleResponse);
}

function getAllAnswers(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include'
    };

    return fetch(apiPath + 'user-persona-responses?usrId=' + id, requestOptions).then(handleResponse);

}

function getTechSalesData(id, start, end) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',
    };
    const locId = JSON.parse(localStorage.getItem('app')).locations[0].id
    return fetch(apiPath + 'visits/sales?locId=' + locId + '&techId=' + id + '&start=' + start + '&end=' + end, requestOptions).then(handleResponse);
}

function getHTMLString(numbers) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include'
    };

    return fetch(apiPath + 'consent-forms/' + numbers, requestOptions).then(handleHtmlString);
}


function getTechHoursData(id, start, end, type) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include'
    };
    const locId = JSON.parse(localStorage.getItem('app')).locations[0].id

    return fetch(apiPath + 'time-cards?locId=' + locId + '&techId=' + id + '&start=' + start + '&end=' + end + '&type=' + type, requestOptions).then(handleResponse);
}

function addNewTimeCard(endPoint, data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(data)
    };
    return fetch(apiPath + endPoint, requestOptions).then(handleResponse);
}

function updateTimeCard(id, data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(data)
    };
    return fetch(apiPath + 'time-cards/' + id, requestOptions).then(handleResponse);
}

function changeOrder(id, order) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(order)
    };
    return fetch(apiPath + 'new-technicians/' + id + '/order', requestOptions).then(handleResponse);
}

async function markAsContacted(userId) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: {}
    };
    try {
        return await fetch(apiPath + `users/${userId}/mark-contacted`, requestOptions);
    } catch (e) {
        return e
    }

}

function updateBreakTime(id, data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(data)
    };

    return fetch(apiPath + 'time-card-breaks/' + id, requestOptions).then(handleResponse);
}

function deleteBreakPoint(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include'
    };

    return fetch(apiPath + 'time-card-breaks/' + id, requestOptions).then(handleDeleteResponse);
}

function makeitApproved(id) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify({})
    };

    return fetch(apiPath + 'time-cards/' + id + '/status', requestOptions).then(handleResponse);
}

function deleteTimeCard(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include'
    };

    return fetch(apiPath + 'time-cards/' + id, requestOptions).then(handleDeleteResponse);
}

function login(username, password, businessId, storeId) {

    //generating auth hash for sending login request
    const hash = new Buffer(`${businessId}_${username}:${password}`).toString('base64');

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${hash}`,
            'X-Requested-With': 'XMLHttpRequest'
        },
        credentials: 'include',
        crossDomain: true,
    };

    //console.log(apiPath+'users/token', requestOptions)

    return fetch(apiPath + 'users/token', requestOptions)
        .then(response => {
            if (!response.ok) {
                return Promise.reject("Username or Password is wrong.");
            }
            return response.json();
        })
        .then(auth => {
            //console.log('AUTH', auth)
            if (auth && auth.sessionId) {
                const getUserRequestOptions = {
                    method: 'GET',
                    headers: {
                        'X-Auth-Token': auth.sessionId,
                        'X-Xsrf-Token': auth.csrf.token,
                        'X-Requested-With': 'XMLHttpRequest',
                        'Content-Type': 'application/json'
                    },
                    credentials: 'include'
                };


                localStorage.setItem(storeId + "_sessionId", auth.sessionId)
                localStorage.setItem(storeId + "_csrf", auth.csrf.token)

                return fetch(apiPath + 'users/0', getUserRequestOptions).then(response => {
                    if (!response.ok) {
                        return Promise.reject("Username or Password is wrong.");
                    }
                    return response.json();
                }).then(user => {
                    user.csrfToken = auth.csrf.token;
                    user.token = auth.sessionId;
                    user.loggedIn = true;
                    localStorage.setItem(storeId + "_user", JSON.stringify(user));
                    localStorage.setItem('user', JSON.stringify(user));
                    if (user.role == "USER")
                        return Promise.reject("User does not have permission to system. Please contact the store manager.")
                    else
                        return user;
                })
            } else {
                return auth;
            }

        });
}

function saveTask(taskJSON) {

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(taskJSON)
    };
    return fetch(apiPath + 'tasks', requestOptions).then({});

}

function updateTask(id, taskJSON) {
    //console.log(taskJSON)
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(taskJSON)
    };
    return fetch(apiPath + 'tasks/' + id, requestOptions).then(handleResponse);
}

function deleteTask(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify({})
    };
    return fetch(apiPath + 'tasks/' + id, requestOptions).then(handleDeleteResponse);
}

function logout(businessId) {
    // remove user from local storage to log user out

}

function getUserInfo() {
    const getUserRequestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include'
    };
    return fetch(apiPath + 'users/0', getUserRequestOptions).then(response => {

        if (!response.ok) {
            if (response.status == 401 || response.status == 403)
                //history.pushState({urlPath:'/page2.php'},"",'/page2.php')
                history.pushState(`/${window.location.href}/login`, '')
            localStorage.removeItem('user')
            return Promise.reject(response.statusText);
        }
        return response.json();
    }).then(user => {
        // store user details and token in local storage to keep user logged in between page refreshes
        if (user) {
            let oldUser = JSON.parse(localStorage.getItem('user'))
            user.token = oldUser.token;
            user.csrfToken = oldUser.csrfToken;
            user.loggedIn = true;
            localStorage.setItem('user', JSON.stringify(user));
        }
        return user;
    })
}

function getCustomerInfo(userId) {
    const getUserRequestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include'
    };
    const locId = JSON.parse(localStorage.getItem('app')).locations[0].id;
    return fetch(apiPath + 'users/' + userId + '?locId=' + locId, getUserRequestOptions).then(handleResponse);
}

function getCustomerInfoForCall(userId) {
    const getUserRequestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include'
    };
    return fetch(apiPath + 'users/' + userId, getUserRequestOptions).then(handleResponse);
}


function getCustomerFormulas(userId) {
    const getUserRequestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include'
    };
    return fetch(apiPath + 'user-formulas?usrId=' + userId, getUserRequestOptions).then(handleResponse);
}

function getAllTask(offset) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include'
    };
    var queryParams = "locId=" + JSON.parse(localStorage.getItem('app')).locations[0].id + "&page=" + offset + "&pageCount=" + 7;
    return fetch(apiPath + 'tasks?' + queryParams, requestOptions).then(handleResponse);
}

function getSpecificTask(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include'
    };

    return fetch(apiPath + 'tasks/' + id, requestOptions).then(handleResponse);
}


function getTechnicianInfo(staffId) {
    const getUserRequestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include'
    };
    return fetch(apiPath + 'new-technicians/' + staffId, getUserRequestOptions).then(handleResponse);
}

function saveStaffInfos(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(data)
    };
    return fetch(apiPath + 'new-technicians', requestOptions).then(handleResponse);
}

function saveStaffHours(hours) {
    alert('Please set an endpoint')
}

function updateStaffHours(id, hours) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(hours)
    };
    return fetch(apiPath + 'technician-hours/' + id, requestOptions).then(handleResponse);
}

function updateStaffInfos(id, data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(data)
    };
    return fetch(apiPath + 'new-technicians/' + id, requestOptions).then(handleResponse);
}


function deleteCustomer(userId) {

    const getUserRequestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include'
    };
    return fetch(apiPath + 'users/' + userId, getUserRequestOptions).then(handleDeleteResponse);
}

function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(user)
    };
    return fetch(apiPath + 'users', requestOptions).then(response => {
        if (!response.ok) {
            if (response.status == 401 || response.status == 403) {
                //window.location = `/${window.location.href.split('/')[3]}/login`;
            } else
                throw response
        }
        return response.json();
    });
}

function saveNote(id, note) {

    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(note)
    };
    return fetch(apiPath + 'users/' + id + '/notes', requestOptions).then({});
}

function saveApptStaffNotes(id, note) {
    //console.log(note)

    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(note)
    };
    return fetch(apiPath + 'visits/update/' + id + '/notes', requestOptions).then(handleDeleteResponse);
}

function updateFormulData(data, id) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(data)
    };
    return fetch(apiPath + 'user-formulas/' + id, requestOptions).then({});
}

function deleteFormula(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include'
    };
    return fetch(apiPath + 'user-formulas/' + id, requestOptions).then();
}

function saveFormulData(data) {

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(data)
    };
    return fetch(apiPath + 'user-formulas', requestOptions).then(handleResponse);
}

function updateProfile(user) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(user)
    };

    return fetch(apiPath + 'users/0', requestOptions).then(handleResponse);
}

function processMerge(oid, nid) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include'
    };

    return fetch(apiPath + 'users/' + oid + '/merge/' + nid, requestOptions);

}


function updateCustomerProfile(userId, user) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(user)
    };

    return fetch(apiPath + 'users/' + userId, requestOptions).then(response => {
        if (!response.ok) {
            if (response.status == 401 || response.status == 403) {
                window.location = `/${window.location.href.split('/')[3]}/login`;
            } else
                throw response
        }
        return response.json();
    });
}


function updateProfilePic(file) {
    let data = new FormData()
    data.append('img', file);
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        crossDomain: true,
        mimeType: "multipart/form-data",
        processData: false,
        contentType: false,
        body: data
    };
    delete requestOptions.headers["Content-Type"];
    return fetch(apiPath + 'users/0/picture', requestOptions).then(handleResponse);
}

function updatePassword(password) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(password)
    };

    return fetch(apiPath + 'users/0/password', requestOptions).then({});
}

function updateCustomerPassword(userId, password) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(password)
    };

    return fetch(apiPath + 'users/' + userId + '/password', requestOptions).then({});
}


function updateCustomerRewardBalance(rewardJson) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(rewardJson)
    };

    return fetch(apiPath + 'rewards', requestOptions).then({});
}


function resetPassword(resetEmail) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(resetEmail)
    };

    return fetch(apiPath + 'users/me/password', requestOptions).then(response => {
        if (!response.ok) {
            return Promise.reject(response.status);
        } else {
            return {}
        }
    });
}

function searchCustomers(type, offset, searchVal) {

    //console.log(searchVal);

    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',
    };
    var queryParams = "type=" + type + "&businessId=" + JSON.parse(localStorage.getItem('app')).id + "&page=" + offset + "&pageCount=" + config.recordsPageSize;
    if (searchVal != undefined && searchVal != '') {

        queryParams += "&locId=" + JSON.parse(localStorage.getItem('app')).locations[0].id + "&searchVal=" + searchVal
    }
    return fetch(apiPath + 'users?' + queryParams, requestOptions).then(handleResponse);
}

function getSpecificCustomer(type, offset, searchVal) {

    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',
    };
    var queryParams = "type=" + type + "&businessId=" + JSON.parse(localStorage.getItem('app')).id + "&page=" + offset + "&pageCount=" + config.recordsPageSize;
    if (searchVal != undefined)
        queryParams += "&searchVal=" + searchVal
    return fetch(apiPath + 'users?' + queryParams, requestOptions).then(handleResponse);
}

function getAllRewards(id, type, offset, searchVal) {


    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',
    };
    var queryParams = "type=" + type + "&page=" + offset + "&pageCount=" + config.recordsPageSize;
    if (searchVal != undefined)
        queryParams += "&searchVal=" + searchVal
    return fetch(apiPath + 'rewards?usrId=' + id + '&' + queryParams, requestOptions).then(handleResponse);
}


function getAllTechnicians(offset, team, searchVal) {
    //alert(team)
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',
    };
    var queryParams = "locId=" + JSON.parse(localStorage.getItem('app')).locations[0].id + "&page=" + offset + "&pageCount=" + config.recordsPageSize;
    //alert(queryParams);
    if (team != undefined && team != null && team != '')
        queryParams += "&team=" + team

    if (searchVal != undefined)
        queryParams += "&searchVal=" + searchVal
    return fetch(apiPath + 'new-technicians?' + queryParams, requestOptions).then(handleResponse);
}

function getAllActivities(offset) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include'
    };
    var queryParams = "locId=" + JSON.parse(localStorage.getItem('app')).locations[0].id + "&page=" + offset + "&pageCount=" + config.recordsPageSize;
    return fetch(apiPath + 'activities?' + queryParams, requestOptions).then(handleResponse);

}

function getTechniciansSchedule(id, dateStart, dateEnd) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',
    };
    var dateParams = "&start=" + dateStart + "&end=" + dateEnd;
    var queryParams = "type=tech" + '&locId=' + JSON.parse(localStorage.getItem('app')).locations[0].id + '&techId=' + id + dateParams;
    if (id == "all")
        queryParams = "type=all-tech" + '&locId=' + JSON.parse(localStorage.getItem('app')).locations[0].id + dateParams;
    return fetch(apiPath + 'visits/calendar?' + queryParams, requestOptions).then(handleResponse);

}


function saveCommissionScale(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(data)
    };

    return fetch(apiPath + 'technician-comp-formulas', requestOptions).then(handleResponse);

}

function updateCommissionScale(id, data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(data)
    };

    return fetch(apiPath + 'technician-comp-formulas/' + id, requestOptions).then(handleResponse);

}

function deleteCommissionScale(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include'
    };
    return fetch(apiPath + 'technician-comp-formulas/' + id, requestOptions).then(handleDeleteResponse);
}


function handleDeleteResponse(response) {
    if (!response.ok) {

        if (response.status == 401 || response.status == 403) {
            window.location = `/${window.location.href.split('/')[3]}/login`;
        }
        return Promise.reject(response);
    }
    return {"status": "ok"}


}


function handleHtmlString(response) {

    //console.log(response)

    return response.json()

}

function handleResponse(response) {
    if (!response.ok) {
        if (response.status == 401 || response.status == 403) {
            localStorage.removeItem('user')
            if (window.location.href.split('/').length > 4) {
                window.location = `/${window.location.href.split('/')[3]}/login`;
            } else {
                window.location.reload();
            }
        }

        return Promise.reject(response);

    }


    return response.json();


}

function handleTweillioResponse(response) {

    if (!response.ok) {
        return Promise.reject(response);

    }

    return response.text();


}
