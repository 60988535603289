/* eslint-disable */
import React from 'react';
import {connect} from 'react-redux';
import {userActions} from '../../_actions';
import {inventoryService, userService} from '../../_services';
import {appService} from '../../_services'
import qs from 'qs';
import Footer from '../footer/footer'
import {Link} from 'react-router-dom';
import {helperFunctions} from '../../_helpers'
import 'rc-datetime-picker/dist/picker.min.css';
import moment from 'moment';
import {DatetimePickerTrigger} from 'rc-datetime-picker';

import {DatePickerInput} from 'rc-datepicker';
import StaffSearchBar from "../../_components/staffSearchBar";
import CreatableSelect from 'react-select/creatable';
import InlineMessage from "../../_components/inlineMessage";
import Sorting from "../../_components/sorting";
import utilities from "../../utils";
import './technicianDetail.css'
import _ from "lodash";
import CustomTimePicker from '../../_components/custom-timer-picker'

const format = 'hh:mm a';
const now = moment().hour(0).minute(0);
const today = new Date()

class TechnicianDetailPage extends React.Component {
    constructor(props) {
        super(props);
        this.app = JSON.parse(localStorage.getItem('app'));
        this.canUpdate = false;
        this.storeId = this.app.vanityId
        let thisObj = this;
        var userRole = JSON.parse(localStorage.getItem('user')).role;
        this.MANAGER = userRole == "MANAGER" ? true : false
        this.MAN_DIREC = userRole == "MANAGER" || userRole == "DIRECTOR" || userRole == 'ADMIN' ? true : false;

        const userId = qs.parse(this.props.location.search.replace("?", "")).staffId

        if (userRole === "TECHNICIAN" || userRole === "MANAGER")
            this.canUpdate = true;


        this.getStaffInfo(userId);
        this.state = {
            pageOffset: 0,
            ctgryId: '',
            user: {
                id: '',
                firstName: '',
                lastName: '',
                nickName: '',
                email: '',
                mobileNumber: '',
                gender: '',
                birthDate: '',
                emailNotification: true,
                smsNotification: false,
                customerBookingAllowed: false,
                clockReminderEnabled: false,
                profilePic: '',
                nextBirthdayRewardDate: '',
                rewardBalance: 0,
                referralToken: '',
                totalEarningsAsReferrer: '',
                instagramUrl: '',
                accountStatus: '',
                representationColor: '',
                team: '',
                role: '',
                expertise: '',
                biography: '',
                specialities: [],
                formulas: '',
                technicianServices: [],
                productCommissionPercentage: '',
                compensationStrategy: '',
                strategyWage: 0,
                monday: {},
                tuesday: {},
                wednesday: {},
                thursday: {},
                friday: {},
                saturday: {},
                sunday: {},
                bookable: false,
                displayOrder: '',
                doNotCallEnabled: false,
                acceptClientRequestEnabled: false,
                customerAccessAllowed: false,
                autoLogoutEnabled: false,
                appointmentSchedulingAllowed: true

            },
            selectedSpecialities: [],
            experts: {},
            teams: {},
            userDetails: {},
            userId: '',
            submitted: false,
            tabCond: false,
            count: 0,
            selectedTeam: 'All',
            allServices: '',
            selectedService: {},
            pricingModel: '',
            payoption: 'Hourly',
            selectedScale: {},
            loading: false,
            displayOrder: '',
            searchKey: '',
            mode: '',
            min: '',
            max: '',
            durat: '',
            process: '',
            predefined: 'THIS_WEEK',
            defaultStartDate: moment().startOf('week'),
            defaultEndDate: moment(),
            techHours: '',
            salesData: '',
            specificEnd: '',
            monT: {
                monP: 0,
                satP: 0,
                sunP: 0,
                wedP: 0,
                thuP: 0,
                friP: 0,
                tueP: 0,
                monS: 0,
                satS: 0,
                sunS: 0,
                wedS: 0,
                thuS: 0,
                friS: 0,
                tueS: 0

            },
            wedT: {},
            thuT: {},
            satT: {},
            tueT: {},
            friT: {},
            sunT: {},
            day: {
                mon: [],
                sun: [],
                sat: [],
                tue: [],
                wed: [],
                fri: [],
                thu: []
            },

            salesCount: 1,
            maxCount: 1,
            type: 'all',
            latitude: '',
            longitude: '',
            notes: '',
            clockIn: moment(),
            clockOut: moment(),
            cardId: '',
            timeCount: 1,
            maxTimeCount: 1,
            timeSheet: false,
            revenue: false,
            singleTimeSheet: '',
            brekNotes: '',
            breakId: '',
            breakStart: moment(),
            breakEnd: moment(),
            breakPoints: [],
            detailsLat: '',
            detailsLang: '',
            timeCard: 'all',
            address: '',
            singleHours: {
                dayOfWeek: '',
                from: '',
                to: '',
                pattern: '',
                patternStartDate: ''
            },

            ab: '',
            item: [],
            items: [],
            revenues: '',
            passwordSubmitted: false,
            passwordUpdated: false,
            errors: {},
            list: [],
            user_id: '',
            categories: []

        };


        appService.getAllServicesForStaffdetails(this.state.pageOffset, this.state.ctgryId).then(services => {
            this.setState({
                allServices: services
            })
        })


        this.handleChange = this.handleChange.bind(this);
        //this.handleSubmit = this.handleSubmit.bind(this);

        this.selectTimeSlot = this.selectTimeSlot.bind(this)
        this.eventClickHandler = this.eventClickHandler.bind(this)
        this.getMyLocation = this.getMyLocation.bind(this)
        this.calendarRef = React.createRef()

        //fetch category
        this.getCategories();

        this.onchangeTextDelayed = _.debounce(this.onchangeText, 500)

    }

    getCategories() {
        appService.getCategories(0, 200).then(categories => {
            this.setState({categories})
            //this.initializeServices()
        }).catch(e => {
            console.log(e)
        })
    }

    getStaffInfo = (userId) => {
        userService.getTechnicianInfo(userId).then(user => {
            const specialties = this.state.selectedSpecialities;
            user.specialties.forEach(sp => {
                specialties.push({
                    label: sp,
                    value: sp
                })
            })
            const experts = this.state.experts;
            const teams = this.state.teams;
            experts.label = user.expertise
            experts.value = user.expertise
            teams.label = user.team
            teams.value = user.team
            this.setState({
                user: {
                    ...this.state.user,
                    id: user.id,
                    firstName: user.user.names.first,
                    lastName: user.user.names.last,
                    nickName: user.user.names.nick,
                    email: user.user.emailAddress,
                    mobileNumber: user.user.mobileNumber,
                    gender: user.user.gender,
                    birthDate: user.user.birthDate,
                    emailNotification: user.user.emailNotificationEnabled,
                    smsNotification: user.user.smsNotificationEnabled,
                    customerBookingAllowed: user.customerBookingAllowed,
                    clockReminderEnabled: user.clockReminderEnabled,
                    profilePic: user.user.imageUrl,
                    createdTime: user.createdTime,
                    nextBirthdayRewardDate: user.user.nextBirthdayRewardDate,
                    rewardBalance: user.user.rewardBalance,
                    referralToken: user.user.referralToken,
                    totalEarningsAsReferrer: user.user.totalEarningsAsReferrer,
                    instagramUrl: user.instagramUrl,
                    accountStatus: user.accountStatus,
                    representationColor: user.representationColor,
                    team: user.team,
                    role: user.user.role,
                    formulas: user.formulas,
                    expertise: user.expertise,
                    biography: user.biography,
                    specialities: user.specialties,
                    technicianServices: user.technicianServices,
                    productCommissionPercentage: user.productCommissionPercentage,
                    compensationStrategy: user.compensationStrategy,
                    strategyWage: user.strategyWage,
                    cashPaymentPercentage: user.cashPaymentPercentage,
                    hours: user.hours,
                    displayOrder: user.displayOrder,
                    doNotCallEnabled: user.user.doNotCallEnabled,
                    acceptClientRequestEnabled: user.acceptClientRequestEnabled,
                    customerAccessAllowed: user.customerAccessAllowed,
                    autoLogoutEnabled: user.autoLogoutEnabled,
                    appointmentSchedulingAllowed: user.user.appointmentSchedulingAllowed,
                    monday: {
                        id: user.hours.length != 0 && user.hours[0].id,
                        dayOfWeek: user.hours.length != 0 && user.hours[0].dayOfWeek,
                        from: user.hours.length != 0 && user.hours[0].from,
                        to: user.hours.length != 0 && user.hours[0].to,
                        pattern: user.hours.length != 0 && user.hours[0].pattern,
                        patternStartDate: user.hours.length != 0 && user.hours[0].patternStartDate
                    },
                    tuesday: {
                        id: user.hours.length > 1 && user.hours[1].id,
                        dayOfWeek: user.hours.length > 1 && user.hours[1].dayOfWeek,
                        from: user.hours.length > 1 && user.hours[1].from,
                        to: user.hours.length > 1 && user.hours[1].to,
                        pattern: user.hours.length > 1 && user.hours[1].pattern,
                        patternStartDate: user.hours.length > 1 && user.hours[1].patternStartDate
                    },
                    wednesday: {
                        id: user.hours.length > 2 && user.hours[2].id,
                        dayOfWeek: user.hours.length > 2 && user.hours[2].dayOfWeek,
                        from: user.hours.length > 2 && user.hours[2].from,
                        to: user.hours.length > 2 && user.hours[2].to,
                        pattern: user.hours.length > 2 && user.hours[2].pattern,
                        patternStartDate: user.hours.length > 2 && user.hours[2].patternStartDate
                    },
                    thursday: {
                        id: user.hours.length > 3 && user.hours[3].id,
                        dayOfWeek: user.hours.length > 3 && user.hours[3].dayOfWeek,
                        from: user.hours.length > 3 && user.hours[3].from,
                        to: user.hours.length > 3 && user.hours[3].to,
                        pattern: user.hours.length > 3 && user.hours[3].pattern,
                        patternStartDate: user.hours.length > 2 && user.hours[3].patternStartDate
                    },
                    friday: {
                        id: user.hours.length > 4 && user.hours[4].id,
                        dayOfWeek: user.hours.length > 4 && user.hours[4].dayOfWeek,
                        from: user.hours.length > 4 && user.hours[4].from,
                        to: user.hours.length > 4 && user.hours[4].to,
                        pattern: user.hours.length > 4 && user.hours[4].pattern,
                        patternStartDate: user.hours.length > 4 && user.hours[4].patternStartDate
                    },
                    saturday: {
                        id: user.hours.length > 5 && user.hours[5].id,
                        dayOfWeek: user.hours.length > 5 && user.hours[5].dayOfWeek,
                        from: user.hours.length > 5 && user.hours[5].from,
                        to: user.hours.length > 5 && user.hours[5].to,
                        pattern: user.hours.length > 5 && user.hours[5].pattern,
                        patternStartDate: user.hours.length > 5 && user.hours[5].patternStartDate
                    },
                    sunday: {
                        id: user.hours.length > 6 && user.hours[6].id,
                        dayOfWeek: user.hours.length > 6 && user.hours[6].dayOfWeek,
                        from: user.hours.length > 6 && user.hours[6].from,
                        to: user.hours.length > 6 && user.hours[6].to,
                        pattern: user.hours.length > 6 && user.hours[6].pattern,
                        patternStartDate: user.hours.length > 6 && user.hours[6].patternStartDate
                    },
                    bookable: user.bookable


                },
                selectedSpecialities: specialties,
                experts: experts,
                teams: teams,
                userDetails: user,

                userId: userId,
                submitted: false,
                password: '',
                cPassword: '',
                user_id: user.user.id

            });
        });
    }

    handleGross = () => {
        const {name, value} = event.target;
        this.setState({
            selectedScale: {
                ...this.state.selectedScale,
                [name]: value
            }
        })

    }

    changePending = (e) => {
        this.setState({
            type: 'init'
        }, () => {
            this.initializeTimeData()
        })


    }
    changeAll = (e) => {
        this.setState({
            type: 'all'
        }, () => {
            this.initializeTimeData()
        })
    }


    changeMonday = (event) => {
        const {name, value} = event.target;
        const {user} = this.state
        this.setState({
            user: {
                ...user,
                monday: {
                    ...user.monday,
                    [name]: value

                }
            }
        })
    }
    changeTuesday = (event) => {
        const {user} = this.state

        const {name, value} = event.target;
        this.setState({
            user: {
                ...user,
                tuesday: {
                    ...user.tuesday,
                    [name]: value

                }
            }
        })

    }

    changeWednesday = (event) => {
        const {user} = this.state

        const {name, value} = event.target;
        this.setState({
            user: {
                ...user,
                wednesday: {
                    ...user.wednesday,
                    [name]: value

                }
            }
        })

    }
    changeThursday = (event) => {
        const {user} = this.state

        const {name, value} = event.target;
        this.setState({
            user: {
                ...user,
                thursday: {
                    ...user.thursday,
                    [name]: value

                }
            }
        })

    }
    changeFriday = (event) => {
        const {user} = this.state

        const {name, value} = event.target;
        this.setState({
            user: {
                ...user,
                friday: {
                    ...user.friday,
                    [name]: value

                }
            }
        })

    }
    changeSaturday = (event) => {
        const {user} = this.state

        const {name, value} = event.target;
        this.setState({
            user: {
                ...user,
                saturday: {
                    ...user.saturday,
                    [name]: value

                }
            }
        })

    }
    changeSunday = (event) => {
        const {user} = this.state

        const {name, value} = event.target;
        this.setState({
            user: {
                ...user,
                sunday: {
                    ...user.sunday,
                    [name]: value

                }
            }
        })

    }


    changeTech = (e) => {
        this.setState({
            selectedService: {...this.state.selectedService, [e.target.name]: e.target.value}
        });
    }


    //for increasing a row with commsion tab
    clickHandlerToIncrease = (e) => {
        e.preventDefault();
        this.setState({
            count: this.state.count + 1
        });
    }

    //for decreasing a row with commsion tab
    clickHandlerToDecrease(e) {
        e.preventDefault();
        this.setState({
            count: this.state.count - 1
        });
    }


    handleDateChange(info, successCallback, failureCallback) {
        var start = info.start
        start = moment(start)
        var end = info.end
        // if(!start.isSame(this.calendarDate) || this.startApp == true){
        var techSchedule = this.state.getAllTechnicians == true ? "all" : this.state.selectedServicenician
        start = moment(start).format("YYYY-MM-DD")
        end = moment(end).format("YYYY-MM-DD")
        if (moment(end).diff(start, 'days') <= 1)
            end = start
        userService.getTechniciansSchedule(techSchedule, start, end).then(slots => {
            this.events = slots;
            this.calendarDate = start;
            this.startApp = false;
            successCallback(slots)

        }, error => {
            console.log(error)
        })
        // }
    }


    handleChange(event) {
        const {name, value} = event.target;

        // eslint-disable-next-line
        const {user} = this.state;
        // eslint-disable-next-line
        if (name == "emailNotification") {

            this.setState({
                user: {
                    ...user,
                    emailNotification: !this.state.user.emailNotification
                }

            })

        } else if (name === "clockReminderEnabled") {
            this.setState({
                user: {
                    ...user,
                    clockReminderEnabled: !this.state.user.clockReminderEnabled
                }

            })

        }
        // eslint-disable-next-line
        else if (name === "smsNotification") {
            this.setState({
                user: {
                    ...user,
                    smsNotification: !this.state.user.smsNotification
                }

            })
        } else if (name === "customerBookingAllowed") {
            this.setState({
                user: {
                    ...user,
                    customerBookingAllowed: !this.state.user.customerBookingAllowed
                }

            })
        } else if (name === "acceptClientRequestEnabled") {
            this.setState({
                user: {
                    ...user,
                    acceptClientRequestEnabled: !this.state.user.acceptClientRequestEnabled
                }

            })
        } else if (name === "customerAccessAllowed") {
            this.setState({
                user: {
                    ...user,
                    customerAccessAllowed: !this.state.user.customerAccessAllowed
                }

            })
        } else if (name === "autoLogoutEnabled") {
            this.setState({
                user: {
                    ...user,
                    autoLogoutEnabled: !this.state.user.autoLogoutEnabled
                }

            })
        } else if (name === "appointmentSchedulingAllowed") {
            this.setState({
                user: {
                    ...user,
                    appointmentSchedulingAllowed: !this.state.user.appointmentSchedulingAllowed
                }

            })
        } else if (name === "password") {
            this.setState({
                user: {
                    ...user
                },
                password: value

            })
        } else if (name === "cPassword") {
            this.setState({
                user: {
                    ...user,
                },
                cPassword: value

            })
        } else {

            this.setState({
                [name]: value,
                user: {
                    ...user,
                    [name]: value
                }
            });
        }
    }

    changeBreakStart = (start, name) => {
        this.setState({
            breakStart: start
        })
    }
    changeBreakEnd = (end, name) => {
        this.setState({
            breakEnd: end
        })
    }


    getUser(userId) {
        userService.getTechnicianInfo(userId).then(user => {

            this.setState({
                user: {
                    firstName: user.names.first,
                    lastName: user.names.last,
                    nickName: user.names.nick,
                    email: user.emailAddress,
                    mobileNumber: user.mobileNumber,
                    gender: user.gender,
                    birthDate: user.birthDate,
                    emailNotification: user.emailNotificationEnabled,
                    smsNotification: user.smsNotificationEnabled,
                    profilePic: user.imageUrl,
                },
                userDetails: user,
                userId: userId,
                submitted: false,

            });
        });
    }

    initializeRelativeMethod() {
        window.colorPicker();
        window.specialitiesSuggestions()
        window.expertiseSuggestions()
    }

    componentDidMount = async () => {
        const metaInfo = await appService.checkVersion();
        await helperFunctions.clearCache(metaInfo)
        //this.initializeRelativeMethod();
        window.initiateCheckBoxes();
        this.initializeSalesData();
        this.initializeTimeData();
        this.getMyLocation()

    }

    getMyLocation() {
        const location = window.navigator && window.navigator.geolocation;

        if (location) {
            location.getCurrentPosition((position) => {
                this.setState({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                })
            }, (error) => {
                this.setState({latitude: 'err-latitude', longitude: 'err-longitude'})
            })
        }

    }

    openHoursModal = (day) => {
        const {user} = this.state;
        const newHour = {};

        if (Object.keys(day).length != 0) {
            newHour.id = user[day].id;
            newHour.dayOfWeek = user[day].dayOfWeek;
            newHour.from = user[day].from.toUpperCase();
            newHour.to = user[day].to.toUpperCase();
            newHour.pattern = user[day].pattern;
            newHour.patternStartDate = user[day].patternStartDate;
        }
        this.setState({
            singleHours: newHour
        })
    }


    initializeTimeData = () => {
        this.setState({
            loading: true
        })
        const {defaultStartDate, defaultEndDate, timeCard} = this.state
        if (timeCard == 'all') {
            userService.getTechHoursData(qs.parse(this.props.location.search.replace("?", "")).staffId, defaultStartDate.format('YYYY-MM-DD'), defaultEndDate.format('YYYY-MM-DD'), this.state.type).then(techHours => {
                this.setState({
                    techHours,
                    loading: false
                })
            })
        } else {
            userService.getTechHoursData(qs.parse(this.props.location.search.replace("?", "")).staffId, defaultStartDate.format('YYYY-MM-DD'), defaultEndDate.format('YYYY-MM-DD'), this.state.type).then(techHours => {
                this.setState({
                    techHours,
                    loading: false
                })
            })
        }
    }

    initializeSalesData = () => {
        const {defaultStartDate, defaultEndDate} = this.state

        userService.getTechSalesData(qs.parse(this.props.location.search.replace("?", "")).staffId, defaultStartDate.format('YYYY-MM-DD'), defaultEndDate.format('YYYY-MM-DD')).then(salesData => {

            var keys = ''
            if (Object.keys(salesData) != 0) {
                keys = Object.keys(salesData)
            }

            const mon = []
            const wed = []
            const sun = []
            const sat = []
            const thu = []
            const fri = []
            const tue = []

            var monP = 0
            var wedP = 0
            var thuP = 0
            var satP = 0
            var tueP = 0
            var friP = 0
            var sunP = 0

            var monS = 0
            var wedS = 0
            var thuS = 0
            var satS = 0
            var tueS = 0
            var friS = 0
            var sunS = 0
            const salesInfo = []


            for (var i = 0; i < keys.length; i++) {
                let dayInfo = {
                    date: '',
                    day: '',
                    serviceChargeAmount: 0,
                    productPurchaseAmount: 0,
                    singleDayData: []
                }

                salesData[keys[i]].filter(item => item.status == 'Checked Out').map(s => {
                    dayInfo.date = moment(keys[i]).format('MMM D');
                    dayInfo.day = helperFunctions.getDay(keys[i]);
                    dayInfo.serviceChargeAmount += s.serviceChargeAmount * 1
                    dayInfo.productPurchaseAmount += s.productPurchaseAmount * 1
                    dayInfo.singleDayData.push({
                        bookedTime: helperFunctions.formatDateTimeWithDay(s.bookedTime, 'time'),
                        purchaseItems: s.purchaseItems,
                        visitTechnicians: s.visitTechnicians,
                        client: s.client.fullName
                    })

                })
                if (dayInfo.date != '') {
                    salesInfo.push(dayInfo);
                }


            }

            this.setState({
                revenues: salesInfo.reverse()
            })


            for (var i = 0; i < keys.length; i++) {
                var name = helperFunctions.getDay(keys[i]);
                if (name == 'Mon') {
                    salesData[keys[i]].map(s => {
                        mon.push(s)
                        monS += s.serviceChargeAmount * 1
                        monP = s.productPurchaseAmount * 1

                    })
                } else if (name == "Wed") {
                    salesData[keys[i]].map(s => {
                        wed.push(s)
                        wedS += s.serviceChargeAmount * 1
                        wedP = s.productPurchaseAmount * 1
                    })
                } else if (name == "Sun") {
                    salesData[keys[i]].map(s => {
                        sun.push(s)
                        sunS += s.serviceChargeAmount * 1
                        sunP = s.productPurchaseAmount * 1
                    })
                } else if (name == "Sat") {
                    salesData[keys[i]].map(s => {
                        sat.push(s)
                        satS += s.serviceChargeAmount * 1
                        satP = s.productPurchaseAmount * 1
                    })
                } else if (name == "Thu") {
                    salesData[keys[i]].map(s => {
                        thu.push(s)
                        thuS += s.serviceChargeAmount * 1
                        thuP = s.productPurchaseAmount * 1
                    })
                } else if (name == "Tue") {
                    salesData[keys[i]].map(s => {
                        tue.push(s)
                        tueS += s.serviceChargeAmount * 1
                        tueP = s.productPurchaseAmount * 1
                    })
                } else if (name == "Fri") {
                    salesData[keys[i]].map(s => {
                        fri.push(s)
                        friS += s.serviceChargeAmount * 1
                        friP = s.productPurchaseAmount * 1
                    })
                }
            }

            this.setState({
                salesData,

                day: {
                    mon: mon,
                    tue: tue,
                    wed: wed,
                    thu: thu,
                    fri: fri,
                    sat: sat,
                    sun: sun

                },
                monT: {
                    monS: monS,
                    monP: monP
                },
                wedT: {
                    wedS: wedS,
                    wedP: wedP

                },
                thuT: {
                    thuS: thuS,
                    thuP: thuP
                },
                satT: {
                    satS: satS,
                    satP: satP
                },
                tueT: {
                    tueS: tueS,
                    tueP: tueP
                },
                friT: {
                    friS: friS,
                    friP: friP

                },
                sunT: {
                    sunS: sunS,
                    sunP: sunP
                },
                loading: false

            })
        })
    }

    getNextSales = () => {

        const maxCount = this.state.maxCount;
        const salesCount = this.state.salesCount

        if (maxCount > 1 && salesCount <= maxCount) {
            if (this.state.predefined == 'THIS_MONTH') {
                if ((maxCount - salesCount) == 1) {
                    const start = moment(this.state.defaultEndDate).add(1, 'days');
                    const end = moment();
                    this.setState({
                        defaultStartDate: start,
                        defaultEndDate: end,
                        salesCount: this.state.salesCount + 1,
                        loading: true
                    }, () => {
                        if (this.state.timeSheet == true) {
                            this.initializeTimeData()
                        } else {
                            this.initializeSalesData()
                        }
                    });
                } else {
                    this.setState({
                        defaultStartDate: moment(this.state.defaultEndDate).add(1, 'days'),
                        defaultEndDate: moment(this.state.defaultEndDate).add(6, 'days'),
                        salesCount: this.state.salesCount + 1,
                        loading: true
                    }, () => {
                        if (this.state.timeSheet == true) {
                            this.initializeTimeData()
                        } else {
                            this.initializeSalesData()
                        }
                    });
                }

            } else if (this.state.predefined == 'LAST_MONTH') {
                if ((maxCount - salesCount) == 1) {
                    const start = moment(this.state.defaultEndDate).add(1, 'days');
                    const end = moment().subtract(1, 'months').endOf('month');
                    this.setState({
                        defaultStartDate: start,
                        defaultEndDate: end,
                        salesCount: this.state.salesCount + 1,
                        loading: true
                    }, () => {
                        if (this.state.timeSheet == true) {
                            this.initializeTimeData()
                        } else {
                            this.initializeSalesData()
                        }
                    });
                } else {
                    this.setState({
                        defaultStartDate: moment(this.state.defaultEndDate).add(1, 'days'),
                        defaultEndDate: moment(this.state.defaultEndDate).add(6, 'days'),
                        salesCount: this.state.salesCount + 1,
                        loading: true
                    }, () => {
                        if (this.state.timeSheet == true) {
                            this.initializeTimeData()
                        } else {
                            this.initializeSalesData()
                        }
                    });
                }

            } else if (this.state.predefined == 'RANGE') {
                if ((maxCount - salesCount) == 1) {

                    this.setState({
                        defaultStartDate: moment(this.state.defaultEndDate).add(1, 'days'),
                        defaultEndDate: moment(this.state.specificEnd),
                        salesCount: this.state.salesCount + 1,
                        loading: true
                    }, () => {
                        if (this.state.timeSheet == true) {
                            this.initializeTimeData()
                        } else {
                            this.initializeSalesData()
                        }
                    });
                } else {

                    this.setState({
                        defaultStartDate: moment(this.state.defaultEndDate).add(1, 'days'),
                        defaultEndDate: moment(this.state.defaultEndDate).add(6, 'days'),
                        salesCount: this.state.salesCount + 1,
                        loading: true
                    }, () => {
                        if (this.state.timeSheet == true) {
                            this.initializeTimeData()
                        } else {
                            this.initializeSalesData()
                        }
                    });
                }
            } else {

                if ((maxCount - salesCount) == 1) {
                    this.setState({
                        defaultStartDate: moment(this.state.defaultEndDate).add(1, 'days'),
                        defaultEndDate: moment().subtract(1, 'months').endOf('month'),
                        salesCount: this.state.salesCount + 1,
                        loading: true
                    }, () => {
                        if (this.state.timeSheet == true) {
                            this.initializeTimeData()
                        } else {
                            this.initializeSalesData()
                        }
                    });
                } else {
                    this.setState({
                        defaultStartDate: moment(this.state.defaultEndDate).add(1, 'days'),
                        defaultEndDate: moment(this.state.defaultEndDate).add(6, 'days'),
                        salesCount: this.state.salesCount + 1,
                        loading: true
                    }, () => {
                        if (this.state.timeSheet == true) {
                            this.initializeTimeData()
                        } else {
                            this.initializeSalesData()
                        }
                    });
                }
            }


        }
    }
    getPrevSales = () => {
        const maxCount = this.state.maxCount;
        const salesCount = this.state.salesCount;


        if (this.state.predefined == 'THIS_MONTH') {
            if (maxCount > 1 && salesCount != 1) {
                if (salesCount == 2) {
                    const start = moment().startOf('month')
                    this.setState({
                        defaultStartDate: moment().startOf('month'),
                        defaultEndDate: start.add(6, 'days'),
                        salesCount: this.state.salesCount - 1,
                        loading: true
                    }, () => {
                        if (this.state.timeSheet == true) {
                            this.initializeTimeData()
                        } else {
                            this.initializeSalesData()
                        }
                    });
                } else {
                    this.setState({
                        defaultStartDate: moment(this.state.defaultStartDate).subtract(7, 'days'),
                        defaultEndDate: moment(this.state.defaultEndDate).subtract(7, 'days'),
                        salesCount: this.state.salesCount - 1,
                        loading: true
                    }, () => {
                        if (this.state.timeSheet == true) {
                            this.initializeTimeData()
                        } else {
                            this.initializeSalesData()
                        }
                    });
                }

            }
        } else {
            if (maxCount > 1 && salesCount != 1) {
                if (salesCount == 2) {
                    const start = moment().subtract(1, 'months').startOf('month')
                    this.setState({
                        defaultStartDate: start,
                        defaultEndDate: moment().subtract(1, 'months').startOf('month').add(6, 'days'),
                        salesCount: this.state.salesCount - 1,
                        loading: true
                    }, () => {
                        if (this.state.timeSheet == true) {
                            this.initializeTimeData()
                        } else {
                            this.initializeSalesData()
                        }
                    });
                } else {
                    this.setState({
                        defaultStartDate: moment(this.state.defaultStartDate).subtract(7, 'days'),
                        defaultEndDate: moment(this.state.defaultEndDate).subtract(7, 'days'),
                        salesCount: this.state.salesCount - 1,
                        loading: true
                    }, () => {
                        if (this.state.timeSheet == true) {
                            this.initializeTimeData()
                        } else {
                            this.initializeSalesData()
                        }
                    });
                }

            }
        }


    }


    handleSelect = selectedOption => {
        this.setState(
            {...this.state, selectedSpecialities: selectedOption}
        );
    };
    selectExpertise = selectedOption => {
        this.setState(
            {...this.state, experts: selectedOption}
        );
    };
    selectTeams = selectedOption => {
        this.setState(
            {...this.state, teams: selectedOption}
        );
    };
    handleBirthDate = date => {
        this.setState({
            user: {
                ...this.state.user,
                birthDate: date
            }
        })
    };

    updateStaffHours = () => {
        const {singleHours} = this.state;

        this.setState({
            loading: true
        })

        const newHour = {
            technician: {
                id: this.state.user.id
            },
            dayOfWeek: singleHours.dayOfWeek,
            from: singleHours.from.toUpperCase(),
            to: singleHours.to.toUpperCase(),
            pattern: singleHours.pattern,
            patternStartDate: singleHours.patternStartDate
        }

        userService.updateStaffHours(singleHours.id, newHour).then(res => {
            /* eslint-disable */
            $('#hoursModal').modal('hide');
            userService.getTechnicianInfo(this.state.user.id).then(user => {
                this.resetState(user)
            })
            window.DataUpdateSuccess();

        }).catch(e => {
            this.setState({
                loading: false
            })
            e.json().then(err => {
                Swal.fire({
                    title: 'Warning',
                    text: `${err.message}.`,
                    type: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Ok'
                })
            })
        })


    }
    getDuration = (timeB, timeS) => {

        const sum = moment(moment(timeB)).diff(moment());
        const minutes = parseInt(moment.duration(sum).asMinutes());

        return minutes
    }
    updateStaffInfos = (e) => {
        e.preventDefault();
        const {user} = this.state;
        let specialities = [];
        if (this.state.selectedSpecialities && this.state.selectedSpecialities.length != 0) {
            for (let i = 0; i < this.state.selectedSpecialities.length; i++) {
                specialities.push(this.state.selectedSpecialities[i].label)
            }
        } else if (this.state.selectedSpecialities && this.state.selectedSpecialities.length == 0) {
            specialities = user.specialities
        }


        if (user.firstName && user.lastName && user.birthDate && user.mobileNumber && user.gender) {
            user.mobileNumber = user.mobileNumber.replace(' ', '').replace(')', '').replace('(', '').replace('-', '');
            const updatedUser = {
                location: {
                    id: this.app.locations[0].id
                },
                customerBookingAllowed: this.state.user.customerBookingAllowed,
                acceptClientRequestEnabled: user.acceptClientRequestEnabled,
                customerAccessAllowed: user.customerAccessAllowed,
                autoLogoutEnabled: user.autoLogoutEnabled,
                user: {
                    business: {
                        id: this.app.id
                    },
                    id: user.id,
                    role: user.role,
                    emailAddress: user.email,
                    mobileNumber: user.mobileNumber,
                    birthDate: user.birthDate,
                    names: {
                        first: user.firstName,
                        last: user.lastName,
                        nick: user.nickName
                    },
                    gender: user.gender,
                    emailNotificationEnabled: user.emailNotification == 'on' ? true : user.emailNotification == true ? true : false,
                    smsNotificationEnabled: user.smsNotification,
                    appointmentSchedulingAllowed: user.appointmentSchedulingAllowed,
                    referredByUser: {
                        referralToken: user.referralToken
                    },
                    doNotCallEnabled: user.doNotCallEnabled

                },
                instagramUrl: user.instagramUrl,
                representationColor: user.representationColor,
                accountStatus: user.accountStatus,
                team: this.state.teams.value,
                expertise: this.state.experts.value,
                biography: user.biography,
                specialties: specialities,
                productCommissionPercentage: user.productCommissionPercentage,
                compensationStrategy: user.compensationStrategy,
                strategyWage: user.strategyWage,
                cashPaymentPercentage: user.cashPaymentPercentage,
                clockReminderEnabled: user.clockReminderEnabled,
                displayOrder: this.state.userDetails.displayOrder

            }

            //log('USEEE', user)

            let errors = this.validateData(user);
            this.setState({
                errors
            })

            if (Object.keys(errors).length == 0) {
                userService.updateStaffInfos(this.state.userId, updatedUser).then(res => {
                    window.DataUpdateSuccess();
                    this.setState({errors: {}})
                }).catch(e => {
                    e.json().then(err => {
                        Swal.fire({
                            title: 'Warning',
                            text: `${err.errors && err.errors.length > 0 ? err.errors[0] : err.message}`,
                            type: 'warning',
                            showCancelButton: false,
                            confirmButtonText: 'Ok'
                        })
                    })
                })
            }


        }
    }

    validateData = (user) => {

        const errors = {}
        if (!user.firstName) errors.firstName = 'Please fill first name';
        if (!user.lastName) errors.lastName = 'Please fill last name';
        if (!user.nickName) errors.nickName = 'Please fill nick name';
        if (!user.mobileNumber) errors.mobileNumber = 'Please enter mobile number';
        if (!user.email) errors.emailAddress = 'Please enter a valid email';
        if (!user.gender) errors.gender = 'Please select a gender';
        if (!user.birthDate) errors.birthDate = 'Please enter valid date of birth';
        if (user.birthDate) {
            let duration = this.getDuration(this.state.user.birthDate, '');
            if (duration > 0) errors.birthDate = 'Please enter valid date of birth';
        }


        if (!user.representationColor) errors.representationColor = 'Please choose a color';
        if (this.state.selectedSpecialities == null) errors.specialities = 'Please choose specialities';
        if (!user.accountStatus) errors.accountStatus = 'Please select account status';
        if (!user.team) errors.team = 'Please select a team';
        if (!user.expertise) errors.expertise = 'Please select an expertise';
        if (!user.biography) errors.biography = 'Please provide biography';
        if (!user.instagramUrl) errors.instagramUrl = 'Please enter your instagram url';
        //if(!user.productCommissionPercentage) errors.productCommissionPercentage = 'Please enter product commission percentage';
        if (!user.role) errors.role = 'Please assign a role to the staff';
        if (!user.referralToken) errors.referralToken = 'Referall token should not be empty!';


        return errors;
    }


    changeDefaultEnd = (end) => {
        this.setState({
            defaultEndDate: end
        })
    }
    changeDefaultStart = (start) => {
        this.setState({
            defaultStartDate: start
        })
    }


    showSalesData = () => {
        $('#pickerModal').modal('hide');
        const start = this.state.defaultStartDate
        const end = this.state.defaultEndDate
        const diff = end.diff(start, 'days')
        this.setState({
            maxCount: 1,
            salesCount: 1,
            loading: true
        })
        if (diff >= 7) {

            this.setState({
                predefined: 'RANGE',
                maxCount: Math.ceil(diff / 7),
                defaultStartDate: start,
                defaultEndDate: moment(this.state.defaultStartDate).add(7, 'days'),
                specificEnd: this.state.defaultEndDate
            }, () => {
                if (this.state.timeSheet == true) {
                    this.initializeTimeData()
                } else {
                    this.initializeSalesData()
                }
            })
        } else {
            if (this.state.timeSheet == true) {
                this.initializeTimeData()
            } else {
                this.initializeSalesData()
            }
        }

    }

    predefinedSalesData = (type) => {
        $('#pickerModal').modal('hide')
        this.setState({
            loading: true
        });
        if (type == 'TODAY') {
            this.setState({
                defaultStartDate: moment(),
                defaultEndDate: moment(),
                maxCount: 1,
                salesCount: 1,
                predefined: type
            }, () => {
                if (this.state.timeSheet == true) {
                    this.initializeTimeData()
                } else {
                    this.initializeSalesData()
                }

            })
        } else if (type == 'THIS_WEEK') {
            this.setState({
                defaultStartDate: moment().startOf('week'),
                defaultEndDate: moment(),
                maxCount: 1,
                salesCount: 1,
                predefined: type
            }, () => {
                if (this.state.timeSheet == true) {
                    this.initializeTimeData()
                } else {
                    this.initializeSalesData()
                }
            })
        } else if (type == 'LAST_WEEK') {

            this.setState({
                defaultStartDate: moment().subtract(1, 'weeks').startOf('week'),
                defaultEndDate: moment().subtract(1, 'weeks').endOf('week'),
                maxCount: 1,
                salesCount: 1,
                predefined: type
            }, () => {
                if (this.state.timeSheet == true) {
                    this.initializeTimeData()
                } else {
                    this.initializeSalesData()
                }
            })
        } else if (type == 'THIS_MONTH') {

            const start = moment().startOf('month')
            const end = moment()
            const maxCount = Math.ceil(end.diff(start, 'days') / 7)
            this.setState({
                defaultStartDate: moment().startOf('month'),
                defaultEndDate: start.add(6, 'days'),
                maxCount: maxCount,
                predefined: type
            }, () => {
                if (this.state.timeSheet == true) {
                    this.initializeTimeData()
                } else {
                    this.initializeSalesData()
                }
            })
        } else if (type == 'LAST_MONTH') {
            const start = moment().subtract(1, 'months').startOf('month')
            const end = moment().subtract(1, 'months').endOf('month')
            this.setState({
                defaultStartDate: start,
                defaultEndDate: moment().subtract(1, 'months').startOf('month').add(6, 'days'),
                maxCount: Math.ceil(end.diff(start, 'days') / 7),
                predefined: type

            }, () => {
                if (this.state.timeSheet == true) {
                    this.initializeTimeData()
                } else {
                    this.initializeSalesData()
                }
            })
        }

        setTimeout(() => {
            this.setState({
                loading: false
            });
        }, 1000)

    }

    closeAddModal = () => {
        $('#addBreakModal').modal('hide');
        $('#timeCardDetails').modal('show');
    }
    closeModalBreak = () => {
        $('#addBreakModal').modal('hide');
        $('#timeCardDetails').modal('show');
    }

    //saving time card
    addNewTimeCard = (end) => {
        const {clockIn, clockOut, notes, latitude, longitude} = this.state
        if (latitude !== 'err-latitude' && longitude !== 'err-longitude') {
            this.setState({
                loading: true
            });
            if (end == 'time-cards') {
                const timeCardJSON = {
                    technician: {
                        id: qs.parse(this.props.location.search.replace("?", "")).staffId
                    },
                    period: {
                        from: this.state.clockIn.format("YYYY-MM-DDTHH:mm:ss"),
                        to: this.state.clockOut.format("YYYY-MM-DDTHH:mm:ss")
                    },
                    notes: notes,
                    startAddress: {
                        latitude: latitude,
                        longitude: longitude

                    }
                }
                userService.addNewTimeCard(end, timeCardJSON).then(res => {
                    this.setState({
                        cardId: res.id,
                        notes: '',
                        loading: false
                    });
                    window.DataUpdateSuccess()
                    this.initializeTimeData()
                }).catch(err => {
                    this.setState({
                        loading: false
                    })
                    err.json().then(e => {
                        Swal.fire({
                            title: 'Error!',
                            text: `${e.message}!`,
                            type: 'warning',
                            cancelButtonText: 'Ok'
                        })

                    })
                })
            } else {
                const timeCardJSON = {
                    card: {
                        id: this.state.cardId
                    },
                    period: {
                        from: moment(clockIn).format("YYYY-MM-DDT") + moment(this.state.breakStart).format("HH:mm:ss"),
                        to: moment(clockOut).format("YYYY-MM-DDT") + moment(this.state.breakEnd).format("HH:mm:ss")
                    },
                    startAddress: {
                        latitude: latitude,
                        longitude: longitude

                    }
                }
                userService.addNewTimeCard(end, timeCardJSON, this.state.cardId).then(res => {
                    this.setState({
                        loading: false
                    });
                    window.DataUpdateSuccess()
                    this.initializeTimeData()
                }).catch(err => {
                    this.setState({
                        loading: false
                    })
                    Swal.fire({
                        title: 'Error!',
                        text: `Please save a timesheet first!`,
                        type: 'warning',
                        cancelButtonText: 'Ok'
                    })
                    this.setState({
                        loading: false
                    })
                })
            }
        } else {
            Swal.fire({
                title: 'Error!',
                text: `It seems that you did not enable the location access. Please go to the system preference and enable location access for the browser and reload the page.`,
                type: 'warning',
                cancelButtonText: 'Ok'
            })
        }
    }

    closeHandler = () => {
        this.setState({
            cardId: '', notes: '',
            clockIn: moment(),
            clockOut: moment(),
            breakStart: moment(),
            breakEnd: moment(),
        })
    }


    openViewDetailsTimeCard = (t) => {
        var apiPath = 'https://maps.googleapis.com/maps/api/geocode/json?';
        apiPath += '&latlng=' + t.startAddress.latitude + ',' + t.startAddress.longitude;
        apiPath += '&key=AIzaSyB0w-ZFo7EvFeo1wEz_tD4sA5fiMlUjQzU';


        fetch(apiPath).then(res => {
            res.json().then(add => {
                this.setState({
                    address: add.results[0].formatted_address
                })
            })
        }).catch(e => console.log(e))


        const breakPoints = []
        if (t.breaks.length > 0) {
            t.breaks.map((b, k) => {
                breakPoints.push({
                    key: k,
                    from: b.period.from,
                    to: b.period.to,
                    id: b.id
                })
            })
        }

        this.setState({
            clockIn: moment(t.period.from),
            clockOut: moment(t.period.to),
            notes: t.notes,
            singleTimeSheet: t,
            cardId: t.id,
            breakPoints: breakPoints,
            detailsLat: t.startAddress.latitude,
            detailsLang: t.startAddress.longitude

        }, () => {
            window.initializeTimeCardMap(this.state.detailsLat, this.state.detailsLang)
        })

        $('#timeCardDetails').modal('show');


    }


    editBreakPoint = (k) => {
        const breaks = this.state.breakPoints.find(b => b.key == k)
        if (breaks) {
            this.setState({
                breakId: breaks.id,
                breakStart: moment(breaks.from),
                breakEnd: moment(breaks.to)
            })
        }


        $('#timeCardDetails').modal('hide');
    }


    changeClockIn = (clockIn) => {
        this.setState({
            clockIn
        })
    }
    changeClockOut = (clockOut) => {
        this.setState({
            clockOut
        })
    }


    openTimeSheetPicker = () => {
        this.setState({
            timeSheet: true
        })
    }
    openPickerModal = () => {
        this.setState({
            timeSheet: false,
            revenue: true
        })
    }


    updateBreakTime = () => {

        const breakJSON = {
            card: {
                id: this.state.cardId
            },
            period: {
                from: this.state.breakStart.format('YYYY-MM-DDTHH:mm:ss'),
                to: this.state.breakEnd.format('YYYY-MM-DDTHH:mm:ss')
            },
            endAddress: {
                latitude: this.state.latitude,
                longitude: this.state.longitude
            }

        }


        userService.updateBreakTime(this.state.breakId, breakJSON).then(res => {
            const prevBreak = this.state.breakPoints.map(b => {
                if (b.id == res.id) {
                    return {
                        ...b,
                        id: res.id,
                        from: res.period.from,
                        to: res.period.to
                    }
                }
                return b
            })

            this.setState({
                breakPoints: prevBreak
            })

            $('#modalBreak').modal('hide');
            $('#timeCardDetails').modal('show');
            this.initializeTimeData()

        })
    }

    addBreak = () => {
        const breakJSON = {
            card: {
                id: this.state.cardId
            },
            period: {
                from: moment(this.state.singleTimeSheet.createdTime).format('YYYY-MM-DDT') + this.state.breakStart.format('HH:mm:ss'),
                to: moment(this.state.singleTimeSheet.createdTime).format('YYYY-MM-DDT') + this.state.breakEnd.format('HH:mm:ss'),
            },
            startAddress: {
                latitude: this.state.latitude,
                longitude: this.state.longitude
            }
        }
        userService.addNewTimeCard('time-card-breaks', breakJSON).then(res => {

            const newBreak = {
                key: this.state.breakPoints.length,
                id: res.id,
                from: res.period.from,
                to: res.period.to
            }
            const breakPoints = this.state.breakPoints;
            breakPoints.push(newBreak);
            this.setState({
                breakPoints: breakPoints
            });
            $('#addBreakModal').modal('hide');
            $('#timeCardDetails').modal('show');
            this.initializeTimeData()
        })
    }


    deleteBreakPoint = (key, id) => {
        Swal.fire({
            title: 'Delete',
            text: `Are you sure you want to delete this`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then(result => {
            if (result.value) {
                userService.deleteBreakPoint(id).then(() => {
                    const newBreakPoints = this.state.breakPoints.filter(b => b.id != id)
                    this.setState({
                        breakPoints: newBreakPoints
                    })
                    $('#modalBreak').modal('hide');
                    $('#timeCardDetails').modal('show');
                    this.initializeTimeData()
                }).catch(err => {
                    err.json().then(e => {
                        Swal.fire({
                            title: 'Error',
                            text: `${e.message}.`,
                            type: 'warning',
                            showCancelButton: true,
                            showConfirmButton: false,
                            cancelButtonText: 'Ok'
                        })
                    })
                })
            }
        })
    }

    openAddBreakModal = () => {
        $('#addBreakModal').modal('show');
        $('#timeCardDetails').modal('hide');

    }


    closeDetailsModal = () => {
        $('#timeCardDetails').modal('hide')
        this.setState({
            singleTimeSheet: '',
            cardId: '',
            breakPoints: []
        })

    }

    opedEditTimeCard = () => {
        $('#timeCardDetails').modal('hide')
        $('#editTimeCard').modal('show')
    }

    updateTimeCard = () => {
        this.setState({
            loading: true
        })
        const editJSON = {

            period: {
                from: moment(this.state.clockIn).format("YYYY-MM-DDTHH:mm:ss"),
                to: moment(this.state.clockOut).format("YYYY-MM-DDTHH:mm:ss")
            },
            notes: this.state.notes,
            endAddress: {
                latitude: this.state.latitude,
                longitude: this.state.longitude

            }
        }
        userService.updateTimeCard(this.state.cardId, editJSON).then(res => {

            $('#editTimeCard').modal('hide');

            this.initializeTimeData();
            this.setState({
                loading: false
            })

        })
    }


    makeitApproved = (id) => {

        Swal.fire({
            title: 'Approval',
            text: `Are you sure you want to accept the time card and breaks`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, please!',
            cancelButtonText: 'Not now'
        }).then(result => {
            if (result.value) {
                this.setState({
                    loading: true
                })
                userService.makeitApproved(id).then(() => {
                    this.initializeTimeData();
                    this.setState({
                        loading: false
                    })
                }).catch(e => this.setState({loading: false}))
            }
        })


    }
    deleteTimeCard = (id) => {
        Swal.fire({
            title: 'Delete',
            text: `Are you sure you want to delete this`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then(result => {
            if (result.value) {
                userService.deleteTimeCard(id).then(() => {
                    this.initializeTimeData();
                    this.setState({
                        loading: false
                    })
                })
            }
        })


    }

    handleHoursFrom = (value, name) => {
        this.setState({
            singleHours: {
                ...this.state.singleHours,
                [name]: value.format(format)
            }
        })
    }


    handleHours = (e) => {
        const {name, value} = e.target
        this.setState({
            singleHours: {
                ...this.state.singleHours,
                [name]: value
            }
        })
    }
    handlePatternStart = (jsDate, dateString) => {
        this.setState({
            singleHours: {
                ...this.state.singleHours,
                patternStartDate: moment(dateString).format('YYYY-MM-DD')

            }
        })
    }

    handleCheckbox = (e) => {
        if (e.target.checked) {
            this.setState({
                item: this.state.item.concat(e.target.value)
            })
        } else {
            this.setState({
                item: this.state.item.filter(i => i !== e.target.value)
            })
        }
    }
    formatwage = () => {
        this.setState({
            user: {
                ...this.state.user,
                strategyWage: (this.state.user.strategyWage * 1).toFixed(2)
            }
        })
    }

    clearPlace = () => {
        this.setState({
            place: ''
        })
    }
    setPlace = (value) => {
        this.setState({
            ...this.state
        }, () => {
            this.setState({
                place: 'Type a name'
            })
        })
    }
    onSelectTechnician = (user) => {
        //console.log(user)
        this.getStaffInfo(user.id)
    }

    totalProducts = () => {
        const {revenues} = this.state;
        if (revenues.length > 0) {
            let total = 0;
            revenues.map(rev => {
                total += rev.productPurchaseAmount;
            })

            return (total * 1).toFixed(2)
        } else {
            return '0.00'
        }
    }

    //password updates
    //password update
    submitPassword = (e) => {
        e.preventDefault();
        this.setState({passwordSubmitted: true});
        const {dispatch} = this.props;
        if (this.state.password == this.state.cPassword) {
            const newPassword = {
                password: this.state.password
            }
            userService.updateCustomerPassword(this.state.user_id, newPassword).then(res => {
                window.toast('success', `Password is updated!`)
                this.setState({passwordSubmitted: false})
            }).catch(e => {
                //console.log()
                this.setState({passwordSubmitted: false})
            })

        }
    }

    openRearrangeModal = () => {
        let list = []
        this.technicianSorting(this.app.locations[0].technicians.filter(t => t.active == true && t.bookable == true)).forEach((technician) => {
            list.push(
                {
                    content: (<div className="tech-list">
                        <img src={technician.user.imageUrl} alt={technician.user.fullName}/>
                        <p>{technician.user.names.nick}</p>
                    </div>), id: [technician.id]
                }
            )
        })
        this.setState({
            list
        })


    }
    technicianSorting = (techs) => {
        return techs.sort((a, b) => a.displayOrder - b.displayOrder);

    }
    changeOrder = (item, index, newList) => {
        this.setState({
            list: newList
        }, () => {
            userService.changeOrder(item.id, {displayOrder: index + 1}).then(res => {
                const {dispatch} = this.props;
                this.app = JSON.parse(localStorage.getItem('app'));
                dispatch(userActions.getAllTechnicians(0))
            }).catch(err => {
                console.log(err)
            })
        })
    }


    searchService = (event) => {
        //setting state immediately
        this.setState({searchKey: event.target.value})
        //waiting for delay searchkey to fire the request
        this.onchangeTextDelayed(event.target.value)
    }

    onchangeText = async (text) => {
        //need to implement search get method
        //if user type fast it will wait 500ms
        //And will fire the below statement
        let {pageOffset, ctgryId} = this.state;
        this.doFilter(pageOffset, ctgryId, text)

    }

    doFilter = (pageOffset, ctgryId, text) => {
        this.setState({isFiltered: true})
        appService.getAllServicesForStaffdetails(pageOffset, ctgryId, text).then(service => {
            this.setState({
                allServices: service,
                isFiltered: false
            });
        }).catch(e => {
            this.setState({isFiltered: false})
        })
    }


    render() {


        var date = new Date();

        var day = date.getDate();
        var month = date.getMonth();
        var year = date.getFullYear();

        const {updating, profileUpdated, profileUpdateError} = this.props;
        const {
            submitted,
            user,
            userDetails,
            tabCond,
            allServices,
            technicianServices,
            salesData,
            revenues,
            passwordSubmitted,
            passwordUpdated
        } = this.state;


        var dupHtml = [];

        for (var i = 0; i < this.state.count; i++) {
            dupHtml.push(
                <div key={i} className="row">
                    <div className="col-md-1 text-right">
                        <button onClick={this.clickHandlerToDecrease.bind(this)}
                                className="remove btn btn-danger btn-lg btn-circle btn-outline"><i
                            className="fa fa-minus"></i></button>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <input
                                type="text"
                                name={`mingross-${this.state.count}`}
                                onChange={this.handleGross}
                                className="form-control"
                                placeholder="Min Gross"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <input
                                type="text"
                                name={`maxgross-${this.state.count}`}
                                onChange={this.handleGross}
                                className="form-control"
                                placeholder="Max Gross"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <input
                                type="text"
                                name={`compercent-${this.state.count}`}
                                onChange={this.handleGross}
                                className="form-control"
                                placeholder="Percentage"
                            />
                        </div>
                    </div>
                    <div className="col-md-2 text-left">
                        <button className="btn btn-success btn-circle btn-lg btn-outline"
                                onClick={() => this.updateCommissionScale(`mingross-${this.state.count}`, `maxgross-${this.state.count}`, `compercent-${this.state.count}`, '', 'POST')}>
                            <i className="fa fa-save"></i></button>
                    </div>

                </div>
            )
        }


        var options = [];
        var options1 = [];
        var options2 = [];
        if (this.app) {
            const option = this.app.lookupGroup.technicianSpecialties.map(res => {
                return {value: res, label: res}
            });
            const option1 = this.app.lookupGroup.technicianExpertises.map(res => {
                return {value: res, label: res}
            });
            const option2 = this.app.lookupGroup.teams.map(res => {
                return {value: res, label: res}
            });

            var arg3 = options.concat(option)
            var expertises = options1.concat(option1)
            var teams = options1.concat(option2)

        }


        var techDefaultSplts = [];
        var defaultOpt = '';
        if (user.specialities) {
            var defaultValueOption = user.specialities.map(res => {
                return {value: res, label: res}
            });
            defaultOpt = techDefaultSplts.push(defaultValueOption);
        }


        return (

            <div id="page-wrapper">
                {user && userDetails &&
                    <div className="container-fluid">
                        <div className="row bg-title">
                            <div className="col-lg-2 col-md-4 col-sm-4 col-xs-12">
                                <h4 className="page-title">Technician Details</h4>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <StaffSearchBar
                                        place={this.props.place}
                                        id="newCustomer"
                                        clearPlace={this.props.clearPlace}
                                        getNewValue={this.getNewValue}
                                        onSelectTechnician={this.onSelectTechnician}/>
                                </div>
                            </div>
                            <div className="col-lg-7 col-sm-8 col-md-8 col-xs-12">
                                <ol className="breadcrumb">
                                    <li><Link to={`/${this.storeId}`}>Dashboard</Link></li>
                                    <li><Link to={`/${this.storeId}/staffs`}>Staff</Link></li>
                                    <li className="active">Staff Details</li>
                                </ol>
                            </div>
                        </div>


                        <div className="row">

                            <div className="col-md-5 col-lg-4 col-xs-12">
                                <div className="white-box">
                                    {user.profilePic &&
                                        <div className="user-bg">
                                            <img width="100%" alt="user" src={user.profilePic}/>
                                        </div>
                                    }
                                    {!user.profilePic &&
                                        <div className="user-bg loading"></div>
                                    }
                                    <div className="user-btm-box">
                                        <div
                                            className={user.firstName ? 'row text-center m-t-10' : 'row text-center m-t-10 before-loading'}>
                                            <div className="col-md-6 col-sm-6 b-r"><strong>Name</strong>
                                                <p><i className="fa fa-user"></i>{user.firstName + " " + user.lastName}
                                                </p>
                                            </div>
                                            <div className="col-md-6 col-sm-6"><strong>Phone</strong>
                                                <p><i className="fa fa-phone"></i>{this.state.user.mobileNumber ?
                                                    <a href={"tel:" + helperFunctions.formatMobilePhone(user.mobileNumber)}>{helperFunctions.formatMobilePhone(this.state.user.mobileNumber)}</a> : ''}
                                                </p>
                                            </div>

                                        </div>
                                        <hr/>
                                        <div
                                            className={userDetails.createdTime ? 'row text-center m-t-10' : 'row text-center m-t-10 before-loading'}>
                                            <div className="col-md-6 col-sm-6 b-r"><strong>Joining Date</strong>
                                                <p><i
                                                    className="fa fa-calender"></i> {helperFunctions.formatDateTimeWithDay(userDetails.createdTime, 'latest')} {helperFunctions.formatDateTimeWithDay(userDetails.createdTime, 'date')}
                                                </p>
                                            </div>

                                            <div className="col-md-6 col-sm-6 b-r"><strong>Referred By</strong>
                                                <p><i className="fa fa-user-plus"></i>{userDetails.referredByUser ?
                                                    <a onClick={() => {
                                                        this.getUser(userDetails.referredByUser.id)
                                                    }}> {userDetails.referredByUser.fullName} </a> : ''}</p>
                                            </div>

                                        </div>
                                        <hr/>
                                        <div
                                            className={user.referralToken ? 'row text-center m-t-10' : 'row text-center m-t-10 before-loading'}>
                                            <div className="col-md-6 col-sm-6 b-r"><strong>Email
                                                Address</strong><br/><br/>
                                                <p><i className="fa fa-envelope"></i>{user.email}</p>
                                            </div>

                                            <div className="col-md-6 col-sm-6"><strong>Next Birthday</strong><br/><br/>
                                                <p><i
                                                    className="fa fa-calender"></i>{helperFunctions.formatDateTime(userDetails.nextBirthdayRewardDateTime, 'date')}
                                                </p>

                                            </div>
                                        </div>
                                        <hr/>
                                        <div
                                            className={user.referralToken ? 'row m-t-10' : 'row m-t-10 before-loading'}>
                                            <div className="col-md-12">
                                                <strong>Last Visit:</strong> &nbsp;
                                                {userDetails.statusTime && helperFunctions.formatDateTimeWithDay(moment(userDetails.statusTime), 'latest')}
                                                <br/>
                                                <strong>Required Password Change:</strong> &nbsp; <i
                                                className="fa fa-check text-success"></i><br/><br/>
                                                <strong>Can Book Online:</strong> &nbsp; <i
                                                className="fa fa-check text-success"></i><br/><br/>
                                                <strong>Pending Consent Forms:</strong> &nbsp; <i
                                                className="fa fa-check text-success"></i>

                                            </div>
                                        </div>
                                        <hr/>

                                        <div
                                            className={user.referralToken ? 'row m-t-10' : 'row m-t-10 before-loading'}>
                                            <div className="col-md-4 col-sm-4 b-r">
                                                <p className="text-blue">Reward Balance</p>
                                                <h1 className="text-left">{(user.rewardBalance * 1).toFixed(2)}</h1>
                                            </div>

                                            <div className="col-md-4 col-sm-4  b-r text-center">
                                                <p className="text-danger">ReferralToken</p>
                                                <h1 className="referral-token">{user.referralToken}</h1>
                                            </div>
                                            <div className="col-md-4 col-sm-4 text-right"><p
                                                className="text-purple">Referral Earnings</p>
                                                <h1>{user.totalEarningsAsReferrer}</h1>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="col-md-7 col-lg-8 col-xs-12">
                                <div className="customer-details-tab">
                                    <ul className={user.referralToken ? 'nav nav-tabs tabs customtab' : 'nav nav-tabs tabs customtab before-loading'}>
                                        <li className="tab active">
                                            <a onClick={this.initializeRelativeMethod} href="#info" data-toggle="tab"
                                               aria-expanded="true"> <span className="visible-xs"><i
                                                className="fa fa-cog"></i></span> <span
                                                className="hidden-xs">Info</span>
                                            </a>
                                        </li>

                                        <li className="tab">
                                            <a href="#hours" data-toggle="tab"
                                               aria-expanded="true"> <span className="visible-xs"><i
                                                className="fa fa-alarm"></i></span> <span
                                                className="hidden-xs">Hours</span> </a>
                                        </li>

                                        <li className="tab">
                                            <a href="#services" data-toggle="tab" aria-expanded="true"> <span
                                                className="visible-xs"><i className="fa fa-list"></i></span> <span
                                                className="hidden-xs">Services</span> </a>

                                        </li>

                                        {user.compensationStrategy == 'Commission' &&
                                            <li className="tab">
                                                <a href="#scale" data-toggle="tab" aria-expanded="true"> <span
                                                    className="visible-xs"><i
                                                    className="fa fa-balance-scale"></i></span> <span
                                                    className="hidden-xs">Commission</span> </a>
                                            </li>
                                        }


                                        <li className="tab">
                                            <a onClick={this.clearDate} href="#revenue" data-toggle="tab"
                                               aria-expanded="true"> <span
                                                className="visible-xs"><i className="fa fa-balance-scale"></i></span>
                                                <span
                                                    className="hidden-xs">Sales</span> </a>
                                        </li>


                                        {user.compensationStrategy != 'Commission' &&
                                            <li className="tab">
                                                <a onClick={() => this.clearSales()} href="#timeClock"
                                                   data-toggle="tab" aria-expanded="true"> <span className="visible-xs"><i
                                                    className="ti ti-list"></i></span> <span
                                                    className="hidden-xs">Time Cards</span> </a>
                                            </li>
                                        }

                                    </ul>
                                    <div className="tab-content">
                                        <div className="tab-pane active fade in" id="info">

                                            <div className="tech-add-form settings">
                                                <form>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div
                                                                className={user.referralToken ? 'form-group' : 'form-group before-loading'}>
                                                                <label htmlFor="fname">First Name &nbsp;  <span
                                                                    className="text-danger">*</span></label>
                                                                <input
                                                                    required
                                                                    maxLength="40"
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="firstName"
                                                                    value={user.firstName}
                                                                    onChange={this.handleChange}
                                                                />

                                                                <p>{this.state.errors.firstName &&
                                                                    <InlineMessage
                                                                        text={this.state.errors.firstName}/>}</p>

                                                            </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <div
                                                                className={user.referralToken ? 'form-group' : 'form-group before-loading'}>
                                                                <label htmlFor="lname">Last Name &nbsp; <span
                                                                    className="text-danger">*</span></label>
                                                                <input
                                                                    required
                                                                    maxLength="40"
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="lastName"
                                                                    value={user.lastName}
                                                                    onChange={this.handleChange}
                                                                />
                                                                <p>{this.state.errors.lastName &&
                                                                    <InlineMessage
                                                                        text={this.state.errors.lastName}/>}</p>

                                                            </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <div
                                                                className={user.referralToken ? 'form-group' : 'form-group before-loading'}>
                                                                <label htmlFor="nickName">Nick Name &nbsp; <span
                                                                    className="text-danger">*</span></label>
                                                                <input
                                                                    required
                                                                    maxLength="40"
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="nickName"
                                                                    id="nickName"
                                                                    value={user.nickName}
                                                                    onChange={this.handleChange}
                                                                />

                                                                <p>{this.state.errors.nickName &&
                                                                    <InlineMessage
                                                                        text={this.state.errors.nickName}/>}</p>

                                                            </div>
                                                        </div>

                                                    </div>


                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div
                                                                className={user.referralToken ? 'form-group' : 'form-group before-loading'}>
                                                                <label htmlFor="email">Email Address &nbsp; <span
                                                                    className="text-danger">*</span></label>
                                                                <input
                                                                    required
                                                                    maxLength="100"
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="email"
                                                                    id="email"
                                                                    value={user.email}
                                                                    onChange={this.handleChange}
                                                                />

                                                                <p>{this.state.errors.emailAddress &&
                                                                    <InlineMessage
                                                                        text={this.state.errors.emailAddress}/>}</p>

                                                            </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <div
                                                                className={user.referralToken ? 'form-group' : 'form-group before-loading'}>
                                                                <label htmlFor="phoneNumber">Phone Number &nbsp; <span
                                                                    className="text-danger">*</span></label>
                                                                <input
                                                                    required
                                                                    maxLength="20"
                                                                    className="form-control mobileNumber"
                                                                    type="tel"
                                                                    name="mobileNumber"
                                                                    id="phoneNumber"
                                                                    value={user.mobileNumber}
                                                                    onChange={this.handleChange}
                                                                />
                                                                <p>{this.state.errors.phoneNumber &&
                                                                    <InlineMessage
                                                                        text={this.state.errors.phoneNumber}/>}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div
                                                                className={user.referralToken ? 'form-group' : 'form-group before-loading'}>
                                                                <label htmlFor="phoneNumber">Gender &nbsp; <span
                                                                    className="text-danger">*</span></label>
                                                                <select
                                                                    required
                                                                    onChange={this.handleChange}
                                                                    className="form-control"
                                                                    name="gender"
                                                                    value={user.gender}>
                                                                    <option value="Male">MALE</option>
                                                                    <option value="Female">FEMALE</option>

                                                                </select>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div
                                                                className={user.referralToken ? 'form-group' : 'form-group before-loading'}>
                                                                <label htmlFor="db">Date Of Birth</label>
                                                                <input
                                                                    type="date"
                                                                    name="birthDate"
                                                                    id="db"
                                                                    value={user.birthDate}
                                                                    onChange={this.handleChange}/>
                                                                <p>{this.state.errors.birthDate &&
                                                                    <InlineMessage
                                                                        text={this.state.errors.birthDate}/>}</p>
                                                            </div>
                                                        </div>


                                                        <div className="col-md-6">
                                                            <div
                                                                className={user.referralToken ? 'form-group' : 'form-group before-loading'}>
                                                                <label htmlFor="rep">Rep. Color &nbsp;  <span
                                                                    className="text-danger">*</span></label>
                                                                <input
                                                                    type="color"
                                                                    name="representationColor"
                                                                    value={user.representationColor}
                                                                    onChange={this.handleChange}/>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-4 techs-specialities">
                                                            <div
                                                                className={user.referralToken ? 'form-group' : 'form-group before-loading'}>
                                                                <label htmlFor="team">Choose a team &nbsp; <span
                                                                    className="text-danger">*</span></label>
                                                                {/*<select*/}
                                                                {/*    required*/}
                                                                {/*    name="team"*/}
                                                                {/*    onChange={this.handleChange}*/}
                                                                {/*    className="form-control"*/}
                                                                {/*    value={user.team}>*/}
                                                                {/*    {this.app && this.app.lookupGroup.teams.map((t, key) => {*/}
                                                                {/*        return <option value={t} key={key}>{t}</option>*/}
                                                                {/*    })}*/}
                                                                {/*</select>*/}

                                                                <CreatableSelect
                                                                    onChange={this.selectTeams}
                                                                    options={teams}
                                                                    isMulti={false}
                                                                    isSearchable={true}
                                                                    defaultValue={this.state.teams}
                                                                />

                                                            </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <div
                                                                className={user.referralToken ? 'form-group' : 'form-group before-loading'}>
                                                                <label htmlFor="joinDate">Account Status &nbsp; <span
                                                                    className="text-danger">*</span></label>
                                                                <select
                                                                    onChange={this.handleChange}
                                                                    required className="form-control"
                                                                    name="accountStatus"
                                                                    value={user.accountStatus}
                                                                >
                                                                    <option value="Active">Active</option>
                                                                    <option value="Inactive">Inactive</option>
                                                                    <option value="Invisible">Invisible</option>

                                                                </select>

                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div
                                                                className={user.referralToken ? 'form-group' : 'form-group before-loading'}>
                                                                <label htmlFor="instagramUrl">Instagram Url  &nbsp;<span
                                                                    className="text-danger">*</span></label>
                                                                <input
                                                                    required
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="instagramUrl"
                                                                    id="instagramUrl"
                                                                    value={user.instagramUrl}
                                                                    onChange={this.handleChange}
                                                                />

                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="row">

                                                        <div className="col-md-6 techs-specialities">
                                                            <div
                                                                className={user.referralToken ? 'form-group' : 'form-group before-loading'}>
                                                                <label htmlFor="specialities">Expertise &nbsp; <span
                                                                    className="text-danger">*</span></label>

                                                                {/*<select*/}
                                                                {/*    value={user.expertise}*/}
                                                                {/*    name="expertise"*/}
                                                                {/*    onChange={this.handleChange}*/}
                                                                {/*>*/}

                                                                {/*    {this.app && this.app.lookupGroup.technicianExpertises.map((e, key) => {*/}
                                                                {/*        return <option value={e} key={key}>{e}</option>*/}
                                                                {/*    })}*/}
                                                                {/*</select>*/}

                                                                <CreatableSelect
                                                                    onChange={this.selectExpertise}
                                                                    options={expertises}
                                                                    isMulti={false}
                                                                    isSearchable={true}
                                                                    defaultValue={this.state.experts}
                                                                />


                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 techs-specialities">
                                                            <div
                                                                className={user.referralToken ? 'form-group' : 'form-group before-loading'}>
                                                                <label htmlFor="specialities">Specialtiess &nbsp; <span
                                                                    className="text-danger">*</span></label>
                                                                {/*<Select*/}

                                                                {/*    onChange={this.handleSelect}*/}
                                                                {/*    options={arg3}*/}
                                                                {/*    isMulti={true}*/}
                                                                {/*    isSearchable={true}*/}
                                                                {/*    defaultValue={this.state.selectedSpecialities}*/}

                                                                {/*/>*/}

                                                                <CreatableSelect
                                                                    onChange={this.handleSelect}
                                                                    options={arg3}
                                                                    isMulti={true}
                                                                    isSearchable={true}
                                                                    defaultValue={this.state.selectedSpecialities}
                                                                />

                                                                <p>{this.state.errors.specialities &&
                                                                    <InlineMessage
                                                                        text={this.state.errors.specialities}/>}</p>

                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div
                                                                className={user.referralToken ? 'form-group' : 'form-group before-loading'}>
                                                                <label htmlFor="wage">Pay Option &nbsp; <span
                                                                    className="text-danger">*</span></label>
                                                                <select
                                                                    name="compensationStrategy"
                                                                    value={user.compensationStrategy}
                                                                    onChange={this.handleChange}
                                                                >
                                                                    <option value="Hourly">Hourly</option>
                                                                    <option value="Weekly">Weekly</option>
                                                                    <option value="Commission">Commission</option>
                                                                </select>
                                                            </div>
                                                            <div
                                                                className={user.referralToken ? 'form-group' : 'form-group before-loading'}>
                                                                <label>Product Commission &nbsp; <span
                                                                    className="text-danger">*</span></label>
                                                                <div className="commission-percent">
                                                                    <input
                                                                        type="text"
                                                                        name="productCommissionPercentage"
                                                                        onChange={this.handleChange}
                                                                        value={(user.productCommissionPercentage * 1).toFixed(2)}
                                                                    /> <b>%</b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            {user.compensationStrategy == 'Hourly' &&
                                                                <div
                                                                    className={user.referralToken ? 'form-group' : 'form-group before-loading'}>
                                                                    <label>Pay Rate  &nbsp; <span
                                                                        className="text-danger">*</span></label>

                                                                    <input
                                                                        className="form-control"
                                                                        name="strategyWage"
                                                                        onChange={this.handleChange}
                                                                        onBlur={this.formatwage}
                                                                        value={user.strategyWage}
                                                                        type="text"/>

                                                                </div>
                                                            }
                                                            {user.compensationStrategy == 'Weekly' &&
                                                                <div
                                                                    className={user.referralToken ? 'form-group' : 'form-group before-loading'}>
                                                                    <label>Pay Rate  &nbsp; <span
                                                                        className="text-danger">*</span></label>

                                                                    <input
                                                                        className="form-control"
                                                                        name="strategyWage"
                                                                        onChange={this.handleChange}
                                                                        onBlur={this.formatwage}
                                                                        value={user.strategyWage}
                                                                        type="text"/>

                                                                </div>
                                                            }
                                                            {user.compensationStrategy == 'Commission' &&
                                                                <div
                                                                    className={user.referralToken ? 'form-group' : 'form-group before-loading'}>
                                                                    <label>Pay Rate  &nbsp; <span
                                                                        className="text-danger">*</span></label>

                                                                    <input
                                                                        className="form-control"
                                                                        name="strategyWage"
                                                                        onChange={this.handleChange}
                                                                        onBlur={this.formatwage}
                                                                        value={user.strategyWage}
                                                                        disabled
                                                                        type="text"/>

                                                                </div>
                                                            }
                                                            <div
                                                                className={user.referralToken ? 'form-group' : 'form-group before-loading'}>
                                                                <label>Cash Ratio  &nbsp; <span
                                                                    className="text-danger">*</span></label>
                                                                <div className="relative">
                                                                    <input
                                                                        className="form-control"
                                                                        name="strategyWage"
                                                                        value={user.cashPaymentPercentage}
                                                                        onChange={this.handleChange}
                                                                        type="text"/>
                                                                    <b className="percentage">%</b>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div
                                                                className={user.referralToken ? 'form-group bio' : 'form-group bio before-loading'}>
                                                                <label>Describe the Bio: &nbsp;<span
                                                                    className="text-danger">*</span></label>
                                                                <textarea
                                                                    required
                                                                    onChange={this.handleChange}
                                                                    maxLength="1000"
                                                                    className="form-control"
                                                                    name="biography"
                                                                    value={user.biography}></textarea>
                                                            </div>
                                                            <p>{this.state.errors.biography &&
                                                                <InlineMessage text={this.state.errors.biography}/>}</p>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div
                                                                className={user.referralToken ? 'form-group' : 'form-group before-loading'}>
                                                                <label htmlFor="">Referral Token</label>
                                                                <input
                                                                    readOnly
                                                                    type="text"
                                                                    value={user.referralToken}
                                                                />
                                                            </div>

                                                            <div
                                                                className={user.referralToken ? 'flex align-middle' : 'flex align-middle before-loading'}>
                                                                <label className="switch">
                                                                    <input type="checkbox" name="smsNotification"
                                                                           checked={user.smsNotification} readOnly
                                                                           onClick={this.handleChange}
                                                                    />
                                                                    <span className="switchSlider"></span>
                                                                </label>
                                                                <label
                                                                    htmlFor="smsNotification">&nbsp; &nbsp; &nbsp; Text
                                                                    Notifications</label>
                                                            </div>
                                                            <div
                                                                className={user.referralToken ? 'flex align-middle' : 'flex align-middle before-loading'}>
                                                                <label className="switch">
                                                                    <input type="checkbox" name="emailNotification"
                                                                           checked={user.emailNotification} readOnly
                                                                           onClick={this.handleChange}
                                                                    />
                                                                    <span className="switchSlider"></span>
                                                                </label>
                                                                <label
                                                                    htmlFor="emailNotification"> &nbsp; &nbsp; &nbsp; Email
                                                                    Notifications</label>
                                                            </div>
                                                            <div
                                                                className={user.referralToken ? 'flex align-middle' : 'flex align-middle before-loading'}>
                                                                <label className="switch">
                                                                    <input type="checkbox" name="clockReminderEnabled"
                                                                           checked={user.clockReminderEnabled} readOnly
                                                                           onClick={this.handleChange}
                                                                    />
                                                                    <span className="switchSlider"></span>
                                                                </label>
                                                                <label
                                                                    htmlFor="clockReminderEnabled">&nbsp; &nbsp; &nbsp; Clock
                                                                    In Reminder</label>
                                                            </div>
                                                            <div
                                                                className={user.referralToken ? 'flex align-middle' : 'flex align-middle before-loading'}>
                                                                <label className="switch">
                                                                    <input type="checkbox" name="customerBookingAllowed"
                                                                           checked={user.customerBookingAllowed}
                                                                           readOnly
                                                                           onClick={this.handleChange}
                                                                    />
                                                                    <span className="switchSlider"></span>
                                                                </label>
                                                                <label
                                                                    htmlFor="customerBookingAllowed">&nbsp; &nbsp; &nbsp; Customer
                                                                    Booking Allowed</label>
                                                            </div>
                                                            {this.MAN_DIREC === true &&
                                                                <div
                                                                    className={user.referralToken ? 'flex align-middle' : 'flex align-middle before-loading'}>
                                                                    <label className="switch">
                                                                        <input type="checkbox"
                                                                               name="acceptClientRequestEnabled"
                                                                               checked={user.acceptClientRequestEnabled}
                                                                               readOnly
                                                                               onClick={this.handleChange}
                                                                        />
                                                                        <span className="switchSlider"></span>
                                                                    </label>
                                                                    <label
                                                                        htmlFor="acceptClientRequestEnabled">&nbsp; &nbsp; &nbsp; Accept
                                                                        Client Calls</label>
                                                                </div>
                                                            }
                                                            {this.MAN_DIREC === true &&
                                                                <div
                                                                    className={user.referralToken ? 'flex align-middle' : 'flex align-middle before-loading'}>
                                                                    <label className="switch">
                                                                        <input type="checkbox"
                                                                               name="customerAccessAllowed"
                                                                               checked={user.customerAccessAllowed}
                                                                               readOnly
                                                                               onClick={this.handleChange}
                                                                        />
                                                                        <span className="switchSlider"></span>
                                                                    </label>
                                                                    <label
                                                                        htmlFor="customerAccessAllowed">&nbsp; &nbsp; &nbsp;Allow
                                                                        customer access</label>
                                                                </div>
                                                            }
                                                            {this.MAN_DIREC === true &&
                                                                <div
                                                                    className={user.referralToken ? 'flex align-middle' : 'flex align-middle before-loading'}>
                                                                    <label className="switch">
                                                                        <input type="checkbox"
                                                                               name="appointmentSchedulingAllowed"
                                                                               checked={user.appointmentSchedulingAllowed}
                                                                               readOnly
                                                                               onClick={this.handleChange}
                                                                        />
                                                                        <span className="switchSlider"></span>
                                                                    </label>
                                                                    <label
                                                                        htmlFor="customerAccessAllowed">&nbsp; &nbsp; &nbsp;Allow
                                                                        appointment scheduling</label>
                                                                </div>
                                                            }
                                                            {this.MAN_DIREC === true &&
                                                                <div
                                                                    className={user.referralToken ? 'flex align-middle' : 'flex align-middle before-loading'}>
                                                                    <label className="switch">
                                                                        <input type="checkbox"
                                                                               name="autoLogoutEnabled"
                                                                               checked={user.autoLogoutEnabled}
                                                                               readOnly
                                                                               onClick={this.handleChange}
                                                                        />
                                                                        <span className="switchSlider"></span>
                                                                    </label>
                                                                    <label
                                                                        htmlFor="autoLogoutEnabled">&nbsp; &nbsp; &nbsp;Enable
                                                                        auto Logout</label>
                                                                </div>
                                                            }

                                                        </div>
                                                    </div>


                                                    <div className='row'>

                                                        <div
                                                            className={user.referralToken ? 'col-md-3 col-md-offset-9 text-right' : 'col-md-3 col-md-offset-9 text-right before-loading'}>
                                                            <button onClick={this.updateStaffInfos}
                                                                    className="btn btn-custom-info">Update Info
                                                            </button>
                                                        </div>
                                                    </div>

                                                </form>
                                            </div>
                                            <div
                                                className={user.referralToken ? 'settings' : 'settings before-loading'}>
                                                <h4>Update Password</h4>
                                                <form name="form" className="form-horizontal form-material"
                                                      onSubmit={this.submitPassword}>

                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div
                                                                className={'form-group' + (passwordSubmitted && !user.password ? ' has-error' : '')}>
                                                                <label className="col-md-12" htmlFor="password">NEW
                                                                    PASSWORD*</label>
                                                                <div className="col-md-12">
                                                                    <input placeholder="*******" type="password"
                                                                           name="password" value={this.state.password}
                                                                           onChange={this.handleChange}/>
                                                                    {passwordSubmitted && !this.state.password &&
                                                                        <div className="help-block">Password is
                                                                            required</div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div
                                                                className={'form-group' + (passwordSubmitted && !(this.state.password === this.state.cPassword) ? 'is-invalid' : '')}>
                                                                <label className="col-md-12" htmlFor="cPassword">CONFIRM
                                                                    PASSWORD*</label>
                                                                <div className="col-md-12">
                                                                    <input placeholder="*******" type="password"
                                                                           name="cPassword" value={this.state.cPassword}
                                                                           onChange={this.handleChange}/>
                                                                    {passwordSubmitted && !(this.state.password === this.state.cPassword) &&
                                                                        <div className="help-block">Password and confirm
                                                                            password fields do not match.</div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="form-group">
                                                        <div className="col-sm-12">
                                                            {
                                                                this.MAN_DIREC &&
                                                                <button className="btn btn-custom-info"
                                                                        type="submit">Update
                                                                    Password</button>
                                                            }
                                                        </div>
                                                    </div>
                                                    {
                                                        passwordSubmitted && passwordUpdated &&
                                                        window.customerUpdateSuccess()
                                                    }
                                                </form>
                                            </div>


                                        </div>

                                        <div className="tab-pane fade" id="hours">

                                            <div className="settings">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <label htmlFor="team">Working Hour &nbsp; <span
                                                            className="text-danger">*</span></label>
                                                    </div>

                                                </div>


                                                <div className="table-responsive">
                                                    <table className="table">
                                                        <thead>
                                                        <tr>
                                                            <th>Day</th>
                                                            <th>Start Time</th>
                                                            <th>End Time</th>
                                                            <th>Week</th>
                                                            <th>Starting Date</th>
                                                            <th>Action</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {user.hours && user.hours.length > 0 &&
                                                            <tr>
                                                                <td>{user.monday.dayOfWeek}</td>
                                                                <td>{user.monday.from}</td>
                                                                <td>{user.monday.to}</td>
                                                                <td>{user.monday.pattern}</td>
                                                                <td>{user.monday.patternStartDate}</td>
                                                                <td>
                                                                    <button data-toggle="modal"
                                                                            data-target="#hoursModal"
                                                                            onClick={() => this.openHoursModal('monday')}
                                                                            className="no-border"><i
                                                                        className="fa fa-pencil text-info"></i></button>
                                                                </td>
                                                            </tr>}

                                                        {user.hours && user.hours.length > 1 && <tr>
                                                            <td>{user.tuesday.dayOfWeek}</td>
                                                            <td>{user.tuesday.from}</td>
                                                            <td>{user.tuesday.to}</td>
                                                            <td>{user.tuesday.pattern}</td>
                                                            <td>{user.tuesday.patternStartDate}</td>
                                                            <td>
                                                                <button data-toggle="modal" data-target="#hoursModal"
                                                                        onClick={() => this.openHoursModal('tuesday')}
                                                                        className="no-border"><i
                                                                    className="fa fa-pencil text-info"></i></button>
                                                            </td>
                                                        </tr>}
                                                        {user.hours && user.hours.length > 2 && <tr>
                                                            <td>{user.wednesday.dayOfWeek}</td>
                                                            <td>{user.wednesday.from}</td>
                                                            <td>{user.wednesday.to}</td>
                                                            <td>{user.wednesday.pattern}</td>
                                                            <td>{user.wednesday.patternStartDate}</td>
                                                            <td>
                                                                <button data-toggle="modal" data-target="#hoursModal"
                                                                        onClick={() => this.openHoursModal('wednesday')}
                                                                        className="no-border"><i
                                                                    className="fa fa-pencil text-info"></i></button>
                                                            </td>
                                                        </tr>}
                                                        {user.hours && user.hours.length > 3 && <tr>
                                                            <td>{user.thursday.dayOfWeek}</td>
                                                            <td>{user.thursday.from}</td>
                                                            <td>{user.thursday.to}</td>
                                                            <td>{user.thursday.pattern}</td>
                                                            <td>{user.thursday.patternStartDate}</td>
                                                            <td>
                                                                <button data-toggle="modal" data-target="#hoursModal"
                                                                        onClick={() => this.openHoursModal('thursday')}
                                                                        className="no-border"><i
                                                                    className="fa fa-pencil text-info"></i></button>
                                                            </td>
                                                        </tr>}
                                                        {user.hours && user.hours.length > 4 && <tr>
                                                            <td>{user.friday.dayOfWeek}</td>
                                                            <td>{user.friday.from}</td>
                                                            <td>{user.friday.to}</td>
                                                            <td>{user.friday.pattern}</td>
                                                            <td>{user.friday.patternStartDate}</td>
                                                            <td>
                                                                <button data-toggle="modal" data-target="#hoursModal"
                                                                        onClick={() => this.openHoursModal('friday')}
                                                                        className="no-border"><i
                                                                    className="fa fa-pencil text-info"></i></button>
                                                            </td>
                                                        </tr>}
                                                        {user.hours && user.hours.length > 5 && <tr>
                                                            <td>{user.saturday.dayOfWeek}</td>
                                                            <td>{user.saturday.from}</td>
                                                            <td>{user.saturday.to}</td>
                                                            <td>{user.saturday.pattern}</td>
                                                            <td>{user.saturday.patternStartDate}</td>
                                                            <td>
                                                                <button data-toggle="modal" data-target="#hoursModal"
                                                                        onClick={() => this.openHoursModal('saturday')}
                                                                        className="no-border"><i
                                                                    className="fa fa-pencil text-info"></i></button>
                                                            </td>
                                                        </tr>}
                                                        {user.hours && user.hours.length > 6 && <tr>
                                                            <td>{user.sunday.dayOfWeek}</td>
                                                            <td>{user.sunday.from}</td>
                                                            <td>{user.sunday.to}</td>
                                                            <td>{user.sunday.pattern}</td>
                                                            <td>{user.sunday.patternStartDate}</td>
                                                            <td>
                                                                <button data-toggle="modal" data-target="#hoursModal"
                                                                        onClick={() => this.openHoursModal('sunday')}
                                                                        className="no-border"><i
                                                                    className="fa fa-pencil text-info"></i></button>
                                                            </td>
                                                        </tr>}


                                                        </tbody>
                                                    </table>
                                                </div>
                                                {this.state.loading == true &&
                                                    <div className="custom-loading">
                                                        <div className="loading"><i
                                                            className="fa fa-spinner fa-spin"></i></div>
                                                    </div>
                                                }
                                            </div>

                                        </div>


                                        {user.compensationStrategy == 'Commission' &&

                                            <div className="tab-pane fade" id="scale">

                                                <div id="scale-row" className="settings">
                                                    <label>Commission Scale: <button
                                                        onClick={this.clickHandlerToIncrease.bind(this)}
                                                        className="btn btn-info" id="addOneRow">Add Row</button>
                                                    </label>
                                                    <br/>

                                                    {user.formulas && user.formulas.length > 0 && user.formulas.map((s, k) => {
                                                        return <div key={k} className="single-scale">
                                                            <div className="row">

                                                                <div className="col-md-3 col-md-offset-1">
                                                                    <div className="form-group">
                                                                        <input
                                                                            name={"mingross-" + s.id}
                                                                            required
                                                                            onChange={this.handleGross}
                                                                            defaultValue={this.state.selectedScale["mingross-" + s.id] ? (this.state.selectedScale["mingross-" + s.id] * 1).toFixed(2) : (s.minGrossAmount * 1).toFixed(2)}
                                                                            type="text"/>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <div className="form-group">
                                                                        <input
                                                                            className="form-control"
                                                                            name={"maxgross-" + s.id}
                                                                            required
                                                                            onChange={this.handleGross}
                                                                            defaultValue={this.state.selectedScale["maxgross-" + s.id] ? (this.state.selectedScale["maxgross-" + s.id] * 1).toFixed(2) : (s.maxGrossAmount * 1).toFixed(2)}
                                                                            type="text"/>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <div className="form-group">
                                                                        <input
                                                                            onChange={this.handleGross}
                                                                            name={"compercent-" + s.id}
                                                                            defaultValue={this.state.selectedScale["compercent-" + s.id] ? (this.state.selectedScale["compercent-" + s.id] * 1).toFixed(2) : (s.commissionPercentage * 1).toFixed(2)}
                                                                            type="text"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2 text-left">
                                                                    <button
                                                                        onClick={() => this.updateCommissionScale("mingross-" + s.id, "maxgross-" + s.id, "compercent-" + s.id, s.id, 'PUT')}
                                                                        className="btn btn-info btn-lg btn-outline btn-circle">
                                                                        <i className="fa fa-pencil"></i></button>
                                                                    <button
                                                                        onClick={() => this.updateCommissionScale('', '', '', s.id, 'DELETE')}
                                                                        className="btn btn-danger btn-lg btn-outline btn-circle">
                                                                        <i className="fa fa-trash"></i></button>
                                                                </div>

                                                            </div>

                                                        </div>


                                                    })
                                                    }

                                                    {dupHtml && dupHtml}


                                                </div>
                                            </div>
                                        }


                                        <div className="tab-pane fade" id="revenue">
                                            <div className="sales">
                                                <div className="date-selector">
                                                    <div>
                                                        {this.state.salesCount != 1 &&
                                                            <button onClick={this.getPrevSales}><i
                                                                className="fa fa-angle-left fa-2x"></i></button>
                                                        }
                                                        &nbsp; <span data-target="#pickerModal" data-toggle="modal"
                                                                     onClick={this.openPickerModal}>{helperFunctions.getTimelineDate(this.state.defaultStartDate)} - {helperFunctions.getTimelineDate(this.state.defaultEndDate)} </span> &nbsp;
                                                        {this.state.salesCount != this.state.maxCount && this.state.maxCount != 1 &&
                                                            <button onClick={this.getNextSales}><i
                                                                className="fa fa-angle-right fa-2x"></i></button>}
                                                    </div>
                                                </div>
                                                <div className="day-wrapper">
                                                    <div className="total-sales">
                                                        <div className="total-service text-center">
                                                            <h4 className="fade-title">Total (Services)</h4>
                                                            <h3 className="dark-title">${((this.state.satT.satS * 1) + (this.state.sunT.sunS * 1) + (this.state.monT.monS * 1) + (this.state.tueT.tueS * 1) + (this.state.wedT.wedS * 1) + (this.state.thuT.thuS * 1) + (this.state.friT.friS * 1)).toFixed(2)}</h3>

                                                        </div>
                                                        <div className="total-product text-center">
                                                            <h4 className="fade-title">Total (Products)</h4>
                                                            <h3 className="dark-title">${this.totalProducts()}</h3>
                                                        </div>
                                                    </div>

                                                    {revenues && revenues.length > 0 && revenues.map((rev, key) => {
                                                        return <div key={key} className="row  parent-row">
                                                            <div className="col-md-2">
                                                                <div className="day-title">
                                                                    <div className="name">{rev.day}</div>
                                                                    <div
                                                                        className="day-count"> {rev.date}</div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-8">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div className="blocks">
                                                                            <h4 className="fade-title">Services</h4>
                                                                            <h4 className="dark-title">${(rev.serviceChargeAmount * 1).toFixed(2)}</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="blocks">
                                                                            <h4 className="fade-title">Products</h4>
                                                                            <h4 className="dark-title">${(rev.productPurchaseAmount * 1).toFixed(2)}</h4>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {rev && rev.singleDayData.length > 0 && rev.singleDayData.map((s, k) => {
                                                                    return <div key={k}
                                                                                className="row custom-padding row-inactive">
                                                                        <div className="col-md-12">

                                                                            <div className="blocks col-md-6">
                                                                                {s.visitTechnicians.length > 0 && s.visitTechnicians.map((t, ks) => {
                                                                                    return <ul key={ks}>
                                                                                        <li>
                                              <span
                                                  className="title">{t.offeredService.name}</span>
                                                                                            <span
                                                                                                className="value">{t.chargeAmount != '-1' ? '$' + (t.chargeAmount * 1).toFixed(2) : 'Per Consult'}</span>
                                                                                        </li>
                                                                                        <li>
                                              <span
                                                  className="title">{s.client}</span>
                                                                                            <span
                                                                                                className="value">{s.bookedTime}</span>
                                                                                        </li>
                                                                                    </ul>
                                                                                })}
                                                                                {s.visitTechnicians.length == 0 &&
                                                                                    <ul>
                                                                                        <li>
                                              <span
                                                  className="title">----</span>
                                                                                            <span
                                                                                                className="value">----</span>
                                                                                        </li>
                                                                                        <li>
                                              <span
                                                  className="title">----</span>
                                                                                            <span
                                                                                                className="value">----</span>
                                                                                        </li>
                                                                                    </ul>
                                                                                }
                                                                            </div>


                                                                            <div className="blocks  col-md-6">
                                                                                {s.purchaseItems.length > 0 && s.purchaseItems.map((p, pk) => {
                                                                                    return <ul key={pk}>
                                                                                        <li>
                                              <span
                                                  className="title">{p.locatedProductVariant.variant.product.name}</span>
                                                                                            <span
                                                                                                className="value">{p.locatedProductVariant.variant.product.brand.name}</span>
                                                                                        </li>
                                                                                        <li>
                                              <span
                                                  className="title">Qty: {p.quantity}</span>
                                                                                            <span
                                                                                                className="value">${(p.chargeAmount * 1).toFixed(2)}</span>
                                                                                        </li>
                                                                                    </ul>
                                                                                })}
                                                                                {s.purchaseItems.length == 0 &&
                                                                                    <ul>
                                                                                        <li>
                                              <span
                                                  className="title">----</span>
                                                                                            <span
                                                                                                className="value">----</span>
                                                                                        </li>
                                                                                        <li>
                                              <span
                                                  className="title">----</span>
                                                                                            <span
                                                                                                className="value">----</span>
                                                                                        </li>
                                                                                    </ul>
                                                                                }
                                                                            </div>


                                                                            <div className="blocks  col-md-6">

                                                                            </div>


                                                                        </div>

                                                                    </div>
                                                                })}

                                                            </div>

                                                            <div className="col-md-1 text-center">
                                                                <div className="arrow">
                                                                    <i className="fa fa-angle-down"></i>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    })

                                                    }


                                                </div>

                                            </div>
                                            {this.state.loading == true &&
                                                <div className="custom-loading">
                                                    <div className="loading"><i className="fa fa-spinner fa-spin"></i>
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                        {user.compensationStrategy != 'Commission' &&

                                            <div className="tab-pane fade" id="timeClock">
                                                <div className="sales">
                                                    <div className="date-selector">
                                                        {this.state.salesCount != 1 &&
                                                            <button onClick={this.getPrevSales}><i
                                                                className="fa fa-angle-left fa-2x"></i></button>
                                                        }
                                                        <div data-toggle="modal" data-target="#pickerModal"
                                                             onClick={this.openTimeSheetPicker}>{this.formatPredefined(this.state.predefined)} - {moment(this.state.defaultStartDate).format('DD')} - {moment(this.state.defaultEndDate).format('DD MMM')}</div>
                                                        {this.state.salesCount != this.state.maxCount && this.state.maxCount != 1 &&
                                                            <button onClick={this.getNextSales}><i
                                                                className="fa fa-angle-right fa-2x"></i></button>}
                                                    </div>
                                                    <div className="day-wrapper">
                                                        <div className="hours">
                                                            <div className="total-hours text-center">
                                                                <h2 className="custom-info text-center">
                                                                    Total Hours - &nbsp;
                                                                    {this.state.techHours.length > 0 &&
                                                                        this.checkTotalWeekHOurs(this.state.techHours)
                                                                    }
                                                                    {this.state.techHours.length == 0 && `0h 0m`}

                                                                </h2>
                                                            </div>

                                                        </div>
                                                        <div className="hour-block">
                                                            <div className="regular text-center">
                                                                <h4 className="fade-title">Regular</h4>
                                                                <h4 className="dark-title">
                                                                    {this.state.techHours.length > 0 &&
                                                                        this.calculateWeekWiseRegular(this.state.techHours)
                                                                    }
                                                                    {this.state.techHours.length == 0 && `0h 0m`}
                                                                </h4>

                                                            </div>
                                                            <div className="break text-center">
                                                                <h4 className="fade-title">Break</h4>
                                                                <h4 className="dark-title">
                                                                    {this.state.techHours.length > 0 &&
                                                                        this.calculateWeekWiseBreak(this.state.techHours)
                                                                    }
                                                                    {this.state.techHours.length == 0 && `0h 0m`}
                                                                </h4>
                                                            </div>

                                                        </div>

                                                        <div className="row">

                                                            <div className="col-md-12">
                                                                <div className="switcher-spacer"></div>


                                                                <div className="radios">
                                                                    <label className="radio-inline">
                                                                        <input onChange={this.changeAll} defaultChecked
                                                                               type="radio" value="all"
                                                                               name="type"/> All
                                                                    </label>
                                                                    <label className="radio-inline">
                                                                        <input onChange={this.changePending}
                                                                               type="radio"
                                                                               value="pending" name="type"/> Pending
                                                                    </label>
                                                                </div>
                                                                <div className="add-timecard-button text-right">
                                                                    <button data-toggle="modal" data-target="#addTime"
                                                                            onClick={this.openTimeCardModel}
                                                                            className="btn btn-custom-info">+ Add New
                                                                    </button>
                                                                </div>

                                                                <div className="switcher-spacer"></div>

                                                                <div className="table-responsive">
                                                                    <table className="table">
                                                                        <thead>
                                                                        <tr>
                                                                            <th>Day</th>
                                                                            <th>Clock In</th>
                                                                            <th>Clock Out</th>
                                                                            <th>Breaks</th>
                                                                            <th>Regular</th>
                                                                            <th>Action</th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {this.state.techHours.length > 0 && this.state.techHours.map((t, k) => {
                                                                            return <tr
                                                                                className={t.status == 'Initiated' ? 'pendings' : ''}
                                                                                key={k}>
                                                                                <td>{helperFunctions.formatDateTimeWithDay(t.createdTime, 'timeCard')}</td>
                                                                                <td>{helperFunctions.formatDateTimeWithDay(t.period.from, 'time')}</td>
                                                                                <td>{helperFunctions.formatDateTimeWithDay(t.period.to, 'time')}</td>
                                                                                <td>
                                                                                    {t.breaks.length > 0 &&
                                                                                        this.calculateBreakingTime(t.breaks)
                                                                                    }
                                                                                    {t.breaks.length == 0 && '---'}
                                                                                </td>
                                                                                <td>{parseInt(moment.duration(moment(t.period.to).diff(moment(t.period.from))).asHours())}h {parseInt(moment.duration(moment(t.period.to).diff(moment(t.period.from))).asMinutes()) % 60}m</td>
                                                                                {this.MAN_DIREC == true && t.status == 'Initiated' &&
                                                                                    <td>

                                                                                        <button
                                                                                            title="Approve"
                                                                                            onClick={() => this.makeitApproved(t.id)}>
                                                                                            <i className="text-danger fa fa-exclamation-triangle"></i>
                                                                                        </button>
                                                                                        <button
                                                                                            onClick={() => this.openViewDetailsTimeCard(t)}>
                                                                                            <i className="custom-info fa fa-pencil"></i>
                                                                                        </button>

                                                                                        <button
                                                                                            onClick={() => this.deleteTimeCard(t.id)}>
                                                                                            <i className="text-danger fa fa-trash"></i>
                                                                                        </button>
                                                                                    </td>}

                                                                                {this.MAN_DIREC == true && t.status != 'Initiated' &&
                                                                                    <td>
                                                                                        <button
                                                                                            onClick={() => this.openViewDetailsTimeCard(t)}>
                                                                                            <i className="custom-info fa fa-pencil"></i>
                                                                                        </button>
                                                                                        <button
                                                                                            onClick={() => this.deleteTimeCard(t.id)}>
                                                                                            <i className="text-danger fa fa-trash"></i>
                                                                                        </button>

                                                                                    </td>}
                                                                            </tr>
                                                                        })}


                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>

                                                    {this.state.loading == true &&
                                                        <div className="custom-loading">
                                                            <div className="loading"><i
                                                                className="fa fa-spinner fa-spin"></i></div>
                                                        </div>
                                                    }

                                                </div>

                                            </div>
                                        }

                                        <div className="tab-pane fade" id="services">

                                            <div className="bs-example bs-example-tabs settings">
                                                <div className="row">
                                                    <div className="col-md-9">
                                                        <ul role="tablist" className="nav nav-tabs">
                                                            {this.state.categories.map((osc, key) => {
                                                                return this.checkCategoryForAll(osc, key)
                                                            })}

                                                        </ul>
                                                    </div>


                                                </div>
                                                <div>
                                                    <div className={"row"}>
                                                        <div className={"col-md-8"}>
                                                            <h4>Category: <span
                                                                className={"text-danger"}><strong>{this.findCategory()}</strong></span>
                                                            </h4>
                                                        </div>


                                                    </div>
                                                    <div className={"row"}>
                                                        <div className={"col-md-4"}>
                                                            <div clssName="form-group">
                                                                <div className="input-group mb-3">
                                                                    <input value={this.state.searchKey}
                                                                           placeholder={"Type service name"}
                                                                           type={"search"} onInput={this.searchService}
                                                                           className={"form-control"}/>
                                                                    <div className="input-group-addon">
                                                                        <span><i className={"fa fa-search"}></i></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-8 pull-right">
                                                            <div className="action justify-content-end">
                                                                <button onClick={() => {
                                                                    this.resetFilters()
                                                                }} className="btn btn-default pull-left">Clear Filter
                                                                </button>

                                                                <ul className="pager  pull-left">
                                                                    {this.state.pageOffset > 0 &&
                                                                        <li><a onClick={() => {
                                                                            this.getPreviousPage()
                                                                        }}><i className="fa fa-angle-left"></i></a>
                                                                        </li>}
                                                                    {allServices.length == 9 &&
                                                                        <li><a onClick={() => {
                                                                            this.getNextPage()
                                                                        }}><i className="fa fa-angle-right"></i></a>
                                                                        </li>
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="table-responsive">
                                                        {this.state.isFiltered &&
                                                            <div className={"custom-loader"}>
                                                                <div className="loaderDiv">
                                                                    <div className="loader"></div>
                                                                </div>
                                                            </div>
                                                        }

                                                        <table className="table table-staff table-striped">
                                                            <thead>
                                                            <tr>
                                                                <th>Service Name</th>
                                                                <th>Price</th>
                                                                <th>Duration</th>
                                                                <th>Process</th>
                                                                <th>Action</th>
                                                            </tr>
                                                            </thead>

                                                            <tbody>

                                                            {allServices.length != 0 && allServices.filter(s => s.service.active == true).map((s, key) => {

                                                                return this.checkAvailableService(s.service.id, s, key)

                                                            })}</tbody>
                                                        </table>
                                                        {this.state.items.length > 0 &&
                                                            <div>
                                                                <button className="addSelelcted"
                                                                        onClick={this.addServiceTechnician}>Add Selected
                                                                </button>
                                                            </div>

                                                        }
                                                    </div>
                                                </div>
                                                {this.state.loading == true &&
                                                    <div className="custom-loading">
                                                        <div className="loading"><i
                                                            className="fa fa-spinner fa-spin"></i></div>
                                                    </div>
                                                }
                                            </div>


                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <Footer/>

                <div className="modal fade" id="updateStaffService" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <button onClick={this.resetSelectedService} className="btn close"
                                        data-dismiss="modal">&times;</button>
                                <br/>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Price Type</label>
                                            <select
                                                name="mode"
                                                value={this.state.mode}
                                                onChange={this.handleChange}>
                                                <option
                                                    value="Price Range">Price Range
                                                </option>
                                                <option
                                                    value="Starting Price">Starting Price
                                                </option>
                                                <option
                                                    value="Fixed Price">FixedPrice
                                                </option>
                                                <option value="Per Consult">Per Consult
                                                </option>

                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Duration</label>
                                            <input
                                                type="text"
                                                name="durat"
                                                defaultValue={this.state.durat}
                                                className="form-control"
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    {this.state.mode == 'Fixed Price' &&
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Minimum Fee</label>
                                                <input
                                                    type="text"
                                                    name="min"
                                                    defaultValue={this.state.min && (this.state.min * 1).toFixed(2)}
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>}
                                    {this.state.mode == 'Starting Price' &&
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Minimum Fee</label>
                                                <input
                                                    type="text"
                                                    name="min"
                                                    defaultValue={this.state.min && (this.state.min * 1).toFixed(2)}
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                    }
                                    {this.state.mode == 'Price Range' &&
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Minimum Fee</label>
                                                <input
                                                    type="text"
                                                    name="min"
                                                    defaultValue={this.state.min && (this.state.min * 1).toFixed(2)}
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                    }
                                    {this.state.mode == 'Price Range' &&
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Maximum Fee</label>
                                                <input
                                                    type="text"
                                                    name="max"
                                                    defaultValue={this.state.max && (this.state.max * 1).toFixed(2)}
                                                    onChange={this.handleChange}
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>}
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Processing Time</label>
                                            <input
                                                type="text"
                                                name="process"
                                                defaultValue={this.state.process}
                                                onChange={this.handleChange}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="switcher-spacer"></div>
                                        <div className="form-group">

                                            <button onClick={this.updateServiceTechnician}
                                                    className="btn btn-custom-info pull-right update">Update
                                            </button>

                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="addServiceForm" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <button onClick={this.resetAddForm} className="btn close"
                                        data-dismiss="modal">&times;</button>
                                <br/>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Price Type</label>
                                            <select
                                                name="mode"
                                                value={this.state.mode}
                                                onChange={this.handleChange}>
                                                <option
                                                    value="Price Range">Price Range
                                                </option>
                                                <option
                                                    value="Starting Price">Starting Price
                                                </option>
                                                <option
                                                    value="Fixed Price">FixedPrice
                                                </option>
                                                <option value="Per Consult">Per Consult
                                                </option>

                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Duration</label>
                                            <input
                                                type="text"
                                                name="durat"
                                                defaultValue={this.state.durat}
                                                className="form-control"
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    {this.state.mode == 'Fixed Price' &&
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Minimum Fee</label>
                                                <input
                                                    type="text"
                                                    name="min"
                                                    defaultValue={this.state.min && (this.state.min * 1).toFixed(2)}
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>}
                                    {this.state.mode == 'Starting Price' &&
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Minimum Fee</label>
                                                <input
                                                    type="text"
                                                    name="min"
                                                    defaultValue={this.state.min && (this.state.min * 1).toFixed(2)}
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                    }
                                    {this.state.mode == 'Price Range' &&
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Minimum Fee</label>
                                                <input
                                                    type="text"
                                                    name="min"
                                                    defaultValue={this.state.min && (this.state.min * 1).toFixed(2)}
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                    }
                                    {this.state.mode == 'Price Range' &&
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Maximum Fee</label>
                                                <input
                                                    type="text"
                                                    name="max"
                                                    defaultValue={this.state.max && (this.state.max * 1).toFixed(2)}
                                                    onChange={this.handleChange}
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>}
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Processing Time</label>
                                            <input
                                                type="text"
                                                name="process"
                                                defaultValue={this.state.process}
                                                onChange={this.handleChange}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="switcher-spacer"></div>
                                        <div className="form-group">
                                            <button onClick={this.addServiceTechnician}
                                                    className="btn btn-custom-info pull-right">Add Service
                                            </button>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="pickerModal" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <button onClick={this.closePickerModal} className="btn close"
                                        data-dismiss="modal">&times;</button>
                                <br/>

                                <div className="row">
                                    <div className="col-md-12">
                                        <h3>
                                            <div className="shape"></div>
                                            Pick start & end date
                                        </h3>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Start Date</label>
                                            <DatetimePickerTrigger
                                                moment={this.state.defaultStartDate}
                                                onChange={this.changeDefaultStart}>
                                                <input
                                                    type="text"
                                                    value={this.state.defaultStartDate.format('YYYY-MM-DD')}
                                                    readOnly/>
                                            </DatetimePickerTrigger>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>End Date</label>
                                            <DatetimePickerTrigger
                                                moment={this.state.defaultEndDate}
                                                onChange={this.changeDefaultEnd}>
                                                <input
                                                    type="text"
                                                    value={this.state.defaultEndDate.format('YYYY-MM-DD')}
                                                    readOnly/>
                                            </DatetimePickerTrigger>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="switcher-spacer"></div>
                                        <div className="form-group">
                                            <button onClick={this.showSalesData}
                                                    className="btn btn-custom-info pull-right">
                                                {this.state.timeSheet == true ? 'Show Sheet' : 'Show Sales'}
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <div className="OR">
                                            OR
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <h3>
                                            <div className="shape"></div>
                                            Choose a predefined period
                                        </h3>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="btns-predefined">
                                            <button onClick={() => this.predefinedSalesData('TODAY')}
                                                    className="btn btn-info btn-lg">Today
                                            </button>
                                            <button onClick={() => this.predefinedSalesData('THIS_WEEK')}
                                                    className="btn btn-custom-info btn-lg">This Week
                                            </button>
                                            <button onClick={() => this.predefinedSalesData('LAST_WEEK')}
                                                    className="btn btn-warning btn-lg">Last Week
                                            </button>
                                            <button onClick={() => this.predefinedSalesData('THIS_MONTH')}
                                                    className="btn btn-purple btn-lg">This Month
                                            </button>
                                            <button onClick={() => this.predefinedSalesData('LAST_MONTH')}
                                                    className="btn btn-danger btn-lg">Last Month
                                            </button>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>


                <div className="modal fade" id="hoursModal" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <strong>Update Hours</strong>
                                <button onClick={this.closePickerModal} className="btn close"
                                        data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label htmlFor="day">Day</label>
                                            <input
                                                readOnly
                                                type="text"
                                                name="dayOfWeek"
                                                value={this.state.singleHours.dayOfWeek}
                                                onChange={this.handleHours}
                                            />
                                        </div>
                                    </div>


                                    {this.state.singleHours.to !== '' &&
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="day">End Time</label>

                                                <CustomTimePicker onChange={this.handleHoursFrom}
                                                                  value={this.state.singleHours.from} name={"from"}
                                                                  format={format}/>

                                            </div>
                                        </div>}


                                    {this.state.singleHours.to !== '' &&
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="day">End Time</label>
                                                {this.state.singleHours.to != '' &&
                                                    <CustomTimePicker format={format} onChange={this.handleHoursFrom}
                                                                      value={this.state.singleHours.to} name={"to"}/>
                                                }
                                            </div>

                                        </div>}
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="day">Pattern</label>
                                            <select
                                                name="pattern"
                                                value={this.state.singleHours.pattern}
                                                onChange={this.handleHours}
                                                className="form-control">
                                                <option value="Every Week">Every Week</option>
                                                <option value="Every Other Week">Every Other Week</option>
                                                <option value="Never">Never</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="day">Starting Date</label>

                                            <DatePickerInput
                                                showOnInputClick={true}
                                                value={moment(this.state.singleHours.patternStartDate)}
                                                readOnly
                                                onChange={this.handlePatternStart}
                                                className={"date"}
                                            />


                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button onClick={this.updateStaffHours} className="btn btn-custom-info">Update</button>
                            </div>
                            {this.state.loading == true &&
                                <div className="custom-loading">
                                    <div className="loading"><i className="fa fa-spinner fa-spin"></i></div>
                                </div>
                            }

                        </div>
                    </div>
                </div>


                <div className="modal fade" id="addService" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-85" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <button onClick={this.resetSelectedService} className="btn close"
                                        data-dismiss="modal">&times;</button>
                                <br/>

                                <div className="bs-example bs-example-tabs settings">

                                    <div className="row">
                                        <div className="row">
                                            <div className="col-md-9">
                                                <ul role="tablist" className="nav nav-tabs">
                                                    {this.state.categories.map((osc, key) => {
                                                        return this.checkCategoryForAll(osc, key)
                                                    })}

                                                </ul>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="action">
                                                    <button onClick={() => {
                                                        this.resetFilters()
                                                    }} className="btn btn-default pull-left">Clear Filter
                                                    </button>

                                                    <ul className="pager  pull-left">
                                                        <li><a onClick={() => {
                                                            this.getPreviousPage()
                                                        }}><i className="fa fa-angle-left"></i></a>
                                                        </li>
                                                        <li><a onClick={() => {
                                                            this.getNextPage()
                                                        }}><i className="fa fa-angle-right"></i></a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="table-responsive">
                                            <h4>Category: {this.findCategory()}</h4>
                                            <table className="table">
                                                <thead>
                                                <tr>
                                                    <th>Service Name</th>
                                                    <th>Price</th>
                                                    <th>Mins</th>
                                                    <th>Process</th>
                                                    <th>Action</th>
                                                </tr>
                                                </thead>
                                                <tbody>

                                                {allServices.length != 0 && allServices.filter(s => s.service.active == true).map((s, key) => {

                                                    return this.checkAvailableService(s.service.id, s, key)

                                                })}</tbody>
                                            </table>
                                            {this.state.items.length > 0 &&
                                                <div>
                                                    <button className="addSelelcted"
                                                            onClick={this.addServiceTechnician}>Add Selected
                                                    </button>
                                                </div>

                                            }
                                        </div>

                                        {this.state.loading == true &&
                                            <div className="custom-loading">
                                                <div className="loading"><i className="fa fa-spinner fa-spin"></i></div>
                                            </div>
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="modal fade" id="editTimeCard">
                    <div className="modal-dialog">
                        <div className="modal-content">

                            <div className="modal-body">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span
                                    aria-hidden="true" data-target="#timeCardDetails" data-toggle="modal">×</span>
                                </button>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h3>
                                                    <div className="shape"></div>
                                                    Update Timesheet
                                                </h3>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Clock In</label>
                                                    <DatetimePickerTrigger
                                                        moment={this.state.clockIn}
                                                        onChange={this.changeClockIn}>
                                                        <input
                                                            type="text"
                                                            value={this.state.clockIn.format('YYYY-MM-DD') + " " + helperFunctions.formatDateTimeWithDay(this.state.clockIn, 'time')}
                                                            readOnly/>
                                                    </DatetimePickerTrigger>

                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Clock Out</label>
                                                    <DatetimePickerTrigger
                                                        moment={this.state.clockOut}
                                                        onChange={this.changeClockOut}>
                                                        <input
                                                            type="text"
                                                            value={this.state.clockOut.format('YYYY-MM-DD') + " " + helperFunctions.formatDateTimeWithDay(this.state.clockOut, 'time')}
                                                            readOnly/>
                                                    </DatetimePickerTrigger>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Notes</label>
                                                    <textarea value={this.state.notes} onChange={this.handleChange}
                                                              name="notes" className="form-control" rows="6"></textarea>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-12">

                                                <div className="form-group">
                                                    <button onClick={this.updateTimeCard}
                                                            className="btn btn-custom-info pull-right">Update Time
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>


                            </div>

                            {this.state.loading == true &&
                                <div className="custom-loading">
                                    <div className="loading"><i className="fa fa-spinner fa-spin"></i></div>
                                </div>
                            }

                        </div>
                    </div>
                </div>


                <div data-backdrop="static" className="modal fade" id="addTime">
                    <div className="modal-dialog">
                        <div className="modal-content">

                            <div className="modal-body">
                                <button onClick={this.closeHandler} type="button" className="close" data-dismiss="modal"
                                        aria-label="Close"><span
                                    aria-hidden="true">×</span></button>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h3>
                                                    <div className="shape"></div>
                                                    Add Timesheet
                                                </h3>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Clock In</label>
                                                    <DatetimePickerTrigger
                                                        moment={this.state.clockIn}
                                                        onChange={this.changeClockIn}>
                                                        <input
                                                            type="text"
                                                            value={this.state.clockIn.format('YYYY-MM-DD') + " " + helperFunctions.formatDateTimeWithDay(this.state.clockIn, 'time')}
                                                            readOnly/>
                                                    </DatetimePickerTrigger>

                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Clock Out</label>
                                                    <DatetimePickerTrigger
                                                        moment={this.state.clockOut}
                                                        onChange={this.changeClockOut}>
                                                        <input
                                                            type="text"
                                                            value={this.state.clockOut.format('YYYY-MM-DD') + " " + helperFunctions.formatDateTimeWithDay(this.state.clockOut, 'time')}
                                                            readOnly/>
                                                    </DatetimePickerTrigger>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Notes</label>
                                                    <textarea value={this.state.notes} onChange={this.handleChange}
                                                              name="notes" className="form-control" rows="6"></textarea>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-12">

                                                <div className="form-group">
                                                    <button disabled={this.state.cardId !== ''}
                                                            onClick={() => this.addNewTimeCard('time-cards')}
                                                            className="btn btn-custom-info pull-right">Add Time
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.cardId !== '' &&
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h3>
                                                        <div className="shape"></div>
                                                        Add Break
                                                    </h3>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Start Break</label>
                                                        <CustomTimePicker name="clockIn"
                                                                          onChange={this.changeBreakStart}
                                                                          format={format}
                                                                          value={moment(this.state.breakStart).format(format)}/>
                                                        {/*<CustomTimePicker name="clockIn"*/}
                                                        {/*                  onChange={this.changeBreakStart}*/}
                                                        {/*                  format={format}*/}
                                                        {/*                  value={moment(this.state.breakStart).format(format)}/>*/}
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <label>End Break</label>
                                                    <CustomTimePicker name="clockOut" onChange={this.changeBreakEnd}
                                                                      format={format}
                                                                      value={moment(this.state.breakEnd).format(format)}/>
                                                </div>
                                            </div>


                                            <div className="row">
                                                <div className="col-md-12">

                                                    <div className="form-group">
                                                        <button onClick={() => this.addNewTimeCard('time-card-breaks')}
                                                                className="btn btn-custom-info pull-right">Add Break
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>


                            </div>

                            {this.state.loading == true &&
                                <div className="custom-loading">
                                    <div className="loading"><i className="fa fa-spinner fa-spin"></i></div>
                                </div>
                            }

                        </div>
                    </div>
                </div>


                <div data-keyboard="false" data-backdrop="static" className="modal fade" id="modalBreak">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button onClick={this.closeModalBreak} className="close"
                                        data-dismiss="modal">&times;</button>
                                <h3>
                                    <div className="shape"></div>
                                    Add Break
                                </h3>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label>Start Break</label>

                                    <CustomTimePicker name="clockIn"
                                                      onChange={this.changeBreakStart}
                                                      format={format}
                                                      value={moment(this.state.breakStart).format(format)}/>

                                </div>

                                <div className="form-group">
                                    <label>End Break</label>

                                    <CustomTimePicker name="clockIn"
                                                      onChange={this.changeBreakEnd}
                                                      format={format}
                                                      value={moment(this.state.breakEnd).format(format)}/>

                                </div>

                                <div className="switcher-spacer"></div>
                                <div className="form-group">
                                    <button onClick={this.updateBreakTime}
                                            className="btn btn-custom-info pull-right">Update Break
                                    </button>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
                <div data-backdrop="static" data-keyboard="false" className="modal fade" id="addBreakModal">
                    <div className="modal-dialog modal-sm">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button onClick={this.closeAddModal} className="close"
                                        data-dismiss="modal">&times;</button>
                                <h3>
                                    <div className="shape"></div>
                                    Add Break
                                </h3>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label>Start Break</label>

                                    <CustomTimePicker name="breakStart" format={format} onChange={this.changeBreakStart}
                                                      value={this.state.breakStart}/>
                                </div>

                                <div className="form-group">
                                    <label>End Break</label>

                                    <CustomTimePicker name="breakEnd" format={format} onChange={this.changeBreakEnd}
                                                      value={this.state.breakEnd}/>

                                </div>

                                <div className="switcher-spacer"></div>
                                <div className="form-group">
                                    <button onClick={this.addBreak} className="btn btn-custom-info pull-right">Add
                                        Break
                                    </button>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>


                <div data-backdrop="static" data-keyboard="false" className="modal fade" id="timeCardDetails">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                {this.state.singleTimeSheet != '' &&
                                    <div className="map-header">
                                        <h4 className="custom-info">Time Entry
                                            - {parseInt(moment.duration(moment(this.state.singleTimeSheet.period.to).diff(moment(this.state.singleTimeSheet.period.from))).asHours())}h {parseInt(moment.duration(moment(this.state.singleTimeSheet.period.to).diff(moment(this.state.singleTimeSheet.period.from))).asMinutes()) % 60}m</h4>
                                        <div className="map-btn">
                                            <button title="Add break" onClick={this.openAddBreakModal}
                                                    data-toggle="modal"
                                                    data-target="addBreakModal" className="only-icon custom-info"><i
                                                className="fa fa-plus"></i></button>
                                            <button title="Edit Time" onClick={this.opedEditTimeCard}
                                                    data-toggle="modal"
                                                    data-target="#editTimeCard" className="only-icon text-info"><i
                                                className="fa fa-pencil"></i></button>

                                            <button onClick={this.closeDetailsModal} className="only-icon text-danger">
                                                <i
                                                    className="fa fa-close"></i></button>
                                        </div>
                                    </div>
                                }

                                <div id="map"></div>


                                <div className="show-details">
                                    <div className="singleClock clock">
                                        <div
                                            className="times">{this.state.singleTimeSheet != '' && helperFunctions.formatDateTimeWithDay(this.state.singleTimeSheet.period.from, 'time')}</div>
                                        <div className="round-shape">
                                            <div className="circular_shape"></div>
                                        </div>
                                        <div className="content">
                                            <p>Clock In <br/>{this.state.address}</p>
                                        </div>
                                    </div>


                                    {this.state.breakPoints.length > 0 &&
                                        this.state.breakPoints.map((b, key) => {
                                            return <React.Fragment key={key}>
                                                <div className="singleClock">
                                                    <div
                                                        className="times">{helperFunctions.formatDateTimeWithDay(b.from, 'time')}</div>
                                                    <div className="round-shape break-shape">
                                                        <div className="circular_shape"></div>
                                                    </div>
                                                    <div className="content">
                                                        <p>Break Start</p>
                                                        <div className="hover-action">
                                                            <button data-target="#modalBreak" data-toggle="modal"
                                                                    onClick={() => this.editBreakPoint(b.key)}
                                                                    className="no-border"><i
                                                                className="fa fa-pencil text-info"></i></button>
                                                            <button onClick={() => this.deleteBreakPoint(b.key, b.id)}
                                                                    className="no-border"><i
                                                                className="fa fa-trash text-danger"></i></button>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="singleClock">
                                                    <div
                                                        className="times">{helperFunctions.formatDateTimeWithDay(b.to, 'time')}</div>
                                                    <div className="round-shape break-shape">
                                                        <div className="circular_shape"></div>
                                                    </div>
                                                    <div className="content">
                                                        <p>Break End</p>

                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        })
                                    }


                                    <div className="singleClock clock">
                                        <div
                                            className="times">{this.state.singleTimeSheet != '' && helperFunctions.formatDateTimeWithDay(this.state.singleTimeSheet.period.to, 'time')}</div>
                                        <div className="round-shape">
                                            <div className="circular_shape"></div>
                                        </div>
                                        <div className="content">
                                            <p>Clock Out</p>
                                        </div>
                                    </div>


                                </div>

                            </div>

                        </div>
                    </div>
                </div>

                <div className="modal fade" id="editTime">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span
                                    aria-hidden="true">×</span></button>
                                <h4 className="modal-title" id="cancelLabelModel">Edit Timing</h4>

                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="form-group">
                                        <label>Pick Date</label>
                                        <div className="input-group date endtime">
                                            <input type="text" className="form-control margin-top-0"/>
                                            <span className="input-group-addon"><span
                                                className="glyphicon glyphfa fa-calendar"></span></span>
                                        </div>
                                    </div>

                                    <div className="time-clock">
                                        <label>Time In</label>
                                        <input type="text" name="timepicker" className="time_element"/>
                                    </div>


                                    <div className="time-clock">
                                        <label>Time Out</label>
                                        <input type="text" name="timepicker" className="time_element"/>
                                    </div>

                                    <div className="form-group text-right">
                                        <input type="submit" className="btn btn-info" value="Update"/>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="modal fade" id="sorting">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <Sorting
                                changeOrder={this.changeOrder}
                                list={this.state.list}/>
                        </div>
                    </div>
                </div>

            </div>

        );
    }


    eventClickHandler(eventClickInfo) {

        window.editTimeModal();
    }

    selectTimeSlot(selectionInfo) {
        window.addTimeModal();
    }

    openTimeCardModel = () => {
        $('#addTime').modal('show');
        this.setState({
            timeSheet: true,
            revenue: false
        })
    }

    isCheckedAllList = (id, service, query) => {
        if (service) {

            if (query === 'pricingModel') {
                return this.checkPriceType(service.minimumFee, service.maximumFee)
            }
            if (query === 'duration') {
                return helperFunctions.timeConvert(service.duration, 'minute');
            }
            if (query === 'minimumFee') {
                return service.minimumFee
            }

            if (query === 'maximumFee') {
                return service.maximumFee
            }
            if (query === 'displayedPrice') {
                return service.displayedPrice
            }
            if (query == 'sId') {
                return service.id
            }
            if (query == 'processingTimeInMinutes') {
                return 0
            }
            if (query == 'newClientRetentionPercentage') {
                return service.newClientRetentionPercentage
            }
            if (query == 'oldClientRetentionPercentage') {
                return service.oldClientRetentionPercentage
            }


        }

    }


    searchServicesByCategory(ctgryId) {
        this.setState({
            ...this.state,
            pageOffset: 0,
            searchKey: '',
            ctgryId: ctgryId
        })
        this.doFilter(0, ctgryId);

    }

    resetFilters() {
        this.setState({
            pageOffset: 0,
            ctgryId: '',
            item: [],
            searchKey: ''
        });

        this.doFilter(0, '')
    }

    getNextPage() {
        var offset = this.state.pageOffset + 1;
        const {allServices, ctgryId, searchKey} = this.state;
        if (allServices && allServices.length == 9) {
            this.setState({pageOffset: offset})
            this.doFilter(offset, ctgryId, searchKey)
        }

    }

    getPreviousPage() {
        var offset = this.state.pageOffset - 1;
        if (offset >= 0) {
            const {searchKey, ctgryId} = this.state;
            this.setState({pageOffset: offset})
            this.doFilter(offset, ctgryId, searchKey)
        }
    }


    saveTechService = (sid, duration, minimumFee, maximumFee, pricingModel, method, processingTimeInMinutes = '', techSerId = '', service) => {

        if (sid != '' && duration != '' && minimumFee != '' && maximumFee != '' && pricingModel != '') {
            var durationmnt = '';
            var minimumFees = '';
            var maximumFees = '';
            var processing = '';
            if (duration in this.state.selectedService) {
                durationmnt = this.state.selectedService[duration]
            } else {
                durationmnt = this.isCheckedAllList(sid, service, 'duration');

            }
            if (processingTimeInMinutes in this.state.selectedService) {
                processing = this.state.selectedService[processingTimeInMinutes]
            } else {
                processing = this.isCheckedAllList(sid, service, 'processingTimeInMinutes');
            }
            if (minimumFee in this.state.selectedService && pricingModel in this.state.selectedService) {

                minimumFees = this.state.selectedService[pricingModel] == 'Per Consult' ? '-1' : this.state.selectedService[pricingModel] == 'Price Range' ? this.state.selectedService[minimumFee] : this.state.selectedService[pricingModel] == 'Starting Price' ? this.state.selectedService[minimumFee] : this.state.selectedService[pricingModel] == 'Fixed Price' ? this.state.selectedService[minimumFee] : '';

            } else if (minimumFee in this.state.selectedService && !(pricingModel in this.state.selectedService)) {
                minimumFees = this.isCheckedAllList(sid, service, 'pricingModel') == 'Per Consult' ? '-1' : this.isCheckedAllList(sid, service, 'pricingModel') == 'Price Range' ? this.state.selectedService[minimumFee] : this.isCheckedAllList(sid, service, 'pricingModel') == 'Starting Price' ? this.state.selectedService[minimumFee] : this.isCheckedAllList(sid, service, 'pricingModel') == 'Fixed Price' ? this.state.selectedService[minimumFee] : '';
            } else if (!(minimumFee in this.state.selectedService) && pricingModel in this.state.selectedService) {
                minimumFees = this.state.selectedService[pricingModel] == 'Per Consult' ? '-1' : this.state.selectedService[pricingModel] == 'Price Range' ? this.isCheckedAllList(sid, service, 'minimumFee') : this.state.selectedService[pricingModel] == 'Starting Price' ? this.isCheckedAllList(sid, service, 'minimumFee') : this.state.selectedService[pricingModel] == 'Fixed Price' ? this.isCheckedAllList(sid, service, 'minimumFee') : '';
            } else {
                minimumFees = this.isCheckedAllList(sid, service, 'pricingModel') == 'Per Consult' ? '-1' : this.isCheckedAllList(sid, service, 'pricingModel') == 'Price Range' ? this.isCheckedAllList(sid, service, 'minimumFee') : this.isCheckedAllList(sid, service, 'pricingModel') == 'Starting Price' ? this.isCheckedAllList(sid, service, 'minimumFee') : this.isCheckedAllList(sid, service, 'pricingModel') == 'Fixed Price' ? this.isCheckedAllList(sid, service, 'minimumFee') : '';
            }


            if (maximumFee in this.state.selectedService && pricingModel in this.state.selectedService) {

                maximumFees = this.state.selectedService[pricingModel] == 'Per Consult' ? '-1' : this.state.selectedService[pricingModel] == 'Price Range' ? this.state.selectedService[maximumFee] : this.state.selectedService[pricingModel] == 'Starting Price' ? '-1' : this.state.selectedService[pricingModel] == 'Fixed Price' ? this.state.selectedService[minimumFee] : '';

            } else if (maximumFee in this.state.selectedService && !(pricingModel in this.state.selectedService)) {

                maximumFees = this.isCheckedAllList(sid, service, 'pricingModel') == 'Per Consult' ? '-1' : this.isCheckedAllList(sid, service, 'pricingModel') == 'Price Range' ? this.state.selectedService[maximumFee] : this.isCheckedAllList(sid, service, 'pricingModel') == 'Starting Price' ? '-1' : this.isCheckedAllList(sid, service, 'pricingModel') == 'Fixed Price' ? this.state.selectedService[minimumFee] : '';

            } else if (!(maximumFee in this.state.selectedService) && pricingModel in this.state.selectedService) {

                maximumFees = this.state.selectedService[pricingModel] == 'Per Consult' ? '-1' : this.state.selectedService[pricingModel] == 'Price Range' ? this.isCheckedAllList(sid, service, 'maximumFee') : this.state.selectedService[pricingModel] == 'Starting Price' ? '-1' : this.state.selectedService[pricingModel] == 'Fixed Price' ? this.state.selectedService[minimumFee] : '';

            } else {

                maximumFees = this.isCheckedAllList(sid, service, 'pricingModel') == 'Per Consult' ? '-1' : this.isCheckedAllList(sid, service, 'pricingModel') == 'Price Range' ? this.isCheckedAllList(sid, service, 'maximumFee') : this.isCheckedAllList(sid, service, 'pricingModel') == 'Starting Price' ? '-1' : this.isCheckedAllList(sid, service, 'pricingModel') == 'Fixed Price' ? this.isCheckedAllList(sid, service, 'minimumFee') : '';

            }


            if (minimumFees != undefined && maximumFees != undefined && durationmnt != undefined) {

                const techJSON = {
                    technician: {
                        id: this.state.user.id
                    },
                    service: {
                        id: sid
                    },
                    duration: 'PT' + durationmnt + 'M',
                    minimumFee: minimumFees,
                    maximumFee: maximumFees,
                    processingTime: "PT" + processing + "M"

                }
                appService.saveTechnicianToService(techJSON).then(res => {
                    const {dispatch} = this.props;
                    window.DataUpdateSuccess();
                    userService.getTechnicianInfo(this.state.user.id).then(user => {

                        this.resetState(user);

                    });

                    appService.getAllServicesForStaffdetails(this.state.pageOffset, this.state.ctgryId).then(services => {

                        this.setState({
                            allServices: services
                        })
                    })

                }).catch(err => {
                    Swal.fire({
                        title: 'Invalid Data!',
                        text: `Pricing Model is invalid!`,
                        type: 'warning',
                        cancelButtonText: 'Ok'
                    })
                })
            }

        }


    }


    addServiceTechnician = () => {
        //console.log("CLICK")

        if (this.state.items.length > 0) {
            this.setState({
                loading: true
            })
            for (var i = 0; i < this.state.items.length; i++) {
                const techJSON = {
                    technician: {
                        id: this.state.user.id
                    },
                    service: {
                        id: this.state.items[i].sid
                    },
                    duration: 'PT' + this.state.items[i].durat + 'M',
                    minimumFee: this.state.items[i].min,
                    maximumFee: this.state.items[i].max,
                    processingTime: 'PT' + this.state.items[i].process + 'M'

                }

                appService.saveTechnicianToService(techJSON).then(res => {
                    const userId = qs.parse(this.props.location.search.replace("?", "")).staffId
                    userService.getTechnicianInfo(userId).then(user => {
                        this.resetState(user);
                    });

                })

                this.setState({
                    items: [],
                    loading: false
                })

            }

            window.uncheckEveryone('#adds-tech-table input')


        } else {

            var min = '';
            var max = '';
            if (this.state.mode == 'Per Consult') {
                min = -1;
                max = -1
            } else if (this.state.mode == 'Price Range') {
                min = this.state.min;
                max = this.state.max
            } else if (this.state.mode == 'Starting Price') {
                min = this.state.min;
                max = -1
            } else if (this.state.mode == 'Fixed Price') {
                min = this.state.min;
                max = this.state.min
            } else {
                min = this.state.min;
                max = this.state.max
            }

            const techJSON = {
                technician: {
                    id: this.state.user.id
                },
                service: {
                    id: this.state.sid
                },
                duration: 'PT' + this.state.durat + 'M',
                minimumFee: min,
                maximumFee: max,
                processingTime: 'PT' + this.state.process + 'M'

            }
            appService.saveTechnicianToService(techJSON).then((res) => {
                //$('#addService').modal('toggle')
                $('#addServiceForm').modal('toggle')
                window.DataUpdateSuccess();
                const userId = qs.parse(this.props.location.search.replace("?", "")).staffId
                userService.getTechnicianInfo(userId).then(user => {
                    this.resetState(user);
                });
            });
        }
    }

    updateServiceTechnician = async () => {
        var min = '';
        var max = '';
        if (this.state.mode == 'Per Consult') {
            min = -1;
            max = -1
        } else if (this.state.mode == 'Price Range') {
            min = this.state.min;
            max = this.state.max
        } else if (this.state.mode == 'Starting Price') {
            min = this.state.min;
            max = -1
        } else if (this.state.mode == 'Fixed Price') {
            min = this.state.min;
            max = this.state.min
        } else {
            min = this.state.min;
            max = this.state.max
        }
        const userId = qs.parse(this.props.location.search.replace("?", "")).staffId

        const {techServiceId, sid, durat, process} = this.state;
        let result = await utilities.updateTechnicianService(techServiceId, userId, min, max, sid, durat, process);
        if (result) {
            //console.log('dfsdfsd',result)
            $('#updateStaffService').modal('toggle')
            window.DataUpdateSuccess();
            userService.getTechnicianInfo(userId).then(user => {
                this.resetState(user);
            });
        }
    }
    deleteServiceTechnician = (service) => {
        //console.log(service)
        Swal.fire({
            title: 'Remove Service',
            text: `Are you sure you want to remove this Service from this technician?`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, remove it!',
            cancelButtonText: 'No, keep it'
        }).then((result) => {
            if (result.value) {

                if (this.state.item.length > 0) {
                    for (var i = 0; i < this.state.item.length; i++) {
                        appService.deleteTechService(this.state.item[i]).then(res => {
                            userService.getTechnicianInfo(this.state.user.id).then(user => {
                                this.resetState(user);

                            });
                        })
                    }
                    this.setState({
                        item: []
                    })
                    window.uncheckEveryone('#offered-service-table input');

                    window.toast('success', `Selected services have been deleted from the system`);


                } else {
                    appService.deleteTechService(service.id).then(res => {
                        //$('#updateStaffService').modal('toggle');
                        window.toast('success', `The service has been removed from the technician`);
                        userService.getTechnicianInfo(this.state.user.id).then(user => {
                            this.resetState(user);
                        });

                    })
                }
            }
        })


    }


    resetSelectedService = () => {
        this.setState({
            ...this.state,
            mode: '',
            durat: '',
            max: '',
            min: '',
            process: '',
            sid: '',
            techServiceId: '',
            techId: ''
        })

        this.resetFilters()
    }

    resetAddForm = () => {
        this.setState({
            ...this.state,
            mode: '',
            durat: '',
            max: '',
            min: '',
            process: '',
            sid: '',
            techServiceId: '',
            techId: ''
        })
        /* eslint-disable */
        //$('#addService').modal('show');

        this.resetFilters()
    }
    closePickerModal = () => {
        this.setState({
            timeSheet: false,
            revenue: false
        })
    }

    resetState = (user) => {
        this.setState({
            loading: false,
            user: {
                id: user.id,
                firstName: user.user.names.first,
                lastName: user.user.names.last,
                nickName: user.user.names.nick,
                email: user.user.emailAddress,
                mobileNumber: user.user.mobileNumber,
                gender: user.user.gender,
                birthDate: user.user.birthDate,
                emailNotification: user.user.emailNotificationEnabled,
                smsNotification: user.user.smsNotificationEnabled,
                profilePic: user.user.imageUrl,
                createdTime: user.createdTime,
                nextBirthdayRewardDate: user.user.nextBirthdayRewardDate,
                rewardBalance: user.user.rewardBalance,
                referralToken: user.user.referralToken,
                totalEarningsAsReferrer: user.user.totalEarningsAsReferrer,
                instagramUrl: user.instagramUrl,
                accountStatus: user.accountStatus,
                representationColor: user.representationColor,
                team: user.team,
                expertise: user.expertise,
                biography: user.biography,
                specialities: user.specialties,
                technicianServices: user.technicianServices,
                productCommissionPercentage: user.productCommissionPercentage,
                compensationStrategy: user.compensationStrategy,
                strategyWage: user.strategyWage,
                cashPaymentPercentage: user.cashPaymentPercentage,
                formulas: user.formulas,
                hours: user.hours,
                monday: {
                    id: user.hours[0].id,
                    dayOfWeek: user.hours[0].dayOfWeek,
                    from: user.hours[0].from,
                    to: user.hours[0].to,
                    pattern: user.hours[0].pattern,
                    patternStartDate: user.hours[0].patternStartDate
                },
                tuesday: {
                    id: user.hours[1].id,
                    dayOfWeek: user.hours[1].dayOfWeek,
                    from: user.hours[1].from,
                    to: user.hours[1].to,
                    pattern: user.hours[1].pattern,
                    patternStartDate: user.hours[1].patternStartDate
                },
                wednesday: {
                    id: user.hours[2].id,
                    dayOfWeek: user.hours[2].dayOfWeek,
                    from: user.hours[2].from,
                    to: user.hours[2].to,
                    pattern: user.hours[2].pattern,
                    patternStartDate: user.hours[2].patternStartDate
                },
                thursday: {
                    id: user.hours[3].id,
                    dayOfWeek: user.hours[3].dayOfWeek,
                    from: user.hours[3].from,
                    to: user.hours[3].to,
                    pattern: user.hours[3].pattern,
                    patternStartDate: user.hours[3].patternStartDate
                },
                friday: {
                    id: user.hours[4].id,
                    dayOfWeek: user.hours[4].dayOfWeek,
                    from: user.hours[4].from,
                    to: user.hours[4].to,
                    pattern: user.hours[4].pattern,
                    patternStartDate: user.hours[4].patternStartDate
                },
                saturday: {
                    id: user.hours[5].id,
                    dayOfWeek: user.hours[5].dayOfWeek,
                    from: user.hours[5].from,
                    to: user.hours[5].to,
                    pattern: user.hours[5].pattern,
                    patternStartDate: user.hours[5].patternStartDate
                },
                sunday: {
                    id: user.hours[6].id,
                    dayOfWeek: user.hours[6].dayOfWeek,
                    from: user.hours[6].from,
                    to: user.hours[6].to,
                    pattern: user.hours[6].pattern,
                    patternStartDate: user.hours[6].patternStartDate
                }


            },
            userDetails: user,
            count: 0

        });
    }


    updateCommissionScale = (ming, maxg, percents, sid = '', method) => {

        if (method == 'PUT' || method == 'POST') {
            var mingr = '';
            var maxgr = '';
            var percentg = '';
            if (ming in this.state.selectedScale) {
                mingr = this.state.selectedScale[ming]
            } else {
                mingr = this.grossAmountChecking(sid, 'minGrossAmount');

            }
            if (maxg in this.state.selectedScale) {
                maxgr = this.state.selectedScale[maxg]
            } else {

                maxgr = this.grossAmountChecking(sid, 'maxGrossAmount');

            }
            if (percents in this.state.selectedScale) {
                percentg = this.state.selectedScale[percents]

            } else {

                percentg = this.grossAmountChecking(sid, 'commissionPercentage');

            }


            if (mingr != null && maxgr != null && percentg != null) {


                if (method == 'PUT') {
                    const formulaJSON = {
                        commissionPercentage: percentg,
                        minGrossAmount: mingr,
                        maxGrossAmount: maxgr
                    }


                    userService.updateCommissionScale(sid, formulaJSON).then(res => {
                        window.updateSuccessAert()
                        userService.getTechnicianInfo(this.state.user.id).then(user => {
                            this.resetState(user)
                        })

                    }).catch(err => {
                        Swal.fire({
                            title: 'Warning',
                            text: `${err.message}`,
                            type: 'warning',
                            showCancelButton: false,
                            confirmButtonText: 'Ok'
                        })

                    })
                } else if ((method == 'POST')) {

                    const formulaJSON = {
                        technician: {
                            id: this.state.user.id
                        },
                        commissionPercentage: percentg,
                        minGrossAmount: mingr,
                        maxGrossAmount: maxgr
                    }


                    userService.saveCommissionScale(formulaJSON).then(res => {
                        userService.getTechnicianInfo(this.state.user.id).then(user => {

                            this.resetState(user);

                        })
                        window.DataUpdateSuccess();
                    }).catch(e => {
                        e.json().then(err => {
                            Swal.fire({
                                title: 'Warning',
                                text: `${err.message}`,
                                type: 'warning',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            })
                        })
                    })
                }


            } else {
                Swal.fire({
                    title: 'Warning',
                    text: `Invalid Input Data`,
                    type: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Ok'
                })
            }

        } else if (method == 'DELETE') {
            Swal.fire({
                title: 'Deletation',
                text: `Are you sure you want to delete?`,
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, keep it'
            }).then((result) => {
                if (result.value) {
                    userService.deleteCommissionScale(sid).then(d => {
                        userService.getTechnicianInfo(this.state.user.id).then(res => {
                            this.resetState(res)
                        })
                        window.toast('error', `This data has been deleted from the system`)
                    }).catch(e => {
                        Swal.fire({
                            title: 'Warning',
                            text: `${e.message}.`,
                            type: 'warning',
                            showCancelButton: false,
                            confirmButtonText: 'Ok'
                        })
                    })
                }
            })
        }
    }

    clearDate = () => {
        this.setState({
            defaultStartDate: moment().startOf('week'),
            defaultEndDate: moment(),
            predefined: 'THIS_WEEK'
        }, () => {
            this.initializeSalesData()
        })
    }

    clearSales = () => {
        this.setState({
            tabCond: true,
            defaultStartDate: moment().startOf('week'),
            defaultEndDate: moment(),
            predefined: 'THIS_WEEK',
            timeSheet: true,
            salesCount: 1,
            maxCount: 1
        }, () => {
            this.initializeTimeData()
        })
    }


    formatPredefined = (predefined) => {
        if (predefined == 'TODAY') {
            return predefined
        } else {
            return predefined.replace(/_/g, " ");
        }
    }


    grossAmountChecking = (id, query) => {

        if (this.state.user != '') {

            var formula = this.state.user.formulas.find(f => f.id == id);
            if (formula) {

                if (query === 'minGrossAmount') {
                    return formula.minGrossAmount
                }
                if (query === 'maxGrossAmount') {
                    return formula.maxGrossAmount
                }

                if (query === 'commissionPercentage') {
                    return formula.commissionPercentage
                }

                if (query == 'id') {
                    return formula.id
                }


            }
        }
    }


    checkOfferedService = (id, key) => {
        const s = this.state.user.technicianServices.find(t => t.service.id == id);
        if (s) {
            return <tr key={key}>
                <td>

                    <div className="form-check">
                        <input
                            onChange={this.handleCheckbox}
                            value={s.id}
                            type="checkbox"
                            id={`return-${s.id}`}
                            className="form-check-input"/> &nbsp;
                        <label
                            className="form-check-label"
                            htmlFor={`return-${s.id}`}>{s.service.name}</label>
                    </div>
                </td>
                <td>{this.isChecked(s.service.id, 'displayedPrice')}</td>
                <td>{this.isChecked(s.service.id, 'duration')}</td>
                <td>{this.isChecked(s.service.id, 'processingTimeInMinutes')}</td>
                <td>
                    <label
                        className="label light-green lable-info">{this.isChecked(s.service.id, 'newClientRetentionPercentage')}</label>


                    <label
                        className="label dark-green label-info">{this.isChecked(s.service.id, 'oldClientRetentionPercentage')}</label>

                </td>
                <td>
                    <button data-toggle="modal" data-target="#updateStaffService" onClick={() => this.openEditModal(s)}
                            className="btn btn-outline btn-info btn-lg btn-circle"><i className="fa fa-pencil"></i>
                    </button>
                </td>
            </tr>

        }
    }


    openEditModal = (s) => {
        this.setState({
            ...this.state,
            item: [],
            mode: this.isChecked(s.service.id, 'pricingModel'),
            min: this.isChecked(s.service.id, 'minimumFee'),
            max: this.isChecked(s.service.id, 'maximumFee'),
            durat: this.isChecked(s.service.id, 'duration'),
            process: this.isChecked(s.service.id, 'processingTimeInMinutes'),
            sid: s.service.id,
            techServiceId: s.id

        })

    }


    openAddModal = (s) => {
        this.setState({
            ...this.state,
            mode: this.isCheckedAllList(s.service.id, s, 'pricingModel'),
            min: this.isCheckedAllList(s.service.id, s, 'minimumFee'),
            max: this.isCheckedAllList(s.service.id, s, 'maximumFee'),
            durat: this.isCheckedAllList(s.service.id, s, 'duration'),
            process: this.isCheckedAllList(s.service.id, s, 'processingTimeInMinutes'),
            sid: s.service.id,
            techServiceId: s.id

        })
        //$('#addService').modal('toggle')
    }
    // handleCheckboxForAdd = (e, s) => {
    //     var items = this.state.items;
    //     if (e.target.checked) {
    //         items.push({
    //             mode: this.isCheckedAllList(s.service.id, s, 'pricingModel'),
    //             min: this.isCheckedAllList(s.service.id, s, 'minimumFee'),
    //             max: this.isCheckedAllList(s.service.id, s, 'maximumFee'),
    //             durat: this.isCheckedAllList(s.service.id, s, 'duration'),
    //             process: this.isCheckedAllList(s.service.id, s, 'processingTimeInMinutes'),
    //             sid: s.service.id,
    //             techServiceId: s.id
    //         });
    //         this.setState({
    //             items: items
    //         })
    //     } else {
    //         const newItem = this.state.items.filter(i => i.techServiceId != e.target.value);
    //         this.setState({
    //             items: newItem
    //         })
    //     }
    // }


    isChecked = (id, query) => {
        if (this.state.user != '') {

            var service = this.state.user.technicianServices.find(s => s.service.id == id);


            if (service) {

                if (query === 'pricingModel') {
                    return this.checkPriceType(service.minimumFee, service.maximumFee)
                }
                if (query === 'duration') {
                    return helperFunctions.timeConvert(service.duration, 'minute');
                }

                if (query === 'minimumFee') {
                    return service.minimumFee
                }

                if (query === 'maximumFee') {
                    return service.maximumFee
                }
                if (query === 'displayedPrice') {
                    return service.displayedPrice
                }

                if (query == 'sId') {
                    return service.id
                }
                if (query == 'processingTimeInMinutes') {
                    return service.processingTimeInMinutes
                }
                if (query == 'newClientRetentionPercentage') {
                    return service.newClientRetentionPercentage
                }
                if (query == 'oldClientRetentionPercentage') {
                    return service.oldClientRetentionPercentage
                }


            }
        }
    }


    checkPriceType = (min, max) => {

        if (min == '-1' && max == '-1') {
            return 'Per Consult'
        } else if (min > 0 && max > 0 && min < max) {
            return 'Price Range'
        } else if (min > 0 && max == -1) {
            return 'Starting Price'
        } else {
            return 'Fixed Price'
        }
    }


    checkAvailableService = (id, s, key) => {
        const existingService = this.state.user.technicianServices.find(t => t.service.id == id);
        return (<tr className={`${existingService ? 'existing' : 'new'}`} key={key}>
            <td style={{width: '350px'}}>
                <div className={`form-check`}>
                    <input
                        checked={existingService != undefined}
                        // onChange={(event) => this.handleCheckboxForAdd(event, s)}
                        type="checkbox"
                        //value={id}
                        id={`add-${id}`}
                        className="form-check-input"/> &nbsp;
                    <label
                        htmlFor={`add-${id}`}
                        className="form-check-label">{s.service.name}</label>
                </div>

            </td>
            <td>{existingService !== undefined ? existingService.displayedPrice : s.service.displayedPrice}</td>
            <td>{existingService !== undefined ? existingService.durationInMinutes : s.service.durationInMinutes}</td>
            <td>{existingService !== undefined ? existingService.processingTimeInMinutes : '---'}</td>
            <td>
                <div className={"btn-group btn-group-xs"}>
                    {existingService &&
                        <React.Fragment>
                            <button onClick={() => this.deleteServiceTechnician(existingService)}
                                    className={"btn btn-danger"}><i className={"fa fa-trash"}></i></button>
                            <button data-toggle="modal" data-target="#updateStaffService"
                                    onClick={() => this.openEditModal(existingService)} className={"btn btn-info"}><i
                                className={"fa fa-edit"}></i></button>
                        </React.Fragment>
                    }
                    {!existingService &&
                        <button data-toggle="modal" data-target="#addServiceForm" onClick={() => this.openAddModal(s)}
                                className={"btn btn-primary"}><i className={"fa fa-plus"}></i></button>}
                </div>

            </td>
        </tr>)
    }

    checkCategory = (s, k) => {
        if (s) {
            if (typeof (s.parent) == "undefined") {
                let sum = this.makeCategoryList(s.id)
                return (
                    <React.Fragment key={k}>
                        {sum.length > 0 &&
                            <li className="dropdown" role="presentation"><a data-toggle="dropdown"
                                                                            className="dropdown-toggle">{s.name} <span
                                className="caret"></span></a>
                                <ul className="dropdown-menu">
                                    {this.makeCategoryList(s.id)}
                                </ul>
                            </li>
                        }
                        {sum.length == 0 && this.state.user.technicianServices.filter(t => t.service.category.id == s.id).length > 0 &&
                            <li className="dropdown" role="presentation"><a
                                onClick={() => this.searchServicesByCategory(s.id)} data-toggle="dropdown"
                                className="dropdown-toggle">{s.name}</a></li>

                        }

                    </React.Fragment>
                )

            }
        }
    }

    checkCategoryForAll = (s, k) => {
        if (s) {
            if (typeof (s.parent) == "undefined") {
                let sum = this.makeCategoryForAllList(s.id)
                return (
                    <React.Fragment key={k}>
                        {sum.length > 0 &&
                            <li className="dropdown" role="presentation"><a data-toggle="dropdown"
                                                                            className="dropdown-toggle">{s.name} <span
                                className="caret"></span></a>
                                <ul className="dropdown-menu">
                                    {this.makeCategoryForAllList(s.id)}
                                </ul>
                            </li>
                        }
                        {sum.length == 0 &&
                            <li className="dropdown" role="presentation"><a
                                onClick={() => this.searchServicesByCategory(s.id)} data-toggle="dropdown"
                                className="dropdown-toggle">{s.name}</a></li>

                        }

                    </React.Fragment>
                )

            }
        }
    }

    makeCategoryList = (ids) => {

        const fill = this.state.categories.filter(osc => typeof (osc.parent) != "undefined")
        const chld = fill.filter(f => f.parent.id == ids)
        let list = []
        chld.map((ch, key) => {
            const techService = this.state.user.technicianServices.filter(t => t.service.category.id == ch.id)
            if (techService.length > 0) {
                list.push(<li key={key}><a onClick={() => this.searchServicesByCategory(ch.id)}>{ch.name}</a></li>)
            }
        })
        return list;

    }
    makeCategoryForAllList = (ids) => {
        const fill = this.state.categories.filter(osc => typeof (osc.parent) != "undefined")
        const chld = fill.filter(f => f.parent.id == ids)
        let list = []
        chld.map((ch, key) => {
            const serviceExist = this.state.categories.find(oc => oc.id == ch.id);
            if (serviceExist.services.length > 0) {
                list.push(<li key={key}><a onClick={() => this.searchServicesByCategory(ch.id)}>{ch.name}</a></li>)
            }
        })
        return list

    }


    findCategory = () => {
        //console.log(this.state.ctgryId)
        const osc = this.state.categories.find(osc => osc.id == this.state.ctgryId);
        if (osc) {
            return osc.name
        } else {
            return 'All'
        }
    }


    calculateWeekWiseRegular = (weeks) => {
        var sum = 0;
        var totalBreaks = 0;
        for (var i = 0; i < weeks.length; i++) {
            sum += moment(weeks[i].period.to).diff(moment(weeks[i].period.from))

            if (weeks[i].breaks.length > 0) {
                weeks[i].breaks.map(b => {
                    totalBreaks += moment(b.period.to).diff(moment(b.period.from))
                })
            }

        }
        return parseInt(moment.duration(sum - totalBreaks).asHours()) + "h" + " " + parseInt(moment.duration(sum - totalBreaks).asMinutes()) % 60 + 'm'
    }

    calculateWeekWiseBreak = (weeks) => {

        var totalBreaks = 0;
        for (var i = 0; i < weeks.length; i++) {
            if (weeks[i].breaks.length > 0) {
                weeks[i].breaks.map(b => {
                    totalBreaks += moment(b.period.to).diff(moment(b.period.from))
                })
            }

        }
        return parseInt(moment.duration(totalBreaks).asHours()) + "h" + " " + parseInt(moment.duration(totalBreaks).asMinutes()) % 60 + 'm'
    }


    checkTotalWeekHOurs = (weeks) => {
        //log(weeks)
        var sum = 0;
        for (var i = 0; i < weeks.length; i++) {
            sum += moment(weeks[i].period.to).diff(moment(weeks[i].period.from))
        }
        return parseInt(moment.duration(sum).asHours()) + "h" + " " + parseInt(moment.duration(sum).asMinutes()) % 60 + 'm'

    }


    calculateBreakingTime = (breaks) => {
        var sum = 0;
        breaks.map(b => {
            sum += moment(b.period.to).diff(moment(b.period.from))
        })
        return parseInt(moment.duration(sum).asHours()) + "h" + " " + parseInt(moment.duration(sum).asMinutes()) % 60 + 'm'

    }


    ordinal_suffix_of(i) {
        var j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }
        return i + "th";
    }


}

function mapStateToProps(state) {
    const {updating, profileUpdated, profileUpdateError} = state.updateProfile
    const {message} = state.alert;
    return {
        message,
        updating,
        profileUpdated,
        profileUpdateError
    };
}

const connectedTechnicianDetailPage = connect(mapStateToProps)(TechnicianDetailPage);
export {connectedTechnicianDetailPage as TechnicianDetailPage};
