/* eslint-disable */
import React, {Component} from "react";
import CheckOut from "./checkOut";
import "rc-datetime-picker/dist/picker.min.css";
import moment from "moment";
import {DatetimePickerTrigger} from "rc-datetime-picker";
import tasksImg from "./../task.png";
import notesImg from "./../note.png";
import CustomerSearchBar from "./customerSearchBar";
import {userService} from "../_services/user.service";
import ServiceSearchBar from "./serviceSearchBar";
import InlineMessage from "./inlineMessage";
import {helperFunctions} from "../_helpers";
import "rc-time-picker/assets/index.css";
import TimePicker from "rc-time-picker";
import ProductSearchBar from "./productSearchBar";
import {appService, reservationService} from "../_services";
import NewCustomer from "./newCustomer";
import ProductScanner from "./productScanner";
import CustomTimePicker from "./custom-timer-picker";
import NotePopUp from "./note-popup/note-popup";

const format = "hh:mm a";
const now = moment().hour(0).minute(0);

class AddToCart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            staffNote: false,
            customerNote: false,
            editDate: false,
            service: false,
            products: false,
            dueTime: moment(),
            selectedCustomer: this.props.customer ? this.props.customer : "",
            singleProduct: "",
            selectedService: {},
            selectedServices: [],
            selectedProduct: {},
            selectedProducts: [],
            technicians: [],
            services: [],
            selectedTechnician: "",
            startTime: "",
            endTime: "",
            technician: "",
            techServices: "",
            serviceId: "",
            serviceName: "",
            servicePrice: "",
            buttonText: false,
            timeStart: "",
            timeEnd: "",
            singleService: "",
            durat: "",
            requestSoruce: "Phone Call",
            productPrice: "",
            qty: 1,
            errors: {},
            key: 1,
            place: "Type a name",
            smodal: false,
            pmodal: false,
            validQty: "",
            notes: "",
            customerId: "",
            pastAppts: "",
            customerModal: "",
            email: "",
            firstName: "",
            mobile: "",
            waitList: "",
            mount: "",
            mode: "",
            customerNotes: "",
            technicianRequested: true,
            serrors: {},
            popUp: false,
        };
        this.loggedUser = JSON.parse(localStorage.getItem("user"));
        this.app = JSON.parse(localStorage.getItem("app"));
        this.onCustomerSelect = this.onCustomerSelect.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentWillReceiveProps = () => {
        if (this.props.selected.length > 0) {
            this.setState({
                selectedServices: this.props.selected,
            });
        }
    };

    getAllTechnicians() {
        userService
            .getAllTechniciansForCalendarReservation("all", 0)
            .then((techs) => {
                var bookableTechs = techs.filter(
                    (tech) =>
                        tech.active && tech.bookable && tech.technicianServices.length > 0
                );
                this.setState({
                    technicians: bookableTechs,
                });
            });
    }

    getAllServices() {
        const tech = this.state.selectedTechnician;
        if (tech != "") {
            var techServices = this.state.technicians.find(
                (t) => t.id == this.state.selectedTechnician
            ).technicianServices;
            var techServicesDetail = [];
            this.app.offeredServiceCategories.forEach((oc) => {
                techServicesDetail = techServicesDetail.concat(
                    oc.services.filter(
                        (s) => techServices.find((ts) => ts.service.id == s.id) != null
                    )
                );
            });

            this.setState({
                services: techServicesDetail,
            });
        }
    }

    onCustomerSelect(c) {
        this.setState({
            selectedCustomer: c,
            popUp: c.staffNotes ? true : false
        });
        this.props.setPlace();
    }

    initializeServiceSelect() {
        this.getAllTechnicians();
        this.setState({
            service: !this.state.service,
            buttonText: !this.state.buttonText,
            smodal: !this.state.smodal,
        });
    }

    handleChange(e) {
        const {name, value} = e.target;

        this.setState(
            {
                ...this.state,
                [name]: value,
            },
            function () {
                if (name == "selectedTechnician") {
                    this.getAllServices();
                }

                if (name == "selectedService") {
                    this.setState({
                        selectedService: this.state.services.find((s) => s.id == value),
                    });
                }
            }
        );
    }

    handleChangeRequested = () => {
        this.setState(
            {
                ...this.state,
                technicianRequested: !this.state.technicianRequested,
            },
            () => {
                //console.log(this.state.technicianRequested)
            }
        );
    };

    changeTech = (e) => {
        const {name, value} = e.target;
        const durat = this.state.techServices.find((t) => t.id == value);

        var duration = "";
        var price = "";
        if (durat) {
            duration = durat.durationInMinute;
            price = durat.price;
        }

        setTimeout(() => {
            this.setState({
                [name]: value,
                durat:
                    this.state.timeStart != ""
                        ? moment(this.state.timeStart).add(Number(duration), "minutes")
                        : moment(this.props.startTime).add(Number(duration), "minutes"),
                servicePrice: price,
            });
        }, 200);
    };

    getDuration = (timeB, timeS) => {
        const sum = moment(timeB).diff(moment(timeS));
        const minutes = parseInt(moment.duration(sum).asMinutes());
        //console.log(minutes)
        return minutes;
    };

    changeStartTime = (start) => {
        var duration = 0;
        if (this.state.techServices.length > 0) {
            const durat = this.state.techServices.find(
                (t) => t.id == this.state.technician
            );

            if (durat) {
                duration = durat.durationInMinute;
            }
        } else {
            duration = this.getDuration(this.props.endTime, this.props.startTime);
        }

        console.log(start)


        this.setState({
            timeStart: start,
            durat: moment(start).add(Number(duration), "minutes"),
        });
    };
    changeEndTime = (end) => {
        this.setState({
            durat: end,
        });
    };

    onServiceSelect = (serv) => {
        this.setState({
            ...this.state,
            serviceId: serv.service.id,
            serviceName: serv.service.name,
        });

        if (serv != "") {
            let techServices = [];
            let technicianIds = [];
            let selectedTech = "";
            if (this.state.mode == "update") {
                selectedTech = serv.technicianServices.filter(
                    (t) => t.technician.id == this.state.technician
                );
            } else {
                selectedTech = serv.technicianServices.filter(
                    (t) => t.technician.id == this.props.selectedTech
                );
            }

            if (selectedTech.length > 0) {
                const valids = this.checkValid(selectedTech[0].technician.id);
                if (valids == "valid") {
                    techServices.push({
                        key: 0,
                        id: selectedTech[0].technician.id,
                        name: selectedTech[0].technician.user.names.nick,
                        price: selectedTech[0].displayedPrice,
                        duration: helperFunctions.timeConvert(selectedTech[0].duration),
                        durationInMinute: selectedTech[0].durationInMinutes,
                        actualPrice: this.checkPriceType(
                            selectedTech[0].minimumFee,
                            selectedTech[0].maximumFee
                        ),
                    });
                }

                let filter = "";
                if (this.state.mode == "update") {
                    filter = this.state.technician;
                } else {
                    filter = this.props.selectedTech;
                }

                serv.technicianServices
                    .filter((tech) => tech.technician.id != filter)
                    .map((technician, key) => {
                        const valid = this.checkValid(technician.technician.id);
                        if (valid == "valid") {
                            techServices.push({
                                key: key + 1,
                                id: technician.technician.id,
                                name: technician.technician.user.names.nick,
                                price: technician.displayedPrice,
                                duration: helperFunctions.timeConvert(technician.duration),
                                durationInMinute: technician.durationInMinutes,
                                actualPrice: this.checkPriceType(
                                    technician.minimumFee,
                                    technician.maximumFee
                                ),
                            });
                        }
                    });
            } else {
                serv.technicianServices.forEach((technician, key) => {
                    const valid = this.checkValid(technician.technician.id);
                    if (valid == "valid") {
                        techServices.push({
                            key: key,
                            id: technician.technician.id,
                            name: technician.technician.user.names.nick,
                            price: technician.displayedPrice,
                            duration: helperFunctions.timeConvert(technician.duration),
                            durationInMinute: technician.durationInMinutes,
                            actualPrice: this.checkPriceType(
                                technician.minimumFee,
                                technician.maximumFee
                            ),
                        });
                    }
                });
            }

            this.setState({techServices: techServices});
            var techJson = [];
            var i = 0;

            techServices.forEach((tech) => {
                techJson.push({
                    text: tech.name,
                    value: tech.id,
                    selected: false,
                    description: "[" + tech.duration + ", " + tech.price + "]",
                    imageSrc: this.findTechnician(tech.id, "image"),
                });
                i++;
            });

            setTimeout(function () {
                window.initiateTechniciansSelect(
                    techJson,
                    "#technicians-select-div-add",
                    "#technicians-select-add"
                );
            }, 100);
        }
    };
    checkValid = (id) => {
        const t = this.app.locations[0].technicians.find((t) => t.id == id);
        if (t) {
            if (t.bookable == true) {
                return "valid";
            }
        } else {
            return "Not valid";
        }
    };
    onProductSelect = (product) => {
        const products = this.state.selectedProduct;
        products.name = product.variant.product.name;
        products.id = product.id;
        products.attribute = product.variant.visualAttributeValues[0].value
        this.setState({
            selectedProduct: products,
            productPrice: product.salePrice,
            validQty: product.quantity,
            errors: {},
        });
    };

    closeCustomerModal = () => {
        this.setState({
            ...this.state,
            customerModal: "",
            email: "",
            mobile: "",
            firstName: "",
        });
    };

    addSelectedService = () => {
        var service = {};
        service.key = this.state.key++;
        service.timeStart =
            this.state.timeStart != ""
                ? moment(this.state.timeStart)
                : moment(this.props.startTime);
        service.timeEnd = this.state.durat;
        service.servicename = this.state.serviceName;
        service.price = this.state.servicePrice;
        service.technician = this.state.technician;
        service.service = this.state.serviceId;
        service.technicianRequested = this.state.technicianRequested;
        service.technicianname = this.findTechnician(this.state.technician, "nick");
        const serrors = this.serviceValidate(service);
        this.setState({serrors});
        if (Object.keys(serrors).length === 0) {
            $("#createEvent").modal("show");
            $("#addService").modal("hide");
            var selectedServices = this.state.selectedServices;
            selectedServices.push(service);
            this.props.setPlace("Type a name");

            this.setState({
                ...this.state,
                selectedServices: selectedServices,
                techServices: "",
                smodal: false,
                technician: "",
                serviceName: "",
                serrors: {},
                technicianRequested: true,
            });
        }
    };

    addSelectedProduct = () => {
        const {selectedProduct, technician} = this.state;
        let selected = this.state.selectedProducts.slice();

        if (selected.length > 0) {
            const exist = selected.find(
                (item) =>
                    item.id === selectedProduct.id &&
                    item.technicianId === this.state.technician
            );
            const temp = exist;
            if (exist) {
                selected.splice(selected.indexOf(exist), 1, {
                    ...temp,
                    qty: exist.qty + this.state.qty,
                    price:
                        exist.price + this.state.productPrice * 1 * (this.state.qty * 1),
                });
            } else {
                selected.push({
                    id: selectedProduct.id,
                    key: this.state.key++,
                    qty: this.state.qty,
                    name: selectedProduct.name,
                    price: this.state.qty * 1 * (this.state.productPrice * 1),
                    technicianId: this.state.technician,
                    techImage: this.findTechnician(technician, "image"),
                    technicianname: this.findTechnician(technician, "nick"),
                });
            }
        } else {
            selected.push({
                id: selectedProduct.id,
                key: this.state.key++,
                qty: this.state.qty,
                name: selectedProduct.name,
                price: this.state.qty * 1 * (this.state.productPrice * 1),
                technicianId: this.state.technician,
                techImage: this.findTechnician(technician, "image"),
                technicianname: this.findTechnician(technician, "nick"),
            });
        }

        $("#addProduct").modal("toggle");
        $("#createEvent").modal("toggle");
        this.props.setPlace("waitList");
        this.setState({
            selectedProducts: selected,
            techServices: "",
            technician: "",
            productPrice: "",
            validQty: "",
            qty: 1,
            technicianname: "",
            productName: "",
            errors: {},
            pmodal: false,
        });
    };

    validate = (data) => {
        const errors = {};
        if (!data.id) errors.product = "Please select a product";
        if (!data.qty || data.qty < 1) errors.qty = "Please enter valid quantity ";
        if (!data.price) errors.price = "Please enter a valid price";
        if (!data.technicianId) errors.technicianId = "Please select a staff";

        return errors;
    };

    serviceValidate = (data) => {
        //let duration = this.getDuration(data.timeEnd, data.timeStart);

        const serrors = {};
        if (!data.timeStart) serrors.timeStart = "Please select a start time";
        if (!data.timeEnd) serrors.timeEnd = "Please select valid end time";
        // if (duration < 0) {
        //     serrors.timeEnd = "Please select valid end time";
        // }
        if (!data.servicename)
            serrors.servicename = "Please select a service first";
        if (!data.price) serrors.price = "Please enter the price";
        if (!data.technician) serrors.technician = "Please choose a technician!";
        if (!data.service) serrors.technician = "Please choose a service!";

        return serrors;
    };

    checkPriceType = (min, max) => {
        if (min == "-1" && max == "-1") {
            return "Per Consult";
        } else if (min > 0 && max > 0 && min < max) {
            return min * 1;
        } else if (min > 0 && max == -1) {
            return min * 1;
        } else {
            return min * 1;
        }
    };

    saveAppointment = () => {
        const {selectedServices, selectedProducts} = this.state;
        if (selectedServices.length != 0) {
            var tech = [];
            var purchaseItems = [];
            if (selectedProducts.length > 0) {
                for (var i = 0; i < selectedProducts.length; i++) {
                    var singleProduct = {
                        technician: {
                            id: selectedProducts[i].technicianId,
                        },
                        quantity: selectedProducts[i].qty,
                        chargeAmount: selectedProducts[i].price,
                        locatedProductVariant: {
                            id: selectedProducts[i].id,
                        },
                    };

                    purchaseItems.push(singleProduct);
                }
            } else {
                purchaseItems = [];
            }
            const datePart = moment(this.props.apptDate).format("YYYY-MM-DDT");

            for (var i = 0; i < selectedServices.length; i++) {
                var singleTech = {
                    technician: {
                        id: selectedServices[i].technician,
                    },
                    offeredService: {
                        id: selectedServices[i].service,
                    },
                    expectedStartTime:
                        datePart + moment(selectedServices[i].timeStart).format("HH:mm:ss"),
                    period: {
                        to:
                            datePart + moment(selectedServices[i].timeEnd).format("HH:mm:ss"),
                    },
                    technicianRequested: selectedServices[i].technicianRequested,
                };

                tech.push(singleTech);
            }
            const apptJSON = {
                location: {
                    id: this.app.locations[0].id,
                },
                requestSource: this.state.requestSoruce,
                requestType: "Appointment",
                standingAppointment: false,
                staffNotes: this.state.customerNotes,
                visitTechnicians: tech,
                purchaseItems: purchaseItems,
                client: {
                    id: this.state.selectedCustomer.id
                        ? this.state.selectedCustomer.id
                        : this.props.customer.id
                            ? this.props.customer.id
                            : this.props.waitCustomer.id,
                },
            };

            this.props.saveAppointment(apptJSON);

            this.setState(
                {
                    ...this.state,
                    selectedServices: [],
                    techServices: [],
                    technician: "",
                    selectedCustomer: "",
                    selectedProducts: [],
                    serviceName: "",
                    technicianRequested: true,
                    customerNotes: ''
                },
                () => {
                    this.setState({
                        selectedServices: [],
                    });
                }
            );
            $("#createEvent").modal("toggle");
            this.props.setPlace();
        } else {
            var tech = [];
            var purchaseItems = [];
            if (selectedProducts.length > 0) {
                for (var i = 0; i < selectedProducts.length; i++) {
                    var singleProduct = {
                        technician: {
                            id: selectedProducts[i].technicianId,
                        },
                        quantity: selectedProducts[i].qty,
                        chargeAmount: selectedProducts[i].price,
                        locatedProductVariant: {
                            id: selectedProducts[i].id,
                        },
                    };

                    purchaseItems.push(singleProduct);
                }
            } else {
                purchaseItems = [];
            }
            const datePart = moment(this.props.apptDate).format("YYYY-MM-DDT");

            for (var i = 0; i < this.props.selected.length; i++) {
                var singleTech = {
                    technician: {
                        id: this.props.selected[i].technician,
                    },
                    offeredService: {
                        id: this.props.selected[i].service,
                    },
                    expectedStartTime:
                        datePart +
                        moment(this.props.selected[i].timeStart).format("HH:mm:ss"),
                    period: {
                        to:
                            datePart +
                            moment(this.props.selected[i].timeEnd).format("HH:mm:ss"),
                    },
                };

                tech.push(singleTech);
            }
            const apptJSON = {
                location: {
                    id: this.app.locations[0].id,
                },
                requestSource: this.state.requestSoruce,
                requestType: "Appointment",
                standingAppointment: false,
                customerNotes: "",
                visitTechnicians: tech,
                purchaseItems: purchaseItems,
                client: {
                    id: this.state.selectedCustomer.id
                        ? this.state.selectedCustomer.id
                        : this.props.waitCustomer.id,
                },
            };

            this.props.saveAppointment(apptJSON);
            this.setState(
                {
                    selectedServices: [],
                    techServices: [],
                    technician: "",
                    selectedCustomer: "",
                    selectedProducts: [],
                    serviceName: "",
                    technicianRequested: true,
                    customerNotes: ''
                },
                () => {
                    this.setState({
                        selectedServices: [],
                    });
                }
            );
            $("#createEvent").modal("toggle");
        }
    };

    incrementQty = () => {
        var qty = parseInt(this.state.qty, 10);
        qty++;
        if (qty >= this.state.validQty) {
            this.setState({
                qty: this.state.validQty,
            });
        } else {
            this.setState({
                qty: qty,
            });
        }
    };
    decrementQty = () => {
        var qty = parseInt(this.state.qty, 10);
        if (isNaN(qty)) {
            this.setState({
                qty: 1,
            });
        } else {
            if (qty > 1) {
                this.setState({
                    qty: qty - 1,
                });
            }
        }
    };

    //remove product from the cart
    removeProduct = (key) => {
        const newSelectedProducts = this.state.selectedProducts.filter(
            (p) => p.key != key
        );
        this.setState({
            ...this.state,
            selectedProducts: newSelectedProducts,
        });
    };

    //remove product from the cart
    removeService = (key) => {
        const newSelectedServices = this.state.selectedServices.filter(
            (s) => s.key != key
        );
        this.setState({
            ...this.state,
            selectedServices: newSelectedServices,
        });
    };

    refreshCreateModal = (val) => {
        this.setState(
            {
                selectedServices: [],
                techServices: [],
                technician: "",
                selectedCustomer: "",
                selectedProducts: [],
                customerNotes: '',
                popUp: false,
                errors: {},
            },
            () => {
                this.setState({
                    selectedServices: [],
                });
            }
        );

        this.props.setPlace(val);
    };

    openMiniModal = (id) => {
        reservationService.getPastAppointments(id).then((a) => {
            this.setState({
                pastAppts: a,
                miniModal: true,
            });
        });
    };
    openMiniModalForOpen = (id) => {
        reservationService.getOpenAppointments(id).then((a) => {
            this.setState({
                pastAppts: a,
                miniModal: true,
            });
        });
    };

    register = (newUser) => {
        this.setState({
            loading: true,
        });
        userService
            .register(newUser)
            .then((res) => {
                $("#createEvent").modal("toggle");
                $("#addCustomer").modal("toggle");
                this.setState({
                    ...this.state,
                    selectedCustomer: res,
                    customerModal: "",
                    loading: false,
                });
            })
            .catch((err) => {
                err.json().then((e) => {
                    Swal.fire({
                        title: "Error",
                        text: `${e.message}`,
                        type: "warning",
                        showCancelButton: false,
                        confirmButtonText: "Ok!",
                    });
                });
            });
    };

    getNewValue = (value) => {
        var emailChecker =
            /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        var numberChecker = /^\d+$/;
        var nameChecker = /^[a-zA-Z]/;
        if (emailChecker.test(value)) {
            this.setState({
                email: value,
                firstName: "",
                mobile: "",
            });
        } else if (numberChecker.test(value)) {
            this.setState({
                email: "",
                firstName: "",
                mobile: value,
            });
        } else if (nameChecker.test(value)) {
            this.setState({
                email: "",
                firstName: value,
                mobile: "",
            });
        } else {
            this.setState({
                email: "",
                firstName: "",
                mobile: "",
            });
        }
    };

    collectPastServices = (appt) => {
        $("#createEvent").modal("show");
        $("#MiniModal").modal("hide");

        var selectedProducts = [];

        if (appt.visitTechnicians && appt.visitTechnicians.length > 0) {
            appt.visitTechnicians.map((services, key) => {
                appService
                    .getSingleServiceData(services.offeredService.id)
                    .then((res) => {
                        let tech = res.technicianServices.find(
                            (ts) => ts.technician.id == this.props.selectedTech
                        );
                        if (tech) {
                            var selectedServices = [];
                            selectedServices.push({
                                key: this.state.key++,
                                timeStart: moment(this.props.startTime),
                                timeEnd: moment(this.props.startTime).add(
                                    tech.durationInMinutes,
                                    "minutes"
                                ),
                                servicename: this.findServiceName(
                                    services.offeredService.id,
                                    services.offeredService.category.id
                                ),
                                price: services.chargeAmount,
                                technician: this.props.selectedTech,
                                service: services.offeredService.id,
                                technicianname: this.findTechnician(
                                    this.props.selectedTech,
                                    "nick"
                                ),
                                technicianRequested: services.technicianRequested,
                            });

                            this.setState({
                                selectedServices: selectedServices,
                            });
                        } else {
                            let otherTech = res.technicianServices.find(
                                (ts) => ts.technician.id == services.technician.id
                            );
                            if (otherTech) {
                                let selectedServices = [];

                                selectedServices.push({
                                    key: selectedServices.length + 1,
                                    timeStart: moment(this.props.startTime),
                                    timeEnd: moment(this.props.startTime).add(
                                        otherTech.durationInMinutes,
                                        "minutes"
                                    ),
                                    servicename: this.findServiceName(
                                        services.offeredService.id,
                                        services.offeredService.category.id
                                    ),
                                    price: services.chargeAmount,
                                    technician: services.technician.id,
                                    service: services.offeredService.id,
                                    technicianname: this.findTechnician(
                                        services.technician.id,
                                        "nick"
                                    ),
                                    technicianRequested: services.technicianRequested,
                                });

                                this.setState({
                                    selectedServices: selectedServices,
                                });
                            }
                        }
                    });

                this.props.setPlace("Type a name");
            });
        }
        if (appt.purchaseItems && appt.purchaseItems.length > 0) {
            appt.purchaseItems.map((p, key) => {
                selectedProducts.push({
                    id: p.locatedProductVariant.id,
                    key: key,
                    qty: p.quantity,
                    name: p.locatedProductVariant.variant.product.name,
                    price: p.chargeAmount,
                    technicianId: p.technician.id,
                    techImage: this.findTechnician(p.technician.id, "image"),
                    technicianname: this.findTechnician(p.technician.id, "nick"),
                });
            });
        }

        this.setState({
            ...this.state,
            selectedProducts: selectedProducts,
            techServices: "",
            smodal: false,
            technician: "",
        });
    };

    editService = (key) => {
        this.closeThisModal();
        $("#createEvent").modal("toggle");
        setTimeout(() => {
            const service = this.state.selectedServices.find((s) => s.key == key);
            this.setState({
                ...this.state,
                serviceId: service.service,
                serviceName: service.servicename,
                place: service.servicename,
                mode: "update",
                key: key,
                timeStart: moment(service.timeStart),
                technicianRequested: service.technicianRequested,
            });

            appService.getSingleServiceData(service.service, "").then((res) => {
                if (res != "") {
                    let techService = [];
                    const selectedT = res.technicianServices.filter(
                        (t) => t.technician.id == service.technician
                    );
                    if (selectedT.length > 0) {
                        const validet = this.checkValid(selectedT[0].technician.id);
                        if (validet == "valid") {
                            techService.push({
                                key: 0,
                                id: selectedT[0].technician.id,
                                name: selectedT[0].technician.user.names.nick,
                                price: selectedT[0].displayedPrice,
                                duration: helperFunctions.timeConvert(selectedT[0].duration),
                                durationInMinute: selectedT[0].durationInMinutes,
                                actualPrice: this.checkPriceType(
                                    selectedT[0].minimumFee,
                                    selectedT[0].maximumFee
                                ),
                            });
                        }

                        res.technicianServices
                            .filter((tech) => tech.technician.id != service.technician)
                            .forEach((tech, k) => {
                                const valid = this.checkValid(tech.technician.id);
                                if (valid == "valid") {
                                    techService.push({
                                        key: k + 1,
                                        id: tech.technician.id,
                                        name: tech.technician.user.names.nick,
                                        price: tech.displayedPrice,
                                        duration: helperFunctions.timeConvert(tech.duration),
                                        durationInMinute: tech.durationInMinutes,
                                        actualPrice: this.checkPriceType(
                                            tech.minimumFee,
                                            tech.maximumFee
                                        ),
                                    });
                                }
                            });
                    } else {
                        res.technicianServices.forEach((techn, keys) => {
                            const valid = this.checkValid(techn.technician.id);
                            if (valid == "valid") {
                                techService.push({
                                    key: keys,
                                    id: techn.technician.id,
                                    name: techn.technician.user.names.nick,
                                    price: techn.displayedPrice,
                                    duration: helperFunctions.timeConvert(techn.duration),
                                    durationInMinute: techn.durationInMinutes,
                                    actualPrice: this.checkPriceType(
                                        techn.minimumFee,
                                        techn.maximumFee
                                    ),
                                });
                            }
                        });
                    }

                    this.setState({
                        techServices: techService,
                    });
                    var techJson = [];
                    var i = 0;

                    techService.forEach((tech) => {
                        techJson.push({
                            text: tech.name,
                            value: tech.id,
                            selected: false,
                            description: "[" + tech.duration + ", " + tech.price + "]",
                            imageSrc: this.findTechnician(tech.id, "image"),
                        });
                        i++;
                    });

                    setTimeout(function () {
                        window.initiateTechniciansSelect(
                            techJson,
                            `#technicians-select-div-add`,
                            `#technicians-select-add`
                        );
                    }, 100);
                }
            });
        }, 100);

        $("#addService").modal("show");
        $("#createEvent").modal("hide");
    };

    clearState = () => {
        this.setState({
            place: "",
        });
    };
    updateService = () => {
        const newSelectedServices = this.state.selectedServices.map(
            (service, key) => {
                if (service.key == this.state.key) {
                    return {
                        ...service,
                        timeStart: this.state.timeStart,
                        timeEnd: this.state.durat,
                        technician: this.state.technician,
                        service: this.state.serviceId,
                        servicename: this.state.serviceName,
                        technicianRequested: this.state.technicianRequested,
                    };
                }
                return service;
            }
        );

        this.setState({
            selectedServices: newSelectedServices,
        });
    };

    onProductScan = (product) => {
        if (product.length > 0) {
            this.setState({
                selectedProduct: {
                    name: product[0].variant.product.name,
                    id: product[0].id,
                    attribute: product[0].variant.visualAttributeValues[0].value
                },
                productPrice: product[0].salePrice,
                validQty: product[0].quantity,
                productName: product[0].variant.product.name,
                productId: product[0].id,
                errors: {},
            });
        } else {
            this.setState({
                errors: {
                    product: "Product Not Found!",
                },
            });
        }
    };

    clearPlace = () => {
        this.setState({
            place: "",
        });
    };

    clearScanner = () => {
        this.setState({
            productName: "",
        });
    };

    render() {
        const {customer, waitCustomer} = this.props;
        const {
            selectedCustomer,
            selectedProducts,
            selectedServices,
            technicians,
            selectedTechnician,
            services,
            selectedService,
            errors,
        } = this.state;
        return (
            <React.Fragment>
                <div
                    data-backdrop="static"
                    data-keyboard="false"
                    className="modal fade"
                    id="createEvent"
                >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    onClick={() => this.refreshCreateModal("Type a name.")}
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                                <h4 className="modal-title" id="cancelLabelModel">
                                    Appointment
                                </h4>
                            </div>
                            <div className="modal-body">
                                <NotePopUp
                                    isVisible={this.state.popUp}
                                    close={() => this.setState({popUp: false, customerNotes: ''})}
                                    handler={this.handleChange}
                                    customerNotes={this.state.selectedCustomer.staffNotes ? this.state.selectedCustomer.staffNotes : 'NONE'}
                                    staffNotes={this.state.customerNotes}
                                    btnClick={() => this.setState({popUp: !this.state.popUp})}
                                />

                                <div className="client-info addApt">
                                    <div className="info-header">
                                        <div className="row">
                                            <div className="col-md-6">
                                                {this.props.selectNewCustomer == true && (
                                                    <React.Fragment>
                                                        <div className="form-group">
                                                            <label>Client name</label>
                                                            <CustomerSearchBar
                                                                place={this.props.place}
                                                                id="newCustomer"
                                                                clearPlace={this.props.clearPlace}
                                                                getNewValue={this.getNewValue}
                                                                onCustomerSelect={this.onCustomerSelect}
                                                            />
                                                        </div>
                                                    </React.Fragment>
                                                )}
                                                {this.props.selectNewCustomer == false &&
                                                    this.props.customer && (
                                                        <div className="form-group">
                                                            <CustomerSearchBar
                                                                place={this.props.customer.fullName}
                                                                id="newId"
                                                                onCustomerSelect={this.onCustomerSelect}
                                                            />
                                                        </div>
                                                    )}
                                                {this.props.selectNewCustomer == false &&
                                                    this.props.waitCustomer && (
                                                        <div className="form-group">
                                                            <CustomerSearchBar
                                                                place={this.props.waitCustomer.fullName}
                                                                id="newId"
                                                                onCustomerSelect={this.onCustomerSelect}
                                                            />
                                                        </div>
                                                    )}
                                            </div>
                                            <div className="col-md-6">
                                                {this.props.selectNewCustomer == true && (
                                                    <div className="add-new-customer text-right">
                                                        <br/>
                                                        <button
                                                            data-dismiss="modal"
                                                            data-toggle="modal"
                                                            data-target="#addCustomer"
                                                            className="btn btn-custom-info"
                                                        >
                                                            +New Client
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="client">
                                            {selectedCustomer != "" && (
                                                <React.Fragment>
                                                    <div className="client-wrapper d-flex flex-column justify-start">
                                                        <div className="chat-image">
                                                            <img
                                                                style={{width: "40px", height: "40px"}}
                                                                src={selectedCustomer.imageUrl}
                                                                alt=""
                                                            />
                                                        </div>


                                                        <div
                                                            className="notes m-t-20"
                                                            style={{marginLeft: "-16px", cursor: "pointer"}}
                                                        >
                                                            <div className="btn-rounded-with-image">
                                                                <div className="customer-task">
                                                                    <button
                                                                        title="Staff Note"
                                                                        onClick={() =>
                                                                            this.setState({
                                                                                popUp: !this.state.popUp,
                                                                            })
                                                                        }
                                                                    >
                                                                        <img src={tasksImg} alt=""/>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                    <div className="chat-body">
                                                        <div className="chat-text">
                                                            <div>
                                                                <h3>
                                                                    <strong>{selectedCustomer.fullName}</strong>
                                                                </h3>
                                                                {this.loggedUser.technicianResponsibilities.length > 0 && this.loggedUser.technicianResponsibilities[0].customerAccessAllowed === false ? null :
                                                                    <p>
                                                                        <i className="fa fa-phone"></i>{" "}
                                                                        {helperFunctions.formatMobilePhone(
                                                                            selectedCustomer.mobileNumber
                                                                        )}
                                                                    </p>}
                                                                <p>
                                                                    <i className="fa fa-dollar"></i>{" "}
                                                                    {(selectedCustomer.rewardBalance * 1).toFixed(
                                                                        2
                                                                    )}
                                                                </p>
                                                                <div className="appt-time">
                                                                    <p>
                                                                        <i className="fa fa-calendar"></i>{" "}
                                                                        {helperFunctions.formatDateTimeWithDay(
                                                                            this.props.apptDate,
                                                                            "latest"
                                                                        )}
                                                                    </p>
                                                                </div>
                                                                <div className="appt-time">
                                                                    <p>
                                                                        <i className="fa fa-calendar-check-o"></i>
                                                                        {selectedCustomer.lastVisit ? (
                                                                            <a
                                                                                style={{cursor: "pointer"}}
                                                                                data-toggle="modal"
                                                                                data-target="#MiniModal"
                                                                                data-dismiss="modal"
                                                                                onClick={() =>
                                                                                    this.openMiniModal(
                                                                                        selectedCustomer.id
                                                                                    )
                                                                                }
                                                                            >
                                                                                {
                                                                                    selectedCustomer.lastVisit
                                                                                        .readableBookedTime
                                                                                }{" "}
                                                                            </a>
                                                                        ) : (
                                                                            "NONE"
                                                                        )}
                                                                    </p>
                                                                </div>

                                                                <div className="appt-time">
                                                                    <p>
                                                                        <i className="fa fa-random"></i>
                                                                        {selectedCustomer.nextVisit && (
                                                                            <a
                                                                                data-toggle="modal"
                                                                                data-target="#MiniModal"
                                                                                data-dismiss="modal"
                                                                                onClick={() =>
                                                                                    this.openMiniModalForOpen(
                                                                                        selectedCustomer.id
                                                                                    )
                                                                                }
                                                                            >
                                                                                {" "}
                                                                                {
                                                                                    selectedCustomer.nextVisit
                                                                                        .readableBookedTime
                                                                                }{" "}
                                                                            </a>
                                                                        )}
                                                                        {!selectedCustomer.nextVisit && "NONE"}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <hr/>

                                                        <div className="form-group">
                                                            <strong>Source Of Request</strong>
                                                            <div className="inline-radio-buttons">
                                                                <label className="radio-inline">
                                                                    <input
                                                                        defaultChecked
                                                                        type="radio"
                                                                        value="Phone Call"
                                                                        onChange={this.handleChange}
                                                                        name="requestSoruce"
                                                                    />
                                                                    Phone Call
                                                                </label>
                                                                <label className="radio-inline">
                                                                    <input
                                                                        type="radio"
                                                                        value="Walk In"
                                                                        onChange={this.handleChange}
                                                                        name="requestSoruce"
                                                                    />
                                                                    Walk In
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div className="confirm-btn-group">
                                                            <div className="confirm-btn">
                                                                <button
                                                                    data-toggle="modal"
                                                                    data-dismiss="modal"
                                                                    data-target="#addService"
                                                                    onClick={() => this.initializeServiceSelect()}
                                                                    className={
                                                                        !this.state.buttonText
                                                                            ? "button-custom"
                                                                            : "actve"
                                                                    }
                                                                >
                                                                    Add Service
                                                                </button>
                                                                {this.app != null &&
                                                                    this.app.locations[0].brands.length > 0 && (
                                                                        <button
                                                                            data-toggle="modal"
                                                                            data-dismiss="modal"
                                                                            data-target="#addProduct"
                                                                            onClick={() =>
                                                                                this.setState({
                                                                                    products: !this.state.products,
                                                                                    pmodal: !this.state.pmodal,
                                                                                })
                                                                            }
                                                                            className={
                                                                                this.state.products
                                                                                    ? "actve"
                                                                                    : "button-custom"
                                                                            }
                                                                        >
                                                                            Add Product
                                                                        </button>
                                                                    )}
                                                            </div>

                                                        </div>

                                                    </div>
                                                </React.Fragment>
                                            )}
                                        </div>

                                        <div className="client">
                                            {customer && (
                                                <React.Fragment>
                                                    <div className="chat-image">
                                                        <img src={customer.imageUrl} alt=""/>
                                                    </div>
                                                    <div className="chat-body">
                                                        <div className="chat-text">
                                                            <div>
                                                                <h3>
                                                                    <strong>{customer.fullName}</strong>
                                                                </h3>
                                                                <p>
                                                                    <i className="fa fa-phone"></i>{" "}
                                                                    {customer.mobileNumber}
                                                                </p>
                                                                <p>
                                                                    <i className="fa fa-dollar"></i>{" "}
                                                                    {(customer.rewardBalance * 1).toFixed(2)}
                                                                </p>
                                                                <div className="appt-time">
                                                                    <p>
                                                                        <i className="fa fa-calendar"></i>{" "}
                                                                        {helperFunctions.formatDateTimeWithDay(
                                                                            this.props.apptDate,
                                                                            "date"
                                                                        )}
                                                                    </p>
                                                                </div>
                                                                <div className="appt-time">
                                                                    <p>
                                                                        <i className="fa fa-calendar-check-o"></i>

                                                                        {customer.lastVisit && (
                                                                            <a
                                                                                style={{cursor: "pointer"}}
                                                                                data-toggle="modal"
                                                                                data-target="#MiniModal"
                                                                                data-dismiss="modal"
                                                                                onClick={() =>
                                                                                    this.openMiniModal(customer.id)
                                                                                }
                                                                            >
                                                                                {customer.lastVisit.readableBookedTime}
                                                                            </a>
                                                                        )}
                                                                        {!customer.lastVisit && "NONE"}
                                                                    </p>
                                                                </div>
                                                                <div className="appt-time">
                                                                    <p>
                                                                        <i className="fa fa-random"></i>

                                                                        {customer.nextVisit
                                                                            ? customer.nextVisit.readableBookedTime
                                                                            : "NONE"}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <hr/>

                                                        <div className="form-group">
                                                            <strong>Source Of Request</strong>
                                                            <div className="inline-radio-buttons">
                                                                <label className="radio-inline">
                                                                    <input
                                                                        defaultChecked
                                                                        type="radio"
                                                                        onChange={this.handleChange}
                                                                        name="requestSoruce"
                                                                    />
                                                                    Phone Call
                                                                </label>
                                                                <label className="radio-inline">
                                                                    <input
                                                                        type="radio"
                                                                        value="Walk In"
                                                                        onChange={this.handleChange}
                                                                        name="requestSoruce"
                                                                    />
                                                                    Walk In
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div className="confirm-btn-group">
                                                            <div className="confirm-btn">
                                                                <button
                                                                    data-dismiss="modal"
                                                                    data-toggle="modal"
                                                                    data-target="#addService"
                                                                    onClick={() => this.initializeServiceSelect()}
                                                                    className={
                                                                        !this.state.buttonText
                                                                            ? "button-custom"
                                                                            : "actve"
                                                                    }
                                                                >
                                                                    Add Service
                                                                </button>
                                                                {this.app != null &&
                                                                    this.app.locations[0].brands.length > 0 && (
                                                                        <button
                                                                            data-target="#addProduct"
                                                                            data-dismiss="modal"
                                                                            data-toggle="modal"
                                                                            onClick={() =>
                                                                                this.setState({
                                                                                    products: !this.state.products,
                                                                                    pmodal: !this.state.pmodal,
                                                                                })
                                                                            }
                                                                            className={
                                                                                this.state.products
                                                                                    ? "actve"
                                                                                    : "button-custom"
                                                                            }
                                                                        >
                                                                            Add Product
                                                                        </button>
                                                                    )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )}
                                        </div>

                                        <div className="client">
                                            {waitCustomer && (
                                                <React.Fragment>
                                                    <div className="chat-image">
                                                        <img src={waitCustomer.imageUrl} alt=""/>
                                                    </div>
                                                    <div className="chat-body">
                                                        <div className="chat-text">
                                                            <div>
                                                                <h3>
                                                                    <strong>{waitCustomer.fullName}</strong>
                                                                </h3>
                                                                <p>
                                                                    <i className="fa fa-phone"></i>{" "}
                                                                    {waitCustomer.mobileNumber}
                                                                </p>
                                                                <p>
                                                                    <i className="fa fa-dollar"></i>{" "}
                                                                    {(waitCustomer.rewardBalance * 1).toFixed(2)}
                                                                </p>
                                                                <div className="appt-time">
                                                                    <p>
                                                                        <i className="fa fa-calendar"></i>{" "}
                                                                        {helperFunctions.formatDateTimeWithDay(
                                                                            this.props.apptDate,
                                                                            "date"
                                                                        )}
                                                                    </p>
                                                                </div>
                                                                <div className="appt-time">
                                                                    <p>
                                                                        <i className="fa fa-calendar-check-o"></i>

                                                                        {waitCustomer.lastVisit && (
                                                                            <a
                                                                                style={{cursor: "pointer"}}
                                                                                data-toggle="modal"
                                                                                data-target="#MiniModal"
                                                                                data-dismiss="modal"
                                                                                onClick={() =>
                                                                                    this.openMiniModal(waitCustomer.id)
                                                                                }
                                                                            >
                                                                                {
                                                                                    waitCustomer.lastVisit
                                                                                        .readableBookedTime
                                                                                }
                                                                            </a>
                                                                        )}
                                                                        {!waitCustomer.lastVisit && "NONE"}
                                                                    </p>
                                                                </div>
                                                                <div className="appt-time">
                                                                    <p>
                                                                        <i className="fa fa-random"></i>

                                                                        {waitCustomer.nextVisit
                                                                            ? waitCustomer.nextVisit
                                                                                .readableBookedTime
                                                                            : "NONE"}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <hr/>

                                                        <div className="form-group">
                                                            <strong>Source Of Request</strong>
                                                            <div className="inline-radio-buttons">
                                                                <label className="radio-inline">
                                                                    <input
                                                                        defaultChecked
                                                                        type="radio"
                                                                        onChange={this.handleChange}
                                                                        name="requestSoruce"
                                                                    />
                                                                    Phone Call
                                                                </label>
                                                                <label className="radio-inline">
                                                                    <input
                                                                        type="radio"
                                                                        value="Walk In"
                                                                        onChange={this.handleChange}
                                                                        name="requestSoruce"
                                                                    />
                                                                    Walk In
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div className="confirm-btn-group">
                                                            <div className="confirm-btn">
                                                                <button
                                                                    data-dismiss="modal"
                                                                    data-toggle="modal"
                                                                    data-target="#addService"
                                                                    onClick={() => this.initializeServiceSelect()}
                                                                    className={
                                                                        !this.state.buttonText
                                                                            ? "button-custom"
                                                                            : "actve"
                                                                    }
                                                                >
                                                                    Add Service
                                                                </button>
                                                                {this.app != null &&
                                                                    this.app.locations[0].brands.length > 0 && (
                                                                        <button
                                                                            data-target="#addProduct"
                                                                            data-dismiss="modal"
                                                                            data-toggle="modal"
                                                                            onClick={() =>
                                                                                this.setState({
                                                                                    products: !this.state.products,
                                                                                    pmodal: true,
                                                                                })
                                                                            }
                                                                            className={
                                                                                this.state.products
                                                                                    ? "actve"
                                                                                    : "button-custom"
                                                                            }
                                                                        >
                                                                            Add Product
                                                                        </button>
                                                                    )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )}
                                        </div>
                                    </div>
                                    <div className="past-appointments">
                                        {selectedServices.length > 0 && (
                                            <div className="steamline">
                                                {selectedServices.map((s, k) => {
                                                    return (
                                                        <div key={k} className="sl-item">
                                                            <div className="sl-left">
                                                                <img
                                                                    style={{width: "40px", height: "40px"}}
                                                                    src={this.findTechnician(
                                                                        s.technician,
                                                                        "image"
                                                                    )}
                                                                    alt="user"
                                                                    className="img-circle"
                                                                />
                                                            </div>
                                                            <div className="sl-right">
                                                                <div className="m-l-40">
                                                                    <p>
                                                                        <b>{s.servicename}</b> with{" "}
                                                                        <b>
                                                                            {this.findTechnician(
                                                                                s.technician,
                                                                                "nick"
                                                                            )}
                                                                        </b>
                                                                        <br/>
                                                                        <b>Time: </b>
                                                                        {moment(s.timeStart).format(format)} -{" "}
                                                                        {moment(s.timeEnd).format(format)}
                                                                        <br/>
                                                                        <b>Price:</b> {s.price}
                                                                        <br/>
                                                                        {s.technicianRequested != undefined && (
                                                                            <b>
                                                                                Stylist Preference:{" "}
                                                                                {s.technicianRequested == true ? (
                                                                                    <strong className="text-primary">
                                                                                        YES
                                                                                    </strong>
                                                                                ) : (
                                                                                    <strong className="text-primary">
                                                                                        NO
                                                                                    </strong>
                                                                                )}
                                                                            </b>
                                                                        )}
                                                                    </p>
                                                                    <p className="pb35">
                                    <span className="pull-right">
                                      <button
                                          onClick={() => this.editService(s.key)}
                                          className="btn m-t-10 m-r-5 btn-custom-info"
                                      >
                                        Edit
                                      </button>
                                      <button
                                          onClick={() =>
                                              this.removeService(s.key)
                                          }
                                          className="btn m-t-10 m-r-5 btn-danger"
                                      >
                                        Cancel
                                      </button>
                                    </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}

                                        {this.props.selected.length > 0 &&
                                            selectedServices.length == 0 && (
                                                <div className="steamline">
                                                    {this.props.selected.map((s, k) => {
                                                        return (
                                                            <div key={k} className="sl-item">
                                                                <div className="sl-left">
                                                                    <img
                                                                        src={this.findTechnician(
                                                                            s.technician,
                                                                            "image"
                                                                        )}
                                                                        alt="user"
                                                                        className="img-circle"
                                                                    />
                                                                </div>
                                                                <div className="sl-right">
                                                                    <div className="m-l-40">
                                                                        <p>
                                                                            <b>{s.servicename}</b> with{" "}
                                                                            <b>{s.technicianname}</b>
                                                                            <br/>
                                                                            <b>Time: </b>
                                                                            {moment(s.timeStart).format(
                                                                                format
                                                                            )} - {moment(s.timeEnd).format(format)}
                                                                            <br/>
                                                                            <b>Price:</b> {s.price}
                                                                            <br/>
                                                                            {s.technicianRequested != undefined && (
                                                                                <b>
                                                                                    Stylist Preference:{" "}
                                                                                    {s.technicianRequested == true ? (
                                                                                        <strong
                                                                                            className="text-primary">
                                                                                            YES
                                                                                        </strong>
                                                                                    ) : (
                                                                                        <strong
                                                                                            className="text-warning">
                                                                                            NO
                                                                                        </strong>
                                                                                    )}
                                                                                </b>
                                                                            )}
                                                                        </p>
                                                                        <p className="pb35">
                                      <span className="pull-right">
                                        <button
                                            onClick={() =>
                                                this.editService(s.key)
                                            }
                                            className="btn m-t-10 m-r-5 btn-custom-info"
                                        >
                                          Edit
                                        </button>
                                        <button
                                            onClick={() =>
                                                this.removeService(s.key)
                                            }
                                            className="btn m-t-10 m-r-5 btn-danger"
                                        >
                                          Cancel
                                        </button>
                                      </span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            )}

                                        <div className="past-appointments">
                                            {selectedProducts.length > 0 && (
                                                <div className="table-responsive table-product">
                                                    <table className="table table-bordered table-condensed">
                                                        <thead>
                                                        <tr>
                                                            <th>Product</th>
                                                            <th>Tech</th>
                                                            <th>Qty</th>
                                                            <th>Price</th>
                                                            <th className="text-center">Action</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {selectedProducts.map((s, k) => {
                                                            return (
                                                                <tr key={k}>
                                                                    <td>{s.name}</td>
                                                                    <td>
                                                                        <img
                                                                            width="30"
                                                                            className="img-circle"
                                                                            src={s.techImage}
                                                                        />{" "}
                                                                        {s.technicianname}
                                                                    </td>
                                                                    <td>{s.qty}</td>
                                                                    <td>{(s.price * 1).toFixed(2)}</td>
                                                                    <td align="center">
                                                                        <button
                                                                            onClick={() =>
                                                                                this.removeProduct(s.key)
                                                                            }
                                                                            className="cross-btn"
                                                                        >
                                                                            <i className="fa fa-close text-danger"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {this.state.selectedServices.length > 0 && (
                                <div className="modal-footer">
                                    <ul>
                                        <li>
                                            <button
                                                onClick={this.saveAppointment}
                                                className="btn btn-custom-info"
                                            >
                                                Save
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            )}
                            {this.state.selectedServices.length == 0 &&
                                this.props.selected.length > 0 && (
                                    <div className="modal-footer">
                                        <ul>
                                            <li>
                                                <button
                                                    onClick={this.saveAppointment}
                                                    className="btn btn-custom-info"
                                                >
                                                    Save
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                )}

                            {this.props.loading == true && (
                                <div className="custom-loading">
                                    <div className="loading">
                                        <i className="fa fa-spinner fa-spin"></i>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div
                    data-backdrop="static"
                    data-keyboard="false"
                    className="modal fade"
                    id="MiniModal"
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <strong>Last Appointment</strong>
                                <button
                                    data-toggle="modal"
                                    data-target="#createEvent"
                                    data-dismiss="modal"
                                    className="close"
                                    onClick={() => this.closeThisModal()}
                                >
                                    &times;
                                </button>
                            </div>
                            <div className="modal-body">
                                <strong>
                                    Appointment on{" "}
                                    {this.state.pastAppts &&
                                        helperFunctions.formatDateTimeWithDay(
                                            moment(this.state.pastAppts[0].bookedTime),
                                            "date"
                                        )}{" "}
                                    {this.state.pastAppts &&
                                        helperFunctions.formatDateTimeWithDay(
                                            this.state.pastAppts[0].bookedTime,
                                            "time"
                                        )}
                                </strong>
                                <div className="steamline">
                                    {this.state.pastAppts &&
                                        this.state.pastAppts[0].visitTechnicians.map((s, key) => {
                                            return (
                                                <div key={key} className="sl-item">
                                                    <div className="sl-left">
                                                        <img
                                                            width="30"
                                                            src={this.findTechnician(
                                                                s.technician.id,
                                                                "image"
                                                            )}
                                                            alt="user"
                                                            className="img-circle"
                                                        />
                                                    </div>
                                                    <div className="sl-right">
                                                        <div className="m-l-40">
                                                            <p>
                                                                <b>
                                                                    {this.findServiceName(
                                                                        s.offeredService.id,
                                                                        s.offeredService.category.id
                                                                    )}
                                                                </b>{" "}
                                                                with{" "}
                                                                <b>
                                                                    {this.findTechnician(s.technician.id, "nick")}
                                                                </b>
                                                                <br/>
                                                                <b>Time: </b>
                                                                {moment(s.expectedStartTime).format(
                                                                    format
                                                                )} - {moment(s.expectedEndTime).format(format)}
                                                                <br/>
                                                                <b>Price:</b> ${(s.chargeAmount * 1).toFixed(2)}
                                                                <br/>
                                                                {s.technicianRequested != undefined && (
                                                                    <b>
                                                                        Stylist Preference:{" "}
                                                                        {s.technicianRequested == true ? (
                                                                            <strong className="text-primary">
                                                                                YES
                                                                            </strong>
                                                                        ) : (
                                                                            <strong className="text-warning">
                                                                                NO
                                                                            </strong>
                                                                        )}
                                                                    </b>
                                                                )}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    onClick={() =>
                                        this.collectPastServices(this.state.pastAppts[0])
                                    }
                                    className="btn btn-custom-info"
                                >
                                    Rebook
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="addCustomer">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    data-toggle="modal"
                                    data-target="#createEvent"
                                    data-dismiss="modal"
                                    className="close"
                                    onClick={this.closeCustomerModal}
                                >
                                    &times;
                                </button>
                                <h4>Add New Customer</h4>
                            </div>
                            <div className="modal-body">
                                <NewCustomer
                                    firstName={this.state.firstName}
                                    mobile={this.state.mobile}
                                    email={this.state.email}
                                    register={this.register}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    data-backdrop="static"
                    data-keyboard="false"
                    className="modal fade"
                    id="addService"
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <button
                                    data-dismiss="modal"
                                    data-target="#createEvent"
                                    className="close"
                                    onClick={() => this.closeThisModal("Search a name")}
                                >
                                    &times;
                                </button>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group select-arrow">
                                            <label htmlFor="stylish">
                                                Service &nbsp;{" "}
                                                {this.state.serrors.service && (
                                                    <InlineMessage text={this.state.serrors.service}/>
                                                )}
                                                <span className="text-danger">*</span>
                                            </label>
                                            <ServiceSearchBar
                                                place={
                                                    this.state.place != ""
                                                        ? this.state.place
                                                        : this.props.place
                                                }
                                                clearPlace={this.props.clearPlace}
                                                setPlace={this.props.setPlace}
                                                clearState={this.clearState}
                                                id="serviceId"
                                                onServiceSelect={this.onServiceSelect}
                                            />
                                        </div>
                                        <p>
                                            {this.state.serrors.servicename && (
                                                <InlineMessage text={this.state.serrors.servicename}/>
                                            )}
                                        </p>
                                    </div>
                                    <div className="col-md-12">
                                        {this.state.techServices.length !== 0 && (
                                            <div className="form-group add-services">
                                                <label>Select Stylist</label>
                                                <select
                                                    className="hidden"
                                                    id="technicians-select-add"
                                                    name="technician"
                                                    value={this.state.technician}
                                                    onChange={this.changeTech}
                                                >
                                                    <option>Select Technician</option>
                                                    {this.state.techServices.map((e, key) => {
                                                        return (
                                                            <option key={e.id} value={e.id}>
                                                                {e.name}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                                <div
                                                    id="technicians-select-div-add"
                                                    className="add-services"
                                                ></div>
                                            </div>
                                        )}
                                        {this.state.techServices.length == 0 && (
                                            <div className="form-group add-services">
                                                <label>Select Stylist</label>
                                                <input type="text" className="form-control" readOnly/>
                                                <p>
                                                    {this.state.serrors.technician && (
                                                        <InlineMessage
                                                            text={this.state.serrors.technician}
                                                        />
                                                    )}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="endTime">
                                                Start Time &nbsp; <span className="text-danger">*</span>
                                            </label>
                                            <div className="time-clock">
                                                <CustomTimePicker
                                                    name="startTime"
                                                    onChange={this.changeStartTime}
                                                    format={format}
                                                    value={
                                                        this.state.timeStart != ""
                                                            ? moment(this.state.timeStart).format("hh:mm a")
                                                            : moment(this.props.startTime).format("hh:mm a")
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="endTime">
                                                End Time &nbsp; <span className="text-danger">*</span>
                                            </label>

                                            <div className="time-clock">
                                                <CustomTimePicker
                                                    name="endTime"
                                                    onChange={this.changeEndTime}
                                                    format={format}
                                                    value={
                                                        this.state.durat != ""
                                                            ? moment(this.state.durat).format("hh:mm a")
                                                            : moment(this.props.endTime).format("hh:mm a")
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <p>
                                            {this.state.serrors.timeEnd && (
                                                <InlineMessage text={this.state.serrors.timeEnd}/>
                                            )}
                                        </p>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <label>Selected Stylist Preferred?</label>
                                    </div>
                                    <div className="col-md-6 text-right">
                                        <div className="flex align-middle">
                                            <label htmlFor="technicianRequested">Yes &nbsp;</label>
                                            <label className="switch">
                                                <input
                                                    type="checkbox"
                                                    checked={this.state.technicianRequested}
                                                    name="technicianRequested"
                                                    readOnly
                                                    onClick={this.handleChangeRequested}
                                                />
                                                <span className="switchSlider"></span>
                                            </label>
                                            <label htmlFor="technicianRequested"> &nbsp; No</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                {this.state.mode == "update" && (
                                    <button
                                        data-dismiss="modal"
                                        data-target="#createEvent"
                                        data-toggle="modal"
                                        onClick={this.updateService}
                                        className="btn btn-custom-info"
                                    >
                                        Update Service
                                    </button>
                                )}
                                {this.state.mode == "" && (
                                    <button
                                        onClick={this.addSelectedService}
                                        className="btn btn-custom-info"
                                    >
                                        Add Service
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {this.app != null && this.app.locations[0].brands.length > 0 && (
                    <div
                        data-backdrop="static"
                        data-keyboard="false"
                        className="modal fade"
                        id="addProduct"
                    >
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    {this.state.pmodal == true && (
                                        <ProductScanner onProductScan={this.onProductScan}/>
                                    )}
                                    <button
                                        className="close"
                                        data-toggle="modal"
                                        data-dismiss="modal"
                                        data-target="#createEvent"
                                        onClick={() => this.closePmodal()}
                                    >
                                        &times;
                                    </button>
                                    <div className="row">
                                        <div className="col-md-8">
                                            <div className="form-group select-arrow">
                                                <label htmlFor="stylish">
                                                    Product &nbsp; <span className="text-danger">*</span>
                                                </label>
                                                <ProductSearchBar
                                                    id="productId"
                                                    place={this.props.place}
                                                    scanningName={this.state.productName}
                                                    clearScanner={this.clearScanner}
                                                    clearPlace={this.props.clearPlace}
                                                    setPlace={this.props.setPlace}
                                                    onProductSelect={this.onProductSelect}
                                                />
                                                <p className="text-danger error">
                                                    {errors.product && (
                                                        <InlineMessage text={errors.product}/>
                                                    )}
                                                </p>
                                            </div>

                                        </div>
                                        <div className="col-md-4">
                                            <input className="form-control"
                                                   readOnly
                                                   value={this.state.selectedProduct.attribute}/>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group add-services">
                                                <label>Select Staff</label>
                                                <select
                                                    name="technician"
                                                    id="technicians-select"
                                                    value={this.state.technician}
                                                    onChange={this.handleChange}
                                                >
                                                    <optgroup label="">
                                                        <option>Select Staff</option>
                                                    </optgroup>
                                                    {this.app.lookupGroup.teams.map((team, key) => {
                                                        return this.teamWiseTechDisplay(team, key);
                                                    })}
                                                </select>
                                                <p className="text-danger error">
                                                    {errors.technicianId && (
                                                        <InlineMessage text={errors.technicianId}/>
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="endTime">
                                                    Quantity &nbsp; <span className="text-danger">*</span>
                                                </label>

                                                <div className="quantity-counter">
                                                    <div className="count-grabber">
                                                        <input
                                                            readOnly
                                                            type="text"
                                                            className="form-control"
                                                            value={this.state.qty}
                                                        />
                                                        <div className="btns">
                                                            <div
                                                                onClick={this.incrementQty}
                                                                className="incrmntbtn"
                                                            >
                                                                <i className="fa fa-caret-up"></i>
                                                            </div>
                                                            <div
                                                                onClick={this.decrementQty}
                                                                className="drmntbtn"
                                                            >
                                                                <i className="fa fa-caret-down"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <p className="text-danger error">
                                                    {errors.qty && <InlineMessage text={errors.qty}/>}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="endTime">
                                                    {" "}
                                                    Sale Price &nbsp; <span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    value={this.state.productPrice}
                                                    className="form-control"
                                                    type="text"
                                                    name="productPrice"
                                                    onChange={this.handleChange}
                                                />
                                                <p className="text-danger error">
                                                    {errors.price && (
                                                        <InlineMessage text={errors.price}/>
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group text-right">
                                        <button
                                            onClick={this.addSelectedProduct}
                                            className="btn btn-custom-info"
                                        >
                                            Add Product
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    }

    findServiceName(serviceId, categoryId) {
        // eslint-disable-next-line
        let category = this.app.offeredServiceCategories.find(
            (sc) => sc.id == categoryId
        );
        if (category == null) return "Service not found";
        let service = category.services.find((s) => s.id == serviceId);
        if (service == null) return "Service not found";
        return service.name;
    }

    findTechnician(technicianId, query) {
        // eslint-disable-next-line
        let technician = this.app.locations[0].technicians.find(
            (technician) => technician.id == technicianId
        );

        if (technician) {
            if (query == "image") {
                return technician.user.imageUrl;
            } else if (query == "active") {
                if (technician.active == "true") {
                    return "active";
                }
            } else if (query == "nick") {
                return technician.user.names.nick;
            } else {
                return technician.user.fullName;
            }
        } else {
            return "";
        }
    }

    closeThisModal = (val) => {
        $("#createEvent").modal("show");
        this.setState({
            buttonText: !this.state.buttonText,
            smodal: false,
            miniModal: false,
            customerId: "",
            pastAppts: "",
            mode: "",
            timeStart: "",
            technician: "",
            key: 1,
            service: true,
            serviceId: "",
            serviceName: "",
            servicePrice: "",
            techServices: "",
            place: "Type a name",
            durat: "",
            technicianRequested: true,
            serrors: {}

        });
        this.props.setPlace("something");
    };

    closePmodal() {
        this.setState({
            buttonText: !this.state.buttonText,
            pmodal: false,
            errors: {},
        });
    }

    teamWiseTechDisplay = (team, k) => (
        <optgroup key={k} label={team}>
            {this.checkChild(team)}
        </optgroup>
    );
    checkChild = (team) => {
        const grouped = [];
        this.app.locations[0].technicians
            .filter((t) => t.active == true)
            .map((t, k) => {
                if (t.team == team)
                    grouped.push(
                        <option key={k} value={t.id}>
                            {t.user.names.nick}
                        </option>
                    );
            });
        return grouped;
    };
}

export default AddToCart;
