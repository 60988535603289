import React from 'react';


const ProductInfo = ({ product: info, openEditForm }) => {
  return (info && Object.keys(info).length > 0 &&
    <React.Fragment>

      <div className="product-info">
        <div className="d-flex items-center">
          <h3 style={{ marginRight: '20px' }}><strong>Product Information</strong></h3>
          <div className="info-action-button">
            <button onClick={openEditForm} className="btn btn-circle btn-lg btn-primary"><i className="fa fa-pencil"></i></button>
          </div>
        </div>
        <br />
        <h4><strong>{info.name}</strong></h4>
        <div className="customer-details-tab">
          <ul className={"nav nav-tabs tabs customtab"}>

            {['Details', 'Ingredients', 'Usage'].map((info, key) => {
              return (
                <li key={key} className={`tab ${key === 0 ? 'active' : ''}`}>
                  <a
                    href={`#${info}`}
                    data-toggle="tab"
                    aria-expanded="true"
                  >
                    {" "}

                    <span className="hidden-xs">{info}</span>{" "}
                  </a>
                </li>
              )
            })}


          </ul>
          <div className="tab-content">

            {['Details', 'Ingredients', 'Usage'].map((desc, key) => {
              return (
                <div key={key} className={`tab-pane fade ${key === 0 ? 'in active' : null}`} id={desc}>
                  <div className="details" dangerouslySetInnerHTML={{ __html: info[desc.toLowerCase()] !== '' ? info[desc.toLowerCase()] : `<strong><span class="text-danger">${desc} is not added yet</span></strong>` }}></div>

                </div>
              )
            })}
          </div>
        </div>


        <div></div>
        <div className="d-flex details-footer">
          <div className="brand">
            <i className="fa fa-tags"></i>Brand:{info.brand.name}
          </div>
          <div className="brand">
            <i className="fa fa-map-marker"></i>In Stock: <span>{info.inStock === true ? 'YES' : 'NO'}</span>
          </div>
          <div className="brand">
            <i className="fa fa-eye"></i>Visible: <span>{info.visible === true ? 'YES' : 'NO'}</span>
          </div>
        </div>
      </div>
    </React.Fragment >

  )

}

export default ProductInfo;
