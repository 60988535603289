import {appService, userService} from "../_services";
const utilities = {
    updateTechnicianService
}
export default utilities;
async function updateTechnicianService(id,userId,min,max,sid,duration,process){


    const techJSON = {
        technician: {
            id: userId
        },
        service: {
            id: sid
        },
        duration: 'PT' + duration + 'M',
        minimumFee: min,
        maximumFee: max,
        processingTime: 'PT' + process + 'M'

    }
   return await appService.updateServiceTechnician(id, techJSON);
}


