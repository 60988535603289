import {authHeader} from "../_helpers";
import {Config} from "../config";

var config = Config()
const apiPath = config.serverUrl;

export const FirebaseService = {
    firebaseTopicSubscription,
    firebaseTopicUnsubscription
}

async function firebaseTopicSubscription(topic, registrationToken) {
    console.log(authHeader())
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify({topic, registrationToken})
    };
    return fetch(apiPath + 'fcm/subscribe', requestOptions).then(handleResponse);
}

async function firebaseTopicUnsubscription(topic, registrationToken) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify({topic, registrationToken})
    };
    return fetch(apiPath + 'fcm/unsubscribe', requestOptions).then(handleResponse);
}

function handleResponse(response) {
    if (!response.ok) {
        if (response.status == 401 || response.status == 403) {
            localStorage.removeItem('user')
            window.location = `/${window.location.href.split('/')[3]}/login`;
        }
        return Promise.reject(response);
    }
    return response.json();
}
