export function authHeader() {
    // return authorization header with token
    let user = JSON.parse(localStorage.getItem('user'));

    if (user && user.token) {
        return {
            'X-Auth-Token': user.token,
            'Content-Type': 'application/json',
            'X-Xsrf-Token':user.csrfToken,
            'X-Requested-With': 'XMLHttpRequest'
        };
    } else {
        return {};
    }
}



