/* eslint-disable */
import React, {Component} from 'react';
import 'rc-datetime-picker/dist/picker.min.css';
import moment from 'moment';
import {userService} from '../_services/user.service'
import InlineMessage from './inlineMessage';
import {helperFunctions} from '../_helpers'
import 'rc-time-picker/assets/index.css';
import TimePicker from 'rc-time-picker';
import ProductSearchBar from './productSearchBar'
import {appService, reservationService} from '../_services'
import {connect} from 'react-redux'
import {DatetimePickerTrigger} from "rc-datetime-picker";
import ProductScanner from "./productScanner";

const format = 'hh:mm a';
const now = moment().hour(0).minute(0);

class Shopping extends Component {

    constructor(props) {
        super(props)
        this.state = {
            products: false,
            singleProduct: '',
            selectedProduct: {},
            selectedProducts: [],
            technician: '',
            buttonText: false,
            requestSoruce: 'Walk In',
            productPrice: '',
            qty: 1,
            errors: {},
            key: 1,
            place: 'Type a name',
            validQty: '',
            customerId: '',
            pastAppts: '',
            customerModal: '',
            mobile: '',
            mount: '',
            mode: '',
            attendant: '',
            giftCardSaleAmount: '',
            taxAmount: '',
            tipAmount: '',
            creditPaidAmount: '',
            cashPaidAmount: '',
            checkPaidAmount: '',
            giftCardPaidAmount: '',
            rewardDeductionAmount: '',
            discountDeductionAmount: '',
            shoppingInfo: '',
            vId: '',
            totalChargeAmount: '',
            startTime: moment(),
            endTime: moment()


        }

        this.app = JSON.parse(localStorage.getItem('app'))

        this.handleChange = this.handleChange.bind(this)
    }


    getAllTechnicians() {
        userService.getAllTechniciansForCalendarReservation('all', 0).then(techs => {
            var bookableTechs = techs.filter(tech => tech.active && tech.bookable && tech.technicianServices.length > 0);
            this.setState({
                technicians: bookableTechs,
            });
        })
    }


    handleChange(e) {
        const {name, value} = e.target;
        this.setState({
            [name]: value
        }, function () {
            if (name == "selectedTechnician") {
                this.getAllServices()
            }

            if (name == "selectedService") {
                this.setState({selectedService: this.state.services.find(s => s.id == value)})
            }
        })
    }


    onProductSelect = (product) => {

        const products = this.state.selectedProduct
        products.name = product.variant.product.name
        products.id = product.id

        this.setState({
            selectedProduct: products,
            productPrice: product.salePrice,
            validQty: product.quantity
        })

    }


    addSelectedProduct = () => {
        const {selectedProduct, technician} = this.state
        const selected = this.state.selectedProducts

        const productJSON = {
            id: selectedProduct.id,
            key: this.state.key++,
            qty: this.state.qty,
            name: selectedProduct.name,
            price: this.state.productPrice,
            technicianId: this.state.technician,
            techImage: this.findTechnician(technician, 'image'),
            technicianname: this.findTechnician(technician, 'nick')
        }


        const errors = this.validate(productJSON);
        this.setState({errors});
        if (Object.keys(errors).length == 0) {
            $('#addProduct').modal('toggle')
            $('#Shopping').modal('toggle')
            selected.push(productJSON);
            this.props.setPlace()
            this.setState({selectedProducts: selected, techServices: ''})
        }

    }

    validate = (data) => {

        const errors = {}
        if (!data.id) errors.product = 'Please select a product';
        if (!data.qty || data.qty < 1) errors.qty = 'Please enter valid quantity ';
        if (!data.price) errors.price = 'Please enter a valid price';
        if (!data.technicianId) errors.technicianId = 'Please select a staff';


        return errors;
    }


    saveShopping = () => {

        const {selectedProducts} = this.state
        this.setState({
            mode: ''
        })
        var purchaseItems = []
        if (selectedProducts.length > 0) {
            for (var i = 0; i < selectedProducts.length; i++) {
                var singleProduct = {
                    technician: {
                        id: selectedProducts[i].technicianId,
                    },
                    quantity: selectedProducts[i].qty,
                    chargeAmount: selectedProducts[i].price,
                    locatedProductVariant: {
                        id: selectedProducts[i].id
                    }
                }

                purchaseItems.push(singleProduct)
            }
        } else {
            purchaseItems = []
        }

        const apptJSON = {
            location: {
                id: this.app.locations[0].id
            },
            requestSource: this.state.requestSoruce,
            requestType: 'Shopping',
            customerNotes: '',
            visitTechnicians: [
                {
                    technician: {
                        id: this.state.attendant != '' ? this.state.attendant : this.props.user.id,
                    },
                    expectedStartTime: this.state.startTime.format("YYYY-MM-DDTHH:mm:ss"),
                    period: {
                        to: this.state.startTime.format("YYYY-MM-DDTHH:mm:ss")
                    }
                }
            ],
            purchaseItems: purchaseItems,
            client: {
                id: this.props.customer.id
            }

        }
        this.save(apptJSON);
        this.setState({
            technician: '',
            selectedProducts: [],
            productPrice: '',

        });

        this.props.setPlace()

    }

    save = (apptJSON) => {
        reservationService.saveAppointment(apptJSON).then(res => {
            window.DataUpdateSuccess();
            this.setState({
                giftCardSaleAmount: res.giftCardSaleAmount,
                taxAmount: res.taxAmount,
                tipAmount: res.tipAmount,
                creditPaidAmount: res.creditPaidAmount,
                cashPaidAmount: res.cashPaidAmount,
                checkPaidAmount: res.checkPaidAmount,
                giftCardPaidAmount: res.giftCardPaidAmount,
                rewardDeductionAmount: res.rewardDeductionAmount,
                discountDeductionAmount: res.discountDeductionAmount,
                shoppingInfo: res,
                vId: res.id,
                totalChargeAmount: res.totalChargeAmount,
                mode: 'checkout'
            })
        }).catch(e => {
            e.json().then(err=>{
                Swal.fire({
                    title: 'Error',
                    text: `${err.message}`,
                    type: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Ok!'
                })
            })

        })
    }


    incrementQty = () => {
        var qty = parseInt(this.state.qty, 10);
        qty++
        if (qty >= this.state.validQty) {
            this.setState({
                qty: this.state.validQty
            })
        } else {
            this.setState({
                qty: qty
            })
        }


    }
    decrementQty = () => {
        var qty = parseInt(this.state.qty, 10);
        if (isNaN(qty)) {
            this.setState({
                qty: 1
            })
        } else {
            if (qty > 1) {
                this.setState({
                    qty: qty - 1
                })
            }
        }
    }


    //remove product from the cart
    removeProduct = (key) => {
        const newSelectedProducts = this.state.selectedProducts.filter(p => p.key != key);
        this.setState({
            ...this.state,
            selectedProducts: newSelectedProducts
        })
    }
    //remove product from the cart
    goBack = () => {
        this.setState({
            ...this.state,
            mode: ''
        })
    }


    refreshCreateModal = (val) => {
        this.setState({
            selectedServices: [],
            techServices: [],
            technician: '',
            selectedCustomer: '',
            selectedProducts: []
        }, () => {
            this.setState({
                selectedServices: []
            })
        })

        this.props.setPlace(val)

    }

    changeHandler = (e) => {
        const {name, value} = e.target;
        this.setState({
            [name]: value,

        })

    }

    apptCheckout = () => {
        const {
            shoppingInfo,
            giftCardSaleAmount,
            taxAmount,
            tipAmount,
            totalChargeAmount,
            creditPaidAmount,
            cashPaidAmount,
            checkPaidAmount,
            giftCardPaidAmount,
            rewardDeductionAmount,
            discountDeductionAmount,

        } = this.state;
        const paymentJSON = {
            serviceChargeAmount: shoppingInfo.serviceChargeAmount,
            productPurchaseAmount: shoppingInfo.productPurchaseAmount,
            taxAmount: taxAmount,
            tipAmount: tipAmount,
            giftCardSaleAmount: giftCardSaleAmount,
            totalChargeAmount: totalChargeAmount,
            creditPaidAmount: creditPaidAmount < 0 ? 0 : creditPaidAmount,
            cashPaidAmount: cashPaidAmount < 0 ? 0 : cashPaidAmount,
            checkPaidAmount: checkPaidAmount < 0 ? 0 : checkPaidAmount,
            giftCardPaidAmount: giftCardPaidAmount < 0 ? 0 : giftCardPaidAmount,
            rewardDeductionAmount: rewardDeductionAmount < 0 ? 0 : rewardDeductionAmount,
            discountDeductionAmount: discountDeductionAmount < 0 ? 0 : discountDeductionAmount
        }
        this.props.productCheckout(this.state.vId, paymentJSON)
        this.setState({
            shoppingInfo: '',
            giftCardSaleAmount: '',
            taxAmount: '',
            tipAmount: '',
            totalChargeAmount: '',
            creditPaidAmount: '',
            cashPaidAmount: '',
            checkPaidAmount: '',
            giftCardPaidAmount: '',
            rewardDeductionAmount: '',
            discountDeductionAmount: '',
            mode: ''
        })

    }
    changeStartTime = (start) => {
        this.setState({
            startTime: start
        })
    }
    changeEndTime = (end) => {
        this.setState({
            startTime: end
        })
    }

    onProductScan=(product)=> {
        console.log(product)
        const products = this.state.selectedProduct
        // products.name = product[0].variant.product.name
        // products.id =  product[0].id

        this.setState({
            selectedProduct: {name: product[0].variant.product.name,id:product[0].id},
            productPrice:  product[0].salePrice,
            validQty:  product[0].quantity,
            productName: product[0].variant.product.name,
            productId: product[0].id

        })

    }



    clearScanner = () => {
        this.setState({
            productName: ''
        })
    }


    render() {
        const {customer, user} = this.props;
        const {selectedProducts, errors, shoppingInfo} = this.state


        return (
            <React.Fragment>
                <div data-backdrop="static" data-keyboard="false" className="modal fade" id="Shopping">

                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button onClick={() => this.refreshCreateModal('Type a name.')} type="button"
                                        className="close"
                                        data-dismiss="modal" aria-label="Close"><span
                                    aria-hidden="true">×</span></button>
                                <h4 className="modal-title" id="cancelLabelModel">Appointment</h4>
                            </div>
                            <div className="modal-body">
                                <div className="client-info addApt">
                                    <div className="info-header" style={{border: 'none'}}>

                                        <div className="client">
                                            {
                                                customer &&
                                                <React.Fragment>
                                                    <div className="chat-image">
                                                        <img src={customer.imageUrl} alt=""/>
                                                    </div>
                                                    <div className="chat-body">
                                                        <div className="chat-text">
                                                            <div>
                                                                <strong>{customer.fullName}</strong>
                                                                <p><i
                                                                    className="fa fa-phone"></i> {customer.mobileNumber}
                                                                </p>
                                                                <p><i
                                                                    className="fa fa-dollar"></i> {(customer.rewardBalance * 1).toFixed(2)}
                                                                </p>
                                                                <div className="appt-time">
                                                                    <p><i
                                                                        className="fa fa-calendar"></i> {helperFunctions.formatDateTimeWithDay(this.props.apptDate, 'date')}
                                                                    </p>
                                                                </div>
                                                                <div className="appt-time">
                                                                    <p><i className="fa fa-calendar-check-o"></i>

                                                                        {customer.lastVisit &&
                                                                        <a style={{cursor: 'pointer'}}>
                                                                            {customer.lastVisit.readableBookedTime}
                                                                        </a>}
                                                                        {!customer.lastVisit && 'NONE'}


                                                                    </p>
                                                                </div>
                                                                <div className="appt-time">
                                                                    <p><i className="fa fa-random"></i>

                                                                        {customer.nextVisit ? customer.nextVisit.readableBookedTime : 'NONE'}

                                                                    </p>
                                                                </div>

                                                                <br/>

                                                                <div className="form-group">
                                                                    <strong>Source Of Request</strong>
                                                                    <div className="inline-radio-buttons">

                                                                        <label className="radio-inline">
                                                                            <input
                                                                                type="radio"
                                                                                value="Walk In"
                                                                                onChange={this.handleChange}
                                                                                checked
                                                                                name="requestSoruce"/>Walk In</label>
                                                                    </div>
                                                                </div>
                                                                <div className="confirm-btn-group">
                                                                    <div className="confirm-btn">
                                                                        {this.app != null && this.app.locations[0].brands.length > 0 &&
                                                                        <button
                                                                            data-target="#addProduct"
                                                                            data-dismiss="modal"
                                                                            data-toggle="modal"
                                                                            onClick={() => this.setState({
                                                                                products: !this.state.products,
                                                                                pmodal: !this.state.pmodal
                                                                            })}
                                                                            className={this.state.products ? "actve" : "button-custom"}>Add
                                                                            Product</button>}
                                                                    </div>

                                                                </div>
                                                            </div>


                                                            <div>


                                                                <div className="form-group">
                                                                    <strong>Attendant</strong>
                                                                    <div className="form-group add-services">
                                                                        <select name="attendant"
                                                                                value={this.state.attendant != '' ? this.state.attendant : user.id}
                                                                                onChange={this.handleChange}>

                                                                            {this.app.lookupGroup.teams.map((team, key) => {
                                                                                return this.teamWiseTechDisplay(team, key)
                                                                            })}
                                                                        </select>
                                                                        <p className="text-danger error">{errors.technicianId &&
                                                                        <InlineMessage text={errors.technicianId}/>}</p>

                                                                    </div>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="endTime">Purchase Time &nbsp; <span
                                                                            className="text-danger">*</span></label>
                                                                    <div className="time-clock">
                                                                        <DatetimePickerTrigger
                                                                            moment={this.state.startTime}
                                                                            onChange={this.changeStartTime}>
                                                                            <input
                                                                                type="text"
                                                                                value={this.state.startTime.format('YYYY-MM-DD') + " " + helperFunctions.formatDateTimeWithDay(moment(this.state.startTime.format('YYYY-MM-DDTHH:mm:ss')), 'time')}
                                                                                readOnly/>
                                                                        </DatetimePickerTrigger>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </div>


                                                    </div>
                                                </React.Fragment>

                                            }
                                        </div>


                                    </div>
                                    {this.state.mode == '' &&
                                    <div className="past-appointments">
                                        {selectedProducts.length > 0 &&
                                        <div className="table-responsive table-product">
                                            <table className="table table-bordered table-condensed">
                                                <thead>
                                                <tr>
                                                    <th>Product</th>
                                                    <th>Tech</th>
                                                    <th>Qty</th>
                                                    <th>Price</th>
                                                    <th className="text-center">Action</th>
                                                </tr>
                                                </thead>
                                                <tbody>


                                                {
                                                    selectedProducts.map((s, k) => {
                                                        return <tr key={k}>
                                                            <td>{s.name}</td>
                                                            <td><img width="30" className="img-circle"
                                                                     src={s.techImage}/> {s.technicianname}</td>
                                                            <td>{s.qty}</td>
                                                            <td><i
                                                                className="fa fa-dollar"></i>{(s.price * 1).toFixed(2)}
                                                            </td>
                                                            <td align="center">
                                                                <button onClick={() => this.removeProduct(s.key)}
                                                                        className="cross-btn"><i
                                                                    className="ti ti-close text-danger"></i></button>
                                                            </td>
                                                        </tr>
                                                    })
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                        }
                                    </div>
                                    }
                                    {this.state.mode != '' &&
                                    <div className="past-appointments">
                                        <div className="check-out-info">
                                            <div className="expense col-md-5">
                                                <ul className="list-group">
                                                    <li className="list-group-item">
                                                        <b>Costs: <span
                                                            className="text-warning cost">($ {shoppingInfo && (((shoppingInfo.totalChargeAmount * 1) + (this.state.tipAmount * 1) + (this.state.taxAmount * 1) + (this.state.giftCardSaleAmount * 1))).toFixed(2)})</span></b>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <p className="title-fade">Service Charge
                                                            Amount &nbsp; <span
                                                                className="text-danger">*</span></p>
                                                        <p className="title-amount">
                                                            <input
                                                                readOnly
                                                                type="text"
                                                                maxLength="9"
                                                                step="0.01"
                                                                onChange={this.changeHandler}
                                                                value={shoppingInfo && shoppingInfo.serviceChargeAmount && (shoppingInfo.serviceChargeAmount * 1).toFixed(2)}/>
                                                        </p>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <p className="title-fade">Products Purchase
                                                            Amount &nbsp; <span
                                                                className="text-danger">*</span></p>
                                                        <p className="title-amount">
                                                            <input
                                                                readOnly
                                                                type="text"
                                                                maxLength="9"
                                                                step="0.01"
                                                                onChange={this.changeHandler}
                                                                value={shoppingInfo && shoppingInfo.productPurchaseAmount && (shoppingInfo.productPurchaseAmount * 1).toFixed(2)}
                                                            />
                                                        </p>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <p className="title-fade">Gift Card Sale Amount &nbsp; <span
                                                            className="text-danger">*</span></p>
                                                        <p className="title-amount">
                                                            <input
                                                                type="text"
                                                                maxLength="9"
                                                                step="0.01"
                                                                onChange={this.changeHandler}
                                                                name="giftCardSaleAmount"
                                                                value={(this.state.giftCardSaleAmount * 1)}
                                                            />
                                                        </p>
                                                    </li>

                                                    <li className="list-group-item">
                                                        <p className="title-fade">Tip Amount &nbsp;
                                                            <span className="text-danger">*</span>
                                                        </p>
                                                        <p className="title-amount">
                                                            <input
                                                                type="text"
                                                                name="tipAmount"
                                                                maxLength="9" step="0.01"
                                                                onChange={this.changeHandler}
                                                                onBlur={this.formatAmount}
                                                                value={this.state.tipAmount}
                                                            />
                                                        </p>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <p className="title-fade">Tax Amount &nbsp;
                                                            <span className="text-danger">*</span>
                                                        </p>
                                                        <p className="title-amount">
                                                            <input
                                                                readOnly
                                                                type="text"
                                                                maxLength="9" step="0.01"
                                                                name="taxAmount"
                                                                onChange={this.changeHandler}
                                                                onBlur={this.formatTax}
                                                                value={(this.state.taxAmount * 1)}
                                                            />
                                                        </p>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <p className="title-fade"><b>Total
                                                            Amount</b></p>
                                                        <p className="title-amount">
                                                            <input
                                                                readOnly
                                                                type="text"
                                                                maxLength="9"
                                                                step="0.01"
                                                                onChange={this.changeHandler}
                                                                value={shoppingInfo && (((shoppingInfo.totalChargeAmount * 1) + (this.state.tipAmount * 1) + (this.state.taxAmount * 1) + (this.state.giftCardSaleAmount * 1))).toFixed(2)}
                                                            />
                                                        </p>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="expense col-md-5 col-md-offset-1">
                                                <ul className="list-group">
                                                    <li className="list-group-item">
                                                        <b>Payments: <span
                                                            className="text-custom-info">($ {((this.state.creditPaidAmount * 1) + (this.state.cashPaidAmount * 1) + (this.state.checkPaidAmount * 1) + (this.state.rewardDeductionAmount * 1) + (this.state.giftCardPaidAmount * 1) + (this.state.discountDeductionAmount * 1)).toFixed(2)})</span></b>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <p className="title-fade">Credit Paid
                                                            Amount &nbsp; <span
                                                                className="text-danger">*</span></p>
                                                        <p className="title-amount">
                                                            <input

                                                                type="text"
                                                                maxLength="9"
                                                                name="creditPaidAmount"
                                                                onChange={this.changeHandler}
                                                                value={this.state.creditPaidAmount}
                                                            />
                                                        </p>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <p className="title-fade">Cash Paid
                                                            Amount &nbsp; <span
                                                                className="text-danger">*</span></p>
                                                        <p className="title-amount">
                                                            <input
                                                                type="text"
                                                                maxLength="9"
                                                                name="cashPaidAmount"
                                                                onChange={this.changeHandler}
                                                                value={this.state.cashPaidAmount}
                                                            />
                                                        </p>
                                                    </li>

                                                    <li className="list-group-item">
                                                        <p className="title-fade">Check Paid
                                                            Amount &nbsp; <span
                                                                className="text-danger">*</span></p>
                                                        <p className="title-amount">
                                                            <input
                                                                name="checkPaidAmount"
                                                                type="text"
                                                                maxLength="9"
                                                                onChange={this.changeHandler}
                                                                value={this.state.checkPaidAmount}
                                                            />
                                                        </p>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <p className="title-fade">Gift Card Paid
                                                            Amount &nbsp; <span
                                                                className="text-danger">*</span></p>
                                                        <p className="title-amount">
                                                            <input
                                                                name="giftCardPaidAmount"
                                                                type="text"
                                                                maxLength="9"
                                                                onChange={this.changeHandler}
                                                                value={this.state.giftCardPaidAmount}
                                                            />
                                                        </p>
                                                    </li>


                                                    <li className="list-group-item">
                                                        <p className="title-fade">Reward Points
                                                            Used &nbsp; <span
                                                                className="text-danger">*</span></p>
                                                        <p className="title-amount">
                                                            <input

                                                                type="text"
                                                                name="rewardDeductionAmount"
                                                                onChange={this.changeHandler}
                                                                value={this.state.rewardDeductionAmount}
                                                            />
                                                        </p>
                                                    </li>

                                                    <li className="list-group-item">
                                                        <p className="title-fade">Discount
                                                            Amount &nbsp; <span
                                                                className="text-danger">*</span></p>
                                                        <p className="title-amount">
                                                            <input

                                                                type="text"
                                                                name="discountDeductionAmount"
                                                                onChange={this.changeHandler}
                                                                value={this.state.discountDeductionAmount}
                                                            />
                                                        </p>
                                                    </li>


                                                </ul>

                                            </div>


                                        </div>
                                    </div>
                                    }

                                </div>


                            </div>

                            {this.state.mode == '' &&
                            <div className="modal-footer">
                                <ul>
                                    <li>
                                        <button onClick={this.saveShopping}
                                                className="btn btn-custom-info">Continue <i
                                            className="fa fa-arrow-right"></i>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            }
                            {this.state.mode !== '' &&
                            <div className="modal-footer">
                                <ul>
                                    <li>
                                        <button onClick={this.goBack}
                                                className="btn btn-custom-info">Go Back
                                        </button>
                                    </li>
                                    <li>
                                        <button onClick={this.apptCheckout}
                                                className="btn btn-custom-info">Check Out
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            }


                            {this.props.loading == true &&
                            <div className="custom-loading">
                                <div className="loading"><i className="fa fa-spinner fa-spin"></i></div>
                            </div>
                            }


                        </div>
                    </div>
                </div>

                {this.app != null && this.app.locations[0].brands.length > 0 &&
                <div data-backdrop="static" data-keyboard="false" className="modal fade"
                     id="addProduct">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <ProductScanner
                                    onProductScan={this.onProductScan}
                                />
                                <button className="close" data-toggle="modal" data-dismiss="modal"
                                        data-target="#Shopping" onClick={() => this.closePmodal()}>&times;</button>
                                <div className="row">

                                    <div className="col-md-12">
                                        <div
                                            className="form-group select-arrow">
                                            <label htmlFor="stylish">Product &nbsp;  <span
                                                className="text-danger">*</span></label>
                                            <ProductSearchBar
                                                id="productId"
                                                place={this.props.place}
                                                scanningName={this.state.productName}
                                                clearScanner={this.clearScanner}
                                                clearPlace={this.props.clearPlace}
                                                setPlace={this.props.setPlace}
                                                onProductSelect={this.onProductSelect}/>
                                            <p className="text-danger error">{errors.product &&
                                            <InlineMessage text={errors.product}/>}</p>
                                        </div>

                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group add-services">
                                            <label>Select Staff</label>
                                            <select name="technician" id="technicians-select"
                                                    value={this.state.technician} onChange={this.handleChange}>
                                                <optgroup label="">
                                                    <option>Select Staff</option>
                                                </optgroup>
                                                {this.app.lookupGroup.teams.map((team, key) => {
                                                    return this.teamWiseTechDisplay(team, key)
                                                })}
                                            </select>
                                            <p className="text-danger error">{errors.technicianId &&
                                            <InlineMessage text={errors.technicianId}/>}</p>

                                        </div>


                                    </div>
                                </div>

                                <div className="row">

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="endTime">Quantity &nbsp; <span
                                                className="text-danger">*</span></label>

                                            <div className="quantity-counter">
                                                <div className="count-grabber">
                                                    <input readOnly type="text" className="form-control"
                                                           value={this.state.qty}/>
                                                    <div className="btns">
                                                        <div onClick={this.incrementQty} className="incrmntbtn"><i
                                                            className="fa fa-caret-up"></i></div>
                                                        <div onClick={this.decrementQty} className="drmntbtn"><i
                                                            className="fa fa-caret-down"></i></div>
                                                    </div>
                                                </div>

                                            </div>


                                            <p className="text-danger error">{errors.qty &&
                                            <InlineMessage text={errors.qty}/>}</p>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="endTime"> Price &nbsp; <span
                                                className="text-danger">*</span></label>
                                            <input value={this.state.productPrice} className="form-control" type="text"
                                                   name="productPrice" onChange={this.handleChange}/>
                                            <p className="text-danger error">{errors.price &&
                                            <InlineMessage text={errors.price}/>}</p>
                                        </div>
                                    </div>

                                </div>

                                <div className="form-group text-right">
                                    <button onClick={this.addSelectedProduct}
                                            className="btn btn-custom-info">Add Product
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }

            </React.Fragment>
        );
    }


    findTechnician(technicianId, query) {
        // eslint-disable-next-line
        let technician = this.app.locations[0].technicians.find(technician => technician.id == technicianId)

        if (technician) {
            if (query == 'image') {
                return technician.user.imageUrl;
            } else if (query == 'active') {
                if (technician.active == 'true') {
                    return 'active'
                }
            } else if (query == 'nick') {
                return technician.user.names.nick;
            } else {
                return technician.user.fullName;
            }
        } else {

            return "";
        }
    }

    closeThisModal = (val) => {
        $('#createEvent').modal('show');
        this.setState({
            buttonText: !this.state.buttonText,
            smodal: false,
            miniModal: false,
            customerId: '',
            pastAppts: '',
            mode: '',
            timeStart: '',
            technician: '',
            key: 1,
            service: true,
            serviceId: '',
            serviceName: '',
            servicePrice: '',
            techServices: '',
            place: 'Type a name'

        });

    }

    closePmodal() {
        this.setState({
            buttonText: !this.state.buttonText,
            pmodal: false,
            validQty: '',
            qty: 1,
            productPrice: '',
            technician: '',
            productName: ''

        });
        this.props.setPlace()
    }

    teamWiseTechDisplay = (team, k) => <optgroup key={k} label={team}>{this.checkChild(team)}</optgroup>
    checkChild = team => {
        const grouped = [];
        this.app.locations[0].technicians.filter(t => t.active == true).map((t, k) => {
            if (t.team == team) grouped.push(<option key={k} value={t.id}>{t.user.names.nick}</option>)
        });
        return grouped
    }

}

function mapStateToProps(state) {
    const {user} = state.authentication;
    return {
        user
    };
}

export default connect(mapStateToProps)(Shopping);
