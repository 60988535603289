import {closeOutConstants} from "../_constants";


export function closeOut(state={},action) {

    switch (action.type) {
        case closeOutConstants.GET_ALL_CLOSEOUT_REQUEST:
            return{
                closeOut:[],
                closeoutLoaded:false
            }

        case closeOutConstants.GET_ALL_CLOSEOUT_SUCCESS:
            return {
                closeOut: action.closeOut,
                closeoutLoaded:true
            }
        case closeOutConstants.GET_ALL_CLOSEOUT_FAILURE:
            return {
                closeOut: [],
                closeoutLoaded: false
            }

        default:
            return state
    }
}